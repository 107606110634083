// <!-- ENUMS -->

/**
 * Map defining node selectors.
 * @type {typeof Treeview.NodeSelectors}
 */
export const NodeSelectors = Object.freeze({
    Hierarchy: Object.freeze({ type: 'Hierarchy', code: 'h' }),
    Location: Object.freeze({ type: 'Location', code: 'l' }),
    Station: Object.freeze({ type: 'Station', code: 's' }),
});

/**
 * Available node types.
 * @type {typeof Treeview.NodeTypes}
 */
export const NodeTypes = Object.freeze({
    Hierarchy: NodeSelectors.Hierarchy.type,
    Location: NodeSelectors.Location.type,
    Station: NodeSelectors.Station.type,
});

/**
 * Map defining node type identifier codes.
 * @type {typeof Treeview.NodeCodes}
 */
export const NodeCodes = Object.freeze({
    Hierarchy: NodeSelectors.Hierarchy.code,
    Location: NodeSelectors.Location.code,
    Station: NodeSelectors.Station.code,
});

/**
 * Natural ascending sort order for {@link NodeTypes}.
 * Hierarchy < Location < Station (ASC)
 */
export const NodeTypeSortOrder = Object.freeze([
    NodeTypes.Hierarchy,
    NodeTypes.Location,
    NodeTypes.Station,
]);

// <!-- GENERIC TYPE GUARDS -->

/** @type {Treeview.Guards.NodeSelectorGuard} */
const isNodeSelector = (value) => {
    return isNodeType(value) || isNodeCode(value);
};

/** @type {Treeview.Guards.NodeTypeGuard} */
const isNodeType = (value) => {
    return (
        typeof value === 'string' &&
        !!Object.values(NodeTypes).find((t) => t === value)
    );
};

/** @type {Treeview.Guards.NodeCodeGuard} */
const isNodeCode = (value) => {
    return (
        typeof value === 'string' &&
        !!Object.values(NodeCodes).find((c) => c === value)
    );
};

/** @type {Treeview.Guards.NodeIDValidator} */
const isNodeIDFormat = (value) => {
    if (typeof value !== 'string' || value.length <= 0) {
        // EMPTY or non-String.
        return false;
    }

    const code = value.substring(0, 1);
    if (!isNodeCode(code)) {
        // INVALID node code.
        return false;
    }

    const identifier = value.substring(1);
    if (typeof identifier !== 'string' || identifier?.length <= 0) {
        // EMPTY or non-String identifier.
        return false;
    }

    // RETURN `true` when all tests pass.
    return true;
};

// <!-- SPECIFIC TYPE GUARDS -->

/** @type {Treeview.Guards.SpecificNodeTypeGuard} */
const isSpecificNodeType = (value, type) => {
    return isNodeType(type) && isNodeType(value) && value === type;
};

/** @type {Treeview.Guards.SpecificNodeCodeGuard} */
const isSpecificNodeCode = (value, code) => {
    return isNodeCode(code) && isNodeCode(value) && value === code;
};

/** @type {Treeview.Guards.SpecificNodeIDValidator} */
const isSpecificNodeIDFormat = (value, selector) => {
    // FIND the actual code.
    const code = getNodeCode(selector);
    if (typeof code !== 'string' || code?.length <= 0) {
        // INVALID code type, nothing will ever match.
        return false;
    }

    // CONFIRM value is of a valid format.
    if (!isNodeIDFormat(value)) {
        // INVALID id format to begin with.
        return false;
    }

    // TEST if code matches the selector.
    if (!isSpecificNodeCode(value.substring(0, 1), code)) {
        // INVALID node code in the id string.
        return false;
    }

    // GET remaining identifier substring.
    // (Already confirmed to exist by earlier isNodeIDFormat call).
    const identifier = value.substring(1).trim();

    // IF hierarchy or location...
    if (code === 'h' || code === 'l') {
        // TEST if identifier is valid numeric.
        const numeric = Number(identifier);
        const isFiniteInteger =
            Number.isFinite(numeric) && Number.isInteger(numeric);
        return isFiniteInteger;
    }

    // IF weather station...
    if (code === 's') {
        // TEST if identifier is valid string.
        const station = identifier.trim();
        const isNonEmptyString = station.length >= 1;
        return isNonEmptyString;
    }

    // RETURN false, due to invalid format.
    return false;
};

// <!-- SELECTORS -->

/**
 * @type {Treeview.Selectors.NodeTypeSelector}
 * Get the node type associated with the provided selector.
 */
const getNodeType = (selector) => {
    // TEST if already a type.
    if (isNodeType(selector)) {
        // RETURN valid type.
        return selector;
    }

    if (isNodeCode(selector)) {
        const codes = Object.entries(NodeCodes);
        const types = Object.entries(NodeTypes);

        // GET the key from the node code.
        const [ckey = undefined] =
            codes.find(([_, value]) => value === selector) ?? [];

        // GET the type using the key.
        const [_, type = undefined] = types.find(([key]) => key === ckey) ?? [];

        // RETURN the matched type.
        return isNodeType(type) ? type : undefined;
    }

    // RETURN invalid code.
    return undefined;
};

/**
 * @type {Treeview.Selectors.NodeCodeSelector}
 * Get the node code associated with the provided selector.
 */
const getNodeCode = (selector) => {
    // TEST if already a code.
    if (isNodeCode(selector)) {
        // RETURN valid code.
        return selector;
    }

    if (isNodeType(selector)) {
        const types = Object.entries(NodeTypes);
        const codes = Object.entries(NodeCodes);

        // GET the key from the node type.
        const [tkey] = types.find(([_, value]) => value === selector) ?? [];

        // GET the code using the key.
        const [_, code = undefined] = codes.find(([key]) => key === tkey) ?? [];

        // RETURN the matched type.
        return isNodeCode(code) ? code : undefined;
    }

    // RETURN invalid code.
    return undefined;
};

/** @type {Treeview.Selectors.NodeIDFormatterSelector} */
const getNodeIDFormatter = (selector) => {
    // GET the code used for the formatter.
    const code = getNodeCode(selector);
    /**
     * Prepared formatter.
     * @param {string | number} identifier
     * @returns {string}
     */
    const formatter = (identifier) => formatNodeID(code, identifier);
    // RETURN prepared formatter.
    return formatter;
};

// <!-- EXTRACTORS -->

/** @type {Treeview.Extractors.NodeTypeExtractor} */
const extractNodeType = (id) => {
    // TEST if it is well-formed as an id.
    if (!isNodeIDFormat(id)) {
        // INVALID format.
        return undefined;
    }

    const code = id.substring(0, 1).trim();
    if (!isNodeCode(code)) {
        // INVALID code character.
        return undefined;
    }

    // RETURN the type from the code.
    return getNodeType(code);
};

/** @type {Treeview.Extractors.NodeCodeExtractor} */
const extractNodeCode = (id) => {
    // TEST if it is well-formed as an id.
    if (!isNodeIDFormat(id)) {
        // INVALID format.
        return undefined;
    }

    const code = id.substring(0, 1).trim();
    if (!isNodeCode(code)) {
        // INVALID code character.
        return undefined;
    }

    // RETURN the code.
    return code;
};

/** @type {Treeview.Extractors.ResourceIDExtractor} */
const extractResourceID = (id) => {
    // TEST if it is well-formed as an id.
    if (!isNodeIDFormat(id)) {
        // INVALID format.
        return undefined;
    }

    const code = id.substring(0, 1).trim();
    if (!isNodeCode(code)) {
        // INVALID code character.
        return undefined;
    }

    const identifier = id.substring(1).trim();
    if (typeof identifier !== 'string' || identifier.length <= 0) {
        // INVALID identifier.
        return undefined;
    }

    // RETURN resource id string.
    return identifier;
};

// <!-- SORTERS -->

/**
 * Get the sorting position of the input selector.
 * @param {Treeview.NodeType | Treeview.NodeCode} selector
 * @returns {number}
 */
const getNodeSelectorSortOrder = (selector) => {
    const type = !!selector ? NodeSelector.getType(selector) : '?';
    const position = NodeTypeSortOrder.findIndex((t) => t === type);
    return position;
};

/**
 * Compare two node types based on the {@link orderedNodeTypes} collection order.
 * @type {(a: Treeview.NodeType | Treeview.NodeCode, b: Treeview.NodeType | Treeview.NodeCode) => number}
 */
const compareNodeSelectors = (a, b) => {
    const _a = getNodeSelectorSortOrder(a);
    const _b = getNodeSelectorSortOrder(b);
    return _a - _b;
};

// <!-- FORMATTERS -->

/** @type {Treeview.Formatters.NodeIDFormatter} */
const formatNodeID = (code, identifier) => {
    // FORMAT the node id using the specified code and identifier.
    const id = `${code ?? ''}${identifier ?? ''}`;
    // RETURN formatted id.
    return id;
};

// <!-- CLASS -->

/** @class Node selector helpers. */
export class NodeSelector {
    static get DefaultType() {
        return NodeTypes.Hierarchy;
    }
    static get DefaultCode() {
        return NodeSelector.getCode(NodeSelector.DefaultType);
    }
    static get DefaultID() {
        return NodeSelector.format(NodeSelector.DefaultCode, '_');
    }

    // <!-- GENERIC TYPE GUARDS -->
    static isType = isNodeType;
    static isCode = isNodeCode;
    static isSelector = isNodeSelector;
    static isFormat = isNodeIDFormat;

    // <!-- SPECIFIC TYPE GUARDS -->
    static isSpecificType = isSpecificNodeType;
    static isSpecificCode = isSpecificNodeCode;
    static isSpecificFormat = isSpecificNodeIDFormat;

    // <!-- SELECTORS -->
    static getType = getNodeType;
    static getCode = getNodeCode;
    static getFormatter = getNodeIDFormatter;
    static getSortOrder = getNodeSelectorSortOrder;

    // <!-- EXTRACTORS -->
    static readType = extractNodeType;
    static readCode = extractNodeCode;
    static readResourceID = extractResourceID;

    // <!-- FORMATTERS -->
    static format = formatNodeID;

    // <!-- SORTERS -->
    static compare = compareNodeSelectors;
}

// <!-- DEFAULT -->
export default NodeSelector;
