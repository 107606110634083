<template>
    <div
        class="tooltip-focus"
        @mouseover="onMouseOver"
        @mouseenter="onMouseEnter"
        @mousemove="onMouseMove"
        @mouseout="onMouseOut"
    >
        <Teleport :to="target">
            <div
                v-if="isTooltipVisible"
                class="absolute"
            >
                <div
                    class="relative bg-primary-800 text-white text-left rounded-lg pr-1 pl-3 py-2"
                    :class="`${textSize}`"
                    :style="`left: ${position.x}px; top: ${position.y}px; width: ${width}px; z-index: 51`"
                >
                    <slot>
                        {{ content }}
                    </slot>
                </div>
            </div>
        </Teleport>

        <slot
            v-if="hasIcon"
            name="icon"
        ></slot>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent, toRefs, computed } from 'vue';
    // <!-- COMPOSABLES -->
    import { useTooltip } from '@/components/tooltips/hooks/useTooltip';

    export default defineComponent({
        name: 'BaseTooltip',

        props: {
            target: { type: String, default: 'body' },
            text: { type: String, default: 'my tooltip' },
            textSize: { type: String, default: 'text-sm' },
            offsetX: { type: Number, default: 15 },
            offsetY: { type: Number, default: -20 },
            width: { type: Number, default: 200 },
        },
        setup(props, context) {
            // <!-- DESTRUCTURE -->
            const { text } = toRefs(props);

            // <!-- STATE -->
            const tooltip = useTooltip({
                content: text,
                offset: { x: props.offsetX, y: props.offsetY },
            });

            // <!-- CONDITIONALS -->
            const hasIcon = computed(() => {
                return !!context.slots.icon;
            });

            // <!-- EXPOSE -->
            return {
                ...tooltip,
                hasIcon,
            };
        },
    });
</script>
