// <!-- API -->
import { computed, ref } from 'vue';

/**
 * Define component interface.
 */
export const useSwitchAccountModal = () => {
    // <!-- DEFINE -->
    // ==== OPEN MODALS ====
    /** @type {V.Ref<'switchAccount'>} Only one modal can be open at a time. */
    const openModal = ref(null);

    /** @type {V.ComputedRef<Boolean>} */
    const isSwitchAccountOpen = computed(() => {
        const currentOpenModal = openModal.value;
        return currentOpenModal === 'switchAccount';
    });

    /**
     * Set the open modal.
     * @param {'switchAccount'} id
     */
    const setOpenModal = (id = 'switchAccount') => {
        openModal.value = id ?? null;
    };

    const onShow = () => {
        setOpenModal('switchAccount');
    };

    const onHide = () => {
        setOpenModal(null);
    };

    // <!-- EXPOSE -->
    return { openModal, isSwitchAccountOpen, setOpenModal, onShow, onHide };
};

// <!-- DEFAULT -->
export default useSwitchAccountModal;
