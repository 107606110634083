// <!-- API -->
import UploadState from '@/store/types/uploader/state';
import { UploadWorkflowModule } from '@/store/types/uploader/module/UploadWorkflowModule';
import { UploadDataModule } from '@/store/types/uploader/module/UploadDataModule';
import { UploadErrorsModule } from '@/store/types/uploader/module/UploadErrorsModule';

// <!-- TYPES -->
import { ECNBState } from '../../ECNBStore';
import { ECNBModule } from '@/store/types/ECNBModule';

// CLASS
/**
 * @class
 * Uploader module.
 */
export class UploadModule extends ECNBModule {
    /**
     * Name of the module.
     */
    static get namespace() {
        return 'uploader';
    }

    /**
     * Module state, getters, mutations, and actions.
     */
    static get module() {
        // EXPOSE
        return {
            namespaced: true,
            get state() {
                return () => new UploadState();
            },
            get modules() {
                const modules = Object.fromEntries([
                    ['errors', UploadErrorsModule.module],
                    ['data', UploadDataModule.module],
                    ['workflow', UploadWorkflowModule.module],
                ]);
                return {
                    ...modules,
                };
            },
            getters: {},
            mutations: {
                /**
                 * @param {ECNBState['uploader']} state
                 * @param {{ key: String, data: any }} payload
                 */
                setModuleState: (state, payload) => {
                    const { key, data } = payload;
                    state[key] = Object.assign(state[key], data);
                },
                /**
                 * @param {ECNBState['uploader']} state
                 */
                clearModule: (state) => {
                    const initialState = new UploadState();
                    Object.assign(state, initialState);
                },
            },
            actions: {},
        };
    }
}
