// <!-- API -->
import { computed } from 'vue';
import isNil from 'lodash-es/isNil';

/**
 * Get computed property that points to the appropriate marketing domain.
 */
export const useMarketingDomain = (useDefaultWhenMissing = true) => {
    /**
     * @type {String} Default domain to use when using this hook.
     */
    const defaultDomain = useDefaultWhenMissing
        ? 'http://webstaging.eclimatenotebook.com'
        : null;

    /**
     * @type {V.ComputedRef<String>}
     */
    const marketingDomain = computed(() => {
        const current = process.env.VUE_APP_MARKETING_SITE_DOMAIN;
        return current ?? defaultDomain;
    });

    /**
     * Get marketing link with the marketing domain prefixed.
     * @param {String} link
     */
    const getMarketingLink = (link) => {
        return [marketingDomain.value, link ?? '']
            .filter((segment) => !isNil(segment) && segment?.length > 0)
            .join('/');
    };

    return {
        marketingDomain,
        getMarketingLink,
    };
};

// DEFAULT
export default useMarketingDomain;
