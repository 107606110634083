// <!-- API -->
import ActionLogger from '@/utils/ActionLogger';
import { useECNBStore } from './useECNBStore';

// <!-- TYPES -->
import { Store } from 'vuex';
import { ECNBStore, ECNBState } from '@/store/types/ECNBStore';
import { Maybe } from 'true-myth/dist/maybe';
import profile from '@/api/v2/profile';
import organizations from '@/api/v2/organizations';
import session from '@/api/v2/session';

/**
 * Get the computed property, getters, and actions.
 * @param {Store<ECNBState>} [store] Vuex store reference.
 */
export const useECNBProfile = (store = null) => {
    // Wrap the provided store or create a new one.
    const $ = useECNBStore(store);
    const profile = new ECNBProfile($.api.store);
    return profile;
};

/**
 * @class
 * ECNBStore wrapper.
 */
export class ECNBProfile extends ECNBStore {
    /**
     * Create specialized {@link ECNBStore} wrapper.
     * @param {Store<ECNBState>} store Vuex store reference.
     */
    constructor(store) {
        super(store);
        /**
         * Get organization as computed property.
         */
        this.organization = this.api.create.computed.accounts.organization;
        /**
         * Get account as computed property.
         */
        this.account = this.api.create.computed.accounts.account;
        /**
         * Get user as computed property.
         */
        this.user = this.api.create.computed.users.user;
    }

    /**
     * Is the primary user account an administrator level account?
     * @returns {Boolean}
     */
    get isAdmin() {
        return this.api.getters['isAdmin'];
    }

    /**
     * Is there a current user that is logged in?
     * @returns {Boolean}
     */
    get isLoggedIn() {
        return this.api.getters['isLoggedIn'];
    }

    /**
     * Get the account hierarchy labels.
     *
     * @returns {{ labels: [ site: String, building: String, floor: String, room: String ]; site: String; building: String; floor: String; room: String }}
     */
    get hierarchy() {
        return this.api.getters['AccountTreeLevel'];
    }

    /**
     * Get the account timezone.
     * @returns {TimeZoneIdentifier}
     */
    get timezone() {
        return this.api.getters['AccountTimezone'];
    }

    /**
     * Dispatch event to the store. Fetches the current user's selected organization details, if one is available.
     * @param {number} id
     * @returns {Promise<Organization.Model>}
     */
    fetchSelectedOrganization(id) {
        return this.api.dispatch('fetchSelectedOrganization', { id });
    }

    /**
     * Dispatch event to the store. Fetches the current user's selected account details, if one is available.
     * @param {number} id Account id.
     * @returns {Promise<Account.Model>} Unresolved promise.
     */
    fetchSelectedAccount(id) {
        return this.api.dispatch('fetchSelectedAccount', { id });
    }

    /**
     * Dispatch event to the store. Fetches the current user's details.
     * @returns {Promise<User.Model>} Unresolved promise.
     */
    fetchCurrentUser() {
        return this.api.dispatch('fetchCurrentUser');
    }

    /**
     * Fetch session information and return reference to self (so one can get the account and user).
     * @param {Boolean} [ignoreCache] Force refetch.
     * @returns {Promise<this>} Unresolved promise.
     */
    async getSessionInformation(ignoreCache = false) {
        try {
            console.groupCollapsed(
                `[session::info] @ ${new Date().toLocaleString()}`
            );
            const notify = ActionLogger.log(`[session::info]`);
            if (
                ignoreCache === true ||
                !this.context.state.accounts.hasAccount ||
                !this.context.state.accounts.hasOrganization ||
                !this.context.state.users.hasUser
            ) {
                try {
                    /** Get the authenticated user, if logged in. */
                    const authenticatedUser = Maybe.of(
                        await this.fetchCurrentUser()
                    );

                    // Exit early if not logged in.
                    if (authenticatedUser.isJust) {
                        const organization =
                            await session.fetchSessionOrganization();
                        const account = await session.fetchSessionAccount();

                        // Set the current organization if one was found.
                        if (authenticatedUser.isJust && organization.isOk) {
                            this.api.store.commit(
                                'setCurrentOrganization',
                                organization.value
                            );
                        }

                        // Set the current account if one was found.
                        if (
                            authenticatedUser.isJust &&
                            organization.isOk &&
                            account.isOk
                        ) {
                            this.api.store.commit(
                                'setCurrentAccount',
                                account.value
                            );
                            notify.success(
                                `Successfully requested session user and selected account.`
                            );
                            return this;
                        }
                    }

                    // Otherwise, fail to request the previous session.
                    throw new Error(`Failed to request session selections.`);
                } catch (error) {
                    notify.failure(error);
                }
            } else {
                notify.warning(`Cached user information.`);
            }
        } finally {
            console.groupEnd();
        }
        return this;
    }
}

// DEFAULT
export default useECNBProfile;
