import ResourceType from '@/enums/ResourceType';

/**
 * Create a specialized resource instance.
 *
 * @implements {globalThis.Resource.Typeable<'payload'>}
 * @implements {globalThis.Resource.Attributable<PayloadData>}
 */
export class TimezonePayload {
    // <!-- TYPE ALIASES -->

    /** @typedef {{ timezone: TimeZone.Identifier, timezone_type: 3 }} PayloadData */

    // <!-- STATIC FACTORY METHODS -->

    /**
     * Create resource instance from passed attributes.
     *
     * @param {Partial<PayloadData>} attributes
     * @returns {TimezonePayload}
     */
    static create(attributes) {
        return new TimezonePayload(attributes);
    }

    /**
     * Create resource instance from passed attributes.
     *
     * @param {TimeZone.Identifier} data
     * @returns {TimezonePayload}
     */
    static fromValue(data) {
        const attributes = TimezonePayload.attributesFromValue(data);
        return TimezonePayload.create(attributes);
    }

    // <!-- STATIC UTILITY METHODS -->

    /** @type {Readonly<PayloadData>} */
    static get defaults() {
        return {
            timezone: 'UTC',
            timezone_type: 3,
        };
    }

    /**
     * Create resource attributes from the model data.
     *
     * @param {TimeZone.Identifier} timezone
     * @returns {Partial<PayloadData>}
     */
    static attributesFromValue(timezone) {
        // Return the created state.
        return { timezone };
    }

    // <!-- CONSTRUCTOR -->

    /**
     * Create resource instance.
     *
     * @param {Partial<PayloadData>} attributes
     */
    constructor(attributes) {
        // Bind the type.
        this._type = ResourceType.Payload;
        Object.defineProperty(this, '_type', { enumerable: false });

        // Bind the attributes.
        this._attributes = /** @type {PayloadData} */ (
            Object.assign(TimezonePayload.defaults, attributes)
        );
        Object.defineProperty(this, '_attributes', { enumerable: false });

        // Bind the enumerable properties. (Uses dynamic getters/setters).
        for (const key in TimezonePayload.defaults) {
            Object.defineProperty(this, key, {
                ...Object.getOwnPropertyDescriptor(
                    TimezonePayload.prototype,
                    key
                ),
                enumerable: true,
            });
        }
    }

    /** Displays the specified tag when printing to the console. */
    get [Symbol.toStringTag]() {
        return 'Timezone::Payload';
    }

    // <!-- RESOURCE INTERFACE -->

    /** Get shallow copy of this instance as a resource. */
    toPayload() {
        return this.clone();
    }

    /** Get shallow copy of this instance as a resource. */
    toValue() {
        return this.timezone;
    }

    // <!-- ATTRIBUTABLE INTERFACE  -->

    /**
     * Ensure the specified key exists on this model.
     *
     * @type {Resource.Attributable<PayloadData>['exists']}
     */
    exists(key) {
        if (key in this._attributes) {
            const value = this._attributes[key];
            return value !== null && value !== undefined;
        }
        return false;
    }

    /**
     * Get a single attribute value by key.
     * - Attribute values of `undefined` or `null` are returned directly when no default value is supplied.
     *
     * @type {Resource.Attributable<PayloadData>['get']}
     */
    get(key) {
        // Get and return the attribute value.
        return this._attributes[key];
    }

    /**
     * Get a single attribute value by key.
     * - Attribute values of `undefined` or `null` are mapped to the default value using a supplier.
     *
     * @type {Resource.Attributable<PayloadData>['getOr']}
     */
    getOr(key, supplier) {
        // Get the attribute value.
        const value = this._attributes[key];

        // If the value is defined and non-null, return it.
        if (value !== null && value !== undefined) {
            return /** @type {any} */ (value);
        }

        // Case: defaultValue is a function; return its result.
        return supplier.call();
    }

    /**
     * Get a single attribute value by key.
     * - Attribute values of `undefined` or `null` are mapped to the default value.
     *
     * @type {Resource.Attributable<PayloadData>['getOrElse']}
     */
    getOrElse(key, defaultValue) {
        // Get the attribute value.
        const value = this._attributes[key];

        // If the value is defined and non-null, return it.
        if (value !== null && value !== undefined) {
            return value;
        }

        // Case: defaultValue is a non-nullable value.
        return defaultValue;
    }

    /**
     * Set a single attribute key/value pair.
     *
     * @see {@link delete} For more information on how to clear key/value pairs.
     * @template {keyof PayloadData} [Key=never]
     * @template {PayloadData[Key]} [Value=never]
     * @param {Key} key
     * @param {Value} value
     * @returns {this}
     */
    set(key, value) {
        this._attributes[key] = value;
        return this;
    }

    /**
     * Set a single attribute value by key to `null` or its default value, if non-nullable.
     *
     * @template {keyof PayloadData} [Key=never]
     * @param {Key} key
     * @returns {this}
     */
    delete(key) {
        this._attributes[key] = null;
        return this;
    }

    /**
     * Set multiple attributes, excluding attributes where mass-assignment is disabled.
     *
     * @param {Partial<PayloadData>} attributes
     * @returns {this}
     */
    fill(attributes) {
        // Case: Some keys are fillable.
        for (const key in attributes) {
            if (key in this._attributes) {
                this.set(
                    /** @type {keyof PayloadData} */ (key),
                    attributes[key]
                );
            }
        }
        // Return fluent interface reference when done.
        return this;
    }

    /**
     * Applies a transformation to the resource attributes.
     *
     * @type {Resource.Attributable<PayloadData>['extract']}
     */
    extract(callback) {
        return callback?.apply(this, [this._attributes]);
    }

    /** Get shallow copy. */
    clone() {
        return TimezonePayload.fromValue(this.timezone);
    }

    // <!-- ATTRIBUTE::PROPERTIES -->

    get timezone() {
        return this._attributes['timezone'];
    }

    set timezone(value) {
        this._attributes['timezone'] = value;
    }

    get timezone_type() {
        return this._attributes['timezone_type'];
    }
}
