// <!-- UTILITIES -->
import { isDateTimeLocalString } from '@/utils/typeof';
import { parseISO } from 'date-fns';

// <!-- TYPES -->
/** @typedef {import('@/utils/date').IDate} IDate */ //TODO: Enhance with global types.

/**
 * Parse the given string in datetime-local format and return an instance of Date. If the argument isn't a string, the function cannot parse the string or the values are invalid, it returns Invalid Date.
 * @param {String} argument The value to convert.
 * @param {Object} [options] Object containing options.
 * @param {0 | 1 | 2} [options.additionalDigits] Number of extended year digits to include. Defaults to `2`.
 * @returns {Date} The parsed date in the local timezone, without any timezone offset information.
 */
export const parseLocal = (argument, options = {}) => {
    // <!-- VALIDATION -->
    const isValidPattern = isDateTimeLocalString(argument);
    if (isValidPattern === true) {
        // HACK: `datetime-local` is a SUBSET of ISO without a timezone offset
        //   Since we confirm the string doesn't have a timezone offset in the RegExp check above,
        //   we can now use the existing API without importing the large `parse` method.
        const date = parseISO(argument, options);
        return date;
    }
    // Return invalid date.
    return new Date(NaN);
};

// <!-- DEFAULT -->
export default parseLocal;
