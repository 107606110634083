// <!-- ENUMS -->

/** @type {Treeview.CheckModes} */
export const CheckModes = Object.freeze({
    auto: 0,
    manual: 1,
});

// <!-- GENERIC TYPE GUARDS -->

/** @type {Treeview.Guards.CheckModeSelectorGuard} */
const isCheckModeSelector = (value) => {
    return isCheckModeKey(value) || isCheckMode(value);
};

/** @type {Treeview.Guards.CheckModeKeyGuard} */
const isCheckModeKey = (value) => {
    return (
        typeof value === 'string' &&
        !!Object.keys(CheckModes).find((k) => k === value)
    );
};

/** @type {Treeview.Guards.CheckModeGuard} */
const isCheckMode = (value) => {
    return (
        typeof value === 'number' &&
        !!Object.values(CheckModes).find((m) => m === value)
    );
};

// <!-- SPECIFIC TYPE GUARDS -->

/** @type {Treeview.Guards.SpecificCheckModeKeyGuard} */
const isSpecificCheckModeKey = (value, key) => {
    return isCheckModeKey(key) && isCheckModeKey(value) && value === key;
};

/** @type {Treeview.Guards.SpecificCheckModeGuard} */
const isSpecificCheckMode = (value, mode) => {
    return isCheckMode(mode) && isCheckMode(value) && value === mode;
};

/** @type {(value: unknown) => value is typeof CheckModes['auto']} */
const isAutoCheckMode = (value) =>
    isCheckModeSelector(value) &&
    isSpecificCheckMode(getCheckMode(value), CheckModes.auto);

/** @type {(value: unknown) => value is typeof CheckModes['manual']} */
const isManualCheckMode = (value) =>
    isCheckModeSelector(value) &&
    isSpecificCheckMode(getCheckMode(value), CheckModes.manual);

// <!-- SELECTORS -->

/**
 * @type {Treeview.Selectors.CheckModeKeySelector}
 * Get the check mode key associated with the provided selector.
 */
const getCheckModeKey = (selector) => {
    // TEST if already a key.
    if (isCheckModeKey(selector)) {
        // RETURN valid key.
        return selector;
    }

    if (isCheckMode(selector)) {
        // GET the key from the check modes.
        const [modeKey = undefined] = Object.entries(CheckModes).find(
            ([_, value]) => value === selector
        );
        // RETURN matched check mode key.
        return isCheckModeKey(modeKey) ? modeKey : undefined;
    }

    // RETURN invalid code.
    return undefined;
};

/**
 * @type {Treeview.Selectors.CheckModeSelector}
 * Get the check mode associated with the provided selector.
 */
const getCheckMode = (selector) => {
    // TEST if already a mode.
    if (isCheckMode(selector)) {
        // RETURN valid mode.
        return selector;
    }

    if (isCheckModeKey(selector)) {
        // GET the mode from the check modes.
        const [_ = undefined, mode = undefined] = Object.entries(
            CheckModes
        ).find(([key]) => key === selector);
        // RETURN matched check mode.
        return isCheckMode(mode) ? mode : undefined;
    }

    // RETURN invalid code.
    return undefined;
};

/**
 * Namespace of check mode related helpers.
 */
export class CheckMode {
    static get DefaultMode() {
        return CheckModes.auto;
    }

    // <!-- GENERIC TYPE GUARDS -->
    static isKey = isCheckModeKey;
    static isMode = isCheckMode;
    static isSelector = isCheckModeSelector;

    // <!-- SPECIFIC TYPE GUARDS -->
    static isSpecificKey = isSpecificCheckModeKey;
    static isSpecificMode = isSpecificCheckMode;
    static isAuto = isAutoCheckMode;
    static isManual = isManualCheckMode;

    // <!-- SELECTORS -->
    static getKey = getCheckModeKey;
    static getMode = getCheckMode;
}

// <!-- DEFAULT -->
export default CheckMode;
