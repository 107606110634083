// <!-- UTILITIES -->
import { lightFormat, toDate } from 'date-fns';

// <!-- TYPES -->
/** @typedef {import('@/utils/date').IDate} IDate */ //TODO: Enhance with global types.

/** @typedef {Object} */
const Pattern = {
    complete: {
        seconds: `yyyy-MM-dd'T'HH:mm:ss`,
        ms: `yyyy-MM-dd'T'HH:mm:ss.sss`,
    },
    date: {
        seconds: `yyyy-MM-dd`,
        ms: `yyyy-MM-dd`,
    },
    time: {
        seconds: `HH:mm:ss`,
        ms: `HH:mm:ss.sss`,
    },
};

/**
 * Return the formatted datetime-local format string for the provided {@link Date}. Options may be passed to control the parts and notations of the date.
 * @param {IDate} date The original date.
 * @param {Object} [options] Object with options.
 * @param {'complete' | 'date' | 'time'} [options.representation] Format date, local time, or both.
 * @param {'seconds' | 'ms'} [options.precision] If `ms`, includes the milliseconds component. Otherwise, ends at the `seconds` precision.
 * @returns {String} Formatted datetime (or subset) without timezone information.
 */
export const formatLocal = (date, options = {}) => {
    // <!-- DESTRUCTURE -->
    const { representation = 'complete', precision = 'ms' } = options ?? {};

    // <!-- DEFINE -->
    const input = toDate(date); // Ensure it's a valid Date object.
    const pattern = Pattern[representation][precision];

    // <!-- EXPOSE -->
    const formatted = lightFormat(input, pattern);
    return formatted;
};

// <!-- DEFAULT -->
export default formatLocal;
