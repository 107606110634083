// <!-- UTILITY -->
import { Enum } from '@/utils/enums';

/** @type {Enum<AlertCategories> & AlertCategories} */
export const AlertType = Enum.create({
    info: 'info',
    success: 'success',
    failure: 'error',
    error: 'error',
    warning: 'warning',
});

export default AlertType;
