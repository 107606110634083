/**
 * Type-safe, dynamic Enums with intellisense support.
 */

// <!-- TYPES -->
/** @typedef {string|number|symbol} EnumKey */
/** @typedef {string|number|boolean|symbol} EnumLiteral */

/**
 * @template {unknown} T
 * @template {unknown} [V=never]
 * @typedef {T extends boolean ? T extends true ? "TRUE" : V : V} TrueOrElse
 */

/**
 * @template {unknown} T
 * @template {unknown} [V=never]
 * @typedef {T extends boolean ? T extends false ? "FALSE" : V : V} FalseOrElse
 */

/**
 * @template {EnumLiteral} T
 * @typedef {TrueOrElse<T, FalseOrElse<T, T>>} SafeKey
 */

/**
 * @template {EnumLiteral} T Enum key types.
 * @typedef {{ [K in T as SafeKey<K> extends EnumKey ? SafeKey<K> : string & K]: K }} DynamicEnum
 */

/**
 * @template {EnumLiteral} K Enum key types.
 * @template {EnumLiteral} V Enum key types.
 * @typedef {[ DynamicEnum<K>, DynamicEnum<V> ]} DynamicEnumGrid
 */

/**
 * @template {EnumLiteral} K Enum key types.
 * @template {EnumLiteral} V Enum key types.
 * @typedef {{ x: DynamicEnum<K>, y: DynamicEnum<V> }} DynamicEnumDict
 */

/** Used when the key is 'truthy'. @readonly */
const TRUE = /** @type {const} */ ('TRUE');

/** Used when the key is 'falsy'. @readonly */
const FALSE = /** @type {const} */ ('FALSE');

/**
 * Narrow key type to acceptable value.
 *
 * @param {EnumLiteral} key Value to parse as a key.
 * @returns {EnumKey}
 */
const useSafeKey = (key) => {
    if (typeof key === 'string') {
        return /** @type {string} */ (key);
    }
    if (typeof key === 'boolean') {
        return /** @type {string} */ (Boolean(key) ? TRUE : FALSE);
    }
};
// TODO - Enhance with global types.
/**
 * Factory functions for {@link DynamicEnum} structure.
 */
export const DynamicEnumFactory = () => {
    /**
     * @template {EnumLiteral} T
     * @param {readonly T[]} keys
     * @returns {{ [K in T as SafeKey<K> extends EnumKey ? SafeKey<K> : string & K]: K }}
     * @example
     * const KEYS = DynamicEnumFactory().fromKeys(['a', true, 'C SPACE', 4]);
     * KEYS.a; // => "a"
     * KEYS.TRUE; // => true
     * KEYS["C SPACE"]; // => "C SPACE"
     * KEYS[4]; // => 4
     */
    const fromKeys = (keys) => {
        const target = Object.create(null);
        for (const key of keys) {
            target[useSafeKey(key)] = key;
        }
        return target;
    };

    /**
     * @template {EnumLiteral} K
     * @template {EnumLiteral} V
     * @template {readonly [K, V]} Pair
     * @param {readonly Pair[]} pairs
     * @returns {{ [T in Pair[0] as SafeKey<T>]: readonly Pair[1] }}
     * @example
     * const KEYS = DynamicEnumFactory().fromPairs([['a', 1], ['b', 2]]);
     * KEYS.a; // => "a"
     * KEYS.TRUE; // => true
     * KEYS["C SPACE"]; // => "C SPACE"
     * KEYS[4]; // => 4
     */
    const fromPairs = (pairs) => {
        const target = Object.create(null);
        for (const [key, value] of pairs) {
            target[useSafeKey(key)] = value;
        }
        return target;
    };

    /**
     * @template {EnumLiteral} T Enum types.
     * @param {readonly T[]} array Immutable array.
     * @return {DynamicEnum<T>}
     * @example
     * const letters = DynamicEnumFactory().fromArray(["a", "b_2", "C SPACE", true]);
     * letters.a; // => "a"
     * letters.b_2; // => "b_2"
     * letters["C SPACE"]; // => "C SPACE"
     * letters.TRUE; // => true
     */
    const fromArray = (array) => {
        const target = Object.create(null);
        return array.reduce((target, key) => {
            target[useSafeKey(key)] = key;
            return target;
        }, target);
    };

    return {
        fromKeys,
        fromArray,
        fromPairs,
    };
};

/**
 * Factory functions for {@link DynamicEnumGrid} structure.
 */
export const DynamicEnumGridFactory = () => {
    // Get support factories.
    const { fromArray } = DynamicEnumFactory();

    /**
     * @template {EnumLiteral} K Domain types.
     * @template {EnumLiteral} V Codomain types.
     * @param {readonly K[]} domain Domain array.
     * @param {readonly V[]} codomain Codomain array.
     * @return {[ DynamicEnum<K>, DynamicEnum<V> ]}
     * @example
     * const grid = DynamicEnumGridFactory().fromData(["a", "b", "c"], [1, 2, 3]);
     * grid[0].A; // => 'a'
     * grid[1][2]; // => 2
     * grid[0].C; // => 'c'
     */
    const fromData = (domain, codomain) => {
        return [fromArray(domain), fromArray(codomain)];
    };

    /**
     * @template {EnumLiteral} K Domain types.
     * @template {EnumLiteral} V Codomain types.
     * @param {readonly (readonly [K, V])[]} pairs
     * @return {[ DynamicEnum<K> , DynamicEnum<V> ]}
     * const grid = DynamicEnumGridFactory().fromPairs([['a', 1], ['b', 2], ['c', 3]]);
     * grid[0].a; // => 'a'
     * grid[1][2]; // => 2
     * grid[0].c; // => 'c'
     */
    const fromPairs = (pairs) => {
        const domain = pairs.map(([x, _]) => x);
        const codomain = pairs.map(([_, y]) => y);
        return fromData(domain, codomain);
    };

    return {
        fromData,
        fromPairs,
    };
};

/**
 * Factory functions for {@link DynamicEnumDict} structure.
 */
export const DynamicEnumDictFactory = () => {
    // Supporting factories.
    const { fromArray } = DynamicEnumFactory();

    /**
     * @template {EnumLiteral} K Domain types.
     * @template {EnumLiteral} V Codomain types.
     * @param {readonly K[]} domain Domain array.
     * @param {readonly V[]} codomain Codomain array.
     * @return {{ x: DynamicEnum<K>, y: DynamicEnum<V> }}
     * @example
     * const dict = DynamicEnumDictFactory().fromData(["a", "b", "c"], [1, 2, 3]);
     * dict.x.a; // => 'a'
     * dict.y[2]; // => 2
     * dict.x.c; // => 'c'
     */
    const fromData = (domain, codomain) => {
        const dx = fromArray(domain);
        const dy = fromArray(codomain);
        return {
            x: dx,
            y: dy,
        };
    };

    /**
     * @template {EnumLiteral} K Domain types.
     * @template {EnumLiteral} V Codomain types.
     * @param {readonly [K, V][]} pairs
     * @return {{ x: DynamicEnum<K>, y: DynamicEnum<V> }}
     * const dict = DynamicEnumDictFactory().fromPairs([['a', 1], ['b', 2], ['c', 3]]);
     * dict.x.a; // => 'a'
     * dict.y[2]; // => 2
     * dict.x.c; // => 'c'
     */
    const fromPairs = (pairs) => {
        const domain = pairs.map(([x, _]) => x);
        const codomain = pairs.map(([_, y]) => y);
        return fromData(domain, codomain);
    };

    return {
        fromData,
        fromPairs,
    };
};
