<template>
    <!-- ACTIONS -->
    <div
        v-if="!!$slots.default"
        :class="[
            'modal__actions',
            'flex flex-col-reverse justify-center items-center gap-y-2', // mobile only
            'sm:flex-grow sm:flex-wrap sm:justify-start sm:items-center sm:flex-row-reverse sm:gap-x-2',
        ]"
    >
        <slot
            :disabled="disabled"
            :busy="busy"
        >
            <!-- EXAMPLE ACTIONS -->
            <!-- <ModalButton
                type="button"
                :theme="Theme.danger"
                @click.stop="$log(`[debug::button] > ${Theme.danger}`, $event)"
            >
                <template #default>
                    <span>{{ `Danger` }}</span>
                </template>
            </ModalButton>
            <ModalButton
                type="button"
                :theme="Theme.error"
                @click.stop="
                    $log(
                        `[debug::button] > ${Theme.error} || ${Theme.failure}`,
                        $event
                    )
                "
            >
                <template #default>
                    <span>{{ `Error` }}</span>
                </template>
            </ModalButton>
            <ModalButton
                type="button"
                :theme="Theme.success"
                @click.stop="$log(`[debug::button] > ${Theme.success}`, $event)"
            >
                <template #default>
                    <span>{{ `Success` }}</span>
                </template>
            </ModalButton>
            <ModalButton
                type="button"
                :theme="Theme.warning"
                @click.stop="$log(`[debug::button] > ${Theme.warning}`, $event)"
            >
                <template #default>
                    <span>{{ `Warning` }}</span>
                </template>
            </ModalButton>
            <ModalButton
                type="button"
                :theme="Theme.info"
                @click.stop="$log(`[debug::button] > ${Theme.info}`, $event)"
            >
                <template #default>
                    <span>{{ `Info` }}</span>
                </template>
            </ModalButton>
            <ModalButton
                type="button"
                :theme="Theme.secondary"
                @click.stop="
                    $log(`[debug::button] > ${Theme.secondary}`, $event)
                "
            >
                <template #default>
                    <div
                        class="inline-flex justify-center items-center gap-x-2"
                    >
                        <AcademicCapIcon class="h-4 w-4" />
                        <span>{{ `Secondary` }}</span>
                    </div>
                </template>
            </ModalButton>
            <ModalButton
                type="button"
                :theme="Theme.primary"
                @click.stop="$log(`[debug::button] > ${Theme.primary}`, $event)"
            >
                <template #default>
                    <div
                        class="inline-flex justify-center items-center gap-x-2"
                    >
                        <AcademicCapIcon class="h-4 w-4" />
                        <span>{{ `Primary` }}</span>
                    </div>
                </template>
            </ModalButton>
            <ModalButton
                type="button"
                :theme="Theme.white"
                @click.stop="$log(`[debug::button] > ${Theme.white}`, $event)"
            >
                <template #default>
                    <span>{{ `White` }}</span>
                </template>
            </ModalButton>
            <ModalButton
                type="button"
                :theme="Theme.black"
                @click.stop="$log(`[debug::button] > ${Theme.black}`, $event)"
            >
                <template #default>
                    <span>{{ `Black` }}</span>
                </template>
            </ModalButton> -->
        </slot>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent } from 'vue';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'ModalActions',
        props: {
            /** If `true`, button actions are disabled. */
            disabled: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            /** If `true`, button actions are disabled and busy. */
            busy: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
        },
    });
</script>
