import {
    useFontVariant,
    useBorderVariant,
    useOutlineVariant,
    useMarginsVariant,
    usePaddingsVariant,
    useHeightVariant,
    useWidthVariant,
    useDimensionsVariant,
} from './useButtonStyles';

/**
 * useButtonSizes.js
 *
 * This composable gives access to specific size presets, as defined below.
 */

/**
 * Example preset size.
 */
// export const example = [
//     // Default size settings.
//     ...defaultSizes,
//     // Font size variants.
//     ...useFontVariant({ variant: 'base', size: 'xs' }),
//     // Border size variants.
//     ...useBorderVariant({ variant: 'base', size: 2 }),
//     // Outline size variants.
//     ...useOutlineVariant({ variant: 'base', offset: 2, size: 2 }),
//     // Margin variants.
//     ...useMarginsVariant({ variant: 'base', m: 1 }),
//     // Padding variants.
//     ...usePaddingsVariant({ variant: 'base', px: 2.5, py: 1.5 }),
//     // Width variants.
//     ...useWidthVariant({ variant: 'base', max: 5 }),
//     // Height variants.
//     ...useHeightVariant({ variant: 'base', max: 10 }),
// ];

/**
 * Default size CSS for all buttons.
 */
export const defaultSizes = [
    'text-lg',
    'border-2',
    'focus:ring-2',
    'focus:ring-offset-2',
    'max-h-10',
    'px-4',
    'py-2',
];

/**
 * Tiny (xs) size preset.
 */
export const xs = [
    // Font size variants.
    ...useFontVariant({ variant: 'base', size: 'xs' }),
    // Border size variants.
    ...useBorderVariant({ variant: 'base', size: 1 }),
    // Outline size variants.
    ...useOutlineVariant({ variant: 'base', offset: 2, size: 2 }),
    // Padding variants.
    ...usePaddingsVariant({ variant: 'base', px: 2.5, py: 1.5 }),
    // Height variants.
    ...useHeightVariant({ variant: 'base', max: 8 }),
];

/**
 * Small (sm) size preset.
 */
export const sm = [
    // Font size variants.
    ...useFontVariant({ variant: 'base', size: 'sm' }),
    /* Font leading from Tailwind UI. (Only applies to sm) */
    'leading-4',
    // Padding variants.
    ...usePaddingsVariant({ variant: 'base', px: 2.5, py: 1.5 }),
    // Height variants.
    ...useHeightVariant({ variant: 'base', max: 10 }),
];

/**
 * Medium (md) size preset.
 */
export const md = [
    // Font size variants.
    ...useFontVariant({ variant: 'base', size: 'sm' }),
    // Padding variants.
    ...usePaddingsVariant({ variant: 'base', px: 3, py: 2 }),
    // Height variants.
    ...useHeightVariant({ variant: 'base', max: 10 }),
];

/**
 * Large (lg) size preset.
 */
export const lg = [
    // Font size variants.
    ...useFontVariant({ variant: 'base', size: 'base' }),
    // Padding variants.
    ...usePaddingsVariant({ variant: 'base', px: 4, py: 3 }),
    // Height variants.
    ...useHeightVariant({ variant: 'base', max: 12 }),
];

/**
 * Huge (xl) size preset.
 */
export const xl = [
    // Font size variants.
    ...useFontVariant({ variant: 'base', size: 'lg' }),
    // Padding variants.
    ...usePaddingsVariant({ variant: 'base', px: 6, py: 3 }),
    // Height variants.
    ...useHeightVariant({ variant: 'base', max: 20 }),
];
