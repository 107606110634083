// <!-- UTILITIES -->
import { Maybe } from 'true-myth/dist/maybe';
import { AttributeFactory } from './AttributeFactory';

/**
 * Base resource class. Provides static utilities to subclasses.
 *
 * @template {Resource.Type} [T=never]
 * @template {{ [key: string]: any }} [Data = never]
 * @implements {Resource.Base<T, Data>}
 */
export class BaseResource {
    // <!-- STATIC UTILITY METHODS -->

    /** Defines suite of coalesce utilities. */
    static coalesce = Object.freeze({
        /**
         * Get the finite, non-null, real integer from the input string or number.
         *
         * @param {string|number} amount
         * @returns
         */
        count: (amount) => {
            // Convert into a number.
            const value =
                typeof amount === 'string' ? Number.parseInt(amount) : amount;

            // Return value, when valid.
            if (
                value !== null &&
                value !== undefined &&
                !Number.isNaN(value) &&
                Number.isFinite(value) &&
                Number.isSafeInteger(value)
            ) {
                return value;
            }

            // If null, NaN, or infinite, return null.
            return null;
        },
    });

    /** Defines suite of parser utilities. */
    static parse = Object.freeze({
        /**
         * Parse a price field into a float.
         *
         * @param {string|number} [value]
         * @returns
         */
        price: (value) => {
            // Convert into float.
            const cost =
                typeof value === 'string' ? Number.parseFloat(value) : value;

            // Determine if valid.
            const isValid =
                value !== null &&
                value !== undefined &&
                !Number.isNaN(cost) &&
                Number.isFinite(cost);

            // Return cast when valid. Otherwise, null.
            return isValid ? cost : null;
        },
        /**
         * Parse the ISO-8601 datestring into a `Date` instance.
         *
         * @param {string} iso
         */
        dateString: (iso) => {
            const maybeISO = Maybe.of(iso);
            return maybeISO.isJust ? new Date(maybeISO.value) : null;
        },
    });

    /** Defines suite of serializer utilities. */
    static serialize = Object.freeze({
        /**
         * Serialize the price field.
         *
         * @param {number} [value]
         * @returns
         */
        priceAsString: (value) => (value ? `${value}` : null),
        /**
         * Parse the `Date` into an ISO-8601 string.
         *
         * @param {Date} date
         */
        dateAsISOString: (date) => {
            return date?.toISOString();
        },
    });

    // <!-- CONSTRUCTOR -->

    /**
     * Construct an instance.
     *
     * @param {T} type
     * @param {Data} attributes
     */
    constructor(type, attributes) {
        // Assign the type information.
        this._type = type;
        Object.defineProperty(this, '_type', { enumerable: false });

        // Assign the attributes information.
        this._attributes = attributes;
        Object.defineProperty(this, '_attributes', { enumerable: false });
    }

    // <!-- TYPEABLE INTERFACE  -->

    /**
     * Assert this component represents a payload instance type.
     * @returns {this is this & { _type: 'payload' }}
     */
    isPayload() {
        return this._type === 'payload';
    }

    /**
     * Assert this component represents a model instance type.
     * @returns {this is this & { _type: 'model' }}
     */
    isModel() {
        return this._type === 'model';
    }

    // <!-- ATTRIBUTABLE INTERFACE  -->

    /**
     * Ensure the specified key exists on this model.
     *
     * @type {Resource.Attributable<Data>['exists']}
     */
    exists(key) {
        if (key in this._attributes) {
            const value = this._attributes[key];
            return value !== null && value !== undefined;
        }
        return false;
    }

    /**
     * Get a single attribute value by key.
     * - Attribute values of `undefined` or `null` are returned directly when no default value is supplied.
     *
     * @type {Resource.Attributable<Data>['get']}
     */
    get(key) {
        // Get and return the attribute value.
        return this._attributes[key];
    }

    /**
     * Get a single attribute value by key.
     * - Attribute values of `undefined` or `null` are mapped to the default value using a supplier.
     *
     * @type {Resource.Attributable<Data>['getOr']}
     */
    getOr(key, supplier) {
        // Get the attribute value.
        const value = this._attributes[key];

        // If the value is defined and non-null, return it.
        if (value !== null && value !== undefined) {
            return value;
        }

        // Case: defaultValue is a function; return its result.
        return supplier.call();
    }

    /**
     * Get a single attribute value by key.
     * - Attribute values of `undefined` or `null` are mapped to the default value.
     *
     * @type {Resource.Attributable<Data>['getOrElse']}
     */
    getOrElse(key, defaultValue) {
        // Get the attribute value.
        const value = this._attributes[key];

        // If the value is defined and non-null, return it.
        if (value !== null && value !== undefined) {
            return value;
        }

        // Case: defaultValue is a non-nullable value.
        return defaultValue;
    }

    /**
     * Set a single attribute key/value pair.
     *
     * @see {@link delete} For more information on how to clear key/value pairs.
     * @template {keyof Data} [Key=never]
     * @template {Data[Key]} [Value=never]
     * @param {Key} key
     * @param {Value} value
     * @returns {this}
     */
    set(key, value) {
        this._attributes[key] = value;
        return this;
    }

    /**
     * Set a single attribute value by key to `null` or its default value, if non-nullable.
     *
     * @template {keyof Data} [Key=never]
     * @param {Key} key
     * @returns {this}
     */
    delete(key) {
        this._attributes[key] = null;
        return this;
    }

    /**
     * Set multiple attributes, excluding attributes where mass-assignment is disabled.
     *
     * @param {Partial<Data>} attributes
     * @returns {this}
     */
    fill(attributes) {
        // Case: Some keys are fillable.
        for (const key in attributes) {
            if (key in this._attributes) {
                this.set(key, attributes[key]);
            }
        }
        // Return fluent interface reference when done.
        return this;
    }

    /**
     * Applies a transformation to the resource attributes.
     *
     * @type {Resource.Attributable<Data>['extract']}
     */
    extract(callback) {
        return callback?.apply(this, [this._attributes]);
    }

    // <!-- SERVICE METHODS -->

    /** Get shallow copy. */
    clone() {
        return /** @type {this} */ (
            new BaseResource(this._type, { ...this._attributes })
        );
    }

    /**
     * Map this resource into something else.
     * @template U
     * @template {(resource: this) => U} Callback
     * @param {Callback} callback
     */
    map(callback) {
        return callback.apply(this, [this]);
    }
}

// <!-- EXPORTS -->
export default BaseResource;
