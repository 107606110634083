// <!-- API -->
import { computedEager } from '@vueuse/core';

/**
 * Get access to a computed value containing the local timezone or a fallback.
 * @returns {Readonly<Vue.Ref<TimeZone.Identifier>>}
 */
export const useLocalTimezone = () =>
    computedEager(() => {
        /** @type {any} */
        const identifier = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;
        return identifier ?? 'UTC';
    });

// <!-- DEFAULT -->
export default useLocalTimezone;
