// <!-- UTILITIES -->
import is from '@sindresorhus/is';
import gte from 'lodash-es/gte';
import lte from 'lodash-es/lte';

/**
 * @class
 * Class containing namespaced static helpers for parsing Axis values.
 */
export class AxisValue {
    /** @type {(value: unknown) => value is undefined} Predicate test to see if `value` is strictly `undefined`. */
    static isStrictlyUndefined = (value) => value === undefined;

    /** @type {(value: unknown) => value is null} Predicate test to see if `value` is `undefined`, `null`, or an empty `String`. */
    static isMissing = (value) => is.nullOrUndefined(value) || value === '';

    /** @type {(value: unknown) => value is null} Predicate test to see if `value` is `undefined`, `null`, an empty `String`, or `NaN` */
    static isInvalid = (value) =>
        AxisValue.isMissing(value) || Number.isNaN(Number(value));

    /** @type {(value: String | Number) => Number} Cast `value` to a `Number`. */
    static castAsExtent = (value) =>
        AxisValue.isMissing(value) ? null : Number(value);

    /** @type {(value: String | Number) => String} Cast `value` to a `String`. */
    static castAsString = (value) => {
        if (AxisValue.isMissing(value)) {
            return '';
        } else if (value === Infinity) {
            return String(Infinity);
        } else if (value === -Infinity) {
            return String(-Infinity);
        } else {
            return String(value);
        }
    };

    /**
     * Serialize input string `value` into a number.
     * @param {String} value
     */
    static serialize = (value) => ({
        get asLower() {
            if (
                AxisValue.isMissing(value) ||
                value === '' ||
                value === '-Infinity' ||
                value === String(Number.MIN_VALUE)
            ) {
                return -Infinity;
            }
            return Number(value);
        },
        get asUpper() {
            if (
                AxisValue.isMissing(value) ||
                value === '' ||
                value === 'Infinity' ||
                value === String(Number.MAX_VALUE)
            ) {
                return Infinity;
            }
            return Number(value);
        },
    });

    /**
     * Deserialize `value` into a parseable input string.
     * @param {Number} value
     */
    static deserialize = (value) => ({
        get asLower() {
            if (AxisValue.isMissing(value) || value === -Infinity) {
                return String('-Infinity');
            }
            return String(value);
        },
        get asUpper() {
            if (AxisValue.isMissing(value) || value === Infinity) {
                return String('Infinity');
            }
            return String(value);
        },
    });

    /**
     * Parse input string as a numeric axis value.
     * @param {String | Number | '' | null} [value] Value to parse.
     * @param {Readonly<Partial<{ whenUndefined: Number, whenInvalid: Number }>>} [defaults] Default values to use.
     */
    static parse(value = undefined, defaults = {}) {
        // Get the default values.
        const { whenUndefined = null, whenInvalid = null } = defaults;

        // Get the parsed value.
        if (AxisValue.isStrictlyUndefined(value)) {
            // If strictly undefined, return `whenUndefined`.
            return whenUndefined;
        } else if (AxisValue.isInvalid(value)) {
            // If invalid, return `whenInvalid`.
            return whenInvalid;
        } else {
            // Cast to number and return.
            return AxisValue.castAsExtent(value);
        }
    }

    /**
     * Clamp value between inclusive bounds `[min, max]`.
     * @param {Number} value Parsed, safe value to clamp.
     * @param {Readonly<Partial<{ min: Number, max: Number }>>} [bounds] Inclusive boundaries.
     * @returns {Number} Clamped value.
     */
    static clamp(value, bounds = {}) {
        const { min = -Infinity, max = Infinity } = bounds;
        return Math.min(Math.max(value, min), max);
    }

    /**
     * Validate value based on provided params.
     * @param {Number} value Value to validate.
     * @param {Readonly<Partial<{ min: Number, max: Number }>>} [bounds] Inclusive boundaries.
     * @param {Readonly<Partial<{ whenUndefined: Number, whenInvalid: Number, whenBelowMinimum: Number, whenAboveMaximum: Number }>>} [defaults] Default values to use.
     */
    static validate(value, bounds, defaults) {
        // Get the default values.
        const { min = -Infinity, max = Infinity } = bounds ?? {};
        const {
            whenUndefined = null,
            whenInvalid = null,
            whenBelowMinimum = min,
            whenAboveMaximum = max,
        } = defaults;

        // Parse the value.
        const $value = AxisValue.parse(value, { whenUndefined, whenInvalid });
        const isValueDefined = !is.nullOrUndefined($value);

        // Check if clamping is necessary.
        const isMinNil = is.nullOrUndefined(min);
        const isMaxNil = is.nullOrUndefined(max);
        const isBoundsNil = isMinNil && isMaxNil;
        if (isBoundsNil) {
            // NOTE: No clamping will occur when both the min and max are undefined or null.
            return $value;
        }

        // Check if defined bounds make sense.
        const isMinDefined = !isMinNil;
        const isMaxDefined = !isMaxNil;
        const isBoundsDefined = !isMinNil && !isMaxNil;
        if (isBoundsDefined) {
            if (min > max) {
                // NOTE: If both min and max are provided, but are invalid, throw an error.
                throw new TypeError(
                    `Validation behavior is undefined. Minimum is greater than the maximum.`
                );
            }
        }

        /** `true` when `value >= min` and `value` is defined and `min` is defined. */
        const isValueGTEMin = isValueDefined && isMinDefined && gte(min);
        const isValueBelowMin = !isValueGTEMin;
        if (isMinDefined && isValueBelowMin) {
            // NOTE: Can only be below minimum when `min` is defined and `value <= min`.
            return whenBelowMinimum;
        }

        /** `true` when `value <= max` and `value` is defined and `max` is defined. */
        const isValueLTEMax = isValueDefined && isMaxDefined && lte(max);
        const isValueAboveMax = !isValueLTEMax;
        if (isMaxDefined && isValueAboveMax) {
            // NOTE: Can only be above maximum when `max` is defined and `value >= max`.
            return whenAboveMaximum;
        }

        // Return value clamped in bounds.
        return AxisValue.clamp($value, { min, max });
    }
}

// <!-- DEFAULT -->
export default AxisValue;
