/**
 * @class
 * Account state.
 */
export class AccountState {
    /**
     * Construct initial account state.
     */
    constructor() {
        /** @type {Organization.Model} */
        this.organization = null;
        /** @type {Account.Model} */
        this.account = null;
    }
    /** Does the organization state have a valid state assigned? */
    get hasOrganization() {
        return !!this.organization?.id;
    }
    /** Does the account state have a valid state assigned? */
    get hasAccount() {
        return !!this.account?.id;
    }
}
