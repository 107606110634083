// <!-- EXPORT -->
export { FormDataModel as DateRangeFormDataModel } from '@/utils/filters/daterange/FormDataModel';
export { StoreDataModel as DateRangeStoreDataModel } from '@/utils/filters/daterange/StoreDataModel';

// <!-- IMPORT -->
import { FormDataModel } from '@/utils/filters/daterange/FormDataModel';
import { StoreDataModel } from '@/utils/filters/daterange/StoreDataModel';

// <!-- DEFAULT -->
export default {
    FormDataModel,
    StoreDataModel,
};
