// <!-- API -->
import {
    SidebarFilterRecord,
    DateRangeFilter,
    LimitFilterRecord,
    ScaleFilterRecord,
    LimitFilter,
    ScaleFilter,
    LocationFilter,
    WeatherStationFilter,
    TimezoneFilter,
} from '@/utils/filters';

// <!-- COMPOSABLES -->
import { useLocalTimezone } from '@/hooks/timezone';

// <!-- UTILITIES -->
import { Tree } from '@/utils/tree';

// <!-- TYPES -->
// ==== SIDEBAR ====
/** @typedef {import('@/utils/filters').IAxisID} IAxisID */
/** @typedef {import('@/utils/filters').ISidebarFilterRecord} ISidebarFilterRecord */
// ==== AXIS ====
/** @typedef {import('@/utils/filters').AxisExtent} AxisExtent */
/** @typedef {import('@/utils/filters').AxisRangeFilterPropertyKey} AxisRangeFilterPropertyKey */
/** @typedef {import('@/utils/filters').IAxisRange} IAxisRange */
/** @typedef {import('@/utils/filters').IAxisModifier} IAxisModifier */
/** @typedef {import('@/utils/filters').IAxisRangeFilter} IAxisRangeFilter */
// ==== LIMITS ====
/** @typedef {import('@/utils/filters').ILimitFilter} ILimitFilter */
/** @typedef {import('@/utils/filters').ILimitFilterRecord} ILimitFilterRecord */
// ==== SCALES ====
/** @typedef {import('@/utils/filters').IScaleFilter} IScaleFilter */
/** @typedef {import('@/utils/filters').IScaleFilterRecord} IScaleFilterRecord */

/**
 * @class
 * Analysis state.
 */
export class AnalysisState {
    /**
     * Determine if the state is namespaced?
     */
    static get namespaced() {
        return true;
    }

    /**
     * Instantiate the initial state object using this helper.
     * @returns {{ filters: ISidebarFilterRecord }}
     */
    static initialState() {
        // Get the local timezone. This is only used once, for initializing the store.
        const localTimezone = useLocalTimezone();
        console.log(
            `[init::store] - Using the '${localTimezone.value}' time zone to initialize the store.`
        );

        // Compute the date range with the default preset.
        const { start, end } =
            DateRangeFilter.StoreDataModel.computePresetDateRange(
                'P1Y0S',
                Date.now(),
                localTimezone.value
            );
        console.dir({ start, end });

        // Prepare the formatter.
        const dateFormatter = new Intl.DateTimeFormat('en-CA', {
            timeZone: localTimezone.value,
        });

        // Initialize the daterange filter.
        const daterange = DateRangeFilter.StoreDataModel.create({
            start: dateFormatter.format(start),
            end: dateFormatter.format(end),
            checked: [],
        });
        console.dir(daterange);

        // Initialize the timezone filter.
        /** @type {import('@/utils/filters').ITimezoneFilter} Timezone filter. */
        const timezone = new TimezoneFilter({
            timezone: localTimezone.value,
            useAccountTimezone: true,
        });

        // Initialize the limits filter record.
        const limits = new LimitFilterRecord({
            temp: LimitFilter.temp({
                lower: -Infinity,
                upper: Infinity,
                checked: false,
            }),
            rh: LimitFilter.rh({
                lower: -Infinity,
                upper: Infinity,
                checked: false,
            }),
            dp: LimitFilter.dp({
                lower: -Infinity,
                upper: Infinity,
                checked: false,
            }),
        });

        // Initialize the scales filter record.
        const scales = new ScaleFilterRecord({
            temp: ScaleFilter.temp({
                lower: NaN, // 20,
                upper: NaN, // 90,
                checked: false,
            }),
            rh: ScaleFilter.rh({
                lower: NaN, // 20,
                upper: NaN, // 90,
                checked: false,
            }),
            dp: ScaleFilter.dp({
                lower: NaN, // 10,
                upper: NaN, // 80,
                checked: false,
            }),
        });

        // Initialize the locations filter.
        const locationTree = Tree.create({});
        const locations = new LocationFilter({ tree: locationTree });

        // Initialize the stations filter.
        const weatherStationTree = Tree.create({});
        const stations = new WeatherStationFilter({ tree: weatherStationTree });

        // Initialize the filter record.
        /** @type {ISidebarFilterRecord} Record containing sidebar filters. */
        const filters = new SidebarFilterRecord({
            daterange,
            timezone,
            limits,
            scales,
            locations,
            stations,
        });

        // <!-- EXPOSE -->
        return { filters };
    }

    /**
     * Construct initial state.
     */
    constructor() {
        // Prepare the initial state.
        const state = AnalysisState.initialState();

        // Assign the prepared state.
        /** @type {ISidebarFilterRecord} Analysis module's filters state. */
        this.filters = state.filters;
    }
}
