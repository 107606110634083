// <!-- PLUGINS -->
import { useAxios as axios } from '@/plugins/axios';

// <!-- TYPES -->
/** @typedef {globalThis.Account.Model} AccountResource */
import {
    LocationPayload,
    LocationResource,
} from '@/models/v1/locations/Location';
import {
    LocationHierarchyPayload,
    LocationHierarchyResource,
} from '@/models/v1/locations/LocationHierarchy';

// <!-- MODELS -->
import { Location, LocationHierarchy } from '@/models/v1/locations';

// <!-- ROUTES -->
const ROUTES = {
    GET_HIERARCHIES: (account) => `accounts/${account}/hierarchies`,
    GET_HIERARCHY: (account, hierarchy) =>
        `accounts/${account}/hierarchies/${hierarchy}`,
    CREATE_HIERARCHY: (account) => `accounts/${account}/hierarchies`,
    UPDATE_HIERARCHY: (account, hierarchy) =>
        `accounts/${account}/hierarchies/${hierarchy}`,
    DELETE_HIERARCHY: (account, hierarchy) =>
        `accounts/${account}/hierarchies/${hierarchy}`,
};

// <!-- REQUESTS -->
/**
 * Fetch all hierarchies associated with an institution account.
 * @param {Pick<AccountResource, 'id'>} account
 * @returns {Promise<{ hierarchies: LocationHierarchyResource[], unassignedLocations: LocationResource[] }>}
 */
export const fetchHierarchies = async (account = { id: 8 }) => {
    /** @type {import('axios').AxiosResponse<{ data: LocationHierarchyPayload[], unassigned_locations: LocationPayload[] }>} */
    const response = await axios().get(ROUTES.GET_HIERARCHIES(account.id));
    const { data, unassigned_locations } = response.data;
    // Convert and return response.
    return {
        hierarchies: data.map((node) =>
            new LocationHierarchy({ payload: node }).toResource()
        ),
        unassignedLocations: unassigned_locations.map((location) =>
            new Location({ payload: location }).toResource()
        ),
    };
};

/**
 * Fetch specified hierarchy by id for the supplied account.
 * @param {Pick<AccountResource, 'id'>} account
 * @param {Pick<LocationHierarchyResource, 'id'>} hierarchy
 */
export const fetchHierarchyById = async (
    account = { id: 8 },
    hierarchy = { id: 1 }
) => {
    /** @type {import('axios').AxiosResponse<{ data: LocationHierarchyPayload }>} */
    const response = await axios().get(
        ROUTES.GET_HIERARCHY(account.id, hierarchy.id)
    );
    const payload = response.data.data;
    return new LocationHierarchy({ payload }).toResource();
};

/**
 * Create new hierarchy node using request body content.
 * @param {Pick<AccountResource, 'id'>} account
 * @param {Pick<LocationHierarchyPayload, 'name'|'parent_id'> & { children: string[] }} request
 */
export const createHierarchy = async (
    account = { id: 8 },
    request = {
        name: 'Example Hierarchy (Root)',
        parent_id: null,
        children: [],
    }
) => {
    /** @type {import('axios').AxiosResponse<{ data: LocationHierarchyPayload }, typeof request>} */
    const response = await axios().post(
        ROUTES.CREATE_HIERARCHY(account.id),
        request
    );
    const payload = response.data.data;
    return new LocationHierarchy({ payload }).toResource();
};

/**
 * Update hierarchy using request body content.
 * @param {Pick<AccountResource, 'id'>} account
 * @param {Pick<LocationHierarchyResource, 'id'>} hierarchy
 * @param {Partial<Pick<LocationHierarchyPayload, 'name'>>} request
 */
export const updateHierarchyById = async (
    account = { id: 8 },
    hierarchy = { id: -1 },
    request = {
        name: 'Example Hierarchy (Root) (Updated)',
    }
) => {
    /** @type {import('axios').AxiosResponse<{ data: LocationHierarchyPayload }, typeof request>} */
    const response = await axios().post(
        ROUTES.UPDATE_HIERARCHY(account.id, hierarchy.id),
        request
    );
    const payload = response.data.data;
    return new LocationHierarchy({ payload }).toResource();
};

/**
 * Delete hierarchy by id for the supplied account.
 * @param {Pick<AccountResource, 'id'>} account
 * @param {Pick<LocationHierarchyResource, 'id'>} hierarchy
 */
export const deleteHierarchyById = async (
    account = { id: 8 },
    hierarchy = { id: 1 }
) => {
    /** @type {import('axios').AxiosResponse<{ status?: 'deleted', data: LocationHierarchyPayload }>} */
    const response = await axios().put(
        ROUTES.DELETE_HIERARCHY(account.id, hierarchy.id)
    );
    // NOTE: We don't currently handle the status field.
    // @ts-ignore
    const status = response.data.status;
    const payload = response.data.data;
    return new LocationHierarchy({ payload }).toResource();
};

// <!-- EXPORTS -->
export default {
    LocationHierarchy,
    LocationHierarchyPayload,
    LocationHierarchyResource,
    fetchHierarchies,
    fetchHierarchyById,
    createHierarchy,
    updateHierarchyById,
    deleteHierarchyById,
};
