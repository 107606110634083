/** Styles containing constants. */
const STYLES = /** @type {const} */ ({
    LABEL: ' block mb-1 font-bold text-sm',
    INNER: {
        WIDTH: ' max-w-md',
        BORDER: ' border border-gray-400',
        BASE: ' rounded-lg mb-1 overflow-hidden focus-within:border-blue-500',
    },
    INPUT: {
        WIDTH_FULL: ' w-full',
        HEIGHT_10: ' h-10',
        HEIGHT_50: ' h-50',
        BORDER: ' border',
        BORDER_NONE: ' border-none',
        BASE: ' px-3 rounded-lg text-base placeholder-gray-400 disabled:px-0',
        FILE: ' rounded-lg text-sm text-gray-700 placeholder-gray-400 disabled:px-0',
    },
    INPUT_ERROR:
        ' block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md',
});

// We'll create a classConfig object that contains the class lists we want
// for each sectionKey within a given input type
const classConfig = {
    // if we want to return some classes for _all_ section keys regardless of input type
    // we can put them here to later combine with any more specific section output.
    all: {
        outer: 'mb-5',
        help: 'text-xs text-gray-500',
        messages: 'list-none p-0 mt-1 mb-0',
        message: 'text-red-500 mb-1 text-xs',
    },
    // We'll put our specific `text` type styles here separated by
    // their `sectionKey`
    text: {
        label: STYLES.LABEL,
        inner: STYLES.INNER.WIDTH + STYLES.INNER.BORDER + STYLES.INNER.BASE,
        input:
            STYLES.INPUT.WIDTH_FULL +
            STYLES.INPUT.HEIGHT_10 +
            STYLES.INPUT.BORDER_NONE +
            STYLES.INPUT.BASE,
        inputError: STYLES.INPUT_ERROR,
    },
    password: {
        label: STYLES.LABEL,
        inner: STYLES.INNER.WIDTH + STYLES.INNER.BORDER + STYLES.INNER.BASE,
        input:
            STYLES.INPUT.WIDTH_FULL +
            STYLES.INPUT.HEIGHT_10 +
            STYLES.INPUT.BORDER_NONE +
            STYLES.INPUT.BASE,
        inputError: STYLES.INPUT_ERROR,
    },
    date: {
        label: STYLES.LABEL,
        inner: STYLES.INNER.WIDTH + STYLES.INNER.BORDER + STYLES.INNER.BASE,
        input:
            STYLES.INPUT.WIDTH_FULL +
            STYLES.INPUT.HEIGHT_10 +
            STYLES.INPUT.BORDER_NONE +
            STYLES.INPUT.BASE,
        inputError: STYLES.INPUT_ERROR,
    },
    [`datetime-local`]: {
        label: STYLES.LABEL,
        inner: STYLES.INNER.WIDTH + STYLES.INNER.BORDER + STYLES.INNER.BASE,
        input:
            STYLES.INPUT.WIDTH_FULL +
            STYLES.INPUT.HEIGHT_10 +
            STYLES.INPUT.BORDER_NONE +
            STYLES.INPUT.BASE,
        inputError: STYLES.INPUT_ERROR,
    },
    textarea: {
        label: STYLES.LABEL,
        inner:
            STYLES.INNER.WIDTH + STYLES.INNER.BORDER_NONE + STYLES.INNER.BASE,
        input:
            ' w-full max-w-md' +
            STYLES.INPUT.HEIGHT_50 +
            STYLES.INPUT.BORDER +
            STYLES.INPUT.BASE,

        inputError: STYLES.INPUT_ERROR,
    },
    file: {
        label: STYLES.LABEL,
        inner: STYLES.INNER.WIDTH,
        input:
            STYLES.INPUT.WIDTH_FULL +
            STYLES.INPUT.HEIGHT_10 +
            STYLES.INPUT.BORDER_NONE +
            STYLES.INPUT.FILE,
        inputError: STYLES.INPUT_ERROR,
    },
    select: {
        label: STYLES.LABEL,
        inner: STYLES.INNER.WIDTH,
        input:
            STYLES.INPUT.WIDTH_FULL +
            STYLES.INPUT.HEIGHT_10 +
            STYLES.INPUT.BORDER +
            STYLES.INPUT.BASE,

        inputError: STYLES.INPUT_ERROR,
    },
    radio: {
        legend: STYLES.LABEL,
    },
    number: {
        label: STYLES.LABEL,
        inner: STYLES.INNER.WIDTH + STYLES.INNER.BORDER + STYLES.INNER.BASE,
        input:
            STYLES.INPUT.WIDTH_FULL +
            STYLES.INPUT.HEIGHT_10 +
            STYLES.INPUT.BORDER_NONE +
            STYLES.INPUT.BASE,
        inputError: STYLES.INPUT_ERROR,
    },
};

// We need a helper function to convert our strings into objects
// because rootClasses must return a keyed object of booleans
/** @returns {Record<string, boolean>} */
function createClassObject(classString) {
    const classList = defaultClassObject({});
    classString.split(' ').forEach((className) => {
        classList[className] = true;
    });
    return classList;
}

// Lastly, put it all together using the information provided to the rootClasses
// function which is called once for each sectionKey in a FormKit input.
/** @type {import('@formkit/core').FormKitConfig["rootClasses"]} */
function rootClasses(sectionKey, node) {
    // grab the node `type` for easy reference
    const type = node.props.type;
    // get a matching class string from our classConfig object
    // if it exists. Otherwise return an empty
    const definitionClasses = classConfig?.[type]
        ? classConfig?.[type]?.[sectionKey]
        : '';
    // similarly get any matching "all" class lists that should be concatenated
    const allClasses = classConfig.all?.[sectionKey] || '';
    // combine the two class strings into a single string
    const classList = allClasses
        ? `${allClasses} ${definitionClasses} formkit-input`
        : definitionClasses;
    // finally, get an object constructed by our createClassObject function below
    // given the current sectionKey and matching definition. If we do not
    // have a matching definition then return an empty object
    /** @type {Record<string, boolean>} */
    const classObject = classList
        ? createClassObject(classList)
        : defaultClassObject({});
    // then return the classObject to be applied to the current sectionKey
    return classObject;
}

/**
 * Fixes class object type.
 * @param {Record<string, boolean>} value
 */
const defaultClassObject = (value) => {
    return value;
};

export default rootClasses;
