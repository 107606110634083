// <!-- API -->
import { useNARAFeature } from '@/utils/features';

// <!-- UTILITIES -->
const { isNARAEnabled } = useNARAFeature();

// <!-- HELPERS -->

/** Determine if this is in a production environment. */
const isProductionEnvironment = () => process.env.NODE_ENV === 'production';

/** Determine if this is in a local-API environment. */
const isLocalEnvironment = () =>
    process.env.VUE_APP_API_PREFIX.includes('localhost');

/** Determine if this is in a staging-API environment. */
const isDebugEnvironment = () => process.env.VUE_APP_DEBUG_AXIOS === 'true';

/** Determine if this is a NARA or Commercial environment. */
const isNARAEnvironment = () => {
    return isNARAEnabled.value === true;
};

/** Get the environment tags. */
const getEnvironmentTags = () => {
    const tags = [];

    // Build the tag list.
    tags.push(isLocalEnvironment() ? 'local' : '');
    tags.push(
        isProductionEnvironment()
            ? isDebugEnvironment()
                ? 'staging'
                : 'production'
            : 'development'
    );
    tags.push(isNARAEnvironment() ? 'nara' : 'commercial');

    // Return non-empty string tags.
    return tags.filter((tag) => tag.length > 0);
};

// <!-- COMPOSABLE -->

const { timeZone, locale, calendar } = Intl.DateTimeFormat().resolvedOptions();

/**
 * Get information about the current environment.
 */
export const useEnvironmentDetails = () => {
    const context = {
        node: process.env.NODE_ENV ?? 'unknown',
        environment: getEnvironmentTags().join('-'),
        timezone: {
            id: timeZone,
            calendar,
            locale,
        },
    };
    return {
        ...context,
        display: () => {
            console.log(
                `{ NodeJS: ${context.node} }, { Application: ${context.environment} }`
            );
            console.log(
                `{ Client Time Zone: ${context.timezone.id}, Calendar: ${context.timezone.calendar}, Locale: ${context.timezone.locale} }`
            );
        },
    };
};

// <!-- DEFAULT -->
export default useEnvironmentDetails;
