// <!-- ENUMS -->

/**
 * Allowed node state keys.
 * @type {typeof Treeview.NodeStates}
 */
export const NodeStates = Object.freeze({
    opened: 'opened',
    disabled: 'disabled',
    editable: 'editable',
    draggable: 'draggable',
    dropable: 'dropable',
    checked: 'checked',
    indeterminate: 'indeterminate',
    isLoading: 'isLoading',
});

// <!-- GENERIC TYPE GUARDS -->

/** @type {Treeview.Guards.NodeStateKeyGuard} */
const isNodeStateKey = (value) => {
    return typeof value === 'string' && value in NodeStates;
};

/** @type {Treeview.Guards.NodeStateGuard} */
const isNodeStateRecord = (value) => {
    return typeof value === 'object';
};

// <!-- SPECIFIC TYPE GUARDS -->

/** @type {Treeview.Guards.SpecificNodeStateKeyGuard} */
const isSpecificNodeStateKey = (value, key) =>
    isNodeStateKey(key) && isNodeStateKey(value) && value === key;

/** @type {Treeview.Guards.SpecificNodeStateValidator} */
const isSpecificNodeState = (state, key, value = true) =>
    isNodeStateKey(key) && isNodeStateRecord(state) && state[key] === value;

// <!-- SELECTORS -->

/** @type {Treeview.Selectors.NodeStateValidatorSelector} */
const getNodeStateValidator = (selector, expected = true) => {
    // TEST if key is valid.
    if (!isNodeStateKey(selector)) {
        // RETURN predicate that always returns false.
        console.warn(
            `The key '${selector}' does not exist on the state record. Results will always be false.`
        );
        return () => false;
    }
    /**
     * Prepared validator.
     * @param {Readonly<NodeState>} source
     * @returns {boolean}
     */
    const validator = (source) =>
        isSpecificNodeState(source, selector, expected);
    // RETURN prepared formatter.
    return validator;
};

// <!-- EXTRACTORS -->

/** @type {Treeview.Extractors.NodeStateSetExtractor} */
const getNodeStateAsSet = (state) => {
    const keys = /** @type {Treeview.NodeStateKey[]} */ (Object.keys(state));
    const enabled = keys.filter(
        (key) => isNodeStateKey(key) && state[key] === true
    );
    return new Set(enabled);
};

/** @type {Treeview.Extractors.NodeStateFlagExtractor} */
const getNodeStateByKey = (state, key) => getNodeStateAsSet(state).has(key);

// <!-- COMPARATORS -->

/** @type {Treeview.Comparators.NodeStateComparator} */
const compareNodeStates = (a, b) => {
    if (!isNodeStateRecord(a) || !isNodeStateRecord(b)) {
        // One is not a record!
        return false;
    }
    const _a = NodeState.getEnabled(a);
    const _b = NodeState.getEnabled(b);
    return _a.size === _b.size && [..._a].every((state) => _b.has(state));
};

// <!-- FACTORIES -->

/** @type {Treeview.Factories.NodeStateFactory} */
const createNodeState = (props) => {
    const defaults = /** @type {Treeview.NodeState} */ ({
        ...NodeState.DefaultState,
    });
    const instance = Object.assign({}, defaults, props);
    return instance;
};

// <!-- DUPLICATORS -->

/** @type {Treeview.Duplicators.NodeStateDuplicator} */
const cloneNodeState = (source) => {
    const instance = Object.assign({}, source);
    return instance;
};

// <!-- MUTATIONS -->

/** @type {Treeview.Mutations.NodeStateRecordMutation} */
const overrideNodeState = (source, patch) => {
    const target = cloneNodeState(source);
    const instance = Object.assign(target, patch);
    return instance;
};

/** @type {Treeview.Mutations.NodeStateMutation} */
const setNodeState = (source, key, value = true) => {
    const override = overrideNodeState(source, { [key]: value === true });
    return override;
};

/** @type {Treeview.Mutations.NodeStateValueMutation} */
const enableNodeState = (source, key) => setNodeState(source, key, true);

/** @type {Treeview.Mutations.NodeStateValueMutation} */
const disableNodeState = (source, key) => setNodeState(source, key, false);

// <!-- CLASS -->

/** @class Node state helpers. */
export class NodeState {
    static get DefaultState() {
        return Object.freeze({
            opened: false,
            checked: false,
            disabled: false,
            draggable: false,
            dropable: false,
            editable: false,
            indeterminate: false,
            isLoading: false,
        });
    }

    // <!-- GENERIC TYPE GUARDS -->
    static isKey = isNodeStateKey;
    static isRecord = isNodeStateRecord;

    // <!-- SPECIFIC TYPE GUARDS -->
    static isSpecificKey = isSpecificNodeStateKey;
    static isSpecificState = isSpecificNodeState;

    // <!-- SELECTORS -->
    static get isOpened() {
        return getNodeStateValidator(NodeStates.opened, true);
    }
    static get isClosed() {
        return getNodeStateValidator(NodeStates.opened, false);
    }
    static get isDisabled() {
        return getNodeStateValidator(NodeStates.disabled, true);
    }
    static get isEnabled() {
        return getNodeStateValidator(NodeStates.disabled, false);
    }
    static get isEditable() {
        return getNodeStateValidator(NodeStates.editable, true);
    }
    static get isNotEditable() {
        return getNodeStateValidator(NodeStates.editable, false);
    }
    static get isDraggable() {
        return getNodeStateValidator(NodeStates.draggable, true);
    }
    static get isNotDraggable() {
        return getNodeStateValidator(NodeStates.draggable, false);
    }
    static get isDropable() {
        return getNodeStateValidator(NodeStates.dropable, true);
    }
    static get isNotDropable() {
        return getNodeStateValidator(NodeStates.dropable, false);
    }
    static get isChecked() {
        return getNodeStateValidator(NodeStates.checked, true);
    }
    static get isNotChecked() {
        return getNodeStateValidator(NodeStates.checked, false);
    }
    static get isIndeterminate() {
        return getNodeStateValidator(NodeStates.indeterminate, true);
    }
    static get isDeterminate() {
        return getNodeStateValidator(NodeStates.indeterminate, false);
    }
    static get isLoading() {
        return getNodeStateValidator(NodeStates.isLoading, true);
    }
    static get isNotLoading() {
        return getNodeStateValidator(NodeStates.isLoading, false);
    }

    // <!-- EXTRACTORS -->
    static getEnabled = getNodeStateAsSet;
    static getState = getNodeStateByKey;

    // <!-- COMPARATORS -->
    static compare = compareNodeStates;

    // <!-- FACTORIES -->
    static create = createNodeState;
    static clone = cloneNodeState;

    // <!-- MUTATIONS -->
    static override = overrideNodeState;
    static set = setNodeState;
    static enable = enableNodeState;
    static disable = disableNodeState;
}

// <!-- DEFAULT -->
export default NodeState;
