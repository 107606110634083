/**
 * Encode value from a String.
 *
 * @param {String | Number | Boolean} value Value to encode as a boolean.
 * @returns {null|"1"|"0"} Encoded value.
 */
export const encodeBoolean = (value) => {
    if (value === null) {
        return null;
    } else {
        return ['TRUE', 'YES', '1', 'Y'].includes(String(value).toUpperCase())
            ? '1'
            : '0';
    }
};

/**
 * Decode value from a String.
 *
 * @param {String} value Value to decode into a boolean.
 * @returns {Boolean} Decoded value.
 */
export const decodeBoolean = (value) => {
    if (value === null) {
        return null;
    } else {
        return ['TRUE', 'YES', '1', 'Y'].includes(String(value).toUpperCase())
            ? true
            : false;
    }
};
