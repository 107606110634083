// <!-- UTILITY -->
import { Enum } from '@/utils/enums';

/** @type {Enum<ReminderFrequencyOptions> & ReminderFrequencyOptions} */
export const ReminderFrequency = Enum.create({
    Never: 'never',
    Weekly: 'weekly',
    Biweekly: 'biweekly',
    Monthly: 'monthly',
    Quarterly: 'quarterly',
});

export default ReminderFrequency;
