// <!-- API -->
import { ScaleFilter } from '@/utils/filters';

// <!-- TYPES -->
/** @typedef {import('@/utils/filters').IAxisID} IAxisID */
/** @typedef {import('@/utils/filters').IScaleFilter} IScaleFilter */
/** @typedef {import('@/utils/filters').IScaleFilterRecord} IScaleFilterRecord */

/**
 * @class
 * Individual limit filter for the keyed axis.
 * @implements {IScaleFilterRecord}
 */
export class ScaleFilterRecord {
    /**
     * Create an axis range.
     * @param {Readonly<Iterable<Readonly<IScaleFilter>>>} [props]
     * @returns {IScaleFilterRecord}
     */
    static create = (props = []) => {
        const record = new ScaleFilterRecord();
        for (const prop of props) {
            record[prop.key] = prop;
        }
        return record;
    };

    /**
     * Clone an existing axis range.
     * @param {Readonly<IScaleFilterRecord>} [source]
     * @returns {IScaleFilterRecord}
     */
    static clone = (source = new ScaleFilterRecord()) =>
        new ScaleFilterRecord(source);

    /**
     * Collect keys from the record and return the iterable.
     * @param {IScaleFilterRecord} [source] Source filter record to select entries from.
     */
    static *keys(source = new ScaleFilterRecord()) {
        const filters = /** @type {const} */ (['temp', 'rh', 'dp']);
        for (const key of filters) {
            yield key;
        }
        return;
    }

    /**
     * Collect entries from the record and return the iterable. Useful for creating {@link Map} instances.
     * @param {IScaleFilterRecord} [source] Source filter record to select entries from.
     */
    static *entries(source = new ScaleFilterRecord()) {
        for (const key of ScaleFilterRecord.keys(source)) {
            /** @type {[ key: IAxisID, filter: IScaleFilter ]} */
            const entry = [key, source[key]];
            yield entry;
        }
        return;
    }

    /**
     * Collect values from the record and return the iterable.
     * @param {IScaleFilterRecord} [source] Source filter record to select entries from.
     */
    static *values(source = new ScaleFilterRecord()) {
        for (const key of ScaleFilterRecord.keys(source)) {
            yield source[key];
        }
        return;
    }

    /**
     * Create an axis range.
     * @param {Readonly<Partial<IScaleFilterRecord>>} [props]
     */
    constructor(props) {
        // Get the temperature filter.
        const temp = ScaleFilter.temp(props?.temp);

        // Get the relative humidity filter.
        const rh = ScaleFilter.rh(props?.rh);

        // Initialize dew point filter.
        const dp = ScaleFilter.dp(props?.dp);

        /** @type {IScaleFilter} Temperature filter. */
        this.temp = temp;

        /** @type {IScaleFilter} Relative humidity filter. */
        this.rh = rh;

        /** @type {IScaleFilter} Dew point filter. */
        this.dp = dp;
    }
}
