<template>
    <BaseButton v-bind="button">
        <slot></slot>
    </BaseButton>
</template>

<script>
    // <!-- API -->
    import { defineComponent, toRefs, computed } from 'vue';

    // <!-- COMPONENTS -->
    import BaseButton from '@/components/buttons/BaseButton.vue';

    // <!-- COMPOSABLES -->
    import useButtonPresets from '@/components/buttons/hooks/useButtonPresets';

    // <!-- CONSTANTS -->

    /** Button theme composable. */
    const themes = useButtonPresets();

    /** Map of presets. */
    const presets = new Map([
        [
            'base',
            {
                theme: themes.primary,
                size: themes.md,
                modifiers: themes.defaultModifiers,
            },
        ],
        [
            'primary',
            {
                theme: themes.primary,
                size: themes.md,
                modifiers: themes.defaultModifiers,
            },
        ],
        [
            'secondary',
            {
                theme: themes.secondary,
                size: themes.md,
                modifiers: themes.defaultModifiers,
            },
        ],
        [
            'white',
            {
                theme: themes.white,
                size: themes.md,
                modifiers: themes.defaultModifiers,
            },
        ],
        [
            'danger',
            {
                theme: themes.danger,
                size: themes.md,
                modifiers: themes.defaultModifiers,
            },
        ],
        [
            'icon',
            {
                theme: [
                    'block',
                    'items-center',
                    'justify-items-center',
                    'text-center',
                    'rounded-md',
                    'text-black',
                    'hover:bg-primary-50',
                    'focus:bg-gray-50',
                    'focus:ring-offset-1',
                    'focus:ring-1',
                    'focus:ring-primary-50',
                ],
                size: ['p-2', 'max-h-10'],
            },
        ],
        [
            'danger-icon',
            {
                theme: [
                    'block',
                    'items-center',
                    'justify-items-center',
                    'text-center',
                    'rounded-md',
                    'text-red-600',
                    'hover:text-red-600',
                    'hover:bg-red-100',
                    'focus:bg-gray-50',
                    'focus:ring-offset-1',
                    'focus:ring-1',
                    'focus:ring-primary-50',
                ],
                size: ['p-2', 'max-h-10'],
            },
        ],
        [
            'gridAction',
            {
                theme: [
                    'inline-flex',
                    'flex-row',
                    'flex-nowrap',
                    'items-center',
                    'justify-items-center',
                    'text-white',
                    'text-center',
                    'rounded-md',
                    'bg-primary-600',
                    'hover:bg-primary-800',
                    'focus:text-black',
                    'focus:bg-primary-200',
                    'focus:ring-offset-1',
                    'focus:ring-1',
                    'focus:ring-primary-50',
                ],
                size: ['p-2', 'max-h-8'],
            },
        ],
        [
            'avatar',
            {
                theme: [
                    'flex',
                    'items-center',
                    'user-button',
                    'bg-gray-800',
                    'rounded-full',
                    'focus:outline-none',
                    'focus:ring-offset-gray-800',
                    'focus:ring-white',
                ],
                size: [
                    'max-w-xs',
                    'text-sm',
                    'focus:ring-2',
                    'focus:ring-offset-2',
                ],
            },
        ],
        [
            'menu-item',
            {
                theme: [
                    'block',
                    'text-gray-700',
                    'w-full',
                    'text-left',
                    'hover:bg-gray-100',
                ],
                size: ['px-4', 'py-2', 'text-sm'],
            },
        ],
        [
            'hamburger',
            {
                theme: [
                    'inline-flex',
                    'items-center',
                    'justify-center',
                    'hamburger-menu',
                    'text-white',
                    'rounded-md',
                    'focus:outline-none',
                    'focus:ring-offset-gray-800',
                    'focus:ring-white',
                ],
                size: ['p-2', 'focus:ring-2', 'focus:ring-offset-2'],
            },
        ],
        [
            'treeview',
            {
                theme: [
                    'block',
                    'items-center',
                    'justify-items-center',
                    'break-normal',
                    'text-primary-700',
                    'hover:bg-primary-700',
                    'hover:bg-opacity-10',
                ],
                size: ['text-xs', 'font-medium', 'py-1', 'px-4', 'h-6'],
                modifiers: ['border', 'border-primary-700', 'rounded-md'],
            },
        ],
        [
            'login',
            {
                theme: [
                    'inline-flex',
                    'justify-center',
                    'text-white',
                    'font-medium',
                    'bg-primary-600',
                    'hover:bg-primary-700',
                    'focus:outline-none',
                    'focus:ring-primary-500',
                    'border',
                    'border-transparent',
                    'shadow-sm',
                    'rounded-md',
                    'disabled:cursor-not-allowed',
                    'disabled:bg-gray-200',
                ],
                size: [
                    'w-full',
                    'px-4',
                    'py-2',
                    'text-sm',
                    'focus:ring-2',
                    'focus:ring-offset-2',
                ],
            },
        ],
    ]);

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'VariantButton',
        components: {
            BaseButton,
        },
        props: {
            variant: {
                /** @type {import('vue').PropType<String>} */
                type: String,
                default: 'base',
                /** @param {String} value */
                validator: (value) => {
                    return presets.has(value);
                },
            },
            overrides: {
                /** @type {import('vue').PropType<String[]>} */
                type: Array,
                default: () => [],
            },
        },
        setup(props, context) {
            const { overrides, variant } = toRefs(props);

            // Apply themes to the button.
            const button = computed(() => {
                // Select the preset if one is provided, or use the default.
                let preset = presets.get(variant.value) ?? presets.get('base');
                // Return object with preset properties, any overrides, and any passthrough attributes.
                return {
                    ...preset,
                    overrides: overrides.value,
                    ...context.attrs,
                };
            });
            // Export only the button configuration.
            return { button };
        },
    });
</script>
