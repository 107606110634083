// <!-- UTILITIES -->
import { getLegacyTimezones } from '@/utils/timezone/getLegacyTimezones';

// <!-- CONSTANTS -->
const LEGACY_TIMEZONES = getLegacyTimezones();

/**
 * Custom validation rule that ensures legacy timezones are not included.
 * @param {import('@formkit/core').FormKitNode} node
 */
export const isValidTimezone = (node) => {
    const value = String(node?.value ?? 'placeholder');
    const invalid = new Set(['placeholder', ...LEGACY_TIMEZONES]);
    return !invalid.has(value);
};

// <!-- DEFAULT EXPORT -->
export default isValidTimezone;
