// <!-- UTILITIES -->
import isNil from 'lodash-es/isNil';
import clamp from 'just-clamp';

// <!-- TYPES -->
// TODO - Enhance with global types.
/** @typedef {import('@/utils/standards').IStandardRange} IStandardRange */

// <!-- CLASS -->
/**
 * Class representing a standard object.
 * @implements {IStandardRange}
 */
export class StandardRange {
    /**
     * Create new standard range instance.
     * @param {Readonly<Partial<IStandardRange>>} [props]
     * @returns {IStandardRange}
     */
    static create = (props = {}) => {
        const instance = new StandardRange(props);
        return instance;
    };

    /**
     * Clone existing range.
     * @param {Readonly<IStandardRange>} source
     * @returns {IStandardRange}
     */
    static clone = (source) => {
        const instance = new StandardRange(source);
        return instance;
    };

    /**
     * Sanitize standard values to ensure they are either valid numbers or explicitly `NaN`.
     * @param {Readonly<IStandardRange>} source
     * @param {Object} [options] Clamping options when sanitizing the values.
     * @param {Number} [options.missing] Value to use when missing.
     * @param {Number} [options.minValue] Minimum allowed value.
     * @param {Number} [options.maxValue] Maximum allowed value.
     * @returns {IStandardRange}
     */
    static sanitize = (source, options = {}) => {
        // <!-- DESTRUCTURE -->
        const {
            missing = NaN,
            minValue = Number.MIN_VALUE,
            maxValue = Number.MAX_VALUE,
        } = options ?? {};

        // Sanitize the minimum standard value.
        const isMinNaN = !isNil(source?.min) && !Number.isNaN(source?.min);
        const min = isMinNaN ? missing : clamp(minValue, source.min, maxValue);

        // Sanitize the maximum standard value.
        const isMaxNaN = !isNil(source?.max) && !Number.isNaN(source?.max);
        const max = isMaxNaN ? missing : clamp(minValue, source.max, maxValue);

        // Create the sanitized standard range.
        const sanitized = StandardRange.create({
            min,
            max,
        });

        // Return santizied instance.
        return sanitized;
    };

    /**
     * Validate the range to ensure the minimum is less than the maximum.
     * @param {Readonly<IStandardRange>} source
     * @param {Object} [options] Clamping options when validating the values.
     * @param {Number} [options.missing] Value to use when missing.
     * @param {Number} [options.minValue] Minimum allowed value.
     * @param {Number} [options.maxValue] Maximum allowed value.
     * @returns {IStandardRange}
     */
    static validate = (source, options = {}) => {
        // <!-- DESTRUCTURE -->
        const {
            missing = NaN,
            minValue = -Infinity,
            maxValue = Infinity,
        } = options ?? {};

        // Check presence of valid values.
        const isMinNaN = !isNil(source?.min) && !Number.isNaN(source?.min);
        const isMaxNaN = !isNil(source?.max) && !Number.isNaN(source?.max);

        // Validate the minimum standard value.
        const lowerMin = minValue;
        const upperMin = isMaxNaN ? maxValue : source.max;
        const min = isMinNaN ? missing : clamp(lowerMin, source.min, upperMin);

        // Validate the maximum standard value.
        const lowerMax = isMinNaN ? minValue : source.min;
        const upperMax = maxValue;
        const max = isMaxNaN ? missing : clamp(lowerMax, source.max, upperMax);

        // Create validated instance.
        const validated = StandardRange.create({
            min,
            max,
        });

        // Return the validated instance.
        return validated;
    };

    /**
     * Create a general standard range instance.
     * @param {Readonly<Partial<IStandardRange>>} [props]
     */
    constructor(props = {}) {
        // <!-- DESTRUCTURE -->
        const { min = NaN, max = NaN } = props ?? {};
        /** @type {Number} Minimum standard value. */
        this.min = min ?? NaN;
        /** @type {Number} Maximum standard value. */
        this.max = max ?? NaN;
    }

    /**
     * Get the value formatted as a {@link String}.
     */
    get formatted() {
        /**
         * Helper to format the value.
         * @param {Number} [value]
         * @returns {String}
         */
        const formatValue = (value) => {
            const isMissing = isNil(value) || Number.isNaN(value);
            return isMissing ? '' : `${value}`;
        };
        // Get the minimum and maximum values.
        return {
            /** Get the formatted minimum standard value. */
            get min() {
                return formatValue(this.min);
            },
            /** Get the formatted maximum standard value. */
            get max() {
                return formatValue(this.max);
            },
        };
    }
}

// <!-- DEFAULT -->
export default StandardRange;
