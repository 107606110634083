// <!-- IMPORTS -->

import { CheckModes } from '@/utils/tree';

// <!-- UTILITIES -->

import clone from 'just-clone';

// <!-- FACTORIES -->

/** @type {Treeview.Factories.ConfigFactory} */
const createConfig = (props = {}) => {
    const defaults = /** @type {Treeview.Config} */ ({
        ...Config.DefaultConfig,
    });
    const instance = Object.assign({}, defaults, props);
    return instance;
};

// <!-- DUPLICATORS -->

/** @type {Treeview.Duplicators.ConfigDuplicator} */
const cloneConfig = (source) => {
    const instance = clone({ ...source });
    instance.roots = [...instance.roots];
    instance.leaves = [...instance.leaves];
    return instance;
};

// <!-- MUTATIONS -->

/** @type {Treeview.Mutations.ConfigMutation} */
const overrideConfig = (source, patch) => {
    const target = cloneConfig(source);
    const instance = Object.assign(target, patch);
    return instance;
};

// <!-- CLASS -->

/** @class Treeview configuration class helper. */
export class Config {
    static get DefaultConfig() {
        return Object.freeze(
            /** @type {Treeview.Config} */ ({
                roots: [],
                leaves: [],
                padding: 25,
                checkMode: CheckModes.auto,
                checkboxes: true,
                disabled: false,
            })
        );
    }

    // <!-- FACTORIES -->
    static create = createConfig;

    // <!-- DUPLICATORS -->
    static clone = cloneConfig;

    // <!-- MUTATIONS -->
    static override = overrideConfig;
}

// <!-- DEFAULT -->

export default Config;
