<template>
    <img
        :src="imageUrl"
        alt="User Avatar"
    />
</template>

<script>
    // <!-- API -->
    import { defineComponent, computed } from 'vue';

    // <!-- UTILITIES -->
    import md5 from 'md5';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'Gravatar',
        props: {
            email: {
                type: String,
            },
            default: {
                default: 'mp',
                type: String,
            },
            rating: {
                default: 'g',
                type: String,
            },
            size: {
                type: Number,
            },
        },
        setup(props) {
            // Hash email for use with the gravatar link.
            const hash = computed(() => {
                return !!props.email
                    ? md5(props.email.trim().toLowerCase())
                    : '';
            });

            // Format image url to pull from the gravatar API.
            const gravatarUrl = 'https://www.gravatar.com/avatar';
            const imageUrl = computed(() => {
                let queryString =
                    `/${hash.value}?d=${props.default}&r=${props.rating}` +
                    (!!props.size ? `%s=${props.size}` : '');
                return `${gravatarUrl}${queryString}`;
            });

            // Expose to Options API.
            return {
                hash,
                imageUrl,
            };
        },
    });
</script>
