<template>
    <div>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="30"
            viewBox="0 0 462.258 43.9326"
        >
            <title>eClimateNotebook Logo</title>
            <path
                class="logo-white"
                d="M55.24,40.2178c0,1.8252-5.24,3.415-10.2456,3.415-10.8344,0-15.9575-6.7129-15.9575-20.8447,0-13.5435,5.2407-21.4927,15.84-21.4927,4.1215,0,9.8925,1.7075,9.8925,3.8862v7.8907c0,.8242-.2358,1.0009-1.0009,1.0009H49.4108c-.8247,0-1.0014-.1767-1.0014-1.0009V8.1851a9.3836,9.3836,0,0,0-3.1206-.53c-5.4761,0-7.8907,3.4741-7.8907,13.602,0,10.0694,1.3545,16.0166,8.3619,16.0166a14.0181,14.0181,0,0,0,3.1206-.2944V31.68c0-.7656.1767-1.0015,1.0009-1.0015H54.239c.8247,0,1.0009.2359,1.0009,1.0015Z"
            />
            <path
                class="logo-white"
                d="M60.0681,5.8887H57.1237c-.7065,0-.8242-.1768-.8242-.8247V1.2368c0-.6479.1177-.8247.8242-.8247h9.5982c.7065,0,.8242.1768.8242.8247V33.3281c0,1.8843,0,3.5332,1.6489,3.5332a9.3719,9.3719,0,0,0,2.2964-.53.4484.4484,0,0,1,.4712.3535L73.14,41.5132a1.1129,1.1129,0,0,1,.1177.4707c0,.8247-3.1211,1.6489-5.9473,1.6489-7.2426,0-7.2426-5.9472-7.2426-11.3642Z"
            />
            <path
                class="logo-white"
                d="M90.2155,41.8076c0,.6475-.1176.8242-.8242.8242H74.3757c-.7066,0-.8242-.1767-.8242-.8242V37.98c0-.6475.1176-.8242.8242-.8242h3.7685V19.02H74.3171c-.707,0-.8247-.1767-.8247-.8242V14.3677c0-.648.1177-.8242.8247-.8242H84.798c.7066,0,.8248.1762.8248.8242V37.1558h3.7685c.7066,0,.8242.1767.8242.8242ZM81.8542,9.7158a4.8754,4.8754,0,0,1-4.8877-4.8872,4.858,4.858,0,1,1,4.8877,4.8872Z"
            />
            <path
                class="logo-white"
                d="M137.6164,41.8076c0,.6475-.1177.8242-.8242.8242H127.135c-.7066,0-.8243-.1767-.8243-.8242V23.6714c0-2.2378-.2353-4.9463-2.4731-4.9463a7.7054,7.7054,0,0,0-5.064,2.4141V37.1558h3.003c.7065,0,.8247.1767.8247.8242v3.8276c0,.6475-.1182.8242-.8247.8242H112.12c-.7065,0-.8242-.1767-.8242-.8242V23.6714c0-2.2378-.2358-4.9463-2.4731-4.9463a7.7036,7.7036,0,0,0-5.064,2.4141V37.1558h3.0029c.7066,0,.8242.1767.8242.8242v3.8276c0,.6475-.1176.8242-.8242.8242H93.277c-.7065,0-.8242-.1767-.8242-.8242V37.98c0-.6475.1177-.8242.8242-.8242H96.28V24.0244a14.559,14.559,0,0,0-.5889-5.0049H92.8649c-.7065,0-.8247-.2353-.8247-.8242V14.3677c0-.648.1182-.8242.8247-.8242H101.05c1.4131,0,1.59,1.7661,1.8843,3.062,1.7666-1.5313,4.3574-4.063,9.0093-4.063,2.5322,0,5.2993,1.59,6.0063,4.063,1.7661-1.5313,4.2984-4.063,9.0088-4.063,5.5352,0,6.8306,4.1806,6.8306,9.3032v15.31h3.0034c.7065,0,.8242.1767.8242.8242Z"
            />
            <path
                class="logo-white"
                d="M162.2878,32.21a16.3872,16.3872,0,0,0,.5889,4.9463h2.5317c.6479,0,.8247.1767.8247.8242v3.8276c0,.6475-.1177.8242-.8247.8242h-7.36c-1.413,0-1.5307-1.4721-1.7666-2.5322a9.7594,9.7594,0,0,1-7.5957,3.5332c-5.4174,0-8.8325-3.5332-8.8325-9.0679,0-9.9516,10.7168-9.9516,15.3687-9.9516V22.7881c0-2.65-.4712-4.3574-4.1807-4.3574-3.2978,0-6.5361,1.7666-7.3017,1.7666-.2945,0-.53-.3535-.5889-.5889l-1.1777-3.5918a3.1241,3.1241,0,0,1-.1768-.707c0-1.354,5.83-2.7671,10.3638-2.7671,7.36,0,10.1279,3.3559,10.1279,9.48Zm-7.0659-2.7676c-1.4722,0-8.3028-.4712-8.3028,4.71a3.839,3.839,0,0,0,3.8863,4.0044,6.4173,6.4173,0,0,0,4.4165-2.2378Z"
            />
            <path
                class="logo-white"
                d="M178.2448,33.27c0,1.8843,0,3.5918,2.002,3.5918,2.3554,0,3.8276-1.1186,4.6518-1.1186a.4481.4481,0,0,1,.4712.353l1.1778,4.8286a1.7263,1.7263,0,0,1,.1176.53c0,1.06-4.2988,2.1787-8.3027,2.1787-7.5962,0-7.5962-5.9472-7.5962-11.3642V19.02h-4.1807c-.7065,0-.8242-.1767-.8242-.8242V14.3677c0-.648.1177-.8242.8242-.8242h4.1807V6.124c0-.7656.1182-.9424.9424-1.06l5.1816-.7066c.1768,0,.4121-.0591.7657-.0591.53,0,.5888.2945.5888,1.0015v8.2437h6.7129c.5884,0,1.06.1176,1.06.5888a2.0876,2.0876,0,0,1-.1177.5889l-.8242,3.4741c-.1768.7652-.53.8242-1.1192.8242h-5.7114Z"
            />
            <path
                class="logo-white"
                d="M195.9685,30.09c0,3.2974.9419,7.36,6.477,7.36,4.24,0,6.6538-1.9434,7.478-1.9434a.7823.7823,0,0,1,.648.4712l1.4722,3.5332a2.0947,2.0947,0,0,1,.1767.6475c0,1.5308-5.8887,3.4741-10.5405,3.4741-7.7725,0-13.19-4.416-13.19-15.251,0-9.3623,5.0054-15.8393,12.8955-15.8393,10.01,0,11.7769,8.95,11.7769,16.0752,0,1.1186-.2353,1.4721-1.3545,1.4721Zm9.2446-5.4765c0-2.4141-.1768-6.5947-4.24-6.5947-4.5927,0-4.8286,4.9462-5.0048,6.5947Z"
            />
            <path
                class="logo-white"
                d="M247.7268,7.9492V41.3955c0,1.06-.1768,1.2363-1.2373,1.2363h-3.8858c-.8242,0-1.001-.1176-1.3545-.8242l-10.834-20.08a39.6886,39.6886,0,0,1-3.4746-8.0083h-.1177c0,1.9434.2945,3.7686.2945,6.4776V36.979h2.9438c1.0015,0,1.2368.1768,1.2368,1.2363v3.18c0,1.06-.2353,1.2363-1.2368,1.2363H217.3425c-1.001,0-1.2363-.1767-1.2363-1.2363v-3.18c0-1.0595.2353-1.2363,1.2363-1.2363h3.0034V7.9492h-3.0034c-1.001,0-1.2363-.1767-1.2363-1.2363v-3.18c0-1.06.2353-1.2368,1.2363-1.2368h9.0683c.8833,0,1.001.1177,1.354.7656L237.54,21.3159a47.1818,47.1818,0,0,1,3.5323,8.5381h.1181c0-1.8843-.2363-5.77-.2363-7.6548V7.9492"
            />
            <path
                class="logo-white"
                d="M265.7453,43.6328c-7.9492,0-12.7773-6.0649-12.7773-15.5454s4.8281-15.5449,12.7773-15.5449c7.8906,0,12.7774,6.0649,12.7774,15.5449S273.6945,43.6328,265.7453,43.6328Zm4.8281-15.5454c0-5.4761-.7656-9.6567-4.8281-9.6567s-4.8281,4.1806-4.8281,9.6567.706,9.6572,4.8281,9.6572S270.5734,33.6226,270.5734,28.0874Z"
            />
            <path
                class="logo-white"
                d="M292.3586,8.3232v5.22h6.7129c.5889,0,1.06.1176,1.06.5888a2.1061,2.1061,0,0,1-.1181.5889l-.8243,3.4741c-.1767.7652-.53.8242-1.1191.8242h-5.7119V33.27c0,1.8843,0,3.5918,2.0029,3.5918,2.3545,0,3.8272-1.1186,4.6514-1.1186a.4473.4473,0,0,1,.4707.353l1.1777,4.8286a1.6989,1.6989,0,0,1,.1182.53c0,1.06-4.2988,2.1787-8.3027,2.1787-7.5957,0-7.5957-5.9472-7.5957-11.3642V19.02H280.7c-.707,0-.8252-.1767-.8252-.8242V14.3677c0-.648.1182-.8242.8252-.8242h4.1807V9.7246"
            />
            <path
                class="logo-white"
                d="M310.0822,30.09c0,3.2974.9424,7.36,6.4776,7.36,4.2392,0,6.6543-1.9434,7.4785-1.9434a.7827.7827,0,0,1,.6474.4712l1.4717,3.5332a2.0648,2.0648,0,0,1,.1768.6475c0,1.5308-5.8877,3.4741-10.54,3.4741-7.7724,0-13.1894-4.416-13.1894-15.251,0-9.3623,5.0049-15.8393,12.8955-15.8393,10.01,0,11.7764,8.95,11.7764,16.0752,0,1.1186-.2354,1.4721-1.3545,1.4721Zm9.2451-5.4765c0-2.4141-.1767-6.5947-4.2392-6.5947-4.5938,0-4.8291,4.9462-5.0059,6.5947Z"
            />
            <path
                class="logo-white"
                d="M339.465,15.1333a12.0421,12.0421,0,0,1,7.2432-2.5908c8.0078,0,10.3047,7.0659,10.3047,14.7207,0,10.01-4.2988,16.37-12.19,16.37a7.9712,7.9712,0,0,1-6.3-2.5908c-.2353.6475-.8242,2.5908-1.708,2.5908a3.4658,3.4658,0,0,1-.8242-.1177l-4.7109-1.1777c-.294-.0591-.5889-.2353-.5889-.4712,0-.53.6484-1.06,1.06-3.062a18.7278,18.7278,0,0,0,.2354-3.65V5.8887h-2.9443c-.7071,0-.8243-.1768-.8243-.8247V1.2368c0-.6479.1172-.8247.8243-.8247h9.5976c.7071,0,.8242.1768.8242.8247Zm0,21.3745a5.8147,5.8147,0,0,0,3.6514,1.2368c5.3584,0,6.418-5.476,6.418-10.0693,0-4.71-.8242-9.2446-5.2989-9.2446a7.5236,7.5236,0,0,0-4.7705,2.0019Z"
            />
            <path
                class="logo-white"
                d="M373.2629,43.6328c-7.9492,0-12.7783-6.0649-12.7783-15.5454s4.8291-15.5449,12.7783-15.5449c7.8906,0,12.7773,6.0649,12.7773,15.5449S381.2121,43.6328,373.2629,43.6328Zm4.8281-15.5454c0-5.4761-.7656-9.6567-4.8281-9.6567s-4.8281,4.1806-4.8281,9.6567.706,9.6572,4.8281,9.6572S378.091,33.6226,378.091,28.0874Z"
            />
            <path
                class="logo-white"
                d="M402.3518,43.6328c-7.9493,0-12.7784-6.0649-12.7784-15.5454s4.8291-15.5449,12.7784-15.5449c7.8906,0,12.7773,6.0649,12.7773,15.5449S410.301,43.6328,402.3518,43.6328ZM407.18,28.0874c0-5.4761-.7656-9.6567-4.8281-9.6567s-4.8282,4.1806-4.8282,9.6567.7061,9.6572,4.8282,9.6572S407.18,33.6226,407.18,28.0874Z"
            />
            <path
                class="logo-white"
                d="M448.9279,41.8076c0,.6475-.1181.8242-.8242.8242H440.802c-.6475,0-.7061,0-1.1192-.5888L429.5549,28.2642h-.1172v8.8916h3.0029c.7071,0,.8242.1767.8242.8242v3.8276c0,.6475-.1171.8242-.8242.8242H418.9562c-.707,0-.8242-.1767-.8242-.8242V37.98c0-.6475.1172-.8242.8242-.8242h3.003V5.8887h-2.9444c-.706,0-.8242-.1768-.8242-.8247V1.2368c0-.6479.1182-.8247.8242-.8247h9.5987c.706,0,.8242.1768.8242.8247V25.1436l7.36-6.0064V19.02h-2.414c-.707,0-.8242-.1767-.8242-.8242V14.3677c0-.648.1172-.8242.8242-.8242h13.0127c.707,0,.8252.1762.8252.8242v3.8276c0,.6475-.1182.8242-.8252.8242h-3.0615l-8.3028,7.066,9.1856,11.07h2.8857c.7061,0,.8242.1767.8242.8242Z"
            />
            <path
                class="logo-secondary"
                d="M28.7307,31.4268c-4.6231,5.3808-12.96,12.5058-20.4634,12.5058-5.5327,0-8.1094-4.9267-8.2612-9.9287-.3033-9.9287,10.6865-20.0845,20.54-20.0088,2.4253,0,5.4565.6065,5.4565,3.6382,0,3.7134-3.7133,9.3218-18.2651,14.0972l-.1518,1.667c-.2276,2.88,2.2739,3.8652,4.6992,3.8652,5.3808,0,11.7475-5.5322,15.7641-8.7158Zm-9.398-12.2022c0-.53-.3789-.9853-.91-.8335-3.7895.9854-8.185,4.6231-10.2314,9.6255C14.861,25.7427,19.4084,20.5132,19.3327,19.2246Z"
            />
            <rect
                class="logo-white"
                x="240.9445"
                y="0.396"
                width="83.3867"
                height="5.5"
            />
            <rect
                class="logo-white"
                x="343.1945"
                y="0.396"
                width="71.166"
                height="5.5"
            />
            <path
                class="logo-white"
                d="M462.258,6.041a5.6834,5.6834,0,0,1-1.67,4.065,5.7937,5.7937,0,0,1-4.0869,1.6626,5.9506,5.9506,0,0,1-2.292-.4322,5.4613,5.4613,0,0,1-1.8017-1.1865,5.3858,5.3858,0,0,1-1.18-1.7944A5.9881,5.9881,0,0,1,450.803,6.07a5.66,5.66,0,0,1,1.67-4.05A5.6943,5.6943,0,0,1,454.2971.8115,5.789,5.789,0,0,1,456.56.3721a6.0462,6.0462,0,0,1,2.293.4248,5.28,5.28,0,0,1,2.98,2.9736A5.9483,5.9483,0,0,1,462.258,6.041Zm-1.3183.0879a5.1866,5.1866,0,0,0-.3291-1.89,4.3289,4.3289,0,0,0-.916-1.4576,3.9416,3.9416,0,0,0-1.3916-.93,4.6494,4.6494,0,0,0-1.7432-.3223,4.5473,4.5473,0,0,0-1.8232.3516,4.2229,4.2229,0,0,0-1.3995.9668,4.2984,4.2984,0,0,0-.9,1.4282,4.7625,4.7625,0,0,0-.3155,1.7358,5.1442,5.1442,0,0,0,.33,1.875,4.3252,4.3252,0,0,0,.915,1.4575,4.0252,4.0252,0,0,0,1.3916.9375,4.8132,4.8132,0,0,0,3.5743-.0293,4.2019,4.2019,0,0,0,2.2929-2.3877A4.7616,4.7616,0,0,0,460.94,6.1289ZM459.2112,8.81a.2761.2761,0,0,1-.0215.11.1493.1493,0,0,1-.11.0733,1.7488,1.7488,0,0,1-.249.0366q-.1611.0147-.4394.0146c-.1661,0-.3-.0048-.4024-.0146a.9655.9655,0,0,1-.249-.0513.33.33,0,0,1-.1465-.1025.4914.4914,0,0,1-.081-.1831l-.3077-1.0108a1.7016,1.7016,0,0,0-.417-.7544,1.0727,1.0727,0,0,0-.7255-.2124h-.3956V8.7656a.22.22,0,0,1-.124.22,1.7257,1.7257,0,0,1-.5791.0585,1.9525,1.9525,0,0,1-.6006-.0585.2175.2175,0,0,1-.1464-.22V3.5068a.7387.7387,0,0,1,.1611-.5053.61.61,0,0,1,.4834-.1831h1.6846a4.65,4.65,0,0,1,1.0332.1025,2.1638,2.1638,0,0,1,.7617.3223,1.4274,1.4274,0,0,1,.4756.5493,1.7675,1.7675,0,0,1,.1611.7837,1.4923,1.4923,0,0,1-.3809,1.0913,2.1185,2.1185,0,0,1-1.0254.5493,1.8566,1.8566,0,0,1,.6592.3955,2.1963,2.1963,0,0,1,.5274.835l.3515.9375A2.1053,2.1053,0,0,1,459.2112,8.81ZM457.3948,4.752a1.0652,1.0652,0,0,0-.044-.3077.5475.5475,0,0,0-.1679-.2563.8419.8419,0,0,0-.33-.1685,1.9009,1.9009,0,0,0-.5127-.0586h-.6739V5.6016h.6446a1.1952,1.1952,0,0,0,.85-.2344A.8321.8321,0,0,0,457.3948,4.752Z"
            />
        </svg>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent } from 'vue';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'ECNBLogo',
    });
</script>

<style scoped>
    .logo-white {
        fill: #fff;
    }

    .logo-secondary {
        fill: #eacf26;
    }
</style>
