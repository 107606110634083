// <!-- UTILITY -->
import { Enum } from '@/utils/enums';

export const ModalIcon = Enum.create({
    info: 'info',
    warning: 'warning',
    success: 'success',
    failure: 'failure',
    error: 'error',
    danger: 'danger',
});

export default ModalIcon;
