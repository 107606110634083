// <!-- DEFINE -->
/** @typedef {Record<String, Readonly<Router.RouteRecordRaw>>} */
const actions = {
    'remote-login': {
        path: '/actions/login_by_acct',
        name: 'cart',
        component: () =>
            import(
                /* webpackPrefetch: true */ /* webpackChunkName: "actions" */ '@/features/cart/views/CartIntegration.vue'
            ),
        meta: { namespace: 'actions' },
    },
};

// <!-- EXPORTS -->
/** @typedef {Record<String, Readonly<Router.RouteRecordRaw>>} */
export const cart = {
    ...actions,
};

// <!-- DEFAULT -->
export default cart;
