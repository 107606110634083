/**
 * Base attribute factory interface.
 *
 * @template {Resource.Type} [T = never]
 * @template {{ [key: string]: any }} [P = never]
 * @template {{ [key: string]: any }} [M = never]
 * @implements {Omit<Resource.AttributeFactory<T,P,M>, 'fromPayload' | 'fromModel'>}
 */
export class AttributeFactory {
    // <!-- CONSTRUCTOR -->

    /**
     * Create an attribute factory that returns the supplied initial state.
     * @param {T extends Resource.Types['Payload'] ? P : M} initialValues
     */
    constructor(initialValues) {
        this._init = initialValues;
        Object.defineProperty(this, '_init', { enumerable: false });
    }

    // <!-- FACTORY INTERFACE -->

    /** Get shallow copy of the initial state. */
    get initialState() {
        return Object.assign(Object.create(null), this._init);
    }
}
