// <!-- API -->
import router from '@/router';
import { useAuthRedirects, useNARARedirects } from '@/router/guards';

// <!-- TYPES -->
/** @template [S=any] @typedef {import('vuex').Store<S>} Store<S> */
/** @typedef {import('@/store/types/ECNBStore').ECNBState} ECNBState */

/**
 * Register the router.
 * @param {Vue.App} app Vue application instance.
 * @param {Store<ECNBState>} store Store instance.
 */
export const registerRouter = (app, store) => {
    // Register navigation guards.
    useAuthRedirects(router, store);
    useNARARedirects(router, store);
    // Register router with the Vue app.
    app.use(router);
    console.log(
        `[plugin::router] registered @ ${new Date().toLocaleTimeString()}`
    );
};
