// <!-- UTILITIES -->
import { Enum } from '@/utils/enums';

// <!-- COUNTRY REFERENCE -->
/** @type {Country.References} */
export const Countries = [
    { iso3: 'AFG', iso2: 'AF', country: 'Afghanistan' },
    { iso3: 'ALB', iso2: 'AL', country: 'Albania' },
    { iso3: 'DZA', iso2: 'DZ', country: 'Algeria' },
    { iso3: 'ASM', iso2: 'AS', country: 'American Samoa' },
    { iso3: 'AND', iso2: 'AD', country: 'Andorra' },
    { iso3: 'AGO', iso2: 'AO', country: 'Angola' },
    { iso3: 'AIA', iso2: 'AI', country: 'Anguilla' },
    { iso3: 'ATA', iso2: 'AQ', country: 'Antarctica' },
    { iso3: 'ATG', iso2: 'AG', country: 'Antigua and Barbuda' },
    { iso3: 'ARG', iso2: 'AR', country: 'Argentina' },
    { iso3: 'ARM', iso2: 'AM', country: 'Armenia' },
    { iso3: 'ABW', iso2: 'AW', country: 'Aruba' },
    { iso3: 'AUS', iso2: 'AU', country: 'Australia' },
    { iso3: 'AUT', iso2: 'AT', country: 'Austria' },
    { iso3: 'AZE', iso2: 'AZ', country: 'Azerbaijan' },
    { iso3: 'BHS', iso2: 'BS', country: 'Bahamas' },
    { iso3: 'BHR', iso2: 'BH', country: 'Bahrain' },
    { iso3: 'BGD', iso2: 'BD', country: 'Bangladesh' },
    { iso3: 'BRB', iso2: 'BB', country: 'Barbados' },
    { iso3: 'BLR', iso2: 'BY', country: 'Belarus' },
    { iso3: 'BEL', iso2: 'BE', country: 'Belgium' },
    { iso3: 'BLZ', iso2: 'BZ', country: 'Belize' },
    { iso3: 'BEN', iso2: 'BJ', country: 'Benin' },
    { iso3: 'BMU', iso2: 'BM', country: 'Bermuda' },
    { iso3: 'BTN', iso2: 'BT', country: 'Bhutan' },
    { iso3: 'BOL', iso2: 'BO', country: 'Bolivia' },
    {
        iso3: 'BES',
        iso2: 'BQ',
        country: 'Bonaire, Sint Eustatius and Saba',
    },
    { iso3: 'BIH', iso2: 'BA', country: 'Bosnia and Herzegovina' },
    { iso3: 'BWA', iso2: 'BW', country: 'Botswana' },
    { iso3: 'BVT', iso2: 'BV', country: 'Bouvet Island' },
    { iso3: 'BRA', iso2: 'BR', country: 'Brazil' },
    {
        iso3: 'IOT',
        iso2: 'IO',
        country: 'British Indian Ocean Territory',
    },
    { iso3: 'BRN', iso2: 'BN', country: 'Brunei Darussalam' },
    { iso3: 'BGR', iso2: 'BG', country: 'Bulgaria' },
    { iso3: 'BFA', iso2: 'BF', country: 'Burkina Faso' },
    { iso3: 'BDI', iso2: 'BI', country: 'Burundi' },
    { iso3: 'CPV', iso2: 'CV', country: 'Cabo Verde' },
    { iso3: 'KHM', iso2: 'KH', country: 'Cambodia' },
    { iso3: 'CMR', iso2: 'CM', country: 'Cameroon' },
    { iso3: 'CAN', iso2: 'CA', country: 'Canada' },
    { iso3: 'CYM', iso2: 'KY', country: 'Cayman Islands' },
    { iso3: 'CAF', iso2: 'CF', country: 'Central African Republic' },
    { iso3: 'TCD', iso2: 'TD', country: 'Chad' },
    { iso3: 'CHL', iso2: 'CL', country: 'Chile' },
    { iso3: 'CHN', iso2: 'CN', country: 'China' },
    { iso3: 'CXR', iso2: 'CX', country: 'Christmas Island' },
    { iso3: 'CCK', iso2: 'CC', country: 'Cocos (Keeling) Islands' },
    { iso3: 'COL', iso2: 'CO', country: 'Colombia' },
    { iso3: 'COM', iso2: 'KM', country: 'Comoros' },
    {
        iso3: 'COD',
        iso2: 'CD',
        country: 'Democractic Republic of Congo',
    },
    { iso3: 'COG', iso2: 'CG', country: 'Congo' },
    { iso3: 'COK', iso2: 'CK', country: 'Cook Islands' },
    { iso3: 'CRI', iso2: 'CR', country: 'Costa Rica' },
    { iso3: 'HRV', iso2: 'HR', country: 'Croatia' },
    { iso3: 'CUB', iso2: 'CU', country: 'Cuba' },
    { iso3: 'CUW', iso2: 'CW', country: 'Curaçao' },
    { iso3: 'CYP', iso2: 'CY', country: 'Cyprus' },
    { iso3: 'CZE', iso2: 'CZ', country: 'Czechia' },
    { iso3: 'CIV', iso2: 'CI', country: "Côte d'Ivoire" },
    { iso3: 'DNK', iso2: 'DK', country: 'Denmark' },
    { iso3: 'DJI', iso2: 'DJ', country: 'Djibouti' },
    { iso3: 'DMA', iso2: 'DM', country: 'Dominica' },
    { iso3: 'DOM', iso2: 'DO', country: 'Dominican Republic' },
    { iso3: 'ECU', iso2: 'EC', country: 'Ecuador' },
    { iso3: 'EGY', iso2: 'EG', country: 'Egypt' },
    { iso3: 'SLV', iso2: 'SV', country: 'El Salvador' },
    { iso3: 'GNQ', iso2: 'GQ', country: 'Equatorial Guinea' },
    { iso3: 'ERI', iso2: 'ER', country: 'Eritrea' },
    { iso3: 'EST', iso2: 'EE', country: 'Estonia' },
    { iso3: 'SWZ', iso2: 'SZ', country: 'Eswatini' },
    { iso3: 'ETH', iso2: 'ET', country: 'Ethiopia' },
    { iso3: 'FLK', iso2: 'FK', country: 'Falkland Islands [Malvinas]' },
    { iso3: 'FRO', iso2: 'FO', country: 'Faroe Islands' },
    { iso3: 'FJI', iso2: 'FJ', country: 'Fiji' },
    { iso3: 'FIN', iso2: 'FI', country: 'Finland' },
    { iso3: 'FRA', iso2: 'FR', country: 'France' },
    { iso3: 'GUF', iso2: 'GF', country: 'French Guiana' },
    { iso3: 'PYF', iso2: 'PF', country: 'French Polynesia' },
    { iso3: 'ATF', iso2: 'TF', country: 'French Southern Territories' },
    { iso3: 'GAB', iso2: 'GA', country: 'Gabon' },
    { iso3: 'GMB', iso2: 'GM', country: 'Gambia' },
    { iso3: 'GEO', iso2: 'GE', country: 'Georgia' },
    { iso3: 'DEU', iso2: 'DE', country: 'Germany' },
    { iso3: 'GHA', iso2: 'GH', country: 'Ghana' },
    { iso3: 'GIB', iso2: 'GI', country: 'Gibraltar' },
    { iso3: 'GRC', iso2: 'GR', country: 'Greece' },
    { iso3: 'GRL', iso2: 'GL', country: 'Greenland' },
    { iso3: 'GRD', iso2: 'GD', country: 'Grenada' },
    { iso3: 'GLP', iso2: 'GP', country: 'Guadeloupe' },
    { iso3: 'GUM', iso2: 'GU', country: 'Guam' },
    { iso3: 'GTM', iso2: 'GT', country: 'Guatemala' },
    { iso3: 'GGY', iso2: 'GG', country: 'Guernsey' },
    { iso3: 'GIN', iso2: 'GN', country: 'Guinea' },
    { iso3: 'GNB', iso2: 'GW', country: 'Guinea-Bissau' },
    { iso3: 'GUY', iso2: 'GY', country: 'Guyana' },
    { iso3: 'HTI', iso2: 'HT', country: 'Haiti' },
    {
        iso3: 'HMD',
        iso2: 'HM',
        country: 'Heard Island and McDonald Islands',
    },
    { iso3: 'VAT', iso2: 'VA', country: 'Holy See (Vatican)' },
    { iso3: 'HND', iso2: 'HN', country: 'Honduras' },
    { iso3: 'HKG', iso2: 'HK', country: 'Hong Kong' },
    { iso3: 'HUN', iso2: 'HU', country: 'Hungary' },
    { iso3: 'ISL', iso2: 'IS', country: 'Iceland' },
    { iso3: 'IND', iso2: 'IN', country: 'India' },
    { iso3: 'IDN', iso2: 'ID', country: 'Indonesia' },
    { iso3: 'IRN', iso2: 'IR', country: 'Iran' },
    { iso3: 'IRQ', iso2: 'IQ', country: 'Iraq' },
    { iso3: 'IRL', iso2: 'IE', country: 'Ireland' },
    { iso3: 'IMN', iso2: 'IM', country: 'Isle of Man' },
    { iso3: 'ISR', iso2: 'IL', country: 'Israel' },
    { iso3: 'ITA', iso2: 'IT', country: 'Italy' },
    { iso3: 'JAM', iso2: 'JM', country: 'Jamaica' },
    { iso3: 'JPN', iso2: 'JP', country: 'Japan' },
    { iso3: 'JEY', iso2: 'JE', country: 'Jersey' },
    { iso3: 'JOR', iso2: 'JO', country: 'Jordan' },
    { iso3: 'KAZ', iso2: 'KZ', country: 'Kazakhstan' },
    { iso3: 'KEN', iso2: 'KE', country: 'Kenya' },
    { iso3: 'KIR', iso2: 'KI', country: 'Kiribati' },
    { iso3: 'PRK', iso2: 'KP', country: 'North Korea' },
    { iso3: 'KOR', iso2: 'KR', country: 'South Korea' },
    { iso3: 'KWT', iso2: 'KW', country: 'Kuwait' },
    { iso3: 'KGZ', iso2: 'KG', country: 'Kyrgyzstan' },
    { iso3: 'LAO', iso2: 'LA', country: 'Laos' },
    { iso3: 'LVA', iso2: 'LV', country: 'Latvia' },
    { iso3: 'LBN', iso2: 'LB', country: 'Lebanon' },
    { iso3: 'LSO', iso2: 'LS', country: 'Lesotho' },
    { iso3: 'LBR', iso2: 'LR', country: 'Liberia' },
    { iso3: 'LBY', iso2: 'LY', country: 'Libya' },
    { iso3: 'LIE', iso2: 'LI', country: 'Liechtenstein' },
    { iso3: 'LTU', iso2: 'LT', country: 'Lithuania' },
    { iso3: 'LUX', iso2: 'LU', country: 'Luxembourg' },
    { iso3: 'MAC', iso2: 'MO', country: 'Macao' },
    { iso3: 'MDG', iso2: 'MG', country: 'Madagascar' },
    { iso3: 'MWI', iso2: 'MW', country: 'Malawi' },
    { iso3: 'MYS', iso2: 'MY', country: 'Malaysia' },
    { iso3: 'MDV', iso2: 'MV', country: 'Maldives' },
    { iso3: 'MLI', iso2: 'ML', country: 'Mali' },
    { iso3: 'MLT', iso2: 'MT', country: 'Malta' },
    { iso3: 'MHL', iso2: 'MH', country: 'Marshall Islands' },
    { iso3: 'MTQ', iso2: 'MQ', country: 'Martinique' },
    { iso3: 'MRT', iso2: 'MR', country: 'Mauritania' },
    { iso3: 'MUS', iso2: 'MU', country: 'Mauritius' },
    { iso3: 'MYT', iso2: 'YT', country: 'Mayotte' },
    { iso3: 'MEX', iso2: 'MX', country: 'Mexico' },
    {
        iso3: 'FSM',
        iso2: 'FM',
        country: 'Federated States of Micronesia ',
    },
    { iso3: 'MDA', iso2: 'MD', country: 'Moldova' },
    { iso3: 'MCO', iso2: 'MC', country: 'Monaco' },
    { iso3: 'MNG', iso2: 'MN', country: 'Mongolia' },
    { iso3: 'MNE', iso2: 'ME', country: 'Montenegro' },
    { iso3: 'MSR', iso2: 'MS', country: 'Montserrat' },
    { iso3: 'MAR', iso2: 'MA', country: 'Morocco' },
    { iso3: 'MOZ', iso2: 'MZ', country: 'Mozambique' },
    { iso3: 'MMR', iso2: 'MM', country: 'Myanmar' },
    { iso3: 'NAM', iso2: 'NA', country: 'Namibia' },
    { iso3: 'NRU', iso2: 'NR', country: 'Nauru' },
    { iso3: 'NPL', iso2: 'NP', country: 'Nepal' },
    { iso3: 'NLD', iso2: 'NL', country: 'Netherlands' },
    { iso3: 'NCL', iso2: 'NC', country: 'New Caledonia' },
    { iso3: 'NZL', iso2: 'NZ', country: 'New Zealand' },
    { iso3: 'NIC', iso2: 'NI', country: 'Nicaragua' },
    { iso3: 'NER', iso2: 'NE', country: 'Niger' },
    { iso3: 'NGA', iso2: 'NG', country: 'Nigeria' },
    { iso3: 'NIU', iso2: 'NU', country: 'Niue' },
    { iso3: 'NFK', iso2: 'NF', country: 'Norfolk Island' },
    { iso3: 'MNP', iso2: 'MP', country: 'Northern Mariana Islands' },
    { iso3: 'NOR', iso2: 'NO', country: 'Norway' },
    { iso3: 'OMN', iso2: 'OM', country: 'Oman' },
    { iso3: 'PAK', iso2: 'PK', country: 'Pakistan' },
    { iso3: 'PLW', iso2: 'PW', country: 'Palau' },
    { iso3: 'PSE', iso2: 'PS', country: 'Palestine' },
    { iso3: 'PAN', iso2: 'PA', country: 'Panama' },
    { iso3: 'PNG', iso2: 'PG', country: 'Papua New Guinea' },
    { iso3: 'PRY', iso2: 'PY', country: 'Paraguay' },
    { iso3: 'PER', iso2: 'PE', country: 'Peru' },
    { iso3: 'PHL', iso2: 'PH', country: 'Philippines' },
    { iso3: 'PCN', iso2: 'PN', country: 'Pitcairn' },
    { iso3: 'POL', iso2: 'PL', country: 'Poland' },
    { iso3: 'PRT', iso2: 'PT', country: 'Portugal' },
    { iso3: 'PRI', iso2: 'PR', country: 'Puerto Rico' },
    { iso3: 'QAT', iso2: 'QA', country: 'Qatar' },
    { iso3: 'MKD', iso2: 'MK', country: 'Republic of North Macedonia' },
    { iso3: 'ROU', iso2: 'RO', country: 'Romania' },
    { iso3: 'RUS', iso2: 'RU', country: 'Russian Federation' },
    { iso3: 'RWA', iso2: 'RW', country: 'Rwanda' },
    { iso3: 'REU', iso2: 'RE', country: 'Réunion' },
    { iso3: 'BLM', iso2: 'BL', country: 'Saint Barthélemy' },
    {
        iso3: 'SHN',
        iso2: 'SH',
        country: 'Saint Helena, Ascension and Tristan da Cunha',
    },
    { iso3: 'KNA', iso2: 'KN', country: 'Saint Kitts and Nevis' },
    { iso3: 'LCA', iso2: 'LC', country: 'Saint Lucia' },
    { iso3: 'MAF', iso2: 'MF', country: 'Saint Martin (French)' },
    { iso3: 'SPM', iso2: 'PM', country: 'Saint Pierre and Miquelon' },
    {
        iso3: 'VCT',
        iso2: 'VC',
        country: 'Saint Vincent and the Grenadines',
    },
    { iso3: 'WSM', iso2: 'WS', country: 'Samoa' },
    { iso3: 'SMR', iso2: 'SM', country: 'San Marino' },
    { iso3: 'STP', iso2: 'ST', country: 'Sao Tome and Principe' },
    { iso3: 'SAU', iso2: 'SA', country: 'Saudi Arabia' },
    { iso3: 'SEN', iso2: 'SN', country: 'Senegal' },
    { iso3: 'SRB', iso2: 'RS', country: 'Serbia' },
    { iso3: 'SYC', iso2: 'SC', country: 'Seychelles' },
    { iso3: 'SLE', iso2: 'SL', country: 'Sierra Leone' },
    { iso3: 'SGP', iso2: 'SG', country: 'Singapore' },
    { iso3: 'SXM', iso2: 'SX', country: 'Sint Maarten (Dutch)' },
    { iso3: 'SVK', iso2: 'SK', country: 'Slovakia' },
    { iso3: 'SVN', iso2: 'SI', country: 'Slovenia' },
    { iso3: 'SLB', iso2: 'SB', country: 'Solomon Islands' },
    { iso3: 'SOM', iso2: 'SO', country: 'Somalia' },
    { iso3: 'ZAF', iso2: 'ZA', country: 'South Africa' },
    {
        iso3: 'SGS',
        iso2: 'GS',
        country: 'South Georgia and the South Sandwich Islands',
    },
    { iso3: 'SSD', iso2: 'SS', country: 'South Sudan' },
    { iso3: 'ESP', iso2: 'ES', country: 'Spain' },
    { iso3: 'LKA', iso2: 'LK', country: 'Sri Lanka' },
    { iso3: 'SDN', iso2: 'SD', country: 'Sudan' },
    { iso3: 'SUR', iso2: 'SR', country: 'Suriname' },
    { iso3: 'SJM', iso2: 'SJ', country: 'Svalbard and Jan Mayen' },
    { iso3: 'SWE', iso2: 'SE', country: 'Sweden' },
    { iso3: 'CHE', iso2: 'CH', country: 'Switzerland' },
    { iso3: 'SYR', iso2: 'SY', country: 'Syrian Arab Republic' },
    { iso3: 'TWN', iso2: 'TW', country: 'Taiwan (Province of China)' },
    { iso3: 'TJK', iso2: 'TJ', country: 'Tajikistan' },
    { iso3: 'TZA', iso2: 'TZ', country: 'Tanzania' },
    { iso3: 'THA', iso2: 'TH', country: 'Thailand' },
    { iso3: 'TLS', iso2: 'TL', country: 'Timor-Leste' },
    { iso3: 'TGO', iso2: 'TG', country: 'Togo' },
    { iso3: 'TKL', iso2: 'TK', country: 'Tokelau' },
    { iso3: 'TON', iso2: 'TO', country: 'Tonga' },
    { iso3: 'TTO', iso2: 'TT', country: 'Trinidad and Tobago' },
    { iso3: 'TUN', iso2: 'TN', country: 'Tunisia' },
    { iso3: 'TUR', iso2: 'TR', country: 'Turkey' },
    { iso3: 'TKM', iso2: 'TM', country: 'Turkmenistan' },
    { iso3: 'TCA', iso2: 'TC', country: 'Turks and Caicos Islands' },
    { iso3: 'TUV', iso2: 'TV', country: 'Tuvalu' },
    { iso3: 'UGA', iso2: 'UG', country: 'Uganda' },
    { iso3: 'UKR', iso2: 'UA', country: 'Ukraine' },
    { iso3: 'ARE', iso2: 'AE', country: 'United Arab Emirates' },
    {
        iso3: 'GBR',
        iso2: 'GB',
        country: 'United Kingdom of Great Britain and Northern Ireland',
    },
    {
        iso3: 'UMI',
        iso2: 'UM',
        country: 'United States Minor Outlying Islands',
    },
    { iso3: 'USA', iso2: 'US', country: 'United States' },
    { iso3: 'URY', iso2: 'UY', country: 'Uruguay' },
    { iso3: 'UZB', iso2: 'UZ', country: 'Uzbekistan' },
    { iso3: 'VUT', iso2: 'VU', country: 'Vanuatu' },
    { iso3: 'VEN', iso2: 'VE', country: 'Venezuela' },
    { iso3: 'VNM', iso2: 'VN', country: 'Viet Nam' },
    { iso3: 'VGB', iso2: 'VG', country: 'Virgin Islands (British)' },
    { iso3: 'VIR', iso2: 'VI', country: 'Virgin Islands (U.S.)' },
    { iso3: 'WLF', iso2: 'WF', country: 'Wallis and Futuna' },
    { iso3: 'ESH', iso2: 'EH', country: 'Western Sahara' },
    { iso3: 'YEM', iso2: 'YE', country: 'Yemen' },
    { iso3: 'ZMB', iso2: 'ZM', country: 'Zambia' },
    { iso3: 'ZWE', iso2: 'ZW', country: 'Zimbabwe' },
    { iso3: 'ALA', iso2: 'AX', country: 'Åland Islands' },
];

// <!-- COUNTRY NAME -->

// Get the country names.
const Names = Countries.map((region) => region.country);

// Get record mapping iso2 codes to country names.
const NameByISO2 = Countries.reduce((context, region) => {
    return Object.assign(context, {
        [region.iso2]: region.country,
    });
}, /** @type {Country.ByISO2} */ ({}));

// Get record mapping iso3 codes to country names.
const NameByISO3 = Countries.reduce((context, region) => {
    return Object.assign(context, {
        [region.iso3]: region.country,
    });
}, /** @type {Country.ByISO3} */ ({}));

// <!-- COUNTRY ISO2 -->

// Get the 2-letter ISO codes.
const ISO2s = Countries.map((region) => region.iso2);

// Get record mapping country names to iso2 codes.
const ISO2ByName = Countries.reduce((context, region) => {
    return Object.assign(context, {
        [region.country]: region.iso2,
    });
}, /** @type {Country.ISO2ByName} */ ({}));

// Get record mapping iso3 codes to iso2 codes.
const ISO2ByISO3 = Countries.reduce((context, region) => {
    return Object.assign(context, {
        [region.iso3]: region.iso2,
    });
}, /** @type {Country.ISO2ByISO3} */ ({}));

// <!-- COUNTRY ISO3 -->

// Get the 2-letter ISO codes.
const ISO3s = Countries.map((region) => region.iso3);

// Get record mapping country names to iso3 codes.
const ISO3ByName = Countries.reduce((context, region) => {
    return Object.assign(context, {
        [region.country]: region.iso3,
    });
}, /** @type {Country.ISO3ByName} */ ({}));

// Get record mapping iso3 codes to iso2 codes.
const ISO3ByISO2 = Countries.reduce((context, region) => {
    return Object.assign(context, {
        [region.iso2]: region.iso3,
    });
}, /** @type {Country.ISO3ByISO2} */ ({}));

// <!-- NAMED EXPORTS -->

/** Helper class for country names. */
export class CountryName {
    /** Enum mapping iso2 codes to country names. */
    static ByISO2 = Enum.create(NameByISO2);

    /** Enum mapping iso3 codes to country names. */
    static ByISO3 = Enum.create(NameByISO3);

    /** Get array of country names. */
    static get values() {
        return [...Names];
    }

    /**
     * Check if the passed value is a valid country name.
     *
     * @param {string} value
     * @returns {value is Country.Name}
     */
    static isCountry(value) {
        return (
            typeof value === 'string' &&
            Names.includes(/** @type {Country.Name} */ (value))
        );
    }
}

/** Helper class for ISO2 country codes. */
export class CountryISO2 {
    /** Enum mapping iso2 codes to country names. */
    static ByName = Enum.create(ISO2ByName);

    /** Enum mapping iso3 codes to iso2 codes. */
    static ByISO3 = Enum.create(ISO2ByISO3);

    /** Get array of country names. */
    static get values() {
        return [...ISO2s];
    }

    /**
     * Check if the passed value is a valid country name.
     *
     * @param {string} value
     * @returns {value is Country.ISO2}
     */
    static isCode(value) {
        return (
            typeof value === 'string' &&
            ISO2s.includes(/** @type {Country.ISO2} */ (value))
        );
    }
}

/** Helper class for ISO3 country codes. */
export class CountryISO3 {
    /** Enum mapping iso3 codes to country names. */
    static ByName = Enum.create(ISO3ByName);

    /** Enum mapping iso2 codes to iso3 codes. */
    static ByISO2 = Enum.create(ISO3ByISO2);

    /** Get array of country names. */
    static get values() {
        return [...ISO3s];
    }

    /**
     * Check if the passed value is a valid country name.
     *
     * @param {string} value
     * @returns {value is Country.ISO3}
     */
    static isCode(value) {
        return (
            typeof value === 'string' &&
            ISO3s.includes(/** @type {Country.ISO3} */ (value))
        );
    }
}
