import { Account } from '@/models/v2/accounts';

/**
 * Resource data transfer object.
 * @implements {globalThis.Account.Payload}
 */
export class AccountPayload {
    /** Get the static factory methods. */
    static get create() {
        return {
            /** @type {globalThis.PayloadConstructor<globalThis.Account.Payload>} */
            fromData: (data) => new AccountPayload(data),
        };
    }

    /**
     * Construct a payload instance.
     * @param {Partial<globalThis.Account.Payload>} data
     */
    constructor(data) {
        this.id = data.id;
        this.name = data.name;
        this.reminder = data.reminder;
        this.temp_scale = data.temp_scale;
        this.timezone = data.timezone;

        this.last_upload_at = data.last_upload_at;
        this.created_at = data.created_at;
        this.updated_at = data.updated_at;

        this.account_tree_level = data.account_tree_level;

        this.number_of_locations = data.number_of_locations;
        this.number_of_stations = data.number_of_stations;
        this.number_of_notes = data.number_of_notes;
        this.number_of_hierarchies = data.number_of_hierarchies;
        this.number_of_dataset_batches = data.number_of_dataset_batches;
        this.number_of_mapping_profiles = data.number_of_mapping_profiles;
        this.number_of_report_templates = data.number_of_report_templates;

        this.organization_id = data.organization_id;
        this.organization = data.organization;
    }

    // SERVICE METHODS

    /**
     * Convert the paylod into a model.
     * @returns {globalThis.Account.Model}
     */
    toModel() {
        return Account.create.fromPayload(this);
    }
}
