/**
 * This is responsible for creating the Vuex store
 * from the modules within the `./store` directory.
 *
 * ## Adding Modules
 *
 * https://vuex.vuejs.org/guide/structure.html
 *
 * Register additional modules by creating the appropriate
 * [feature].js file in the `./store` directory and then
 * import it as a module in the appropriate location.
 *
 * ## Using the Store
 *
 * https://vuex.vuejs.org/guide/composition-api.html
 *
 * In the Vue 3 Composition API, use the `useStore` composable
 * provided by the "vuex" package in the setup() function.
 *
 * ```
 * import { useStore } from 'vuex';
 * ```
 *
 * ## Handling Form Input
 *
 * `v-model` is tricky to use when working with a piece of state
 * that belongs to Vuex. Use the `:value` to bind the prop
 * and provide a custom handler on `@input` or `@change`.
 *
 * Alternatively `v-model` a two-way computed property that has
 * a get() and set(value) function.
 *
 * https://vuex.vuejs.org/guide/forms.html
 */

/** Vuex Imports */
import { createStore, createLogger } from 'vuex';

/** Modules */
// import xxx from "./modules/xxx";
import users from './modules/users';
import accounts from './modules/accounts';
import uploader from './modules/uploader';
import analysis from './modules/analysis';

/**
 * Require the modules and return them.
 *
 * See: https://vuex.vuejs.org/guide/hot-reload.html#dynamic-module-hot-reloading
 * @returns {Object} containing context and modules.
 */
function loadModules() {
    const context = require.context('./modules', false, /([a-z_]+)\.js$/i);
    const modules = context
        .keys()
        .map((key) => ({ key, name: key.match(/([a-z_]+)\.js$/i)[1] }))
        .reduce(
            (modules, { key, name }) => ({
                ...modules,
                [name]: context(key).default,
            }),
            {}
        );
    return { context, modules };
}

/** Determine environment */
const debug = process.env.NODE_ENV !== 'production';

/** Load the modules. */
const { context, modules } = loadModules();

/** Export the default store for use by main.js */
const store = createStore({
    modules,

    /**
     * https://vuex.vuejs.org/guide/strict.html
     */
    strict: debug,

    /**
     * Vuex store accepts plugins that exposes hooks for each
     * mutation. A plugin is simply a function that receives
     * the store as the only argument. eg. `usePlugin(store)`
     *
     * Plugins cannot directly mutate state, but this may be useful
     * if we need to sync some external source (like a websocket)
     * to the mutation of a particular state.
     *
     * https://vuex.vuejs.org/guide/plugins.html#committing-mutations-inside-plugins
     * https://vuex.vuejs.org/guide/plugins.html#built-in-logger-plugin
     */
    plugins: debug ? [createLogger()] : [],
});

/**
 * Enables hot module reloading for Vuex store.
 *
 * See: https://vuex.vuejs.org/guide/hot-reload.html#dynamic-module-hot-reloading
 */
if (module.hot) {
    module.hot.accept(context.id, () => {
        const { modules } = loadModules();
        store.hotUpdate({
            modules,
        });
    });
}

export default store;
