// <!-- API -->
import { Assertion } from '@/store/types/ECNBAssertions';
import { UploadConfiguration } from '@/store/types/uploader/state';

// <!-- TYPES -->

import { ECNBState } from '@/store/types/ECNBStore';
import { ECNBModule } from '@/store/types/ECNBModule';

/**
 * @class
 * Asynchronous actions that can be invoked with the appropriate context.
 */
export class UploadConfigurationActions {
    /**
     * Asynchronous assertions that will test predicates
     * and fail when appropriate, throwing errors to interrupt actions.
     * @param {import('vuex').ActionContext<UploadConfiguration, ECNBState>} context
     */
    static assert(context) {
        const $ = UploadConfigurationActions;
        return {
            /**
             * Assert valid filetype.
             * @param {String} payload
             * @returns {Promise<void>}
             */
            filetype: async (payload) => {
                const { rootState } = context;
                const { config } = rootState.uploader;
                const type = payload;
                await Assertion.expect(type).isNotNil();
                if (!config.allowedFileTypes.includes('*')) {
                    await Assertion.expect(config.allowedFileTypes).includes(
                        type
                    );
                }
            },
            /**
             * Assert valid file size.
             * @param {Number} payload
             * @returns {Promise<void>}
             */
            filesize: async (payload) => {
                const { rootState } = context;
                const { config } = rootState.uploader;
                const size = payload;
                await Assertion.expect(size).isNotNil();
                await Assertion.expect(size).isNumber();
                await Assertion.expect(size).isGreaterThan(0);
                await Assertion.expect(size).isLessThanOrEqual(
                    config.fileSizeLimit
                );
            },
            /**
             * Assert count is below or equal to (and not over) capacity.
             * @param {Number} payload
             * @returns {Promise<void>}
             */
            belowOrAtCapacity: async (payload) => {
                const { rootState } = context;
                const { config } = rootState.uploader;
                const count = payload;
                await Assertion.expect(count).isNotNil();
                await Assertion.expect(count).isNumber();
                await Assertion.expect(count, `Capacity`).isLessThanOrEqual(
                    config.fileCountLimit,
                    {
                        failure: `Selection count (${count}) is over the capacity limit (${config.fileCountLimit}).`,
                    }
                );
            },
            /**
             * Assert count is below (and not equal to or over) capacity.
             * @param {Number} payload
             * @returns {Promise<void>}
             */
            belowCapacity: async (payload) => {
                const { rootState } = context;
                const { config } = rootState.uploader;
                const count = payload;
                await $.assert(context).assertBelowOrAtCapacity(count);
                await Assertion.expect(count, `Capacity`).isLessThan(
                    config.fileCountLimit,
                    {
                        failure: `Selection count (${count}) is at or over the capacity limit (${config.fileCountLimit}).`,
                    }
                );
            },
        };
    }
}
