import { User } from '@/models/v2/users/User';

/**
 * Resource data transfer object.
 * @implements {globalThis.User.Payload}
 */
export class UserPayload {
    /** Get the static factory methods. */
    static get create() {
        return {
            /** @type {globalThis.PayloadConstructor<globalThis.User.Payload>} */
            fromData: (data) => new UserPayload(data),
        };
    }

    /**
     * Construct a payload instance.
     * @param {Partial<globalThis.User.Payload>} data
     */
    constructor(data) {
        this.id = data.id;
        this.user_name = data.user_name;
        this.email = data.email;
        this.first_name = data.first_name;
        this.last_name = data.last_name;

        this.is_super = data.is_super;
        this.is_protected = data.is_protected;

        this.created_at = data.created_at;
        this.updated_at = data.updated_at;
        this.last_login_at = data.last_login_at;

        this.number_of_accounts = data.number_of_accounts;
        this.number_of_organization_accounts =
            data.number_of_organization_accounts;
        this.number_of_organizations = data.number_of_organizations;

        this.accounts = data.accounts;
        this.organizations = data.organizations;
    }

    // SERVICE METHODS

    /**
     * Convert the paylod into a model.
     * @returns {globalThis.User.Model}
     */
    toModel() {
        return User.create.fromPayload(this);
    }
}
