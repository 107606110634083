// <!-- INTERFACES -->
import { ModelResource } from '@/utils/resources';
import { ModelAttributeFactory } from '@/utils/resources';
import { SubscriptionPayload } from '@/payloads/v2/subscriptions/SubscriptionPayload';

// <!-- RELATIONSHIPS -->
import { PlanModel } from '@/models/v2/plans/PlanModel';
import { OrganizationModel } from '@/models/v2/organizations/OrganizationModel';

/**
 * Create a specialized resource instance.
 *
 * @extends {ModelResource<PayloadData,ModelData>}
 * @implements {Resource.Model<PayloadData,ModelData>}
 * @implements {ModelData}
 */
export class SubscriptionModel extends ModelResource {
    // <!-- TYPE ALIASES -->

    /** @typedef {Subscription.Payload} PayloadData */
    /** @typedef {Subscription.Model} ModelData */

    // <!-- STATIC FACTORY METHODS -->

    /**
     * Create resource instance from passed attributes.
     *
     * @param {Partial<ModelData>} attributes
     * @returns {SubscriptionModel}
     */
    static create(attributes) {
        return new SubscriptionModel(attributes);
    }

    /**
     * Create resource instance from passed attributes.
     *
     * @param {Partial<PayloadData>} data
     * @returns {SubscriptionModel}
     */
    static fromPayload(data) {
        const attributes = SubscriptionModel.attributesFromPayload(data);
        return SubscriptionModel.create(attributes);
    }

    // <!-- STATIC UTILITY METHODS -->

    /** @type {Readonly<ModelData>} */
    static get defaults() {
        return {
            id: null,
            organizationId: null,
            organization: null,
            planId: null,
            plan: null,
            pricePerYear: 0,
            pricePerMonth: 0,
            maxUsers: null,
            maxLocations: null,
            isPaid: false,
            isActive: false,
            isExpired: false,
            paidAt: null,
            activeAt: null,
            expireAt: null,
            createdAt: null,
            updatedAt: null,
        };
    }

    /**
     * Create resource attributes from the payload data.
     *
     * @param {Partial<PayloadData>} payload
     * @returns {Partial<ModelData>}
     */
    static attributesFromPayload(payload = {}) {
        // Get transformer functions.
        const { parse } = ModelResource;

        // Transform attributes.
        const plan = PlanModel.fromPayload(payload.subscription_level);
        const organization = payload.organization
            ? OrganizationModel.fromPayload(payload.organization)
            : null;

        // Return the created state.
        return {
            id: payload.id,
            organizationId: payload.organization_id,
            organization,
            planId: payload.subscription_level_id,
            plan,
            pricePerYear: parse.price(payload.price_per_year),
            pricePerMonth: parse.price(payload.price_per_month),
            maxUsers: payload.max_users,
            maxLocations: payload.max_locations,
            isPaid: payload.is_paid,
            isActive: payload.is_active,
            isExpired: payload.is_expired,
            paidAt: parse.dateString(payload.paid_at),
            activeAt: parse.dateString(payload.active_at),
            expireAt: parse.dateString(payload.expire_at),
            createdAt: parse.dateString(payload.created_at),
            updatedAt: parse.dateString(payload.updated_at),
        };
    }

    // <!-- CONSTRUCTOR -->

    /**
     * Create resource instance.
     *
     * @param {Partial<ModelData>} attributes
     */
    constructor(attributes) {
        super(
            attributes,
            ModelAttributeFactory.create(
                SubscriptionModel.attributesFromPayload,
                SubscriptionModel.defaults
            )
        );

        // Hydrate sub-resource.
        if (
            this.exists('organization') &&
            !(this.organization instanceof OrganizationModel)
        ) {
            this.organization = OrganizationModel.create(this.organization);
        }

        // Hydrate sub-resource.
        if (this.exists('plan') && !(this.plan instanceof PlanModel)) {
            this.plan = PlanModel.create(this.plan);
        }
    }

    /** Displays the specified tag when printing to the console. */
    get [Symbol.toStringTag]() {
        return 'Subscription\\Model';
    }

    // <!-- RESOURCE INTERFACE -->

    /** Get shallow copy of this instance as a resource. */
    toPayload() {
        return SubscriptionPayload.fromModel(this);
    }

    /** Get shallow copy of this instance as a resource. */
    toModel() {
        return this.clone();
    }

    /** Get shallow copy of this instance. */
    clone() {
        return /** @type {this} */ (SubscriptionModel.create(this));
    }

    // <!-- ATTRIBUTE::PROPERTIES -->

    get id() {
        return this.get('id');
    }

    set id(value) {
        this.set('id', value);
    }

    get organizationId() {
        return this.get('organizationId');
    }

    set organizationId(value) {
        this.set('organizationId', value);
    }

    get organization() {
        return this.get('organization');
    }

    set organization(value) {
        this.set('organization', value);
    }

    get planId() {
        return this.get('planId');
    }

    set planId(value) {
        this.set('planId', value);
    }

    get plan() {
        return this.get('plan');
    }

    set plan(value) {
        this.set('plan', value);
    }

    get pricePerYear() {
        return this.get('pricePerYear');
    }

    set pricePerYear(value) {
        this.set('pricePerYear', value);
    }

    get pricePerMonth() {
        return this.get('pricePerMonth');
    }

    set pricePerMonth(value) {
        this.set('pricePerMonth', value);
    }

    get maxUsers() {
        return this.get('maxUsers');
    }

    set maxUsers(value) {
        this.set('maxUsers', value);
    }

    get maxLocations() {
        return this.get('maxLocations');
    }

    set maxLocations(value) {
        this.set('maxLocations', value);
    }

    get isPaid() {
        return this.get('isPaid');
    }

    set isPaid(value) {
        this.set('isPaid', value);
    }

    get isActive() {
        return this.get('isActive');
    }

    set isActive(value) {
        this.set('isActive', value);
    }

    get isExpired() {
        return this.get('isExpired');
    }

    set isExpired(value) {
        this.set('isExpired', value);
    }

    get paidAt() {
        return this.get('paidAt');
    }

    set paidAt(value) {
        this.set('paidAt', value);
    }

    get expireAt() {
        return this.get('expireAt');
    }

    set expireAt(value) {
        this.set('expireAt', value);
    }

    get createdAt() {
        return this.get('createdAt');
    }

    set createdAt(value) {
        this.set('createdAt', value);
    }

    get updatedAt() {
        return this.get('updatedAt');
    }

    set updatedAt(value) {
        this.set('updatedAt', value);
    }
}
