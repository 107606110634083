// <!-- UTILITIES -->
import pick from 'just-pick';
import isNil from 'lodash-es/isNil';

// <!-- TYPES -->
// ==== GRAPH FILTERS ====
/** @typedef {import('@/utils/filters').IAxisModifier} IAxisModifier Record containing the checked modifier state for a limit or scale. */

/**
 * @class
 * Record containing the inclusive lower and upper axis range bounds.
 * @implements {IAxisModifier}
 */
export class AxisModifier {
    /**
     * Create an axis range.
     * @param {Readonly<Partial<IAxisModifier>>} [props]
     * @returns {IAxisModifier}
     */
    static create = (props = {}) => new AxisModifier(props);

    /**
     * Clone an existing axis range.
     * @param {Readonly<IAxisModifier>} [source]
     * @returns {IAxisModifier}
     */
    static clone = (source = new AxisModifier()) => new AxisModifier(source);

    /**
     * Extract only the data members from an existing instance.
     * @param {Readonly<IAxisModifier>} [target]
     * @returns {IAxisModifier}
     */
    static data = (target) => pick(target, 'checked');

    /** @type {(value: any) => Boolean} Predicate test to see if `value` is strictly `undefined`. */
    static isStrictlyUndefined = (value) => value === undefined;

    /** @type {(value: any) => Boolean} Predicate test to see if `value` is `undefined`, `null`, or an empty `String`. */
    static isMissing = (value) => isNil(value) || value === '';

    /** @type {(checked: String | 0 | 1 | Boolean) => Boolean} Cast input `checked` to a `Boolean`. */
    static castAsChecked = (checked) =>
        AxisModifier.isMissing(checked)
            ? false
            : [true, 'true', 'TRUE', 'T', 1, '1', 'checked'].includes(checked);

    /**
     * Parse input string as a boolean checkbox value.
     * @param {String | 0 | 1 | Boolean | null} [checked] Value to parse.
     * @param {Readonly<Partial<{ whenUndefined: Boolean, whenInvalid: Boolean }>>} [defaults] Default values to use.
     */
    static parse(checked = undefined, defaults = {}) {
        const { whenUndefined = false, whenInvalid = false } = defaults;
        if (AxisModifier.isStrictlyUndefined(checked)) {
            // If strictly undefined, return `whenUndefined`.
            return whenUndefined;
        } else if (AxisModifier.isMissing(checked)) {
            // If invalid, return `whenInvalid`.
            return whenInvalid;
        } else {
            // Cast to boolean and return.
            return AxisModifier.castAsChecked(checked);
        }
    }

    /**
     * Create an axis range.
     * @param {Readonly<Partial<IAxisModifier>>} [props]
     */
    constructor(props) {
        // Get the default values (when a property is `undefined`).
        const { checked = false } = props ?? {};
        /** @type {IAxisModifier['checked']} Modifier enabled state. */
        this.checked = checked;
    }
}

// <!-- DEFAULT -->
export default AxisModifier;
