<template>
    <div class="v-select-wrapper">
        <VueSelect
            class="w-full h-10"
            v-bind="attributes"
            @input="events.input('input', $event)"
            @change="events.change('change', $event)"
            @open="events.open('open', $event)"
            @close="events.close('close', $event)"
            @option:selecting="
                events['option:selecting']('option:selecting', $event)
            "
            @option:selected="
                events['option:selected']('option:selected', $event)
            "
            @option:deselecting="
                events['option:deselecting']('option:deselecting', $event)
            "
            @option:deselected="
                events['option:deselected']('option:deselected', $event)
            "
            @search="events['search']('search', $event)"
            @search:blur="events['search:blur']('search:blur', $event)"
            @search:focus="events['search:focus']('search:focus', $event)"
            @search:input="events['search:input']('search:input', $event)"
            @search:compositionstart="
                events['search:compositionstart'](
                    'search:compositionstart',
                    $event
                )
            "
            @search:compositionend="
                events['search:compositionend']('search:compositionend', $event)
            "
            @update:modelValue="
                events['update:modelValue']('update:modelValue', $event)
            "
        >
            <template
                v-if="$slots.header"
                #header="headerProps"
            >
                <slot
                    name="header"
                    v-bind="headerProps"
                ></slot>
            </template>
            <template
                v-if="$slots.footer"
                #footer="footerProps"
            >
                <slot
                    name="footer"
                    v-bind="footerProps"
                ></slot>
            </template>
            <template
                v-if="$slots['list-header']"
                #list-header="listHeaderProps"
            >
                <slot
                    name="list-header"
                    v-bind="listHeaderProps"
                ></slot>
            </template>
            <template
                v-if="$slots['list-footer']"
                #list-footer="listFooterProps"
            >
                <slot
                    name="list-footer"
                    v-bind="listFooterProps"
                ></slot>
            </template>
            <template
                v-if="$slots['no-options']"
                #no-options="noOptionsProps"
            >
                <slot
                    name="no-options"
                    v-bind="noOptionsProps"
                ></slot>
            </template>
            <template
                v-if="$slots['open-indicator']"
                #open-indicator="openIndicatorProps"
            >
                <slot
                    name="open-indicator"
                    v-bind="openIndicatorProps"
                ></slot>
            </template>
            <template
                v-if="$slots.option"
                #option="optionProps"
            >
                <slot
                    name="option"
                    v-bind="optionProps"
                ></slot>
            </template>
            <template
                v-if="$slots.search"
                #search="searchProps"
            >
                <slot
                    name="search"
                    v-bind="searchProps"
                ></slot>
            </template>
            <template
                v-if="$slots['selected-option']"
                #selected-option="selectedOptionProps"
            >
                <slot
                    name="selected-option"
                    v-bind="selectedOptionProps"
                ></slot>
            </template>
            <template
                v-if="$slots['selected-option-container']"
                #selected-option-container="selectedOptionContainerProps"
            >
                <slot
                    name="selected-option-container"
                    v-bind="selectedOptionContainerProps"
                ></slot>
            </template>
            <template
                v-if="$slots.spinner"
                #spinner="spinnerProps"
            >
                <slot
                    name="spinner"
                    v-bind="spinnerProps"
                ></slot>
            </template>
        </VueSelect>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent, computed } from 'vue';

    // <!-- STYLES -->
    import 'vue-select/dist/vue-select.css';

    // <!-- COMPONENTS -->
    import VueSelect from 'vue-select';

    // <!-- TYPES -->
    /** @typedef {import('vue-select').VueSelectProps} VueSelectProps */
    /** @typedef {import('@/plugins/vue-select').VueSelectFormKitProps} VueSelectFormKitProps */
    /** @typedef {import('@formkit/core').FormKitFrameworkContext} FormKitFrameworkContext */

    // <!-- CONSTANTS -->

    /**
     * Legal autocomplete values.
     */
    export const AutocompleteValue = /** @type {const} */ ({
        off: 'off',
        on: 'on',
        // <!-- name related -->
        name: 'name',
        'honorific-prefix': 'honorific-prefix',
        'given-name': 'given-name',
        'additional-name': 'additional-name',
        'family-name': 'family-name',
        'honorific-suffix': 'honorific-suffix',
        nickname: 'nickname',
        // <!-- profile related -->
        email: 'email',
        username: 'username',
        password: 'current-password',
        'current-password': 'current-password',
        'new-password': 'new-password',
        otc: 'one-time-code',
        otp: 'one-time-code',
        'one-time-code': 'one-time-code',
        // <!-- identity related -->
        title: 'organization-title',
        'organization-title': 'organization-title',
        organization: 'organization',
        // <!-- address related -->
        street: 'street-address',
        'street-address': 'street-address',
        'address-line1': 'address-line1',
        'address-line2': 'address-line2',
        'address-line3': 'address-line3',
        'address-level1': 'address-level1',
        'address-level2': 'address-level2',
        'address-level3': 'address-level3',
        'address-level4': 'address-level4',
        country: 'country',
        'country-name': 'country-name',
        'postal-code': 'postal-code',
        zipcode: 'postal-code',
        // <!-- credit card related -->
        'cc-name': 'cc-name',
        'cc-given-name': 'cc-given-name',
        'cc-additional-name': 'cc-additional-name',
        'cc-family-name': 'cc-family-name',
        'cc-number': 'cc-number',
        'cc-exp': 'cc-exp',
        'cc-exp-month': 'cc-exp-month',
        'cc-exp-year': 'cc-exp-year',
        'cc-csc': 'cc-csc',
        'cc-type': 'cc-type',
        // <!-- transaction values -->
        'transaction-currency': 'transaction-currency',
        'transaction-amount': 'transaction-amount',
        // <!-- language values -->
        language: 'language',
        // <!-- demographic values -->
        bday: 'bday',
        'bday-day': 'bday-day',
        'bday-month': 'bday-month',
        'bday-year': 'bday-year',
        gender: 'sex',
        sex: 'sex',
        // <!-- telephone values -->
        tel: 'tel',
        'tel-country-code': 'tel-country-code',
        'tel-national': 'tel-national',
        'tel-area-code': 'tel-area-code',
        'tel-local': 'tel-local',
        'tel-extension': 'tel-extension',
        // <!-- misc -->
        impp: 'impp',
        url: 'url',
        photo: 'photo',
    });

    /**
     * Array of autocomplete values.
     */
    export const AutocompleteValues = Object.values(AutocompleteValue);

    /**
     * Events that can be emitted from the VueSelect component.
     */
    const VueSelectEvents = /** @type {const}*/ ([
        'input',
        'change',
        'open',
        'close',
        'update:modelValue',
        'search',
        'search:compositionstart',
        'search:compositionend',
        'search:keydown',
        'search:blur',
        'search:focus',
        'search:input',
        'option:created',
        'option:selecting',
        'option:selected',
        'option:deselecting',
        'option:deselected',
    ]);

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'VueSelect',
        components: {
            VueSelect,
        },
        props: {
            /** Context provided by FormKit. */
            context: {
                /** @type {V.PropType<FormKitFrameworkContext & Record<VueSelectFormKitProps[Number], any>>} Context. */
                type: Object,
                required: true,
            },
        },
        emits: [...VueSelectEvents],
        setup(props, context) {
            /**
             * Log event and forward it up the chain.
             * @param {typeof VueSelectEvents[Number]} key Event key.
             * @param {any} event Event arguments.
             */
            const onEvent = (key, event) => {
                const node = props.context.node;
                if (debug) {
                    console.groupCollapsed(
                        `[${key}::vs__input] @ ${new Date().toLocaleDateString()}`
                    );
                    console.dir({ key, event, node });
                    console.groupEnd();
                }
                context.emit(key, event, node);
            };

            /**
             * Handle input and pass it to the FormKit node.
             * @param {any} value
             */
            const onCommit = (value) => {
                if (debug) {
                    console.groupCollapsed(
                        `[commit::vs__input] @ ${new Date().toLocaleDateString()}`
                    );
                    console.dir({ selected: value, context: props.context });
                    console.groupEnd();
                }
                props.context.node.input(value);
            };

            /**
             * Determine if the context exists.
             */
            const hasFormKitContext = computed(
                () => !!props && !!props.context
            );

            /**
             * Does the prop have a defined value?
             * @param {VueSelectFormKitProps[Number] | 'vs__label' | 'debug'} key
             */
            const hasVueSelectProp = (key) =>
                hasFormKitContext.value && props.context[key] !== undefined;

            /**
             * Get prop value associated with a context.
             * @param {VueSelectFormKitProps[Number] | 'vs__label' | 'debug'} key
             * @param {any} defaultValue
             * */
            const getVueSelectProp = (key, defaultValue) => {
                return hasVueSelectProp(key)
                    ? props.context[key]
                    : defaultValue;
            };

            /** Determine if it's in debug mode. */
            const debug = getVueSelectProp('debug', true);

            /**
             * Prepare the params with default values.
             * @type {Record<keyof Omit<VueSelectProps, 'components'|'uid'|'searchInputQuerySelector'>, any>} Parameters.
             */
            const params = {
                /** @type {Boolean} Append the dropdown element to the end of the body and size/position it dynamically. Use it if you have overflow or z-index issues. */
                appendToBody: getVueSelectProp('appendToBody', false),
                /** @type {keyof AutocompleteValue} The value provided here will be bound to the autocomplete HTML attribute on the search input. Defaults to `off`. */
                autocomplete: getVueSelectProp(
                    'autocomplete',
                    AutocompleteValue.off
                ),
                /** @type {Boolean} When true, the dropdown will automatically scroll to ensure that the option highlighted is fully within the dropdown viewport when navigating with keyboard arrows. */
                autoscroll: getVueSelectProp('autoscroll', true),
                /**
                 * @type {Function}
                 * {@link https://vue-select.org/api/props.html#calculateposition Documentation}
                 */
                calculatePosition: getVueSelectProp(
                    'calculatePosition',
                    undefined
                    // /**
                    //  * @param {HTMLUListElement} dropdownList
                    //  * @param {Vue} component current instance of vue select
                    //  * @param {Object} style
                    //  * @param {String} style.width calculated width in pixels of the dropdown menu
                    //  * @param {string} style.top absolute position top value in pixels relative to the document
                    //  * @param {string} style.left absolute position left value in pixels relative to the document
                    //  * @return {function|void}
                    //  */
                    // (dropdownList, component, { width, top, left }) => {
                    //     dropdownList.style.top = top;
                    //     dropdownList.style.left = left;
                    //     dropdownList.style.width = width;
                    // }
                ),
                /** @type {Boolean} Can the user clear the selected property? */
                clearable: getVueSelectProp('clearable', true),
                /** @type {Function} Enables/disables clearing the search text when the search input is blurred. */
                clearSearchOnBlur: getVueSelectProp(
                    'clearSearchOnBlur',
                    function ({ clearSearchOnSelect, multiple }) {
                        return clearSearchOnSelect && !multiple;
                    }
                ),
                /** @type {Boolean} Enables/disables clearing the search text when an option is selected. */
                clearSearchOnSelect: getVueSelectProp(
                    'clearSearchOnSelect',
                    true
                ),
                /** @type {Boolean} Close a dropdown when an option is chosen. Set to false to keep the dropdown open (useful when combined with multi-select, for example) */
                closeOnSelect: getVueSelectProp('closeOnSelect', true),
                /** @type {Function} User defined function for adding Options. */
                createOption: getVueSelectProp('createOption', (option) => {
                    let newOption = option;
                    if (typeof params.options[0] === 'object') {
                        newOption = { [params.label]: option };
                    }
                    context.emit('option:created', newOption);
                    return newOption;
                }),
                /** @type {Boolean} Determines whether the user can deselect an option by clicking it from within the dropdown menu. */
                deselectFromDropdown: getVueSelectProp(
                    'deselectFromDropdown',
                    false
                ),
                /** @type {String} Sets RTL support. Accepts ltr, rtl, auto. */
                dir: getVueSelectProp('dir', 'ltr'),
                /** @type {Boolean} Disable the entire component. */
                disabled: getVueSelectProp('disabled', false),
                /** @type {Function} Determines whether the dropdown should open. Used for overriding the default dropdown behaviour. Receives the vue-select instance as the single argument to the function. */
                dropdownShouldOpen: getVueSelectProp(
                    'dropdownShouldOpen',
                    ({ noDrop, open, mutableLoading }) => {
                        return noDrop ? false : open && !mutableLoading;
                    }
                ),
                /** @type {Function} Callback to filter results when search text is provided. Default implementation loops each option, and returns the result of this.filterBy. */
                filter: getVueSelectProp('filter', (options, search) => {
                    return options.filter((option) => {
                        let label = params.getOptionLabel(option);
                        if (typeof label === 'number') {
                            label = label.toString();
                        }
                        return params.filterBy(option, label, search);
                    });
                }),
                /** @type {Boolean} When true, existing options will be filtered by the search text. Should not be used in conjunction with taggable. */
                filterable: getVueSelectProp('filterable', true),
                /** @type {Function} Callback to determine if the provided option should match the current search text. Used to determine if the option should be displayed. */
                filterBy: getVueSelectProp(
                    'filterBy',
                    (option, label, search) => {
                        return (
                            (label || '')
                                .toLocaleLowerCase()
                                .indexOf(search.toLocaleLowerCase()) > -1
                        );
                    }
                ),
                /** @type {Function} Callback to get an option key. If option is an object and has an id, returns option.id by default, otherwise tries to serialize option to JSON. */
                getOptionKey: getVueSelectProp('getOptionKey', (option) => {
                    if (typeof option === 'object' && option.id) {
                        return option.id;
                    } else {
                        try {
                            return JSON.stringify(option);
                        } catch (e) {
                            console.warn(
                                `[vue-select warn]: Could not stringify option ` +
                                    `to generate unique key. Please provide 'getOptionKey' prop ` +
                                    `to return a unique key for each option.\n` +
                                    'https://vue-select.org/api/props.html#getoptionkey'
                            );
                            return null;
                        }
                    }
                }),
                /** @type {Function} Callback to generate the label text. If {option} is an object, returns option[this.label] by default */
                getOptionLabel: getVueSelectProp('getOptionLabel', (option) => {
                    if (typeof option === 'object') {
                        if (!option.hasOwnProperty(params.label)) {
                            return console.warn(
                                `[vue-select warn]: Label key "option.${params.label}" does not` +
                                    ` exist in options object ${JSON.stringify(
                                        option
                                    )}.\n` +
                                    'https://vue-select.org/api/props.html#getoptionlabel'
                            );
                        }
                        return option[params.label];
                    }
                    return option;
                }),
                /** @type {String} Sets the id of the input element. */
                inputId: getVueSelectProp('inputId', undefined),
                /** @type {Boolean} Show spinner if the component is in a loading state. */
                loading: getVueSelectProp('loading', false),
                /** @type {*} ??? */
                mapKeydown: getVueSelectProp('mapKeydown', undefined),
                /** @type {Boolean} Equivalent to the multiple attribute on a <select> input. */
                multiple: getVueSelectProp('multiple', false),
                /** @type {Boolean} Disable the dropdown entirely. */
                noDrop: getVueSelectProp('noDrop', false),
                /** @type {Function} Select the current value if selectOnKeyCodes is enabled */
                onTab: getVueSelectProp('onTab', function () {
                    if (params.selectOnKeyCodes) {
                        // Input will have this function.
                        this.typeAheadSelect();
                    }
                }),
                /** @type {Array} An array of strings or objects to be used as dropdown choices. If you are using an array of objects, vue-select will look for a label key (ex. [{label: 'Canada', value: 'CA'}]). A custom label key can be set with the label prop. */
                options: getVueSelectProp('options', []),
                /** @type {String} Equivalent to the placeholder attribute on an <input>. */
                placeholder: getVueSelectProp('placeholder', ''),
                /** @type {Boolean} When true, newly created tags will be added to the options list. */
                pushTags: getVueSelectProp('pushTags', false),
                /** @type {Function} When working with objects, the reduce prop allows you to transform a given object to only the information you want passed to a v-model binding or @input event. */
                reduce: getVueSelectProp('reduce', (option) => option),
                /** @type {Boolean | Function} When false, updating the options will not reset the selected value. */
                resetOnOptionsChange: getVueSelectProp(
                    'resetOnOptionsChange',
                    false
                ),
                /** @type {Boolean} Enable/disable filtering the options. */
                searchable: getVueSelectProp('searchable', true),
                /** @type {Function} The selectable prop determines if an option is selectable or not. If selectable returns false for a given option, it will be displayed with a vs__dropdown-option--disabled class. The option will be disabled and unable to be selected. */
                selectable: getVueSelectProp('selectable', (option) => true),
                /** @type {Boolean} When true, hitting the 'tab' key will select the current select value. */
                selectOnTab: getVueSelectProp('selectOnTab', false),
                /** @type {Number[]} When keycode is entered, using it will select the current select value. */
                selectOnKeyCodes: getVueSelectProp(
                    'selectOnKeyCodes',
                    [9, 13, 39]
                ),
                /** @type {Number} Set the tabindex for the input field. */
                tabindex: getVueSelectProp('tabindex', null),
                /** @type {Boolean} Enable/disable creating options from searchInput. */
                taggable: getVueSelectProp('taggable', false),
                /** @type {String} Sets a Vue transition property on the .dropdown-menu. vue-select does not include CSS for transitions, you'll need to add them yourself. */
                transition: getVueSelectProp('transition', 'fade'),
                /** @type {String} Tells vue-select what key to use when generating option labels when each option is an object. */
                label: getVueSelectProp('vs__label', 'label'),
                /** @type {String} Contains the currently selected value. Very similar to a value attribute on an <input>. You can listen for changes using the 'input' event. */
                value: props.context?._value ?? null,
            };
            /**
             * Get prop event associated with a context.
             * @param {VueSelectFormKitProps[Number] | 'vs__label' | 'debug'} key
             * @param {Function} defaultEvent
             * */
            const getVueSelectEvent = (key, defaultEvent) => {
                const onCallback = getVueSelectProp(key, defaultEvent);
                return (name, event) => {
                    const node = props.context.node;
                    onCallback(name, event, node);
                };
            };

            /**
             * Event handlers.
             */
            const handlers = {
                input: getVueSelectEvent('onInput', onEvent),
                change: getVueSelectEvent('onChange', onEvent),
                open: getVueSelectEvent('onOpen', onEvent),
                close: getVueSelectEvent('onClose', onEvent),
                ['option:selecting']: getVueSelectEvent(
                    'onOptionSelecting',
                    onEvent
                ),
                ['option:selected']: getVueSelectEvent(
                    'onOptionSelected',
                    onEvent
                ),
                ['option:deselecting']: getVueSelectEvent(
                    'onOptionDeselecting',
                    onEvent
                ),
                ['option:created']: getVueSelectEvent(
                    'onOptionCreated',
                    onEvent
                ),
                ['search']: getVueSelectEvent('onSearch', onEvent),
                ['search:blur']: getVueSelectEvent('onSearchBlur', onEvent),
                ['search:focus']: getVueSelectEvent('onSearchFocus', onEvent),
                ['search:compositionstart']: getVueSelectEvent(
                    'onSearchCompositionStart',
                    onEvent
                ),
                ['search:compositionend']: getVueSelectEvent(
                    'onSearchCompositionEnd',
                    onEvent
                ),
                ['search:input']: getVueSelectEvent('onSearchInput', onEvent),
                ['update:modelValue']: getVueSelectEvent(
                    'onUpdateModelValue',
                    onEvent
                ),
            };

            return {
                debug,
                attributes: params,
                events: handlers,
                onEvent,
                onCommit,
            };
        },
    });
</script>

<style>
    :root {
        --vs-font-size: 1rem;
        --vs-line-height: 1.4;
    }

    input.vs__search[type='search'] {
        border: none;
        padding: 0;
    }
</style>
