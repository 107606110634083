// <!-- TYPES -->

import { ECNBState } from '@/store/types/ECNBStore';
import { UploadState } from '@/store/types/uploader/state';
import { UploadModule } from '@/store/types/uploader/module';

/**
 * @type {import('vuex').Module<UploadState, ECNBState>} Module to export.
 */
const module = {
    namespaced: UploadModule.module.namespaced,
    state: UploadModule.module.state,
    modules: UploadModule.module.modules,
    getters: UploadModule.module.getters,
    mutations: UploadModule.module.mutations,
    actions: UploadModule.module.actions,
};

// EXPOSE
export default module;
