// <!-- ENUMS -->
import { AlertType } from './AlertType';
import { ModalIcon } from './ModalIcon';
import { ReminderFrequency } from './ReminderFrequency';
import { ReportType } from './ReportType';
import { ResourceType } from './ResourceType';
import { Size } from './Size';
import { StorageKey } from './StorageKey';
import { TemperatureScale } from './TemperatureScale';
import { TemperatureUnit } from './TemperatureUnit';
import { Theme } from './Theme';
import { Unit } from './Unit';
import { UserAccountRole } from './UserAccountRole';

/**
 * Provides access to multiple enums.
 */
export class Constants {
    static AlertType = AlertType;
    static ModalIcon = ModalIcon;
    static ReminderFrequency = ReminderFrequency;
    static ReportType = ReportType;
    static ResourceType = ResourceType;
    static Size = Size;
    static StorageKey = StorageKey;
    static TemperatureScale = TemperatureScale;
    static TemperatureUnit = TemperatureUnit;
    static Theme = Theme;
    static Unit = Unit;
    static UserAccountRole = UserAccountRole;
}

export default Constants;
