// <!-- API -->
import { ECNBResourceIndexState } from '@/store/types/cache/state';
import { ECNBResourceIndexStateMutations } from '@/store/types/cache/mutations';

// <!-- TYPES -->
import { ECNBState } from '@/store/types/ECNBStore';
import { ECNBModule } from '@/store/types/ECNBModule';
import { Assertion } from '@/store/types/ECNBAssertions';
import { ECNBResourceStatus } from '@/store/types/cache/state/ECNBResourceIndexState';

// CLASS
/**
 * @class
 * Cache resource module.
 */
export class ECNBResourceCacheModule extends ECNBModule {
    /**
     * Name of the module.
     */
    static get namespace() {
        return 'index';
    }

    /**
     * Module state, getters, mutations, and actions.
     */
    static get module() {
        // ts-ignore
        const _ = this;
        // EXPOSE
        return {
            namespaced: true,
            /** @type {() => ECNBResourceIndexState<any, any>} */
            state: () => {
                throw new Error(
                    `Not implemented. Overwrite in child implementation.`
                );
            },
            get getters() {
                return {};
            },
            get mutations() {
                const $ = ECNBResourceIndexStateMutations;
                const $set = $.set;
                /**
                 * Set mutations.
                 */
                const setters = {
                    /**
                     * Directly assign provided payload to the existing property.
                     * @template {any} [K=any] Resource id type.
                     * @template {{ id: K }} [V=any] Resource type.
                     * @template {ECNBResourceIndexState<K, V>} [S=any] State type.
                     * @param {S} state State instance.
                     * @param {Map<K, V> | null} [payload] Optional payload. Clears property cache if null.
                     */
                    setIndex: (state, payload) => {
                        $set(state).index(payload);
                    },

                    /**
                     * Directly assign provided payload to the existing property.
                     * @template {any} [K=any] Resource id type.
                     * @template {{ id: K }} [V=any] Resource type.
                     * @template {ECNBResourceIndexState<K, V>} [S=any] State type.
                     * @param {S} state State instance.
                     * @param {Map<String, any> | null} [payload] Optional payload. Clears property if null.
                     */
                    setAttributes: (state, payload) => {
                        $set(state).attributes(payload);
                    },

                    /**
                     * Directly assign provided payload to the existing property.
                     * @template {any} [K=any] Resource id type.
                     * @template {{ id: K }} [V=any] Resource type.
                     * @template {ECNBResourceIndexState<K, V>} [S=any] State type.
                     * @param {S} state State instance.
                     * @param {Set<String> | null} [payload] Optional payload. Clears property if null.
                     */
                    setFlags: (state, payload) => {
                        $set(state).flags(payload);
                    },

                    /**
                     * Directly assign provided payload to the existing property.
                     * @template {any} [K=any] Resource id type.
                     * @template {{ id: K }} [V=any] Resource type.
                     * @template {ECNBResourceIndexState<K, V>} [S=any] State type.
                     * @param {S} state State instance.
                     * @param {Set<keyof ECNBResourceStatus> | null} [payload] Optional payload. Clears property if null.
                     */
                    setStatuses: (state, payload) => {
                        $set(state).statuses(payload);
                    },

                    /**
                     * Directly assign provided item to the existing property collection.
                     * @template {any} [K=any] Resource id type.
                     * @template {{ id: K }} [V=any] Resource type.
                     * @template {ECNBResourceIndexState<K, V>} [S=any] State type.
                     * @param {S} state State instance.
                     * @param {{ id: K, resource: V } | null} [item] Optional payload. Drops item if null.
                     */
                    setResource: (state, item) => {
                        $set(state).resource(item);
                    },

                    /**
                     * Directly assign provided item to the existing property collection.
                     * @template {any} [K=any] Resource id type.
                     * @template {{ id: K }} [V=any] Resource type.
                     * @template {ECNBResourceIndexState<K, V>} [S=any] State type.
                     * @param {S} state State instance.
                     * @param {{ key: String, value: any } | null} [item] Optional payload. Drops item if null.
                     */
                    setAttribute: (state, item) => {
                        $set(state).attribute(item);
                    },

                    /**
                     * Directly assign provided item to the existing property collection.
                     * @template {any} [K=any] Resource id type.
                     * @template {{ id: K }} [V=any] Resource type.
                     * @template {ECNBResourceIndexState<K, V>} [S=any] State type.
                     * @param {S} state State instance.
                     * @param {{ key: String, value: Boolean } | null} [item] Optional payload. Drops item if null.
                     */
                    setFlag: (state, item) => {
                        $set(state).flag(item);
                    },

                    /**
                     * Directly assign provided item to the existing property collection.
                     * @template {any} [K=any] Resource id type.
                     * @template {{ id: K }} [V=any] Resource type.
                     * @template {ECNBResourceIndexState<K, V>} [S=any] State type.
                     * @param {S} state State instance.
                     * @param {{ key: keyof ECNBResourceStatus, value: Boolean } | null} [item] Optional payload. Drops item if null.
                     */
                    setStatus: (state, item) => {
                        $set(state).status(item);
                    },
                };
                const $clear = $.clear;
                /**
                 * Clear mutations.
                 */
                const clearers = {
                    /**
                     * Clear existing property.
                     * @template {any} [K=any] Resource id type.
                     * @template {{ id: K }} [V=any] Resource type.
                     * @template {ECNBResourceIndexState<K, V>} [S=any] State type.
                     * @param {S} state State instance.
                     */
                    clearIndex: (state) => {
                        $clear(state).index();
                    },

                    /**
                     * Clear existing property.
                     * @template {any} [K=any] Resource id type.
                     * @template {{ id: K }} [V=any] Resource type.
                     * @template {ECNBResourceIndexState<K, V>} [S=any] State type.
                     * @param {S} state State instance.
                     */
                    clearAttributes: (state) => {
                        $clear(state).attributes();
                    },

                    /**
                     * Clear existing property.
                     * @template {any} [K=any] Resource id type.
                     * @template {{ id: K }} [V=any] Resource type.
                     * @template {ECNBResourceIndexState<K, V>} [S=any] State type.
                     * @param {S} state State instance.
                     */
                    clearFlags: (state) => {
                        $clear(state).flags();
                    },

                    /**
                     * Clear existing property.
                     * @template {any} [K=any] Resource id type.
                     * @template {{ id: K }} [V=any] Resource type.
                     * @template {ECNBResourceIndexState<K, V>} [S=any] State type.
                     * @param {S} state State instance.
                     */
                    clearStatuses: (state) => {
                        $clear(state).statuses();
                    },

                    /**
                     * Clear existing properties.
                     * @template {any} [K=any] Resource id type.
                     * @template {{ id: K }} [V=any] Resource type.
                     * @template {ECNBResourceIndexState<K, V>} [S=any] State type.
                     * @param {S} state State instance.
                     */
                    clearModule: (state) => {
                        $clear(state).statuses();
                        $clear(state).flags();
                        $clear(state).attributes();
                        $clear(state).index();
                    },
                };
                const $add = $.add;
                /**
                 * Adder mutations.
                 */
                const adders = {
                    /**
                     * Merge provided payload with existing property.
                     * @template {any} [K=any] Resource id type.
                     * @template {{ id: K }} [V=any] Resource type.
                     * @template {ECNBResourceIndexState<K, V>} [S=any] State type.
                     * @param {S} state State instance.
                     * @param {Map<K, V>} [payload]
                     */
                    addIndex: (state, payload) => {
                        $add(state).index(payload);
                    },

                    /**
                     * Merge provided payload with existing property.
                     * @template {any} [K=any] Resource id type.
                     * @template {{ id: K }} [V=any] Resource type.
                     * @template {ECNBResourceIndexState<K, V>} [S=any] State type.
                     * @param {S} state State instance.
                     * @param {Map<String, any>} [payload]
                     */
                    addAttributes: (state, payload) => {
                        $add(state).attributes(payload);
                    },

                    /**
                     * Merge provided payload with existing property.
                     * @template {any} [K=any] Resource id type.
                     * @template {{ id: K }} [V=any] Resource type.
                     * @template {ECNBResourceIndexState<K, V>} [S=any] State type.
                     * @param {S} state State instance.
                     * @param {Set<String>} [payload]
                     */
                    addFlags: (state, payload) => {
                        $add(state).flags(payload);
                    },

                    /**
                     * Merge provided payload with existing property.
                     * @template {any} [K=any] Resource id type.
                     * @template {{ id: K }} [V=any] Resource type.
                     * @template {ECNBResourceIndexState<K, V>} [S=any] State type.
                     * @param {S} state State instance.
                     * @param {Set<keyof ECNBResourceStatus>} [payload]
                     */
                    addStatuses: (state, payload) => {
                        $add(state).statuses(payload);
                    },

                    /**
                     * Merge provided payload item with existing property collection.
                     * @template {any} [K=any] Resource id type.
                     * @template {{ id: K }} [V=any] Resource type.
                     * @template {ECNBResourceIndexState<K, V>} [S=any] State type.
                     * @param {S} state State instance.
                     * @param {V} [item] Resource.
                     */
                    addResource: (state, item) => {
                        $add(state).resource(item);
                    },

                    /**
                     * Merge provided payload item with existing property collection.
                     * @template {any} [K=any] Resource id type.
                     * @template {{ id: K }} [V=any] Resource type.
                     * @template {ECNBResourceIndexState<K, V>} [S=any] State type.
                     * @param {S} state State instance.
                     * @param {{ key: String, value?: any }} item
                     */
                    addAttribute: (state, item) => {
                        $add(state).attribute(item.key, item.value);
                    },

                    /**
                     * Merge provided payload item with existing property collection.
                     * @template {any} [K=any] Resource id type.
                     * @template {{ id: K }} [V=any] Resource type.
                     * @template {ECNBResourceIndexState<K, V>} [S=any] State type.
                     * @param {S} state State instance.
                     * @param {String} [key]
                     */
                    addFlag: (state, key) => {
                        $add(state).flag(key);
                    },

                    /**
                     * Merge provided payload item with existing property collection.
                     * @template {any} [K=any] Resource id type.
                     * @template {{ id: K }} [V=any] Resource type.
                     * @template {ECNBResourceIndexState<K, V>} [S=any] State type.
                     * @param {S} state State instance.
                     * @param {keyof ECNBResourceStatus} [key]
                     */
                    addStatus: (state, key) => {
                        $add(state).status(key);
                    },
                };
                const $drop = $.drop;
                /**
                 * Dropper mutations.
                 */
                const droppers = {
                    /**
                     * Directly assign provided payload to the existing property.
                     * @template {any} [K=any] Resource id type.
                     * @template {{ id: K }} [V=any] Resource type.
                     * @template {ECNBResourceIndexState<K, V>} [S=any] State type.
                     * @param {S} state State instance.
                     * @param {Set<K> | 'all'} [payload]
                     */
                    dropIndex: (state, payload) => {
                        $drop(state).index(payload);
                    },

                    /**
                     * Directly assign provided payload to the existing property.
                     * @template {any} [K=any] Resource id type.
                     * @template {{ id: K }} [V=any] Resource type.
                     * @template {ECNBResourceIndexState<K, V>} [S=any] State type.
                     * @param {S} state State instance.
                     * @param {Set<String> | 'all'} [payload]
                     */
                    dropAttributes: (state, payload) => {
                        $drop(state).attributes(payload);
                    },

                    /**
                     * Directly assign provided payload to the existing property.
                     * @template {any} [K=any] Resource id type.
                     * @template {{ id: K }} [V=any] Resource type.
                     * @template {ECNBResourceIndexState<K, V>} [S=any] State type.
                     * @param {S} state State instance.
                     * @param {Set<String> | 'all'} [payload]
                     */
                    dropFlags: (state, payload) => {
                        $drop(state).flags(payload);
                    },

                    /**
                     * Directly assign provided payload to the existing property.
                     * @template {any} [K=any] Resource id type.
                     * @template {{ id: K }} [V=any] Resource type.
                     * @template {ECNBResourceIndexState<K, V>} [S=any] State type.
                     * @param {S} state State instance.
                     * @param {Set<keyof ECNBResourceStatus> | 'all'} [payload]
                     */
                    dropStatuses: (state, payload) => {
                        $drop(state).statuses(payload);
                    },

                    /**
                     * Directly assign provided item to the existing property collection.
                     * @template {any} [K=any] Resource id type.
                     * @template {{ id: K }} [V=any] Resource type.
                     * @template {ECNBResourceIndexState<K, V>} [S=any] State type.
                     * @param {S} state State instance.
                     * @param {K} id
                     */
                    dropResource: (state, id) => {
                        $drop(state).resource(id);
                    },

                    /**
                     * Directly assign provided item to the existing property collection.
                     * @template {any} [K=any] Resource id type.
                     * @template {{ id: K }} [V=any] Resource type.
                     * @template {ECNBResourceIndexState<K, V>} [S=any] State type.
                     * @param {S} state State instance.
                     * @param {String} key
                     */
                    dropAttribute: (state, key) => {
                        $drop(state).attribute(key);
                    },

                    /**
                     * Directly assign provided item to the existing property collection.
                     * @template {any} [K=any] Resource id type.
                     * @template {{ id: K }} [V=any] Resource type.
                     * @template {ECNBResourceIndexState<K, V>} [S=any] State type.
                     * @param {S} state State instance.
                     * @param {String} key
                     */
                    dropFlag: (state, key) => {
                        $drop(state).flag(key);
                    },

                    /**
                     * Directly assign provided item to the existing property collection.
                     * @template {any} [K=any] Resource id type.
                     * @template {{ id: K }} [V=any] Resource type.
                     * @template {ECNBResourceIndexState<K, V>} [S=any] State type.
                     * @param {S} state State instance.
                     * @param {keyof ECNBResourceStatus} key
                     */
                    dropStatus: (state, key) => {
                        $drop(state).status(key);
                    },
                };
                return {
                    ...setters,
                    ...clearers,
                    ...adders,
                    ...droppers,
                };
            },
            get actions() {
                /**
                 * Module assertions.
                 */
                const asserts = {
                    /**
                     * Assertion.
                     * @template {any} [K=any] Resource id type.
                     * @template {{ id: K }} [V=any] Resource type.
                     * @template {ECNBResourceIndexState<K, V>} [S=any]
                     * @param {import('vuex').ActionContext<S, ECNBState>} context
                     */
                    assertIndexIsEmpty: async ({ state }) => {
                        await Assertion.expect(state.is.empty).isTruthy();
                    },
                    /**
                     * Assertion.
                     * @template {any} [K=any] Resource id type.
                     * @template {{ id: K }} [V=any] Resource type.
                     * @template {ECNBResourceIndexState<K, V>} [S=any]
                     * @param {import('vuex').ActionContext<S, ECNBState>} context
                     */
                    assertIndexIsNotEmpty: async ({ state }) => {
                        await Assertion.expect(state.is.empty).isFalsy();
                    },
                    /**
                     * Assertion.
                     * @template {any} [K=any] Resource id type.
                     * @template {{ id: K }} [V=any] Resource type.
                     * @template {ECNBResourceIndexState<K, V>} [S=any]
                     * @param {import('vuex').ActionContext<S, ECNBState>} context
                     * @param {Object} payload
                     * @param {String} payload.key Attribute key to check.
                     */
                    assertAttributeDefined: async ({ state }, payload) => {
                        const { key } = payload;
                        await Assertion.expect(state.extract(key)).isDefined();
                    },
                    /**
                     * Assertion.
                     * @template {any} [K=any] Resource id type.
                     * @template {{ id: K }} [V=any] Resource type.
                     * @template {ECNBResourceIndexState<K, V>} [S=any]
                     * @param {import('vuex').ActionContext<S, ECNBState>} context
                     * @param {Object} payload
                     * @param {String} payload.key Attribute key to check.
                     */
                    assertAttributeUndefined: async ({ state }, payload) => {
                        const { key } = payload;
                        await Assertion.expect(
                            state.extract(key)
                        ).isUndefined();
                    },
                    /**
                     * Assertion.
                     * @template {any} [K=any] Resource id type.
                     * @template {{ id: K }} [V=any] Resource type.
                     * @template {ECNBResourceIndexState<K, V>} [S=any]
                     * @param {import('vuex').ActionContext<S, ECNBState>} context
                     * @param {Object} payload
                     * @param {String} payload.flag Flag to check.
                     */
                    assertFlagIsEnabled: async ({ state }, payload) => {
                        const { flag } = payload;
                        await Assertion.expect(state.has.flag(flag)).isTruthy();
                    },
                    /**
                     * Assertion.
                     * @template {any} [K=any] Resource id type.
                     * @template {{ id: K }} [V=any] Resource type.
                     * @template {ECNBResourceIndexState<K, V>} [S=any]
                     * @param {import('vuex').ActionContext<S, ECNBState>} context
                     * @param {Object} payload
                     * @param {String} payload.flag Flag to check.
                     */
                    assertFlagIsDisabled: async ({ state }, payload) => {
                        const { flag } = payload;
                        await Assertion.expect(state.has.flag(flag)).isFalsy();
                    },
                    /**
                     * Assertion.
                     * @template {any} [K=any] Resource id type.
                     * @template {{ id: K }} [V=any] Resource type.
                     * @template {ECNBResourceIndexState<K, V>} [S=any]
                     * @param {import('vuex').ActionContext<S, ECNBState>} context
                     * @param {Object} payload
                     * @param {keyof ECNBResourceStatus} payload.status Status to check.
                     */
                    assertStatusIsEnabled: async ({ state }, payload) => {
                        const { status } = payload;
                        await Assertion.expect(
                            state.has.status(status)
                        ).isTruthy();
                    },
                    /**
                     * Assertion.
                     * @template {any} [K=any] Resource id type.
                     * @template {{ id: K }} [V=any] Resource type.
                     * @template {ECNBResourceIndexState<K, V>} [S=any]
                     * @param {import('vuex').ActionContext<S, ECNBState>} context
                     * @param {Object} payload
                     * @param {keyof ECNBResourceStatus} payload.status Status to check.
                     */
                    assertStatusIsDisabled: async ({ state }, payload) => {
                        const { status } = payload;
                        await Assertion.expect(
                            state.has.status(status)
                        ).isFalsy();
                    },
                    /**
                     * Assertion.
                     * @template {any} [K=any] Resource id type.
                     * @template {{ id: K }} [V=any] Resource type.
                     * @template {ECNBResourceIndexState<K, V>} [S=any]
                     * @param {import('vuex').ActionContext<S, ECNBState>} context
                     * @param {Object} payload
                     * @param {K} [payload.id] Resource id.
                     * @param {V} [payload.resource] Resource with id.
                     */
                    assertResourceExists: async ({ state }, payload) => {
                        const { id, resource } = payload;
                        const inferredID = id ?? resource.id;
                        if (inferredID !== resource.id) {
                            throw new Error(
                                `Mismatched identifiers provided to assertion.`
                            );
                        }
                        await Assertion.expect(inferredID).isNotNil();
                        await Assertion.expect(
                            state.has.resource(id)
                        ).isTruthy();
                    },
                    /**
                     * Assertion.
                     * @template {any} [K=any] Resource id type.
                     * @template {{ id: K }} [V=any] Resource type.
                     * @template {ECNBResourceIndexState<K, V>} [S=any]
                     * @param {import('vuex').ActionContext<S, ECNBState>} context
                     * @param {Object} payload
                     * @param {K} [payload.id] Resource id.
                     * @param {V} [payload.resource] Resource with id.
                     */
                    assertResourceMissing: async ({ state }, payload) => {
                        const { id, resource } = payload;
                        const inferredID = id ?? resource.id;
                        if (inferredID !== resource.id) {
                            throw new Error(
                                `Mismatched identifiers provided to assertion.`
                            );
                        }
                        await Assertion.expect(inferredID).isNotNil();
                        await Assertion.expect(
                            state.has.resource(id)
                        ).isFalsy();
                    },
                };
                /**
                 * Module cachers.
                 */
                const cachers = {
                    /**
                     * Merge or overwrite cached index.
                     * @template {any} [K=any] Resource id type.
                     * @template {{ id: K }} [V=any] Resource type.
                     * @template {ECNBResourceIndexState<K, V>} [S=any]
                     * @param {import('vuex').ActionContext<S, ECNBState>} context
                     * @param {Object} payload
                     * @param {Map<K, V>} payload.index Index to merge with cache.
                     * @param {Boolean} [payload.append] Should index map be appended to existing cache? Defaults to `true`. If `false`, will overwrite cache.
                     */
                    cacheIndex: async (context, payload) => {
                        const { state } = context;
                        const { index, append = true } = payload;
                        const $use = ECNBModule.use(context);
                        const $mutate = $use.mutate; // Local mutation applicator.
                        // ts-ignore
                        const $invoke = $use.invoke; // Local action dispatcher.
                        try {
                            $mutate('addStatus').withPayload('caching');
                            // Ensure index is defined.
                            await Assertion.expect(index).isNotNil();
                            if (append === true) {
                                // If append is true, merge with existing cache index.
                                $mutate('addIndex').withPayload(index);
                            }
                            if (append === false) {
                                // If append is false, overwrite existing cache index.
                                $mutate('setIndex').withPayload(index);
                            }
                            // Return updated cache index.
                            return state.index;
                        } finally {
                            $mutate('dropStatus').withPayload('caching');
                        }
                    },
                    /**
                     * Add resource to cache.
                     * @template {any} [K=any] Resource id type.
                     * @template {{ id: K }} [V=any] Resource type.
                     * @template {ECNBResourceIndexState<K, V>} [S=any]
                     * @param {import('vuex').ActionContext<S, ECNBState>} context
                     * @param {Object} payload
                     * @param {V} payload.resource Resource to add to cache.
                     */
                    cacheResource: async (context, payload) => {
                        const { state } = context;
                        const { resource } = payload;
                        const $use = ECNBModule.use(context);
                        const $mutate = $use.mutate; // Local mutation applicator.
                        // ts-ignore
                        const $invoke = $use.invoke; // Local action dispatcher.
                        try {
                            $mutate('addStatus').withPayload('caching');
                            // Ensure resource is defined.
                            await Assertion.expect(resource).isNotNil();
                            // Add resource to the cache.
                            $mutate('addResource').withPayload(resource);
                            // Return updated resource from the index.
                            return state.index.get(resource.id);
                        } finally {
                            $mutate('dropStatus').withPayload('caching');
                        }
                    },
                };
                return {
                    ...asserts,
                    ...cachers,
                };
            },
        };
    }
}

// DEFAULT
export default ECNBResourceCacheModule;
