// <!-- API -->
import { UploadWorkflow } from '@/store/types/uploader/state';
import { UploadWorkflowGetters } from '@/store/types/uploader/getters';
import { UploadWorkflowActions } from '@/store/types/uploader/actions';
import isNil from 'lodash-es/isNil';

// <!-- TYPES -->
import { UploadWorkflowMutations } from '@/store/types/uploader/mutations';

import { ECNBState } from '@/store/types/ECNBStore';
import { ECNBModule } from '@/store/types/ECNBModule';
import { FormStep } from '@/hooks/useFormStep';

// CLASS
/**
 * @class
 * Uploader module.
 */
export class UploadWorkflowModule extends ECNBModule {
    /**
     * Name of the module.
     */
    static get namespace() {
        return 'uploader/workflow';
    }

    /**
     * Module state, getters, mutations, and actions.
     */
    static get module() {
        // EXPOSE
        return {
            namespaced: true,
            state: () => new UploadWorkflow(),
            modules: {},
            get getters() {
                const $getters = UploadWorkflowGetters;
                return {
                    ...$getters,
                };
            },
            get mutations() {
                const $ = UploadWorkflowMutations;
                /**
                 * Sequencer related mutations.
                 */
                const sequencer = {
                    /**
                     * @param {UploadWorkflow} state
                     * @param {FormStep[]} sequence Ordered form step sequence.
                     */
                    setSequence: (state, sequence) => {
                        const orderedSteps = new Map();
                        for (const step of sequence) {
                            // Maps preserve insertion order.
                            // Add steps in order of sequence appearance.
                            orderedSteps.set(step.id, step);
                        }
                        $.set(state).steps(orderedSteps);
                    },
                    /**
                     * Set current step by id or index.
                     * @param {UploadWorkflow} state
                     * @param {{ id?: String, index?: Number, step?: FormStep }} target Ordered form step sequence.
                     */
                    setCurrentStep: (state, target) => {
                        const { id, index, step } = target ?? {};
                        if (!isNil(id)) {
                            $.set(state).currentStep.byID(id);
                            return;
                        }
                        if (!isNil(index)) {
                            $.set(state).currentStep.byIndex(index);
                            return;
                        }
                        if (!isNil(step)) {
                            $.set(state).currentStep.byID(step.id);
                            return;
                        }
                    },
                    /**
                     * Go to the special step.
                     * @param {UploadWorkflow} state
                     */
                    toFirstStep: (state) => $.set(state).currentStep.toFirst(),
                    /**
                     * Go to the special step.
                     * @param {UploadWorkflow} state
                     */
                    toLastStep: (state) => $.set(state).currentStep.toLast(),
                    /**
                     * Go to the special step.
                     * @param {UploadWorkflow} state
                     */
                    toPreviousStep: (state) =>
                        $.set(state).currentStep.toPrevious(),
                    /**
                     * Go to the special step.
                     * @param {UploadWorkflow} state
                     */
                    toNextStep: (state) => $.set(state).currentStep.toNext(),
                };
                /**
                 * Lifecycle related mutations.
                 */
                const lifecycle = {
                    /**
                     * @param {UploadWorkflow} state
                     */
                    clearModule: (state) => {
                        const initialState = new UploadWorkflow();
                        Object.assign(state, initialState);
                    },
                    /**
                     * @param {UploadWorkflow} state
                     */
                    reset: (state) => {
                        $.drop(state).status.all();
                        $.set(state).currentStep.to(-1);
                    },
                    /**
                     * @param {UploadWorkflow} state
                     */
                    lock: (state) => {
                        $.change(state).status.locked.enable();
                    },
                    /**
                     * @param {UploadWorkflow} state
                     */
                    unlock: (state) => {
                        $.change(state).status.locked.disable();
                    },
                    /**
                     * @param {UploadWorkflow} state
                     */
                    resolve: (state) => {
                        $.change(state).status.rejected.disable();
                        $.change(state).status.fulfilled.enable();
                    },
                    /**
                     * @param {UploadWorkflow} state
                     */
                    reject: (state) => {
                        $.change(state).status.fulfilled.disable();
                        $.change(state).status.rejected.enable();
                    },
                    /**
                     * @param {UploadWorkflow} state
                     */
                    start: (state) => {
                        lifecycle.reset(state);
                        sequencer.toFirstStep(state);
                        lifecycle.unlock(state);
                    },
                    /**
                     * @param {UploadWorkflow} state
                     * @param {'fulfilled' | 'rejected' | 'restart'} status
                     */
                    end: (state, status) => {
                        switch (status) {
                            case 'fulfilled':
                                // On success, resolve and go to summary.
                                lifecycle.resolve(state);
                                sequencer.toLastStep(state);
                                break;
                            case 'rejected':
                                // On failure, reject and stay at current step.
                                lifecycle.reject(state);
                                break;
                            case 'restart':
                                // On restart, resolve, then invoke start.
                                lifecycle.resolve(state);
                                sequencer.toLastStep(state);
                                lifecycle.start(state);
                                break;
                            default:
                                // Otherwise, do nothing. We will simply lock state.
                                break;
                        }
                        // Lock once 'end' is called.
                        lifecycle.lock(state);
                    },
                };
                return {
                    ...sequencer,
                    ...lifecycle,
                };
            },
            get actions() {
                const $actions = UploadWorkflowActions;
                const $navigation = $actions.navigation;
                /**
                 * Asynchronous lifecycle functions.
                 */
                const lifecycle = {
                    /**
                     * Start the workflow (if not started).
                     * @param {import('vuex').ActionContext<UploadWorkflow, ECNBState>} context
                     */
                    startWorkflow: async (context) => {
                        await $navigation(context).lifecycle.start();
                        console.log(`[workflow::started]`);
                    },
                    /**
                     * Stop the workflow.
                     * @param {import('vuex').ActionContext<UploadWorkflow, ECNBState>} context
                     * @param {'fulfilled' | 'rejected' | 'restart'} status
                     */
                    stopWorkflow: async (context, status) => {
                        await $navigation(context).lifecycle.stop(status);
                        console.log(`[workflow::stopped] - ${status}`);
                    },
                    /**
                     * Stop the workflow with the fulfilled state.
                     * @param {import('vuex').ActionContext<UploadWorkflow, ECNBState>} context
                     */
                    resolveWorkflow: async (context) => {
                        await $navigation(context).lifecycle.resolve();
                        console.log(`[workflow::fulfilled]`);
                    },
                    /**
                     * Stop the workflow with the rejected state.
                     * @param {import('vuex').ActionContext<UploadWorkflow, ECNBState>} context
                     * @param {String} [reason]
                     */
                    rejectWorkflow: async (context, reason) => {
                        await $navigation(context).lifecycle.reject();
                        console.warn(`[workflow::rejected] ${reason}`);
                    },
                    /**
                     * Restart the workflow.
                     * @param {import('vuex').ActionContext<UploadWorkflow, ECNBState>} context
                     */
                    restartWorkflow: async (context) => {
                        await $navigation(context).lifecycle.restart();
                        console.log(`[workflow::restarted]`);
                    },
                };
                /**
                 * Asynchronous transition actions.
                 */
                const transition = {
                    /**
                     * @param {import('vuex').ActionContext<UploadWorkflow, ECNBState>} context
                     * @param {Number} index
                     */
                    forceStepIndex: async (context, index) => {
                        const $navigate = $navigation(context).transition.to;
                        if (!isNil(index)) {
                            return await $navigate.force(index);
                        }
                        throw new Error(`No navigation target provided.`);
                    },
                    /**
                     * @param {import('vuex').ActionContext<UploadWorkflow, ECNBState>} context
                     * @param {{ step?: Pick<FormStep, 'id'>, id?: String, index?: Number, allowTransition?: Boolean | Promise<Boolean> | (() => Boolean) | (() => Promise<Boolean>) }} payload
                     */
                    goToStep: async (context, payload) => {
                        const $navigate = $navigation(context).transition.to;
                        const { step, id, index, allowTransition } =
                            payload ?? {};
                        if (!isNil(step)) {
                            return await $navigate.byID(
                                step.id,
                                allowTransition
                            );
                        }
                        if (!isNil(id)) {
                            return await $navigate.byID(id, allowTransition);
                        }
                        if (!isNil(index)) {
                            return await $navigate.byIndex(
                                index,
                                allowTransition
                            );
                        }
                        throw new Error(`No navigation target provided.`);
                    },
                    /**
                     * @param {import('vuex').ActionContext<UploadWorkflow, ECNBState>} context
                     * @param {{ allowTransition?: Boolean | Promise<Boolean> | (() => Boolean) | (() => Promise<Boolean>) }} payload
                     */
                    goToFirst: async (context, payload) => {
                        const $navigate = $navigation(context).transition.to;
                        const { allowTransition = true } = payload;
                        return await $navigate.first(allowTransition);
                    },
                    /**
                     * @param {import('vuex').ActionContext<UploadWorkflow, ECNBState>} context
                     * @param {{ allowTransition?: Boolean | Promise<Boolean> | (() => Boolean) | (() => Promise<Boolean>) }} payload
                     */
                    goToLast: async (context, payload) => {
                        const $navigate = $navigation(context).transition.to;
                        const { allowTransition = true } = payload;
                        return await $navigate.last(allowTransition);
                    },
                    /**
                     * @param {import('vuex').ActionContext<UploadWorkflow, ECNBState>} context
                     * @param {{ allowTransition?: Boolean | Promise<Boolean> | (() => Boolean) | (() => Promise<Boolean>) }} payload
                     */
                    goToPrevious: async (context, payload) => {
                        const $navigate = $navigation(context).transition.to;
                        const { allowTransition = true } = payload;
                        return await $navigate.previous(allowTransition);
                    },
                    /**
                     * @param {import('vuex').ActionContext<UploadWorkflow, ECNBState>} context
                     * @param {{ allowTransition?: Boolean | Promise<Boolean> | (() => Boolean) | (() => Promise<Boolean>) }} payload
                     */
                    goToNext: async (context, payload) => {
                        const $navigate = $navigation(context).transition.to;
                        const { allowTransition = true } = payload;
                        return await $navigate.next(allowTransition);
                    },
                };
                return {
                    ...lifecycle,
                    ...transition,
                };
            },
        };
    }
}
