// <!-- TYPES -->
import { UploadConfiguration } from '@/store/types/uploader/state/UploadConfiguration';

/**
 * State representation for the upload form.
 */
export class UploadState {
    /**
     * Determine if the state is namespaced?
     */
    static get namespaced() {
        return true;
    }

    /**
     * Upload state.
     */
    constructor() {
        /** Form configuration. */
        this.config = new UploadConfiguration();
    }
}
