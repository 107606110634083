// <!-- UTILITY -->
import { Enum } from '@/utils/enums';

export const Unit = Enum.create({
    None: '',
    Unknown: '?',
    Percent: '%',
    Fahrenheit: '°F',
    Celsius: '°C',
});

// <!-- DEFAULT -->
export default Unit;
