/** Composable that provides helpers for accessing the Vuex store. */

// <!-- API -->
import { useStore } from 'vuex';

// <!-- TYPES -->
import { Store } from 'vuex';
import { ECNBStore, ECNBState } from '@/store/types/ECNBStore';

// <!-- COMPOSABLES -->
/**
 * Creates a new store (or wraps an existing one) in
 * an intellisense friendly typing structure.
 * @param {Store<ECNBState>} [store]
 * @returns {ECNBStore}
 */
export const useECNBStore = (store) => {
    /** @type {Store<ECNBState>} */
    const _ = !!store ? store : useStore();
    const $ = ECNBStoreAssertions;
    $.assertECNBStoreDefined(_);
    return new ECNBStore(_);
};

// <!-- ASSERTIONS -->
/**
 * {@link ECNBStore} assertions.
 */
export const ECNBStoreAssertions = {
    /**
     * Assert store instance exists.
     * @param {Store<ECNBState>} store
     */
    assertStoreDefined: (store) => {
        if (store === undefined || store.state === undefined) {
            throw new TypeError(
                'Vuex store is required for attaching class members.'
            );
        }
    },

    /**
     * Assert store instance is valid.
     * @param {Store<ECNBState>} store
     */
    assertECNBStoreDefined: (store) => {
        ECNBStoreAssertions.assertStoreDefined(store);
        /**
         * Checks to see if the following are all
         * defined accounts:
         * - accounts
         * - users
         */
        if (
            store.state.accounts === undefined ||
            store.state.users === undefined
        ) {
            throw new TypeError(`Vuex store modules are not initialized.`);
        }
    },
};

// DEFAULT
export default useECNBStore;
