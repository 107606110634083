// <!-- INTERFACES -->
import { PayloadResource } from '@/utils/resources';
import { PayloadAttributeFactory } from '@/utils/resources';
import { SubscriptionModel } from '@/models/v2/subscriptions/SubscriptionModel';

// <!-- RELATIONSHIPS -->
import { PlanPayload } from '@/payloads/v2/plans/PlanPayload';
import { OrganizationPayload } from '@/payloads/v2/organizations/OrganizationPayload';

/**
 * Create a specialized resource instance.
 *
 * @extends {PayloadResource<PayloadData,ModelData>}
 * @implements {Resource.Payload<PayloadData,ModelData>}
 * @implements {PayloadData}
 */
export class SubscriptionPayload extends PayloadResource {
    // <!-- TYPE ALIASES -->

    /** @typedef {Subscription.Payload} PayloadData */
    /** @typedef {Subscription.Model} ModelData */

    // <!-- STATIC FACTORY METHODS -->

    /**
     * Create resource instance from passed attributes.
     *
     * @param {Partial<PayloadData>} attributes
     * @returns {SubscriptionPayload}
     */
    static create(attributes) {
        return new SubscriptionPayload(attributes);
    }

    /**
     * Create resource instance from passed attributes.
     *
     * @param {Partial<ModelData>} data
     * @returns {SubscriptionPayload}
     */
    static fromModel(data) {
        const attributes = SubscriptionPayload.attributesFromModel(data);
        return SubscriptionPayload.create(attributes);
    }

    // <!-- STATIC UTILITY METHODS -->

    /** @type {Readonly<PayloadData>} */
    static get defaults() {
        return {
            id: null,
            organization_id: undefined,
            organization: null,
            subscription_level_id: undefined,
            subscription_level: null,
            price_per_year: '0.00',
            price_per_month: '0.00',
            max_users: null,
            max_locations: null,
            is_paid: null,
            is_active: null,
            is_expired: null,
            paid_at: null,
            active_at: null,
            expire_at: null,
            created_at: null,
            updated_at: null,
        };
    }

    /**
     * Create resource attributes from the model data.
     *
     * @param {Partial<ModelData>} model
     * @returns {Partial<PayloadData>}
     */
    static attributesFromModel(model) {
        // Get transformer functions.
        const { serialize } = this;

        // Transform attributes.
        const organization = OrganizationPayload.fromModel(model.organization);
        const subscription_level = PlanPayload.fromModel(model.plan);

        // Return the created state.
        return {
            id: model.id,
            organization_id: model.organizationId,
            organization,
            subscription_level_id: model.planId,
            subscription_level,
            price_per_year: serialize.priceAsString(model.pricePerYear),
            price_per_month: serialize.priceAsString(model.pricePerMonth),
            max_users: model.maxUsers,
            max_locations: model.maxLocations,
            is_paid: model.isPaid,
            is_active: model.isActive,
            is_expired: model.isExpired,
            paid_at: serialize.dateAsISOString(model.paidAt),
            active_at: serialize.dateAsISOString(model.activeAt),
            expire_at: serialize.dateAsISOString(model.expireAt),
            created_at: serialize.dateAsISOString(model.createdAt),
            updated_at: serialize.dateAsISOString(model.updatedAt),
        };
    }

    // <!-- CONSTRUCTOR -->

    /**
     * Create resource instance.
     *
     * @param {Partial<PayloadData>} attributes
     */
    constructor(attributes) {
        super(
            attributes,
            PayloadAttributeFactory.create(
                SubscriptionPayload.attributesFromModel,
                SubscriptionPayload.defaults
            )
        );

        // Hydrate sub-resource.
        if (
            this.exists('organization') &&
            !(this.organization instanceof OrganizationPayload)
        ) {
            this.organization = OrganizationPayload.create(this.organization);
        }

        // Hydrate sub-resource.
        if (
            this.exists('subscription_level') &&
            !(this.subscription_level instanceof PlanPayload)
        ) {
            this.subscription_level = PlanPayload.create(
                this.subscription_level
            );
        }
    }

    /** Displays the specified tag when printing to the console. */
    get [Symbol.toStringTag]() {
        return 'Subscription::Payload';
    }

    // <!-- RESOURCE INTERFACE -->

    /** Get shallow copy of this instance as a resource. */
    toPayload() {
        return this.clone();
    }

    /** Get shallow copy of this instance as a resource. */
    toModel() {
        return SubscriptionModel.fromPayload(this);
    }

    /** Get shallow copy of this instance. */
    clone() {
        return /** @type {this} */ (SubscriptionPayload.create(this));
    }

    // <!-- ATTRIBUTE::PROPERTIES -->

    get id() {
        return this.get('id');
    }

    set id(value) {
        this.set('id', value);
    }

    get organization_id() {
        return this.get('organization_id');
    }

    set organization_id(value) {
        this.set('organization_id', value);
    }

    get organization() {
        return this.get('organization');
    }

    set organization(value) {
        this.set('organization', value);
    }

    get subscription_level_id() {
        return this.get('subscription_level_id');
    }

    set subscription_level_id(value) {
        this.set('subscription_level_id', value);
    }

    get subscription_level() {
        return this.get('subscription_level');
    }

    set subscription_level(value) {
        this.set('subscription_level', value);
    }

    get price_per_year() {
        return this.get('price_per_year');
    }

    set price_per_year(value) {
        this.set('price_per_year', value);
    }

    get price_per_month() {
        return this.get('price_per_month');
    }

    set price_per_month(value) {
        this.set('price_per_month', value);
    }

    get max_users() {
        return this.get('max_users');
    }

    set max_users(value) {
        this.set('max_users', value);
    }

    get max_locations() {
        return this.get('max_locations');
    }

    set max_locations(value) {
        this.set('max_locations', value);
    }

    get is_paid() {
        return this.get('is_paid');
    }

    set is_paid(value) {
        this.set('is_paid', value);
    }

    get is_active() {
        return this.get('is_active');
    }

    set is_active(value) {
        this.set('is_active', value);
    }

    get is_expired() {
        return this.get('is_expired');
    }

    set is_expired(value) {
        this.set('is_expired', value);
    }

    get paid_at() {
        return this.get('paid_at');
    }

    set paid_at(value) {
        this.set('paid_at', value);
    }

    get active_at() {
        return this.get('active_at');
    }

    set active_at(value) {
        this.set('active_at', value);
    }

    get expire_at() {
        return this.get('expire_at');
    }

    set expire_at(value) {
        this.set('expire_at', value);
    }

    get created_at() {
        return this.get('created_at');
    }

    set created_at(value) {
        this.set('created_at', value);
    }

    get updated_at() {
        return this.get('updated_at');
    }

    set updated_at(value) {
        this.set('updated_at', value);
    }
}
