/**
 * store/user.js
 *
 * Provides store for user user information.
 *
 * This was the first module created, so you can use this
 * as reference on how to build your own module.
 *
 * With hot module reloading, modules in the
 * `./store/modules` directory do not need to be
 * manually added to the `./store/index.js`.
 */

// <!-- TYPES -->

import { ECNBState } from '@/store/types/ECNBStore';
import { UserState } from '@/store/types/users/state';
import { UserModule } from '@/store/types/users/module';

/**
 * @type {import('vuex').Module<UserState, ECNBState>} Module to export.
 */
const module = {
    namespaced: UserModule.module.namespaced,
    state: UserModule.module.state,
    modules: UserModule.module.modules,
    getters: UserModule.module.getters,
    mutations: UserModule.module.mutations,
    actions: UserModule.module.actions,
};

/** Export the module. */
export default module;
