// <!-- TYPES -->

import { Model, FORMAT } from '@/models/v1/resource/Model';
import {
    DynamicEnumFactory,
    DynamicEnumGridFactory,
} from '@/utils/DynamicEnum';
import {
    Location,
    LocationResource,
    LocationPayload,
} from '@/models/v1/locations/Location';

/** Model attribute names. */
const FIELDS = DynamicEnumFactory().fromKeys([
    'id',
    'name',
    'path',
    'depth',
    'dateCreated',
    'dateUpdated',
    'parentId',
    'children',
    'locations',
]);

/** Resource <--> Payload aliases. */
const ALIASES = /** @type {const} */ ([
    [FIELDS.id, 'id'],
    [FIELDS.name, 'name'],
    [FIELDS.path, 'path'],
    [FIELDS.depth, 'depth'],
    [FIELDS.dateCreated, 'created_at'],
    [FIELDS.dateUpdated, 'updated_at'],
    [FIELDS.parentId, 'parent_id'],
    [FIELDS.children, 'children'],
    [FIELDS.locations, 'locations'],
]);

/** Resource and payload keys. */
const KEYS = DynamicEnumGridFactory().fromPairs(ALIASES);

/**
 * @class - Represents a location hierarchy payload.
 */
export class LocationHierarchyPayload {
    /** @type {Number} Node id. */
    id = null;
    /** @type {String} Node name. */
    name = null;
    /** @type {String} Path of the hierarchy from the root until this node. */
    path = null;
    /** @type {Number} Depth of the node in the tree. Root nodes have depths of zero. */
    depth = null;
    /** @type {String} Creation date. */
    created_at = null;
    /** @type {String} Last updated date. */
    updated_at = null;
    /** @type {Number} Parent id. */
    parent_id = null;
    /** @type {LocationHierarchyPayload[]} Children. */
    children = [];
    /** @type {LocationPayload[]} Locations. */
    locations = [];

    /**
     * Assign attributes from another instance.
     * @param {Partial<import('@/types').ExcludeMethods<LocationHierarchyPayload>>} attributes
     */
    constructor(attributes = {}) {
        Object.assign(this, attributes);
    }

    /**
     * Parse model state to get the payload.
     * @param {LocationHierarchy} model
     * @returns {this}
     */
    parseModel(model) {
        this.id = model.get(FIELDS.id);
        this.name = model.get(FIELDS.name);
        this.path = model.get(FIELDS.path);
        this.depth = model.get(FIELDS.depth);
        this.parent_id = model.get(FIELDS.parentId);
        this.created_at = model.get(FIELDS.dateCreated);
        this.updated_at = model.get(FIELDS.dateUpdated);

        /** @type {LocationHierarchy[]} Children... */
        const children = model.get(FIELDS.children);
        this.children = children.map((child) => child.toPayload());

        /** @type {Location[]} Locations ... */
        const locations = model.get(FIELDS.locations);
        this.locations = locations.map((location) => location.toPayload());

        // Return this.
        return this;
    }

    /**
     * Convert this payload into a model.
     * @returns {readonly [typeof FIELDS[keyof FIELDS], any][]}
     */
    entries() {
        /** @type {readonly [typeof FIELDS[keyof FIELDS], any][]} */
        return [
            [FIELDS.id, this.id],
            [FIELDS.name, this.name],
            [FIELDS.path, this.path],
            [FIELDS.depth, this.depth],
            [FIELDS.dateCreated, this.created_at],
            [FIELDS.dateUpdated, this.updated_at],
            [FIELDS.parentId, this.parent_id],
            [
                FIELDS.children,
                this.children.map(
                    (child) =>
                        new LocationHierarchy({
                            payload: child,
                        })
                ),
            ],
            [
                FIELDS.locations,
                this.locations.map(
                    (location) => new Location({ payload: location })
                ),
            ],
        ];
    }

    /**
     * Unroll all ancestors.
     *
     * @returns {LocationHierarchyPayload[]}
     */
    unroll() {
        return [...unrollLocationPayload(this)];
    }
}

/**
 * @class - Represents a location hierarchy resource.
 */
export class LocationHierarchyResource {
    /** @type {Number} Node id. */
    id = null;
    /** @type {String} Node name. */
    name = null;
    /** @type {String} Path of the hierarchy from the root until this node. */
    path = null;
    /** @type {Number} Depth of the node in the tree. Root nodes have depths of zero. */
    depth = null;
    /** @type {String} Creation date. */
    dateCreated = null;
    /** @type {String} Last updated date. */
    dateUpdated = null;
    /** @type {Number} Parent id. */
    parentId = null;
    /** @type {LocationHierarchyResource[]} Children. */
    children = [];
    /** @type {LocationResource[]} Locations. */
    locations = [];

    /**
     * Assign attributes from another instance.
     * @param {Partial<import('@/types').ExcludeMethods<LocationHierarchyResource>>} attributes
     */
    constructor(attributes = {}) {
        Object.assign(this, attributes);
    }

    /**
     * Parse model state to get the resource.
     * @param {LocationHierarchy} model
     * @returns {this}
     */
    parseModel(model) {
        this.id = model.get(FIELDS.id);
        this.name = model.get(FIELDS.name);
        this.path = model.get(FIELDS.path);
        this.depth = model.get(FIELDS.depth);
        this.dateCreated = model.get(FIELDS.dateCreated);
        this.dateUpdated = model.get(FIELDS.dateUpdated);
        this.parentId = model.get(FIELDS.parentId);

        /** @type {LocationHierarchy[]} Children... */
        const children = model.get(FIELDS.children);
        this.children = children.map((child) => child.toResource());

        /** @type {Location[]} Locations ... */
        const locations = model.get(FIELDS.locations);
        this.locations = locations.map((location) => location.toResource());

        // Return this.
        return this;
    }

    /**
     * Convert this resource into a model.
     * @returns {readonly [typeof FIELDS[keyof FIELDS], any][]}
     */
    entries() {
        /** @type {readonly [typeof FIELDS[keyof FIELDS], any][]} */
        return [
            [FIELDS.id, this.id],
            [FIELDS.name, this.name],
            [FIELDS.depth, this.depth],
            [FIELDS.path, this.path],
            [FIELDS.dateCreated, this.dateCreated],
            [FIELDS.dateUpdated, this.dateUpdated],
            [FIELDS.parentId, this.parentId],
            [
                FIELDS.children,
                this.children.map(
                    (child) =>
                        new LocationHierarchy({
                            resource: child,
                        })
                ),
            ],
            [
                FIELDS.locations,
                this.locations.map(
                    (location) =>
                        new Location({
                            resource: location,
                        })
                ),
            ],
        ];
    }

    /**
     * Unroll all ancestors.
     *
     * @returns {LocationHierarchyResource[]} Flattened array.
     */
    unroll() {
        return [...unrollLocationHierarchy(this)];
    }
}

/**
 * Flatten hierarichal representation of the hierarchy tree.
 *
 * @param {LocationHierarchyPayload} node Current node.
 */
export const unrollLocationPayload = function* (node) {
    // Yield the root node.
    yield node;

    // If node has children, yield flattened children.
    if (Array.isArray(node.children)) {
        for (const child of node.children) {
            yield* unrollLocationPayload(child);
        }
    }
};

/**
 * Flatten hierarichal representation of the hierarchy tree.
 *
 * @param {LocationHierarchyResource} node Current node.
 */
export const unrollLocationHierarchy = function* (node) {
    // Yield the root node.
    yield node;

    // If node has children, yield flattened children.
    if (Array.isArray(node.children)) {
        for (const child of node.children) {
            yield* unrollLocationHierarchy(child);
        }
    }
};

/**
 * @class
 * @extends {Model<LocationHierarchyPayload, LocationHierarchyResource>}
 */
export class LocationHierarchy extends Model {
    _initialState() {
        return Model.ComposeStateUsingFields(FIELDS);
    }

    _registerAliases() {
        return Model.ComposeAliasesUsingTable(ALIASES);
    }

    /**
     * @param {import('@/types').ExcludeMethods<LocationHierarchyPayload>} payload
     * @returns {this}
     */
    parsePayload(payload) {
        const instance = new LocationHierarchyPayload(payload);
        return this.parseArray(instance.entries());
    }

    /**
     * @returns {LocationHierarchyPayload}
     */
    toPayload() {
        return new LocationHierarchyPayload().parseModel(this);
    }

    /**
     * Parse the input resource.
     * @param {import('@/types').ExcludeMethods<LocationHierarchyResource>} resource
     * @returns {this}
     */
    parseResource(resource) {
        const instance = new LocationHierarchyResource(resource);
        return this.parseArray(instance.entries());
    }

    /**
     * @returns {LocationHierarchyResource}
     */
    toResource() {
        return new LocationHierarchyResource().parseModel(this);
    }
}
