// <!-- API -->
import { AxisRangeFilter } from '@/utils/filters';

// <!-- TYPES -->
// ==== GRAPH FILTERS ====
/** @typedef {import('@/utils/filters').IAxisID} IAxisID */
/** @typedef {import('@/utils/filters').IAxisRange} IAxisRange */
/** @typedef {import('@/utils/filters').IScaleFilter} IScaleFilter */

/**
 * @class
 * Individual limit filter for the keyed axis.
 * @implements {IScaleFilter}
 */
export class ScaleFilter extends AxisRangeFilter {
    /**
     * Create an axis range.
     * @param {Readonly<Partial<IScaleFilter>>} [props]
     * @returns {IScaleFilter}
     */
    static create = (props = {}) => new ScaleFilter(props);

    /**
     * Clone an existing axis range.
     * @param {Readonly<IScaleFilter>} [source]
     * @returns {IScaleFilter}
     */
    static clone = (source = new ScaleFilter()) => new ScaleFilter(source);

    /**
     * Create a temperature limit range.
     * @param {Readonly<Partial<Omit<IScaleFilter, 'key'>>>} [props]
     * @returns {IScaleFilter}
     */
    static temp = (props = {}) => ScaleFilter.create({ ...props, key: 'temp' });

    /**
     * Create a relative humidity limit range.
     * @param {Readonly<Partial<Omit<IScaleFilter, 'key'>>>} [props]
     * @returns {IScaleFilter}
     */
    static rh = (props = {}) => ScaleFilter.create({ ...props, key: 'rh' });

    /**
     * Create a dew point limit range.
     * @param {Readonly<Partial<Omit<IScaleFilter, 'key'>>>} [props]
     * @returns {IScaleFilter}
     */
    static dp = (props = {}) => ScaleFilter.create({ ...props, key: 'dp' });

    /**
     * Create an axis range.
     * @param {Readonly<Partial<IScaleFilter>>} [props]
     */
    constructor(props) {
        super(props);
        /** @type {IAxisID} Filter key. */
        this.key = props.key;
    }
}
