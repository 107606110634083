/**
 * Default modifier CSS for all buttons.
 */
export const defaultModifiers = ['rounded-md', 'shadow-md'];

/**
 * You can add additional modifiers to this composable.
 *
 * Be sure to update useButtonPresets.js.
 */
