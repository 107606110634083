// <!-- PLUGINS -->
import { useAxios as axios } from '@/plugins/axios';
import { AxiosError } from 'axios';

// <!-- UTILITIES -->
import { Result } from 'true-myth/dist/result';
import { ErrorResult } from '@/utils/server';

// <!-- MODELS -->
import { Organization } from '@/models/v2/organizations';
import { Account } from '@/models/v2/accounts';
import { AccountPayload } from '@/payloads/v2/accounts';
import { User } from '@/models/v2/users';
import { UserPayload } from '@/payloads/v2/users';
import { Maybe } from 'true-myth/dist/maybe';

// <!-- ROUTES -->

/** @type {Readonly<Session.Routes>} */
const URI = {
    user: () => `session/user`,
    organization: {
        show: () => `session/organization`,
        select: () => `session/organization`,
        clear: () => `session/organization`,
    },
    account: {
        show: () => `session/account`,
        select: () => `session/account`,
        clear: () => `session/account`,
    },
};

// <!-- ENDPOINTS -->

/**
 * Get the current user, if one is logged in.
 * @type {Session.API.FetchUser}
 */
export const fetchSessionUser = async (config) => {
    try {
        /** @type {Session.Response.FetchedUser} */
        const response = await axios().get(URI.user(), config);
        const payload = Maybe.of(response.data?.user).map(
            (t) => new UserPayload(t)
        );
        const model = payload.map((t) => new User(t));
        const result = model.toOkOrElseErr(() => {
            throw new Error('Unable to parse resource from endpoint response.');
        });
        return result;
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e)
            : ErrorResult.from(e);
    }
};

/**
 * Get the current organization, if one is selected.
 * @type {Session.API.FetchOrganization}
 */
export const fetchSessionOrganization = async (config) => {
    try {
        /** @type {Session.Response.FetchedOrganization} */
        const response = await axios().get(URI.organization.show(), config);
        const payload = Maybe.of(response.data?.user.current_organization).map(
            Organization.Payload.create
        );
        const organization = payload.map(Organization.Model.fromPayload);
        const result = organization.toOkOrElseErr(() => {
            throw new Error('Unable to parse resource from endpoint response.');
        });
        return result;
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e)
            : ErrorResult.from(e);
    }
};

/**
 * Get the current account, if one is selected.
 * @type {Session.API.FetchAccount}
 */
export const fetchSessionAccount = async (config) => {
    try {
        /** @type {Session.Response.FetchedAccount} */
        const response = await axios().get(URI.account.show(), config);
        const payload = Maybe.of(response.data?.user.current_account).map(
            (t) => new AccountPayload(t)
        );
        const account = payload.map((t) => new Account(t));
        const result = account.toOkOrElseErr(() => {
            throw new Error('Unable to parse resource from endpoint response.');
        });
        return result;
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e)
            : ErrorResult.from(e);
    }
};

/**
 * Select the requested organization.
 * @type {Session.API.SelectOrganization}
 */
export const selectOrganization = async (request, config) => {
    try {
        /** @type {Session.Response.SelectedOrganization} */
        const response = await axios().post(
            URI.organization.select(),
            request,
            config
        );
        const result = Result.ok(response.status === 204);
        return result;
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e)
            : ErrorResult.from(e);
    }
};

/**
 * Select the requested account.
 * @type {Session.API.SelectAccount}
 */
export const selectAccount = async (request, config) => {
    try {
        /** @type {Session.Response.SelectedAccount} */
        const response = await axios().post(
            URI.account.select(),
            request,
            config
        );
        const result = Result.ok(response.status === 204);
        return result;
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e)
            : ErrorResult.from(e);
    }
};

/**
 * Clear the current organization.
 * @type {Session.API.ClearOrganization}
 */
export const clearOrganization = async (config) => {
    try {
        /** @type {Session.Response.ClearedOrganization} */
        const response = await axios().delete(URI.organization.clear(), config);
        const result = Result.ok(response.status === 204);
        return result;
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e)
            : ErrorResult.from(e);
    }
};

/**
 * Clear the current account.
 * @type {Session.API.ClearAccount}
 */
export const clearAccount = async (config) => {
    try {
        /** @type {Session.Response.ClearedAccount} */
        const response = await axios().delete(URI.account.clear(), config);
        const result = Result.ok(response.status === 204);
        return result;
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e)
            : ErrorResult.from(e);
    }
};

/** @type {Readonly<Session.API>} */
export default {
    fetchSessionUser,
    fetchSessionOrganization,
    fetchSessionAccount,
    selectOrganization,
    selectAccount,
    clearOrganization,
    clearAccount,
};
