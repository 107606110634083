// <!-- UTILITY -->
import { Enum } from '@/utils/enums';

export const ReportType = Enum.create({
    overview: 'overview',
    performance: 'performance',
    nara: 'nara',
    compare: 'compare',
    custom: 'custom',
});

export default ReportType;
