// <!-- UTILITIES -->
import { isString } from '@/utils/typeof';
import { DateTimeLocalPattern } from '@/utils/regexp';

// <!-- DEFINITION -->
/**
 * Test if input {@link String} matches a valid `datetime-local` input element pattern.
 * @param {String} argument Input value to test.
 * @returns {argument is String} `true`, when input argument is of a valid format.
 */
export const isDateTimeLocalString = (argument) => {
    const pattern = DateTimeLocalPattern;
    const isValidString = isString(argument);
    const isValidPattern = isValidString && pattern.test(argument);
    return isValidPattern === true;
};

// <!-- DEFAULT -->
export default isDateTimeLocalString;
