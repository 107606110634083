// <!-- PLUGINS -->
import { useAxios as axios } from '@/plugins/axios';
import { AxiosError } from 'axios';

// <!-- UTILITIES -->
import { Result } from 'true-myth/dist/result';
import { ErrorResult } from '@/utils/server';

// <!-- MODELS -->
import { Organization } from '@/models/v2/organizations';
import { Account } from '@/models/v2/accounts';
import { AccountPayload } from '@/payloads/v2/accounts';
import { User } from '@/models/v2/users';
import { UserPayload } from '@/payloads/v2/users';
import { Maybe } from 'true-myth/dist/maybe';

// <!-- ROUTES -->

/** @type {Readonly<Profile.Routes>} */
const URI = {
    index: () => `profile`,
    organizations: () => `profile/organizations`,
    accounts: () => `profile/accounts`,
    update: () => `profile`,
    forgetPassword: () => `profile/forgot-password`,
    resetPassword: () => `profile/reset-password`,
    changePassword: () => `profile/change-password`,
};

// <!-- ENDPOINTS -->

/**
 * Get the specified user.
 * @type {Profile.API.FetchIndex}
 */
export const fetchCurrentUser = async (config) => {
    try {
        /** @type {Profile.Response.FetchedIndex} */
        const response = await axios().get(URI.index(), config);
        const payload = new UserPayload(response.data.profile);
        const model = new User(payload);
        const result = Result.ok(model);
        return result;
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e)
            : ErrorResult.from(e);
    }
};

/**
 * Get all organizations the specified user is assigned to.
 * @type {Profile.API.FetchOrganizations}
 */
export const fetchUserOrganizations = async (config) => {
    try {
        /** @type {Profile.Response.FetchedOrganizations} */
        const response = await axios().get(URI.organizations(), config);
        const empty = /** @type {globalThis.Organization.Payload[]} */ ([]);
        const user = Maybe.of(response.data?.user).unwrapOr(empty);
        const data = Maybe.of(response.data?.organizations).unwrapOr(empty);
        const payloads = data.map(Organization.Payload.create);
        const models = payloads.map(Organization.Model.fromPayload);
        const result = Result.ok(models);
        return result;
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e)
            : ErrorResult.from(e);
    }
};

/**
 * Get all accounts the specified user is assigned to.
 * @type {Profile.API.FetchAccounts}
 */
export const fetchUserAccounts = async (config) => {
    try {
        /** @type {Profile.Response.FetchedAccounts} */
        const response = await axios().get(URI.accounts(), config);
        const empty = /** @type {globalThis.Account.Payload[]} */ ([]);
        const user = Maybe.of(response.data?.user).unwrapOr({ is_super: 0 });
        const data = Maybe.of(
            user.is_super === 1
                ? response.data?.organization_accounts
                : response.data.accounts
        ).unwrapOr(empty);
        const payloads = data.map((item) => new AccountPayload(item));
        const models = payloads.map((payload) => new Account(payload));
        const result = Result.ok(models);
        return result;
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e)
            : ErrorResult.from(e);
    }
};

/**
 * Update the specified user.
 * @type {Profile.API.UpdateResource}
 */
export const updateCurrentUser = async (request, config) => {
    try {
        /** @type {Profile.Response.UpdatedResource} */
        const response = await axios().put(URI.update(), request, config);
        const payload = new UserPayload(response.data.profile);
        const model = new User(payload);
        const result = Result.ok(model);
        return result;
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e, request)
            : ErrorResult.from(e);
    }
};

/**
 * Change the password of the specified user.
 * @type {Profile.API.ChangePassword}
 */
export const changePassword = async (request, config) => {
    try {
        /** @type {Profile.Response.ChangedPassword} */
        const response = await axios().put(
            URI.changePassword(),
            request,
            config
        );
        const result = Result.ok(response.data.status === 'success');
        return result;
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e, request)
            : ErrorResult.from(e);
    }
};

/**
 * Send a password reset email for the specified user.
 * @type {Profile.API.ForgetPassword}
 */
export const forgetPassword = async (request, config) => {
    try {
        /** @type {Profile.Response.ForgotPassword} */
        const response = await axios().post(
            URI.forgetPassword(),
            request,
            config
        );
        const result = Result.ok(response.data.status === 'passwords.sent');
        return result;
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e, request)
            : ErrorResult.from(e);
    }
};

/**
 * Send a password reset email for the specified user.
 * @type {Profile.API.ResetPassword}
 */
export const resetPassword = async (request, config) => {
    try {
        /** @type {Profile.Response.ResetPassword} */
        const response = await axios().post(
            URI.resetPassword(),
            request,
            config
        );
        const result = Result.ok(response.data.status === 'success');
        return result;
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e, request)
            : ErrorResult.from(e);
    }
};

/** @type {Readonly<Profile.API>} */
export default {
    fetchCurrentUser,
    updateCurrentUser,
    fetchUserOrganizations,
    fetchUserAccounts,
    changePassword,
    forgetPassword,
    resetPassword,
};
