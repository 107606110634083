// <!-- UTILITY -->
import { Enum } from '@/utils/enums';

export const Size = Enum.create({
    tiny: 'xs',
    small: 'sm',
    medium: 'md',
    large: 'lg',
    huge: 'xl',
});

export default Size;
