// <!-- UTILITIES -->
import { Enum } from '@/utils/enums';

// <!-- STATE REFERENCE -->
/** @type {State.References} */
export const States = [
    { iso2: 'US-AL', subdivision: 'Alabama' },
    { iso2: 'US-AK', subdivision: 'Alaska' },
    { iso2: 'US-AZ', subdivision: 'Arizona' },
    { iso2: 'US-AR', subdivision: 'Arkansas' },
    { iso2: 'US-CA', subdivision: 'California' },
    { iso2: 'US-CO', subdivision: 'Colorado' },
    { iso2: 'US-CT', subdivision: 'Connecticut' },
    { iso2: 'US-DE', subdivision: 'Delaware' },
    { iso2: 'US-FL', subdivision: 'Florida' },
    { iso2: 'US-GA', subdivision: 'Georgia' },
    { iso2: 'US-HI', subdivision: 'Hawaii' },
    { iso2: 'US-ID', subdivision: 'Idaho' },
    { iso2: 'US-IL', subdivision: 'Illinois' },
    { iso2: 'US-IN', subdivision: 'Indiana' },
    { iso2: 'US-IA', subdivision: 'Iowa' },
    { iso2: 'US-KS', subdivision: 'Kansas' },
    { iso2: 'US-KY', subdivision: 'Kentucky' },
    { iso2: 'US-LA', subdivision: 'Louisiana' },
    { iso2: 'US-ME', subdivision: 'Maine' },
    { iso2: 'US-MD', subdivision: 'Maryland' },
    { iso2: 'US-MA', subdivision: 'Massachusetts' },
    { iso2: 'US-MI', subdivision: 'Michigan' },
    { iso2: 'US-MN', subdivision: 'Minnesota' },
    { iso2: 'US-MS', subdivision: 'Mississippi' },
    { iso2: 'US-MO', subdivision: 'Missouri' },
    { iso2: 'US-MT', subdivision: 'Montana' },
    { iso2: 'US-NE', subdivision: 'Nebraska' },
    { iso2: 'US-NV', subdivision: 'Nevada' },
    { iso2: 'US-NH', subdivision: 'New Hampshire' },
    { iso2: 'US-NJ', subdivision: 'New Jersey' },
    { iso2: 'US-NM', subdivision: 'New Mexico' },
    { iso2: 'US-NY', subdivision: 'New York' },
    { iso2: 'US-NC', subdivision: 'North Carolina' },
    { iso2: 'US-ND', subdivision: 'North Dakota' },
    { iso2: 'US-OH', subdivision: 'Ohio' },
    { iso2: 'US-OK', subdivision: 'Oklahoma' },
    { iso2: 'US-OR', subdivision: 'Oregon' },
    { iso2: 'US-PA', subdivision: 'Pennsylvania' },
    { iso2: 'US-RI', subdivision: 'Rhode Island' },
    { iso2: 'US-SC', subdivision: 'South Carolina' },
    { iso2: 'US-SD', subdivision: 'South Dakota' },
    { iso2: 'US-TN', subdivision: 'Tennessee' },
    { iso2: 'US-TX', subdivision: 'Texas' },
    { iso2: 'US-UT', subdivision: 'Utah' },
    { iso2: 'US-VT', subdivision: 'Vermont' },
    { iso2: 'US-VA', subdivision: 'Virginia' },
    { iso2: 'US-WA', subdivision: 'Washington' },
    { iso2: 'US-WV', subdivision: 'West Virginia' },
    { iso2: 'US-WI', subdivision: 'Wisconsin' },
    { iso2: 'US-WY', subdivision: 'Wyoming' },
    { iso2: 'US-DC', subdivision: 'District of Columbia' },
    { iso2: 'US-AS', subdivision: 'American Samoa' },
    { iso2: 'US-GU', subdivision: 'Guam' },
    { iso2: 'US-MP', subdivision: 'Northern Mariana Islands' },
    { iso2: 'US-PR', subdivision: 'Puerto Rico' },
    { iso2: 'US-UM', subdivision: 'United States Minor Outlying Islands' },
    { iso2: 'US-VI', subdivision: 'Virgin Islands, U.S.' },
];

// Get record mapping iso2 codes to subdivision names.
const NameByISO2 = States.reduce((context, region) => {
    return Object.assign(context, {
        [region.iso2]: region.subdivision,
    });
}, /** @type {State.ByISO2} */ ({}));

// <!-- STATE NAME -->
// Get the region subdivision names.
const Names = States.map((region) => region.subdivision);

// Get the region subdivision iso2 codes.
const ISO2s = States.map((region) => region.iso2);

// Get record mapping state names to iso2 codes.
const ISO2ByName = States.reduce((context, region) => {
    return Object.assign(context, {
        [region.subdivision]: region.iso2,
    });
}, /** @type {State.ISO2ByName} */ ({}));

// <!-- NAMED EXPORTS -->
/** Helper class for state names. */
export class StateName {
    /** Enum mapping iso2 codes to state names. */
    static ByISO2 = Enum.create(NameByISO2);

    /** Get array of state names. */
    static get values() {
        return [...Names];
    }

    /**
     * Check if the passed value is a valid state name.
     *
     * @param {string} value
     * @returns {value is State.Name}
     */
    static isState(value) {
        return (
            typeof value === 'string' &&
            Names.includes(/** @type {State.Name} */ (value))
        );
    }
}

/** Helper class for iso2 state codes. */
export class StateISO2 {
    /** Enum mapping iso2 codes to state names. */
    static ByName = Enum.create(ISO2ByName);

    /** Get array of state names. */
    static get values() {
        return [...ISO2s];
    }

    /**
     * Check if the passed value is a valid state name.
     *
     * @param {string} value
     * @returns {value is State.ISO2}
     */
    static isState(value) {
        return (
            typeof value === 'string' &&
            ISO2s.includes(/** @type {State.ISO2} */ (value))
        );
    }
}
