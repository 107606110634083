// <!-- UTILITIES -->
import pick from 'just-pick';

// <!-- TYPES -->
/** @typedef {import("../sidebar/SidebarFilterRecord").ITimezoneFilter} ITimezoneFilter */

/**
 * @class
 * Record containing the inclusive lower and upper date range bounds.
 * @implements {ITimezoneFilter}
 */
export class TimezoneFilter {
    /**
     * Create an date range.
     * @param {Readonly<Partial<ITimezoneFilter>>} [props]
     * @returns {ITimezoneFilter}
     */
    static create = (props = {}) => new TimezoneFilter(props);

    /**
     * Clone an existing date range.
     * @param {Readonly<ITimezoneFilter>} [source]
     * @returns {ITimezoneFilter}
     */
    static clone = (source = new TimezoneFilter()) =>
        new TimezoneFilter(source);

    /**
     * Extract only the data members from an existing instance.
     * @param {Readonly<ITimezoneFilter>} [target]
     * @returns {ITimezoneFilter}
     */
    static data = (target) => pick(target, 'timezone', 'useAccountTimezone');

    /**
     * Create an date range.
     * @param {Readonly<Partial<ITimezoneFilter>>} [props]
     */
    constructor(props) {
        // Get the extents.
        this.timezone = props?.timezone ?? 'UTC';
        // Get the modifier.
        this.useAccountTimezone = props?.useAccountTimezone ?? true;
    }
}
