// <!-- PLUGINS -->
import { useAxios as axios } from '@/plugins/axios';

// <!-- TYPES -->
/** @typedef {globalThis.Account.Model} AccountResource */

// <!-- MODELS -->
import { WeatherStation } from '@/models/v1/weather/WeatherStation';

// <!-- ROUTES -->
const ROUTES = {
    GET_WEATHER_STATIONS: (account) => `accounts/${account}/weather-stations`,
    GET_WEATHER_STATION_BY_ID: (account, id) =>
        `accounts/${account}/weather-stations/${id}`,
    REGISTER_WEATHER_STATION: (account) =>
        `accounts/${account}/weather-stations`,
    UNREGISTER_WEATHER_STATION: (account, id) =>
        `accounts/${account}/weather-stations/${id}`,
};

// <!-- REQUESTS -->

/**
 * Fetch all weather stations associated with the specified account.
 * @param {Partial<AccountResource>} account Account resource.
 * @param {import('@/types').AxiosRequestConfig} [config]
 * @returns {Promise<import('@/models/v1/weather/WeatherStation').WeatherStationResource[]>}
 */
export const fetchWeatherStations = async (account, config = {}) => {
    /** @type {import('axios').AxiosResponse<{ data: import('@/models/v1/weather/WeatherStation').WeatherStationPayload[] }>} */
    const response = await axios().get(
        ROUTES.GET_WEATHER_STATIONS(account.id),
        config ?? {}
    );
    const collection = response.data.data;
    return collection.map((payload) =>
        new WeatherStation({ payload }).toResource()
    );
};

/**
 * Register weather station
 * @param {Partial<AccountResource>} account Account resource.
 * @param {Partial<import('@/models/v1/weather/WeatherStation').WeatherStationResource>} station Weather station resource.
 * @returns {Promise<import('@/models/v1/weather/WeatherStation').WeatherStationResource>}
 */
export const fetchWeatherStationById = async (account, station) => {
    /** @type {import('axios').AxiosResponse<{ weather_station: import('@/models/v1/weather/WeatherStation').WeatherStationPayload, status: string }>} */
    const response = await axios().get(
        ROUTES.GET_WEATHER_STATION_BY_ID(account.id, station.id)
    );
    const payload = response.data;
    return new WeatherStation({ payload }).toResource();
};

/**
 * Register weather station
 * @param {Partial<AccountResource>} account Account resource.
 * @param {Partial<import('@/models/v1/weather/WeatherStation').WeatherStationResource>} station Weather station resource.
 */
export const registerWeatherStation = async (account, station) => {
    /** @type {{ station_id: String }} Station ID to register. */
    const request = { station_id: station.id };
    /** @type {import('axios').AxiosResponse<{ weather_station?: import('@/models/v1/weather/WeatherStation').WeatherStationPayload, status?: string, message?: string, errors?: { station_id: string[] } }>} */
    const response = await axios().post(
        ROUTES.REGISTER_WEATHER_STATION(account.id),
        request
    );
    return response.data;
};

/**
 * Unregister weather station.
 * @param {Partial<AccountResource>} account Account resource.
 * @param {Partial<import('@/models/v1/weather/WeatherStation').WeatherStationResource>} station Weather station resource.
 * @returns {Promise<{ status: string }>}
 */
export const unregisterWeatherStation = async (account, station) => {
    /** @type {import('axios').AxiosResponse<{ status: string }>} */
    const response = await axios().delete(
        ROUTES.UNREGISTER_WEATHER_STATION(account.id, station.id)
    );
    return response.data;
};

// <!-- EXPORTS -->
export default {
    WeatherStation,
    fetchWeatherStations,
    fetchWeatherStationById,
    registerWeatherStation,
    unregisterWeatherStation,
};
