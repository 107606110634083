// <!-- UTILITY -->
import { Enum } from '@/utils/enums';

export const UserAccountRole = Enum.create({
    Administrator: 'admin',
    DataManager: 'data-manager',
    DataAnalyst: 'data-analyst',
    Guest: 'guest',
});

export default UserAccountRole;
