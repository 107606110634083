// <!-- API -->
import { ECNBResourceIndexState } from '@/store/types/cache/state';

/**
 * @class
 * Base collection management for a resource index.
 * @extends {ECNBResourceIndexState<Number, Organization>}
 */
export class OrganizationIndexState extends ECNBResourceIndexState {
    /**
     * Initial resource index.
     * @param {Map<Number, Organization>} [index]
     * @param {Map<String, any>} [attributes]
     */
    constructor(index = new Map(), attributes = new Map()) {
        super('organizations', index, attributes);
    }
}
