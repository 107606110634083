// <!-- INTERFACES -->
import { PayloadResource } from '@/utils/resources';
import { PayloadAttributeFactory } from '@/utils/resources';
import { AccessModel } from '@/models/v2/organizations/AccessModel';

// <!-- ENUMS -->
import { UserAccountRole } from '@/enums';

/**
 * Create a specialized resource instance.
 *
 * @extends {PayloadResource<PayloadData,ModelData>}
 * @implements {Resource.Payload<PayloadData,ModelData>}
 * @implements {PayloadData}
 */
export class AccessPayload extends PayloadResource {
    // <!-- TYPE ALIASES -->

    /** @typedef {Organization.Payload.OnUser} PayloadData */
    /** @typedef {Organization.Model.OnUser} ModelData */

    // <!-- STATIC FACTORY METHODS -->

    /**
     * Create resource instance from passed attributes.
     *
     * @param {Partial<PayloadData>} attributes
     * @returns {AccessPayload}
     */
    static create(attributes) {
        return new AccessPayload(attributes);
    }

    /**
     * Create resource instance from passed attributes.
     *
     * @param {Partial<ModelData>} data
     * @returns {AccessPayload}
     */
    static fromModel(data) {
        const attributes = AccessPayload.attributesFromModel(data);
        return AccessPayload.create(attributes);
    }

    // <!-- STATIC UTILITY METHODS -->

    /** @type {Readonly<PayloadData>} */
    static get defaults() {
        return {
            user_id: null,
            organization_id: null,
            created_at: null,
            updated_at: null,
            role_name: UserAccountRole.Guest,
        };
    }

    /**
     * Create resource attributes from the model data.
     *
     * @param {Partial<ModelData>} model
     * @returns {Partial<PayloadData>}
     */
    static attributesFromModel(model) {
        // Get transformer functions.
        const { serialize } = this;

        // Return the created state.
        return {
            user_id: model.userId,
            organization_id: model.organizationId,
            created_at: serialize.dateAsISOString(model.createdAt),
            updated_at: serialize.dateAsISOString(model.updatedAt),
            role_name: model.userRole,
        };
    }

    // <!-- CONSTRUCTOR -->

    /**
     * Create resource instance.
     *
     * @param {Partial<PayloadData>} attributes
     */
    constructor(attributes) {
        super(
            attributes,
            PayloadAttributeFactory.create(
                AccessPayload.attributesFromModel,
                AccessPayload.defaults
            )
        );
    }

    // <!-- RESOURCE INTERFACE -->

    /** Get shallow copy of this instance as a resource. */
    toPayload() {
        return this.clone();
    }

    /** Get shallow copy of this instance as a resource. */
    toModel() {
        return AccessModel.fromPayload(this);
    }

    /** Get shallow copy of this instance. */
    clone() {
        return /** @type {this} */ (AccessPayload.create(this));
    }

    // <!-- ATTRIBUTE::PROPERTIES -->

    get user_id() {
        return this.get('user_id');
    }

    set user_id(value) {
        this.set('user_id', value);
    }

    get organization_id() {
        return this.get('organization_id');
    }

    set organization_id(value) {
        this.set('organization_id', value);
    }

    get created_at() {
        return this.get('created_at');
    }

    set created_at(value) {
        this.set('created_at', value);
    }

    get updated_at() {
        return this.get('updated_at');
    }

    set updated_at(value) {
        this.set('updated_at', value);
    }

    get role_name() {
        return this.get('role_name');
    }

    set role_name(value) {
        this.set('role_name', value);
    }
}

// <!-- EXPORTS -->
export default AccessPayload;
