// <!-- EXPORTS -->
/** @typedef {Record<String, Readonly<Router.RouteRecordRaw>>} */
export const info = {
    about: {
        path: '/about',
        name: 'About',
        component: () =>
            import(
                /* webpackPrefetch: true */ /* webpackChunkName: "info" */ '@/views/About.vue'
            ),
        meta: { namespace: 'info' },
    },
    catchAll: {
        path: '/:catchAll(.*)',
        name: '404',
        component: () =>
            import(
                /* webpackPrefetch: true */ /* webpackChunkName: "info" */ '@/features/layout/views/404.vue'
            ),
        meta: { namespace: 'info' },
    },
};

// <!-- DEFAULT -->
export default info;
