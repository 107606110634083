// <!-- DEFINE -->
/** @typedef {Record<String, Readonly<Router.RouteRecordRaw>>} */
const children = {
    locations: {
        path: 'locations',
        name: 'Locations',
        component: () =>
            import(
                /* webpackPrefetch: true */ /* webpackChunkName: "data" */ '@/features/data-manager/views/DataManager.vue'
            ),
        meta: { namespace: 'data-manager' },
        alias: ['/locations'],
    },
    location: {
        path: 'locations/:id',
        name: 'Location Detail',
        component: () =>
            import(
                /* webpackPrefetch: true */ /* webpackChunkName: "data" */ '@/features/data-manager/views/LocationDetail.vue'
            ),
        meta: { namespace: 'data-manager' },
    },
    stations: {
        path: 'weather-stations',
        name: 'Weather Stations',
        component: () =>
            import(
                /* webpackPrefetch: true */ /* webpackChunkName: "data" */ '@/features/data-manager/views/WeatherStations.vue'
            ),
        meta: { namespace: 'data-manager' },
        alias: ['/weather-stations'],
    },
};

// <!-- EXPORTS -->
/** @typedef {Record<String, Readonly<Router.RouteRecordRaw>>} */
export const locations = {
    index: {
        path: '/data-manager',
        name: 'Data Manager',
        redirect: '/data-manager/locations',
        component: () =>
            import(
                /* webpackPrefetch: true */ /* webpackChunkName: "data" */ '@/features/data-manager/views/index.vue'
            ),
        meta: { namespace: 'data-manager' },
        children: [children.stations, children.locations, children.location],
    },
};

// <!-- DEFAULT -->
export default locations;
