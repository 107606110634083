// <!-- MODELS -->
import { endOfTimezoneDay, startOfTimezoneDay } from '@/utils/timezone';
import { FormDataModel } from './FormDataModel';

// <!-- UTILITIES -->
import { sub } from 'date-fns';
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';
import { parse as parseDurationString } from 'tinyduration';

// <!-- DATA CLASS -->
/**
 * Represents the form data model of a date range filter.
 *
 * @implements {Filters.DateRange.Store.Data}
 */
export class StoreDataModel {
    // TYPE DEFINITIONS

    /** @typedef {Filters.DateRange.Store.Data} Data */

    // STATIC METHODS

    /**
     * Create new filter state instance.
     *
     * @param {Partial<Data>} [params]
     * @returns {StoreDataModel}
     */
    static create(params = {}) {
        return new StoreDataModel(params);
    }

    /**
     * Calculate the timezone-aware date range given a preset and reference end date.
     *
     * @param {Duration | string} [preset] Preset ISO-8601 duration string or duration record.
     * @param {DateLike} [reference] If not specified, defaults to current time. Represents incremental moment of time to compute the end date from, in conjunction with the timezone specifier.
     * @param {TimeZone.Identifier} [timezone] Timezone to compute with.
     * @returns {FixedDateRange}
     */
    static computePresetDateRange(
        preset = 'P1Y0S',
        reference = new Date(),
        timezone = 'UTC'
    ) {
        // Prepare timezone-aware date formatter. eg. YYYY-MM-DD
        const dateFormatter = new Intl.DateTimeFormat('en-CA', {
            hour12: false,
            timeZone: timezone,
            dateStyle: 'short',
        });

        // Prepare timezone-aware time formatter. eg. HH:MM:SS ZONE
        const timeFormatter = new Intl.DateTimeFormat('en-CA', {
            hour12: false,
            timeZone: timezone,
            timeZoneName: 'short',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        });

        // Compute the duration.
        const duration =
            typeof preset === 'string'
                ? parseDurationString(preset)
                : preset ?? { years: 1 };

        // Find the short timezone name.
        const timezoneAbbreviation = timeFormatter
            .format(reference)
            .substring(8)
            .trimStart();

        // Compute the end date as a moment of time in the specified timezone on the stated day.
        const end = new Date(
            `${dateFormatter.format(
                reference
            )}, 23:59:59 ${timezoneAbbreviation}`
        );

        // If date is invalid, throw an error.
        if (Number.isNaN(end.valueOf())) {
            throw new TypeError(
                'Cannot compute the end date using the provided arguments.'
            );
        }

        // Compute the offset past date using the duration and the computed end date.
        const difference = sub(end, duration);

        // Compute the start date using the difference's date component and 00:00:00 timestamp in the specified timezone.
        const start = new Date(
            `${dateFormatter.format(
                difference
            )}, 00:00:00 ${timezoneAbbreviation}`
        );

        // If date is invalid, throw an error.
        if (Number.isNaN(start.valueOf())) {
            throw new TypeError(
                'Cannot compute the start date using the provided arguments.'
            );
        }

        // Return the interval, with dates pushed to
        return { start, end };
    }

    /**
     * Compute a checked modifier list from the record.
     * @param {Partial<{ [K in Filters.DateRange.Modifier]: boolean }>} [params]
     * @returns {Filters.DateRange.ModifierList}
     */
    static computeCheckedModifiers(params = {}) {
        /** @type {Filters.DateRange.ModifierList} */
        const checked = new Set(['all', 'overlap']);

        if (params?.all !== true) {
            checked.delete('all');
        }

        if (params?.overlap !== true) {
            checked.delete('overlap');
        }

        // Return the modifier list.
        return checked;
    }

    // CONSTRUCTOR

    /**
     * Instantiate filter state.
     * @param {Partial<Data>} [params]
     */
    constructor(params = {}) {
        // DEFINE PROPERTIES
        this.start = '';
        this.end = '';
        this.checked = /** @type {Filters.DateRange.ModifierList} */ ([]);

        // APPLY INITIAL VALUES.
        this.update(params);
    }

    // SERVICE METHODS

    /**
     * Update the filter state.
     * @param {Partial<Data>} [params]
     * @returns {StoreDataModel}
     */
    update(params = {}) {
        // Update all the data where properties are enumerable and present.
        for (const key in params) {
            switch (key) {
                case 'start':
                case 'end':
                    this[key] = params?.[key] ?? '';
                    break;
                case 'checked':
                    const value = new Set(params?.[key] ?? []);
                    this[key] = Array.from(value);
                    break;
            }
        }

        // Return self.
        return this;
    }

    /**
     * Create a new instance with the same values as the current one.
     * @returns {StoreDataModel}
     */
    clone() {
        return new StoreDataModel({ ...this });
    }

    /**
     * Create a new form data model instance using this cache data model.
     */
    toFormModel() {
        const { start, end } = this;
        const modifiers = Array.from(this.checked);
        return new FormDataModel({
            start,
            end,
            all: modifiers?.includes('all'),
            overlap: modifiers?.includes('overlap'),
        });
    }
}

// <!-- DEFAULT -->
export default StoreDataModel;
