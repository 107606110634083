// <!-- API -->
import { Maybe } from 'true-myth/dist/maybe';

/**
 * Utility class for handling response status codes.
 */
export class Status {
    //------------------------------------------------------------------
    // CONSTANTS
    //------------------------------------------------------------------

    // 1xx
    static HTTP_CONTINUE = /** @type {const} */ (100);
    static HTTP_SWITCHING_PROTOCOLS = /** @type {const} */ (101);
    static HTTP_PROCESSING = /** @type {const} */ (102);
    static HTTP_EARLY_HINTS = /** @type {const} */ (103);

    // 2xx
    static HTTP_OK = /** @type {const} */ (200);
    static HTTP_CREATED = /** @type {const} */ (201);
    static HTTP_ACCEPTED = /** @type {const} */ (202);
    static HTTP_NON_AUTHORITATIVE_INFORMATION = /** @type {const} */ (203);
    static HTTP_NO_CONTENT = /** @type {const} */ (204);
    static HTTP_RESET_CONTENT = /** @type {const} */ (205);
    static HTTP_PARTIAL_CONTENT = /** @type {const} */ (206);
    static HTTP_MULTI_STATUS = /** @type {const} */ (207);
    static HTTP_ALREADY_REPORTED = /** @type {const} */ (208);
    static HTTP_IM_USED = /** @type {const} */ (226);

    // 3xx
    static HTTP_MULTIPLE_CHOICES = /** @type {const} */ (300);
    static HTTP_MOVED_PERMANENTLY = /** @type {const} */ (301);
    static HTTP_FOUND_ = /** @type {const} */ (302);
    static HTTP_SEE_OTHER = /** @type {const} */ (303);
    static HTTP_NOT_MODIFIED = /** @type {const} */ (304);
    static HTTP_USE_PROXY = /** @type {const} */ (305);
    static HTTP_SWITCH_PROXY = /** @type {const} */ (306);
    static HTTP_TEMPORARY_REDIRECT = /** @type {const} */ (307);
    static HTTP_PERMANENT_REDIRECT = /** @type {const} */ (308);

    // 4xx
    static HTTP_BAD_REQUEST = /** @type {const} */ (400);
    static HTTP_UNAUTHORIZED = /** @type {const} */ (401);
    static HTTP_PAYMENT_REQUIRED = /** @type {const} */ (402);
    static HTTP_FORBIDDEN = /** @type {const} */ (403);
    static HTTP_NOT_FOUND = /** @type {const} */ (404);
    static HTTP_METHOD_NOT_ALLOWED = /** @type {const} */ (405);
    static HTTP_NOT_ACCEPTABLE = /** @type {const} */ (406);
    static HTTP_PROXY_AUTHENTICATION_REQUIRED = /** @type {const} */ (407);
    static HTTP_REQUEST_TIMEOUT = /** @type {const} */ (408);
    static HTTP_CONFLICT = /** @type {const} */ (409);
    static HTTP_GONE = /** @type {const} */ (410);
    static HTTP_LENGTH_REQUIRED = /** @type {const} */ (411);
    static HTTP_PRECONDITION_FAILED = /** @type {const} */ (412);
    static HTTP_PAYLOAD_TOO_LARGE = /** @type {const} */ (413);
    static HTTP_URI_TOO_LONG = /** @type {const} */ (414);
    static HTTP_UNSUPPORTED_MEDIA_TYPE = /** @type {const} */ (415);
    static HTTP_RANGE_NOT_SATISFIABLE = /** @type {const} */ (416);
    static HTTP_EXPECTATION_FAILED = /** @type {const} */ (417);
    static HTTP_IM_A_TEAPOT = /** @type {const} */ (418);
    static HTTP_MISDIRECTED_REQUEST = /** @type {const} */ (421);
    static HTTP_UNPROCESSABLE_ENTITY = /** @type {const} */ (422);
    static HTTP_LOCKED = /** @type {const} */ (423);
    static HTTP_FAILED_DEPENDENCY = /** @type {const} */ (424);
    static HTTP_TOO_EARLY = /** @type {const} */ (425);
    static HTTP_UPGRADE_REQUIRED = /** @type {const} */ (426);
    static HTTP_PRECONDITION_REQUIRED = /** @type {const} */ (428);
    static HTTP_TOO_MANY_REQUESTS = /** @type {const} */ (429);
    static HTTP_REQUEST_HEADER_FIELDS_TOO_LARGE = /** @type {const} */ (431);
    static HTTP_UNAVAILABLE_FOR_LEGAL_REASONS = /** @type {const} */ (451);

    // 5xx
    static HTTP_INTERNAL_SERVER_ERROR = /** @type {const} */ (500);
    static HTTP_NOT_IMPLEMENTED = /** @type {const} */ (501);
    static HTTP_BAD_GATEWAY = /** @type {const} */ (502);
    static HTTP_SERVICE_UNAVAILABLE = /** @type {const} */ (503);
    static HTTP_GATEWAY_TIMEOUT = /** @type {const} */ (504);
    static HTTP_HTTP_VERSION_NOT_SUPPORTED = /** @type {const} */ (505);
    static HTTP_VARIANT_ALSO_NEGOTIATES = /** @type {const} */ (506);
    static HTTP_INSUFFICIENT_STORAGE = /** @type {const} */ (507);
    static HTTP_LOOP_DETECTED = /** @type {const} */ (508);
    static HTTP_NOT_EXTENDED = /** @type {const} */ (510);
    static HTTP_NETWORK_AUTHENTICATION_REQUIRED = /** @type {const} */ (511);

    //------------------------------------------------------------------
    // STATIC UTILITY METHODS
    //------------------------------------------------------------------

    /**
     * Indicates if the passed status code is valid.
     *
     * A status code is considered valid if it meets these constraints:
     * - Is defined
     * - Is non-null
     * - Is of type Number
     * - Is not NaN
     * - Is not Infinity
     * - Is a whole number
     * - Is a positive (zero-inclusive) number
     *
     * @param {unknown} code
     * @returns {code is Number}
     */
    static validate(code) {
        return (
            code !== undefined &&
            code !== null &&
            typeof code === 'number' &&
            !Number.isNaN(code) &&
            Number.isFinite(code) &&
            Number.isInteger(code) &&
            code >= 0
        );
    }

    //------------------------------------------------------------------
    // STATIC FACTORY METHODS
    //------------------------------------------------------------------

    /**
     * Instantiate status.
     * @param {number} [value]
     */
    static of(value) {
        const status = Maybe.of(value);
        const code = status.isJust ? status.value : null;
        return Status.validate(code)
            ? new Status(code)
            : new Status(Status.HTTP_INTERNAL_SERVER_ERROR);
    }

    //------------------------------------------------------------------
    // CONSTRUCTOR
    //------------------------------------------------------------------

    /**
     * Instantiate status.
     * @param {number} value
     */
    constructor(value) {
        this.value = value;

        if (!Status.validate(this.value)) {
            throw new Error('Invalid HTTP status code.', { cause: this.value });
        }
    }

    //------------------------------------------------------------------
    // PROPERTIES
    //------------------------------------------------------------------

    /** Indicates if value is a 2xx status code.  */
    get success() {
        return this.value >= 200 && this.value <= 299;
    }

    /** Indicates if value is NOT a 2xx status code.  */
    get error() {
        return !this.success;
    }

    //------------------------------------------------------------------
    // SERVICE METHODS
    //------------------------------------------------------------------

    /**
     * Indicates value is the same as the specified status code.
     * @param {number} code
     */
    is(code) {
        return this.value === code;
    }

    /**
     * Assert status matches the passed value.
     *
     * @param {number} status
     * @param {string} [message]
     */
    assert(status, message = null) {
        if (!this.is(status)) {
            throw new Error(
                message ??
                    `Expected HTTP ${status}. Received HTTP ${this.value}`
            );
        }
    }

    /**
     * Throws an error with the stated reason, if Status is not successful.\
     * @param {string} [reason]
     * @returns {false}
     */
    failure(reason) {
        if (this.error) {
            const message =
                `Failed with reason: ${reason}` ??
                `Failed with reason: Received HTTP ${this.value}`;
            throw new Error(message, { cause: this.value });
        }

        // No failure, therefore we can proceed as needed.
        return false;
    }

    /**
     *
     * @template {any} [T=any]
     * @param {(value: Number) => T} callback
     * @param {string} [reason]
     * @returns { T }
     */
    failureOrElse(callback, reason) {
        if (this.error) {
            const message =
                `Failed with reason: ${reason}` ??
                `Failed with reason: Received HTTP ${this.value}`;
            throw new Error(message, { cause: this.value });
        }

        if (typeof callback !== 'function') {
            throw new Error('Callback function is required.');
        }

        // If a callback must be executed...
        const result = callback(this.value);
        return result;
    }
}
