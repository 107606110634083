// <!-- API -->
import { ECNBResourceIndexStateMutations } from '@/store/types/cache/mutations';
import omit from 'just-omit';

// <!-- TYPES -->
import { LocationIndexState } from '@/store/types/cache/state';
/** @typedef {import('@/models/v1/locations/Location').LocationResource} LocationResource */

/**
 * @class
 * Resource index state mutations.
 */
export class LocationIndexStateMutations {
    /**
     * Provide access to direct state assignment operations.
     * @param {LocationIndexState} state State instance.
     */
    static set(state) {
        const $set = ECNBResourceIndexStateMutations.set(state);
        return {
            ...omit($set, 'index', 'resource'),
            /**
             * Directly assign provided payload to the existing property.
             * @param {Map<Number, LocationResource> | null} [payload] Optional payload. Clears property cache if null.
             */
            locations: (payload) => $set.index(payload),
            /**
             * Directly assign provided item to the existing property collection.
             * @param {{ id: Number, resource: LocationResource } | null} [item] Optional payload. Drops item if null.
             */
            location: (item) => $set.resource(item),
        };
    }

    /**
     * Provide access to clear state operations.
     * @param {LocationIndexState} state State instance.
     */
    static clear(state) {
        const $clear = ECNBResourceIndexStateMutations.clear(state);
        return {
            ...omit($clear, 'index'),
            /**
             * Clear existing property.
             */
            locations: () => $clear.index(),
        };
    }

    /**
     * Provide access to state add operations.
     * @param {LocationIndexState} state State instance.
     */
    static add(state) {
        const $add = ECNBResourceIndexStateMutations.add(state);
        return {
            ...omit($add, 'index', 'resource'),
            /**
             * Merge provided payload with existing property.
             * @param {Map<Number, LocationResource>} [payload]
             */
            locations: (payload) => $add.index(payload),
            /**
             * Merge provided payload item with existing property collection.
             * @param {LocationResource} [item] Resource.
             */
            location: (item) => $add.resource(item),
        };
    }

    /**
     * Provide access to state drop operations.
     * @param {LocationIndexState} state State instance.
     */
    static drop(state) {
        const $drop = ECNBResourceIndexStateMutations.drop(state);
        return {
            ...omit($drop, 'index', 'resource'),
            /**
             * Seperate provided payload with existing property.
             * @param {Set<Number> | 'all'} [payload]
             */
            locations: (payload) => $drop.index(payload),
            /**
             * Drop provided payload item from existing property collection.
             * @param {Number} id
             */
            location: (id) => $drop.resource(id),
        };
    }
}
