// <!-- API -->
import { AxisRangeFilter } from '@/utils/filters';

// <!-- TYPES -->
// ==== GRAPH FILTERS ====
/** @typedef {import('@/utils/filters').IAxisID} IAxisID */
/** @typedef {import('@/utils/filters').ILimitFilter} ILimitFilter */

/**
 * @class
 * Individual limit filter for the keyed axis.
 * @implements {ILimitFilter}
 */
export class LimitFilter extends AxisRangeFilter {
    /**
     * Create an axis range.
     * @param {Readonly<Partial<ILimitFilter>>} [props]
     * @returns {ILimitFilter}
     */
    static create = (props = {}) => new LimitFilter(props);

    /**
     * Clone an existing axis range.
     * @param {Readonly<ILimitFilter>} [source]
     * @returns {ILimitFilter}
     */
    static clone = (source = new LimitFilter()) => new LimitFilter(source);

    /**
     * Create a temperature limit range.
     * @param {Readonly<Partial<Omit<ILimitFilter, 'key'>>>} [props]
     * @returns {ILimitFilter}
     */
    static temp = (props = {}) => LimitFilter.create({ ...props, key: 'temp' });

    /**
     * Create a relative humidity limit range.
     * @param {Readonly<Partial<Omit<ILimitFilter, 'key'>>>} [props]
     * @returns {ILimitFilter}
     */
    static rh = (props = {}) => LimitFilter.create({ ...props, key: 'rh' });

    /**
     * Create a dew point limit range.
     * @param {Readonly<Partial<Omit<ILimitFilter, 'key'>>>} [props]
     * @returns {ILimitFilter}
     */
    static dp = (props = {}) => LimitFilter.create({ ...props, key: 'dp' });

    /**
     * Create an axis range.
     * @param {Readonly<Partial<ILimitFilter>>} [props]
     */
    constructor(props) {
        super(props);
        /** @type {IAxisID} Filter key. */
        this.key = props.key;
    }
}
