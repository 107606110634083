// <!-- UTILITIES -->

// <!-- TYPES -->

// <!-- EXPORTS -->
/**
 * Get (or create) timezone formatter for the specified timezone option.
 * @param {TimeZone.Identifier} timezone
 * @param {Partial<Intl.DateTimeFormatOptions>} options
 */
export const getOrCreateTimezoneFormatter = (
    timezone,
    options = { timeZoneName: 'short' }
) => {
    //const key = `${timezone}-${options.timeZoneName}`;

    // if (TIMEZONE_FORMATTERS.has(key)) {
    //     // Cache hit!
    //     return TIMEZONE_FORMATTERS.get(key);
    // }

    // Create new formatter.
    const formatter = new Intl.DateTimeFormat('en-ca', {
        ...options,
        timeZone: timezone,
    });

    // Cache the formatter.
    //TIMEZONE_FORMATTERS.set(key, formatter);

    // Return the instance.
    return formatter;
};

/**
 * Use the current date for 'getting' the timezone part.
 * @type {Date}
 */
const TIMEZONE_DATE = Object.freeze(new Date());

/**
 * Get the timezone abbreviation.
 */
export const formatTimeZone = (timezone) => {
    const formatter = getOrCreateTimezoneFormatter(timezone, {
        timeZoneName: 'short',
    });
    const parts = formatter.formatToParts(TIMEZONE_DATE);
    const abbreviation = parts.find((part) => part.type === 'timeZoneName');
    return abbreviation?.value ?? 'UTC';
};

// <!-- DEFAULT -->
export default formatTimeZone;
