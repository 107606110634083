// <!-- EXPORTS -->
export {
    isPublic,
    isAccountRequired,
    isAuthenticationRequired,
    isAuthenticationSkipped,
    isUserAuthenticated,
    isUserAuthorized,
} from '@/router/guards/useAuthRedirects';

// <!-- API -->
import { createRouter, createWebHistory } from 'vue-router';

// <!-- ROUTES -->
import {
    auth,
    info,
    cart,
    admin,
    settings,
    analysis,
    notes,
    locations,
    upload,
    debug,
} from '@router/routes';

// <!-- HELPERS -->
/**
 * Define routes.
 * @returns {Router.RouterOptions['routes']}
 */
const useRoutes = () => {
    return [
        // <!-- HOME -->
        analysis.home,

        // <!-- AUTH -->
        auth.login,
        auth.signup,
        auth['forgotten-password'],
        auth['select-organization'],
        auth['expired-subscription'],
        auth['select-account'],
        auth['password-reset'],

        // <!-- INFO -->
        info.about,
        info.catchAll,

        // <!-- CART -->
        cart['remote-login'],

        // <!-- ADMIN -->
        admin.index,

        // <!-- SETTINGS -->
        settings.users,

        // <!-- ANALYSIS -->
        analysis.index,

        // <!-- NOTE MANAGER -->
        notes.index,

        // <!-- LOCATION MANAGER -->
        locations.index,

        // <!-- UPLOAD MANAGER -->
        upload.index,

        // <!-- DEBUG ROUTES -->
        // TODO: Create conditional for local vs staging vs production inclusion/exclusion.
        debug.debug,
    ];
};

/** Create the router instance. */
export const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: useRoutes(),
});

// <!-- DEFAULTS -->
export default router;
