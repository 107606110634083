import { BaseResource } from './BaseResource';
import { ResourceType } from '@/enums/ResourceType';
import { ModelAttributeFactory } from './ModelAttributeFactory';

/**
 * Model resource instance.
 *
 * @template {{[key: string]: any }} [P = never] Payload data schema.
 * @template {{[key: string]: any }} [M = never] Model data schema.
 * @implements {Resource.Model<P,M>}
 * @extends {BaseResource<'model', M>}
 */
export class ModelResource extends BaseResource {
    // <!-- STATIC UTILITY METHODS -->

    /**
     * Define the enumerable dynamic getters and setters for the instance attributes.
     *
     * @template {ModelResource<any,any>} [R = ModelResource<any,any>] Resource instance.
     * @param {R} resource
     */
    static defineEnumerableAttributes(resource) {
        // Bind the enumerable properties. (Uses dynamic getters/setters).
        const prototype = Object.getPrototypeOf(resource);
        for (const key in resource._factory.initialState) {
            Object.defineProperty(resource, key, {
                ...Object.getOwnPropertyDescriptor(prototype, key),
                enumerable: true,
            });
        }
    }

    // <!-- CONSTRUCTOR -->

    /**
     * Create a model instance.
     *
     * @param {Partial<M>} attributes
     * @param {ModelAttributeFactory<P,M>} factory
     */
    constructor(attributes, factory) {
        super(ResourceType.Model, factory.fromModel(attributes));

        // Bind the factory.
        this._factory = Object.freeze(factory);
        Object.defineProperty(this, '_factory', {
            writable: false,
            enumerable: false,
        });

        // Bind the enumerable attributes (using the current factory).
        ModelResource.defineEnumerableAttributes(this);
    }

    // <!-- PAYLOAD INTERFACE -->

    /** Get shallow copy as a payload instance. */
    toPayload() {
        throw new Error('Mapper to payload resource has not been implemented.');
        return null;
    }

    /** Get shallow copy as a model instance. */
    toModel() {
        return this.clone();
    }

    /**
     * Clone this instance.
     *
     * @returns {this}
     */
    clone() {
        return /** @type {this} */ (
            new ModelResource({ ...this._attributes }, this._factory)
        );
    }
}

// <!-- EXPORTS -->
export default ModelResource;
