// <!-- API -->
import { computedEager } from '@vueuse/core';

// <!-- UTILITIES -->
import { Enum } from '@/utils/enums';
import { capitalize } from 'lodash-es';

/**
 * Create eager computed properties.
 * @template {Record<string,string>} [E=Record<string,string>]
 * @template {string | 'is'} [Prefix='is']
 * @param {Enum<E>} dictionary
 * @param {Vue.Ref<E[keyof E]>} observable
 * @param {Prefix extends string ? Prefix : 'is'} [prefix]
 * @returns {{ [P in keyof E as `${Lowercase<Prefix>}${Capitalize<Lowercase<P>>}`]: Vue.ComputedRef<boolean> }}
 */
export const useEnum = (dictionary, observable, prefix) => {
    // Prepare a plain wrapper object to store the computed properties.
    const context = Object.create(null);

    // Create the property definitions.
    return dictionary.keys.reduce((context, key) => {
        const target = dictionary.get(key);
        const property = `${prefix?.toLowerCase() ?? 'is'}${capitalize(
            key.toLowerCase()
        )}`;
        const computedFn = computedEager(() =>
            dictionary.isValue(target, observable.value)
        );
        return Object.assign(context, {
            [property]: computedFn,
        });
    }, context);
};
