// <!-- API -->
import { ECNBStateMutations } from '@/store/types/ECNBStateMutations';
import isNil from 'lodash-es/isNil';

// <!-- TYPES -->
import { UploadRecord } from '@/store/types/uploader/state';
import {
    UploadRecordStatusIDs,
    UploadRecordFlagIDs,
} from '@/store/types/uploader/state/UploadRecord';
/** @typedef {import('@/models/v1/locations/Location').LocationResource} LocationResource */

/**
 * Synchronous mutations for a {@link UploadRecord} instance.
 */
export class UploadRecordMutations extends ECNBStateMutations {
    /**
     * Access property assignment helpers.
     * @param {UploadRecord} context
     */
    static set(context) {
        return {
            /**
             * Change the record status.
             * @param {keyof UploadRecordStatusIDs} id
             */
            status: (id) => {
                /**
                 * Change {@link id} status.
                 * @param {Boolean} value
                 */
                const $change = (value) => {
                    const updated = new Set(context.status.keys());
                    if (value === true) {
                        console.info(
                            `[status::${id}]: %cENABLED`,
                            `background: seagreen;`
                        );
                        updated.add(id);
                    }
                    if (isNil(value) || value === false) {
                        console.info(
                            `[status::${id}]: %cDISABLED`,
                            `background: maroon;`
                        );
                        updated.delete(id);
                    }
                    context.status = updated;
                };
                return {
                    /** @param {Boolean} value */
                    to: (value) => $change(value),
                    enable: () => $change(true),
                    disable: () => $change(false),
                };
            },
            /**
             * Change the record flag.
             * @param {keyof UploadRecordFlagIDs} id
             */
            flag: (id) => {
                /**
                 * Change {@link id} status.
                 * @param {Boolean} value
                 */
                const $change = (value) => {
                    const updated = new Set(context.flags.keys());
                    if (value === true) {
                        console.info(
                            `[flag::${id}]: %cENABLED`,
                            `background: blue;`
                        );
                        updated.add(id);
                    }
                    if (isNil(value) || value === false) {
                        console.info(
                            `[flag::${id}]: %cDISABLED`,
                            `background: firebrick;`
                        );
                        updated.delete(id);
                    }
                    context.flags = updated;
                };
                return {
                    /** @param {Boolean} value */
                    to: (value) => $change(value),
                    enable: () => $change(true),
                    disable: () => $change(false),
                };
            },
            /**
             * Set the transient or persisted location.
             * @param {File} file
             */
            file: (file) => {
                return {
                    asTransient: () => {
                        context.file.commit(file);
                    },
                    asPersisted: () => {
                        context.file.persist(file);
                    },
                };
            },
            /**
             * Set the transient or persisted location.
             * @param {LocationResource} location
             */
            location: (location) => {
                return {
                    asTransient: () => {
                        context.location.commit(location);
                    },
                    asPersisted: () => {
                        context.location.persist(location);
                    },
                    asSuggestion: () => {
                        context.suggestedLocation = location;
                    },
                };
            },
            /**
             * Set the transient or persisted mapping profile.
             * @param {import('@/models/v1/mappings/MappingProfile').MappingProfileResource} profile
             */
            profile: (profile) => {
                return {
                    asTransient: () => {
                        context.mappingProfile.commit(profile);
                    },
                    asPersisted: () => {
                        context.mappingProfile.persist(profile);
                    },
                    asSuggestion: () => {
                        context.suggestedMappingProfile = profile;
                    },
                };
            },
            /**
             * Set the transient or persisted dataset batch.
             * @param {import('@/models/v1/datasets/DatasetBatch').DatasetBatchResource} batch
             */
            batch: (batch) => {
                return {
                    asTransient: () => {
                        context.batch.commit(batch);
                    },
                    asPersisted: () => {
                        context.batch.persist(batch);
                    },
                };
            },
            /**
             * Set the contents.
             * @param {Array<{ line: Number, data: String[] }>} contents
             */
            contents: (contents = []) => {
                context.contents = [...contents];
            },
            /**
             * Set the contents.
             * @param {String} type
             */
            type: (type) => {
                context.type = type;
            },
        };
    }
    /**
     * Access adders.
     * @param {UploadRecord} context
     */
    static add(context) {
        const $ = UploadRecordMutations;
        const $set = $.set(context);
        return {
            /**
             * Add specified property.
             * @param {keyof UploadRecordStatusIDs} id
             */
            status: (id) => $set.status(id).enable(),
            /**
             * Add specified property.
             * @param {keyof UploadRecordFlagIDs} id
             */
            flag: (id) => $set.flag(id).enable(),
        };
    }
    /**
     * Access droppers.
     * @param {UploadRecord} context
     */
    static drop(context) {
        const $ = UploadRecordMutations;
        const $set = $.set(context);
        return {
            /**
             * Drop all state properties.
             */
            state: () => {
                const $drop = $.drop(context);
                $drop.status.all();
                $drop.flag.all();
                $drop.file.property();
                $drop.location.property();
                $drop.location.suggestion();
                $drop.profile.property();
                $drop.profile.suggestion();
                $drop.batch.property();
                $drop.contents();
            },
            /**
             * Drop property.
             */
            get status() {
                return {
                    /**
                     * Drop all statuses.
                     */
                    all: () => context.status.clear(),
                    /**
                     * Drop specified status.
                     * @param {keyof UploadRecordStatusIDs} id
                     */
                    byID: (id) => $set.status(id).disable(),
                };
            },
            /**
             * Drop property.
             */
            get flag() {
                return {
                    /**
                     * Drop all statuses.
                     */
                    all: () => context.status.clear(),
                    /**
                     * Drop specified status.
                     * @param {keyof UploadRecordFlagIDs} id
                     */
                    byID: (id) => $set.flag(id).disable(),
                };
            },
            /**
             * Clear property.
             */
            get file() {
                return {
                    /**
                     * Drop serialized property.
                     */
                    property: () => context.file.clear(),
                };
            },
            /**
             * Clear property.
             */
            get location() {
                return {
                    /**
                     * Drop serialized property.
                     */
                    property: () => context.location.clear(),
                    /**
                     * Drop specified property.
                     */
                    suggestion: () => (context.suggestedLocation = null),
                };
            },
            /**
             * Clear property.
             */
            get profile() {
                return {
                    /**
                     * Drop serialized property.
                     */
                    property: () => context.mappingProfile.clear(),
                    /**
                     * Drop specified property.
                     */
                    suggestion: () => (context.suggestedMappingProfile = null),
                };
            },
            /**
             * Clear property.
             */
            get batch() {
                return {
                    /**
                     * Drop serialized property.
                     */
                    property: () => context.batch.clear(),
                };
            },
            /**
             * Clear contents.
             */
            contents() {
                context.contents = [];
            },
        };
    }
    /**
     * Access specialized status and flag mutations.
     * @param {UploadRecord} context
     */
    static change(context) {
        const $ = UploadRecordMutations;
        return {
            get flags() {
                /**
                 * Get flag mutations.
                 * @param {keyof UploadRecordFlagIDs} key
                 */
                const getMutationsForFlag = (key) => {
                    return {
                        /**
                         * Set the flag.
                         * @param {Boolean} value
                         */
                        set: (value) => $.set(context).flag(key).to(value),
                        /**
                         * Activate flag.
                         */
                        enable: () => $.add(context).flag(key),
                        /**
                         * Deactivate flag.
                         */
                        disable: () => $.drop(context).flag.byID(key),
                    };
                };
                return {
                    /**
                     * Mutate the specified flag.
                     */
                    get marked() {
                        return getMutationsForFlag('checked');
                    },
                    /**
                     * Mutate the specified flag.
                     */
                    get ingested() {
                        return getMutationsForFlag('dataset_ingested');
                    },
                };
            },
            get status() {
                /**
                 * Get status mutations.
                 * @param {keyof UploadRecordStatusIDs} key
                 */
                const getMutationsForStatus = (key) => {
                    return {
                        /**
                         * Set the status.
                         * @param {Boolean} value
                         */
                        set: (value) => $.set(context).status(key).to(value),
                        /**
                         * Activate status.
                         */
                        enable: () => $.add(context).status(key),
                        /**
                         * Deactivate status.
                         */
                        disable: () => $.drop(context).status.byID(key),
                    };
                };
                return {
                    /**
                     * Mutate the specified status.
                     */
                    get uploading() {
                        return getMutationsForStatus('uploading_dataset');
                    },
                    /**
                     * Mutate the specified status.
                     */
                    get viewing() {
                        return getMutationsForStatus('viewing_mapping_profile');
                    },
                    /**
                     * Mutate the specified status.
                     */
                    get editing() {
                        return getMutationsForStatus('editing_mapping_profile');
                    },
                    /**
                     * Mutate the specified status.
                     */
                    get previewing() {
                        return getMutationsForStatus('refreshing_preview');
                    },
                    /**
                     * Mutate the specified status.
                     */
                    get creating() {
                        return getMutationsForStatus(
                            'creating_mapping_profile'
                        );
                    },
                    /**
                     * Mutate the specified status.
                     */
                    get applying() {
                        return getMutationsForStatus(
                            'applying_mapping_profile'
                        );
                    },
                    /**
                     * Mutate the specified status.
                     */
                    get ingesting() {
                        return getMutationsForStatus('ingesting_dataset');
                    },
                };
            },
        };
    }
    /**
     * Select transient version of record property.
     * @param {UploadRecord} context
     */
    static select(context) {
        const $set = UploadRecordMutations.set(context);
        return {
            /**
             * Select transient version of specified property.
             * @param {File} value
             */
            file: (value) => {
                $set.file(value).asTransient();
            },

            /**
             * Select transient version of specified property.
             * @param {LocationResource} value
             */
            location: (value) => {
                $set.location(value).asTransient();
            },

            /**
             * Select transient version of specified property.
             * @param {import('@/models/v1/mappings/MappingProfile').MappingProfileResource} value
             */
            profile: (value) => {
                $set.profile(value).asTransient();
            },

            /**
             * Select transient version of specified property.
             * @param {import('@/models/v1/datasets/DatasetBatch').DatasetBatchResource} value
             */
            batch: (value) => {
                $set.batch(value).asTransient();
            },
        };
    }
    /**
     * Assign suggested version of record property.
     * @param {UploadRecord} context
     */
    static suggest(context) {
        return {
            /**
             * Select suggested version of specified property.
             * @param {LocationResource} value
             */
            location: (value) => {
                context.suggestedLocation = value;
            },
            /**
             * Select suggested version of specified property.
             * @param {import('@/models/v1/mappings/MappingProfile').MappingProfileResource} value
             */
            profile: (value) => {
                context.suggestedMappingProfile = value;
            },
        };
    }
    /**
     * Store uploaded version of file, location, and dataset batch.
     * @param {UploadRecord} context
     */
    static upload(context) {
        const $set = UploadRecordMutations.set(context);
        const $assign = UploadRecordMutations.assign(context);
        return {
            /**
             * Store uploaded batch, file, and location.
             * @param {import('@/models/v1/datasets/DatasetBatch').DatasetBatchResource} batch
             * @param {Array<{ line: Number, data: String[] }>} contents
             * @param {File} file
             * @param {LocationResource} location
             * @param {String} type
             */
            store: (batch, contents, file, location, type) => {
                $set.batch(batch).asTransient();
                $set.contents(contents);
                $set.type(type);
                $assign.file(file);
                $assign.location(location);
            },
        };
    }
    /**
     * Assign persisted version of record property.
     * @param {UploadRecord} context
     */
    static assign(context) {
        const $set = UploadRecordMutations.set(context);
        return {
            /**
             * Assign persisted version of specified property.
             * @param {File} value
             */
            file: (value) => {
                $set.file(value).asPersisted();
            },
            /**
             * Assign persisted version of specified property.
             * @param {LocationResource} value
             */
            location: (value) => {
                $set.location(value).asPersisted();
            },
        };
    }
    /**
     * Assign persisted version of record property.
     * @param {UploadRecord} context
     */
    static apply(context) {
        const $set = UploadRecordMutations.set(context);
        return {
            /**
             * Assign persisted version of specified property.
             * @param {import('@/models/v1/mappings/MappingProfile').MappingProfileResource} value
             */
            profile: (value) => {
                $set.profile(value).asPersisted();
            },
        };
    }
    /**
     * Store ingested version of dataset batch.
     * @param {UploadRecord} context
     */
    static ingest(context) {
        const $set = UploadRecordMutations.set(context);
        const $apply = UploadRecordMutations.apply(context);
        return {
            /**
             * Store ingested dataset batch.
             * @param {import('@/models/v1/datasets/DatasetBatch').DatasetBatchResource} batch
             * @param {import('@/models/v1/mappings/MappingProfile').MappingProfileResource} [profile]
             */
            store: (batch, profile) => {
                $set.batch(batch).asTransient();
                $set.batch(batch).asPersisted();
                $set.profile(profile).asTransient();
                $set.profile(profile).asPersisted();
                $apply.profile(profile);
            },
        };
    }
}
