/**
 * Type guard. Allows type inference for the tested variable.
 * @param {any} value The value to test.
 * @returns {value is string | number | boolean | bigint | symbol | undefined | null} `true` if input argument is of a primitve type.
 */
export const isPrimitive = (value) =>
    value === undefined || value === null || typeof value !== 'object';

// <!-- DEFAULT -->
export default isPrimitive;
