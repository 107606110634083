// <!-- API -->
import { AxisRange, AxisModifier } from '@/utils/filters/axis';

// <!-- UTILITIES -->
import pick from 'just-pick';

// <!-- TYPES -->
// ==== GRAPH FILTERS ====
/** @typedef {import('@/utils/filters').AxisExtent} AxisExtent Graph limit or scale extent. */
/** @typedef {import('@/utils/filters').IAxisModifier} IAxisModifier Record containing the checked modifier state for a limit or scale. */
/** @typedef {import('@/utils/filters').IAxisRangeFilter} IAxisRangeFilter Record containing the range and modifier state */

/**
 * @class
 * Record containing the inclusive lower and upper axis range bounds.
 * @implements {IAxisRangeFilter}
 */
export class AxisRangeFilter {
    /**
     * Create an axis range.
     * @param {Readonly<Partial<IAxisRangeFilter>>} [props]
     * @returns {IAxisRangeFilter}
     */
    static create = (props = {}) => new AxisRangeFilter(props);

    /**
     * Clone an existing axis range.
     * @param {Readonly<IAxisRangeFilter>} [source]
     * @returns {IAxisRangeFilter}
     */
    static clone = (source = new AxisRangeFilter()) =>
        new AxisRangeFilter(source);

    /**
     * Extract only the data members from an existing instance.
     * @param {Readonly<IAxisRangeFilter>} [target]
     * @returns {IAxisRangeFilter}
     */
    static data = (target) => pick(target, 'lower', 'upper', 'checked');

    /**
     * Access filter parsers.
     */
    static get parse() {
        return {
            /**
             * Parse input string as a numeric lower axis value.
             * @param {String | Number | '' | null} [value] Value to parse as minimum.
             * @param {Readonly<Partial<{ whenUndefined: Number, whenInvalid: Number, whenBelowMinimum: Number, whenAboveMaximum: Number }>>} [defaults] Default values to use.
             */
            lower: AxisRange.parse.lower,
            /**
             * Parse input string as a numeric upper axis value.
             * @param {String | Number | '' | null} [value] Value to parse as minimum.
             * @param {Readonly<Partial<{ whenUndefined: Number, whenInvalid: Number, whenBelowMinimum: Number, whenAboveMaximum: Number }>>} [defaults] Default values to use.
             */
            upper: AxisRange.parse.upper,
            /**
             * Parse input string as a boolean checkbox value.
             * @param {String | 0 | 1 | Boolean | null} [checked] Value to parse as minimum.
             * @param {Readonly<Partial<{ whenUndefined: Boolean, whenInvalid: Boolean }>>} [defaults] Default values to use.
             */
            checked: (checked = undefined, defaults = {}) =>
                AxisModifier.parse(checked, defaults),
        };
    }

    /**
     * Access filter validators.
     */
    static get validate() {
        return {
            ...AxisRange.validate,
        };
    }

    /**
     * Create an axis range.
     * @param {Readonly<Partial<IAxisRangeFilter>>} [props]
     */
    constructor(props) {
        // Get the extents.
        const range = AxisRange.create(props);

        // Get the modifier.
        const modifier = AxisModifier.create(props);

        /** @type {AxisExtent} Minimum axis extent. */
        this.lower = range.lower;

        /** @type {AxisExtent} Maximum axis extent. */
        this.upper = range.upper;

        /** @type {IAxisModifier['checked']} Modifier enabled state. */
        this.checked = modifier.checked;
    }
}
