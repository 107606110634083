/**
 * @class
 * User state.
 */
export class UserState {
    /**
     * Construct initial user state.
     */
    constructor() {
        /** @type {User.Model} */
        this.me = null;
    }
    /** Does the user state have a valid state assigned? */
    get hasUser() {
        return !!this.me && !!this.me.id;
    }
}
