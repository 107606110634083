import {
    useFontVariant,
    useBackgroundVariant,
    useBorderVariant,
    useOutlineVariant,
} from './useButtonStyles';

/**
 * useButtonThemes.js
 *
 * This composable gives access to specific theme presets, as defined below.
 */

// export const example = [
//   // Default classes.
//   ...defaultClasses,

//   // Font variants.
//   ...useFontVariant({ variant: "base", color: "", weight: "" }),
//   ...useFontVariant({ variant: "hover", color: "", weight: "" }),
//   ...useFontVariant({ variant: "focus", color: "", weight: "" }),
//   ...useFontVariant({ variant: "disabled", color: "", weight: "" }),

//   // Background variants.
//   ...useBackgroundVariant({ variant: "base", color: "" }),
//   ...useBackgroundVariant({ variant: "hover", color: "" }),
//   ...useBackgroundVariant({ variant: "focus", color: "" }),
//   ...useBackgroundVariant({ variant: "disabled", color: "" }),

//   // Border variants.
//   ...useBorderVariant({ variant: "base", color: "", pattern: "" }),
//   ...useBorderVariant({ variant: "hover", color: "", pattern: "" }),
//   ...useBorderVariant({ variant: "focus", color: "", pattern: "" }),
//   ...useBorderVariant({ variant: "disabled", color: "", pattern: "" }),

//   // Outline variants.
//   ...useOutlineVariant({ variant: "base", color: "", pattern: "" }),
//   ...useOutlineVariant({ variant: "hover", color: "", pattern: "" }),
//   ...useOutlineVariant({ variant: "focus", color: "", pattern: "" }),
//   ...useOutlineVariant({ variant: "disabled", color: "", pattern: "" }),
// ];

/* ****
    Note: These base button classes are based off of the Tailwind UI
    classes mentioned here: https://tailwindui.com/components/application-ui/elements/buttons
*/

/**
 * Default classes for all buttons.
 */
export const defaultLayouts = ['inline-flex', 'items-center', 'opacity-100'];

/**
 * Default style (and size) CSS for all buttons.
 */
export const defaultStyles = [
    'border-2',
    'hover:cursor-pointer',
    'focus:ring-2',
    'focus:ring-offset-2',
    'disabled:opacity-40',
    'disabled:cursor-not-allowed',
];

/**
 * Default array of Tailwind CSS styles.
 */
const defaultClasses = [...defaultLayouts, ...defaultStyles];

/**
 * Primary button theme preset.
 */
export const primary = [
    // Default classes.
    ...defaultClasses,

    // Font variants.
    ...useFontVariant({ variant: 'base', color: 'white' }),

    // Background variants.
    ...useBackgroundVariant({ variant: 'base', color: 'primary-600' }),
    ...useBackgroundVariant({ variant: 'hover', color: 'primary-700' }),
    ...useBackgroundVariant({ variant: 'focus', color: 'primary-500' }),
    ...useBackgroundVariant({ variant: 'disabled', color: 'primary-200' }),

    // Border variants.
    ...useBorderVariant({ variant: 'base', color: 'transparent' }),

    // Outline variants.
    ...useOutlineVariant({ variant: 'focus', color: 'primary-500' }),
];

/**
 * Secondary button theme preset.
 */
export const secondary = [
    // Default classes.
    ...defaultClasses,

    // Font variants.
    ...useFontVariant({ variant: 'base', color: 'black' }),

    // Background variants.
    ...useBackgroundVariant({ variant: 'base', color: 'secondary-400' }),
    ...useBackgroundVariant({ variant: 'hover', color: 'secondary-500' }),
    ...useBackgroundVariant({ variant: 'focus', color: 'secondary-300' }),
    ...useBackgroundVariant({ variant: 'disabled', color: 'secondary-700' }),

    // Border variants.
    ...useBorderVariant({ variant: 'base', color: 'transparent' }),

    // Outline variants.
    ...useOutlineVariant({ variant: 'focus', color: 'primary-500' }),
];

/**
 * White button theme preset.
 */
export const white = [
    // Default classes.
    ...defaultClasses,

    // Font variants.
    ...useFontVariant({ variant: 'base', color: 'gray-700' }),
    ...useFontVariant({ variant: 'disabled', color: 'gray-600' }),

    // Background variants.
    ...useBackgroundVariant({ variant: 'base', color: 'white' }),
    ...useBackgroundVariant({ variant: 'hover', color: 'gray-50' }),
    ...useBackgroundVariant({ variant: 'focus', color: 'gray-100' }),
    ...useBackgroundVariant({ variant: 'disabled', color: 'gray-200' }),

    // Border variants.
    ...useBorderVariant({ variant: 'base', color: 'gray-300' }),

    // Outline variants.
    ...useOutlineVariant({ variant: 'focus', color: 'primary-500' }),
];

/**
 * Danger button theme preset.
 */
export const danger = [
    // Default classes.
    ...defaultClasses,

    // Font variants.
    ...useFontVariant({ variant: 'base', color: 'white' }),
    ...useFontVariant({ variant: 'disabled', color: 'gray-100' }),

    // Background variants.
    ...useBackgroundVariant({ variant: 'base', color: 'red-600' }),
    ...useBackgroundVariant({ variant: 'hover', color: 'red-700' }),
    ...useBackgroundVariant({ variant: 'focus', color: 'red-500' }),
    ...useBackgroundVariant({ variant: 'disabled', color: 'red-200' }),

    // Border variants.
    ...useBorderVariant({ variant: 'base', color: 'gray-300' }),

    // Outline variants.
    ...useOutlineVariant({ variant: 'focus', color: 'primary-500' }),
];

/**
 * Reports button theme preset.
 */
 export const reports = [
    // Default classes.
    ...defaultClasses,

    // Font variants.
    ...useFontVariant({ variant: 'base', color: 'black' }),

    // Background variants.
    ...useBackgroundVariant({ variant: 'base', color: 'gray-400' }),
    ...useBackgroundVariant({ variant: 'hover', color: 'gray-500' }),
    ...useBackgroundVariant({ variant: 'focus', color: 'gray-300' }),
    ...useBackgroundVariant({ variant: 'disabled', color: 'gray-700' }),

    // Border variants.
    ...useBorderVariant({ variant: 'base', color: 'transparent' }),

    // Outline variants.
    ...useOutlineVariant({ variant: 'focus', color: 'gray-500' }),
];