// <!-- API -->
import session from '@/api/v2/session';
import profile from '@/api/v2/profile';
import AccountStateMutations from '@/store/types/accounts/mutations';
import { Context } from '@/utils/session/Context';
import { Maybe } from 'true-myth/dist/maybe';

// <!-- TYPES -->
/** @template [S=any] @template [R=any] @typedef {import('vuex').Module<S,R>} Module<S,R> */
/** @template [S=any] @template [R=any] @typedef {import('vuex').ActionContext<S,R>} ActionContext<S,R> */
import { AccountState } from '@/store/types/accounts/state';
import { ECNBState } from '@/store/types/ECNBStore';
import { ECNBModule } from '@/store/types/ECNBModule';

// CLASS
/**
 * @class
 * Accounts module.
 */
export class AccountModule extends ECNBModule {
    /**
     * Name of the module.
     */
    static get namespace() {
        return 'accounts';
    }

    /**
     * Module state, getters, mutations, and actions.
     * @type {import('vuex').Module<AccountState, ECNBState>}
     */
    static get module() {
        return {
            namespaced: false,
            state: () => new AccountState(),
            get getters() {
                /** @param {AccountState} state */
                const hasSelectedOrganization = (state) => {
                    return state.hasOrganization;
                };
                /** @param {AccountState} state */
                const hasSelectedAccount = (state) => {
                    return state.hasAccount;
                };
                /** @param {AccountState} state */
                const AccountID = (state) => {
                    return state.account.id;
                };
                /** @param {AccountState} state */
                const AccountTreeLevel = (state) => {
                    const labels = state.account.treeLabels;
                    return {
                        labels,
                        site: labels[0],
                        building: labels[1],
                        floor: labels[2],
                        room: labels[3],
                    };
                };
                /** @param {AccountState} state */
                const AccountTimezone = (state) => {
                    return state.account.timezone;
                };
                return {
                    hasSelectedOrganization,
                    hasSelectedAccount,
                    AccountID,
                    AccountTreeLevel,
                    AccountTimezone,
                };
            },
            get mutations() {
                const $ = AccountStateMutations;
                /**
                 * Set the current account.
                 * @param {AccountState} state
                 * @param {Organization.Model} payload
                 */
                const setCurrentOrganization = (state, payload) => {
                    Context.organization = Maybe.of(payload?.id);
                    const key = String(payload?.id);
                    $.set(state).organization(payload);
                };
                /**
                 * Set the current account.
                 * @param {AccountState} state
                 * @param {Account.Model} payload
                 */
                const setCurrentAccount = (state, payload) => {
                    Context.account = Maybe.of(payload.id);
                    const key = String(payload.id);
                    $.set(state).account(payload);
                };
                /**
                 * Update the current organization.
                 * @param {AccountState} state
                 * @param {Organization.Model} payload
                 */
                const patchCurrentOrganization = (state, payload) => {
                    $.patch(state).organization(payload);
                };
                /**
                 * Update the current account.
                 * @param {AccountState} state
                 * @param {Account.Model} payload
                 */
                const patchCurrentAccount = (state, payload) => {
                    $.patch(state).account(payload);
                };
                /**
                 * Update the current organization.
                 * @param {AccountState} state
                 */
                const clearCurrentOrganization = (state) => {
                    Context.organization = Maybe.nothing();
                    $.clear(state).organization();
                };
                /**
                 * Update the current account.
                 * @param {AccountState} state
                 */
                const clearCurrentAccount = (state) => {
                    Context.account = Maybe.nothing();
                    $.clear(state).account();
                };
                return {
                    setCurrentOrganization,
                    setCurrentAccount,
                    patchCurrentOrganization,
                    patchCurrentAccount,
                    clearCurrentOrganization,
                    clearCurrentAccount,
                };
            },
            get actions() {
                /**
                 * Fetch the selected organization details.
                 * @param {import('vuex').ActionContext<AccountState, ECNBState>} context
                 * @param {Object} payload
                 * @param {Number} [payload.id] If `null`, use the session's selected organization. Otherwise, assign organization after fetching.
                 */
                const fetchSelectedOrganization = async (
                    { commit, rootState },
                    { id }
                ) => {
                    // Get the current account.
                    const currentOrganization =
                        await session.fetchSessionOrganization();

                    // Early exit, if admin.
                    if (currentOrganization.isOk) {
                        const organization = currentOrganization.value;
                        const role = organization?.access?.userRole;
                        const isAdmin = role === 'admin';
                        if (isAdmin) {
                            commit('setCurrentOrganization', organization);
                            return Maybe.of(organization);
                        }
                    }

                    // If not admin, verify the user belongs to the organization.
                    const userOrganizations =
                        await profile.fetchUserOrganizations();

                    // Warn and exit if no organizations or missing.
                    if (userOrganizations.isErr) {
                        console.warn(userOrganizations.error);
                        return Maybe.nothing();
                    }

                    if (userOrganizations.isOk) {
                        const organizations = userOrganizations.value ?? [];
                        const selected = Maybe.of(
                            organizations.find((o) => o.id === id)
                        );
                        if (selected.isJust) {
                            // If selected organization exists, commit it.
                            commit('setCurrentOrganization', selected.value);
                            return Maybe.of(selected);
                        } else {
                            console.warn(
                                'User is not assigned to this organization.'
                            );
                            return Maybe.nothing();
                        }
                    }

                    // Return the `Maybe`.
                    return currentOrganization;
                };
                /**
                 * Fetch the selected account details.
                 * @param {import('vuex').ActionContext<AccountState, ECNBState>} context
                 * @param {Object} payload
                 * @param {Number} [payload.id] If `null`, use the session's selected account. Otherwise, assign account after fetching.
                 */
                const fetchSelectedAccount = async (
                    { commit, rootState },
                    { id }
                ) => {
                    // Get user details.
                    const organization = rootState.accounts.organization;
                    const role = organization?.access?.userRole;
                    const isAdmin = role === 'admin';

                    // Get the current account.
                    const currentAccount = await session.fetchSessionAccount();

                    // Early exit, if admin.
                    if (currentAccount.isOk && isAdmin) {
                        const selectedAccount = currentAccount.value;
                        commit('setCurrentAccount', selectedAccount);
                        return currentAccount;
                    }

                    if (currentAccount.isOk) {
                        // If not admin, verify the user belongs to the account.
                        const userAccounts = await profile.fetchUserAccounts();

                        // Get the matching account, if it assigned to the user.
                        const selectedAccount = userAccounts
                            .unwrapOr(/** @type {Account.Model[]} */ ([]))
                            .find((account) => account.id === id);

                        // Warn and exit if no accounts or missing.
                        if (userAccounts.isErr) {
                            console.warn(userAccounts.error);
                            return null;
                        }

                        if (!selectedAccount) {
                            console.warn(
                                'User is not assigned to this account.'
                            );
                            return null;
                        }

                        // If selected account exists, commit it.
                        commit('setCurrentAccount', selectedAccount);
                    }

                    // Return the `Maybe`.
                    return currentAccount;
                };
                return {
                    fetchSelectedOrganization,
                    fetchSelectedAccount,
                };
            },
        };
    }
}

// DEFAULT
export default AccountModule;
