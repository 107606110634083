// <!-- API -->
import { CacheState } from '@/store/types/cache/state';

// ts-ignore
import { ECNBStore, ECNBState } from '@/store/types/ECNBStore';
import { ECNBModule } from '@/store/types/ECNBModule';
import { LocationCacheModule } from '@/store/types/cache/module/LocationCacheModule';
import { LocationHierarchyCacheModule } from '@/store/types/cache/module/LocationHierarchyCacheModule';
import { MappingProfileCacheModule } from '@/store/types/cache/module/MappingProfileCacheModule';
import { NoteCacheModule } from '@/store/types/cache/module/NoteCacheModule';
import { WeatherStationCacheModule } from '@/store/types/cache/module/WeatherStationCacheModule';
import { UserCacheModule } from '@/store/types/cache/module/UserCacheModule';
import { AccountCacheModule } from '@/store/types/cache/module/AccountCacheModule';
import { NARAStandardCacheModule } from '@/store/types/cache/module/NARAStandardCacheModule';
import CacheStateMutations from '@/store/types/cache/mutations';

// CLASS
/**
 * @class
 * Cache module.
 */
export class CacheModule extends ECNBModule {
    /**
     * Name of the module.
     */
    static get namespace() {
        return 'cache';
    }

    /**
     * Module state, getters, mutations, and actions.
     * @type {import('vuex').Module<CacheState, ECNBState>}
     */
    static get module() {
        // EXPOSE
        return {
            namespaced: true,
            state: () => new CacheState(),
            get modules() {
                const modules = Object.fromEntries([
                    [
                        LocationCacheModule.namespace.split('/')[1],
                        LocationCacheModule.module,
                    ],
                    [
                        LocationHierarchyCacheModule.namespace.split('/')[1],
                        LocationHierarchyCacheModule.module,
                    ],
                    [
                        NoteCacheModule.namespace.split('/')[1],
                        NoteCacheModule.module,
                    ],
                    [
                        MappingProfileCacheModule.namespace.split('/')[1],
                        MappingProfileCacheModule.module,
                    ],
                    [
                        WeatherStationCacheModule.namespace.split('/')[1],
                        WeatherStationCacheModule.module,
                    ],
                    [
                        UserCacheModule.namespace.split('/')[1],
                        UserCacheModule.module,
                    ],
                    [
                        AccountCacheModule.namespace.split('/')[1],
                        AccountCacheModule.module,
                    ],
                    [
                        NARAStandardCacheModule.namespace.split('/')[1],
                        NARAStandardCacheModule.module,
                    ],
                ]);
                return {
                    ...modules,
                };
            },
            get getters() {
                /** @type {import('vuex').Getter<CacheState, ECNBState>} */
                // ts-ignore
                const findIndex = (state, getters, rootState, rootGetters) => {
                    /** @param {String} group Cache submodule key. */
                    return (group) => {
                        if (state.loaded.has(group)) {
                            return rootState.cache[group].index;
                        } else {
                            return null;
                        }
                    };
                };
                /** @type {import('vuex').Getter<CacheState, ECNBState>} */
                const findResource = (
                    state,
                    // ts-ignore
                    getters,
                    rootState,
                    // ts-ignore
                    rootGetters
                ) => {
                    /**
                     * @param {String} group Cache submodule key.
                     * @param {any} id Resource id.
                     * @param {any} [defaultValue]
                     */
                    return (group, id, defaultValue = null) => {
                        if (
                            state.loaded.has(group) &&
                            // ts-ignore
                            rootState.cache[group].has.resource(id)
                        ) {
                            // ts-ignore
                            return (
                                rootState.cache[group].select(id) ??
                                defaultValue
                            );
                        } else {
                            return null;
                        }
                    };
                };
                return {
                    findIndex,
                    findResource,
                };
            },
            get mutations() {
                const $ = CacheStateMutations;
                /**
                 * Set the initialized status.
                 * @param {CacheState} state
                 * @param {Boolean} [payload]
                 */
                const setInitialized = (state, payload = false) => {
                    $.set.status(state, 'initialized').to(payload);
                };
                return {
                    setInitialized,
                };
            },
            get actions() {
                return {};
            },
        };
    }
}

// DEFAULT
export default CacheModule;
