// <!-- TYPES -->
import { Model } from '@/models/v1/resource/Model';
import { DynamicEnumFactory } from '@/utils/DynamicEnum';
/** @typedef {ReturnType<MappingRule['toPayload']>} MappingRulePayload */
/** @typedef {ReturnType<MappingRule['toResource']>} MappingRuleResource */

/** Model attribute names. */
const FIELDS = DynamicEnumFactory().fromKeys(['index', 'format', 'target']);

/** Mapping Rule targets. */
const TARGETS = DynamicEnumFactory().fromKeys([
    'rh',
    'temp',
    'dp',
    'date',
    'time',
]);

/**
 * @class
 */
export class MappingRule extends Model {
    _initialState() {
        return Model.ComposeStateUsingFields(FIELDS);
    }

    /**
     * @param {MappingRulePayload} payload
     * @returns {this}
     */
    parsePayload(payload) {
        /** @type {(payload: MappingRulePayload) => [string, import('@/models/v1/resource/Model').AttributeType][]} */
        const transformPayload = (payload) => {
            /** @type {keyof TARGETS} */
            const target = /** @type {any} */ (Object.keys(payload)[0]);
            const attributes = payload[target];
            const entity = {
                ...attributes,
                target: target,
            };
            return Object.entries(entity);
        };
        super.parsePayload(payload, transformPayload);
        return this;
    }

    toPayload() {
        /** @type {keyof TARGETS} */
        const target = this.get(FIELDS.target);

        return {
            [target]: {
                /** @type {Number} */
                [FIELDS.index]: this.get(FIELDS.index),
                /** @type {String} */
                [FIELDS.format]: this.get(FIELDS.format),
                /** @type {keyof TARGETS} */
                [FIELDS.target]: target,
            },
        };
    }

    toResource() {
        /** @type {keyof TARGETS} */
        const target = this.get(FIELDS.target);

        return {
            /** @type {Number} */
            [FIELDS.index]: this.get(FIELDS.index),
            /** @type {String} */
            [FIELDS.format]: this.get(FIELDS.format),
            /** @type {keyof TARGETS} */
            [FIELDS.target]: target,
        };
    }
}
