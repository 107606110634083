import {
    useFontVariant,
    useBackgroundVariant,
    useBorderVariant,
    useOutlineVariant,
    useMarginsVariant,
    usePaddingsVariant,
    useHeightVariant,
    useWidthVariant,
    useDimensionsVariant,
} from './useButtonStyles';

import {
    defaultLayouts,
    defaultStyles,
    primary,
    secondary,
    white,
    danger,
} from './useButtonThemes';

import { defaultSizes, xs, sm, md, lg, xl } from './useButtonSizes';

import { defaultModifiers } from './useButtonModifiers';

/**
 * useButtonPresets.js
 *
 * This composable contains Tailwind CSS arrays that can be applied
 * (or modified) to provide a collection of preset styles to a
 * specific button element.
 *
 * ## How to Use a Preset
 *
 * A preset must be a String[] containing the individual Tailwind CSS
 * classes. Vue uses this array when binding to a component. Using an
 * array over a flat String is strongly encouraged for readability and
 * reuse.
 *
 * You can use a preset by importing this composable and selecting
 * the preset arrays you wish to use. The final style can be applied
 * by combining the arrays (typically with the spread operator).
 *
 * // ... In <script></script>
 * import useButtonPresets from '@/components/buttons/hooks/useButtonPresets';
 *
 * // ... In setup()
 * const { primary, lg } = useButtonPresets();
 * const button = {
 *  theme: primary,
 *  size: lg,
 *  modifiers: [ // custom classes here ],
 * }
 * return { button }
 *
 * // ... In <template></template>
 * <ExampleButton v-bind="button" />
 *
 * ## How to Create a Preset
 *
 * Creating a new preset is straightforward. Just remember *ALL PRESETS ARE AN ARRAY OF TAILWIND CSS CLASSES*.
 *
 * When in doubt, just use the array. The simplest preset is a set of String Tailwind CSS classes.
 *
 * It is strongly encouraged to declare layout presets
 * (eg., spacing, padding, size) in a separate array from visual styles (eg., font, background)
 * so that you can later compose the final style without needing to overwrite anything.
 *
 * theme = [ 'text-primary-400', 'bg-primary-400' ]
 * size = [ 'px-4', 'mb-4' ]
 *
 * You can use the utility functions in useButtonStyles.js in order to create these faster.
 *
 * ## Overriding Preset Styles
 *
 * CSS classes are resolved in the order they are presented. The last element in an array will
 * take precendence over previous declarations. When importing your composable presets, just
 * append any overriding styles to the end of the preset array.
 */

/** Provides access to named properties. */
export default function () {
    return {
        // Default Tailwind CSS styles for all buttons.
        defaultModifiers,
        defaultLayouts,
        defaultStyles,
        defaultSizes,

        // Theme presets for Tailwind CSS classes.
        primary,
        secondary,
        white,
        danger,

        // Size presets for Tailwind CSS classes.
        xs,
        sm,
        md,
        lg,
        xl,

        // Utility functions.
        useFontVariant,
        useBackgroundVariant,
        useBorderVariant,
        useOutlineVariant,
        useMarginsVariant,
        usePaddingsVariant,
        useHeightVariant,
        useWidthVariant,
        useDimensionsVariant,
    };
}
