// <!-- SYNCHRONOUS COMPONENTS -->
import Home from '@/features/analysis/views/index.vue';

// <!-- DEFINE -->
/** @typedef {Record<String, Readonly<Router.RouteRecordRaw>>} */
const children = {
    graph: {
        path: '',
        name: 'Analysis',
        component: () =>
            import(
                /* webpackPrefetch: true */ /* webpackChunkName: "analysis" */ '@/features/analysis/views/Analysis.vue'
            ),
        meta: { namespace: 'analysis' },
    },
    statistics: {
        path: '/analysis/statistics',
        name: 'Statistics',
        component: () =>
            import(
                /* webpackPrefetch: true */ /* webpackChunkName: "analysis" */ '@/features/analysis/views/Statistics.vue'
            ),
        meta: { namespace: 'analysis' },
    },
    compare: {
        path: '/analysis/compare',
        name: 'Compare Metrics',
        component: () =>
            import(
                /* webpackPrefetch: true */ /* webpackChunkName: "analysis" */ '@/features/analysis/views/Compare.vue'
            ),
        meta: { namespace: 'analysis' },
    },
    narastandards: {
        path: '/analysis/narastandards',
        name: 'NARA Standards',
        component: () =>
            import(
                /* webpackPrefetch: true */ /* webpackChunkName: "analysis" */ '@/features/analysis/views/NARAStandards.vue'
            ),
        meta: { namespace: 'analysis' },
    },
    reports: {
        path: '/analysis/reports',
        name: 'Reports',
        component: () =>
            import(
                /* webpackPrefetch: true */ /* webpackChunkName: "analysis" */ '@/features/analysis/views/Reports.vue'
            ),
        meta: { namespace: 'analysis' },
    },
};

// <!-- EXPORTS -->
/** @typedef {Record<String, Readonly<Router.RouteRecordRaw>>} */
export const analysis = {
    home: {
        path: '/',
        name: 'Home',
        redirect: '/analysis',
        component: /** @type {() => Promise<typeof import('*.vue')>}*/ (
            /** @type {unknown} */ (Home)
        ),
        meta: { namespace: 'analysis' },
    },
    index: {
        path: '/analysis',
        name: 'Analysis',
        component: () =>
            import(
                /* webpackPrefetch: true */ /* webpackChunkName: "analysis" */ '@/features/analysis/views/index.vue'
            ),
        meta: { namespace: 'analysis' },
        children: [
            children.graph,
            children.statistics,
            children.compare,
            children.narastandards,
            children.reports,
        ],
    },
};

// <!-- DEFAULT -->
export default analysis;
