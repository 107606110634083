import { AccountPayload } from '@/payloads/v2/accounts/AccountPayload';
import { Maybe } from 'true-myth/dist/maybe';
import { Constants } from '@/enums';
import { Organization } from '@/models/v2/organizations';

/**
 * Resource model.
 * @implements {globalThis.Account.Model}
 */
export class Account {
    /** Get the static factory methods. */
    static get create() {
        return {
            /** @type {ModelConstructor<globalThis.Account.Model, globalThis.Account.Payload>} */
            fromPayload: (payload) => new Account(payload),
        };
    }

    /**
     * Convert model into a payload format.
     *
     * @param {globalThis.Account.Model} model
     * @returns {globalThis.Account.Payload}
     */
    static createPayload(model) {
        return AccountPayload.create.fromData({
            id: model.id,
            name: model.name,
            reminder: model.reminderFrequency,
            temp_scale: model.temperatureScale,
            timezone: {
                timezone_type: 3,
                timezone: model.timezone,
            },
            last_upload_at: model.lastUploadAt?.toISOString(),
            created_at: model.createdAt?.toISOString(),
            updated_at: model.updatedAt?.toISOString(),
            account_tree_level: model.treeLabels,

            number_of_locations: model.numberOfLocations,
            number_of_stations: model.numberOfStations,
            number_of_notes: model.numberOfNotes,
            number_of_hierarchies: model.numberOfHierarchies,
            number_of_dataset_batches: model.numberOfDatasetBatches,
            number_of_mapping_profiles: model.numberOfMappingProfiles,
            number_of_report_templates: model.numberOfReportTemplates,

            organization: Organization.Payload.createFromModel(
                model.organization
            ),
            organization_id: model.organizationId ?? model.organization?.id,
        });
    }

    /**
     * Convert model into a row data entry for the organization's admin.
     *
     * @param {globalThis.Account.Model} model
     * @returns {globalThis.Account.Row}
     */
    static createRowModel(model) {
        return {
            id: model.id,
            name: model.name,
            lastUploadAt: model.lastUploadAt,
        };
    }

    /**
     * Construct a payload instance.
     * @param {Partial<globalThis.Account.Payload>} data
     */
    constructor(data) {
        this.id = data.id;
        this.name = data.name;
        this.reminderFrequency = Maybe.of(data.reminder).unwrapOr(
            Constants.ReminderFrequency.Never
        );
        this.temperatureScale = Maybe.of(data.temp_scale).unwrapOr(
            Constants.TemperatureScale.Fahrenheit
        );
        this.timezone = Maybe.of(data.timezone?.timezone).unwrapOr(
            'America/New_York'
        );

        const last_upload_at = Maybe.of(data.last_upload_at);
        this.lastUploadAt = last_upload_at.isJust
            ? new Date(last_upload_at.value)
            : null;
        const created_at = Maybe.of(data.created_at);
        this.createdAt = created_at.isJust ? new Date(created_at.value) : null;
        const updated_at = Maybe.of(data.updated_at);
        this.updatedAt = updated_at.isJust ? new Date(updated_at.value) : null;

        this.treeLabels = Maybe.of(data.account_tree_level).unwrapOr(
            /** @type {data['account_tree_level']} */ ([
                'Site',
                'Building',
                'Floor',
                'Room',
            ])
        );

        this.numberOfLocations = Maybe.of(data.number_of_locations).unwrapOr(
            NaN
        );
        this.numberOfStations = Maybe.of(data.number_of_stations).unwrapOr(NaN);
        this.numberOfNotes = Maybe.of(data.number_of_notes).unwrapOr(NaN);
        this.numberOfHierarchies = Maybe.of(
            data.number_of_hierarchies
        ).unwrapOr(NaN);
        this.numberOfDatasetBatches = Maybe.of(
            data.number_of_dataset_batches
        ).unwrapOr(NaN);
        this.numberOfMappingProfiles = Maybe.of(
            data.number_of_mapping_profiles
        ).unwrapOr(NaN);
        this.numberOfReportTemplates = Maybe.of(
            data.number_of_report_templates
        ).unwrapOr(NaN);

        this.organizationId = Maybe.of(data.organization_id).unwrapOr(NaN);
        this.organization = Maybe.of(data.organization).unwrapOr(null);
    }

    /**
     * Convert model into a payload.
     */
    toPayload() {
        return Account.createPayload(this);
    }

    /**
     * Convert model into a row data entry for the account's admin.
     *
     * @returns {globalThis.Account.Row}
     */
    toRowModel() {
        return Account.createRowModel(this);
    }
}
