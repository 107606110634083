// <!-- API -->
import {
    TimezoneFilter,
    DateRangeFilter,
    LimitFilterRecord,
    ScaleFilterRecord,
    LocationFilter,
    WeatherStationFilter,
} from '@/utils/filters';
import { DateRangeStoreDataModel } from '../daterange';

// <!-- TYPES -->
/** @typedef {import('@/utils/filters').ITimezoneFilter} ITimezoneFilter */
/** @typedef {import('@/utils/filters').ILimitFilterRecord} ILimitFilterRecord */
/** @typedef {import('@/utils/filters').IScaleFilterRecord} IScaleFilterRecord */
/** @typedef {import('@/utils/filters').ILocationFilter} ILocationFilter */
/** @typedef {import('@/utils/filters').IWeatherStationFilter} IWeatherStationFilter */
/** @typedef {import('@/utils/filters').ISidebarFilterRecord} ISidebarFilterRecord */

// <!-- MODELS -->
const { StoreDataModel: DateRangeStore } = DateRangeFilter;

/**
 * @class
 * Individual limit filter for the keyed axis.
 * @implements {ISidebarFilterRecord}
 */
export class SidebarFilterRecord {
    /**
     * Create an axis range.
     * @param {Readonly<Partial<ISidebarFilterRecord>>} [props]
     * @returns {ISidebarFilterRecord}
     */
    static create = (props = {}) => new SidebarFilterRecord(props);

    /**
     * Clone an existing axis range.
     * @param {Readonly<ISidebarFilterRecord>} [source]
     * @returns {ISidebarFilterRecord}
     */
    static clone = (source = new SidebarFilterRecord()) =>
        new SidebarFilterRecord(source);

    /**
     * Create an axis range.
     * @param {Readonly<Partial<ISidebarFilterRecord>>} [props]
     */
    constructor(props) {
        // Get the timezone filter.
        const timezone = TimezoneFilter.clone(props?.timezone);
        /** @type {ITimezoneFilter} Timezone filter. */
        this.timezone = timezone;

        const daterange = DateRangeStore.create(props?.daterange);
        /** @type {DateRangeStoreDataModel} Date range filter. */
        this.daterange = daterange;

        // Get the limit filter record.
        const limits = LimitFilterRecord.clone(props?.limits);
        /** @type {ILimitFilterRecord} Limit filters record. */
        this.limits = limits;

        // Get the limit filter record.
        const scales = ScaleFilterRecord.clone(props?.scales);
        /** @type {IScaleFilterRecord} Scale filters record. */
        this.scales = scales;

        // Get the locations filter.
        const locations = LocationFilter.clone(props?.locations);
        /** @type {ILocationFilter} Locations filter. */
        this.locations = locations;

        // Get the weather stations filter.
        const stations = WeatherStationFilter.clone(props?.stations);
        /** @type {IWeatherStationFilter} Weather stations filter. */
        this.stations = stations;
    }
}
