// <!-- TYPES -->
/** @typedef {import('@/utils/standards').IStandardMetrics} IStandardMetrics */
// TODO - Enhance with global types.
/**
 * Standard metrics data class.
 * @implements {IStandardMetrics}
 */
export class StandardMetrics {
    /**
     * Create new standard instance.
     * @param {Readonly<Partial<IStandardMetrics>>} props
     * @returns {IStandardMetrics}
     */
    static create = (props) => {
        const instance = new StandardMetrics(props);
        return instance;
    };

    /**
     * Clone existing standard.
     * @param {Readonly<IStandardMetrics>} source
     * @returns {IStandardMetrics}
     */
    static clone = (source) => {
        const instance = new StandardMetrics(source);
        return instance;
    };

    /**
     * Create a general standard instance.
     * @param {Readonly<Partial<IStandardMetrics>>} props
     */
    constructor(props) {
        // <!-- DESTRUCTURE -->
        const {
            records = 0,
            temp_below_count = 0,
            temp_in_count = 0,
            temp_above_count = 0,
            temp_below_percent = 0,
            temp_in_percent = 0,
            temp_above_percent = 0,
            rh_below_count = 0,
            rh_in_count = 0,
            rh_above_count = 0,
            rh_below_percent = 0,
            rh_in_percent = 0,
            rh_above_percent = 0,
        } = props ?? {};
        this.records = records;
        this.temp_below_count = temp_below_count;
        this.temp_in_count = temp_in_count;
        this.temp_above_count = temp_above_count;
        this.temp_below_percent = temp_below_percent;
        this.temp_in_percent = temp_in_percent;
        this.temp_above_percent = temp_above_percent;
        this.rh_below_count = rh_below_count;
        this.rh_in_count = rh_in_count;
        this.rh_above_count = rh_above_count;
        this.rh_below_percent = rh_below_percent;
        this.rh_in_percent = rh_in_percent;
        this.rh_above_percent = rh_above_percent;
    }
}
