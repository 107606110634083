// <!-- API -->
import { ECNBStateMutations } from '@/store/types/ECNBStateMutations';
import isNil from 'lodash-es/isNil';

// <!-- TYPES -->
import {
    UploadWorkflow,
    WorkflowStatusID,
} from '@/store/types/uploader/state/UploadWorkflow';
import { FormStep } from '@/hooks/useFormStep';

/**
 * Synchronous mutations for a {@link UploadWorkflow} instance.
 */
export class UploadWorkflowMutations extends ECNBStateMutations {
    /**
     * Setter mutations for the step controller.
     * @param {UploadWorkflow} context Workflow context.
     */
    static set(context) {
        return {
            /**
             * Change the workflow status.
             * @param {keyof WorkflowStatusID} id
             */
            status: (id) => {
                /**
                 * Change {@link id} status.
                 * @param {Boolean} value
                 */
                const $change = (value) => {
                    const updated = context.status;
                    if (value === true) {
                        console.info(
                            `[flag::${id}]: %cENABLED`,
                            `background: seagreen;`
                        );
                        updated.add(id);
                    }
                    if (isNil(value) || value === false) {
                        console.info(
                            `[flag::${id}]: %cDISABLED`,
                            `background: maroon;`
                        );
                        updated.delete(id);
                    }
                    context.status = updated;
                };
                return {
                    /** @param {Boolean} value */
                    to: (value) => $change(value),
                    enable: () => $change(true),
                    disable: () => $change(false),
                };
            },
            /**
             * Set the form step sequence.
             * @param {Map<String, FormStep>} steps
             */
            steps: (steps) => {
                context.steps = steps;
            },
            /**
             * Access current step mutations.
             */
            get currentStep() {
                const $set = this;
                return {
                    /**
                     * Set current step to index.
                     * @param {Number} index
                     */
                    to: (index = -1) => (context.currentStepIndex = index),
                    /**
                     * Set to first step.
                     */
                    toFirst: () => $set.currentStep.to(0),
                    /**
                     * Set to last step.
                     */
                    toLast: () => $set.currentStep.to(context.count - 1),
                    /**
                     * Set to previous step.
                     */
                    toPrevious: () =>
                        $set.currentStep.to(context.currentStepIndex - 1),
                    /**
                     * Set to next step.
                     */
                    toNext: () =>
                        $set.currentStep.to(context.currentStepIndex + 1),
                    /**
                     * Set current step by id.
                     * @param {String} id
                     */
                    byID: (id) => {
                        if (context.steps.has(id)) {
                            const index = context.sequence.findIndex(
                                (s) => s.id === id
                            );
                            $set.currentStep.to(index);
                        }
                    },
                    /**
                     * Set current step by index.
                     * @param {Number} index
                     */
                    byIndex: (index) => $set.currentStep.to(index),
                };
            },
            /**
             * Replace sequence step individually. Step must exist.
             */
            get step() {
                const $set = this;
                return {
                    /**
                     * Replace step by index.
                     * @param {Number} index
                     * @param {FormStep} step
                     */
                    at: (index, step) => {
                        if (index >= 0 && index < context.count) {
                            const selected = context.sequence[index];
                            $set.step.byID(selected.id, step);
                        }
                    },
                    /**
                     * Replace step by id.
                     * @param {String} id
                     * @param {FormStep} step
                     */
                    byID: (id, step) => {
                        if (context.steps.has(id)) {
                            context.steps = context.steps.set(id, step);
                        }
                    },
                };
            },
        };
    }
    /**
     * Adder mutations for the step controller.
     * @param {UploadWorkflow} context Workflow context.
     */
    static add(context) {
        const $ = UploadWorkflowMutations;
        return {
            /**
             * Add the workflow status.
             * @param {keyof WorkflowStatusID} id
             */
            status: (id) => $.set(context).status(id).enable(),
        };
    }
    /**
     * Drop mutations for the step controller.
     * @param {UploadWorkflow} context Workflow context.
     */
    static drop(context) {
        const $ = UploadWorkflowMutations;
        return {
            /**
             * Drop the workflow status.
             */
            get status() {
                return {
                    /**
                     * Drop all statuses.
                     */
                    all: () => context.status.clear(),
                    /**
                     * Drop specified status.
                     * @param {keyof WorkflowStatusID} id
                     */
                    byID: (id) => $.set(context).status(id).disable(),
                };
            },
        };
    }
    /**
     * Access specialized status mutations.
     * @param {UploadWorkflow} context
     */
    static change(context) {
        const $ = UploadWorkflowMutations;
        return {
            get status() {
                /**
                 * Get status mutations.
                 * @param {keyof WorkflowStatusID} key
                 */
                const getMutationsForStatus = (key) => {
                    return {
                        /**
                         * Set the status.
                         * @param {Boolean} value
                         */
                        set: (value) => $.set(context).status(key).to(value),
                        /**
                         * Activate status.
                         */
                        enable: () => $.add(context).status(key),
                        /**
                         * Deactivate status.
                         */
                        disable: () => $.drop(context).status.byID(key),
                    };
                };
                return {
                    /**
                     * Mutate the specified status.
                     */
                    get locked() {
                        return getMutationsForStatus('locked');
                    },
                    /**
                     * Mutate the specified status.
                     */
                    get fulfilled() {
                        return getMutationsForStatus('fulfilled');
                    },
                    /**
                     * Mutate the specified status.
                     */
                    get rejected() {
                        return getMutationsForStatus('rejected');
                    },
                };
            },
        };
    }
}
