/**
 * Map containing resolve code points.
 */
const CodePoints = Object.freeze({
    checkmark: 0x2705,
    warning: 0x26a0,
    cross: 0x1f6a8,
    stethescope: 0x1fa7a,
    pause: 0x23f8,
    'heart-red': 0x2764,
    'heart-black': 0x1f5a4,
    'heart-ache': 0x1f493,
    internet: 0x1f310,
    bulb: 0x1f4a1,
    degree: 0x00b0,
    infinity: 0x267e,
    copyright: 0x00a9,
});

/**
 * Helper function for getting the generated resolves.
 * @param {keyof CodePoints} key
 * @returns {any}
 */
const resolve = (key) => String.fromCodePoint(CodePoints[key]);

// NOTE: Depending on your OS, you may not be able to see the
//       emoji rendered properly in VS Code. This can be fixed
//       using the `editor.fontFamily` setting.
// SEE:  https://github.com/microsoft/vscode/issues/32840#issuecomment-1009300687

/** @class Emoji utility class. */
export const Emoji = Object.freeze({
    /** @type {'✅'} */
    checkmark: resolve('checkmark'),
    /** @type {'⚠️'} */
    warning: resolve('warning'),
    /** @type {'🚨'} */
    cross: resolve('cross'),
    /** @type {'🩺'} */
    stethescope: resolve('stethescope'),
    /** @type {'⏸'} */
    pause: resolve('pause'),
    heart: Object.freeze({
        /** @type {'❤'} */
        red: resolve('heart-red'),
        /** @type {'🖤'} */
        black: resolve('heart-black'),
        /** @type {'💓'} */
        ache: resolve('heart-ache'),
    }),
    /** @type {'🌐'} */
    internet: resolve('internet'),
    /** @type {'💡'} */
    bulb: resolve('bulb'),
    /** @type {'°'} */
    degree: resolve('degree'),
    /** @type {'♾️'} */
    infinity: resolve('infinity'),
    /** @type {'©'} */
    copyright: resolve('copyright'),
});

// <!-- DEFAULT -->
export default Emoji;
