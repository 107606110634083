// <!-- UTILITIES -->
import pick from 'just-pick';

// <!-- TYPES -->
// ==== GRAPH FILTERS ====
/** @typedef {import('@/utils/filters').AxisExtent} AxisExtent */
/** @typedef {import('@/utils/filters').IAxisCategoryID} IAxisCategoryID */
/** @typedef {import('@/utils/filters').IAxisID} IAxisID */
/** @typedef {import('@/utils/filters').IAxisRange} IAxisRange */
/** @typedef {import('@/utils/filters').IAxisCategory} IAxisCategory */

export class AxisCategory {
    /** Temperature axis category. */
    static get T() {
        return /** @type {const} */ ({
            category: 'T',
            axis: 'temp',
            label: 'Temperature',
        });
    }

    /** Relative Humidity axis category. */
    static get RH() {
        return /** @type {const} */ ({
            category: 'RH',
            axis: 'rh',
            label: 'Temperature',
        });
    }

    /** Dew Point axis category. */
    static get DP() {
        return /** @type {const} */ ({
            category: 'DP',
            axis: 'dp',
            label: 'Temperature',
        });
    }

    /**
     * Get iterable of available categories.
     * @returns {Generator<IAxisCategory, void, void>}
     */
    static *categories() {
        yield AxisCategory.T;
        yield AxisCategory.RH;
        yield AxisCategory.DP;
        return;
    }

    /**
     * Get available category by id or key.
     */
    static get category() {
        return {
            /**
             * Get available category by id.
             * @param {IAxisCategoryID} id
             */
            byCategory: (id) => {
                const values = [...AxisCategory.categories()];
                return values.find((category) => category.category === id);
            },
            /**
             * Get available category by key.
             * @param {IAxisID} id
             */
            byAxis: (id) => {
                const values = [...AxisCategory.categories()];
                return values.find((category) =>
                    category.axis
                        .toLocaleLowerCase()
                        .localeCompare(id.toLocaleLowerCase())
                );
            },
        };
    }
}

// <!-- DEFAULT -->
export default AxisCategory;
