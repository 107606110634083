// <!-- API -->
import { ECNBStateMutations } from '@/store/types/ECNBStateMutations';
import isNil from 'lodash-es/isNil';

// <!-- TYPES -->
import { ECNBResourceIndexState } from '@/store/types/cache/state';
import { ECNBResourceStatus } from '@/store/types/cache/state/ECNBResourceIndexState';

/**
 * @class
 * Resource index state mutations.
 */
export class ECNBResourceIndexStateMutations extends ECNBStateMutations {
    /**
     * Provide access to direct state assignment operations.
     * @template {any} [K=any] Resource id type.
     * @template {{ id: K }} [V=any] Resource type.
     * @template {ECNBResourceIndexState<K, V>} [S=any] State type.
     * @param {S} state State instance.
     */
    static set(state) {
        const $ = ECNBResourceIndexStateMutations;
        return {
            /**
             * Directly assign provided payload to the existing property.
             * @param {Map<K, V> | null} [payload] Optional payload. Clears property cache if null.
             */
            index: (payload) => {
                if (isNil(payload) || payload.size === 0) {
                    $.clear(state).index();
                } else {
                    state.index = payload;
                }
            },

            /**
             * Directly assign provided payload to the existing property.
             * @param {Map<String, any> | null} [payload] Optional payload. Clears property if null.
             */
            attributes: (payload) => {
                if (isNil(payload) || payload.size === 0) {
                    $.clear(state).attributes();
                } else {
                    state.attributes = payload;
                }
            },

            /**
             * Directly assign provided payload to the existing property.
             * @param {Set<String> | null} [payload] Optional payload. Clears property if null.
             */
            flags: (payload) => {
                if (isNil(payload) || payload.size === 0) {
                    $.clear(state).flags();
                } else {
                    state.flags = payload;
                }
            },

            /**
             * Directly assign provided payload to the existing property.
             * @param {Set<keyof ECNBResourceStatus> | null} [payload] Optional payload. Clears property if null.
             */
            statuses: (payload) => {
                if (isNil(payload) || payload.size === 0) {
                    $.clear(state).flags();
                } else {
                    state.status = payload;
                }
            },

            /**
             * Directly assign provided item to the existing property collection.
             * @param {{ id: K, resource: V } | null} [item] Optional payload. Drops item if null.
             */
            resource: (item) => {
                const { id, resource } = item;
                const inferredID = id ?? resource?.id;
                if (isNil(inferredID)) {
                    // Do nothing.
                    console.warn(
                        `No identifier provided. Nothing resource to set.`
                    );
                    return;
                }
                if (isNil(resource)) {
                    const updated = state.index;
                    if (updated.delete(inferredID)) {
                        $.set(state).index(updated);
                    } else {
                        console.warn(
                            `No resource with matching identifier '${inferredID}' found. Nothing removed.`
                        );
                    }
                } else {
                    if (inferredID !== resource.id) {
                        throw new TypeError(
                            `Provided identifier does not match resource identifier.`
                        );
                    }
                    const updated = state.index.set(inferredID, resource);
                    $.set(state).index(updated);
                }
            },

            /**
             * Directly assign provided item to the existing property collection.
             * @param {{ key: String, value: any } | null} [item] Optional payload. Drops item if null.
             */
            attribute: (item) => {
                const { key, value } = item;
                if (isNil(key)) {
                    throw new TypeError(`No attribute key provided.`);
                }
                if (isNil(value)) {
                    const updated = state.attributes;
                    if (updated.delete(key)) {
                        $.set(state).attributes(updated);
                    } else {
                        console.warn(
                            `No attribute with matching key '${key}' found. Nothing removed.`
                        );
                    }
                } else {
                    // Assign updated attributes.
                    const updated = state.attributes.set(key, value);
                    $.set(state).attributes(updated);
                }
            },

            /**
             * Directly assign provided item to the existing property collection.
             * @param {{ key: String, value: Boolean } | null} [item] Optional payload. Drops item if null.
             */
            flag: (item) => {
                const { key, value } = item;
                if (isNil(key)) {
                    throw new TypeError(`No flag provided.`);
                }
                if (isNil(value) || value === false) {
                    const updated = state.flags;
                    if (updated.delete(key)) {
                        $.set(state).flags(updated);
                    } else {
                        console.warn(`No flag '${key}' found. Nothing unset.`);
                    }
                } else if (value === true) {
                    const updated = state.flags.add(key);
                    $.set(state).flags(updated);
                } else {
                    console.warn(
                        `Unsupported value passed. No mutation has occurred.`
                    );
                }
            },

            /**
             * Directly assign provided item to the existing property collection.
             * @param {{ key: keyof ECNBResourceStatus, value: Boolean } | null} [item] Optional payload. Drops item if null.
             */
            status: (item) => {
                const { key, value } = item;
                if (isNil(key)) {
                    throw new TypeError(`No status provided.`);
                }
                if (isNil(value) || value === false) {
                    const updated = state.status;
                    if (updated.delete(key)) {
                        $.set(state).flags(updated);
                    } else {
                        console.warn(
                            `No status '${key}' found. Nothing unset.`
                        );
                    }
                } else if (value === true) {
                    const updated = state.status.add(key);
                    $.set(state).statuses(updated);
                } else {
                    console.warn(
                        `Unsupported value passed. No mutation has occurred.`
                    );
                }
            },
        };
    }

    /**
     * Provide access to clear state operations.
     * @template {any} [K=any] Resource id type.
     * @template {{ id: K }} [V=any] Resource type.
     * @template {ECNBResourceIndexState<K, V>} [S=any] State type.
     * @param {S} state State instance.
     */
    static clear(state) {
        const $ = ECNBResourceIndexStateMutations;
        return {
            /**
             * Clear existing property.
             */
            index: () => {
                const updated = state.index;
                updated.clear();
                state.index = updated;
            },
            /**
             * Clear existing property.
             */
            attributes: () => {
                const updated = state.attributes;
                updated.clear();
                state.attributes = updated;
            },
            /**
             * Clear existing property.
             */
            flags: () => {
                const updated = state.flags;
                updated.clear();
                state.flags = updated;
            },
            /**
             * Clear existing property.
             */
            statuses: () => {
                const updated = state.status;
                updated.clear();
                state.status = updated;
            },
        };
    }

    /**
     * Provide access to state add operations.
     * @template {any} [K=any] Resource id type.
     * @template {{ id: K }} [V=any] Resource type.
     * @template {ECNBResourceIndexState<K, V>} [S=any] State type.
     * @param {S} state State instance.
     */
    static add(state) {
        const $ = ECNBResourceIndexStateMutations;
        return {
            /**
             * Merge provided payload with existing property.
             * @param {Map<K, V>} [payload]
             */
            index: (payload) => {
                if (isNil(payload) || payload.size <= 0) {
                    console.warn(
                        `Missing or empty index payload. Nothing to add.`
                    );
                    return;
                }
                for (const [id, resource] of payload) {
                    $.add(state).resource({ id, resource });
                }
            },
            /**
             * Merge provided payload with existing property.
             * @param {Map<String, any>} [payload]
             */
            attributes: (payload) => {
                if (isNil(payload) || payload.size <= 0) {
                    console.warn(
                        `Missing or empty attributes payload. Nothing to add.`
                    );
                    return;
                }
                for (const [key, value] of payload) {
                    $.add(state).attribute(key, value);
                }
            },
            /**
             * Merge provided payload with existing property.
             * @param {Set<String>} [payload]
             */
            flags: (payload) => {
                if (isNil(payload) || payload.size <= 0) {
                    console.warn(
                        `Missing or empty flags payload. Nothing to add.`
                    );
                    return;
                }
                for (const key of payload) {
                    $.add(state).flag(key);
                }
            },
            /**
             * Merge provided payload with existing property.
             * @param {Set<keyof ECNBResourceStatus>} [payload]
             */
            statuses: (payload) => {
                if (isNil(payload) || payload.size <= 0) {
                    console.warn(
                        `Missing or empty statuses payload. Nothing to add.`
                    );
                    return;
                }
                for (const key of payload) {
                    $.add(state).status(key);
                }
            },

            /**
             * Merge provided payload item with existing property collection.
             * @param {V} [item] Resource.
             */
            resource: (item) => {
                if (isNil(item) || isNil(item?.id)) {
                    console.warn(
                        `Missing resource or resource has no valid identifier. Nothing to add.`
                    );
                    return;
                }
                const { id } = item;
                $.set(state).resource({ id, resource: item });
            },
            /**
             * Merge provided payload item with existing property collection.
             * @param {String} key
             * @param {any} [item]
             */
            attribute: (key, item) => {
                if (isNil(key) || isNil(item)) {
                    console.warn(
                        `Missing attribute key or attribute. Nothing to add.`
                    );
                    return;
                }
                $.set(state).attribute({ key, value: item });
            },
            /**
             * Merge provided payload item with existing property collection.
             * @param {String} [key]
             */
            flag: (key) => {
                if (isNil(key)) {
                    console.warn(`Missing flag key. Nothing to add.`);
                    return;
                }
                $.set(state).flag({ key, value: true });
            },
            /**
             * Merge provided payload item with existing property collection.
             * @param {keyof ECNBResourceStatus} [key]
             */
            status: (key) => {
                if (isNil(key)) {
                    console.warn(`Missing status key. Nothing to add.`);
                    return;
                }
                $.set(state).status({ key, value: true });
            },
        };
    }

    /**
     * Provide access to state drop operations.
     * @template {any} [K=any] Resource id type.
     * @template {{ id: K }} [V=any] Resource type.
     * @template {ECNBResourceIndexState<K, V>} [S=any] State type.
     * @param {S} state State instance.
     */
    static drop(state) {
        const $ = ECNBResourceIndexStateMutations;
        return {
            /**
             * Seperate provided payload with existing property.
             * @param {Set<K> | 'all'} [payload]
             */
            index: (payload) => {
                if (payload === 'all') {
                    $.clear(state).index();
                    return;
                }
                if (isNil(payload) || payload.size <= 0) {
                    console.warn(
                        `Missing or empty index payload. Nothing to drop.`
                    );
                    return;
                }
                for (const id of payload) {
                    $.drop(state).resource(id);
                }
            },
            /**
             * Seperate provided payload with existing property.
             * @param {Set<String> | 'all'} [payload]
             */
            attributes: (payload) => {
                if (payload === 'all') {
                    $.clear(state).attributes();
                    return;
                }
                if (isNil(payload) || payload.size <= 0) {
                    console.warn(
                        `Missing or empty attributes payload. Nothing to drop.`
                    );
                    return;
                }
                for (const key of payload) {
                    $.drop(state).attribute(key);
                }
            },
            /**
             * Seperate provided payload with existing property.
             * @param {Set<String> | 'all'} [payload]
             */
            flags: (payload) => {
                if (payload === 'all') {
                    $.clear(state).flags();
                    return;
                }
                if (isNil(payload) || payload.size <= 0) {
                    console.warn(
                        `Missing or empty flags payload. Nothing to drop.`
                    );
                    return;
                }
                for (const key of payload) {
                    $.drop(state).flag(key);
                }
            },
            /**
             * Seperate provided payload with existing property.
             * @param {Set<keyof ECNBResourceStatus> | 'all'} [payload]
             */
            statuses: (payload) => {
                if (payload === 'all') {
                    $.clear(state).statuses();
                    return;
                }
                if (isNil(payload) || payload.size <= 0) {
                    console.warn(
                        `Missing or empty statuses payload. Nothing to drop.`
                    );
                    return;
                }
                for (const key of payload) {
                    $.drop(state).status(key);
                }
            },

            /**
             * Drop provided payload item from existing property collection.
             * @param {K} id
             */
            resource: (id) => {
                if (isNil(id)) {
                    console.warn(
                        `Missing resource identifier. Nothing to drop.`
                    );
                    return;
                }
                $.set(state).resource({ id, resource: null });
            },
            /**
             * Drop provided payload item from existing property collection.
             * @param {String} key
             */
            attribute: (key) => {
                if (isNil(key)) {
                    console.warn(
                        `Missing attribute identifier. Nothing to drop.`
                    );
                    return;
                }
                $.set(state).attribute({ key, value: null });
            },
            /**
             * Drop provided payload item from existing property collection.
             * @param {String} key
             */
            flag: (key) => {
                if (isNil(key)) {
                    console.warn(`Missing flag identifier. Nothing to drop.`);
                    return;
                }
                $.set(state).flag({ key, value: false });
            },
            /**
             * Drop provided payload item from existing property collection.
             * @param {keyof ECNBResourceStatus} key
             */
            status: (key) => {
                if (isNil(key)) {
                    console.warn(`Missing status identifier. Nothing to drop.`);
                    return;
                }
                $.set(state).status({ key, value: false });
            },
        };
    }
}
