// <!-- INTERFACES -->
import { PayloadResource } from '@/utils/resources';
import { PayloadAttributeFactory } from '@/utils/resources';
import { PlanModel } from '@/models/v2/plans/PlanModel';

// <!-- RELATIONSHIPS -->
import { SubscriptionPayload } from '@/payloads/v2/subscriptions/SubscriptionPayload';
import { OrganizationPayload } from '@/payloads/v2/organizations/OrganizationPayload';

/**
 * Create a specialized resource instance.
 *
 * @extends {PayloadResource<PayloadData,ModelData>}
 * @implements {Resource.Payload<PayloadData,ModelData>}
 * @implements {PayloadData}
 */
export class PlanPayload extends PayloadResource {
    // <!-- TYPE ALIASES -->

    /** @typedef {Plan.Payload} PayloadData */
    /** @typedef {Plan.Model} ModelData */

    // <!-- STATIC FACTORY METHODS -->

    /**
     * Create resource instance from passed attributes.
     *
     * @param {Partial<PayloadData>} attributes
     * @returns {PlanPayload}
     */
    static create(attributes) {
        return new PlanPayload(attributes);
    }

    /**
     * Create resource instance from passed attributes.
     *
     * @param {Partial<ModelData>} data
     * @returns {PlanPayload}
     */
    static fromModel(data) {
        const attributes = PlanPayload.attributesFromModel(data);
        return PlanPayload.create(attributes);
    }

    // <!-- STATIC UTILITY METHODS -->

    /** @type {Readonly<PayloadData>} */
    static get defaults() {
        return {
            id: null,
            name: null,
            max_users: null,
            max_locations: null,
            price_per_year: '0',
            price_per_month: '0',
            is_payment_required: true,
            is_free_plan: false,
            is_trial: false,
            is_basic: false,
            is_professional: false,
            is_enterprise: false,
            are_users_unlimited: false,
            are_locations_unlimited: false,
            number_of_subscriptions: null,
            number_of_organizations: null,
            subscriptions: [],
            organizations: [],
        };
    }

    /**
     * Create resource attributes from the model data.
     *
     * @param {Partial<ModelData>} model
     * @returns {Partial<PayloadData>}
     */
    static attributesFromModel(model) {
        // Get transformer functions.
        const { coalesce, serialize } = this;

        // Transform attributes.
        const number_of_subscriptions = coalesce.count(
            model.numberOfSubscriptions
        );
        const number_of_organizations = coalesce.count(
            model.numberOfOrganizations
        );
        const subscriptions = model.subscriptions?.map(
            SubscriptionPayload.fromModel
        );
        const organizations = model.organizations?.map(
            OrganizationPayload.fromModel
        );

        // Return the created state.
        return {
            id: model.id,
            name: model.name,
            max_users: coalesce.count(model.maxUsers),
            max_locations: coalesce.count(model.maxLocations),
            are_users_unlimited: model.areUsersUnlimited,
            are_locations_unlimited: model.areLocationsUnlimited,
            price_per_year: serialize.priceAsString(model.pricePerYear),
            price_per_month: serialize.priceAsString(model.pricePerMonth),
            is_payment_required: model.isPaymentRequired,
            is_free_plan: model.isFreePlan,
            is_trial: model.isTrialPlan,
            is_basic: model.isBasicPlan,
            is_professional: model.isProfessionalPlan,
            is_enterprise: model.isEnterprisePlan,
            number_of_subscriptions,
            subscriptions,
            number_of_organizations,
            organizations,
        };
    }

    // <!-- CONSTRUCTOR -->

    /**
     * Create resource instance.
     *
     * @param {Partial<PayloadData>} attributes
     */
    constructor(attributes) {
        super(
            attributes,
            PayloadAttributeFactory.create(
                PlanPayload.attributesFromModel,
                PlanPayload.defaults
            )
        );

        // Hydrate sub-resource.
        if (this.exists('organizations')) {
            this.organizations = this.organizations.map((payload) => {
                if (!(payload instanceof OrganizationPayload)) {
                    return OrganizationPayload.create(payload);
                } else {
                    return payload;
                }
            });
        }

        // Hydrate sub-resource.
        if (this.exists('subscriptions')) {
            this.subscriptions = this.subscriptions.map((payload) => {
                if (!(payload instanceof SubscriptionPayload)) {
                    return SubscriptionPayload.create(payload);
                } else {
                    return payload;
                }
            });
        }
    }

    // <!-- RESOURCE INTERFACE -->

    /** Get shallow copy of this instance as a payload resource. */
    toPayload() {
        return this.clone();
    }

    /** Get shallow copy of this instance as a model resource. */
    toModel() {
        return PlanModel.fromPayload(this);
    }

    /** Get shallow copy of this instance. */
    clone() {
        return /** @type {this} */ (PlanPayload.create(this));
    }

    // <!-- ATTRIBUTE::PROPERTIES -->

    get id() {
        return this.get('id');
    }

    set id(value) {
        this.set('id', value);
    }

    get name() {
        return this.get('name');
    }

    set name(value) {
        this.set('name', value);
    }

    get max_users() {
        return this.get('max_users');
    }

    set max_users(value) {
        this.set('max_users', value);
    }

    get max_locations() {
        return this.get('max_locations');
    }

    set max_locations(value) {
        this.set('max_locations', value);
    }

    get price_per_year() {
        return this.get('price_per_year');
    }

    set price_per_year(value) {
        this.set('price_per_year', value);
    }

    get price_per_month() {
        return this.get('price_per_month');
    }

    set price_per_month(value) {
        this.set('price_per_month', value);
    }

    get is_payment_required() {
        return this.get('is_payment_required');
    }

    set is_payment_required(value) {
        this.set('is_payment_required', value);
    }

    get is_free_plan() {
        return this.get('is_free_plan');
    }

    set is_free_plan(value) {
        this.set('is_free_plan', value);
    }

    get is_trial() {
        return this.get('is_trial');
    }

    set is_trial(value) {
        this.set('is_trial', value);
    }

    get is_basic() {
        return this.get('is_basic');
    }

    set is_basic(value) {
        this.set('is_basic', value);
    }

    get is_professional() {
        return this.get('is_professional');
    }

    set is_professional(value) {
        this.set('is_professional', value);
    }

    get is_enterprise() {
        return this.get('is_enterprise');
    }

    set is_enterprise(value) {
        this.set('is_enterprise', value);
    }

    get are_users_unlimited() {
        return this.get('are_users_unlimited');
    }

    set are_users_unlimited(value) {
        this.set('are_users_unlimited', value);
    }

    get are_locations_unlimited() {
        return this.get('are_locations_unlimited');
    }

    get number_of_subscriptions() {
        return this.get('number_of_subscriptions');
    }

    get number_of_organizations() {
        return this.get('number_of_organizations');
    }

    get subscriptions() {
        return this.get('subscriptions');
    }

    get organizations() {
        return this.get('organizations');
    }

    set are_locations_unlimited(value) {
        this.set('are_locations_unlimited', value);
    }

    set number_of_subscriptions(value) {
        this.set('number_of_subscriptions', value);
    }

    set number_of_organizations(value) {
        this.set('number_of_organizations', value);
    }

    set subscriptions(value) {
        this.set('subscriptions', value);
    }

    set organizations(value) {
        this.set('organizations', value);
    }
}

// <!-- EXPORTS -->
export default PlanPayload;
