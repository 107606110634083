// <!-- TYPES -->
import { Model } from '@/models/v1/resource/Model';
import { DynamicEnumFactory } from '@/utils/DynamicEnum';
/** @typedef {ReturnType<WeatherStation['toPayload']>} WeatherStationPayload */
/** @typedef {ReturnType<WeatherStation['toResource']>} WeatherStationResource */

/** Resource and payload use the same fieldnames. */
const FIELDS = DynamicEnumFactory().fromKeys(['id', 'name', 'serial']);

/**
 * Represents a weather station.
 * @class
 */
export class WeatherStation extends Model {
    _initialState() {
        return Model.ComposeStateUsingFields(FIELDS);
    }

    toPayload() {
        return {
            /** @type {String} */
            [FIELDS.id]: this.id,
            /** @type {String} */
            [FIELDS.name]: this.get(FIELDS.name),
            /** @type {String} */
            [FIELDS.serial]: this.get(FIELDS.serial) ?? null,
        };
    }

    toResource() {
        return {
            /** @type {String} */
            [FIELDS.id]: this.id,
            /** @type {String} */
            [FIELDS.name]: this.get(FIELDS.name),
            /** @type {String} */
            [FIELDS.serial]: this.get(FIELDS.serial) ?? null,
        };
    }
}
