// <!-- API -->
import { Assertion } from '@/store/types/ECNBAssertions';
import {
    UploadWorkflow,
    WorkflowStatusID,
} from '@/store/types/uploader/state/UploadWorkflow';
import isNil from 'lodash-es/isNil';

// <!-- TYPES -->

import { ECNBState } from '@/store/types/ECNBStore';
import { ECNBModule } from '@/store/types/ECNBModule';
import { FormStep } from '@/hooks/useFormStep';

/**
 * @class
 * Asynchronous actions that can be invoked with the appropriate context.
 */
export class UploadWorkflowActions {
    /**
     * Asynchronous assertions that will test predicates
     * and fail when appropriate, throwing errors to interrupt actions.
     * @param {import('vuex').ActionContext<UploadWorkflow, ECNBState>} context
     */
    static assert(context) {
        const $ = UploadWorkflowActions;
        return {
            /**
             * Assert workflow is idle.
             * @returns {Promise<void>}
             */
            idle: async () => {
                const workflow = context.state;
                await Assertion.expect(workflow.isIdle).isTruthy();
            },
            /**
             * Assert workflow is done.
             * @returns {Promise<void>}
             */
            done: async () => {
                const workflow = context.state;
                await Assertion.expect(workflow.isDone).isTruthy();
            },
            /**
             * Assert workflow is fulfilled.
             * @returns {Promise<void>}
             */
            fulfilled: async () => {
                const workflow = context.state;
                await Assertion.expect(workflow.isFulfilled).isTruthy();
            },
            /**
             * Assert workflow is rejected.
             * @returns {Promise<void>}
             */
            rejected: async () => {
                const workflow = context.state;
                await Assertion.expect(workflow.isRejected).isTruthy();
            },
            /**
             * Assert workflow transitions are locked.
             * @returns {Promise<void>}
             */
            locked: async () => {
                const workflow = context.state;
                await Assertion.expect(workflow.isLocked).isTruthy();
            },
            /**
             * Assert workflow transitions are unlocked.
             * @returns {Promise<void>}
             */
            unlocked: async () => {
                const workflow = context.state;
                await Assertion.expect(workflow.isLocked).isFalsy();
            },
            /**
             * Assert workflow has started and is not fulfilled or rejected.
             * @returns {Promise<void>}
             */
            running: async () => {
                const workflow = context.state;
                await Assertion.expect(workflow.isRunning).isTruthy();
            },
            /**
             * Assert workflow has steps.
             * @returns {Promise<void>}
             */
            hasSteps: async () => {
                const workflow = context.state;
                await Assertion.expect(workflow.steps).isNotEmpty();
            },
            /**
             * Assert workflow has begun or exceeded the first step.
             * @returns {Promise<void>}
             */
            started: async () => {
                const workflow = context.state;
                const current = workflow.currentStepIndex;
                const first = 0;
                await Assertion.expect(current).isGreaterThanOrEqual(first);
            },
            /**
             * Upload step collection assertions.
             */
            get workflowStep() {
                const workflow = context.state;
                return {
                    /**
                     * Assert step exists and is not null nor undefined and exists in the workflow collection.
                     * @param {Pick<FormStep, 'id'>} step
                     * @returns {Promise<void>}
                     */
                    isPresent: async (step) => {
                        await Assertion.expect(step).isNotNil();
                        await Assertion.expect(
                            workflow.steps.has(step.id)
                        ).isTruthy();
                    },
                    /**
                     * Assert step with matching id is null or undefined.
                     * @param {Pick<FormStep, 'id'>} step
                     */
                    isMissing: async (step) => {
                        if (!isNil(step) && !isNil(step.id))
                            await Assertion.expect(
                                workflow.steps.has(step.id)
                            ).isFalsy();
                        {
                        }
                    },
                    /**
                     * Assert current step has a first step.
                     */
                    hasFirst: async () => {
                        await $.assert(context).hasSteps();
                        const step = context.state.firstStep;
                        await Assertion.expect(step).isNotNil();
                    },
                    /**
                     * Assert current step has a last step.
                     */
                    hasLast: async () => {
                        await $.assert(context).hasSteps();
                        const step = context.state.lastStep;
                        await Assertion.expect(step).isNotNil();
                    },
                    /**
                     * Assert current step has a previous step.
                     */
                    hasPrevious: async () => {
                        await $.assert(context).hasSteps();
                        const step = context.state.previousStep;
                        await Assertion.expect(step).isNotNil();
                    },
                    /**
                     * Assert current step has a next step.
                     */
                    hasNext: async () => {
                        await $.assert(context).hasSteps();
                        const step = context.state.nextStep;
                        await Assertion.expect(step).isNotNil();
                    },
                    /**
                     * Assert step exists with matching id.
                     * @param {FormStep['id']} id
                     * @returns {Promise<void>}
                     */
                    withID: async (id) => {
                        await Assertion.expect(id).isNotNil();
                        const match = context.state.steps.get(id);
                        await Assertion.expect(match).isNotNil();
                    },
                    /**
                     * Assert step exists at the specified index location.
                     * @param {Number} index
                     * @returns {Promise<void>}
                     */
                    at: async (index) => {
                        await Assertion.expect(index).isNotNil();
                        await Assertion.expect(index).isNumber();
                        const workflow = context.state;
                        await Assertion.expect(index).isValidIndex(
                            workflow.count
                        );
                        const match = workflow.getStep(index);
                        await Assertion.expect(match).isNotNil();
                    },
                };
            },
            /**
             * Assert workflow transition is allowed.
             * @param {{ allowTransition?: Boolean | Promise<Boolean> | (() => Boolean) | (() => Promise<Boolean>)}} [payload]
             */
            transitionAllowed: async (payload) => {
                await $.assert(context).hasSteps();
                await $.assert(context).unlocked();
                // Check if the condition allows the transition.
                const { allowTransition = true } = payload;
                if (isNil(allowTransition)) {
                    return false;
                }
                if (typeof allowTransition === 'function') {
                    // Function or async function.
                    return await allowTransition();
                }
                // Literal or promise.
                return await allowTransition;
            },
        };
    }
    /**
     * Asynchronous actions for navigation of the workflow.
     * @param {import('vuex').ActionContext<UploadWorkflow, ECNBState>} context
     */
    static navigation(context) {
        const $ = UploadWorkflowActions;
        const workflow = context.state;
        return {
            get transition() {
                return {
                    /**
                     * Transition to specified step, by id or index.
                     */
                    get to() {
                        const $mutate = ECNBModule.use(context).mutate;
                        return {
                            /**
                             * Force transition to a specific index, so long as it is a valid number.
                             * @param {Number} index
                             */
                            force: async (index = -1) => {
                                await Assertion.expect(index).isNotNil();
                                await Assertion.expect(index).isNumber();
                                const target = { index };
                                $mutate('setCurrentStep').withPayload(target);
                                return workflow.currentStep;
                            },
                            /**
                             * Commit transition to specified step, so long as the step exists.
                             * @param {String} id
                             * @param {Boolean | Promise<Boolean> | (() => Promise<Boolean>) | (() => Boolean)} [allowTransition]
                             */
                            byID: async (id, allowTransition = true) => {
                                await Assertion.expect(id).isNotNil();
                                await $.assert(context).workflowStep.withID(id);
                                await $.assert(context).transitionAllowed({
                                    allowTransition,
                                });
                                const target = { id };
                                $mutate('setCurrentStep').withPayload(target);
                                return workflow.currentStep;
                            },
                            /**
                             * Commit transition to specified step, so long as the step exists.
                             * @param {Number} index
                             * @param {Boolean | Promise<Boolean> | (() => Promise<Boolean>) | (() => Boolean)} [allowTransition]
                             */
                            byIndex: async (index, allowTransition = true) => {
                                await Assertion.expect(index).isNotNil();
                                await Assertion.expect(index).isNumber();
                                await $.assert(context).workflowStep.at(index);
                                await $.assert(context).transitionAllowed({
                                    allowTransition,
                                });
                                const target = { index };
                                $mutate('setCurrentStep').withPayload(target);
                                return workflow.currentStep;
                            },
                            /**
                             * Commit transition to specified step, so long as the step exists.
                             * @param {Boolean | Promise<Boolean> | (() => Promise<Boolean>) | (() => Boolean)} [allowTransition]
                             */
                            first: async (allowTransition = true) => {
                                await $.assert(context).workflowStep.hasFirst();
                                await $.assert(context).transitionAllowed({
                                    allowTransition,
                                });
                                $mutate('toFirstStep').withPayload(undefined);
                                return workflow.currentStep;
                            },
                            /**
                             * Commit transition to specified step, so long as the step exists.
                             * @param {Boolean | Promise<Boolean> | (() => Promise<Boolean>) | (() => Boolean)} [allowTransition]
                             */
                            last: async (allowTransition = true) => {
                                await $.assert(context).workflowStep.hasLast();
                                await $.assert(context).transitionAllowed({
                                    allowTransition,
                                });
                                $mutate('toLastStep').withPayload(undefined);
                                return workflow.currentStep;
                            },
                            /**
                             * Commit transition to specified step, so long as the step exists.
                             * @param {Boolean | Promise<Boolean> | (() => Promise<Boolean>) | (() => Boolean)} [allowTransition]
                             */
                            previous: async (allowTransition = true) => {
                                await $.assert(
                                    context
                                ).workflowStep.hasPrevious();
                                await $.assert(context).transitionAllowed({
                                    allowTransition,
                                });
                                $mutate('toPreviousStep').withPayload(
                                    undefined
                                );
                                return workflow.currentStep;
                            },
                            /**
                             * Commit transition to specified step, so long as the step exists.
                             * @param {Boolean | Promise<Boolean> | (() => Promise<Boolean>) | (() => Boolean)} [allowTransition]
                             */
                            next: async (allowTransition = true) => {
                                await $.assert(context).workflowStep.hasNext();
                                await $.assert(context).transitionAllowed({
                                    allowTransition,
                                });
                                $mutate('toNextStep').withPayload(undefined);
                                return workflow.currentStep;
                            },
                        };
                    },
                };
            },
            get lifecycle() {
                const $ = UploadWorkflowActions;
                const $mutate = ECNBModule.use(context).mutate;
                return {
                    /**
                     * Start the workflow (if not started).
                     */
                    start: async () => {
                        await $.assert(context).idle();
                        await $.assert(context).hasSteps();
                        $mutate('start').withPayload({});
                    },
                    /**
                     * Resolve, reject, or restart the workflow. By default, restarts.
                     * @param {'fulfilled' | 'rejected' | 'restart'} status
                     */
                    stop: async (status = 'restart') => {
                        await $.assert(context).running();
                        await $.assert(context).hasSteps();
                        $mutate('end').withPayload(status);
                    },
                    /**
                     * Resolve the workflow.
                     */
                    resolve: async () => {
                        await $.navigation(context).lifecycle.stop('fulfilled');
                    },
                    /**
                     * Reject the workflow.
                     */
                    reject: async () => {
                        await $.navigation(context).lifecycle.stop('rejected');
                    },
                    /**
                     * Restart the workflow.
                     */
                    restart: async () => {
                        await $.navigation(context).lifecycle.stop('restart');
                    },
                };
            },
        };
    }
}
