// <!-- API -->
import { ECNBStateMutations } from '@/store/types/ECNBStateMutations';
import {
    SidebarFilterRecord,
    DateRangeFilter,
    LimitFilterRecord,
    ScaleFilterRecord,
    AxisRange,
    LimitFilter,
    ScaleFilter,
    LocationFilter,
    WeatherStationFilter,
    TimezoneFilter,
} from '@/utils/filters';

// <!-- UTILITIES -->
import { Tree } from '@/utils/tree';

// <!-- TYPES -->
import { AnalysisState } from '@/store/types/analysis/state';
/** @typedef {import('@/utils/filters').IAxisRange} IAxisRange */
/** @typedef {import('@/utils/filters').IAxisModifier} IAxisModifier */
/** @typedef {import('@/utils/filters').IAxisRangeFilter} IAxisRangeFilter */
/** @typedef {import('@/utils/filters').ILimitFilter} ILimitFilter */
/** @typedef {import('@/utils/filters').ILimitFilterRecord} ILimitFilterRecord */
/** @typedef {import('@/utils/filters').IScaleFilter} IScaleFilter */
/** @typedef {import('@/utils/filters').IScaleFilterRecord} IScaleFilterRecord */
/** @typedef {import('@/utils/filters').ILocationFilter} ILocationFilter */
/** @typedef {import('@/utils/filters').IWeatherStationFilter} IWeatherStationFilter */
/** @typedef {import('@/utils/filters').ISidebarFilterRecord} ISidebarFilterRecord */

/**
 * Synchronous {@link AnalysisState} mutations.
 */
export class AnalysisStateMutations extends ECNBStateMutations {
    // <!-- HELPERS -->

    /**
     * Provide access to direct state assignment operations.
     * @param {AnalysisState} state State instance.
     */
    static set(state) {
        const $ = AnalysisStateMutations;
        return {
            /**
             * Assign all filters.
             * @param {Readonly<ISidebarFilterRecord>} payload
             */
            filters: (payload) => {
                // Copy the provided payload values.
                const filters = SidebarFilterRecord.clone(payload);
                // Assign the filters.
                state.filters = filters;
            },
            /**
             * Access to date range filter setters.
             */
            get daterange() {
                return {
                    /**
                     * Assign the date range filter directly.
                     * @param {Readonly<Filters.DateRange.Store.Data>} payload
                     */
                    filter: (payload) => {
                        // Copy the provided payload values.
                        const daterange =
                            DateRangeFilter.StoreDataModel.create(payload);
                        // Patch the entire filters state.
                        $.patch(state).filters({ daterange });
                    },
                    /**
                     * Assign the date range strings directly.
                     * @param {Readonly<NominalDateRange>} payload
                     */
                    range: (payload) => {
                        // Copy the provided payload values.
                        const range =
                            DateRangeFilter.StoreDataModel.create(payload);
                        // Reference the existing state's modifier list, since this shouldn't change it
                        range.checked = state.filters.daterange.checked;
                        // Patch the entire filters state.
                        $.patch(state).daterange.filter(range);
                    },
                    /**
                     * Assign the start date directly.
                     * @param {Readonly<string>} payload
                     */
                    start: (payload) => {
                        // Parse the provided payload values.
                        const start = payload ?? '';
                        // Patch the date range filter.
                        $.patch(state).daterange.range({ start });
                    },
                    /**
                     * Assign the end date directly.
                     * @param {Readonly<string>} payload
                     */
                    end: (payload) => {
                        // Parse the provided payload values.
                        const end = payload ?? '';
                        // Patch the date range filter.
                        $.patch(state).daterange.range({ end });
                    },
                    /**
                     * Assign the modifiers directly.
                     * @param {Readonly<Filters.DateRange.ModifierList>} [payload]
                     */
                    modifiers: (payload = []) => {
                        // Get distinct modifiers.
                        const modifiers = new Set(payload);
                        // Get the array.
                        const checked = Array.from(modifiers);
                        // Patch the date range filter.
                        $.patch(state).daterange.filter({ checked });
                    },
                };
            },
            /**
             * Access to timezone filter setters.
             */
            get timezone() {
                return {
                    /**
                     * Assign the modifiers directly.
                     * @param {Readonly<TimeZone.Identifier>} [payload]
                     */
                    timezone: (payload = 'UTC') => {
                        // Patch the timezone filter.
                        $.patch(state).timezone.filter({
                            timezone: payload,
                        });
                    },
                    /**
                     * Assign the modifiers directly.
                     * @param {Readonly<boolean>} [payload]
                     */
                    inheritAccountTimezoneModifier: (payload = true) => {
                        // Patch the timezone filter.
                        $.patch(state).timezone.filter({
                            useAccountTimezone: payload,
                        });
                    },
                };
            },
            /**
             * Access to limits filter setters.
             */
            get limits() {
                return {
                    /**
                     * Assign the limit filters explicitly.
                     * @param {Readonly<ILimitFilterRecord>} payload
                     */
                    record: (payload) => {
                        // Copy the payload values.
                        const limits = LimitFilterRecord.clone(payload);
                        // Patch the sidebar filters.
                        $.patch(state).filters({ limits });
                    },
                    /**
                     * Access keyed limits filter axis.
                     */
                    get temp() {
                        return {
                            /**
                             * Assign the limit filters explicitly.
                             * @param {Readonly<IAxisRangeFilter>} payload
                             */
                            filter: (payload) => {
                                // Copy the payload values.
                                const temp = LimitFilter.temp(payload);
                                // Patch the limit filter record.
                                $.patch(state).limits.record({ temp });
                            },
                            /**
                             * Assign the lower and upper values explicitly.
                             * @param {Readonly<IAxisRange>} payload
                             */
                            extents: (payload) => {
                                // Copy the payload values.
                                const extents = AxisRange.clone(payload);
                                // Patch the temperature limit filter.
                                $.patch(state).limits.temp(extents);
                            },
                            /**
                             * Assign the lower value explicitly.
                             * @param {Readonly<IAxisRange['lower']>} payload
                             */
                            lower: (payload) => {
                                // Patch the temperature limit filter.
                                $.patch(state).limits.temp({ lower: payload });
                            },
                            /**
                             * Assign the upper value explicitly.
                             * @param {Readonly<IAxisRange['upper']>} payload
                             */
                            upper: (payload) => {
                                // Patch the temperature limit filter.
                                $.patch(state).limits.temp({ upper: payload });
                            },
                            /**
                             * Assign the modifier value explicitly.
                             * @param {Readonly<Boolean>} payload
                             */
                            modifier: (payload) => {
                                // Patch the temperature limit filter.
                                $.patch(state).limits.temp({
                                    checked: payload,
                                });
                            },
                        };
                    },
                    /**
                     * Access keyed limits filter axis.
                     */
                    get rh() {
                        return {
                            /**
                             * Assign the limit filters explicitly.
                             * @param {Readonly<IAxisRangeFilter>} payload
                             */
                            filter: (payload) => {
                                // Copy the payload values.
                                const rh = LimitFilter.rh(payload);
                                // Patch the limit filter record.
                                $.patch(state).limits.record({ rh });
                            },
                            /**
                             * Assign the lower and upper values explicitly.
                             * @param {Readonly<IAxisRange>} payload
                             */
                            extents: (payload) => {
                                // Copy the payload values.
                                const extents = AxisRange.clone(payload);
                                // Patch the limit filter.
                                $.patch(state).limits.rh(extents);
                            },
                            /**
                             * Assign the lower value explicitly.
                             * @param {Readonly<IAxisRange['lower']>} payload
                             */
                            lower: (payload) => {
                                // Patch the limit filter.
                                $.patch(state).limits.rh({ lower: payload });
                            },
                            /**
                             * Assign the upper value explicitly.
                             * @param {Readonly<IAxisRange['upper']>} payload
                             */
                            upper: (payload) => {
                                // Patch the limit filter.
                                $.patch(state).limits.rh({ upper: payload });
                            },
                            /**
                             * Assign the modifier value explicitly.
                             * @param {Readonly<Boolean>} payload
                             */
                            modifier: (payload) => {
                                // Patch the limit filter.
                                $.patch(state).limits.rh({
                                    checked: payload,
                                });
                            },
                        };
                    },
                    /**
                     * Access keyed limits filter axis.
                     */
                    get dp() {
                        return {
                            /**
                             * Assign the limit filters explicitly.
                             * @param {Readonly<IAxisRangeFilter>} payload
                             */
                            filter: (payload) => {
                                // Copy the payload values.
                                const dp = LimitFilter.dp(payload);
                                // Patch the limit filter record.
                                $.patch(state).limits.record({ dp });
                            },
                            /**
                             * Assign the lower and upper values explicitly.
                             * @param {Readonly<IAxisRange>} payload
                             */
                            extents: (payload) => {
                                // Copy the payload values.
                                const extents = AxisRange.clone(payload);
                                // Patch the limit filter.
                                $.patch(state).limits.dp(extents);
                            },
                            /**
                             * Assign the lower value explicitly.
                             * @param {Readonly<IAxisRange['lower']>} payload
                             */
                            lower: (payload) => {
                                // Patch the limit filter.
                                $.patch(state).limits.dp({ lower: payload });
                            },
                            /**
                             * Assign the upper value explicitly.
                             * @param {Readonly<IAxisRange['upper']>} payload
                             */
                            upper: (payload) => {
                                // Patch the limit filter.
                                $.patch(state).limits.dp({ upper: payload });
                            },
                            /**
                             * Assign the modifier value explicitly.
                             * @param {Readonly<Boolean>} payload
                             */
                            modifier: (payload) => {
                                // Patch the limit filter.
                                $.patch(state).limits.dp({
                                    checked: payload,
                                });
                            },
                        };
                    },
                };
            },
            /**
             * Access to scales filter setters.
             */
            get scales() {
                return {
                    /**
                     * Assign the scale filters explicitly.
                     * @param {Readonly<IScaleFilterRecord>} payload
                     */
                    record: (payload) => {
                        // Copy the payload values.
                        const scales = ScaleFilterRecord.clone(payload);
                        // Patch the sidebar filters.
                        $.patch(state).filters({ scales });
                    },
                    /**
                     * Access keyed scales filter axis.
                     */
                    get temp() {
                        return {
                            /**
                             * Assign the scale filters explicitly.
                             * @param {Readonly<IAxisRangeFilter>} payload
                             */
                            filter: (payload) => {
                                // Copy the payload values.
                                const temp = ScaleFilter.temp(payload);
                                // Patch the scale filter record.
                                $.patch(state).scales.record({ temp });
                            },
                            /**
                             * Assign the lower and upper values explicitly.
                             * @param {Readonly<IAxisRange>} payload
                             */
                            extents: (payload) => {
                                // Copy the payload values.
                                const extents = AxisRange.clone(payload);
                                // Patch the temperature scale filter.
                                $.patch(state).scales.temp(extents);
                            },
                            /**
                             * Assign the lower value explicitly.
                             * @param {Readonly<IAxisRange['lower']>} payload
                             */
                            lower: (payload) => {
                                // Patch the temperature scale filter.
                                $.patch(state).scales.temp({ lower: payload });
                            },
                            /**
                             * Assign the upper value explicitly.
                             * @param {Readonly<IAxisRange['upper']>} payload
                             */
                            upper: (payload) => {
                                // Patch the temperature scale filter.
                                $.patch(state).scales.temp({ upper: payload });
                            },
                            /**
                             * Assign the modifier value explicitly.
                             * @param {Readonly<Boolean>} payload
                             */
                            modifier: (payload) => {
                                // Patch the temperature scale filter.
                                $.patch(state).scales.temp({
                                    checked: payload,
                                });
                            },
                        };
                    },
                    /**
                     * Access keyed scales filter axis.
                     */
                    get rh() {
                        return {
                            /**
                             * Assign the scale filters explicitly.
                             * @param {Readonly<IAxisRangeFilter>} payload
                             */
                            filter: (payload) => {
                                // Copy the payload values.
                                const rh = ScaleFilter.rh(payload);
                                // Patch the scale filter record.
                                $.patch(state).scales.record({ rh });
                            },
                            /**
                             * Assign the lower and upper values explicitly.
                             * @param {Readonly<IAxisRange>} payload
                             */
                            extents: (payload) => {
                                // Copy the payload values.
                                const extents = AxisRange.clone(payload);
                                // Patch the scale filter.
                                $.patch(state).scales.rh(extents);
                            },
                            /**
                             * Assign the lower value explicitly.
                             * @param {Readonly<IAxisRange['lower']>} payload
                             */
                            lower: (payload) => {
                                // Patch the scale filter.
                                $.patch(state).scales.rh({ lower: payload });
                            },
                            /**
                             * Assign the upper value explicitly.
                             * @param {Readonly<IAxisRange['upper']>} payload
                             */
                            upper: (payload) => {
                                // Patch the scale filter.
                                $.patch(state).scales.rh({ upper: payload });
                            },
                            /**
                             * Assign the modifier value explicitly.
                             * @param {Readonly<Boolean>} payload
                             */
                            modifier: (payload) => {
                                // Patch the scale filter.
                                $.patch(state).scales.rh({
                                    checked: payload,
                                });
                            },
                        };
                    },
                    /**
                     * Access keyed scales filter axis.
                     */
                    get dp() {
                        return {
                            /**
                             * Assign the scale filters explicitly.
                             * @param {Readonly<IAxisRangeFilter>} payload
                             */
                            filter: (payload) => {
                                // Copy the payload values.
                                const dp = ScaleFilter.dp(payload);
                                // Patch the scale filter record.
                                $.patch(state).scales.record({ dp });
                            },
                            /**
                             * Assign the lower and upper values explicitly.
                             * @param {Readonly<IAxisRange>} payload
                             */
                            extents: (payload) => {
                                // Copy the payload values.
                                const extents = AxisRange.clone(payload);
                                // Patch the scale filter.
                                $.patch(state).scales.dp(extents);
                            },
                            /**
                             * Assign the lower value explicitly.
                             * @param {Readonly<IAxisRange['lower']>} payload
                             */
                            lower: (payload) => {
                                // Patch the scale filter.
                                $.patch(state).scales.dp({ lower: payload });
                            },
                            /**
                             * Assign the upper value explicitly.
                             * @param {Readonly<IAxisRange['upper']>} payload
                             */
                            upper: (payload) => {
                                // Patch the scale filter.
                                $.patch(state).scales.dp({ upper: payload });
                            },
                            /**
                             * Assign the modifier value explicitly.
                             * @param {Readonly<Boolean>} payload
                             */
                            modifier: (payload) => {
                                // Patch the scale filter.
                                $.patch(state).scales.dp({
                                    checked: payload,
                                });
                            },
                        };
                    },
                };
            },
            /**
             * Access to locations filter setters.
             */
            get locations() {
                return {
                    /**
                     * Set the tree explicitly.
                     * @param {Readonly<Treeview.Tree>} payload
                     */
                    tree: (payload) => {
                        // Copy the payload values.
                        const locations = LocationFilter.create(payload);
                        // Patch the analysis sidebar filter.
                        $.patch(state).filters({ locations });
                    },
                };
            },
            /**
             * Access to weather stations filter setters.
             */
            get stations() {
                return {
                    /**
                     * Assign the treeview filter explicitly.
                     * @param {Readonly<Treeview.Tree>} payload
                     */
                    tree: (payload) => {
                        // Copy the payload values.
                        const stations = WeatherStationFilter.create(payload);
                        // Patch the analysis sidebar filter.
                        $.patch(state).filters({ stations });
                    },
                };
            },
        };
    }

    /**
     * Provide access to state update/merging operations.
     * @param {AnalysisState} state State instance.
     */
    static patch(state) {
        const $ = AnalysisStateMutations;
        return {
            /**
             * Update existing filters.
             * @param {Readonly<Partial<ISidebarFilterRecord>>} payload
             */
            filters: (payload) => {
                // Copy the original store values.
                const source = { ...state.filters };

                // Copy the provided payload values.
                const patch = { ...payload };

                // Create the patched limits object.
                const merged = Object.assign({}, source, patch);

                // Patch the filters.
                $.set(state).filters(merged);
            },
            /**
             * Access to date range filter patchers.
             */
            get daterange() {
                return {
                    /**
                     * Patch the date range filter.
                     * @param {Readonly<Partial<Filters.DateRange.Store.Data>>} payload
                     */
                    filter: (payload) => {
                        // Copy the original store values.
                        const source = DateRangeFilter.StoreDataModel.create(
                            state.filters.daterange
                        );

                        // Copy the provided payload values.
                        const patch = { ...payload };

                        // Create the merged patched filter.
                        const daterange = Object.assign({}, source, patch);

                        // Patch the filters record.
                        $.patch(state).filters({ daterange });
                    },
                    /**
                     * Patch the date range in the date range filter.
                     * @param {Readonly<Partial<NominalDateRange>>} payload
                     */
                    range: (payload) => {
                        // Copy the store values.
                        const { start, end } = { ...state.filters.daterange };

                        // Copy the provided payload values.
                        const patch = { ...payload };

                        // Create the patched range.
                        const range = DateRangeFilter.StoreDataModel.create(
                            Object.assign({}, { start, end }, patch)
                        );

                        // Patch the date range filter.
                        $.patch(state).daterange.filter(range);
                    },
                };
            },
            /**
             * Access to timezone filter patchers.
             */
            get timezone() {
                return {
                    /**
                     * Patch the timezone filter.
                     * @param {Readonly<Partial<import('@/utils/filters').ITimezoneFilter>>} payload
                     */
                    filter: (payload) => {
                        // Copy the original store values.
                        const source = TimezoneFilter.clone(
                            state.filters.timezone
                        );

                        // Copy the provided payload values.
                        const patch = { ...payload };

                        // Create the merged patched filter.
                        const timezone = Object.assign({}, source, patch);

                        // Patch the filters record.
                        $.patch(state).filters({ timezone });
                    },
                };
            },
            /**
             * Access to limits filter patchers.
             */
            get limits() {
                return {
                    /**
                     * Patch the limit filter record.
                     * @param {Readonly<Partial<ILimitFilterRecord>>} [payload]
                     */
                    record: (payload = {}) => {
                        // Copy the original store values.
                        const source = LimitFilterRecord.clone(
                            state.filters.limits
                        );

                        // Copy the provided payload values.
                        const patch = { ...payload };

                        // Create the patched limits object.
                        const limits = Object.assign({}, source, patch);

                        // Patch the filters.
                        $.patch(state).filters({ limits });
                    },
                    /**
                     * Patch the limit filter.
                     * @param {Readonly<Partial<IAxisRangeFilter>>} payload
                     */
                    temp: (payload) => {
                        // Copy the original store values.
                        const source = LimitFilter.clone(
                            state.filters.limits.temp
                        );

                        // Copy the payload.
                        const patch = { ...payload };

                        // Create the patched limits object.
                        const temp = Object.assign({}, source, patch);

                        // Patch the limit filter record.
                        $.patch(state).limits.record({ temp });
                    },
                    /**
                     * Patch the limit filter.
                     * @param {Readonly<Partial<IAxisRangeFilter>>} payload
                     */
                    rh: (payload) => {
                        // Copy the original store values.
                        const source = LimitFilter.clone(
                            state.filters.limits.rh
                        );

                        // Copy the payload.
                        const patch = { ...payload };

                        // Create the patched limits object.
                        const rh = Object.assign({}, source, patch);

                        // Patch the limit filter record.
                        $.patch(state).limits.record({ rh });
                    },
                    /**
                     * Patch the limit filter.
                     * @param {Readonly<Partial<IAxisRangeFilter>>} payload
                     */
                    dp: (payload) => {
                        // Copy the original store values.
                        const source = LimitFilter.clone(
                            state.filters.limits.dp
                        );

                        // Copy the payload.
                        const patch = { ...payload };

                        // Create the patched limits object.
                        const dp = Object.assign({}, source, patch);

                        // Patch the limit filter record.
                        $.patch(state).limits.record({ dp });
                    },
                };
            },
            /**
             * Access to scales filter patchers.
             */
            get scales() {
                return {
                    /**
                     * Patch the scale filter record.
                     * @param {Readonly<Partial<IScaleFilterRecord>>} [payload]
                     */
                    record: (payload = {}) => {
                        // Copy the original store values.
                        const source = ScaleFilterRecord.clone(
                            state.filters.scales
                        );

                        // Copy the provided payload values.
                        const patch = { ...payload };

                        // Create the patched scales object.
                        const scales = Object.assign({}, source, patch);

                        // Patch the filters.
                        $.patch(state).filters({ scales });
                    },
                    /**
                     * Patch the scale filter.
                     * @param {Readonly<Partial<IAxisRangeFilter>>} payload
                     */
                    temp: (payload) => {
                        // Copy the original store values.
                        const source = ScaleFilter.clone(
                            state.filters.scales.temp
                        );

                        // Copy the payload.
                        const patch = { ...payload };

                        // Create the patched scales object.
                        const temp = Object.assign({}, source, patch);

                        // Patch the scale filter record.
                        $.patch(state).scales.record({ temp });
                    },
                    /**
                     * Patch the scale filter.
                     * @param {Readonly<Partial<IAxisRangeFilter>>} payload
                     */
                    rh: (payload) => {
                        // Copy the original store values.
                        const source = ScaleFilter.clone(
                            state.filters.scales.rh
                        );

                        // Copy the payload.
                        const patch = { ...payload };

                        // Create the patched scales object.
                        const rh = Object.assign({}, source, patch);

                        // Patch the scale filter record.
                        $.patch(state).scales.record({ rh });
                    },
                    /**
                     * Patch the scale filter.
                     * @param {Readonly<Partial<IAxisRangeFilter>>} payload
                     */
                    dp: (payload) => {
                        // Copy the original store values.
                        const source = ScaleFilter.clone(
                            state.filters.scales.dp
                        );

                        // Copy the payload.
                        const patch = { ...payload };

                        // Create the patched scales object.
                        const dp = Object.assign({}, source, patch);

                        // Patch the scale filter record.
                        $.patch(state).scales.record({ dp });
                    },
                };
            },
            /**
             * Access to the location filter patchers.
             */
            get locations() {
                return {
                    /**
                     * Patch the existing location filter tree.
                     * @param {Readonly<Treeview.TreeProps>} payload
                     */
                    tree: (payload) => {
                        // GET current tree.
                        const current = state.filters.locations.tree;
                        const update = Tree.override(current, payload);

                        // UPDATE location filter.
                        const locations = LocationFilter.create(update);

                        // PATCH the analysis sidebar filter.
                        $.patch(state).filters({ locations });
                    },
                };
            },
            /**
             * Access to the weather station filter patchers.
             */
            get stations() {
                return {
                    /**
                     * Patch the existing weather station filter tree.
                     * @param {Readonly<Treeview.TreeProps>} payload
                     */
                    tree: (payload) => {
                        // GET current tree.
                        const current = state.filters.stations.tree;
                        const update = Tree.override(current, payload);

                        // UPDATE weather station filter.
                        const stations = WeatherStationFilter.create(update);

                        // PATCH the analysis sidebar filter.
                        $.patch(state).filters({ stations });
                    },
                };
            },
        };
    }

    /**
     * Provide access to state clear operations.
     * // TODO: Add other filter clearer mutations.
     * @param {AnalysisState} state State instance.
     */
    static clear(state) {
        const $ = AnalysisStateMutations;
        return {
            /**
             * Clear all filters.
             */
            filters: () => {
                $.clear(state).daterange.range();
                $.clear(state).limits.record();
                $.clear(state).scales.record();
                $.clear(state).locations.tree();
                $.clear(state).stations.tree();
            },
            /**
             * Access to date range filter clearers.
             */
            get daterange() {
                return {
                    /**
                     * Clear the filter.
                     */
                    filter: () => {
                        // Select the timezone.
                        const timezone =
                            state.filters.timezone?.timezone ?? 'UTC';

                        // Find the start and end dates using the 'UTC' by default.
                        const { start, end } =
                            DateRangeFilter.StoreDataModel.computePresetDateRange(
                                'P1Y0S',
                                new Date(),
                                timezone
                            );

                        // Prepare timezone-aware date formatter. eg. YYYY-MM-DD
                        const dateFormatter = new Intl.DateTimeFormat('en-CA', {
                            hour12: false,
                            timeZone: timezone,
                            dateStyle: 'short',
                        });

                        // Instantiate a date range filter instance.
                        const empty = DateRangeFilter.StoreDataModel.create({
                            start: dateFormatter.format(start),
                            end: dateFormatter.format(end),
                        });

                        // Initialize the date range filter.
                        $.set(state).daterange.filter(empty);
                    },
                    /**
                     * Clear the date range strings.
                     */
                    range: () => {
                        // Select the timezone.
                        const timezone =
                            state.filters.timezone?.timezone ?? 'UTC';

                        // Find the start and end dates using the 'UTC' by default.
                        const { start, end } =
                            DateRangeFilter.StoreDataModel.computePresetDateRange(
                                'P1Y0S',
                                new Date(),
                                timezone
                            );

                        // Prepare timezone-aware date formatter. eg. YYYY-MM-DD
                        const dateFormatter = new Intl.DateTimeFormat('en-CA', {
                            hour12: false,
                            timeZone: timezone,
                            dateStyle: 'short',
                        });

                        // Initialize the date range filter dates.
                        $.set(state).daterange.range({
                            start: dateFormatter.format(start),
                            end: dateFormatter.format(end),
                        });
                    },
                    /**
                     * Clear the modifiers.
                     */
                    modifiers: () => {
                        $.set(state).daterange.modifiers([]);
                    },
                };
            },
            get limits() {
                return {
                    /**
                     * Clear the entire record.
                     */
                    record: () => {
                        const record = LimitFilterRecord.create([]);
                        $.set(state).limits.record(record);
                    },
                    /**
                     * Clear the specified filter.
                     */
                    temp: () => {
                        const filter = LimitFilter.temp({});
                        $.set(state).limits.temp.filter(filter);
                    },
                    /**
                     * Clear the specified filter.
                     */
                    rh: () => {
                        const filter = LimitFilter.rh({});
                        $.set(state).limits.rh.filter(filter);
                    },
                    /**
                     * Clear the specified filter.
                     */
                    dp: () => {
                        const filter = LimitFilter.dp({});
                        $.set(state).limits.dp.filter(filter);
                    },
                };
            },
            get scales() {
                return {
                    /**
                     * Clear the entire record.
                     */
                    record: () => {
                        const record = ScaleFilterRecord.create([]);
                        $.set(state).scales.record(record);
                    },
                    /**
                     * Clear the specified filter.
                     */
                    temp: () => {
                        const filter = ScaleFilter.temp({});
                        $.set(state).scales.temp.filter(filter);
                    },
                    /**
                     * Clear the specified filter.
                     */
                    rh: () => {
                        const filter = ScaleFilter.rh({});
                        $.set(state).scales.rh.filter(filter);
                    },
                    /**
                     * Clear the specified filter.
                     */
                    dp: () => {
                        const filter = ScaleFilter.dp({});
                        $.set(state).scales.dp.filter(filter);
                    },
                };
            },
            get locations() {
                return {
                    /**
                     * Clear the entire filter.
                     */
                    tree: () => {
                        const tree = Tree.create({});
                        $.set(state).locations.tree(tree);
                    },
                };
            },
            get stations() {
                return {
                    /**
                     * Clear the entire filter.
                     */
                    tree: () => {
                        const tree = Tree.create({});
                        $.set(state).stations.tree(tree);
                    },
                };
            },
        };
    }

    // <!-- VUEX MUTATIONS -->

    /**
     * Access mutations for the {@link ISidebarFilterRecord}.
     */
    static get sidebar() {
        /** Alias for the class. */
        const $ = AnalysisStateMutations;

        /**
         * Apply setter mutation.
         * @param {AnalysisState} state
         * @param {Readonly<ISidebarFilterRecord>} payload
         */
        const setFilters = (state, payload) => {
            $.set(state).filters(payload);
        };

        /**
         * Apply patcher mutation.
         * @param {AnalysisState} state
         * @param {Readonly<Partial<ISidebarFilterRecord>>} payload
         */
        const patchFilters = (state, payload) => {
            $.patch(state).filters(payload);
        };

        /**
         * Apply clear mutation.
         * @param {AnalysisState} state
         */
        const clearFilters = (state) => {
            $.clear(state).filters();
        };

        return {
            setFilters,
            patchFilters,
            clearFilters,
        };
    }

    /**
     * Access mutations for the {@link DateRangeFilter} modules.
     */
    static get daterange() {
        /** Alias for the class. */
        const $ = AnalysisStateMutations;

        // <!-- DATE RANGE -->

        /**
         * Set the date range.
         * @type {Filters.DateRange.Store.DateRangeMutation}
         */
        const setDateRange = (state, payload) => {
            $.set(state).daterange.range(payload);
        };

        /**
         * Apply patcher mutation.
         * @param {AnalysisState} state
         * @param {Readonly<Partial<NominalDateRange>>} payload
         */
        const patchDateRange = (state, payload) => {
            $.patch(state).daterange.range(payload);
        };

        /**
         * Apply clear mutation.
         * @param {AnalysisState} state
         */
        const clearDateRange = (state) => {
            $.clear(state).daterange.range();
        };

        /**
         * Apply setter mutation.
         * @param {AnalysisState} state
         * @param {string} payload
         */
        const setStartDate = (state, payload) => {
            $.set(state).daterange.start(payload);
        };

        /**
         * Apply clear mutation.
         * @param {AnalysisState} state
         */
        const clearStartDate = (state) => {
            $.clear(state).daterange.start();
        };

        /**
         * Apply setter mutation.
         * @param {AnalysisState} state
         * @param {string} payload
         */
        const setEndDate = (state, payload) => {
            $.set(state).daterange.end(payload);
        };

        /**
         * Apply clear mutation.
         * @param {AnalysisState} state
         */
        const clearEndDate = (state) => {
            $.clear(state).daterange.end();
        };

        // <!-- MODIFIER -->

        /**
         * Set date range filter modifiers.
         * @type {Filters.DateRange.Store.DateRangeModifierListMutation}
         */
        const setDateRangeModifiers = (state, payload) => {
            const checked = Array.from(payload);
            $.set(state).daterange.modifiers(checked);
            return;
        };

        /**
         * Apply clear mutation.
         * @param {AnalysisState} state
         */
        const clearDateRangeModifiers = (state) => {
            $.set(state).daterange.modifiers([]);
            return;
        };

        /**
         * Apply setter mutation.
         * @param {AnalysisState} state
         * @param {{ key: any, value: Boolean }} payload
         */
        const setDateRangeModifier = (state, payload) => {
            const { key, value } = payload;
            if (value === true) {
                const current = new Set(state.filters.daterange.checked);
                const withValue = [...current.add(key)];
                $.set(state).daterange.modifiers(withValue);
                return;
            }
            if (value === false) {
                const current = new Set(state.filters.daterange.checked);
                current.delete(key);
                const withoutValue = [...current];
                $.set(state).daterange.modifiers(withoutValue);
                return;
            }
        };

        /**
         * Apply clear mutation.
         * @param {AnalysisState} state
         * @param {Filters.DateRange.Modifier} payload
         */
        const clearDateRangeModifier = (state, payload) => {
            setDateRangeModifier(state, { key: payload, value: false });
        };

        return {
            setDateRange,
            patchDateRange,
            clearDateRange,

            setStartDate,
            clearStartDate,

            setEndDate,
            clearEndDate,

            setDateRangeModifiers,
            clearDateRangeModifiers,

            setDateRangeModifier,
            clearDateRangeModifier,
        };
    }

    /**
     * Access mutations for the {@link ITimezoneFilter}.
     */
    static get timezone() {
        /** Alias for the class. */
        const $ = AnalysisStateMutations;

        // <!-- TIMEZONE -->

        /**
         * Apply setter mutation.
         * @param {AnalysisState} state
         * @param {Readonly<TimeZone.Identifier>} payload
         */
        const setTimezone = (state, payload) => {
            $.set(state).timezone.timezone(payload);
        };

        // <!-- MODIFIER -->

        /**
         * Apply setter mutation.
         * @param {AnalysisState} state
         * @param {boolean} payload
         */
        const setInheritAccountTimezoneModifier = (state, payload) => {
            const value = payload === true;
            $.set(state).timezone.inheritAccountTimezoneModifier(value);
        };

        return {
            setTimezone,
            setInheritAccountTimezoneModifier,
        };
    }

    /**
     * Access mutations for the {@link ILimitFilterRecord}.
     */
    static get limits() {
        /** Alias for the class. */
        const $ = AnalysisStateMutations;

        /**
         * Apply setter mutation.
         * @param {AnalysisState} state
         * @param {Readonly<ILimitFilterRecord>} payload
         */
        const setLimits = (state, payload) => {
            $.set(state).limits.record(payload);
        };

        /**
         * Apply patcher mutation.
         * @param {AnalysisState} state
         * @param {Readonly<Partial<ILimitFilterRecord>>} payload
         */
        const patchLimits = (state, payload) => {
            $.patch(state).limits.record(payload);
        };

        /**
         * Apply clear mutation.
         * @param {AnalysisState} state
         */
        const clearLimits = (state) => {
            $.clear(state).limits.record();
        };

        return {
            setLimits,
            patchLimits,
            clearLimits,
        };
    }

    /**
     * Access mutations for the {@link IScaleFilterRecord}.
     */
    static get scales() {
        /** Alias for the class. */
        const $ = AnalysisStateMutations;

        /**
         * Apply setter mutation.
         * @param {AnalysisState} state
         * @param {Readonly<IScaleFilterRecord>} payload
         */
        const setScales = (state, payload) => {
            $.set(state).scales.record(payload);
        };

        /**
         * Apply patcher mutation.
         * @param {AnalysisState} state
         * @param {Readonly<Partial<IScaleFilterRecord>>} payload
         */
        const patchScales = (state, payload) => {
            $.patch(state).scales.record(payload);
        };

        /**
         * Apply clear mutation.
         * @param {AnalysisState} state
         */
        const clearScales = (state) => {
            $.clear(state).scales.record();
        };

        return {
            setScales,
            patchScales,
            clearScales,
        };
    }

    /**
     * Access mutations for the {@link ILocationFilter}.
     */
    static get locations() {
        /** Alias for the class. */
        const $ = AnalysisStateMutations;

        /**
         * Apply setter mutation.
         * @param {AnalysisState} state
         * @param {Readonly<Treeview.Tree>} payload
         */
        const setLocationTree = (state, payload) => {
            $.set(state).locations.tree(payload);
        };

        /**
         * Apply patcher mutation.
         * @param {AnalysisState} state
         * @param {Readonly<Treeview.TreeProps>} payload
         */
        const patchLocationTree = (state, payload) => {
            $.patch(state).locations.tree(payload);
        };

        /**
         * Apply clear mutation.
         * @param {AnalysisState} state
         */
        const clearLocationTree = (state) => {
            $.clear(state).locations.tree();
        };

        return {
            setLocationTree,
            patchLocationTree,
            clearLocationTree,
        };
    }

    /**
     * Access mutations for the {@link IWeatherStationFilter}.
     */
    static get stations() {
        /** Alias for the class. */
        const $ = AnalysisStateMutations;

        /**
         * Apply setter mutation.
         * @param {AnalysisState} state
         * @param {Readonly<Treeview.Tree>} payload
         */
        const setWeatherStationTree = (state, payload) => {
            $.set(state).stations.tree(payload);
        };

        /**
         * Apply patcher mutation.
         * @param {AnalysisState} state
         * @param {Readonly<Treeview.TreeProps>} payload
         */
        const patchWeatherStationTree = (state, payload) => {
            $.patch(state).stations.tree(payload);
        };

        /**
         * Apply clear mutation.
         * @param {AnalysisState} state
         */
        const clearWeatherStationTree = (state) => {
            $.clear(state).stations.tree();
        };

        return {
            setWeatherStationTree,
            patchWeatherStationTree,
            clearWeatherStationTree,
        };
    }
}
