// <!-- FORWARD EXPORTS -->
export { SubscriptionPayload } from '@/payloads/v2/subscriptions/SubscriptionPayload';
export { SubscriptionModel } from './SubscriptionModel';

// <!-- IMPORT -->
import { SubscriptionPayload } from '@/payloads/v2/subscriptions/SubscriptionPayload';
import { SubscriptionModel } from './SubscriptionModel';

// <!-- NAMESPACE EXPORTS -->
export const Subscription = {
    Payload: SubscriptionPayload,
    Model: SubscriptionModel,
};
