// <!-- UTILITY -->
import { Enum } from '@/utils/enums';

/** @type {Enum<StorageKeys> & StorageKeys} */
export const StorageKey = Enum.create({
    AccessToken: 'auth_token',
    RefreshToken: 'refresh_token',
    SelectedOrganization: 'selected_organization',
    SelectedAccount: 'selected_account',
});

export default StorageKey;
