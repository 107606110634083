// <!-- TYPES -->
import { UploadErrors } from '@/store/types/uploader/state';
import { UploadErrorGroupID } from '@/store/types/uploader/state/UploadErrors';

/**
 * Synchronous getters for a {@link UploadErrors} instance.
 */
export const UploadErrorsGetters = {
    /**
     * Get errors.
     * @param {UploadErrors} context
     */
    get: (context) => ({
        /**
         * Get map of all errors, by group.
         * @returns {Map<keyof UploadErrorGroupID, Set<String>>}
         */
        all: () => {
            return context.errors;
        },
        /**
         * Get the errors of a specific group.
         * @param {keyof UploadErrorGroupID} id
         * @returns {Set<String>}
         */
        group: (id) => {
            const key = UploadErrorGroupID[id] ?? null;
            return !!key ? context.errors.get(key) : null;
        },
    }),
    /**
     * Validate errors.
     * @param {UploadErrors} context
     */
    has: (context) => ({
        /**
         * Check if a group exists.
         * @param {keyof UploadErrorGroupID} id
         * @returns {Boolean} Returns true if the group exists.
         */
        group: (id) => {
            return !!id ? context.errors.has(id) : false;
        },
        /**
         * Check if a group exists with the specified error.
         * @param {keyof UploadErrorGroupID} id
         * @param {String} error
         * @returns {Boolean} Returns true if the group exists and the error exists in that group.
         */
        error: (id, error) => {
            if (UploadErrorsGetters.has(context).group(id)) {
                return context.errors.get(id).has(error);
            }
            // Error or group does not exist.
            return false;
        },
        /**
         * Check if a group exists with the specified error.
         * @param {keyof UploadErrorGroupID} id
         */
        includes: (id) => ({
            /**
             * Get count of all errors that are in the specified group.
             * @param  {...String} errors
             */
            count: (...errors) => {
                const current = context.errors.get(id) ?? new Set();
                const filtered = errors.filter((e) => current.has(e));
                return filtered.length;
            },
            /**
             * Return true if all of the specified errors are present in the mentioned group.
             * @param {...String} errors
             */
            all: (...errors) => {
                const current = context.errors.get(id) ?? new Set();
                const filtered = errors.filter((e) => current.has(e));
                return filtered.length === current.size;
            },
            /**
             * Return true if one or more of the specified errors are present in the mentioned group.
             * @param {...String} errors
             */
            some: (...errors) => {
                const current = context.errors.get(id) ?? new Set();
                const filtered = errors.filter((e) => current.has(e));
                return filtered.length > 0;
            },
            /**
             * Return true if zero of the specified errors are present in the mentioned group.
             * @param {...String} errors
             */
            none: (...errors) => {
                const current = context.errors.get(id) ?? new Set();
                const filtered = errors.filter((e) => current.has(e));
                return current.size === 0 || filtered.length === 0;
            },
        }),
    }),
};
