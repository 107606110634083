// <!-- PLUGINS -->
import { useAxios as axios } from '@/plugins/axios';

// <!-- TYPES -->
/** @typedef {import('@/models/v1/standards/NARAStandard').NARAStandardPayload} NARAStandardPayload */
/** @typedef {import('@/models/v1/standards/NARAStandard').NARAStandardResource} NARAStandardResource */

// <!-- MODELS -->
import { NARAStandard } from '@/models/v1/standards';

// <!-- ROUTES -->
const ROUTES = {
    // <!-- CRUD -->
    CREATE_NARA_STANDARD: () => `nara/standards`,
    LIST_NARA_STANDARD: () => 'nara/standards',
    GET_NARA_STANDARD: (standard) => `nara/standards/${standard}`,
    UPDATE_NARA_STANDARD: (standard) => `nara/standards/${standard}`,
    DELETE_NARA_STANDARD: (standard) => `nara/standards/${standard}`,
};

// <!-- REQUESTS -->

/**
 * Fetch list of nara standards
 * @returns {Promise<NARAStandardResource[]>}
 */
export const fetchNARAStandards = async () => {
    /** @type {import('axios').AxiosResponse<{ data: NARAStandardPayload[] }>} */
    const response = await axios().get(ROUTES.LIST_NARA_STANDARD());
    const collection = response.data.data;
    return collection.map((payload) => {
        let standard = { ...payload };
        standard.min_temp = Math.round(payload.min_temp);
        standard.max_temp = Math.round(payload.max_temp);
        return new NARAStandard({
            payload: standard,
        }).toResource();
    });
};

/**
 * Fetch accounts associated with the specified user.
 * @param {Pick<NARAStandardPayload, 'id'>} standard
 * @returns {Promise<NARAStandardPayload>}
 */
export const fetchNARAStandardById = async (standard = { id: 9800 }) => {
    /** @type {import('axios').AxiosResponse<{ user: NARAStandardPayload }>} */
    const response = await axios().get(ROUTES.GET_NARA_STANDARD(standard.id));
    const payload = response.data.user;
    return new NARAStandard({ payload }).toResource();
};

/**
 * Create new user using request body content.
 * @param {Partial<Omit<NARAStandardPayload, 'id'>>} request
 */
export const createNARAStandard = async (request) => {
    /** @type {import('axios').AxiosResponse<import('..').StatusResponse<{ standard: NARAStandardPayload }>, NARAStandardPayload>} */
    // Apply rounding.
    request.min_temp = Math.round(request.min_temp);
    request.max_temp = Math.round(request.max_temp);
    const response = await axios().post(ROUTES.CREATE_NARA_STANDARD(), request);
    const { status, ...payload } = response.data.standard;
    return new NARAStandard({ payload }).toResource();
};

/**
 * Update the username, email, or admin status for the specified user.
 * @param {Pick<NARAStandardResource, 'id'>} standard
 * @param {{ }} request
 */
export const updateNARAStandardById = async (
    standard = { id: null },
    request = {
        // TODO
    }
) => {
    /** @type {import('axios').AxiosResponse<{ standard: NARAStandardPayload }, { USER_NAME: String, email: String, USER_ADMIN_YN: "Y" | "N" }>} */
    const response = await axios().put(
        ROUTES.UPDATE_NARA_STANDARD(standard.id),
        request
    );
    const payload = response.data.standard;
    return new NARAStandard({ payload }).toResource();
};

/**
 * Delete the specified nara standard.
 * @param {Pick<NARAStandardResource, 'id'>} standard
 */
export const deleteNARAStandardById = async (standard = { id: null }) => {
    /** @type {import('axios').AxiosResponse<{ status: String | Number }>} */
    const response = await axios().delete(
        ROUTES.DELETE_NARA_STANDARD(standard.id)
    );
    const { status } = response.data;
    return { status };
};

// <!-- EXPORTS -->
export default {
    NARAStandard,
    fetchNARAStandards,
    fetchNARAStandardById,
    createNARAStandard,
    updateNARAStandardById,
    deleteNARAStandardById,
};
