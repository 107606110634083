// <!-- API -->
import {
    LimitFilterRecord,
    ScaleFilterRecord,
    LocationFilter,
    WeatherStationFilter,
    TimezoneFilter,
} from '@/utils/filters';

// <!-- UTILITIES -->
import is from '@sindresorhus/is';
import { Tree, NodeRecord } from '@/utils/tree';

// <!-- TYPES -->
import { ECNBState } from '@/store/types/ECNBStore';
import AnalysisState from '@/store/types/analysis/state';
import { sub } from 'date-fns';
/** @template [S=any] @template [R=any] @typedef {import('vuex').ActionContext<S,R>} ActionContext<S,R> */
/** @typedef {import('@/utils/filters').ILimitFilterRecord} ILimitFilterRecord */
/** @typedef {import('@/utils/filters').IScaleFilterRecord} IScaleFilterRecord */
/** @typedef {import('@/utils/filters').ISidebarFilterRecord} ISidebarFilterRecord */
/** @typedef {import('@/utils/filters').ILocationFilter} ILocationFilter */
/** @typedef {import('@/utils/filters').IWeatherStationFilter} IWeatherStationFilter */

/**
 * @class
 * Actions for the analysis state.
 */
export class AnalysisStateActions {
    /**
     * Access actions for the {@link Filters.DateRange.StoreDataModel}.
     */
    static get daterange() {
        return {
            /**
             * Dispatchable action for updating the date range filter state.
             * - Assume payload has already been validated.
             *
             * ```
             * // Example usage:
             * store.dispatch("analysis/saveDateRangeFilter", data);
             * ```
             * @type {Filters.DateRange.Store.SnapshotAction}
             */
            saveDateRangeFilter: async ({ state, commit }, payload) => {
                // Extract the relevant properties.
                const { start, end } = payload;
                const checked = Array.from(payload.checked);

                // Commit the date range components.
                commit('setDateRange', { start, end });
                // Commit the date range modifiers.
                commit('setDateRangeModifiers', checked);

                // Return the store data.
                return state.filters.daterange.clone();
            },
            /**
             * Dispatchable action for updating the date range filter state.
             * - Assume payload has already been validated.
             *
             * ```
             * // Example usage:
             * store.dispatch("analysis/useDateRangePreset", option);
             * ```
             * @type {Filters.DateRange.Store.UsePresetAction}
             */
            useDateRangePreset: async (
                { state, rootState, commit },
                payload
            ) => {
                // Extract the relevant properties.
                const { timestamp, duration } = payload;

                // Find the reference instance of time.
                const referenceTime = new Date(timestamp);

                // Find the reference timezone.
                const customTimezone =
                    state?.filters?.timezone?.timezone ?? 'UTC';
                const accountTimezone =
                    rootState?.accounts?.account?.timezone ?? 'UTC';
                const useAccountTimezone =
                    state?.filters?.timezone?.useAccountTimezone === true;
                const referenceTimezone = useAccountTimezone
                    ? accountTimezone
                    : customTimezone;

                // Create the appropriate date formatter.
                const dateFormatter = new Intl.DateTimeFormat('en-CA', {
                    timeZone: referenceTimezone,
                });

                // Compute the fixed date range.
                const endDate = referenceTime;
                const startDate = sub(endDate, duration);

                // Format the start and end dates.
                const start = dateFormatter.format(startDate);
                const end = dateFormatter.format(endDate);

                // Apply these changes via direct mutation.
                commit('setDateRange', { start, end });

                // Return the store data.
                return state.filters.daterange.clone();
            },
            /**
             * Dispatchable action for updating the date range filter state.
             * - Assume payload has already been validated.
             *
             * ```
             * // Example usage:
             * store.dispatch("analysis/useDateRange", option);
             * ```
             * @type {Filters.DateRange.Store.UseDateRangeAction}
             */
            useDateRange: async ({ state, commit }, payload) => {
                // Extract the relevant properties.
                const { start = '', end = '' } = payload;

                // Apply these changes via direct mutation.
                commit('setDateRange', { start, end });

                // Return the store data.
                return state.filters.daterange.clone();
            },
            /**
             * Dispatchable action for updating the date range modifier state.
             * - Assume payload has already been validated.
             *
             * ```
             * // Example usage:
             * store.dispatch("analysis/useDateRangeModifiers", option);
             * ```
             * @type {Filters.DateRange.Store.UseDateRangeModifiersAction}
             */
            useDateRangeModifiers: async ({ state, commit }, payload) => {
                // Extract the relevant properties.
                const checked = new Set(payload);

                // Apply these changes via direct mutation.
                commit('setDateRangeModifiers', checked);

                // Return the store data.
                return state.filters.daterange.clone();
            },
        };
    }
    /**
     * Access actions for the {@link ITimezoneFilter}.
     */
    static get timezone() {
        return {
            /**
             * Update the timezone field.
             * @param {import('vuex').ActionContext<AnalysisState, ECNBState>} context Action context.
             * @param {Readonly<TimeZone.Identifier>} payload Action payload.
             * @returns {Promise<SidebarFilter.TimezoneFilterFormData>}
             */
            assignTimezone: async ({ state, commit }, payload) => {
                // <!-- DESTRUCTURE -->
                const timezone = payload ?? 'UTC';

                // <!-- COMMIT -->
                commit('setTimezone', timezone);

                // Return updated filter.
                return TimezoneFilter.clone(state.filters.timezone);
            },
            /**
             * Update the account timezone modifier.
             * @param {import('vuex').ActionContext<AnalysisState, ECNBState>} context Action context.
             * @param {Readonly<boolean>} payload Action payload.
             * @returns {Promise<SidebarFilter.TimezoneFilterFormData>}
             */
            assignInheritAccountTimezoneModifier: async (
                { state, commit },
                payload
            ) => {
                // <!-- DESTRUCTURE -->
                const checked = payload === true;

                // <!-- COMMIT -->
                commit('setInheritAccountTimezoneModifier', checked);

                // Return updated filter.
                return TimezoneFilter.clone(state.filters.timezone);
            },
        };
    }
    /**
     * Access actions for the {@link IScaleFilterRecord}.
     */
    static get limits() {
        return {
            /**
             * Update the limit record.
             * @param {import('vuex').ActionContext<AnalysisState, ECNBState>} context Action context.
             * @param {Readonly<Partial<ILimitFilterRecord>>} payload Action payload.
             * @returns {Promise<ILimitFilterRecord>}
             */
            assignLimitRecord: async ({ state, commit }, payload) => {
                if (!is.nullOrUndefined(payload)) {
                    commit('patchLimits', payload);
                }
                // Return potentially updated limits.
                return LimitFilterRecord.clone(state.filters.limits);
            },
        };
    }
    /**
     * Access actions for the {@link IScaleFilterRecord}.
     */
    static get scales() {
        return {
            /**
             * Update scale record.
             * @param {import('vuex').ActionContext<AnalysisState, ECNBState>} context Action context.
             * @param {Readonly<Partial<IScaleFilterRecord>>} payload Action payload.
             * @returns {Promise<IScaleFilterRecord>}
             */
            assignScaleRecord: async ({ state, commit }, payload) => {
                if (!is.nullOrUndefined(payload)) {
                    commit('patchScales', payload);
                }
                // Return potentially updated scales.
                return ScaleFilterRecord.clone(state.filters.scales);
            },
        };
    }
    /**
     * Access actions for the {@link ILocationFilter}.
     */
    static get locations() {
        return {
            /**
             * Notify location tree of the specified payload.
             * @param {import('vuex').ActionContext<AnalysisState, ECNBState>} context Action context.
             * @param {any} payload Action payload.
             * @returns {Promise<any>}
             */
            notifyLocationTree: async ({ state }, payload) => {
                if (!is.nullOrUndefined(payload)) {
                    console.log(`[notify::locations]`, payload);
                }
                // Do nothing.
            },
            /**
             * Update location filter.
             * @param {import('vuex').ActionContext<AnalysisState, ECNBState>} context Action context.
             * @param {Readonly<Treeview.TreeProps>} payload Action payload.
             * @returns {Promise<ILocationFilter>}
             */
            assignLocationTree: async ({ state, commit }, payload) => {
                if (!is.nullOrUndefined(payload)) {
                    commit('patchLocationTree', payload);
                }
                // Return potentially updated tree.
                return LocationFilter.clone(state.filters.locations);
            },
            /**
             * Update location filter's checked locations. Removes unchecked locations as well.
             * @param {import('vuex').ActionContext<AnalysisState, ECNBState>} context Action context.
             * @param {Readonly<Treeview.Node[]>} payload
             * @returns {Promise<ILocationFilter>}
             */
            assignSelectedLocations: async ({ state, commit }, payload) => {
                if (!is.nullOrUndefined(payload)) {
                    const record = NodeRecord.fromCollection(payload);
                    const update = NodeRecord.override(
                        state.filters.locations.tree.nodes,
                        record
                    );
                    const tree = Tree.override(state.filters.locations.tree, {
                        nodes: update,
                    });
                    commit('setLocationTree', tree);
                }
                // Return potentially updated tree.
                return LocationFilter.clone(state.filters.locations);
            },
            /**
             * Update location filter's opened locations. Removes closed locations as well.
             * @param {import('vuex').ActionContext<AnalysisState, ECNBState>} context Action context.
             * @param {Readonly<Treeview.Node[]>} payload
             * @returns {Promise<ILocationFilter>}
             */
            assignOpenedLocations: async ({ state, commit }, payload) => {
                if (!is.nullOrUndefined(payload)) {
                    const record = NodeRecord.fromCollection(payload);
                    const update = NodeRecord.override(
                        state.filters.locations.tree.nodes,
                        record
                    );
                    const tree = Tree.override(state.filters.locations.tree, {
                        nodes: update,
                    });
                    commit('setLocationTree', tree);
                }
                // Return potentially updated tree.
                return LocationFilter.clone(state.filters.locations);
            },
        };
    }
    /**
     * Access actions for the {@link IWeatherStationFilter}.
     */
    static get stations() {
        return {
            /**
             * Update weather station filter.
             * @param {import('vuex').ActionContext<AnalysisState, ECNBState>} context Action context.
             * @param {Readonly<Treeview.TreeProps>} payload Action payload.
             * @returns {Promise<IWeatherStationFilter>}
             */
            assignWeatherStationTree: async ({ state, commit }, payload) => {
                if (!is.nullOrUndefined(payload)) {
                    commit('patchWeatherStationTree', payload);
                }
                // Return potentially updated tree.
                return WeatherStationFilter.clone(state.filters.stations);
            },
            /**
             * Update weather station filter's checked stations. Removes unchecked stations as well.
             * @param {import('vuex').ActionContext<AnalysisState, ECNBState>} context Action context.
             * @param {Readonly<Treeview.Node[]>} payload
             * @returns {Promise<IWeatherStationFilter>}
             */
            assignSelectedWeatherStations: async (
                { state, commit },
                payload
            ) => {
                if (!is.nullOrUndefined(payload)) {
                    const record = NodeRecord.fromCollection(payload);
                    const update = NodeRecord.override(
                        state.filters.stations.tree.nodes,
                        record
                    );
                    const tree = Tree.override(state.filters.stations.tree, {
                        nodes: update,
                    });
                    commit('setWeatherStationTree', tree);
                }
                // Return potentially updated tree.
                return WeatherStationFilter.clone(state.filters.stations);
            },
            /**
             * Update weather station filter's opened stations. Removes closed stations as well.
             * @param {import('vuex').ActionContext<AnalysisState, ECNBState>} context Action context.
             * @param {Readonly<Treeview.Node[]>} payload
             * @returns {Promise<IWeatherStationFilter>}
             */
            assignOpenedWeatherStations: async ({ state, commit }, payload) => {
                if (!is.nullOrUndefined(payload)) {
                    const record = NodeRecord.fromCollection(payload);
                    const update = NodeRecord.override(
                        state.filters.stations.tree.nodes,
                        record
                    );
                    const tree = Tree.override(state.filters.stations.tree, {
                        nodes: update,
                    });
                    commit('setWeatherStationTree', tree);
                }
                // Return potentially updated tree.
                return WeatherStationFilter.clone(state.filters.stations);
            },
        };
    }
}

// <!-- DEFAULT -->
export default AnalysisStateActions;
