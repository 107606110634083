// <!-- API -->
import pick from 'just-pick';

// <!-- TYPES -->
/** @template [S=any] @typedef {import('vuex').Store<S>} Store<S> */
/** @template [S=any] @template [R=any] @typedef {import('vuex').Module<S,R>} Module<S,R> */
import { ECNBStore } from '@/store/types/ECNBStore';

// CLASS
/**
 * @class
 * Namespaced module behavior.
 */
export class ECNBModule {
    /**
     * The module namespace.
     */
    static get namespace() {
        return '';
    }

    /**
     * Module state, getters, mutations, and actions.
     * @type {import('vuex').Module}
     */
    static get module() {
        return {
            get namespaced() {
                return true;
            },
            get state() {
                return () => {};
            },
            get modules() {
                return {};
            },
            get getters() {
                return {};
            },
            get mutations() {
                return {};
            },
            get actions() {
                return {};
            },
        };
    }

    /**
     * Check information about the module.
     */
    static get has() {
        const $ = ECNBModule;
        return {
            /**
             * Check if type is a local module getter, mutation, or action.
             * @param {String} value
             */
            type: (value) => ({
                get getter() {
                    return new Set(Object.keys($.module.getters)).has(value);
                },
                get mutation() {
                    return new Set(Object.keys($.module.mutations)).has(value);
                },
                get action() {
                    return new Set(Object.keys($.module.actions)).has(value);
                },
            }),
        };
    }

    /**
     * Provided intellisense-typed getters, mutations, and actions before
     * invoking the mutation with the wrapped commit.
     *
     * @param {Partial<Pick<Store, 'state' | 'getters' | 'commit' | 'dispatch'>>} context
     */
    static use(context) {
        const $ = ECNBModule;
        const { state, getters, commit, dispatch } = context;
        return {
            context,
            /**
             * Get access to the state resolver.
             */
            get state() {
                if (state === undefined) {
                    throw new TypeError(`No state provided by the context.`);
                }
                return pick(
                    ECNBStore.resolve.state({ state }),
                    'computed',
                    'live'
                );
            },
            /**
             * Get access to the local getter resolver.
             * @param {String} type
             */
            getter: (type) => {
                if (getters === undefined) {
                    throw new TypeError(`No getters provided by the context.`);
                }
                if (!this.has.type(type).getter) {
                    throw new Error(
                        `Getter '${type}' does not exist on module [${this}].`
                    );
                }
                const $getter = pick(
                    ECNBStore.resolve.getter(type).asGlobal(),
                    'asFunction',
                    'asProperty'
                );
                return {
                    /**
                     * Evaluate getter as property, with no default value allowed.
                     */
                    get value() {
                        return $getter.asProperty(getters);
                    },
                    /**
                     * Evaluate getter as a function with the supplied payload.
                     * @template {any} [V=any]
                     * @param {any} payload
                     * @param {V} [defaultValue] Optional default value.
                     * @returns {V}
                     */
                    asFunction: (payload, defaultValue) =>
                        $getter.asFunction(getters, payload, defaultValue),
                    /**
                     * Evaluate getter as a property, with a default value provided when the property is undefined.
                     * @template {any} [V=any]
                     * @param {V} [defaultValue] Optional default value.
                     * @returns {V}
                     */
                    asProperty: (defaultValue) => {
                        return $getter.asProperty(getters, defaultValue);
                    },
                };
            },
            /**
             * Get access to the local mutation resolver.
             * @param {String} type
             */
            mutate: (type) => {
                if (!commit || typeof commit !== 'function') {
                    throw new TypeError(
                        `No commit provided by the context. Cannot apply mutations.`
                    );
                }
                const $mutate = ECNBStore.resolve.mutation(type).asGlobal();
                return {
                    /**
                     * Resolve mutation with passed type as a function with parameters. Assumes type has been resolved.
                     * @param {any} payload
                     */
                    withPayload: (payload) =>
                        $mutate.withPayload(payload).using(commit),
                };
            },
            /**
             * Get access to the local action resolver.
             * @param {String} type
             */
            invoke: (type) => {
                if (!dispatch || typeof dispatch !== 'function') {
                    throw new TypeError(
                        `No dispatch provided by the context. Cannot invoke actions.`
                    );
                }
                const $invoke = ECNBStore.resolve.action(type).asGlobal();
                return {
                    /**
                     * Invokes promise with no payload, without awaiting.
                     */
                    dispatch() {
                        return $invoke.withConfig(dispatch);
                    },
                    /**
                     * Invokes promise with no payload and returns the awaited result.
                     * @returns {Promise<Awaited<any>>}
                     */
                    throwIfRejected: async () => {
                        return await $invoke.withConfig(dispatch);
                    },
                    /**
                     * Invokes promise with no payload and returns the awaited result or the reason for rejection (if rejected).
                     * @returns {Promise<Awaited<any> | Error>}
                     */
                    withReason: async () => {
                        try {
                            return await $invoke.withConfig(dispatch);
                        } catch (error) {
                            return error;
                        }
                    },
                    /**
                     * Invokes promise with no payload and returns the awaited result or the (optional) default value (if rejected).
                     * @template {any} [V=undefined]
                     * @param {V} [defaultValue]
                     * @returns {Promise<V>}
                     */
                    withRejected: async (defaultValue = undefined) => {
                        try {
                            return await $invoke.withConfig(dispatch);
                        } catch (error) {
                            return defaultValue;
                        }
                    },
                    /**
                     * Invoke an action with a payload.
                     * @param {any} payload Payload.
                     */
                    withPayload: (payload) => {
                        const $invokeWith = (payload) =>
                            $invoke.withPayload(payload).using(dispatch);
                        return {
                            /**
                             * Invokes promise with a payload, without awaiting.
                             */
                            dispatch() {
                                return $invokeWith(payload);
                            },
                            /**
                             * Invokes promise with a payload and returns the awaited result.
                             */
                            throwIfRejected: async () => {
                                return await $invokeWith(payload);
                            },
                            /**
                             * Invokes promise with a payload and returns the awaited result or the reason for rejection (if rejected).
                             */
                            withReason: async () => {
                                try {
                                    return await $invokeWith(payload);
                                } catch (error) {
                                    return error;
                                }
                            },
                            /**
                             * Invokes promise with a payload and returns the awaited result or the (optional) default value (if rejected).
                             * @template {any} [V=any]
                             * @param {V} defaultValue
                             */
                            withRejected: async (defaultValue = undefined) => {
                                try {
                                    return await $invokeWith(payload);
                                } catch (error) {
                                    return defaultValue;
                                }
                            },
                        };
                    },
                    /**
                     * Dispatch action in sequence with a payload.
                     * @param {any[]} payloads Payloads used to invoke the action.
                     */
                    inSequence: (payloads) => {
                        /**
                         * Dispatch local action with payload.
                         * @param {any} payload
                         */
                        const $invokeWith = (payload) =>
                            $invoke.withPayload(payload).using(dispatch);
                        return {
                            /**
                             * Returns awaited results from all promises, throwing on first error found.
                             */
                            throwIfRejected: async () => {
                                const results = [];
                                for (const payload of payloads) {
                                    // Next line will throw error on rejection.
                                    const result = await $invokeWith(payload);
                                    results.push(result);
                                }
                                return results;
                            },
                            /**
                             * Returns awaited results from only fulfilled promises.
                             */
                            withFulfilled: async () => {
                                const label = ECNBStore.resolve
                                    .label(type)
                                    .asGlobal()
                                    .action();
                                const results = [];
                                for (const payload of payloads) {
                                    try {
                                        const result = await $invokeWith(
                                            payload
                                        );
                                        console.count(`${label} - Fulfilled`);
                                        results.push(result);
                                    } catch (error) {
                                        console.count(`${label} - Rejected`);
                                    }
                                }
                                return results;
                            },
                            /**
                             * Returns awaited results from all promises, replacing rejected values with the reason of the rejection, if one is provided.
                             * @returns {Promise<any[]>}
                             */
                            withReasons: async () => {
                                const label = ECNBStore.resolve
                                    .label(type)
                                    .asGlobal()
                                    .action();
                                const results = [];
                                for (const payload of payloads) {
                                    try {
                                        const result = await $invokeWith(
                                            payload
                                        );
                                        console.count(`${label} - Fulfilled`);
                                        results.push(result);
                                    } catch (error) {
                                        console.count(`${label} - Rejected`);
                                        results.push(error);
                                    }
                                }
                                return results;
                            },
                            /**
                             * Returns awaited results from all promises, replacing rejected values with the provided default value if one is provided.
                             * @template {any} [V=any]
                             * @param {V} [defaultValue]
                             * @returns {Promise<V[]>}
                             */
                            withRejected: async (defaultValue = undefined) => {
                                const label = ECNBStore.resolve
                                    .label(type)
                                    .asGlobal()
                                    .action();
                                const results = [];
                                for (const payload of payloads) {
                                    try {
                                        const result = await $invokeWith(
                                            payload
                                        );
                                        console.count(`${label} - Fulfilled`);
                                        results.push(result);
                                    } catch (error) {
                                        console.count(`${label} - Rejected`);
                                        results.push(defaultValue);
                                    }
                                }
                                return results;
                            },
                        };
                    },
                    /**
                     * Dispatch action in any order with a payload.
                     * @param {any[]} payloads Payloads used to invoke the action.
                     */
                    inParallel: async (payloads) => {
                        /**
                         * Dispatch all payloads and return array of promises.
                         */
                        const $invokeMany = () =>
                            payloads.map((payload) =>
                                $invoke.withPayload(payload).using(dispatch)
                            );
                        return {
                            /**
                             * Returns awaited results from all promises, throwing on first error found.
                             */
                            throwIfRejected: async () => {
                                await Promise.all($invokeMany());
                            },
                            /**
                             * Returns awaited results from only fulfilled promises.
                             */
                            withFulfilled: async () => {
                                const label = ECNBStore.resolve
                                    .label(type)
                                    .asGlobal()
                                    .action();
                                const results = await Promise.allSettled(
                                    $invokeMany()
                                );
                                const marker = Symbol('reject');
                                const fulfilled = results.map((result) => {
                                    if (result.status === 'fulfilled') {
                                        return result.value;
                                    } else {
                                        return marker;
                                    }
                                });
                                return fulfilled.filter(
                                    (value) => value === marker
                                );
                            },
                            /**
                             * Returns awaited results from all promises, replacing rejected values with the reason of the failure, if value is provided.
                             * @returns {Promise<any[]>}
                             */
                            withReasons: async () => {
                                const label = ECNBStore.resolve
                                    .label(type)
                                    .asGlobal()
                                    .action();
                                /** @type {PromiseSettledResult<any | Error>[]} */
                                const results = await Promise.allSettled(
                                    $invokeMany()
                                );
                                return results.map((result) => {
                                    if (result.status === 'fulfilled') {
                                        return result.value;
                                    } else {
                                        return result.reason;
                                    }
                                });
                            },
                            /**
                             * Returns awaited results from all promises, replacing rejected values with the provided default value if one is provided.
                             * @template {any} [V=any]
                             * @param {V} [defaultValue]
                             * @returns {Promise<V[]>}
                             */
                            withRejected: async (defaultValue = undefined) => {
                                const label = ECNBStore.resolve
                                    .label(type)
                                    .asGlobal()
                                    .action();
                                /** @type {PromiseSettledResult<V>[]} */
                                const results = await Promise.allSettled(
                                    $invokeMany()
                                );
                                return results.map((result) => {
                                    if (result.status === 'fulfilled') {
                                        return result.value;
                                    } else {
                                        return defaultValue;
                                    }
                                });
                            },
                        };
                    },
                };
            },
        };
    }
}

// DEFAULT
export default ECNBModule;
