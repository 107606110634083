const PEM = /^P_A[\d]{1,5}[.][\d]{1,4}$/i;
const PEM2 = /^P2_[\d]{1,5}[.][\d]{1,4}$/i;

/**
 * Attempt to detect whether this is a PEM1 file.
 * @param {String} name 
 * @returns {Boolean}
 */
export const isPEM1File = (name) => {
    return PEM.test(name);
};

/**
 * Attempt to detect whether this is a PEM2 file.
 * @param {String} name 
 * @returns {Boolean}
 */
export const isPEM2File = (name) => {
    if (PEM2.test(name)) {
        return true;
    }

    // Get file extension from file name.
    // See: https://stackoverflow.com/a/12900504
    const extension = name
        .slice(((name.lastIndexOf('.') - 1) >>> 0) + 2)
        .toLowerCase();

    return extension === 'pm2';
};

/** @type {AgGrid.ValueGetterFunc} */
export const getFileType = (params) => {
    console.dir(params);
    if (
        params?.data?.type?.length === 0 &&
        params?.data?.name?.length > 0 &&
        params?.data?.name?.includes('.')
    ) {
        // Could be a PEM file type.
        const name = params?.data?.name;
        if (isPEM1File(name)) {
            return 'bin/PEM';
        }
        if (isPEM2File(name)) {
            return 'text/PEM';
        }
    }
    // If not PEM, return normal file type.
    return params?.data?.type;
};

export default getFileType;
