// <!-- TYPES -->
import { Model } from '@/models/v1/resource/Model';
import { DynamicEnumFactory } from '@/utils/DynamicEnum';
/** @typedef {ReturnType<LocationList['toPayload']>} LocationListPayload */
/** @typedef {ReturnType<LocationList['toResource']>} LocationListResource */
import { Location, LocationPayload, LocationResource } from './Location';

/** Model attribute names. */
const FIELDS = DynamicEnumFactory().fromKeys(['id', 'name', 'locations']);

/**
 * @class
 */
export class LocationList extends Model {
    _initialState() {
        return Model.ComposeStateUsingFields(FIELDS);
    }

    /**
     * @param {LocationListPayload} payload
     * @returns {this}
     */
    parsePayload(payload) {
        /** @type {(payload: LocationListPayload) => [string, import('@/models/v1/resource/Model').AttributeType][]} */
        const transformPayload = (payload) => {
            const { locations, ...attributes } = payload;
            const entity = {
                ...attributes,
                locations: locations.map((location) =>
                    new Location().parsePayload(location)
                ),
            };
            return Object.entries(entity);
        };
        super.parsePayload(payload, transformPayload);
        return this;
    }

    /**
     * @param {LocationListResource} resource
     * @returns {this}
     */
    parseResource(resource) {
        /** @type {(resource: LocationListResource) => [string, import('@/models/v1/resource/Model').AttributeType][]} */
        const transformResource = (resource) => {
            const { locations, ...attributes } = resource;
            const entity = {
                ...attributes,
                locations: locations.map((location) =>
                    new Location().parseResource(location)
                ),
            };
            return Object.entries(entity);
        };
        super.parseResource(resource, transformResource);
        return this;
    }

    toPayload() {
        /** @type {Location[]} */
        const locations = this.get(FIELDS.locations);

        return {
            /** @type {Number} */
            [FIELDS.id]: this.get(FIELDS.id),
            /** @type {String} */
            [FIELDS.name]: this.get(FIELDS.name),
            /** @type {LocationPayload[]} */
            [FIELDS.locations]: locations.map((location) =>
                location.toPayload()
            ),
        };
    }

    toResource() {
        /** @type {Location[]} */
        const locations = this.get(FIELDS.locations);

        return {
            /** @type {Number} */
            [FIELDS.id]: this.get(FIELDS.id),
            /** @type {String} */
            [FIELDS.name]: this.get(FIELDS.name),
            /** @type {LocationResource[]} */
            [FIELDS.locations]: locations.map((location) =>
                location.toResource()
            ),
        };
    }
}
