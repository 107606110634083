// <!-- UTILITY -->
import { Enum } from '@/utils/enums';

export const AssociationType = Enum.create({
    Location: 'location',
    Hierarchy: 'hierarchy',
    None: 'none',
});

export default AssociationType;
