// <!-- TYPES -->

/**
 * @typedef {string | number | boolean | Date | Object} AttributeType Valid attribute types.
 */

/**
 * @template {string} K
 * @template {AttributeType} [V=AttributeType] Type of an individual attribute value.
 * @typedef {[K, V]} Attribute An individual attribute, with a key and value.
 */

/**
 * @template {string} K
 * @template {AttributeType} [V=AttributeType]
 * @template {readonly (readonly [K, V])[]} [T=readonly (readonly [K, V])[]] Attribute definitions.
 * @typedef {T} Attributes
 */

/**
 * @typedef {Map<String, AttributeType>} AttributeMap Attribute definition map.
 */

/**
 * @typedef {Object} ModelState Property representing the state of the model.
 * @property {Model} [model] If present, state is represented by an existing model instance. Highest priority.
 * @property {readonly (readonly [String, AttributeType])[]} [entries] If present, state is represented as an array of attributes.
 * @property {import('@/types').ExcludeMethods<any>} [payload] If present, state is represented by properties with payload field names.
 * @property {( payload: any ) => readonly (readonly [String, AttributeType])[]} [transformPayload] Optional transformation function applied to the payload.
 * @property {import('@/types').ExcludeMethods<any>} [resource] If present, state is represented by properties with resource field names.
 * @property {( resource: any ) => readonly (readonly [String, AttributeType])[]} [transformResource] Optional transformation function applied to the resource.
 */

// <!-- ENUM -->
/**
 * Model schema format.
 */
export const FORMAT = /** @type {const} */ ({
    RESOURCE: 0,
    PAYLOAD: 1,
});

// <!-- CLASS -->
/**
 * @template P
 * @template R
 * @class
 * @description Base model class.
 */
export class Model {
    // <!-- STATIC METHODS -->

    /**
     * @template {any} T
     * Prepare initial state using known fieldnames. Optionally, default values can be supplied.
     * @param {T} fields Array of fieldnames.
     * @param {Map<(keyof T), AttributeType|null>} [defaults] Map of default values.
     */
    static ComposeStateUsingFields(fields, defaults) {
        /** @type {readonly (keyof T)[]} */
        const fieldnames = /** @type {any[]} */ (Object.keys(fields));

        /**
         * Get the default value associated with the provided field.
         * @param {(keyof T)} field
         * @returns {AttributeType|null}
         */
        const getDefaultValue = (field) =>
            !!defaults ? defaults.get(field) : null;

        /**
         * Map fieldname into an entry with the appropriate key and default value.
         * @param {(keyof T)} key Field name.
         * @returns {readonly [(keyof T), AttributeType|null]}
         */
        const getFieldEntry = (key) =>
            /** @type {const} */ ([key, getDefaultValue(key)]);

        // Apply map.
        return fieldnames.map(getFieldEntry);
    }

    /**
     * @template {readonly (readonly [key: String, alias: String])[]} T
     * Prepare alias map using input 2-dimensional table of fieldnames and their aliases.
     * @param {T} aliases Table of fieldname aliases.
     */
    static ComposeAliasesUsingTable(aliases) {
        /** @type {{ (entry: readonly [ key: String, alias: String ]): readonly [alias: string, key: string] }} */
        const getAliasPair = ([key, alias]) =>
            /** @type {const} */ ([alias, key]);
        return new Map(aliases.map(getAliasPair));
    }

    // <!-- MEMBERS -->
    /**
     * @type {Boolean} Is the model currently readonly?
     * @protected
     */
    readonly = false;

    /**
     * @type {String} Primary key field name.
     * @protected
     */
    primaryKey = /** @type {const} */ ('id');

    /**
     * @type {AttributeMap} Underlying attribute value map.
     * @protected
     */
    data = new Map(this._initialState());

    /**
     * @type {String[]} Fillable attributes.
     * @protected
     */
    fillable = ['*'];

    /**
     * @type {Map<string, string>} Attribute aliases.
     * @protected
     */
    aliases = new Map([]);

    // <!-- CONSTRUCTOR -->

    /**
     * Construct model using provided state.
     * @param {ModelState} [state = {}]
     */
    constructor(state = {}) {
        /** Register aliases used with this model. */
        this.aliases = this._registerAliases();

        /** Set the initial model state and register field keys. */
        this.data = new Map(this._initialState());

        /** Set the model state and register field attribute properties. */
        this.sync(state);
    }

    // <!-- HELPERS -->

    /**
     * Get the initial state. Nullable keys should be included.
     * @protected
     * @returns {readonly (readonly [string, AttributeType])[]}
     */
    _initialState() {
        return [];
    }

    /**
     * Register {@link aliases}.
     * @protected
     * @returns {Map<string, string>}
     */
    _registerAliases() {
        return new Map([]);
    }

    /**
     * Check if key is present in the fillable array.
     * @param {String} key
     */
    _isFillable(key) {
        return this.fillable.includes('*') || this.fillable.includes(key);
    }

    /**
     * Determine if provided key is found as a key or value in a given alias map.
     * @param {Map<String, String>} aliases
     * @param {String} keyOrValue
     * @returns {{key: String, value: String}}
     */
    _getAliasEntry(aliases, keyOrValue) {
        const isMapNotEmpty = !!aliases && aliases.size > 0;
        const isKeyOrValueValid = !!keyOrValue && keyOrValue !== '';
        if (isMapNotEmpty && isKeyOrValueValid) {
            const isKey = [...aliases.keys()].includes(keyOrValue);
            if (isKey) {
                const key = keyOrValue;
                const value = aliases.get(key);
                return { key, value };
            }
            const isAlias = [...aliases.values()].includes(keyOrValue);
            if (isAlias) {
                const [key, value] = [...aliases.entries()].find(
                    ([key, alias]) => alias === keyOrValue
                );
                return { key, value };
            }
        }
        // No alias entry present.
        return null;
    }

    /**
     * Get the attribute key from the supplied key or from the key associated with the supplied alias.
     * @protected
     * @param {string} aliasOrKey
     * @returns {string}
     */
    _getAttributeKey(aliasOrKey) {
        // Use if key, or get the alias from the input alias.
        const isKey = this.data.has(aliasOrKey);
        const isAlias = this.aliases.has(aliasOrKey);
        const key =
            (isKey ? aliasOrKey : null) ??
            (isAlias ? this.aliases.get(aliasOrKey) : null);
        return key;
    }

    /**
     * Set property on model instance using the supplied property key.
     * @protected
     * @param {string} key
     * @returns {void}
     */
    _setAttributeProperty(key) {
        // Determine if this instance has a matching property already.
        const isValidKey = !!key;
        const propertyExists = this.hasOwnProperty(key);

        // If the key is valid and a property doesn't already exist.
        if (isValidKey && !propertyExists) {
            const _this = this;
            Object.defineProperty(this, key, {
                get: () => this.get(key),
                set: (value) => this.set(key, value),
            });
        }
    }

    // <!-- PROPERTIES -->

    /**
     * Get the field attribute names used by the model.
     * @public
     * @returns {string[]}
     */
    get fieldnames() {
        return [...this.data.keys()];
    }

    /**
     * Get the field attribute values used by the model.
     * @public
     * @returns {any[]}
     */
    get values() {
        return [...this.data.values()];
    }

    /**
     * Get the identifier for the model.
     */
    get id() {
        return this.data.get(this.primaryKey);
    }

    /** Is the model readonly? */
    get isReadonly() {
        return this.readonly;
    }

    // <!-- METHODS -->

    /**
     * @param {string} aliasOrKey Attribute field name.
     */
    get(aliasOrKey) {
        // Get the attribute key.
        const key = this._getAttributeKey(aliasOrKey);
        const isValidKey = !!key;

        // If key is valid, but no property, get it from the map.
        if (isValidKey) {
            return this.data.get(key);
        }

        // No attribute and no value associated with key? Return null.
        return null;
    }

    /**
     * Explicitly assign value to an attribute named {@link key}.
     *
     * @param {string} aliasOrKey Attribute field name or alias.
     * @param {AttributeType} value Value stored with the model.
     * @param {Boolean} [force=false] Force assignment, even if 'isReadonly' is truthy.
     */
    set(aliasOrKey, value, force = false) {
        // Get the attribute key.
        const key = this._getAttributeKey(aliasOrKey);
        const isValidKey = !!key;
        const isAssignable = isValidKey && (!!force || !this.isReadonly);

        // If key is valid, but no property, attempt to set it on the map.
        if (isAssignable) {
            this.data.set(key, value);
        }
    }

    /**
     * Mass assignment of several attributes allowed within the {@link fillable} collection.
     * Items not in {@link fillable} are ignored.
     * @param {Attribute<string, AttributeType>[]} attributes
     * @param {Boolean} [force=false] Force assignment, even if 'isFillable' is falsy or if 'isReadonly' is truthy.
     */
    fill(attributes, force = false) {
        if (this.fillable.length == 0) {
            // Ignore all attributes if empty fillable set or is readonly.
            return;
        }
        for (const [aliasOrKey, value] of attributes) {
            const key = this._getAttributeKey(aliasOrKey);
            const fillable = !force || this._isFillable(key);
            if (fillable) {
                this.set(key, value, force);
            }
        }
    }

    /**
     * @template {ModelState} S Model state.
     * Sync object representation using input data format.
     * @param {S} state Model state representation as a payload, resource, entry array, or model instance.
     * @returns {this}
     */
    sync(state) {
        if (!!state.payload) this.parsePayload(state.payload);
        if (!!state.resource) this.parseResource(state.resource);
        if (!!state.entries) this.parseArray(state.entries);
        if (!!state.model) this.parseArray(state.model.toArray());
        return this;
    }

    // <!-- FORMATTERS -->

    /**
     * Get the array of key/value entries representing the model state.
     * @public
     * @param {( entries: readonly (readonly [String, AttributeType])[] ) => readonly (readonly [String, AttributeType])[]} [transform] If present, used to transform the array of entries before return.
     * @returns {readonly (readonly [String, AttributeType])[]}
     */
    toArray(transform) {
        // Default implementation.
        const entries = [...this.data.entries()];
        const array = !transform ? entries : transform(entries);
        return array;
    }

    /**
     * Get string representation of the Model.
     * @public
     * @returns {string}
     */
    toString() {
        const entries = this.toArray();
        const state = Object.fromEntries(entries);
        return JSON.stringify(state);
    }

    /**
     * Get payload representation of the Model.
     * - Used by intellisense to get {@link ModelPayload} type.
     * @public
     */
    toPayload() {
        // Default implementation.
        const entries = this.toArray();
        const payload = Object.fromEntries(entries);
        return payload;
    }

    /**
     * Get resource representation of the Model.
     * - Used by intellisense to get {@link ModelResource} type.
     * @public
     */
    toResource() {
        // Default implementation.
        const entries = this.toArray();
        const resource = Object.fromEntries(entries);
        return resource;
    }

    // <!-- PARSERS -->

    /**
     * Parse model state from array of attributes (as key/value tuples).
     * - All fields are considered 'mass assignable' when set using `parseXXX()`, regardless of {@link Model.fillable} contents.
     * @param {readonly (readonly [String, AttributeType])[]} [array] Attributes array.
     * @returns {this}
     */
    parseArray(array) {
        // Default implementation.

        /**
         * @type {readonly (readonly [ aliasOrKey: String, value: AttributeType])[]}
         * Array of key/value pairs with attribute aliases or keys are mapped to corresponding attribute values.
         */
        const entries = array ?? [];

        /**
         * @type {readonly (readonly [ key: String, value: AttributeType ])[]}
         * Array of key/value pairs definiting model state.
         * - {@link entries} collection is filtered into an array of attribute definitions with valid keys.
         * - Entries in the {@link entries} collection with invalid aliases or keys are discarded.
         */
        const attributes = entries.flatMap(([aliasOrKey, value]) => {
            const key = this._getAttributeKey(aliasOrKey);
            return !!key ? [[key, value]] : [];
        });

        /**
         * Validation check to see if there are any valid attributes to sync.
         */
        const hasValidAttributes = !!attributes && attributes?.length > 0;

        // Assign valid attributes as properties to the model instance.
        if (hasValidAttributes) {
            for (const [key, value] of attributes) {
                this._setAttributeProperty(key);
                this.set(key, value, true);
            }
        }

        // Return reference to current instance.
        return this;
    }

    /**
     * @template {any} J
     * Parse model state from JSON string representation.
     * @param {String} json JSON representation of the model state.
     * @param {( state: J ) => [String, AttributeType][] } [transform] If present, used to convert JSON string into an array of model attribute entries.
     * @returns {this}
     */
    parseString(json, transform) {
        // Default implementation.
        const state = /** @type {J} */ (JSON.parse(json));
        const entries = !transform ? Object.entries(state) : transform(state);
        return this.parseArray(entries);
    }

    /**
     * Parse model state from input payload object.
     * @param {import('@/types').ExcludeMethods<any>} payload Payload object to parse.
     * @param {( payload: { [k: String]: any } ) => [String, AttributeType][] } [transform] If present, used to convert payload schema into array of model attribute entries.
     * @returns {this}
     */
    parsePayload(payload, transform) {
        // Default implementation.
        const array = !transform ? Object.entries(payload) : transform(payload);
        const entries = array ?? [];
        return this.parseArray(entries);
    }

    /**
     * Parse model state from input payload object.
     * @param {import('@/types').ExcludeMethods<any>} resource Resource object to parse.
     * @param {( resource: { [k: String]: any } ) => [String, AttributeType][] } [transform] If present, used to convert resource schema into array of model attribute entries.
     * @returns {this}
     */
    parseResource(resource, transform) {
        // Default implementation.
        const array = !transform
            ? Object.entries(resource)
            : transform(resource);
        const entries = array ?? [];
        return this.parseArray(entries);
    }
}

// <!-- EXPOSE -->
export default {
    Model,
    FORMAT,
};
