// <!-- API -->
import { ref, reactive, computed } from 'vue';
/**
 * @class TooltipState
 */
class TooltipState {
    /**
     * Initialize tooltip state.
     *
     * @param {Object} props
     * @param {V.Ref<String>} [props.content] Reference to content.
     * @param {{ x?: Number, y?: Number }} [props.origin] Reactive origin.
     * @param {{ x?: Number, y?: Number }} [props.offset] Reactive offset.
     * @param {V.Ref<Boolean>} [props.isTooltipVisible] Is the tooltip visible?
     */
    constructor(props) {
        this.content = props.content ?? ref('');
        this.origin = reactive(props.origin ?? { x: 0, y: 0 });
        this.offset = reactive(props.offset ?? { x: 0, y: 0 });
        this.isTooltipVisible = props.isTooltipVisible ?? ref(false);
        const $ = this;
        this.position = computed(() => {
            const origin = $.origin;
            const offset = $.offset;
            const position = { x: origin.x + offset.x, y: origin.y + offset.y };
            return position;
        });
    }
}

/**
 * Use existing props (or create new ones).
 *
 * @param {Object} props
 * @param {V.Ref<String>} [props.content] Reference to content.
 * @param {{ x?: Number, y?: Number }} [props.origin] Reactive origin.
 * @param {{ x?: Number, y?: Number }} [props.offset] Reactive offset.
 * @param {V.Ref<Boolean>} [props.isTooltipVisible] Is the tooltip visible?
 */
const useState = (props) => {
    return new TooltipState(props ?? {});
};

/** @param {TooltipState} state */
const useMethods = (state) => {
    /** @param {MouseEvent} event */
    const onMouseOver = (event) => {
        event.preventDefault();
        state.isTooltipVisible.value = true;
        updatePosition(event);
    };

    /** @param {MouseEvent} event */
    const onMouseEnter = (event) => {
        event.preventDefault();
        state.isTooltipVisible.value = true;
        updatePosition(event);
    };

    /** @param {MouseEvent} event */
    const onMouseMove = (event) => {
        event.preventDefault();
        state.isTooltipVisible.value = true;
        updatePosition(event);
    };

    /** @param {MouseEvent} event */
    const onMouseOut = (event) => {
        event.preventDefault();
        state.isTooltipVisible.value = false;
    };

    /** @param {Event} event */
    const onScroll = (event) => {};

    /** @param {Event} event */
    const onResize = (event) => {};

    /** @param {MouseEvent} event */
    const updatePosition = (event) => {
        $.setOrigin(event.pageX, event.pageY);
    };

    /** @param {String} content */
    const setContent = (content) => {
        state.content.value = content;
    };

    const setOrigin = (x, y) => {
        state.origin.x = x;
        state.origin.y = y;

        return $;
    };
    const setOffset = (x, y) => {
        state.offset.x = x;
        state.offset.y = y;

        return $;
    };
    const $ = {
        setContent,
        setOrigin,
        setOffset,
        onMouseEnter,
        onMouseMove,
        onMouseOut,
        onMouseOver,
        onResize,
        onScroll,
    };

    return $;
};

/**
 * Create tooltip from input props.
 *
 * @param {Object} props
 * @param {V.Ref<String>} [props.content] Reference to content.
 * @param {{ x?: Number, y?: Number }} [props.origin] Reactive origin.
 * @param {{ x?: Number, y?: Number }} [props.offset] Reactive offset.
 * @param {V.Ref<Boolean>} [props.isTooltipVisible] Is the tooltip visible?
 */
export const useTooltip = (props) => {
    const state = useState(props);
    const methods = useMethods(state);
    return {
        ...state,
        ...methods,
    };
};

export default useTooltip;
