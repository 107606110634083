/**
 * @template [T=any]
 * Get item from localStorage.
 * @param {String} key
 * @param {T} defaultValue
 * @returns {T}
 */
const get = (key, defaultValue = null) => {
    const value = /** @type {T} */ (
        !!key ? localStorage.getItem(key) : defaultValue
    );
    return value ?? defaultValue;
};

/**
 * Check if item exists in local storage.
 * @param {String} key
 */
const has = (key) => {
    const token = !!key ? localStorage.getItem(key) : null;
    return !!token && token !== '';
};

/**
 * Save item to the local storage.
 * @param {String} key
 * @param {String} value
 */
const set = (key, value) => {
    if (!!key && key !== '') {
        localStorage.removeItem(key);
        localStorage.setItem(key, value);
    }
};

/**
 * Remove item from the local storage.
 * @param {String} key
 */
const remove = (key) => {
    if (!!key && key !== '') {
        localStorage.removeItem(key);
    }
};

/** @typedef - Static API for interacting with local storage. */
export const LocalStorage = {
    get,
    set,
    has,
    remove,
};

// <!-- DEFAULT -->
export default LocalStorage;
