// <!-- UTILITIES -->
import { isNumber, isString, isDateTimeISOString } from '@/utils/typeof';
import {
    isDate,
    isValid,
    toDate,
    parseISO,
    formatISO,
    add,
    sub,
} from 'date-fns';

// <!-- TYPES -->//TODO: Enhance with global types.
/** @typedef {import('@/utils/date').IDate} IDate */
/** @typedef {import('@/utils/date').ITimestamp} ITimestamp */
/** @typedef {import('@/utils/date').IUnixTimestamp} IUnixTimestamp */
/** @typedef {import('@/utils/datetime').IDateTimeISOService} IDateTimeISOService */

// <!-- DEFINITION -->
/**
 * @class
 * ISO 8601 datetime service.
 * @implements {IDateTimeISOService}
 */
export class DateTimeISO {
    /**
     * Clone existing {@link Date} instance. For convenience casts {@link ITimestamp} into {@link Date} instance.
     * @param {IDate} [argument] Instance to clone. If `null`, uses epoch date. If `undefined` uses today's date.
     * @returns {Date}
     */
    static clone = toDate;

    /** @type {IDateTimeISOService['parse']} */
    static parse = (argument) => {
        if (isString(argument)) {
            const input = /** @type {String} */ (argument);
            if (isDateTimeISOString(input)) {
                const date = parseISO(input, { additionalDigits: 2 });
                return date;
            }
        } else {
            const date = /** @type {IDate} */ (argument);
            return toDate(date);
        }
        throw new RangeError(`Cannot parse invalid ISO string: ${argument}.`);
    };

    /** @type {IDateTimeISOService['format']} */
    static format = (argument) => {
        if (isNumber(argument) || (isDate(argument) && isValid(argument))) {
            const input = /** @type {IDate} */ (argument);
            return formatISO(input);
        }
        throw new RangeError(`Cannot format invalid argument: ${argument}.`);
    };

    /** @type {IDateTimeISOService['add']} */
    static add = (date, duration) => add(date, duration);

    /** @type {IDateTimeISOService['subtract']} */
    static subtract = (date, duration) => sub(date, duration);

    /** @type {IDateTimeISOService['clone']} */
    clone = DateTimeISO.clone;

    /** @type {IDateTimeISOService['parse']} */
    parse = DateTimeISO.parse;

    /** @type {IDateTimeISOService['format']} */
    format = DateTimeISO.format;

    /** @type {IDateTimeISOService['add']} */
    add = DateTimeISO.add;

    /** @type {IDateTimeISOService['subtract']} */
    subtract = DateTimeISO.subtract;
}

// <!-- DEFAULT -->
export default DateTimeISO;
