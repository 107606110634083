// <!-- UTILITY -->

/**
 * Creates an Enum representation.
 *
 * @template {Record<string,string>} E
 */
export class Enum {
    /**
     * Create an Enum instance with intellisense properties.
     *
     * @template {Record<string,string>} T
     * @param {Readonly<T>} dict
     */
    static create(dict) {
        const instance = /** @type {unknown} */ (new Enum(dict));
        return /** @type {Enum<T> & T} */ (instance);
    }

    /**
     * Create the enum key type guard.
     *
     * @template {Record<string,string>} T
     * @param {Readonly<T>} dict
     */
    static createKeyGuard(dict) {
        // Get a snapshot of the keys.
        const _keys = Object.keys(dict);

        /** @type {<K extends keyof T extends string ? keyof T : never = keyof T extends string ? keyof T : never>(target: K, key: unknown) => key is K} */
        return (target, key) =>
            target !== undefined &&
            target !== null &&
            _keys.includes(target) &&
            target === key;
    }

    /**
     * Create the enum key type guard.
     *
     * @template {Record<string,string>} T
     * @param {Readonly<T>} dict
     */
    static createValueGuard(dict) {
        // Get a snapshot of the values.
        const _values = Object.values(dict);

        /** @type {<V extends T[Extract<keyof T, string>] = T[Extract<keyof T, string>]>(target: V, value: unknown) => value is V} */
        return (target, value) =>
            target !== undefined &&
            target !== null &&
            _values.includes(target) &&
            target === value;
    }

    // <!-- CONSTRUCTOR -->

    /**
     * Creates an enum representation.
     *
     * @param {Readonly<E>} dict
     */
    constructor(dict) {
        // Bind the underlying representation.
        /** @type {E} */
        this._dictionary = Object.freeze(dict);
        Object.defineProperty(this, '_dictionary', {
            configurable: false,
            enumerable: false,
            writable: false,
        });

        // Bind snapshot of the keys.
        this.keys = Object.keys(this._dictionary);
        Object.defineProperty(this, 'keys', {
            configurable: false,
            enumerable: false,
            writable: false,
        });

        // Bind snapshot of the values.
        this.values = Object.values(this._dictionary);
        Object.defineProperty(this, 'values', {
            configurable: false,
            enumerable: false,
            writable: false,
        });

        // Bind the key guard.
        this.isKey = Enum.createKeyGuard(this._dictionary);
        Object.defineProperty(this, 'isKey', {
            configurable: false,
            enumerable: false,
            writable: false,
        });

        // Bind the value guard.
        this.isValue = Enum.createValueGuard(this._dictionary);
        Object.defineProperty(this, 'isValue', {
            configurable: false,
            enumerable: false,
            writable: false,
        });

        // Forward all enum entries.
        for (const key in this._dictionary) {
            Object.defineProperty(this, key, {
                configurable: false,
                enumerable: true,
                writable: false,
                value: this._dictionary[key],
            });
        }
    }

    /**
     * Confirm that the string key exists for this enum.
     *
     * @param {string} key
     * @returns {key is keyof E}
     */
    hasKey(key) {
        return key !== undefined && key !== null && this.keys.includes(key);
    }

    /**
     * Confirm that the string key exists for this enum.
     *
     * @param {string} value
     * @returns {value is E[keyof E]}
     */
    hasValue(value) {
        return (
            value !== undefined && value !== null && this.values.includes(value)
        );
    }

    /**
     * Get the key of the passed value.
     *
     * @template {E[keyof E]} V
     * @param {V} value
     * @returns {{ [P in E[keyof E]]: { [K in keyof E]: E[K] extends P ? K : never }[keyof E] }[V]}
     */
    keyOf(value) {
        for (const key in this._dictionary) {
            // @ts-ignore
            if (this._dictionary[key] === value) {
                // @ts-ignore
                return key;
            }
        }
        return null;
    }

    /**
     * Get the value of the passed key.
     *
     * @template {keyof E} K
     * @param {K} key
     * @returns {E[K]}
     */
    get(key) {
        return this._dictionary?.[key];
    }
}

// <!-- DEFAULT -->
export default Enum;
