// <!-- UTILITIES -->
import { getFallbackTimezones } from './getFallbackTimezones';

// <!-- UTILITY -->

/**
 * Get the list of supported timezones.
 * @returns {Readonly<Array<TimeZone.Identifier>>}
 */
export const getSupportedTimeZones = () => {
    if (typeof Intl.supportedValuesOf === 'function') {
        // Use browser timezones.
        return /** @type {Readonly<Array<TimeZone.Identifier>>} */ (
            Object.freeze([...Intl.supportedValuesOf('timeZone'), 'UTC'])
        );
    } else {
        // Use fallback.
        return getFallbackTimezones();
    }
};

// <!-- DEFAULT EXPORT -->
export default getSupportedTimeZones;
