// <!-- TYPES -->

import { ECNBState } from '@/store/types/ECNBStore';
import { CacheState } from '@/store/types/cache/state';
import { CacheModule } from '@/store/types/cache/module';

/**
 * @type {import('vuex').Module<CacheState, ECNBState>} Module to export.
 */
const module = {
    namespaced: CacheModule.module.namespaced,
    state: CacheModule.module.state,
    modules: CacheModule.module.modules,
    getters: CacheModule.module.getters,
    mutations: CacheModule.module.mutations,
    actions: CacheModule.module.actions,
};

// EXPOSE
export default module;
