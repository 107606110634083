// <!-- TYPES -->
import { Model, FORMAT } from '@/models/v1/resource/Model';

import {
    DynamicEnumFactory,
    DynamicEnumGridFactory,
} from '@/utils/DynamicEnum';
import { encodeBoolean, decodeBoolean } from '@/utils/BooleanEncoder';
import {
    LocationHierarchy,
    LocationHierarchyPayload,
    LocationHierarchyResource,
} from '@/models/v1/locations/LocationHierarchy';
import {
    NoteAuthor,
    NoteAuthorPayload,
    NoteAuthorResource,
} from '@/models/v1/notes/NoteAuthor';
import {
    NoteLocation,
    NoteLocationPayload,
    NoteLocationResource,
} from '@/models/v1/notes/NoteLocation';

/** Model attribute names. */
const FIELDS = DynamicEnumFactory().fromKeys([
    'id',
    'title',
    'content',
    'dateStart',
    'dateEnd',
    'visible',
    'author',
    'hierarchyId',
    'locationsCount',
    'hierarchy',
    'locations',
]);

/** Resource <--> Payload aliases. */
const ALIASES = /** @type {const} */ ([
    [FIELDS.id, 'id'],
    [FIELDS.title, 'title'],
    [FIELDS.content, 'content'],
    [FIELDS.dateStart, 'start_date'],
    [FIELDS.dateEnd, 'end_date'],
    [FIELDS.visible, 'visible'],
    [FIELDS.author, 'author'],
    [FIELDS.hierarchyId, 'location_hierarchy_id'],
    [FIELDS.locationsCount, 'locations_count'],
    [FIELDS.hierarchy, 'hierarchy'],
    [FIELDS.locations, 'locations'],
]);

/** Resource and payload keys. */
const KEYS = DynamicEnumGridFactory().fromPairs(ALIASES);

/**
 * @class
 */
export class NotePayload {
    /** @type {Number} Identifier. */
    id = null;
    /** @type {String} Note title. */
    title = '';
    /** @type {String} Note content. */
    content = '';
    /** @type {String} Date start. */
    start_date = null;
    /** @type {String} Date end. */
    end_date = null;
    /** @type {"1"|"0"} Visibility. */
    visible = '0';
    /** @type {NoteAuthorPayload} Author payload. */
    author = new NoteAuthorPayload();
    /** @type {LocationHierarchyPayload['id']} Hierarchy identifier. */
    location_hierarchy_id = null;
    /** @type {LocationHierarchyPayload[]} Hierarchy. */
    hierarchy = [];
    /** @type {NoteLocationPayload[]} Locations. */
    locations = [];
    /** @type {Number} Location count. */
    locations_count = null;

    /**
     * Assign attributes from another instance.
     * @param {Partial<import('@/types').ExcludeMethods<NotePayload>>} attributes
     */
    constructor(attributes = {}) {
        const visible = encodeBoolean(attributes.visible);
        Object.assign(this, attributes, { visible });
    }

    /**
     * Parse model state to get the payload.
     * @param {Note} model
     * @returns {this}
     */
    parseModel(model) {
        this.id = model.get(FIELDS.id);
        this.title = model.get(FIELDS.title);
        this.content = model.get(FIELDS.content);
        this.start_date = model.get(FIELDS.dateStart);
        this.end_date = model.get(FIELDS.dateEnd);
        this.visible = encodeBoolean(model.get(FIELDS.visible));
        this.author = model.get(FIELDS.author).toPayload();

        // Hierarchy id.
        this.location_hierarchy_id = model.get(FIELDS.hierarchyId);

        /** @type {LocationHierarchy[]} Location hierarchy. */
        const hierarchy = model.get(FIELDS.hierarchy);
        this.hierarchy = hierarchy?.map((node) => node.toPayload()) ?? [];

        /** @type {NoteLocation[]} Locations. */
        const locations = model.get(FIELDS.locations);
        this.locations =
            locations?.map((location) => location.toPayload()) ?? [];
        this.locations_count = model.get(FIELDS.locationsCount);

        // Return parsed instance.
        return this;
    }

    /**
     * Convert this payload into a model.
     * @returns {readonly [typeof FIELDS[keyof FIELDS], any][]}
     */
    entries() {
        /** @type {NoteAuthor} Locations. */
        const author = new NoteAuthor({ payload: this.author });

        /** @type {LocationHierarchy[]} Location hierarchy. */
        const hierarchy =
            this.hierarchy?.map(
                (node) =>
                    new LocationHierarchy({
                        payload: new LocationHierarchyPayload(node),
                    })
            ) ?? [];

        /** @type {NoteLocation[]} Locations. */
        const locations =
            this.locations?.map(
                (location) =>
                    new NoteLocation({
                        payload: new NoteLocationPayload(location),
                    })
            ) ?? [];

        /** @type {readonly [typeof FIELDS[keyof FIELDS], any][]} */
        return [
            [FIELDS.id, this.id],
            [FIELDS.title, this.title],
            [FIELDS.content, this.content],
            [FIELDS.dateStart, this.start_date],
            [FIELDS.dateEnd, this.end_date],
            [FIELDS.visible, decodeBoolean(this.visible)],
            [FIELDS.author, author],
            [FIELDS.hierarchyId, this.location_hierarchy_id],
            [FIELDS.hierarchy, hierarchy],
            [FIELDS.locations, locations],
            [FIELDS.locationsCount, this.locations_count],
        ];
    }
}

/**
 * @class
 */
export class NoteResource {
    /** @type {Number} Identifier. */
    id;
    /** @type {String} Note title. */
    title;
    /** @type {String} Note content. */
    content;
    /** @type {String} Date start. */
    dateStart;
    /** @type {String} Date end. */
    dateEnd;
    /** @type {Boolean} Visibility. */
    visible = false;
    /** @type {NoteAuthorResource} Author resource. */
    author = new NoteAuthorResource();
    /** @type {LocationHierarchyResource['id']} Hierarchy identifier. */
    hierarchyId = null;
    /** @type {LocationHierarchyResource[]} Hierarchy. */
    hierarchy = [];
    /** @type {NoteLocationResource[]} Locations. */
    locations = [];
    /** @type {Number} Locations count. */
    locationsCount = null;

    /**
     * Assign attributes from another instance.
     * @param {Partial<import('@/types').ExcludeMethods<NoteResource>>} attributes
     */
    constructor(attributes = {}) {
        Object.assign(this, attributes);
    }

    /**
     * Parse model state to get the resource.
     * @param {Note} model
     * @returns {this}
     */
    parseModel(model) {
        this.id = model.get(FIELDS.id);
        this.title = model.get(FIELDS.title);
        this.content = model.get(FIELDS.content);
        this.dateStart = model.get(FIELDS.dateStart);
        this.dateEnd = model.get(FIELDS.dateEnd);
        this.visible = model.get(FIELDS.visible);
        this.author = model.get(FIELDS.author).toResource();
        this.hierarchyId = model.get(FIELDS.hierarchyId);

        /** @type {LocationHierarchy[]} Location hierarchy. */
        const hierarchy = model.get(FIELDS.hierarchy);
        this.hierarchy = hierarchy.map((node) => node.toResource());

        /** @type {NoteLocation[]} Locations. */
        const locations = model.get(FIELDS.locations);
        this.locations = locations.map((location) => location.toResource());
        this.locationsCount = model.get(FIELDS.locationsCount);

        // Return parsed instance.
        return this;
    }

    /**
     * Convert this resource into a model.
     * @returns {readonly [typeof FIELDS[keyof FIELDS], any][]}
     */
    entries() {
        /** @type {NoteAuthor} Author. */
        const author = new NoteAuthor({ resource: this.author });

        /** @type {LocationHierarchy[]} Location hierarchy. */
        const hierarchy = this.hierarchy?.map(
            (node) =>
                new LocationHierarchy({
                    resource: new LocationHierarchyResource(node),
                })
        );

        /** @type {NoteLocation[]} Locations. */
        const locations = this.locations?.map(
            (location) =>
                new NoteLocation({
                    resource: new NoteLocationResource(location),
                })
        );

        /** @type {readonly [typeof FIELDS[keyof FIELDS], any][]} */
        return [
            [FIELDS.id, this.id],
            [FIELDS.title, this.title],
            [FIELDS.content, this.content],
            [FIELDS.dateStart, this.dateStart],
            [FIELDS.dateEnd, this.dateEnd],
            [FIELDS.visible, this.visible],
            [FIELDS.author, author],
            [FIELDS.hierarchyId, this.hierarchyId],
            [FIELDS.hierarchy, hierarchy],
            [FIELDS.locations, locations],
            [FIELDS.locationsCount, this.locationsCount],
        ];
    }

    get path() {
        const separator = '/';
        if (this.hierarchyId >= 0) {
            // Filter for distinct hierarchies.
            const distinct = this.hierarchy.filter(
                (node, index) =>
                    index ===
                    this.hierarchy.findIndex((el) => el.id === node.id)
            );
            // Map distinct hierarchies into path names.
            const levels = distinct.map((node) => node.name);
            // TODO: Handle individual locations.
            return levels.join(separator);
        } else {
            return null;
        }
    }
}

/**
 * @class
 * @extends {Model<NotePayload, NoteResource>}
 */
export class Note extends Model {
    _initialState() {
        return Model.ComposeStateUsingFields(FIELDS);
    }

    _registerAliases() {
        return Model.ComposeAliasesUsingTable(ALIASES);
    }

    /**
     * Parse model from data structure.
     * @param {import('@/types').ExcludeMethods<NotePayload>} payload
     * @returns {this}
     */
    parsePayload(payload) {
        const instance = new NotePayload(payload);
        return this.parseArray(instance.entries());
    }

    /**
     * Transform model into data structure.
     * @returns {NotePayload}
     */
    toPayload() {
        return new NotePayload().parseModel(this);
    }

    /**
     * Parse model from data structure.
     * @param {import('@/types').ExcludeMethods<NoteResource>} resource
     * @returns {this}
     */
    parseResource(resource) {
        const instance = new NoteResource(resource);
        return this.parseArray(instance.entries());
    }

    /**
     * Transform model into data structure.
     * @returns {NoteResource}
     */
    toResource() {
        return new NoteResource().parseModel(this);
    }
}
