<template>
    <transition
        name="fade"
        mode="out-in"
    >
        <div v-if="isLoading">
            <LoadingSpinner />
        </div>
        <div v-else>
            <slot></slot>
        </div>
    </transition>
</template>

<script>
    // <!-- API -->
    import { defineComponent } from 'vue';

    // <!-- COMPONENTS -->
    import LoadingSpinner from '@/components/LoadingSpinner.vue';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'LoadingWrapper',
        props: {
            isLoading: Boolean,
        },
        components: {
            LoadingSpinner,
        },
        setup() {},
    });
</script>

<style scoped>
    /* we will explain what these classes do next! */
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }
</style>
