// <!-- TYPES -->
import { ECNBState } from '@/store/types/ECNBStore';
import { AccountState } from '@/store/types/accounts/state';
import { AccountModule } from '@/store/types/accounts/module';

/**
 * @type {import('vuex').Module<AccountState, ECNBState>} Module to export.
 */
const module = {
    namespaced: AccountModule.module.namespaced,
    state: AccountModule.module.state,
    modules: AccountModule.module.modules,
    getters: AccountModule.module.getters,
    mutations: AccountModule.module.mutations,
    actions: AccountModule.module.actions,
};

// EXPOSE
export default module;
