// <!-- API -->
import { ECNBStateMutations } from '@/store/types/ECNBStateMutations';

// <!-- TYPES -->
import { UserState } from '@/store/types/users/state';

/**
 * Synchronous {@link UserState} instance mutations.
 */
export class UserStateMutations extends ECNBStateMutations {
    /**
     * Provide access to direct state assignment operations.
     * @param {UserState} state State instance.
     */
    static set(state) {
        return {
            /**
             * Assign current user.
             * @param {User} payload
             */
            user: (payload) => {
                state.me = payload;
            },
        };
    }

    /**
     * Provide access to state update/merging operations.
     * @param {UserState} state State instance.
     */
    static patch(state) {
        const $ = UserStateMutations;
        return {
            /**
             * Update current user.
             * @param {User} payload
             */
            user: (payload) => {
                const current = state.me;
                const updated = Object.assign({}, current, payload);
                $.set(state).user(updated);
            },
        };
    }

    /**
     * Provide access to state clear operations.
     * @param {UserState} state State instance.
     */
    static clear(state) {
        const $ = UserStateMutations;
        return {
            /**
             * Clear current user.
             */
            user: () => {
                $.set(state).user(null);
            },
        };
    }
}
