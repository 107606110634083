// <!-- INTERFACES -->
import { ModelResource } from '@/utils/resources';
import { ModelAttributeFactory } from '@/utils/resources';
import { PlanPayload } from '@/payloads/v2/plans/PlanPayload';

// <!-- RELATIONSHIPS -->
import { SubscriptionModel } from '@/models/v2/subscriptions/SubscriptionModel';
import { OrganizationModel } from '@/models/v2/organizations/OrganizationModel';

/**
 * Create a specialized resource instance.
 *
 * @extends {ModelResource<PayloadData,ModelData>}
 * @implements {Resource.Model<PayloadData,ModelData>}
 * @implements {ModelData}
 */
export class PlanModel extends ModelResource {
    // <!-- TYPE ALIASES -->

    /** @typedef {Plan.Payload} PayloadData */
    /** @typedef {Plan.Model} ModelData */

    // <!-- STATIC FACTORY METHODS -->

    /**
     * Create resource instance from passed attributes.
     *
     * @param {Partial<ModelData>} attributes
     * @returns {PlanModel}
     */
    static create(attributes) {
        return new PlanModel(attributes);
    }

    /**
     * Create resource instance from passed attributes.
     *
     * @param {Partial<PayloadData>} data
     * @returns {PlanModel}
     */
    static fromPayload(data) {
        const attributes = PlanModel.attributesFromPayload(data);
        return PlanModel.create(attributes);
    }

    // <!-- STATIC UTILITY METHODS -->

    /** @type {Readonly<ModelData>} */
    static get defaults() {
        return {
            id: null,
            name: null,
            maxUsers: Infinity,
            maxLocations: Infinity,
            pricePerYear: 0,
            pricePerMonth: 0,
            isPaymentRequired: true,
            isFreePlan: false,
            isTrialPlan: false,
            isBasicPlan: false,
            isProfessionalPlan: false,
            isEnterprisePlan: false,
            areUsersUnlimited: false,
            areLocationsUnlimited: false,
            numberOfSubscriptions: NaN,
            subscriptions: [],
            numberOfOrganizations: NaN,
            organizations: [],
        };
    }

    /**
     * Create resource attributes from the payload data.
     *
     * @param {Partial<PayloadData>} payload
     * @returns {Partial<ModelData>}
     */
    static attributesFromPayload(payload = {}) {
        // Get transformer functions.
        const { coalesce, parse } = this;

        // Transform attributes.
        const numberOfSubscriptions = coalesce.count(
            payload.number_of_subscriptions
        );
        const numberOfOrganizations = coalesce.count(
            payload.number_of_organizations
        );
        const subscriptions = payload.subscriptions?.map(
            SubscriptionModel.fromPayload
        );
        const organizations = payload.organizations?.map(
            OrganizationModel.fromPayload
        );

        // Return the created state.
        return {
            id: payload.id,
            name: payload.name,
            maxUsers: coalesce.count(payload.max_users),
            maxLocations: coalesce.count(payload.max_locations),
            areUsersUnlimited: payload.are_users_unlimited,
            areLocationsUnlimited: payload.are_locations_unlimited,
            pricePerYear: parse.price(payload.price_per_year),
            pricePerMonth: parse.price(payload.price_per_month),
            isPaymentRequired: payload.is_payment_required,
            isFreePlan: payload.is_free_plan,
            isTrialPlan: payload.is_trial,
            isBasicPlan: payload.is_basic,
            isProfessionalPlan: payload.is_professional,
            isEnterprisePlan: payload.is_enterprise,
            numberOfSubscriptions,
            subscriptions,
            numberOfOrganizations,
            organizations,
        };
    }

    // <!-- CONSTRUCTOR -->

    /**
     * Create resource instance.
     *
     * @param {Partial<ModelData>} attributes
     */
    constructor(attributes) {
        super(
            attributes,
            ModelAttributeFactory.create(
                PlanModel.attributesFromPayload,
                PlanModel.defaults
            )
        );

        // Hydrate sub-resource.
        if (this.exists('organizations')) {
            this.organizations = this.organizations.map((model) => {
                if (!(model instanceof OrganizationModel)) {
                    return OrganizationModel.create(model);
                } else {
                    return model;
                }
            });
        }

        // Hydrate sub-resource.
        if (this.exists('subscriptions')) {
            this.subscriptions = this.subscriptions.map((model) => {
                if (!(model instanceof SubscriptionModel)) {
                    return SubscriptionModel.create(model);
                } else {
                    return model;
                }
            });
        }
    }

    /** Displays the specified tag when printing to the console. */
    get [Symbol.toStringTag]() {
        return 'Plan\\Model';
    }

    // <!-- RESOURCE INTERFACE -->

    /** Get shallow copy of this instance as a payload resource. */
    toPayload() {
        return PlanPayload.fromModel(this);
    }

    /** Get shallow copy of this instance as a model resource. */
    toModel() {
        return this.clone();
    }

    /** Get shallow copy of this instance. */
    clone() {
        return /** @type {this} */ (PlanModel.create(this));
    }

    // <!-- ATTRIBUTE::PROPERTIES -->

    get id() {
        return this.get('id');
    }

    set id(value) {
        this.set('id', value);
    }

    get name() {
        return this.get('name');
    }

    set name(value) {
        this.set('name', value);
    }

    get maxUsers() {
        return this.get('maxUsers');
    }

    set maxUsers(value) {
        this.set('maxUsers', value);
    }

    get maxLocations() {
        return this.get('maxLocations');
    }

    set maxLocations(value) {
        this.set('maxLocations', value);
    }

    get pricePerYear() {
        return this.get('pricePerYear');
    }

    set pricePerYear(value) {
        this.set('pricePerYear', value);
    }

    get pricePerMonth() {
        return this.get('pricePerMonth');
    }

    set pricePerMonth(value) {
        this.set('pricePerMonth', value);
    }

    get isPaymentRequired() {
        return this.get('isPaymentRequired');
    }

    set isPaymentRequired(value) {
        this.set('isPaymentRequired', value);
    }

    get isFreePlan() {
        return this.get('isFreePlan');
    }

    set isFreePlan(value) {
        this.set('isFreePlan', value);
    }

    get isTrialPlan() {
        return this.get('isTrialPlan');
    }

    set isTrialPlan(value) {
        this.set('isTrialPlan', value);
    }

    get isBasicPlan() {
        return this.get('isBasicPlan');
    }

    set isBasicPlan(value) {
        this.set('isBasicPlan', value);
    }

    get isProfessionalPlan() {
        return this.get('isProfessionalPlan');
    }

    set isProfessionalPlan(value) {
        this.set('isProfessionalPlan', value);
    }

    get isEnterprisePlan() {
        return this.get('isEnterprisePlan');
    }

    set isEnterprisePlan(value) {
        this.set('isEnterprisePlan', value);
    }

    get areUsersUnlimited() {
        return this.get('areUsersUnlimited');
    }

    set areUsersUnlimited(value) {
        this.set('areUsersUnlimited', value);
    }

    get areLocationsUnlimited() {
        return this.get('areLocationsUnlimited');
    }

    set areLocationsUnlimited(value) {
        this.set('areLocationsUnlimited', value);
    }

    get numberOfSubscriptions() {
        return this.get('numberOfSubscriptions');
    }

    set numberOfSubscriptions(value) {
        this.set('numberOfSubscriptions', value);
    }

    get numberOfOrganizations() {
        return this.get('numberOfOrganizations');
    }

    set numberOfOrganizations(value) {
        this.set('numberOfOrganizations', value);
    }

    get subscriptions() {
        return this.get('subscriptions');
    }

    set subscriptions(value) {
        this.set('subscriptions', value);
    }

    get organizations() {
        return this.get('organizations');
    }

    set organizations(value) {
        this.set('organizations', value);
    }
}

// <!-- EXPORTS -->
export default PlanModel;
