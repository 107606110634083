// NOTE: This serves as a proof-of-concept for how to access
//   and manipulate VNode slots (as well as render function usage with functional components).

// <!-- API -->
import { ref, h, Comment as CommentSymbol } from 'vue';

// <!-- UTILITIES -->
import { isString } from '@/utils/typeof';
import { formatVNode } from '@/utils/formatters';

// <!-- TYPES -->
/** @typedef {{ text?: String }} ITextCommentProps */
/** @typedef {{ separator?: String }} ISlotCommentProps */
/** @typedef {ITextCommentProps | ISlotCommentProps} ICommentProps */

/**
 * @type {V.FunctionalComponent<ICommentProps>}
 * Render function for an HTML comment to the DOM. Useful for debugging.
 */
export const Comment = (props, { slots }) => {
    /**
     * Rendered text will use slot content as a fallback if no `text` prop is provided.
     * @type {V.Ref<String>}
     */
    const renderedText = ref('<Comment>Your comment here!</Comment>');

    /**
     * Type guard.
     * @param {ICommentProps} props
     * @returns {props is ITextCommentProps}
     */
    const isTextComment = (props) =>
        'text' in props && isString(props.text) && props.text !== '';
    if (isTextComment(props)) {
        // Render the component as if there is not slot content...
        // Update the rendered text.
        renderedText.value = props.text;
    }

    /**
     * Type guard.
     * @param {ICommentProps} props
     * @returns {props is ISlotCommentProps}
     */
    const isSlotComment = (props) =>
        ('separator' in props && isString(props.separator)) ||
        (!!slots && !!slots.default);
    if (isSlotComment(props)) {
        // Render the component as if there is slot content...
        const separator = props.separator ?? '\n';
        // Get the lines.
        const lines = slots
            .default()
            .map((node) =>
                formatVNode({ value: node, options: { separator } })
            );
        if (lines.length > 1 && ['\n', '\r'].includes(separator)) {
            // Multi-line comments receive additional formatting.
            renderedText.value = `\n${lines
                .map((line) => `\t${line}`)
                .join(separator)}\n`; // Since wrapped in <!-- -->
        } else {
            // Single-line comment.
            // Update the renderedText. This will overwrite the `text` prop's value, if a slot is present.
            renderedText.value = lines.join(separator);
        }
    }

    // Return the VNode for the rendered comment.
    return h(CommentSymbol, ` ${renderedText.value} `);
};

// Register the props for the component.
Comment.props = ['text', 'separator'];

// <!-- DEFAULT -->
export default Comment;
