// <!-- UTILITY -->
import { Enum } from '@/utils/enums';

export const Theme = Enum.create({
    black: 'black', // #000000 (only)
    white: 'white', // #FFFFFF (only)
    primary: 'primary', // #1762a3 (and shades)
    secondary: 'secondary', // #EACF28 (and shades)
    info: 'info', // #1e92f4 (only)
    warning: 'warning', // #FBBF24 (only)
    success: 'success', // #4BB543 (only)
    failure: 'failure', // #DC2626 (only)
    // VVVV same colors but with different intent, in case we want to style later:
    danger: 'danger', // #DC2626 (only)
    error: 'error', // #DC2626 (only)
});

export default Theme;
