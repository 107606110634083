// <!-- UTILITIES -->
import { Unit } from '@/enums';
import { formatDecimal } from '@/utils/formatters';

// <!-- TYPES -->
/** @typedef {import('@/utils/formatters').IUnitFormatter} IUnitFormatter */

// <!-- EXPORTS -->
/**
 * @type {IUnitFormatter} Formats number with units according to the specified options. Set options.minimumFractionDigits and options.maximumFractionDigits to affect presence of decimals.
 *
 * @example
 * ```
 * formatUnit({ value: 12.3 }); // 12.3 ==> "12.3?"
 * formatUnit({ value: 12.3, options: { unit: '°C', minimumFractionDigits: 2, maximumFractionDigits: 2 } }); // 12.3 ==> "12.30°C"
 * ```
 */
export const formatUnit = ({ value, options }) => {
    // <!-- DESTRUCTURE -->
    const {
        unit = Unit.Unknown,
        minimumFractionDigits = 0,
        maximumFractionDigits = 2,
    } = options ?? {};

    // <!-- COMPUTE -->
    const decimal = formatDecimal({
        value,
        options: { minimumFractionDigits, maximumFractionDigits },
    });

    // <!-- CONDITIONALS -->
    const isValid = decimal !== '';

    // When the input value is a valid decimal...
    if (isValid) {
        // Compute the suffix.
        const suffix = `${unit}`;
        // Format the decimal with a temperature unit.
        const formatted = `${decimal}${suffix}`;
        // Return the formatted string.
        return formatted;
    }

    // When the input value is NOT a valid percent, return an empty string.
    return '';
};

// <!-- DEFAULT -->
export default formatUnit;
