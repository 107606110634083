import { BaseResource } from './BaseResource';
import { ResourceType } from '@/enums/ResourceType';
import { PayloadAttributeFactory } from './PayloadAttributeFactory';

/**
 * Payload resource instance.
 *
 * @template {{[key: string]: any }} [P = never] Payload data schema.
 * @template {{[key: string]: any }} [M = never] Model data schema.
 * @implements {Resource.Payload<P,M>}
 * @extends {BaseResource<'payload', P>}
 */
export class PayloadResource extends BaseResource {
    // <!-- STATIC UTILITY METHODS -->

    /**
     * Define the enumerable dynamic getters and setters for the instance attributes.
     *
     * @template {PayloadResource<any,any>} [R = PayloadResource<any,any>] Resource instance.
     * @param {R} resource
     */
    static defineEnumerableAttributes(resource) {
        // Bind the enumerable properties. (Uses dynamic getters/setters).
        const prototype = Object.getPrototypeOf(resource);
        for (const key in resource._factory.initialState) {
            Object.defineProperty(resource, key, {
                ...Object.getOwnPropertyDescriptor(prototype, key),
                enumerable: true,
            });
        }
    }

    // <!-- CONSTRUCTOR -->

    /**
     * Create a payload instance.
     *
     * @param {Partial<P>} attributes
     * @param {PayloadAttributeFactory<P,M>} factory
     */
    constructor(attributes, factory) {
        super(ResourceType.Payload, factory.fromPayload(attributes));

        // Bind the factory.
        this._factory = Object.freeze(factory);
        Object.defineProperty(this, '_factory', {
            writable: false,
            enumerable: false,
        });

        // Bind the enumerable attributes (using the current factory).
        PayloadResource.defineEnumerableAttributes(this);
    }

    // <!-- PAYLOAD INTERFACE -->

    /** Get shallow copy as a payload instance. */
    toPayload() {
        return this.clone();
    }

    /** Get shallow copy as a model instance. */
    toModel() {
        throw new Error('Mapper to model resource has not been implemented.');
        return null;
    }

    /**
     * Clone this instance.
     */
    clone() {
        return /** @type {this} */ (
            new PayloadResource({ ...this._attributes }, this._factory)
        );
    }
}

// <!-- EXPORTS -->
export default PayloadResource;
