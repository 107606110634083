// <!-- FORWARDED EXPORTS -->

export { OrganizationPayload } from '@/payloads/v2/organizations/OrganizationPayload';
export { AccessPayload } from '@/payloads/v2/organizations/AccessPayload';
export { OrganizationModel } from './OrganizationModel';
export { AccessModel } from './AccessModel';

// <!-- IMPORTS -->

import { OrganizationPayload } from '@/payloads/v2/organizations/OrganizationPayload';
import { AccessPayload } from '@/payloads/v2/organizations/AccessPayload';
import { OrganizationModel } from './OrganizationModel';
import { AccessModel } from './AccessModel';

// <!-- NAMESPACED EXPORTS -->

export const Access = {
    Payload: AccessPayload,
    Model: AccessModel,
};

export const Organization = {
    Payload: OrganizationPayload,
    Model: OrganizationModel,
    Access,
};
