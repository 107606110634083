/**
 * vue-select.js
 * Initializes the Vue Select plugin and provided helper for registering it globally.
 */

// <!-- API -->
import { createInput } from '@formkit/vue';

// <!-- COMPONENTS -->
import VueSelect from '@/components/inputs/VueSelect.vue';

// <!-- TYPES -->
/** @typedef {import('vue-select').VueSelectProps} VueSelectProps */

// <!-- STYLES -->
import 'vue-select/dist/vue-select.css';

/**
 * Register the component.
 * @param {V.App} app
 */
export const registerVueSelect = (app) => {
    // ts-ignore
    app.component('VueSelect', VueSelect);
    console.log(
        `[plugin::vue-select] registered as 'VueSelect' @ ${new Date().toLocaleTimeString()}`
    );
};

/**
 * Vue select events.
 */
export const VueSelectFormKitEvents = /** @type {const} */ ([
    'onInput',
    'onChange',
    'onOpen',
    'onClose',
    'onOptionSelecting',
    'onOptionSelected',
    'onOptionDeselecting',
    'onOptionCreated',
    'onSearch',
    'onSearchBlur',
    'onSearchFocus',
    'onSearchCompositionStart',
    'onSearchCompositionEnd',
    'onSearchInput',
    'onUpdateModelValue',
]);

/**
 * Acceptable props that are allowed on the input.
 * @typedef {Array<keyof Omit<VueSelectProps, 'components'|'value'|'label'|'uid'|'searchInputQuerySelector'> | typeof VueSelectFormKitEvents[Number] | 'vs__label' | 'debug' >}
 */
export const VueSelectFormKitProps = [
    'onInput',
    'onChange',
    'onOpen',
    'onClose',
    'onOptionSelecting',
    'onOptionSelected',
    'onOptionDeselecting',
    'onOptionCreated',
    'onSearch',
    'onSearchBlur',
    'onSearchFocus',
    'onSearchCompositionStart',
    'onSearchCompositionEnd',
    'onSearchInput',
    'onUpdateModelValue',
    'appendToBody',
    'autocomplete',
    'autoscroll',
    'calculatePosition',
    'clearable',
    'clearSearchOnBlur',
    'clearSearchOnSelect',
    'closeOnSelect',
    'createOption',
    'deselectFromDropdown',
    'dir',
    'disabled',
    'dropdownShouldOpen',
    'filter',
    'filterable',
    'filterBy',
    'getOptionKey',
    'getOptionLabel',
    'inputId',
    'loading',
    'mapKeydown',
    'multiple',
    'noDrop',
    'onTab',
    'options',
    'placeholder',
    'pushTags',
    'reduce',
    'resetOnOptionsChange',
    'searchable',
    'selectable',
    'selectOnTab',
    'selectOnKeyCodes',
    'tabindex',
    'taggable',
    'transition',
    'vs__label',
    'debug',
];

/**
 * Prepare the {@link VueSelect} input for FormKit.
 */
export const createVueSelectInput = () => {
    // ts-ignore
    const vs = createInput(VueSelect, { props: VueSelectFormKitProps });
    console.log(
        `(FormKit input[type='vs']) configured @ ${new Date().toLocaleTimeString()}`
    );
    return vs;
};
