// <!-- API -->
import { ECNBStateMutations } from '@/store/types/ECNBStateMutations';

// <!-- TYPES -->
import { AccountState } from '@/store/types/accounts/state/AccountState';
import { Organization } from '@/models/v2/organizations';

/**
 * Synchronous {@link AccountState} instance mutations.
 */
export class AccountStateMutations extends ECNBStateMutations {
    /**
     * Provide access to direct state assignment operations.
     * @param {AccountState} state State instance.
     */
    static set(state) {
        return {
            /**
             * Assign current organization.
             * @param {Organization.Model} payload
             */
            organization: (payload) => {
                state.organization = Organization.Model.create(payload).extract(
                    (attributes) => {
                        const access = Organization.Access.Model.create(
                            attributes.access
                        )._attributes;
                        return {
                            ...attributes,
                            access,
                        };
                    }
                );
            },
            /**
             * Assign current account.
             * @param {Account.Model} payload
             */
            account: (payload) => {
                state.account = Object.assign({}, payload);
            },
        };
    }
    /**
     * Provide access to state update/merging operations.
     * @param {AccountState} state State instance.
     */
    static patch(state) {
        const $ = AccountStateMutations;
        return {
            /**
             * Update current organization.
             * @param {Organization.Model} payload
             */
            organization: (payload) => {
                const current = Organization.Model.create(state.organization);
                const updated = current.fill(payload);
                $.set(state).organization(updated);
            },
            /**
             * Update current account.
             * @param {Account.Model} payload
             */
            account: (payload) => {
                const current = state.account;
                const updated = Object.assign({}, current, payload);
                $.set(state).account(updated);
            },
        };
    }
    /**
     * Provide access to state clear operations.
     * @param {AccountState} state State instance.
     */
    static clear(state) {
        const $ = AccountStateMutations;
        return {
            /**
             * Clear current organization.
             */
            organization: () => {
                $.set(state).organization(null);
            },
            /**
             * Clear current account.
             */
            account: () => {
                $.set(state).account(null);
            },
        };
    }
}
