const plugins = new Map([
    ['$log', console.log], // See: https://stackoverflow.com/a/68688746
    ['$info', console.info],
    ['$warn', console.warn],
    ['$error', console.error],
    ['$dir', console.dir],
]);

/**
 * Register the plugins as global properties on the Vue app instance.
 *
 * @param {V.App} app Vue application instance.
 */
export const registerDebugConsole = (app) => {
    plugins.forEach((value, key) => {
        app.config.globalProperties[key] = value;
        console.log(
            `[plugin::console::${key}] registered @ ${new Date().toLocaleTimeString()}`
        );
    });
};
