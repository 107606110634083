// <!-- IMPORTS -->

import {
    NodeTypes,
    NodeCodes,
    NodeSelector,
    NodeState,
} from '@/utils/tree/enums';

// <!-- COMPUTATIONS -->

/**
 * Compute node text hash based on its node code, display label, and resource identifier.
 * @example
 * ```
 * computedNodeTextHash(node); // '1A/B/C/D/Location Name [Serial #]2390'
 * ```
 * @type {(node: Readonly<Treeview.Node>) => string}
 */
export const computeNodeTextHash = (node) => {
    // const _code = NodeSelector.readCode(_id);
    // const _typeOrder = NodeSelector.getSortOrder(_code);
    const _id = node?.id ?? 'u_'; // default to unknown empty id.
    const _text = node?.text ?? Node.DefaultNodeLabel;
    const _key = NodeSelector.readResourceID(_id);
    return `${_text}${_key}`;
    // return `${_typeOrder}${_text}${_key}`;
};

// <!-- GENERIC TYPE GUARDS -->

/** @type {Treeview.Guards.NodeGuard} */
const isNodeInstance = (value) => {
    /** @type {Readonly<(keyof Treeview.Node)[]>} */
    const keys = Object.freeze([
        'id',
        'type',
        'text',
        'parent',
        'children',
        'state',
    ]);
    return typeof value === 'object' && keys.every((key) => key in value);
};

/** @type {Treeview.Guards.NodeInstanceValidator} */
const hasParentReference = (node) =>
    isNodeInstance(node) &&
    !!node.parent &&
    typeof node.parent === 'string' &&
    node.parent !== '';

/** @type {Treeview.Guards.NodeInstanceValidator} */
const hasNoParentReference = (node) => !hasParentReference(node);

/** @type {Treeview.Guards.NodeInstanceValidator} */
const hasZeroChildReferences = (node) =>
    isNodeInstance(node) && !hasOneOrMoreChildReferences(node);

/** @type {Treeview.Guards.NodeInstanceValidator} */
const hasOneOrMoreChildReferences = (node) =>
    isNodeInstance(node) &&
    !!node.children &&
    Array.isArray(node.children) &&
    node.children.length > 0;

// <!-- SPECIFIC TYPE GUARDS -->

/** @type {Treeview.Guards.SpecificNodeValidator} */
const isNodeOfSpecificType = (value, selector) => {
    const type = NodeSelector.getType(selector);
    return (
        isNodeInstance(value) && NodeSelector.isSpecificType(value.type, type)
    );
};

/** @type {Treeview.Guards.SpecificNodeIDValidator} */
const isSpecificNode = (value, id) => {
    const type = NodeSelector.readType(id);
    return (
        NodeSelector.isType(type) &&
        isNodeOfSpecificType(value, type) &&
        value.id === id
    );
};

/** @type {Treeview.Guards.NodeRelatedValidator} */
const hasSpecificParent = (node, id) =>
    hasParentReference(node) && node.parent === id;

/** @type {Treeview.Guards.NodeRelatedValidator} */
const hasSpecificChild = (node, id) =>
    hasOneOrMoreChildReferences(node) && node.children.includes(id);

// <!-- SELECTORS -->

/** @type {Treeview.Selectors.NodeTypeValidatorSelector} */
const getNodeTypeValidator = (selector, expected = true) => {
    const type = NodeSelector.getType(selector);
    /**
     * Prepared validator.
     * @param {Readonly<Node>} source
     * @returns {boolean}
     */
    const validator = (source) =>
        isNodeOfSpecificType(source, type) === expected;
    // RETURN prepared validator.
    return validator;
};

// <!-- EXTRACTORS -->

/** @type {Treeview.Extractors.NodeStateExtractor} */
const getNodeState = (node) => node.state;

/** @type {Treeview.Extractors.NodeTextHashExtractor} */
const getNodeTextHash = (node) => computeNodeTextHash(node);

// <!-- FACTORIES -->

/** @type {Treeview.Factories.NodeFactory} */
const createNode = (props = {}) => {
    const defaults = /** @type {Treeview.Node} */ ({
        ...Node.DefaultNode,
    });
    const instance = Object.assign({}, defaults, props);
    return instance;
};

// <!-- DUPLICATORS -->

/** @type {Treeview.Duplicators.NodeDuplicator} */
const cloneNode = (source) => {
    /** @type {Treeview.Node} */
    const instance = Object.assign({}, source);
    instance.children = [...instance.children];
    instance.state = NodeState.clone(instance.state);
    return instance;
};

// <!-- MUTATIONS -->

/** @type {Treeview.Mutations.NodeMutation} */
const overrideNode = (source, patch) => {
    const target = cloneNode(source);
    const instance = Object.assign(target, patch);
    return instance;
};

// <!-- SORTERS -->

/**
 * Compare two node instances based on their text hash.
 * @type {(a: Treeview.Node, b: Treeview.Node) => number}
 */
const compareNodesByTextHash = (a, b) => {
    const _a = isNodeInstance(a) ? computeNodeTextHash(a) : '?';
    const _b = isNodeInstance(b) ? computeNodeTextHash(b) : '?';
    return _a.localeCompare(_b);
};

// <!-- CLASSES -->

/**
 * @class
 * Treeview node class helper.
 */
export class Node {
    static get DefaultNodeLabel() {
        const label = '???';
        return label;
    }
    static get DefaultNode() {
        return Object.freeze(
            /** @type {Treeview.Node} */ ({
                id: null,
                type: null,
                parent: null,
                children: [],
                text: Node.DefaultNodeLabel,
                state: NodeState.DefaultState,
            })
        );
    }
    static get DefaultHierarchyNode() {
        return Object.freeze(
            Node.override(Node.DefaultNode, {
                id: NodeSelector.format(NodeCodes.Hierarchy, '0'),
                type: NodeTypes.Hierarchy,
            })
        );
    }
    static get DefaultLocationNode() {
        return Object.freeze(
            Node.override(Node.DefaultNode, {
                id: NodeSelector.format(NodeCodes.Location, '0'),
                type: NodeTypes.Location,
            })
        );
    }
    static get DefaultWeatherStationNode() {
        return Object.freeze(
            Node.override(Node.DefaultNode, {
                id: NodeSelector.format(NodeCodes.Station, '0'),
                type: NodeTypes.Station,
            })
        );
    }

    // <!-- GENERIC TYPE GUARDS -->
    static isInstance = isNodeInstance;
    static isRoot = hasNoParentReference;
    static isLeaf = hasZeroChildReferences;
    static hasParent = hasParentReference;
    static hasChildren = hasOneOrMoreChildReferences;
    static isEmpty = hasZeroChildReferences;
    static isNotEmpty = hasOneOrMoreChildReferences;

    // <!-- SPECIFIC TYPE GUARDS -->
    static isSpecificNode = isSpecificNode;
    static isOfType = isNodeOfSpecificType;
    static isChildOf = hasSpecificParent;
    static isParentOf = hasSpecificChild;

    // <!-- SELECTORS -->
    static get isHierarchyNode() {
        return getNodeTypeValidator(NodeTypes.Hierarchy, true);
    }
    static get isNotHierarchyNode() {
        return getNodeTypeValidator(NodeTypes.Hierarchy, false);
    }
    static get isLocationNode() {
        return getNodeTypeValidator(NodeTypes.Location, true);
    }
    static get isNotLocationNode() {
        return getNodeTypeValidator(NodeTypes.Location, false);
    }
    static get isWeatherStationNode() {
        return getNodeTypeValidator(NodeTypes.Station, true);
    }
    static get isNotWeatherStationNode() {
        return getNodeTypeValidator(NodeTypes.Station, false);
    }

    // <!-- EXTRACTORS -->
    static hash = getNodeTextHash;
    static getState = getNodeState;

    // <!-- FACTORIES -->
    static create = createNode;

    // <!-- DUPLICATORS -->
    static clone = cloneNode;

    // <!-- MUTATIONS -->
    static override = overrideNode;

    // <!-- SORTERS -->
    static compare = compareNodesByTextHash;
}

// <!-- DEFAULT -->
export default Node;
