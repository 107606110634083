/**
 * Type guard. Allows type inference for the tested variable.
 * @param {any} value The value to test.
 * @returns {value is Node} `true` if input argument is of type {@link Node} (eg. {@link Element}, {@link HTMLElement}, etc. inherit from the {@link Node} interface).
 */
export const isNode = (value) => {
    if (typeof Node === 'object') {
        // Browser defines Node as an object...
        return value instanceof Node;
    } else {
        // Browser defines Node as a function.
        // duck-typing...
        return (
            value !== null &&
            value !== undefined &&
            typeof value === 'object' &&
            typeof value?.nodeType === 'number' &&
            typeof value?.nodeName === 'string'
        );
    }
};

// <!-- DEFAULT -->
export default isNode;
