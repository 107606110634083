// <!-- API -->
import { Tree, NodeTypes } from '@/utils/tree';

// <!-- TYPES -->
/** @typedef {import('@/utils/filters').ILocationFilter} ILocationFilter */

/**
 * Create a location filter.
 * @param {Readonly<Treeview.TreeProps>} [props]
 * @returns {ILocationFilter}
 */
const createFilter = (props) => {
    const tree = Tree.create(props);
    return new LocationFilter({ tree });
};

/**
 * Clone a location filter.
 * @param {Readonly<ILocationFilter>} [source]
 * @returns {ILocationFilter}
 */
const cloneFilter = (source) => {
    const tree = Tree.clone(source.tree);
    return new LocationFilter({ tree });
};

/**
 * @class
 * Treeview filter for the Locations resource.
 * @implements {ILocationFilter}
 */
export class LocationFilter {
    // <!-- FACTORIES -->
    static create = createFilter;

    // <!-- DUPLICATORS -->
    static clone = cloneFilter;

    /**
     * Create new filter instance as result of merging an existing filter with updated properties.
     * @param {Readonly<ILocationFilter>} source
     * @param {Readonly<Partial<ILocationFilter>>} patch
     */
    static merge = (source, patch) => {
        const tree = Tree.override(source.tree, patch.tree);
        const instance = new LocationFilter({ tree });
        return instance;
    };

    /**
     * Create a location filter.
     * @param {Readonly<Partial<ILocationFilter>>} [props]
     */
    constructor(props) {
        /** @type {NodeTypes['Location']} */
        this.id = NodeTypes.Location;
        /** @type {Treeview.Tree} */
        this.tree = props.tree ?? Tree.create();
    }
}
