/**
 * timezones.js
 *
 * Initializes and configures the timezone polyfill.
 * © 2020 Google, Ecma International
 */

// Should we polyfill our getSupportedTimeZones function?
if (Intl.getSupportedTimeZones == undefined) {
    if (Intl.supportedValuesOf !== undefined) {
        Intl.getSupportedTimeZones = () => Intl.supportedValuesOf('timeZone');
    } else {
        Intl.getSupportedTimeZones = function () {
            const TIMEZONES_IN_CLDR = [
                // DATA from CLDR
                // Generated by
                // file="https://raw.githubusercontent.com/unicode-org/cldr/master/common/supplemental/metaZones.xml"
                //  curl $file 2> /dev/null | egrep -e "<timezone type" | \
                //  cut -d \" -f2|sed -e 's/^/"/' | sed -e 's/$/",/' | tr '\n' ' '
                'Africa/Abidjan',
                'Africa/Accra',
                'Africa/Addis_Ababa',
                'Africa/Algiers',
                'Africa/Asmera',
                'Africa/Bamako',
                'Africa/Bangui',
                'Africa/Banjul',
                'Africa/Bissau',
                'Africa/Blantyre',
                'Africa/Brazzaville',
                'Africa/Bujumbura',
                'Africa/Cairo',
                'Africa/Casablanca',
                'Africa/Ceuta',
                'Africa/Conakry',
                'Africa/Dakar',
                'Africa/Dar_es_Salaam',
                'Africa/Djibouti',
                'Africa/Douala',
                'Africa/El_Aaiun',
                'Africa/Freetown',
                'Africa/Gaborone',
                'Africa/Harare',
                'Africa/Johannesburg',
                'Africa/Juba',
                'Africa/Kampala',
                'Africa/Khartoum',
                'Africa/Kigali',
                'Africa/Kinshasa',
                'Africa/Lagos',
                'Africa/Libreville',
                'Africa/Lome',
                'Africa/Luanda',
                'Africa/Lubumbashi',
                'Africa/Lusaka',
                'Africa/Malabo',
                'Africa/Maputo',
                'Africa/Maseru',
                'Africa/Mbabane',
                'Africa/Mogadishu',
                'Africa/Monrovia',
                'Africa/Nairobi',
                'Africa/Ndjamena',
                'Africa/Niamey',
                'Africa/Nouakchott',
                'Africa/Ouagadougou',
                'Africa/Porto-Novo',
                'Africa/Sao_Tome',
                'Africa/Tripoli',
                'Africa/Tunis',
                'Africa/Windhoek',
                'America/Adak',
                'America/Anchorage',
                'America/Anguilla',
                'America/Antigua',
                'America/Araguaina',
                'America/Argentina/La_Rioja',
                'America/Argentina/Rio_Gallegos',
                'America/Argentina/Salta',
                'America/Argentina/San_Juan',
                'America/Argentina/San_Luis',
                'America/Argentina/Tucuman',
                'America/Argentina/Ushuaia',
                'America/Aruba',
                'America/Asuncion',
                'America/Bahia',
                'America/Bahia_Banderas',
                'America/Barbados',
                'America/Belem',
                'America/Belize',
                'America/Blanc-Sablon',
                'America/Boa_Vista',
                'America/Bogota',
                'America/Boise',
                'America/Buenos_Aires',
                'America/Cambridge_Bay',
                'America/Campo_Grande',
                'America/Cancun',
                'America/Caracas',
                'America/Catamarca',
                'America/Cayenne',
                'America/Cayman',
                'America/Chicago',
                'America/Chihuahua',
                'America/Coral_Harbour',
                'America/Cordoba',
                'America/Costa_Rica',
                'America/Creston',
                'America/Cuiaba',
                'America/Curacao',
                'America/Danmarkshavn',
                'America/Dawson',
                'America/Dawson_Creek',
                'America/Denver',
                'America/Detroit',
                'America/Dominica',
                'America/Edmonton',
                'America/Eirunepe',
                'America/El_Salvador',
                'America/Fort_Nelson',
                'America/Fortaleza',
                'America/Glace_Bay',
                'America/Godthab',
                'America/Goose_Bay',
                'America/Grand_Turk',
                'America/Grenada',
                'America/Guadeloupe',
                'America/Guatemala',
                'America/Guayaquil',
                'America/Guyana',
                'America/Halifax',
                'America/Havana',
                'America/Hermosillo',
                'America/Indiana/Knox',
                'America/Indiana/Marengo',
                'America/Indiana/Petersburg',
                'America/Indiana/Tell_City',
                'America/Indiana/Vevay',
                'America/Indiana/Vincennes',
                'America/Indiana/Winamac',
                'America/Indianapolis',
                'America/Inuvik',
                'America/Iqaluit',
                'America/Jamaica',
                'America/Jujuy',
                'America/Juneau',
                'America/Kentucky/Monticello',
                'America/Kralendijk',
                'America/La_Paz',
                'America/Lima',
                'America/Los_Angeles',
                'America/Louisville',
                'America/Lower_Princes',
                'America/Maceio',
                'America/Managua',
                'America/Manaus',
                'America/Marigot',
                'America/Martinique',
                'America/Matamoros',
                'America/Mazatlan',
                'America/Mendoza',
                'America/Menominee',
                'America/Merida',
                'America/Metlakatla',
                'America/Mexico_City',
                'America/Miquelon',
                'America/Moncton',
                'America/Monterrey',
                'America/Montevideo',
                'America/Montserrat',
                'America/Nassau',
                'America/New_York',
                'America/Nipigon',
                'America/Nome',
                'America/Noronha',
                'America/North_Dakota/Beulah',
                'America/North_Dakota/Center',
                'America/North_Dakota/New_Salem',
                'America/Ojinaga',
                'America/Panama',
                'America/Pangnirtung',
                'America/Paramaribo',
                'America/Phoenix',
                'America/Port_of_Spain',
                'America/Port-au-Prince',
                'America/Porto_Velho',
                'America/Puerto_Rico',
                'America/Punta_Arenas',
                'America/Rainy_River',
                'America/Rankin_Inlet',
                'America/Recife',
                'America/Regina',
                'America/Resolute',
                'America/Rio_Branco',
                'America/Santa_Isabel',
                'America/Santarem',
                'America/Santiago',
                'America/Santo_Domingo',
                'America/Sao_Paulo',
                'America/Scoresbysund',
                'America/Sitka',
                'America/St_Barthelemy',
                'America/St_Johns',
                'America/St_Kitts',
                'America/St_Lucia',
                'America/St_Thomas',
                'America/St_Vincent',
                'America/Swift_Current',
                'America/Tegucigalpa',
                'America/Thule',
                'America/Thunder_Bay',
                'America/Tijuana',
                'America/Toronto',
                'America/Tortola',
                'America/Vancouver',
                'America/Whitehorse',
                'America/Winnipeg',
                'America/Yakutat',
                'America/Yellowknife',
                'Antarctica/Casey',
                'Antarctica/Davis',
                'Antarctica/DumontDUrville',
                'Antarctica/Macquarie',
                'Antarctica/Mawson',
                'Antarctica/McMurdo',
                'Antarctica/Palmer',
                'Antarctica/Rothera',
                'Antarctica/Syowa',
                'Antarctica/Troll',
                'Antarctica/Vostok',
                'Arctic/Longyearbyen',
                'Asia/Aden',
                'Asia/Almaty',
                'Asia/Amman',
                'Asia/Anadyr',
                'Asia/Aqtau',
                'Asia/Aqtobe',
                'Asia/Ashgabat',
                'Asia/Atyrau',
                'Asia/Baghdad',
                'Asia/Bahrain',
                'Asia/Baku',
                'Asia/Bangkok',
                'Asia/Barnaul',
                'Asia/Beirut',
                'Asia/Bishkek',
                'Asia/Brunei',
                'Asia/Calcutta',
                'Asia/Chita',
                'Asia/Choibalsan',
                'Asia/Colombo',
                'Asia/Damascus',
                'Asia/Dhaka',
                'Asia/Dili',
                'Asia/Dubai',
                'Asia/Dushanbe',
                'Asia/Famagusta',
                'Asia/Gaza',
                'Asia/Hebron',
                'Asia/Hong_Kong',
                'Asia/Hovd',
                'Asia/Irkutsk',
                'Asia/Jakarta',
                'Asia/Jayapura',
                'Asia/Jerusalem',
                'Asia/Kabul',
                'Asia/Kamchatka',
                'Asia/Karachi',
                'Asia/Katmandu',
                'Asia/Khandyga',
                'Asia/Kirov',
                'Asia/Krasnoyarsk',
                'Asia/Kuala_Lumpur',
                'Asia/Kuching',
                'Asia/Kuwait',
                'Asia/Macau',
                'Asia/Magadan',
                'Asia/Makassar',
                'Asia/Manila',
                'Asia/Muscat',
                'Asia/Nicosia',
                'Asia/Novokuznetsk',
                'Asia/Novosibirsk',
                'Asia/Omsk',
                'Asia/Oral',
                'Asia/Phnom_Penh',
                'Asia/Pontianak',
                'Asia/Pyongyang',
                'Asia/Qatar',
                'Asia/Qostanay',
                'Asia/Qyzylorda',
                'Asia/Rangoon',
                'Asia/Riyadh',
                'Asia/Saigon',
                'Asia/Sakhalin',
                'Asia/Samarkand',
                'Asia/Seoul',
                'Asia/Shanghai',
                'Asia/Singapore',
                'Asia/Srednekolymsk',
                'Asia/Taipei',
                'Asia/Tashkent',
                'Asia/Tbilisi',
                'Asia/Tehran',
                'Asia/Thimphu',
                'Asia/Tokyo',
                'Asia/Tomsk',
                'Asia/Ulaanbaatar',
                'Asia/Urumqi',
                'Asia/Ust-Nera',
                'Asia/Vientiane',
                'Asia/Vladivostok',
                'Asia/Yakutsk',
                'Asia/Yekaterinburg',
                'Asia/Yerevan',
                'Atlantic/Azores',
                'Atlantic/Bermuda',
                'Atlantic/Canary',
                'Atlantic/Cape_Verde',
                'Atlantic/Faeroe',
                'Atlantic/Madeira',
                'Atlantic/Reykjavik',
                'Atlantic/South_Georgia',
                'Atlantic/St_Helena',
                'Atlantic/Stanley',
                'Australia/Adelaide',
                'Australia/Brisbane',
                'Australia/Broken_Hill',
                'Australia/Currie',
                'Australia/Darwin',
                'Australia/Eucla',
                'Australia/Hobart',
                'Australia/Lindeman',
                'Australia/Lord_Howe',
                'Australia/Melbourne',
                'Australia/Perth',
                'Australia/Sydney',
                'CST6CDT',
                'Etc/GMT',
                'EST5EDT',
                'Europe/Amsterdam',
                'Europe/Andorra',
                'Europe/Astrakhan',
                'Europe/Athens',
                'Europe/Belgrade',
                'Europe/Berlin',
                'Europe/Bratislava',
                'Europe/Brussels',
                'Europe/Bucharest',
                'Europe/Budapest',
                'Europe/Busingen',
                'Europe/Chisinau',
                'Europe/Copenhagen',
                'Europe/Dublin',
                'Europe/Gibraltar',
                'Europe/Guernsey',
                'Europe/Helsinki',
                'Europe/Isle_of_Man',
                'Europe/Istanbul',
                'Europe/Jersey',
                'Europe/Kaliningrad',
                'Europe/Kiev',
                'Europe/Lisbon',
                'Europe/Ljubljana',
                'Europe/London',
                'Europe/Luxembourg',
                'Europe/Madrid',
                'Europe/Malta',
                'Europe/Mariehamn',
                'Europe/Minsk',
                'Europe/Monaco',
                'Europe/Moscow',
                'Europe/Oslo',
                'Europe/Paris',
                'Europe/Podgorica',
                'Europe/Prague',
                'Europe/Riga',
                'Europe/Rome',
                'Europe/Samara',
                'Europe/San_Marino',
                'Europe/Sarajevo',
                'Europe/Saratov',
                'Europe/Simferopol',
                'Europe/Skopje',
                'Europe/Sofia',
                'Europe/Stockholm',
                'Europe/Tallinn',
                'Europe/Tirane',
                'Europe/Ulyanovsk',
                'Europe/Uzhgorod',
                'Europe/Vaduz',
                'Europe/Vatican',
                'Europe/Vienna',
                'Europe/Vilnius',
                'Europe/Volgograd',
                'Europe/Warsaw',
                'Europe/Zagreb',
                'Europe/Zaporozhye',
                'Europe/Zurich',
                'Indian/Antananarivo',
                'Indian/Chagos',
                'Indian/Christmas',
                'Indian/Cocos',
                'Indian/Comoro',
                'Indian/Kerguelen',
                'Indian/Mahe',
                'Indian/Maldives',
                'Indian/Mauritius',
                'Indian/Mayotte',
                'Indian/Reunion',
                'MST7MDT',
                'Pacific/Apia',
                'Pacific/Auckland',
                'Pacific/Bougainville',
                'Pacific/Chatham',
                'Pacific/Easter',
                'Pacific/Efate',
                'Pacific/Enderbury',
                'Pacific/Fakaofo',
                'Pacific/Fiji',
                'Pacific/Funafuti',
                'Pacific/Galapagos',
                'Pacific/Gambier',
                'Pacific/Guadalcanal',
                'Pacific/Guam',
                'Pacific/Honolulu',
                'Pacific/Johnston',
                'Pacific/Kiritimati',
                'Pacific/Kosrae',
                'Pacific/Kwajalein',
                'Pacific/Majuro',
                'Pacific/Marquesas',
                'Pacific/Midway',
                'Pacific/Nauru',
                'Pacific/Niue',
                'Pacific/Norfolk',
                'Pacific/Noumea',
                'Pacific/Pago_Pago',
                'Pacific/Palau',
                'Pacific/Pitcairn',
                'Pacific/Ponape',
                'Pacific/Port_Moresby',
                'Pacific/Rarotonga',
                'Pacific/Saipan',
                'Pacific/Tahiti',
                'Pacific/Tarawa',
                'Pacific/Tongatapu',
                'Pacific/Truk',
                'Pacific/Wake',
                'Pacific/Wallis',
                'PST8PDT',
            ];

            const isSupported = function (timeZone) {
                try {
                    let nf = new Intl.DateTimeFormat('en', { timeZone });
                    let o = nf.resolvedOptions().timeZone;
                    if (o == timeZone) {
                        return true;
                    }
                    // console.log(timeZone + " is accepted but changed to " + o);
                } catch (e) {
                    // console.log(timeZone + " is not supported");
                }
                return false;
            };

            var r = [];
            TIMEZONES_IN_CLDR.forEach(function (timeZone) {
                if (isSupported(timeZone)) r.push(timeZone);
            });
            return r;
        };
    }
}

/**
 * Use the timezone plugin.
 *
 * @param {Vue.App} app Vue application instance.
 * @returns Vue app instance.
 */
export const registerTimeZones = (app) => {
    const key = `$timezones`;
    // ts-ignore
    app.config.globalProperties[key] = Intl.getSupportedTimeZones();
    console.log(
        `[plugin::${key}] registered @ ${new Date().toLocaleTimeString()}`
    );
};
