// <!-- IMPORTS -->

import { NodeSelector } from '@/utils/tree/enums';
import { Node } from '@/utils/tree/node/Node';

// <!-- GENERIC TYPE GUARDS -->

/** @type {Treeview.Guards.NodeRecordGuard} */
const isNodeRecord = (value) => {
    return value !== null && value !== undefined && typeof value === 'object';
};

/** @type {Treeview.Guards.NodeRecordGuard} */
const isNonEmptyRecord = (value) => {
    return (
        isNodeRecord(value) &&
        Object.values(value).filter((n) => Node.isInstance(n)).length > 0
    );
};

// <!-- SPECIFIC TYPE GUARDS -->

/** @type {Treeview.Guards.NodeExistsValidator} */
const isSpecificNodePresent = (record, id) => {
    const node = findNodeByID(record, id);
    return !!node && Node.isInstance(node);
};

// <!-- SELECTORS -->

/**
 * @type {Treeview.Selectors.NodeCollectionSelector}
 * Get collection of all nodes in the record, in insertion order.
 */
const getNodeRecordIndex = (record) => {
    if (isNodeRecord(record)) {
        const nodes = Object.values(record);
        return nodes;
    } else {
        return [];
    }
};

/** @type {Treeview.Selectors.NodeSelector} */
const findNodeByID = (record, id) => {
    if (isNodeRecord(record) && NodeSelector.isFormat(id) && id in record) {
        return record[id];
    }
    return undefined;
};

/** @type {Treeview.Selectors.NodeResourceSelector} */
const findNodeByResourceID = (record, selector, id) => {
    const code = NodeSelector.getCode(selector);
    const identifier = String(id);
    const formattedID = NodeSelector.format(code, identifier);
    return findNodeByID(record, formattedID);
};

// <!-- EXTRACTORS -->

/**
 * Get the node record roots.
 * @param {Treeview.NodeRecord} record
 * @returns {Treeview.Node[]}
 */
const getNodeRecordRoots = (record) => {
    if (isNodeRecord(record)) {
        const nodes = getNodeRecordIndex(record);
        return nodes.filter((n) => Node.isRoot(n));
    }
    // No roots if record is invalid.
    return [];
};

/**
 * Get the node record leaves.
 * @param {Treeview.NodeRecord} record
 */
const getNodeRecordLeaves = (record) => {
    if (isNodeRecord(record)) {
        const nodes = getNodeRecordIndex(record);
        const leaves = nodes.filter((n) => Node.isLeaf(n));
        return leaves;
    }
    // No leaves if record is invalid.
    return [];
};

/**
 * Find path as an array of node ids from the subtree root to the specified node.
 * @param {Treeview.NodeRecord} record
 * @param {string | Treeview.Node} selector
 * @returns {string[]}
 */
const getNodePath = (record, selector) => {
    if (isNodeRecord(record) && !!selector) {
        if (Node.isInstance(selector)) {
            // selector is a node...
            const ancestors = getNodeAncestorsAndSelf(record, selector);
            return ancestors.map((n) => n.id);
        }
        if (NodeSelector.isFormat(selector)) {
            // selector is a string...
            const node = findNodeByID(record, selector);
            const ancestors = getNodeAncestorsAndSelf(record, node);
            return ancestors.map((n) => n.id);
        }
    }
    return [];
};

/** @type {Treeview.Extractors.RelatedNodeExtractor} */
const getNodeParent = (record, node) => {
    if (isNodeRecord(record) && Node.isInstance(node) && Node.hasParent(node)) {
        return findNodeByID(record, node.parent);
    }
    return undefined;
};

/** @type {Treeview.Extractors.RelatedNodeCollectionExtractor} */
const getNodeChildren = (record, node) => {
    if (
        isNodeRecord(record) &&
        Node.isInstance(node) &&
        Node.hasChildren(node)
    ) {
        const children = node.children.map((id) => findNodeByID(record, id));
        const filtered = children.filter((child) => Node.isInstance(child));
        return filtered;
    }
    return [];
};

/** @type {Treeview.Extractors.RelatedNodeExtractor} */
const getNodeRoot = (record, node) => {
    if (!isNodeRecord(record) || !Node.isInstance(node)) {
        // INVALID node or record.
        return undefined;
    }

    if (Node.isRoot(node)) {
        // END search.
        return node;
    }

    const parent = getNodeParent(record, node);
    return Node.isInstance(parent) ? getNodeRoot(record, parent) : node;
};

/** @type {Treeview.Extractors.RelatedNodeCollectionExtractor} */
const getNodeLeaves = (record, node) => {
    if (!isNodeRecord(record) || !Node.isInstance(node)) {
        // INVALID node or record.
        return [];
    }

    if (Node.isLeaf(node)) {
        // BASE CASE - node is a leaf. Return array of 1 element.
        return [node];
    }

    const leaves = [];
    const children = getNodeChildren(record, node) ?? [];
    const filtered = children.filter((child) => Node.isInstance(child));
    filtered.forEach((child) => {
        const _childLeaves = getNodeLeaves(record, child);
        leaves.push(..._childLeaves);
    });

    if (leaves.length === 0) {
        // BASE CASE - no leaves were found, so current node is a leaf.
        leaves.push(node);
    }

    // RETURN leaves.
    return leaves;
};

/** @type {Treeview.Extractors.RelatedNodeCollectionExtractor} */
const getNodeSiblingsAndSelf = (record, node) => {
    if (isNodeRecord(record) && Node.isInstance(node)) {
        const _self = node;
        const _parent = getNodeParent(record, _self);
        const siblings = getNodeChildren(record, _parent);
        return siblings;
    }
    // INVALID record or node.
    return [];
};

/** @type {Treeview.Extractors.RelatedNodeCollectionExtractor} */
const getNodeSiblings = (record, node) =>
    getNodeSiblingsAndSelf(record, node).filter((s) => s.id !== node.id);

/** @type {Treeview.Extractors.RelatedNodeCollectionExtractor} */
const getNodeAncestorsAndSelf = (record, node) => {
    if (isNodeRecord(record) && Node.isInstance(node)) {
        const _self = node;
        /** @type {Treeview.Node[]} */
        const ancestors = [];
        // LOOP through ancestors, adding them as detected.
        let current = _self;
        while (Node.isInstance(current) && Node.hasParent(current)) {
            // GET parent of current node and ensure it's unique.
            const parent = getNodeParent(record, current);
            // STOP if parent is already in array of ancestors (due to possible circular reference).
            if (ancestors.some((n) => n.id === parent.id)) break;
            // ADD parent to the array of ancestors.
            if (Node.isInstance(parent)) ancestors.unshift(parent);
            // SET current node to the parent.
            current = parent ?? undefined;
        }
        // RETURN ancestors with _self at end.
        ancestors.push(_self);
        return ancestors;
    }
    // INVALID record or node.
    return [];
};

/** @type {Treeview.Extractors.RelatedNodeCollectionExtractor} */
const getNodeAncestors = (record, node) =>
    getNodeAncestorsAndSelf(record, node).filter((a) => a.id !== node.id);

/** @type {Treeview.Extractors.RelatedNodeCollectionExtractor} */
const getNodeDescendantsAndSelf = (record, node) => {
    if (isNodeRecord(record) && Node.isInstance(node)) {
        const _self = node;
        /** @type {Treeview.Node[]} */
        const descendants = [];
        // GET immediate children of the current node.
        const children = getNodeChildren(record, _self);
        // FOR EACH child, append the descendants.
        children.forEach((child) => {
            if (!Node.isInstance(child)) {
                // EXIT when child is invalid.
                return;
            } else {
                // APPEND immediate child to end.
                descendants.push(child);
            }
            if (Node.isEmpty(child)) {
                // EXIT when child has no other children.
                return;
            } else {
                // APPEND child's descendants.
                descendants.push(...getNodeDescendants(record, child));
            }
        });
        // RETURN descendants with _self at start.
        descendants.unshift(_self);
        return descendants;
    }
    // INVALID record or node.
    return [];
};

/** @type {Treeview.Extractors.RelatedNodeCollectionExtractor} */
const getNodeDescendants = (record, node) =>
    getNodeDescendantsAndSelf(record, node).filter((d) => d.id !== node.id);

/** @type {Treeview.Extractors.NodeExtractor} */
const getUnassignedHierarchyRoot = (record) => record['h0'];

// <!-- FACTORIES -->

/** @type {Treeview.Factories.NodeRecordFactory} */
const createNodeRecord = (props = {}) => {
    const defaults = /** @type {Treeview.NodeRecord} */ ({});
    const instance = Object.assign({}, defaults, props);
    return instance;
};

/** @type {Treeview.Factories.NodeRecordCollectionFactory} */
const createNodeRecordFromCollection = (props = []) => {
    const record = createNodeRecord({});
    const collection = [...(props ?? [])];
    const instance = collection.reduce((target, node) => {
        const previous = target[node.id];
        const next = !!previous
            ? Node.override(previous, node)
            : Node.clone(node);
        target[node.id] = next;
        return target;
    }, record);
    return instance;
};

// <!-- DUPLICATORS -->

/** @type {Treeview.Duplicators.NodeRecordDuplicator} */
const cloneNodeRecord = (source) => {
    /** @type {Treeview.Node[]} */
    const nodes = Object.values(source);
    const instance = nodes.reduce((record, node) => {
        record[node.id] = Node.clone(node);
        return record;
    }, {});
    return instance;
};

// <!-- MUTATIONS -->

/** @type {Treeview.Mutations.NodeRecordMutation} */
const overrideNodeRecord = (source, patch) => {
    const target = cloneNodeRecord(source);
    const updates = Object.values(patch);
    const instance = updates.reduce((record, node) => {
        const previous = findNodeByID(record, node.id);
        const next = Node.isInstance(previous)
            ? Node.override(previous, node)
            : Node.clone(node);
        record[next.id] = next;
        return record;
    }, target);
    return instance;
};

// <!-- SORTERS -->

/**
 * Get sorter that will compare nodes by their paths.
 * @param {Readonly<Treeview.NodeRecord>} record
 */
const getNodePathSorter = (record) => {
    /**
     * Compare two nodes by their unique hierarchy path.
     * @param {Treeview.Node} a
     * @param {Treeview.Node} b
     * @returns {number}
     */
    const compareByPath = (a, b) => {
        const ancestorsA = NodeRecord.getAncestorsWithSelf(record, a);
        const ancestorsB = NodeRecord.getAncestorsWithSelf(record, b);
        const valuesA = ancestorsA.map((n) => n.text.trim()).join('/');
        const valuesB = ancestorsB.map((n) => n.text.trim()).join('/');
        return valuesA.localeCompare(valuesB);
    };
    // RETURN path comparator.
    return compareByPath;
};

/**
 * Get sorted subtrees, based on the roots of the specified node record.
 * @param {Readonly<Treeview.NodeRecord>} record
 * @returns {Treeview.Node[]}
 */
const getSortedNodeRecordIndex = (record) => {
    // GET unsorted nodes.
    const nodes = getNodeRecordIndex(record);
    // SORT the nodes.
    const sortedNodes = nodes.sort(getNodePathSorter(record));
    // RETURN the nodes.
    return sortedNodes;
};

/**
 * Sort the node record, based on the roots of the specified node record.
 * @param {Readonly<Treeview.NodeRecord>} record
 * @returns {Treeview.NodeRecord}
 */
const sortNodeRecord = (record) => {
    const nodes = getSortedNodeRecordIndex(record);
    return NodeRecord.fromCollection(nodes);
};

// <!-- FILTERS -->

/**
 * Get filtered subtrees, based on the roots of the specified node record.
 * @param {Readonly<Treeview.NodeRecord>} record
 * @param {(node: Treeview.Node) => boolean} predicate
 * @returns {Treeview.Node[]}
 */
const getFilteredNodeRecordIndex = (record, predicate) => {
    // DEFINE the sorted node record index.
    const nodes = getSortedNodeRecordIndex(record);

    // FILTER the nodes based on the predicate.
    const filtered = nodes.filter(predicate);

    // RETURN the filtered nodes.
    return filtered;
};

/**
 * Filter items from the node record index.
 * @param {Readonly<Treeview.NodeRecord>} record
 * @param {(node: Treeview.Node) => boolean} predicate
 * @returns {Treeview.NodeRecord}
 */
const filterNodeRecord = (record, predicate) => {
    const filteredNodes = getFilteredNodeRecordIndex(record, predicate);
    return NodeRecord.fromCollection(filteredNodes);
};

// <!-- CLASSES -->

/**
 * @class
 * Treeview node class helper.
 */
export class NodeRecord {
    // <!-- GENERIC TYPE GUARDS -->
    static isRecord = isNodeRecord;
    static isNotEmpty = isNonEmptyRecord;

    // <!-- SPECIFIC TYPE GUARDS -->
    static hasNode = isSpecificNodePresent;

    // <!-- SELECTORS -->
    static all = getNodeRecordIndex;
    static sorted = getSortedNodeRecordIndex;
    static where = getFilteredNodeRecordIndex;
    static findByID = findNodeByID;
    static findByResourceID = findNodeByResourceID;

    // <!-- EXTRACTORS -->
    static getRoots = getNodeRecordRoots;
    static getLeaves = getNodeRecordLeaves;
    static getPath = getNodePath;
    static getRootOf = getNodeRoot;
    static getLeavesOf = getNodeLeaves;
    static getParentOf = getNodeParent;
    static getChildrenOf = getNodeChildren;
    static getSiblingsWithSelf = getNodeSiblingsAndSelf;
    static getSiblingsOf = getNodeSiblings;
    static getAncestorsWithSelf = getNodeAncestorsAndSelf;
    static getAncestorsOf = getNodeAncestors;
    static getDescendantsWithSelf = getNodeDescendantsAndSelf;
    static getDescendantsOf = getNodeDescendants;
    static getUnassignedRoot = getUnassignedHierarchyRoot;

    // <!-- FACTORIES -->
    static create = createNodeRecord;
    static fromCollection = createNodeRecordFromCollection;

    // <!-- DUPLICATORS -->
    static clone = cloneNodeRecord;

    // <!-- MUTATIONS -->
    static override = overrideNodeRecord;

    // <!-- SORTERS -->
    static sort = sortNodeRecord;

    // <!-- FILTERS -->
    static filter = filterNodeRecord;
}

// <!-- DEFAULT -->
export default NodeRecord;
