// Composables used for managing complex state.

// <!-- API -->
import { ref, computed } from 'vue';

// <!-- TYPES -->
import { DismissAlertEvent, RaiseAlertEvent } from '../events';
/** @typedef {ReturnType<initializeLocalState>} AlertState Local state. */
/** @typedef {ReturnType<prepareStaticProperties>} AlertConstants Local constants. */
/** @typedef {ReturnType<prepareComputedProperties>} AlertProperties Local computed properties. */
/** @typedef {ReturnType<prepareMethods>} AlertMethods Local component methods. */
/** @typedef {ReturnType<prepareEventListeners>} AlertHandlers Local event listeners. */
/** @typedef {{ id: PropertyKey, icon: String, classes: ReturnType<ALERT_STYLES['get']>, title: String, content?: String, messages?: String[], dismissable?: Boolean }} AlertDef */

// <!-- COMPONENTS -->
import {
    XCircleIcon,
    ExclamationIcon,
    BadgeCheckIcon,
    InformationCircleIcon,
} from '@heroicons/vue/outline';

export const ALERT_ICONS = new Map([
    ['info', InformationCircleIcon],
    ['error', XCircleIcon],
    ['warning', ExclamationIcon],
    ['success', BadgeCheckIcon],
]);

export const ALERT_STYLES = new Map([
    [
        'info',
        {
            outer: ['bg-primary-50'],
            alertIcon: ['text-primary-500'],
            content: ['text-primary-800'],
            messages: ['text-primary-700'],
            button: [
                'bg-primary-50',
                'text-primary-500',
                'hover:bg-primary-100',
                'focus:ring-offset-primary-50',
                'focus:ring-primary-600',
            ],
        },
    ],
    [
        'warning',
        {
            outer: ['bg-secondary-50'],
            alertIcon: ['text-secondary-500'],
            content: ['text-secondary-800'],
            messages: ['text-secondary-700'],
            button: [
                'bg-secondary-50',
                'text-secondary-500',
                'hover:bg-secondary-100',
                'focus:ring-offset-secondary-50',
                'focus:ring-secondary-600',
            ],
        },
    ],
    [
        'error',
        {
            outer: ['bg-red-50'],
            alertIcon: ['text-red-500'],
            content: ['text-red-800'],
            messages: ['text-red-700'],
            button: [
                'bg-red-50',
                'text-red-500',
                'hover:bg-red-100',
                'focus:ring-offset-red-50',
                'focus:ring-red-600',
            ],
        },
    ],
    [
        'success',
        {
            outer: ['bg-green-50'],
            alertIcon: ['text-green-500'],
            content: ['text-green-800'],
            messages: ['text-green-700'],
            button: [
                'bg-green-50',
                'text-green-500',
                'hover:bg-green-100',
                'focus:ring-offset-green-50',
                'focus:ring-green-600',
            ],
        },
    ],
]);

/**
 * Initialize the local state.
 */
const initializeLocalState = () => {
    return {
        /** @type {V.Ref<AlertDef[]>} */
        alerts: ref([]),
    };
};

/**
 * Prepare local static (unchanging) properties.
 */
const prepareStaticProperties = () => {
    /** @enum {String} Events. */
    const EVENTS = {
        RaiseAlert: RaiseAlertEvent,
        DismissAlert: DismissAlertEvent,
    };

    /** @enum {String} Alert types. */
    const ALERTS = {
        INFO: 'info',
        WARNING: 'warning',
        ERROR: 'error',
        SUCCESS: 'success',
    };

    /** Defaults */
    const DEFAULTS = {
        TYPE: ALERTS.WARNING,
        TITLE: '',
        CONTENT: '',
        MESSAGES: [],
        DISMISSABLE: true,
    };

    // EXPOSE
    return {
        EVENTS,
        ALERTS,
        DEFAULTS,
    };
};
/**
 * Initialize computed properties.
 * @param {AlertState} state Local state.
 * @param {AlertConstants} constants Local constants.
 */
const prepareComputedProperties = (state, constants) => {
    // Has alerts.
    const hasAlerts = computed(
        () => !!state.alerts.value && state.alerts.value.length > 0
    );

    // EXPOSE
    return {
        hasAlerts,
    };
};

/**
 *
 * @param {AlertState} state Local state.
 * @param {AlertConstants} constants Local constants.
 * @param {AlertProperties} properties Local computed properties.
 */
// ts-ignore
const prepareEventListeners = (state, constants, properties) => {
    /** @param {AlertDef[]} alerts */
    const onSetAlerts = (alerts) => {
        state.alerts.value = alerts;
    };

    /** @param {AlertDef} alert */
    const onRemoveAlert = (alert) => {
        const alerts = state.alerts.value;
        const index = alerts.findIndex((a) => a.id == alert.id);
        if (index >= 0 && index < alerts.length) {
            // Remove the alert.
            state.alerts.value = alerts.filter((_, i) => i != index);
        }
    };

    /** @param {AlertDef} alert */
    const onPushAlert = (alert) => {
        const alerts = state.alerts.value;
        const index = alerts.findIndex((a) => a.id == alert.id);
        if (index >= 0 && index < alerts.length) {
            alerts[index] = alert;
        } else {
            alerts.push(alert);
        }
        state.alerts.value = alerts;
    };

    /** Clear the alerts array. */
    const onClearAlerts = () => {
        onSetAlerts([]);
    };

    return {
        onSetAlerts,
        onClearAlerts,
        onRemoveAlert,
        onPushAlert,
    };
};

/**
 * Initialize component methods.
 * @param {AlertState} state Local state.
 * @param {AlertConstants} constants Local constants.
 * @param {AlertProperties} properties Local computed properties.
 * @param {AlertHandlers} handlers Local event handlers.
 */
// ts-ignore
const prepareMethods = (state, constants, properties, handlers) => {
    /** @param {AlertDef} alert */
    const dismissAlert = (alert) => {
        if (!!alert && alert.dismissable) {
            handlers.onRemoveAlert(alert);
        }
    };

    const clearAlert = (id) => {
        const alert = state.alerts.value.find((a) => a.id == id);
        if (!!alert) {
            handlers.onRemoveAlert(alert);
        }
    };

    /** @param {AlertDef} alert */
    const pushAlert = (alert) => {
        if (!!alert) {
            handlers.onPushAlert(alert);
        }
    };

    /**
     *
     * @param {Object} params
     * @param {*} params.id
     * @param {constants['ALERTS'][keyof constants['ALERTS']]} [params.type]
     * @param {String} [params.title]
     * @param {String} [params.content]
     * @param {String[]} [params.messages]
     * @param {Boolean} [params.dismissable]
     */
    const createAlert = ({
        id,
        type = constants.DEFAULTS.TYPE,
        title = constants.DEFAULTS.TITLE,
        content = constants.DEFAULTS.CONTENT,
        messages = constants.DEFAULTS.MESSAGES,
        dismissable = constants.DEFAULTS.DISMISSABLE,
    }) => {
        return {
            id,
            icon: type,
            classes: ALERT_STYLES.get(type) ?? ALERT_STYLES.get('warning'),
            title,
            content,
            messages,
            dismissable,
        };
    };

    // EXPOSE
    return {
        pushAlert,
        dismissAlert,
        clearAlert,
        createAlert,
    };
};

/**
 * Generate and return local state, properties, and methods for the Compare metrics page.
 * @param {Object} [props] Props to pass into the Compare Metrics page.
 * @param {Partial<AlertState>} [props.state] Local state overrides.
 */
export const useAlerts = (props) => {
    // Initialize the state.
    const constants = prepareStaticProperties();
    const state = Object.assign(initializeLocalState(), props?.state ?? {});
    const properties = prepareComputedProperties(state, constants);
    const handlers = prepareEventListeners(state, constants, properties);
    const methods = prepareMethods(state, constants, properties, handlers);

    // Lifecycle methods.

    // Expose state.
    return {
        constants,
        state,
        properties,
        handlers,
        methods,
    };
};

// <!-- EXPORTS -->
export default {
    useAlerts,
};
