// <!-- API -->
import { ECNBResourceIndexState } from '@/store/types/cache/state';
import isNil from 'lodash-es/isNil';

// <!-- TYPES -->
/** @typedef {import('@/models/v1/locations/Location').LocationResource} LocationResource */
import { LocationHierarchyResource } from '@/models/v1/locations/LocationHierarchy';

/**
 * @class
 * Base collection management for a resource index.
 * @extends {ECNBResourceIndexState<Number, LocationHierarchyResource>}
 */
export class LocationHierarchyIndexState extends ECNBResourceIndexState {
    /**
     * Initial resource index.
     * @param {Map<Number, LocationHierarchyResource>} [index]
     * @param {Set<Number>} [unassigned]
     * @param {Map<String, any>} [attributes]
     */
    constructor(
        index = new Map(),
        unassigned = new Set(),
        attributes = new Map()
    ) {
        super('hierarchies', index, attributes);
        // Set the unassigned locations as an attribute.
        this.attributes.set('unassigned', unassigned);
    }

    // Extend base has() state checkers.
    get hasUnassignedLocations() {
        const $ = this;
        /**
         * Get the unassigned locations.
         * @returns {Set<Number>}
         */
        const getUnassigned = () => {
            return $.attributes.get('unassigned');
        };
        return (
            $.has.attributes &&
            $.has.attribute('unassigned') &&
            !isNil($.attributes.get('unassigned')) &&
            getUnassigned().size > 0
        );
    }

    /**
     * Check if unassigned location with matching identifier exists in the cache.
     * @param {Number} id
     */
    hasUnassignedLocation(id) {
        const $ = this;
        /**
         * Get the unassigned locations.
         * @returns {Set<Number>}
         */
        const getUnassigned = () => {
            return $.attributes.get('unassigned');
        };
        return (
            !isNil(id) && $.hasUnassignedLocations && getUnassigned().has(id)
        );
    }

    get resolve() {
        const $ = this;
        return {
            /**
             * Filter array of location resources to return only the unassigned locations.
             * @param {LocationResource[]} locations
             */
            unassigned: (locations) => {
                /** @type {Set<Number>} */
                const $unassigned = $.attributes.get('unassigned');
                return $unassigned.size === 0
                    ? []
                    : locations.filter((loc) => $unassigned.has(loc.id));
            },
        };
    }
}
