<template>
    <p
        class="max-w-10xl sm:w-auto text-center sm:text-left mb-0 sm:mb-8 md:pr-2 text-sm text-gray-500"
    >
        <slot></slot>
    </p>
</template>

<script>
    // <!-- API -->
    import { defineComponent } from 'vue';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'PageDescription',
    });
</script>
