<template>
    <h2
        class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate mb-6"
    >
        <slot></slot>
    </h2>
</template>

<script>
    // <!-- API -->
    import { defineComponent } from 'vue';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'PageHeader',
    });
</script>
