// <!-- DEFINE -->
/** @typedef {Record<String, Readonly<Router.RouteRecordRaw>>} */
const children = {
    uploader: {
        path: 'upload',
        name: 'Upload',
        component: () =>
            import(
                /* webpackPrefetch: true */ /* webpackChunkName: "upload" */ '~CSVUploader/views/Uploader.vue'
            ),
        meta: { namespace: 'upload' },
        alias: ['/upload'],
    },
};

// <!-- EXPORTS -->
/** @typedef {Record<String, Readonly<Router.RouteRecordRaw>>} */
export const upload = {
    index: {
        path: '/csv-uploader',
        name: 'CSV Uploader',
        redirect: '/csv-uploader/upload',
        component: () =>
            import(
                /* webpackPrefetch: true */ /* webpackChunkName: "upload" */ '~CSVUploader/views/index.vue'
            ),
        meta: { namespace: 'upload' },
        children: [children.uploader],
    },
};

// <!-- DEFAULT -->
export default upload;
