/** Reasonable defaults for the upload configuration. */
const UploadConfigurationDefaults = {
    FILE_SIZE_LIMIT: /** @type {const} */ (10e6),
    FILE_COUNT_LIMIT: /** @type {const} */ (25),
    FILE_TYPES: /** @type {const} */ ([
        '*',
        '.csv',
        '.txt',
        '.xlsx',
        '.pem',
        '.pem2',
    ]),
};

/**
 * @class
 * Upload configuration settings.
 */
export class UploadConfiguration {
    /** @public @type {Readonly<Number>} File size limit. */
    fileSizeLimit = UploadConfigurationDefaults.FILE_SIZE_LIMIT;

    /** @public @type {Readonly<Number>} File count limit. */
    fileCountLimit = UploadConfigurationDefaults.FILE_COUNT_LIMIT;

    /** @public @type {Readonly<String[]>} Allowed file types. */
    allowedFileTypes = UploadConfigurationDefaults.FILE_TYPES;
}
