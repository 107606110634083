// <!-- UTILITIES -->
import { isPrimitive, isVNode } from '@/utils/typeof';

// <!-- TYPES -->
/** @typedef {import('@/utils/formatters').IVNodeFormatter} IVNodeFormatter */

// <!-- EXPORTS -->
/**
 * Get text from VNode children.
 * @param {{ child: V.VNodeArrayChildren | V.VNode | string | number | boolean | null | undefined | void, separator: String }} entry
 * @returns {String}
 */
export const getVNodeChildrenText = ({ child, separator }) => {
    if (isPrimitive(child)) {
        // Descendant is that of a text node. Returns a single line to append to `lines`.
        return String(child);
    } else if (isVNode(child)) {
        // Child is another VNode. Separator will be used to recurse.
        return getVNodeText(child, separator);
    } else if (Array.isArray(child) && 'length' in child) {
        // Child is array of VNode children. Separator will be used to recurse.
        return getVNodeChildrenText({ child: child, separator });
    }
    // Unreachable with current API, but just in case return an empty string.
    return '';
};

/**
 * Get text from VNode.
 * @param {V.VNode | string | boolean | number | bigint | symbol | undefined | null} vnode
 * @param {String} [separator]
 * @returns {String} Compiled text representation of the VNode (and its children).
 */
export const getVNodeText = (vnode, separator = '\n') => {
    if (isVNode(vnode)) {
        const { children } = vnode;
        if (isPrimitive(children)) {
            // VNode is a text node. Returns a single line.
            return String(children);
        } else if (Array.isArray(children)) {
            // VNode['children'] is an array: VNodeArrayChildren.
            const lines = children.map((child) =>
                getVNodeChildrenText({ child, separator })
            );
            // Returns one or more lines, joined together by a newline. If no lines are provided, an empty string is returned in its place.
            return lines.join(separator);
        }
    } else {
        // If not a node, there is no content.
        return '';
    }
};

/**
 * @type {IVNodeFormatter} Formats VNode according to the specified options.
 *
 * @example
 * ```
 * formatVNode({ value: [object VNode], options: { separator: ',' } }); // '<div>I am a node.<p>I am a child.</p></div> ==> "I am a node.,I am a child."
 * formatVNode({ value: [object VNode], options: { separator: '\n' } }); // '<div>I am a node.<p>I am a child.</p></div> ==> "I am a node.\nI am a child."
 * ```
 */
export const formatVNode = ({ value, options }) => {
    // <!-- DESTRUCTURE -->
    const input = value ?? NaN;

    // <!-- CONDITIONALS -->
    const isValid = isVNode(input);

    // When the input value is a valid number...
    if (isValid) {
        // Get the formatted VNode text. Nested text is separated by lines by default.
        const { separator = '\n' } = options ?? {};
        const formatted = getVNodeText(value, separator);
        // Return the formatted string.
        return formatted;
    }

    // When the input value is NOT a valid VNode, return an empty string.
    return '';
};

// <!-- DEFAULT -->
export default formatVNode;
