// <!-- API -->
import { CacheState } from '@/store/types/cache/state';
import { collect } from 'collect.js';
import isNil from 'lodash-es/isNil';

// <!-- TYPES -->
import { Collection } from 'collect.js';

/**
 * Resource status tracker enum.
 */
export const ECNBResourceStatus = /** @type {const} */ ({
    initialized: 'initialized',
    caching: 'caching',
    fetching: 'fetching',
});

/**
 * @class
 * Base collection management for a resource index.
 * @template {any} K Resource index id type.
 * @template {{ id: K }} V Resource index type.
 */
export class ECNBResourceIndexState {
    /**
     * Initial resource index.
     * @param {String} source Resource index api source name.
     * @param {Map<K, V>} [index]
     * @param {Map<String, any>} [attributes]
     */
    constructor(source, index = new Map(), attributes = new Map()) {
        // Add this to the cache state keys.
        CacheState.modules.add(source);

        /**
         * @type {Readonly<String>} Readonly property.
         */
        this._source = source;

        /**
         * @type {Map<K, V>} Cached resource index.
         */
        this.index = index ?? new Map();

        /**
         * @type {Map<String, any>} Object that can contain additional data, on a per state instance basis.
         */
        this.attributes = attributes ?? new Map();

        /**
         * @type {Set<String>} Set of flags used for tracking custom resource index state.
         */
        this.flags = new Set();

        /**
         * @type {Set<keyof ECNBResourceStatus>} Cached resource index status.
         */
        this.status = new Set();
    }

    // <!-- QUERIES -->

    /**
     * Name of the API source to pull fetched resources from.
     */
    get source() {
        return this._source;
    }

    /**
     * Access properties related to checking subproperty existence.
     */
    get has() {
        // Reference to this context.
        const $ = this;
        return {
            /**
             * Check if index reference exists and is non-empty.
             */
            get index() {
                return !isNil($.index) && $.index.size > 0;
            },

            /**
             * @alias index
             * Check if index reference exists and is non-empty.
             */
            get resources() {
                return $.has.index;
            },

            /**
             * Check if attributes reference exists and is non-empty.
             */
            get attributes() {
                return !isNil($.attributes) && $.attributes.size > 0;
            },

            /**
             * Check if any flags exists.
             */
            get flags() {
                return !isNil($.flags) && $.flags.size > 0;
            },

            /**
             * Check if any statuses exists.
             */
            get statuses() {
                return !isNil($.flags) && $.status.size > 0;
            },

            /**
             * Check if resource with matching identifier exists in the cache.
             * @param {K} id
             */
            resource(id) {
                return (
                    !isNil(id) &&
                    $.has.resources &&
                    $.index.has(id) &&
                    !isNil($.index.get(id))
                );
            },

            /**
             * Check if key exists on the attributes object.
             * @param {String} key
             */
            attribute(key) {
                return !isNil(key) && $.has.attributes && $.attributes.has(key);
            },

            /**
             * Check if flag exists on the index.
             * @param {String} key Flag.
             */
            flag(key) {
                return !isNil(key) && $.has.flags && $.flags.has(key);
            },

            /**
             * Check if flag exists on the index.
             * @param {keyof ECNBResourceStatus} key
             */
            status(key) {
                return !isNil(key) && $.has.statuses && $.status.has(key);
            },
        };
    }

    /**
     * Access properties related to checking state.
     */
    get is() {
        const $ = this;
        return {
            /**
             * Is the index empty or missing?
             */
            get empty() {
                return !$.has.index || $.index.size <= 0;
            },
            /**
             * Is the resource present?
             */
            get present() {
                return {
                    /** @param {K} id Resource id. */
                    resource: (id) => $.has.resource(id),
                    /** @param {String} key Attribute key. */
                    attribute: (key) => $.has.attribute(key),
                    /** @param {String} key Flag key. */
                    flag: (key) => $.has.flag(key),
                    /** @param {keyof ECNBResourceStatus} key Status key. */
                    status: (key) => $.has.status(key),
                };
            },
            /**
             * Is the resource missing?
             */
            get missing() {
                return {
                    /** @param {K} id Resource id. */
                    resource: (id) => !$.has.resource(id),
                    /** @param {String} key Attribute key. */
                    attribute: (key) => !$.has.attribute(key),
                    /** @param {String} key Flag key. */
                    flag: (key) => !$.has.flag(key),
                    /** @param {keyof ECNBResourceStatus} key Status key. */
                    status: (key) => !$.has.status(key),
                };
            },
        };
    }

    // <!-- COLLECTION -->

    /**
     * Return all resources with non-nil values from the index as a {@link Collection}.
     * @returns {Collection<V>}
     */
    get all() {
        const $ = this;

        // ITERATE
        /** @type {V[]} */
        const values = Array.from($.index, ([_, value]) => value);

        // FILTER
        /** @type {V[]} Exclude nil values from the collection. */
        const filtered = values.filter((value) => !isNil(value));

        // CAST
        /** @type {Collection<V>} */
        const collection = collect(filtered);

        // EXPOSE
        return collection;
    }

    // <!-- SELECTORS -->

    /**
     * Synchronous selection of a resource, if it exists. Otherwise, returns null or the default value (if provided).
     * @param {K} id
     * @param {V} defaultValue
     * @returns {V}
     */
    select(id, defaultValue = null) {
        return this.has.resource(id) ? this.index.get(id) : defaultValue;
    }

    /**
     * @template {any} T Attribute value.
     * Extract value of named attribute.
     * @param {String} key
     * @param {T} [defaultValue]
     */
    extract(key, defaultValue = null) {
        /** @type {T} Value. */
        const value = this.has.attribute(key)
            ? this.attributes.get(key)
            : defaultValue;
        return value;
    }
}
