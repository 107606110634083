/**
 * useButtonStyles.js
 *
 * This composoable contains helper funtions for programmatically
 * generating variants and themes in a standardized way compatible with Tailwind.
 *
 * YOU DO NOT NEED THESE IF YOU ARE SETTING CSS STYLES ON COMPONENTS DIRECTLY.
 *
 * ## How to Use
 *
 * The useXXXVariant() functions allow you to quickly apply a Tailwind CSS
 * variant to an array of existing Tailwind CSS functions.
 *
 * The useXXXTheme() functions allow you to create a set of conceptually
 * related styles at once in a standardized method. (eg. useFontTheme() accepts
 * an object with a color and size object). You can specify styles like
 * the element's color without any special prefix (eg. 'text', 'bg')
 * so that your object can be reused across multiple themes with a consistent color.
 *
 * In most cases, if you are not programmatically creating a preset variant
 * for use through one of the composables, you do NOT need these helpers and can
 * recreate the same behavior by using the appropriate array of Tailwind CSS directly.
 *
 * ## Example
 *
 * Take this example Tailwind CSS array:
 *
 * font = [ 'text-primary-800', 'font-semibold', 'text-lg', 'hover:text-primary-500', 'hover:font-medium', 'hover:text-sm' ]
 *
 * The above can be recreated with the following commands:
 *
 * font = [
 *   ...useFontVariant({ color: 'primary-800', weight: 'semibold', size: 'lg' }),
 *   ...useFontVariant({ variant: 'hover', color: 'primary-500', weight: 'medium', size: 'sm' })
 * ]
 */

/**
 * Filter CSS array.
 *
 * @param {Array.<String>} array Array of Tailwind CSS string classes.
 * @returns {Array.<String>} returns array of non-undefined values.
 */
const filterCSS = (array) =>
    array && array.length ? array.filter((x) => x !== undefined) : [];

/**
 * Format a CSS class. (eg. useTailwindClass('text', 'white') results in 'text-white';
 *
 * @param {String[]} args Array of arguments.
 * @returns {String} String representing the Tailwind CSS.
 */
export const useTailwindClass = (...args) => {
    const cleaned = filterCSS(args);
    return cleaned.length ? cleaned.join('-') : '';
};

/**
 * Associate a set of CSS classes with a variant state. (eg., 'disabled:', 'hover:', etc.).
 *
 * Use `base` to refer to default non-variant states.
 *
 * https://v2.tailwindcss.com/docs/configuring-variants
 *
 * @param {String} variant Name of the variant to create.
 * @param {Array.<String>} classes Array of Tailwind CSS classes.
 * @returns {Array.<String>} Object with array of Tailwind CSS classes, ready to bind to a `:class` component.
 */
export const useVariant = (variant = 'base', classes = []) => {
    const cleaned = filterCSS(classes);
    const mapped =
        variant && variant !== 'base'
            ? cleaned.map((cls) => `${variant}:${cls}`)
            : cleaned;
    return mapped;
};

/**
 * Get the Tailwind CSS style for the specified property. Defaults to 'text-white'.
 *
 * @param {String} color Tailwind CSS color and shade.
 * @returns {String} String representing the Tailwind CSS.
 */
export const useFontColor = (color) =>
    useTailwindClass('text', color ?? 'white');

/**
 * Get the Tailwind CSS style for the specified property. Defaults to 'text-size'.
 *
 * @param {String} size Tailwind CSS size class.
 * @returns {String} String representing the Tailwind CSS.
 */
export const useFontSize = (size) =>
    useTailwindClass('text', size && size !== 'md' ? size : 'base');

/**
 * Get the Tailwind CSS style for the specified property. Defaults to 'font-medium'.
 *
 * @param {String} weight Tailwind font weight.
 * @returns {String} String representing the Tailwind CSS.
 */
export const useFontWeight = (weight) =>
    useTailwindClass('font', weight ?? 'medium');

/**
 * Get the Tailwind CSS array for binding or further modification.
 *
 * @param {Object} style Style object.
 * @param {String} [style.color] Style setting.
 * @param {String} [style.weight] Style setting.
 * @param {String} [style.size] Style setting.
 * @returns {Array.<String>} Array of CSS classes.
 */
export const useFontTheme = ({ color, weight, size }) => [
    color && useFontColor(color),
    weight && useFontWeight(weight),
    size && useFontSize(size),
];

/**
 * Map style settings into standardized Tailwind CSS classes.
 *
 * @param {Object} style Style settings.
 * @param {String} [style.variant] Variant to apply, if variant should be used. If missing, uses 'base'.
 * @param {String} [style.color] Style setting.
 * @param {String} [style.weight] Style setting.
 * @param {String} [style.size] Style setting.
 * @returns {Array.<String>} Array of CSS classes.
 */
export const useFontVariant = (style) => {
    const theme = useFontTheme(style);
    return style?.variant ? useVariant(style.variant, theme) : theme;
};

/**
 * Get the Tailwind CSS style for the specified property. Defaults to 'bg-white'.
 *
 * @param {String} color Tailwind CSS color and shade.
 * @returns {String} String representing the Tailwind CSS.
 */
export const useBackgroundColor = (color) =>
    useTailwindClass('bg', color ?? 'white');

/**
 * Get the Tailwind CSS array for binding or further modification.
 *
 * @param {Object} style Style object.
 * @param {String} [style.color] Style setting.
 * @returns {Array.<String>} Array of CSS classes.
 */
export const useBackgroundTheme = ({ color }) => [
    color && useBackgroundColor(color),
];

/**
 * Map style settings into standardized Tailwind CSS classes.
 *
 * @param {Object} style Style settings.
 * @param {String} [style.variant] Variant to apply, if variant should be used. If missing, uses 'base'.
 * @param {String} [style.color] Style setting.
 * @returns {Array.<String>} Array of CSS classes.
 */
export const useBackgroundVariant = (style) => {
    const theme = useBackgroundTheme(style);
    return style?.variant ? useVariant(style.variant, theme) : theme;
};

/**
 * Get the Tailwind CSS style for the specified property. Defaults to 'border-transparent'.
 *
 * @param {String} color Tailwind CSS color and shade.
 * @returns {String} String representing the Tailwind CSS.
 */
export const useBorderColor = (color) =>
    useTailwindClass('border', color ?? 'transparent');

/**
 * Get the Tailwind CSS style for the specified property. Defaults to 'border-solid'.
 *
 * @param {String} pattern Tailwind CSS border line type.
 * @returns {String} String representing the Tailwind CSS.
 */
export const useBorderPattern = (pattern) =>
    useTailwindClass('border', pattern ?? 'solid');

/**
 * Get the Tailwind CSS style for the specified property. Defaults to 'border-2'.
 *
 * @param {Number} size Tailwind CSS size.
 * @returns {String} String representing the Tailwind CSS.
 */
export const useBorderSize = (size) =>
    useTailwindClass('border', String(size ?? 2));

/**
 * Get the Tailwind CSS array for binding or further modification.
 *
 * @param {Object} style Style object.
 * @param {String} [style.color] Style setting.
 * @param {String} [style.pattern] Style setting.
 * @param {Number} [style.size] Style setting.
 * @returns {Array.<String>} Array of CSS classes.
 */
export const useBorderTheme = ({ color, pattern, size }) => [
    color && useBorderColor(color),
    pattern && useBorderPattern(pattern),
    size && useBorderSize(size),
];

/**
 * Map style settings into standardized Tailwind CSS classes.
 *
 * @param {Object} style Style settings.
 * @param {String} [style.variant] Variant to apply, if variant should be used. If missing, uses 'base'.
 * @param {String} [style.color] Style setting.
 * @param {String} [style.pattern] Style setting.
 * @param {Number} [style.size] Style setting.
 * @returns {Array.<String>} Array of CSS classes.
 */
export const useBorderVariant = (style) => {
    const theme = useBorderTheme(style);
    return style?.variant ? useVariant(style.variant, theme) : theme;
};

/**
 * Get the Tailwind CSS style for the specified property. Defaults to 'ring-indigo-500'.
 *
 * @param {String} pattern Tailwind CSS for outline line type.
 * @param {String} color Tailwind CSS color and shade.
 * @returns {String} String representing the Tailwind CSS.
 */
export const useOutlineColor = (pattern, color) =>
    useTailwindClass(pattern ?? 'ring', color ?? 'indigo-500');

/**
 * Get the Tailwind CSS style for the specified property. Defaults to 'ring-2'.
 *
 * @param {String} pattern Tailwind CSS for outline line type.
 * @param {Number} size Tailwind CSS color and shade.
 * @returns {String} String representing the Tailwind CSS.
 */
export const useOutlineSize = (pattern, size) =>
    useTailwindClass(pattern ?? 'ring', String(size ?? 2));

/**
 * Get the Tailwind CSS style for the specified property. Defaults to 'ring-offset-2'.
 *
 * @param {String} pattern Tailwind CSS for outline line type.
 * @param {Number} offset Tailwind CSS color and shade.
 * @returns {String} String representing the Tailwind CSS.
 */
export const useOutlineOffset = (pattern, offset) =>
    useTailwindClass(pattern ?? 'ring', 'offset', String(offset ?? 2));

/**
 * Get the Tailwind CSS array for binding or further modification.
 *
 * @param {Object} style Style object.
 * @param {String} [style.pattern] Style setting.
 * @param {String} [style.color] Style setting.
 * @param {Number} [style.offset] Style setting.
 * @param {Number} [style.size] Style setting.
 * @returns {Array.<String>} Array of CSS classes.
 */
export const useOutlineTheme = ({ pattern, color, offset, size }) => [
    pattern && color && useOutlineColor(pattern, color),
    pattern && offset && useOutlineOffset(pattern, offset),
    pattern && size && useOutlineSize(pattern, size),
];

/**
 * Map style settings into standardized Tailwind CSS classes.
 *
 * @param {Object} style Style settings.
 * @param {String} [style.variant] Variant to apply, if variant should be used. If missing, uses 'base'.
 * @param {String} [style.pattern] Style setting.
 * @param {String} [style.color] Style setting.
 * @param {Number} [style.offset] Style setting.
 * @param {Number} [style.size] Style setting.
 * @returns {Array.<String>} Array of CSS classes.
 */
export const useOutlineVariant = (style) => {
    const theme = useOutlineTheme(style);
    return style?.variant ? useVariant(style.variant, theme) : theme;
};

/**
 * Create array of Tailwind CSS margin rules. Missing margin rules are not included.
 *
 * @param {Object} margins Margins to use.
 * @param {Number} [margins.m] Set all margin rules simultaneously.
 * @param {Number} [margins.mx] Set horizontal margin rules simultaneously.
 * @param {Number} [margins.my] Set vertical margin rules simultaneously.
 * @param {Number} [margins.mt] Set top margin rule.
 * @param {Number} [margins.mb] Set bottom margin rule.
 * @param {Number} [margins.ml] Set left margin rule.
 * @param {Number} [margins.mr] Set right margin rule.
 * @returns {Array.<String>} Array with appropriate Tailwind CSS classes.
 */
export const useMargins = ({ m, mx, my, mt, mb, ml, mr }) =>
    [
        m && useTailwindClass('m', String(m)),
        mx && useTailwindClass('mx', String(mx)),
        my && useTailwindClass('my', String(my)),
        mt && useTailwindClass('mt', String(mt)),
        mb && useTailwindClass('mt', String(mb)),
        ml && useTailwindClass('mt', String(ml)),
        mr && useTailwindClass('mt', String(mr)),
    ].filter((x) => x !== undefined);

/**
 * Map style settings into standardized Tailwind CSS classes.
 *
 * @param {Object} margins Margins to use.
 * @param {String} [margins.variant] Variant to apply, if variant should be used. If missing, uses 'base'.
 * @param {Number} [margins.m] Set all margin rules simultaneously.
 * @param {Number} [margins.mx] Set horizontal margin rules simultaneously.
 * @param {Number} [margins.my] Set vertical margin rules simultaneously.
 * @param {Number} [margins.mt] Set top margin rule.
 * @param {Number} [margins.mb] Set bottom margin rule.
 * @param {Number} [margins.ml] Set left margin rule.
 * @param {Number} [margins.mr] Set right margin rule.
 * @returns {Array.<String>} Array of CSS classes.
 */
export const useMarginsVariant = (margins) => {
    const theme = useMargins(margins);
    return margins?.variant ? useVariant(margins.variant, theme) : theme;
};

/**
 * Create array of Tailwind CSS padding rules. Missing padding rules are not included.
 *
 * @param {Object} paddings Paddings to use.
 * @param {Number} [paddings.p] Set all padding rules simultaneously.
 * @param {Number} [paddings.px] Set horizontal padding rules simultaneously.
 * @param {Number} [paddings.py] Set vertical padding rules simultaneously.
 * @param {Number} [paddings.pt] Set top padding rule.
 * @param {Number} [paddings.pb] Set bottom padding rule.
 * @param {Number} [paddings.pl] Set left padding rule.
 * @param {Number} [paddings.pr] Set right padding rule.
 * @returns {Array.<String>} Array with appropriate Tailwind CSS classes.
 */
export const usePaddings = ({ p, px, py, pt, pb, pl, pr }) =>
    [
        p && useTailwindClass('p', String(p)),
        px && useTailwindClass('px', String(px)),
        py && useTailwindClass('py', String(py)),
        pt && useTailwindClass('pt', String(pt)),
        pb && useTailwindClass('pt', String(pb)),
        pl && useTailwindClass('pt', String(pl)),
        pr && useTailwindClass('pt', String(pr)),
    ].filter((x) => x !== undefined);

/**
 * Map style settings into standardized Tailwind CSS classes.
 *
 * @param {Object} paddings Margins to use.
 * @param {String} [paddings.variant] Variant to apply, if variant should be used. If missing, uses 'base'.
 * @param {Number} [paddings.p] Set all padding rules simultaneously.
 * @param {Number} [paddings.px] Set horizontal padding rules simultaneously.
 * @param {Number} [paddings.py] Set vertical padding rules simultaneously.
 * @param {Number} [paddings.pt] Set top padding rule.
 * @param {Number} [paddings.pb] Set bottom padding rule.
 * @param {Number} [paddings.pl] Set left padding rule.
 * @param {Number} [paddings.pr] Set right padding rule.
 * @returns {Array.<String>} Array of CSS classes.
 */
export const usePaddingsVariant = (paddings) => {
    const theme = usePaddings(paddings);
    return paddings?.variant ? useVariant(paddings.variant, theme) : theme;
};

/**
 * Use height settings.
 *
 * @param {Object} height Height settings.
 * @param {Number} [height.h] Height to use.
 * @param {Number} [height.min] Minimum height to use.
 * @param {Number} [height.max] Maximum height to use.
 * @returns {Array.<String>} Array with appropriate Tailwind CSS classes.
 */
export const useHeight = ({ h, min, max }) =>
    [
        h && useTailwindClass('h', String(h)),
        min && useTailwindClass('min', 'h', String(min)),
        max && useTailwindClass('max', 'h', String(max)),
    ].filter((x) => x !== undefined);

/**
 * Map style settings into standardized Tailwind CSS classes.
 *
 * @param {Object} styles Height settings.
 * @param {Object} [styles.variant] Variant to apply, if variant should be used. If missing, uses 'base'.
 * @param {Number} [styles.h] Height to use.
 * @param {Number} [styles.min] Minimum height to use.
 * @param {Number} [styles.max] Maximum height to use.
 * @returns {Array.<String>} Array of CSS classes.
 */
export const useHeightVariant = (styles) => {
    const theme = useHeight(styles);
    return styles?.variant ? useVariant(styles.variant, theme) : theme;
};

/**
 * Use width settings.
 *
 * @param {Object} width Width settings.
 * @param {Number} [width.w] Width to use.
 * @param {Number} [width.min] Minimum width to use.
 * @param {Number} [width.max] Maximum width to use.
 * @returns {Array.<String>} Array with appropriate Tailwind CSS classes.
 */
export const useWidth = ({ w, min, max }) =>
    [
        w && useTailwindClass('w', String(w)),
        min && useTailwindClass('min', 'h', String(min)),
        max && useTailwindClass('max', 'h', String(max)),
    ].filter((x) => x !== undefined);

/**
 * Map style settings into standardized Tailwind CSS classes.
 *
 * @param {Object} styles Width settings.
 * @param {Object} [styles.variant] Variant to apply, if variant should be used. If missing, uses 'base'.
 * @param {Number} [styles.w] Width to use.
 * @param {Number} [styles.min] Minimum width to use.
 * @param {Number} [styles.max] Maximum width to use.
 * @returns {Array.<String>} Array of CSS classes.
 */
export const useWidthVariant = (styles) => {
    const theme = useWidth(styles);
    return styles?.variant ? useVariant(styles.variant, theme) : theme;
};

/**
 * Apply dimension related stylings.
 *
 * @param {Object} dimensions Dimension / layout stylings.
 * @param {Object} [dimensions.margins] Margins settings.
 * @param {Object} [dimensions.paddings] Padding settings.
 * @param {Object} [dimensions.height] Height settings.
 * @param {Object} [dimensions.width] Width settings.
 * @returns
 */
export const useDimensions = ({ margins, paddings, height, width }) =>
    [
        ...(!margins ? [] : useMargins(margins)),
        ...(!paddings ? [] : usePaddings(paddings)),
        ...(!height ? [] : useHeight(height)),
        ...(!width ? [] : useWidth(width)),
    ].filter((x) => x !== undefined);

/**
 * Map style settings into standardized Tailwind CSS classes.
 *
 * @param {Object} dimensions Dimension / layout stylings.
 * @param {Object} [dimensions.variant] Variant to apply, if variant should be used. If missing, uses 'base'.
 * @param {Object} [dimensions.margins] Margins settings.
 * @param {Object} [dimensions.paddings] Padding settings.
 * @param {Object} [dimensions.height] Height settings.
 * @param {Object} [dimensions.width] Width settings.
 * @returns {Array.<String>} Array of CSS classes.
 */
export const useDimensionsVariant = (dimensions) => {
    const theme = useDimensions(dimensions);
    return dimensions?.variant ? useVariant(dimensions.variant, theme) : theme;
};
