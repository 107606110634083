import ActionLogger from '@/utils/ActionLogger';
import App from '@/App.vue';
import plugins from '@/plugins';
import { createApp } from 'vue';

// Initialize Vue application instance.
const app = createApp(App);
app.config.performance = true;

/** <!-- PLUGINS --> */

// NOTE: We may wish to remove this to avoid cluttering globals.
// Composition API can use the `useAxios()` composable instead of relying on window.axios.

/**
 * Asynchronous startup script.
 */
const init = async () => {
    const notify = ActionLogger.log(`[app::init]`);
    try {
        // Group messages.
        console.groupCollapsed(
            `[app::init] @ ${new Date().toLocaleDateString()}`
        );
        console.time('[app::init]');
        // Register console functions under their appropriate properties.
        plugins.registerDebugConsole(app);
        // Register timezones polyfill.
        plugins.registerTimeZones(app);
        // Register axios under the `$axios` property.
        plugins.registerAxios(app, '$axios');
        // Register the vue query.
        plugins.registerVueQuery(app);
        // Register the vue selection.
        plugins.registerVueSelect(app);
        // Register the formkit plugin.
        plugins.registerFormkit(app);
        // Register vuex store for use with Vue.
        const store = await plugins.registerStore(app);
        // Register router for use with Vue.
        plugins.registerRouter(app, store);
        notify.success();
    } catch (err) {
        console.error(err);
        notify.failure(err);
    } finally {
        // End group messages.
        console.timeEnd('[app::init]');
        console.groupEnd();
        // Mount application after plugins are setup.
        app.mount('#app');
    }
};

// Launch init().
init();
