// <!-- TYPES -->

import { ECNBState } from '@/store/types/ECNBStore';
import { AnalysisState } from '@/store/types/analysis/state';
import { AnalysisModule } from '@/store/types/analysis/module';

/**
 * @type {import('vuex').Module<AnalysisState, ECNBState>} Module to export.
 */
const module = {
    namespaced: AnalysisModule.module.namespaced,
    state: AnalysisModule.module.state,
    modules: AnalysisModule.module.modules,
    getters: AnalysisModule.module.getters,
    mutations: AnalysisModule.module.mutations,
    actions: AnalysisModule.module.actions,
};

// EXPOSE
export default module;
