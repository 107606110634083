// <!-- API -->
import { ECNBStateMutations } from '@/store/types/ECNBStateMutations';

// <!-- TYPES -->
import { UploadErrors } from '@/store/types/uploader/state';
import { UploadErrorGroupID } from '@/store/types/uploader/state/UploadErrors';

/**
 * Synchronous mutations for a {@link UploadErrors} instance.
 */
export class UploadErrorsMutations extends ECNBStateMutations {
    /**
     * Setter functions for errors, scoped by context.
     * @param {UploadErrors} context
     */
    static add(context) {
        const $ = UploadErrorsMutations;
        return {
            /**
             * Add new error, if it doesn't already exist.
             * @param {keyof UploadErrorGroupID} id
             * @param  {String} error
             */
            error: (id, error) => {
                if (context.errors.has(id)) {
                    const set = context.errors.get(id);
                    const updatedSet = set.add(error);
                    context.errors.set(id, updatedSet);
                }
            },
            /**
             * Synchronously add multiple errors.
             * @param {keyof UploadErrorGroupID} id
             * @param  {...String} errors
             */
            errors: (id, ...errors) => {
                for (const error of errors) {
                    $.add(context).error(id, error);
                }
            },
        };
    }

    /**
     * Deleter functions for errors, scoped by context.
     * @param {UploadErrors} context
     */
    static clear(context) {
        const $ = UploadErrorsMutations;
        return {
            /**
             * Clear all errors across all groups.
             */
            all: () => {
                for (const id of context.errors.keys()) {
                    // Delete each group.
                    $.clear(context).group(id);
                }
            },
            /**
             * Clear all errors in a specific group.
             * @param {keyof UploadErrorGroupID} id
             */
            group: (id) => {
                if (context.errors.has(id)) {
                    context.errors.set(id, new Set());
                }
            },
            /**
             * Clear specific error in a specific group.
             * @param {keyof UploadErrorGroupID} id
             * @param {String} error
             */
            error: (id, error) => {
                if (context.errors.has(id)) {
                    context.errors.get(id).delete(error);
                }
            },
        };
    }
}
