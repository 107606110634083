<template>
    <div class="flex flex-nowrap flex-row max-h-10">
        <BaseTooltip
            target="#tooltip-target"
            text="Switch Account"
            :textSize="'text-md'"
            :width="138"
        >
            <template #icon>
                <VariantButton
                    variant="icon"
                    alt="Switch Account"
                    @click.prevent="
                        params.handleSwitchAccount($event, params.node.id)
                    "
                >
                    <SwitchHorizontalIcon
                        class="h-4 w-4"
                        aria-hidden="true"
                    />
                </VariantButton>
            </template>
        </BaseTooltip>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent } from 'vue';

    // <!-- COMPONENTS -->
    import VariantButton from '@/components/buttons/VariantButton.vue';
    import { SwitchHorizontalIcon } from '@heroicons/vue/outline';
    import BaseTooltip from '@/components/tooltips/BaseTooltip.vue';

    // <!-- TYPES -->

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'SwitchAccountTableIcons',
        components: {
            SwitchHorizontalIcon,
            VariantButton,
            BaseTooltip,
        },
        props: {
            params: {
                /** @type {V.PropType<AgGrid.ICellRendererParams>} */
                type: Object,
                required: true,
            },
        },
    });
</script>

<style lang="scss"></style>
