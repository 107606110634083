/**
 * @class
 * Cache module state.
 */
export class CacheState {
    /**
     * Cache module keys. When initialized, this is empty.
     * @type {Set<String>}
     */
    static modules = new Set();

    /**
     * Construct initial, empty state.
     */
    constructor() {
        /**
         * @type {Set<'initialized'>} Has the cache state been initialized?
         */
        this.status = new Set();
        /**
         * Settings related to cache resolution.
         * // NOTE: Not currently in use.
         */
        this.settings = {
            /**
             *
             */
            mock: {
                /**
                 * @type {Boolean} When true, cache will treat fetch requests for a specific resource index as missing. Useful for debugging.
                 * // TODO: Implement test with this setting.
                 */
                missingIndex: false,
                /**
                 * @type {Boolean} When true, cache will treat fetch requests for a specific resource as missing. Useful for debugging.
                 * // TODO: Implement test with this setting.
                 */
                missingResource: false,
            },
        };
    }

    /**
     * Get set of cache ids for loaded modules.
     */
    get loaded() {
        return CacheState.modules;
    }

    /**
     * Get access to the state checkers.
     */
    get has() {
        return {
            /**
             * Check if the cache module has done a first-pass initialization.
             * @param {'initialized'} id
             */
            status: (id) => this.status.has(id),
        };
    }
}
