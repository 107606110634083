// <!-- UTILITIES -->
import clone from 'lodash-es/clone';

// <!-- TYPES -->
/** @typedef {import('@/models/v1/locations/Location').LocationResource} LocationResource */

/**
 * Upload record state flags.
 */
export const UploadRecordFlagIDs = /** @type {const}*/ ({
    checked: 'checked', // When present, record is marked for removal.
    dataset_ingested: 'dataset_ingested', // When present, dataset has been ingested.
    pem: 'pem', // When present, record is a PEM type file.
});

/**
 * Upload record statuses.
 */
export const UploadRecordStatusIDs = /** @type {const}*/ ({
    uploading_dataset: 'uploading_dataset', // When present, this record's dataset is being uploaded, with the file and location.
    refreshing_preview: 'refreshing_preview', // When present, this record is refreshing the preview for its mapping profile.
    viewing_mapping_profile: 'viewing_mapping_profile', // When present, record is viewing the mapping profile.
    editing_mapping_profile: 'editing_mapping_profile', // When present, record is editing the mapping profile.
    creating_mapping_profile: 'creating_mapping_profile', // When present, record is creating the mapping profile.
    applying_mapping_profile: 'applying_mapping_profile', // When present, record is applying the mapping profile.
    ingesting_dataset: 'ingesting_dataset', // When present, record is ingesting the dataset.
});

/**
 * @class
 * Record property that contains both a transient and a persisted value.
 * @template T
 */
export class RecordProperty {
    /**
     * Create a record property.
     *
     * @template {*} T
     * @param {T} [transient]
     * @param {T|null} [persisted]
     * @returns {RecordProperty<T>}
     */
    static create(transient, persisted = null) {
        /** @type {RecordProperty<T>} */
        const property = new RecordProperty({ transient, persisted });
        return property;
    }

    /**
     * Create a synced record property.
     * @param {Partial<RecordProperty>} attrs
     */
    constructor(attrs = {}) {
        /** @type {T} Transient, local value. */
        this.transient = attrs?.transient;

        /** @type {T} Persisted, upstream value. */
        this.persisted = attrs?.persisted;

        /** Seal and assign initial value. */
        Object.seal(this);
    }

    /**
     * Does the local transient value exist?
     */
    get isTransient() {
        return !!this.transient;
    }

    /**
     * Does the mirrored persisted value exist?
     */
    get isPersisted() {
        return !!this.persisted;
    }

    get exists() {
        return this.isTransient || this.isPersisted;
    }

    /**
     * Get the persisted value, if present, with fallback to the transient value.
     * @returns {T}
     */
    get value() {
        return this.persisted ?? this.transient;
    }

    /**
     * Set the transient value.
     * @param {T} value
     */
    commit(value) {
        this.transient = value;
    }

    /**
     * Set the persisted value.
     * @param {T} value
     */
    persist(value) {
        this.persisted = value;
    }

    /**
     * Clone the transient and persisted values
     * @returns {RecordProperty<T>}
     */
    clone() {
        const transient = this.isTransient ? clone(this.transient) : null;
        const persisted = this.isPersisted ? clone(this.persisted) : null;
        return new RecordProperty({ transient, persisted });
    }

    /**
     * Clear the property.
     */
    clear() {
        this.transient = null;
        this.persisted = null;
    }
}

/**
 * @class
 * Individual form record submitted by the CSV Uploader.
 */
export class UploadRecord {
    /**
     * Construct an UploadRecord, from an optional initial instance.
     * @param {Partial<UploadRecord>} attrs Record attributes.
     */
    constructor(attrs = {}) {
        /** @public @type {Set<keyof UploadRecordFlagIDs>} Record flags. */
        this.flags = new Set();

        /** @public @type {Set<keyof UploadRecordStatusIDs>} Record status. */
        this.status = new Set();

        /** @public @type {RecordProperty<File>} File associated with this individual form record. */
        this.file = new RecordProperty();

        /** @public @type {RecordProperty<LocationResource>} Location resource assigned to this individual form record. */
        this.location = new RecordProperty();

        /** @public @type {Array<{ line: Number, data: String[] }>} Array of dataset contents, up to 50 lines. */
        this.contents = new Array();

        /** @public @type {String} */
        this.type = null;

        /** @public @type {RecordProperty<import('@/models/v1/datasets/DatasetBatch').DatasetBatchResource>} Dataset resource assigned to this individual form record. */
        this.batch = new RecordProperty();

        /** @public @type {LocationResource} Optional Location resource suggested by the remote. */
        this.suggestedLocation = null;

        /** @public @type {RecordProperty<import('@/models/v1/mappings/MappingProfile').MappingProfileResource>} Mapping Profile resource assigned to this individual form record. */
        this.mappingProfile = new RecordProperty();

        /** @public @type {import('@/models/v1/mappings/MappingProfile').MappingProfileResource} Optional Mapping Profile resource suggested by the remote. */
        this.suggestedMappingProfile = null;

        // Seal and assign initial values. Does not perform a deep clone.
        Object.seal(this);
        Object.assign(this, attrs);
    }

    // <!-- IDENTITY -->

    /**
     * Get the filename, if present. Uses the persisted File over the transient, when possible.
     */
    get filename() {
        return this.file.exists ? this.file.value.name : null;
    }

    /**
     * Get the persisted batch id, if present. Uses the persisted DatasetBatchResource over the transient, whenever possible.
     */
    get id() {
        return this.batch.exists ? this.batch.value.id : null;
    }

    // <!-- DATA -->

    /**
     * Get properties about the dataset the record's batch describes.
     */
    get data() {
        const $ = this;
        return {
            /**
             * Get the number of points described by the dataset batch when ingested.
             */
            get points() {
                return $.isDatasetBatchIngested
                    ? $.batch.persisted.points ?? 0
                    : null;
            },
            /**
             * Get the start or end date as a range or individually.
             */
            get date() {
                return {
                    get start() {
                        return $.isDatasetBatchIngested
                            ? $.batch.persisted.dateDataStart
                            : null;
                    },
                    get end() {
                        return $.isDatasetBatchIngested
                            ? $.batch.persisted.dateDataStart
                            : null;
                    },
                    get range() {
                        return $.isDatasetBatchIngested
                            ? [$.data.date.start, $.data.date.end]
                            : null;
                    },
                    get created() {
                        return $.isDatasetBatchIngested
                            ? $.batch.persisted.dateCreated
                            : null;
                    },
                };
            },
        };
    }

    // <!-- FLAGS -->

    /**
     * Is the 'checked' flag present?
     */
    get isMarkedForRemoval() {
        return this.isFlagEnabled('checked');
    }

    /**
     * Is the 'dataset_ingested' flag present?
     */
    get isMarkedAsIngested() {
        return this.isFlagEnabled('dataset_ingested');
    }

    /**
     * Is the 'pem' flag present?
     */
    get isMarkedAsPEM() {
        return this.isFlagEnabled('pem');
    }

    // <!-- STATUSES -->

    /**
     * Is the status active?
     */
    get isUploadingDataset() {
        return this.isStatusActive('uploading_dataset');
    }

    /**
     * Is the status active?
     */
    get isRefreshingPreview() {
        return this.isStatusActive('refreshing_preview');
    }

    /**
     * Is the status active?
     */
    get isViewingMappingProfile() {
        return this.isStatusActive('viewing_mapping_profile');
    }

    /**
     * Is the status active?
     */
    get isEditingMappingProfile() {
        return this.isStatusActive('editing_mapping_profile');
    }

    /**
     * Is the status active?
     */
    get isCreatingMappingProfile() {
        return this.isStatusActive('creating_mapping_profile');
    }

    /**
     * Is the status active?
     */
    get isApplyingMappingProfile() {
        return this.isStatusActive('applying_mapping_profile');
    }

    /**
     * Is the status active?
     */
    get isDatasetBatchIngesting() {
        return this.isStatusActive('ingesting_dataset');
    }

    // <!-- FILE PROPERTIES -->

    /**
     * Has a transient or persisted file been selected?
     */
    get isFileSelected() {
        return this.file.exists;
    }

    // <!-- LOCATION PROPERTIES -->

    /**
     * Does a transient or persisted location exist?
     */
    get isLocationSelected() {
        return this.location.exists;
    }

    /**
     * Has a valid location been suggested?
     */
    get isLocationSuggested() {
        return !!this.suggestedLocation && !!this.suggestedLocation.id;
    }

    /**
     * Is the selected location the suggested location?
     */
    get usingSuggestedLocation() {
        return (
            this.isLocationSelected &&
            this.isLocationSuggested &&
            this.location.value.id === this.suggestedLocation.id
        );
    }

    // <!-- MAPPING PROFILE -->

    /**
     * Does a transient or persisted mapping profile exist?
     */
    get isMappingProfileSelected() {
        return this.mappingProfile.exists;
    }

    /**
     * Has a valid mapping profile been created?
     */
    get isMappingProfileCreated() {
        return (
            this.mappingProfile.isPersisted && !!this.mappingProfile.value.id
        );
    }

    /**
     * Has a valid mapping profile been suggested?
     */
    get isMappingProfileSuggested() {
        return (
            !!this.suggestedMappingProfile && !!this.suggestedMappingProfile.id
        );
    }

    /**
     * Is the selected mapping profile the suggested mapping profile?
     */
    get usingSuggestedMappingProfile() {
        return (
            this.isMappingProfileSelected &&
            this.isMappingProfileSuggested &&
            this.mappingProfile.value.id === this.suggestedMappingProfile.id
        );
    }

    // <!-- UPLOADED DATASET BATCH -->

    /**
     * Does a transient dataset batch exist, retrieved from
     * the remote when uploading the file and location?
     */
    get isDatasetBatchUploaded() {
        return this.batch.isTransient;
    }

    /**
     * Does a persisted file exist and match the stored batch details?
     */
    get isFileAssigned() {
        return (
            this.file.isPersisted &&
            this.isDatasetBatchUploaded &&
            this.batch.value.filename === this.file.value.name
        );
    }

    /**
     * Does a persisted location exist and match the stored batch details?
     */
    get isLocationAssigned() {
        return (
            this.location.isPersisted &&
            this.isDatasetBatchUploaded &&
            this.batch.value.locationId === this.location.value.id
        );
    }

    /**
     * Does a persisted mapping profile exist and match the stored batch details?
     */
    get isMappingProfileApplied() {
        return (
            this.isMappingProfileCreated &&
            this.isDatasetBatchUploaded &&
            this.batch.value.profileId === this.mappingProfile.value.id
        );
    }

    /**
     * Has the persisted dataset batch had its dataset ingested?
     */
    get isDatasetBatchIngested() {
        return (
            this.isDatasetBatchUploaded &&
            this.batch.isPersisted &&
            this.flags.has('dataset_ingested')
        );
    }

    // <!-- ACCESSORS -->

    /**
     * Check if flag exists.
     * @param {keyof UploadRecordFlagIDs} id
     */
    isFlagEnabled(id) {
        return this.flags.has(id);
    }

    /**
     * Check if status is active.
     * @param {keyof UploadRecordStatusIDs} id
     */
    isStatusActive(id) {
        return this.status.has(id);
    }
}
