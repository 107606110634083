// <!-- API -->
import { isVNode as isNode } from 'vue';

/**
 * Type guard. Allows type inference for the tested variable.
 * @param {any} value The value to test.
 * @returns {value is V.VNode} `true` if input argument is of type {@link V.VNode}.
 */
export const isVNode = (value) => isNode(value);

// <!-- DEFAULT -->
export default isVNode;
