// <!-- DEFINE -->
/** @typedef {Record<String, Readonly<Router.RouteRecordRaw>>} */
const children = {
    notes: {
        path: 'notes',
        name: 'Notes',
        component: () =>
            import(
                /* webpackPrefetch: true */ /* webpackChunkName: "notes" */ '@/features/note-manager/views/Notes.vue'
            ),
        meta: { namespace: 'note-manager' },
        alias: ['/notes'],
    },
};

// <!-- EXPORTS -->
/** @typedef {Record<String, Readonly<Router.RouteRecordRaw>>} */
export const notes = {
    index: {
        path: '/note-manager',
        name: 'Note Manager',
        redirect: '/note-manager/notes',
        component: () =>
            import(
                /* webpackPrefetch: true */ /* webpackChunkName: "notes" */ '@/features/note-manager/views/index.vue'
            ),
        meta: { namespace: 'note-manager' },
        children: [children.notes],
    },
};

// <!-- DEFAULT -->
export default notes;
