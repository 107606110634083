// <!-- UTILITIES -->
import { Emoji } from '@/utils/emoji';

/** @type {(label: String, message?: String) => any} */
export const onActionSuccess = (label, message = 'Success!') =>
    console.log(
        `${label} - ${Emoji.checkmark}: %c${message}`,
        'color:seagreen;'
    );

/** @type {(label: String, message?: String | Error) => any} */
export const onActionFailure = (label, message = 'Failure!') =>
    console.log(
        `${label} - ${Emoji.cross}: %c${
            message instanceof Error
                ? message?.message ?? String(message)
                : message
        }`,
        'color:firebrick;'
    );

/** @type {(label: String, message?: String) => any} */
export const onActionIgnore = (label, message = 'Warning!') =>
    console.log(
        `${label} - ${Emoji.checkmark}: %c${message}`,
        'color:goldenrod;'
    );

// DEFAULT
export default {
    /** @param {String} label */
    log(label) {
        return {
            /** @param {String} [message] */
            info: (message) => {
                console.log(`${label} - ${message}`);
            },
            /** @param {String} [message] */
            success: (message) => {
                onActionSuccess(label, message);
            },
            /** @param {String} [message] */
            failure: (message) => {
                onActionSuccess(label, message);
            },
            /** @param {String} [message] */
            warning: (message) => {
                onActionSuccess(label, message);
            },
        };
    },
};
