// <!-- API -->
import { VueQueryPlugin } from '@tanstack/vue-query';

/**
 * Initializes the [`@tanstack/vue-query`](../../node_modules/@tanstack/vue-query/README.md) library.
 * @param {V.App} app
 */
export const registerVueQuery = (app) => {
    app.use(VueQueryPlugin);
    console.log(
        `[plugin::vue-query] registered. @tanstack/vue-query composables are now discoverable.' @ ${new Date().toLocaleTimeString()}`
    );
};
