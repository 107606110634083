// <!-- API -->
import { ECNBResourceIndexState } from '@/store/types/cache/state';

// <!-- TYPES -->
import { NoteResource } from '@/models/v1/notes/Note';

/**
 * @class
 * Base collection management for a resource index.
 * @extends {ECNBResourceIndexState<Number, NoteResource>}
 */
export class NoteIndexState extends ECNBResourceIndexState {
    /**
     * Initial resource index.
     * @param {Map<Number, NoteResource>} [index]
     * @param {Map<String, any>} [attributes]
     */
    constructor(index = new Map(), attributes = new Map()) {
        super('notes', index, attributes);
    }
}
