// <!-- PLUGINS -->
import { useAxios as axios } from '@/plugins/axios';

// <!-- UTILITIES -->
import isNil from 'lodash-es/isNil';
import { toDate, formatISO } from 'date-fns';

// <!-- MODELS -->
import { Account } from '@/models/v2/accounts/AccountModel';
import { Note, NotePayload, NoteResource } from '@/models/v1/notes/Note';
import {
    Location,
    LocationPayload,
    LocationResource,
} from '@/models/v1/locations/Location';
import {
    LocationHierarchy,
    LocationHierarchyPayload,
    LocationHierarchyResource,
} from '@/models/v1/locations/LocationHierarchy';
import { DateTimeISO, DateTimeLocal } from '@/utils/datetime';

// <!-- TYPES -->
/** @typedef {import('@/api/v1').IRequestException} IRequestException */
/** @typedef {import('@/api/v1').IResponseResult} IResponseResult */
/** @typedef {globalThis.Account.Payload} AccountPayload */
/** @typedef {globalThis.Account.Model} AccountResource */
/** @typedef {User.Payload} UserPayload */
/** @typedef {User.Model} UserResource */
// ==== TARGETS ====
/** @typedef {Readonly<Pick<NotePayload, 'id'>>} IEditNoteTarget */
/** @typedef {Readonly<Pick<NotePayload, 'id' | 'title'>>} IDeleteNoteTarget */
// ==== REQUESTS ====
/** @typedef {Readonly<Pick<NotePayload, 'title' | 'start_date'> & Partial<Pick<NotePayload, 'content' | 'end_date' | 'location_hierarchy_id'>> & { locations?: Number[] | null }>} ICreateNoteRequest */
/** @typedef {Readonly<Pick<NotePayload, 'title' | 'start_date'> & Partial<Pick<NotePayload, 'content' | 'end_date' | 'location_hierarchy_id'>> & { locations?: Number[] | null }>} IEditNoteRequest */
/** @typedef {Readonly<Pick<NotePayload, 'id'>>} IDeleteNoteRequest */
// ==== RESPONSES ====
/** @template [T=any] @template [D=any] @typedef {import('axios').AxiosResponse<T,D>} AxiosResponse */
/** @typedef {AxiosResponse<ICreateNoteSuccess, ICreateNoteRequest>} ICreateNoteSuccessResponse */
/** @typedef {AxiosResponse<ICreateNoteDatabaseError, ICreateNoteRequest>} ICreateNoteDatabaseErrorResponse */
/** @typedef {AxiosResponse<ICreateNoteValidationError, ICreateNoteRequest>} ICreateNoteValidationErrorResponse */
/** @typedef {ICreateNoteSuccessResponse | ICreateNoteDatabaseErrorResponse | ICreateNoteValidationErrorResponse} ICreateNoteResponse */
/** @typedef {AxiosResponse<IEditNoteSuccess, IEditNoteRequest>} IEditNoteSuccessResponse */
/** @typedef {AxiosResponse<IEditNoteDatabaseError, IEditNoteRequest>} IEditNoteDatabaseErrorResponse */
/** @typedef {AxiosResponse<IEditNoteValidationError, IEditNoteRequest>} IEditNoteValidationErrorResponse */
/** @typedef {IEditNoteSuccessResponse | IEditNoteDatabaseErrorResponse | IEditNoteValidationErrorResponse} IEditNoteResponse */
/** @typedef {AxiosResponse<IDeleteNoteSuccess, any>} IDeleteNoteSuccessResponse */
/** @typedef {AxiosResponse<IDeleteNoteError, any>} IDeleteNoteForbiddenErrorResponse */
/** @typedef {AxiosResponse<IDeleteNoteError, any>} IDeleteNoteServerErrorResponse */
/** @typedef {AxiosResponse<IDeleteNoteException, any>} IDeleteNoteExceptionResponse */
/** @typedef {IDeleteNoteSuccessResponse | IDeleteNoteForbiddenErrorResponse | IDeleteNoteServerErrorResponse | IDeleteNoteExceptionResponse } IDeleteNoteResponse */
// ==== DATA ====
/** @typedef {ICreateNoteSuccess | ICreateNoteDatabaseError | ICreateNoteValidationError} ICreateNoteData */
/**
 * @typedef {Object} ICreateNoteSuccess
 * @property {"created"} status Response status type.
 * @property {NotePayload} note Note resource.
 * @property {String} message Human-readable message.
 */
/**
 * @typedef {Object} ICreateNoteDatabaseError
 * @property {"error"} status Response status type.
 * @property {String} message Human-readable message.
 * @property {IRequestException} exception Exception, if one was raised while sending the password reset link. If not present, no error when sending the email occurred.
 */
/**
 * @typedef {Object} ICreateNoteValidationError
 * @property {String} message Human-readable message.
 * @property {Record<String, Array<String>>} errors Record containing properties and the corresponding validation errors. Not present when account is created.
 */

/** @typedef {IEditNoteSuccess | IEditNoteDatabaseError | IEditNoteValidationError} IEditNoteData */
/**
 * @typedef {Object} IEditNoteSuccess
 * @property {"updated"} status Response status type.
 * @property {NotePayload} note Note resource.
 * @property {String} message Human-readable message.
 */
/**
 * @typedef {Object} IEditNoteDatabaseError
 * @property {"error"} status Response status type.
 * @property {String} message Human-readable message.
 * @property {IRequestException} exception Exception, if one was raised while sending the password reset link. If not present, no error when sending the email occurred.
 */
/**
 * @typedef {Object} IEditNoteValidationError
 * @property {String} message Human-readable message.
 * @property {Record<String, Array<String>>} errors Record containing properties and the corresponding validation errors. Not present when account is updated.
 */

/** @typedef {IDeleteNoteSuccess | IDeleteNoteError | IDeleteNoteException} IDeleteNoteData */
/**
 * @typedef {Object} IDeleteNoteSuccess
 * @property {"deleted"} status Response status type.
 * @property {String} message Human-readable message.
 */
/**
 * @typedef {Object} IDeleteNoteError
 * @property {"error"} status Response status type.
 * @property {String} message Human-readable message.
 * @property {IRequestException} exception Exception, if one was raised while sending the password reset link. If not present, no error when sending the email occurred.
 */
/**
 * @typedef {Object} IDeleteNoteException
 * @property {String} message Human-readable message.
 * @property {String} exception Exception title.
 * @property {String} file File location.
 * @property {Number} line Line numbrer.
 * @property {({ file: String, line: Number, function: String, class: String, type: String })[]} trace Stack trace.
 */

// <!-- ROUTES -->
const ROUTES = {
    GET_NOTES: (account) => `accounts/${account}/notes`,
    GET_NOTE: (account, id) => `accounts/${account}/notes/${id}`,
    CREATE_NOTE: (account) => `accounts/${account}/notes`,
    UPDATE_NOTE: (account, id) => `accounts/${account}/notes/${id}`,
    DELETE_NOTE: (account, id) => `accounts/${account}/notes/${id}`,
};

// <!-- REQUESTS -->
/**
 * Fetch all notes associated with an institution account.
 * @param {Pick<AccountResource, 'id'>} account
 * @returns {Promise<NoteResource[]>}
 */
export const fetchNotes = async (account = { id: 8 }) => {
    /** @type {import('axios').AxiosResponse<{ data: NotePayload[] }>} */
    const response = await axios().get(ROUTES.GET_NOTES(account.id));
    const collection = response.data.data;
    return collection.map((note) => new Note({ payload: note }).toResource());
};

/**
 * Fetch specified note by id for the supplied account.
 * @param {Pick<AccountResource, 'id'>} account
 * @param {Pick<NoteResource, 'id'>} note
 * @returns {Promise<NoteResource>}
 */
export const fetchNoteById = async (account = { id: 8 }, note = { id: 1 }) => {
    /** @type {import('axios').AxiosResponse<{ note: NotePayload }>} */
    const response = await axios().get(ROUTES.GET_NOTE(account.id, note.id));
    const payload = response.data.note;
    return new Note({ payload }).toResource();
};

/**
 * Create a new account and get the result of the operation.
 * @param {Pick<AccountPayload, 'id'>} account
 * @param {ICreateNoteRequest} request
 * @returns {Promise<{ note: NoteResource } & IResponseResult>}
 */
export const createNote = async (
    account = { id: 8 },
    request = {
        title: `Untitled Note - ${new Date(Date.now()).toLocaleDateString()}`,
        content: '',
        start_date: DateTimeISO.format(Date.now()),
        end_date: '',
        location_hierarchy_id: null,
        locations: [],
    }
) => {
    // <!-- TYPE GUARDS -->
    /**
     * @param {any} response
     * @returns {response is ICreateNoteResponse}
     */
    const isResponse = (response) => {
        return (
            !isNil(response) && // Non-null Axios response.
            !isNil(response.status) && // Non-null Axios status.
            !isNil(response.data) // Non-null Axios data property.
        );
    };

    /**
     * @param {ICreateNoteResponse} response
     * @returns {response is ({ status: 200 } & ICreateNoteSuccessResponse)}
     */
    const isSuccessResponse = (response) => {
        return (
            isResponse(response) &&
            response.status === 200 &&
            'status' in response.data &&
            !('exception' in response.data) &&
            response.data.status === 'created' &&
            !isNil(response.data.note)
        );
    };

    /**
     * @param {ICreateNoteResponse} response
     * @returns {response is ({ status: 422 | 400 | 500 } & ICreateNoteDatabaseErrorResponse )}
     */
    const isDatabaseErrorResponse = (response) => {
        return (
            isResponse(response) &&
            response.status !== 200 &&
            'status' in response.data &&
            'exception' in response.data &&
            response.data.status === 'error'
        );
    };

    /**
     * @param {ICreateNoteResponse} response
     * @returns {response is ({ status: 422 } & ICreateNoteValidationErrorResponse )}
     */
    const isValidationErrorResponse = (response) => {
        return (
            isResponse(response) &&
            response.status !== 200 &&
            !('status' in response.data) &&
            'errors' in response.data
        );
    };

    /**
     * Get the result from the response.
     * @param {ICreateNoteResponse} response
     */
    const handleResponse = (response) => {
        /** @type {{ note: NoteResource } & IResponseResult} */
        const result = {
            status: 500,
            note: null,
            label: 'An unknown error occurred.',
            messages: [],
            warnings: [],
            errors: [],
        };
        // Assign the status.
        result.status = response.status;
        // Assign the primary message / result label.
        result.label = response.data.message;
        // Assign the user resource.
        if ('note' in response.data) {
            // Assign user resource to the result.
            const payload = response.data.note;
            result.note = new Note({ payload }).toResource();
        }
        // Handle response based on status code and schema.
        switch (response.status) {
            case 200:
                if (isSuccessResponse(response)) {
                    // If response successful...
                    // ...append additional messages.
                    result.messages = [
                        `Created note '${result.note.title}' successfully`,
                    ];
                    return result;
                }
            case 422:
                if (isValidationErrorResponse(response)) {
                    // If response (with validation errors)...
                    const { data } = response;
                    // ...append rule violations as individual errors.
                    const rules = Object.keys(data.errors);
                    const violations = rules.flatMap(
                        (rule) => data.errors[rule]
                    );
                    result.errors = violations;
                    return result;
                } else if (isDatabaseErrorResponse(response)) {
                    // If response (with database / query errors)...
                    const { data } = response;
                    // ...append database exception message.
                    result.errors = [data.exception?.message];
                    return result;
                }
            case 500:
                if (isDatabaseErrorResponse(response)) {
                    // If response (with database / query errors)...
                    const { data } = response;
                    // ...append database exception message.
                    result.errors = [data.exception?.message];
                    return result;
                }
            default:
                // If response of unknown schema.
                throw new TypeError(
                    `Unknown API schema received. Please contact your server administrator: ${JSON.stringify(
                        response.data
                    )}`
                );
        }
    };

    try {
        // <!-- REQUEST -->
        /** @type {ICreateNoteResponse} Response. */
        const response = await axios().post(
            ROUTES.CREATE_NOTE(account.id),
            request
        );
        const result = handleResponse(response);
        return result;
    } catch (e) {
        if ('response' in e && 'isAxiosError' in e) {
            // Get the error result, if possible.
            const result = handleResponse(e.response);
            return result;
        }
        // If no response present in error, throw the error up the stack.
        throw e;
    }
};

/**
 * Update existing account using request body content.
 * @param {Pick<AccountPayload, 'id'>} account
 * @param {IEditNoteTarget} target
 * @param {IEditNoteRequest} request
 * @returns {Promise<{ note: NoteResource } & IResponseResult>}
 */
export const updateNoteById = async (
    account = { id: 8 },
    target = { id: 0 },
    request = {
        title: '',
        content: '',
        start_date: DateTimeISO.format(Date.now()),
        end_date: '',
        location_hierarchy_id: null,
        locations: [],
    }
) => {
    // <!-- TYPE GUARDS -->
    /**
     * @param {any} response
     * @returns {response is IEditNoteResponse}
     */
    const isResponse = (response) => {
        return (
            !isNil(response) && // Non-null Axios response.
            !isNil(response.status) && // Non-null Axios status.
            !isNil(response.data) // Non-null Axios data property.
        );
    };

    /**
     * @param {IEditNoteResponse} response
     * @returns {response is ({ status: 200 } & IEditNoteSuccessResponse)}
     */
    const isSuccessResponse = (response) => {
        return (
            isResponse(response) &&
            response.status === 200 &&
            'status' in response.data &&
            !('exception' in response.data) &&
            response.data.status === 'updated' &&
            !isNil(response.data.note)
        );
    };

    /**
     * @param {IEditNoteResponse} response
     * @returns {response is ({ status: 422 | 400 | 500 } & IEditNoteDatabaseErrorResponse )}
     */
    const isDatabaseErrorResponse = (response) => {
        return (
            isResponse(response) &&
            response.status !== 200 &&
            'status' in response.data &&
            'exception' in response.data &&
            response.data.status === 'error'
        );
    };

    /**
     * @param {IEditNoteResponse} response
     * @returns {response is ({ status: 422 } & IEditNoteValidationErrorResponse )}
     */
    const isValidationErrorResponse = (response) => {
        return (
            isResponse(response) &&
            response.status !== 200 &&
            !('status' in response.data) &&
            'errors' in response.data
        );
    };

    /**
     * Get the result from the response.
     * @param {IEditNoteResponse} response
     */
    const handleResponse = (response) => {
        /** @type {{ note: NoteResource } & IResponseResult} */
        const result = {
            status: 500,
            note: null,
            label: 'An unknown error occurred.',
            messages: [],
            warnings: [],
            errors: [],
        };
        // Assign the status.
        result.status = response.status;
        // Assign the primary message / result label.
        result.label = response.data.message;
        // Assign the account resource.
        if ('note' in response.data) {
            // Assign account resource to the result.
            const payload = response.data.note;
            result.note = new Note({ payload }).toResource();
        }
        // Handle response based on status code and schema.
        switch (response.status) {
            case 200:
                if (isSuccessResponse(response)) {
                    // If response (without mail server exception)...
                    // ...append additional messages.
                    const title = result.note.title;
                    result.messages = [`Note '${title}' has been updated.`];
                    return result;
                }
            case 422:
                if (isValidationErrorResponse(response)) {
                    // If response (with validation errors)...
                    const { data } = response;
                    // ...append rule violations as individual errors.
                    const rules = Object.keys(data.errors);
                    const violations = rules.flatMap(
                        (rule) => data.errors[rule]
                    );
                    result.errors = violations;
                    return result;
                } else if (isDatabaseErrorResponse(response)) {
                    // If response (with database / query errors)...
                    const { data } = response;
                    // ...append database exception message.
                    result.errors = [data.exception?.message];
                    return result;
                }
            case 500:
                if (isDatabaseErrorResponse(response)) {
                    // If response (with database / query errors)...
                    const { data } = response;
                    // ...append database exception message.
                    result.errors = [data.exception?.message];
                    return result;
                }
            default:
                // If response of unknown schema.
                throw new TypeError(
                    `Unknown API schema received. Please contact your server administrator: ${JSON.stringify(
                        response.data
                    )}`
                );
        }
    };

    try {
        // <!-- REQUEST -->
        const formRequest = new FormData();
        // Create the form data request.
        // Add the PUT method.
        // title: 'Note (Updated)',
        // content: 'This is an example note after being updated.',
        // date: new Date().toISOString(),
        // location_hierarchy_id: '',
        // locations: '',
        // Add PUT method to request.
        const ignored = [
            'id',
            'author',
            'visible',
            'locations',
            'locationsCount',
        ];
        for (const key of Object.keys(request)) {
            const value = String(request[key] ?? '');
            if (!ignored.includes(key)) {
                console.log(
                    `[update::note] [append::formData] ${key} =>`,
                    value
                );
                formRequest.append(key, value);
            }
        }

        if ('locations' in request && request.locations?.length > 0) {
            request.locations.forEach((location, index) => {
                const key = `locations.${index}`;
                const value = String(location ?? '');
                if (value?.length > 0) {
                    console.log(
                        `[update::note] [append::formData] ${key} =>`,
                        location
                    );
                    formRequest.append(key, value);
                }
            });
        }

        formRequest.append('_method', 'PUT');

        /** @type {IEditNoteResponse} Response. */
        const response = await axios().post(
            ROUTES.UPDATE_NOTE(account.id, target.id),
            formRequest
        );
        const result = handleResponse(response);
        return result;
    } catch (e) {
        if ('response' in e && 'isAxiosError' in e) {
            // Get the error result, if possible.
            const result = handleResponse(e.response);
            return result;
        }
        // If no response present in error, throw the error up the stack.
        throw e;
    }
};

/**
 * Delete note.
 * @param {Pick<AccountPayload, 'id'>} account
 * @param {IDeleteNoteTarget} target
 * @returns {Promise<IResponseResult>}
 */
export const deleteNoteById = async (
    account = { id: 8 },
    target = { id: 0, title: 'Untitled Note' }
) => {
    // <!-- TYPE GUARDS -->
    /**
     * @param {any} response
     * @returns {response is IDeleteNoteResponse}
     */
    const isResponse = (response) => {
        return (
            !isNil(response) && // Non-null Axios response.
            !isNil(response.status) && // Non-null Axios status.
            !isNil(response.data) // Non-null Axios data property.
        );
    };

    /**
     * @param {IDeleteNoteResponse} response
     * @returns {response is ({ status: 200 } & IDeleteNoteSuccessResponse)}
     */
    const isSuccessResponse = (response) => {
        return (
            isResponse(response) &&
            response.status === 200 &&
            'status' in response.data &&
            !('exception' in response.data) &&
            response.data.status === 'deleted'
        );
    };

    /**
     * @param {IDeleteNoteResponse} response
     * @returns {response is ({ status: 403 } & IDeleteNoteForbiddenErrorResponse )}
     */
    const isForbbidenErrorResponse = (response) => {
        return (
            isResponse(response) &&
            response.status === 403 &&
            'status' in response.data &&
            'exception' in response.data &&
            response.data.status === 'error'
        );
    };

    /**
     * @param {IDeleteNoteResponse} response
     * @returns {response is ({ status: 500 } & IDeleteNoteServerErrorResponse )}
     */
    const isServerErrorResponse = (response) => {
        return (
            isResponse(response) &&
            response.status === 500 &&
            'status' in response.data &&
            'exception' in response.data &&
            response.data.status === 'error'
        );
    };

    /**
     * @param {IDeleteNoteResponse} response
     * @returns {response is ({ status: 404 } & IDeleteNoteExceptionResponse )}
     */
    const isMissingResponse = (response) => {
        return (
            isResponse(response) &&
            response.status === 404 &&
            'status' in response.data &&
            'exception' in response.data &&
            response.data.status === 'error'
        );
    };

    /**
     * @param {IDeleteNoteResponse} response
     * @returns {response is ({ status: 422 | 400 } & IDeleteNoteExceptionResponse )}
     */
    const isExceptionResponse = (response) => {
        return (
            isResponse(response) &&
            response.status !== 200 &&
            !('status' in response.data) &&
            'exception' in response.data
        );
    };

    /**
     * Get the result from the response.
     * @param {IDeleteNoteResponse} response
     * @returns {IResponseResult}
     */
    const handleResponse = (response) => {
        /** @type {IResponseResult} */
        const result = {
            status: 500,
            label: 'An unknown error occurred.',
            messages: [],
            warnings: [],
            errors: [],
        };
        // Assign the status.
        result.status = response.status;
        // Assign the primary message / result label.
        result.label = response.data.message;
        // Handle response based on status code and schema.
        switch (response.status) {
            case 200:
                if (isSuccessResponse(response)) {
                    // If response (without mail server exception)...
                    // ...append message about the target account.
                    result.messages = [
                        `Note '${target.title}' deleted successfully.`,
                    ];
                    return result;
                }
            case 403:
                if (isForbbidenErrorResponse(response)) {
                    // If response (with database / query errors)...
                    const { data } = response;
                    // ...append database exception message.
                    result.errors = [data.exception.message];
                    return result;
                }
            case 404:
                if (isMissingResponse(response)) {
                    // If response (with database / query errors)...
                    const { data } = response;
                    // ...append database exception message.
                    result.errors = [data.exception];
                    return result;
                }
            case 500:
                if (isServerErrorResponse(response)) {
                    // If response (with database / query errors)...
                    const { data } = response;
                    // ...append database exception message.
                    result.errors = [data.exception.message];
                    return result;
                }
            case 400:
            case 422:
                if (isExceptionResponse(response)) {
                    // If response (with database / query errors)...
                    const { data } = response;
                    // ...append database exception message.
                    result.errors = [data.exception];
                    return result;
                }
            default:
                // If response of unknown schema.
                throw new TypeError(
                    `Unknown API schema received. Please contact your server administrator: ${JSON.stringify(
                        response.data
                    )}`
                );
        }
    };

    try {
        // <!-- REQUEST -->
        /** @type {IDeleteNoteResponse} Response. */
        const response = await axios().delete(
            ROUTES.DELETE_NOTE(account.id, target.id)
        );
        const result = handleResponse(response);
        return result;
    } catch (e) {
        if ('response' in e && 'isAxiosError' in e) {
            // Get the error result, if possible.
            const result = handleResponse(e.response);
            return result;
        }
        // If no response present in error, throw the error up the stack.
        throw e;
    }
};

// <!-- EXPORTS -->
export default {
    Note,
    NotePayload,
    NoteResource,
    fetchNotes,
    fetchNoteById,
    createNote,
    updateNoteById,
    deleteNoteById,
};
