// <!-- API -->
import hierarchies from '@/api/v1/accounts/hierarchies';
import { LocationHierarchyIndexState } from '@/store/types/cache/state';

// <!-- TYPES -->

import { ECNBState } from '@/store/types/ECNBStore';
import { ECNBModule } from '@/store/types/ECNBModule';
import { ECNBResourceCacheModule } from '@/store/types/cache/module/ECNBResourceCacheModule';
import { LocationHierarchyResource } from '@/models/v1/locations/LocationHierarchy';
import { Assertion } from '@/store/types/ECNBAssertions';
import { LocationHierarchyIndexStateMutations } from '../mutations';

/**
 * @class
 * Cache resource module.
 */
export class LocationHierarchyCacheModule extends ECNBResourceCacheModule {
    /**
     * Name of the module.
     */
    static get namespace() {
        return 'cache/hierarchies';
    }

    /**
     * Send fetch request for an index of resources.
     *
     * @param {import('vuex').ActionContext} context
     * @returns {Promise<Map<Number, LocationHierarchyResource>>}
     */
    static async fetchIndex(context) {
        const { rootState, dispatch } = context;
        const account = rootState.accounts.account;
        const resources = await hierarchies.fetchHierarchies(account);

        // create index.
        /** @type {Map<Number, LocationHierarchyResource>} */
        const index = new Map();
        for (const hierarchy of resources.hierarchies) {
            index.set(hierarchy.id, hierarchy);
        }

        // cache unassigned ids.
        const unassigned = new Set(
            resources.unassignedLocations.map((location) => location.id)
        );
        await ECNBModule.use(context)
            .invoke('cacheUnassignedLocations')
            .withPayload({ unassigned })
            .throwIfRejected();

        // Return the index.
        return index;
    }

    /**
     * Send fetch request for a single resource.
     *
     * @param {import('vuex').ActionContext} context
     * @param {Number} id
     * @returns {Promise<LocationHierarchyResource>}
     */
    static async fetchResource(context, id) {
        const { rootState } = context;
        const account = rootState.accounts.account;
        const request = { id };
        const resource = await hierarchies.fetchHierarchyById(account, request);
        return resource;
    }

    /**
     * Module state, getters, mutations, and actions.
     */
    static get module() {
        // Inherited module properties.
        const $ = LocationHierarchyCacheModule;
        const $module = super.module;
        // EXPOSE
        return {
            ...$module,
            state: () => new LocationHierarchyIndexState(),
            get mutations() {
                const $ = LocationHierarchyIndexStateMutations;

                /**
                 * Assign provided payload item with existing property collection.
                 * @param {LocationHierarchyIndexState} state State instance.
                 * @param {Set<Number>} [ids] Unassigned location ids.
                 */
                const setUnassignedLocations = (state, ids) => {
                    $.set(state).unassigned(ids);
                };

                /**
                 * Assign provided payload item with existing property collection.
                 * @param {LocationHierarchyIndexState} state State instance.
                 * @param {{ id: Number, value: Boolean }} payload
                 */
                const setUnassignedLocation = (state, payload) => {
                    $.set(state).missing(payload);
                };

                /**
                 * Clear existing property collection.
                 * @param {LocationHierarchyIndexState} state State instance.
                 */
                const clearUnassignedLocations = (state) => {
                    $.clear(state).unassigned();
                };

                /**
                 * Merge provided payload item with existing property collection.
                 * @param {LocationHierarchyIndexState} state State instance.
                 * @param {Set<Number>} [ids] Unassigned location ids.
                 */
                const addUnassignedLocations = (state, ids) => {
                    $.add(state).unassigned(ids);
                };

                /**
                 * Merge provided payload item with existing property collection.
                 * @param {LocationHierarchyIndexState} state State instance.
                 * @param {Number} [id] Unassigned location id.
                 */
                const addUnassignedLocation = (state, id) => {
                    $.add(state).missing(id);
                };

                /**
                 * Separate provided payload items from existing property collection.
                 * @param {LocationHierarchyIndexState} state State instance.
                 * @param {Set<Number> | 'all'} [ids] Unassigned location ids.
                 */
                const dropUnassignedLocations = (state, ids) => {
                    $.drop(state).unassigned(ids);
                };

                /**
                 * Separate provided payload item from existing property collection.
                 * @param {LocationHierarchyIndexState} state State instance.
                 * @param {Number} id
                 */
                const dropUnassignedLocation = (state, id) => {
                    $.drop(state).missing(id);
                };

                return {
                    ...$module.mutations,
                    setUnassignedLocations,
                    clearUnassignedLocations,
                    addUnassignedLocations,
                    dropUnassignedLocations,

                    setUnassignedLocation,
                    addUnassignedLocation,
                    dropUnassignedLocation,
                };
            },
            get actions() {
                /**
                 * Module resource fetchers.
                 */
                const fetchers = {
                    /**
                     * Fetch and cache hierarchy index.
                     * @param {import('vuex').ActionContext<LocationHierarchyIndexState, ECNBState>} context
                     * @param {Object} payload Action payload.
                     * @param {Boolean} payload.ignoreCache If `true`, force cache refresh from remote.
                     * @returns {Promise<Map<Number, LocationHierarchyResource>>}
                     */
                    fetchIndex: async (context, payload) => {
                        const { state, rootState } = context;
                        const { ignoreCache = false } = payload ?? {};
                        const $use = ECNBModule.use(context);
                        const $mutate = $use.mutate; // Local mutation applicator.
                        const $invoke = $use.invoke; // Local action dispatcher.
                        // <!-- FETCHING -->
                        try {
                            // Fetch new index and cache it before selecting the index,
                            //   if ignoreCache is true or if the state is empty.
                            if (ignoreCache === true || state.is.empty) {
                                // Enable fetching status.
                                $mutate('addStatus').withPayload('fetching');
                                // Call the axios API endpoint. (Override in child classes).
                                const index = await $.fetchIndex(context);
                                // Ensure non-null response.
                                await Assertion.expect(index).isNotNil();
                                // Overwrite the index.
                                await $invoke('cacheIndex')
                                    .withPayload({
                                        index,
                                        append: false,
                                    })
                                    .dispatch();
                                // Warn if still empty after fetching and caching.
                                if (state.is.empty) {
                                    console.warn(`Index empty after fetching.`);
                                }
                            }
                        } catch (error) {
                            console.dir(error);
                        } finally {
                            // Disable fetching status.
                            $mutate('dropStatus').withPayload('fetching');
                        }

                        // <!-- SELECTING -->
                        // Return the index map.
                        return state.index;
                    },
                    /**
                     * Fetch and cache hierarchy resource.
                     * @param {import('vuex').ActionContext<LocationHierarchyIndexState, ECNBState>} context
                     * @param {Object} payload Action payload.
                     * @param {Number} payload.id Resource id.
                     * @param {Boolean} [payload.ignoreCache] Ignore cache flag.
                     * @param {LocationHierarchyResource} [payload.defaultValue] Default value (Optional).
                     * @returns {Promise<LocationHierarchyResource>}
                     */
                    fetchResource: async (context, payload) => {
                        const { state, rootState } = context;
                        const {
                            id,
                            ignoreCache = false,
                            defaultValue = null,
                        } = payload ?? {};
                        const $use = ECNBModule.use(context);
                        const $mutate = $use.mutate; // Local mutation applicator.
                        const $invoke = $use.invoke; // Local action dispatcher.
                        await Assertion.expect(id).isNotNil();

                        try {
                            // <!-- FETCHING -->
                            // Fetch resource and cache it if:
                            // - ignoreCache is `true`, or
                            // - state index is empty, or
                            // - state index is missing this specific resource already.
                            if (
                                ignoreCache === true ||
                                state.is.empty ||
                                !state.has.resource(id)
                            ) {
                                // Start fetching status.
                                $mutate('addStatus').withPayload('fetching');
                                // Call the axios API endpoint. (Override in child classes).
                                const resource = await $.fetchResource(
                                    context,
                                    id
                                );
                                // Ensure non-null response.
                                await Assertion.expect(resource).isNotNil();
                                // Cache the index.
                                await $invoke('cacheResource')
                                    .withPayload({
                                        resource,
                                    })
                                    .dispatch();
                                // Warn if still empty after fetching and caching.
                                if (state.is.empty || !state.has.resource(id)) {
                                    console.warn(`Resource not found.`);
                                }
                            }
                        } catch (error) {
                            console.dir(error);
                        } finally {
                            $mutate('dropStatus').withPayload('fetching');
                        }

                        // <!-- SELECTING -->
                        // Return the resource.
                        return state.select(id, defaultValue);
                    },
                };
                return {
                    ...$module.actions,
                    ...fetchers,
                    /**
                     * Merge or overwrite cached unassigned locations.
                     * @param {import('vuex').ActionContext<LocationHierarchyIndexState, ECNBState>} context
                     * @param {Object} payload
                     * @param {Set<Number>} payload.unassigned Unassigned locations set to merge with cache.
                     * @param {Boolean} [payload.append] Should set be appended to existing cache? Defaults to `true`. If `false`, will overwrite cache.
                     */
                    cacheUnassignedLocations: async (context, payload) => {
                        const { state } = context;
                        const { unassigned, append = true } = payload;
                        const $use = ECNBModule.use(context);
                        const $mutate = $use.mutate; // Local mutation applicator.
                        try {
                            $mutate('addStatus').withPayload('caching');
                            // Ensure unassigned is defined.
                            await Assertion.expect(unassigned).isNotNil();
                            if (append === true) {
                                // If append is true, merge with existing cache index.
                                $mutate('addUnassignedLocations').withPayload(
                                    unassigned
                                );
                            }
                            if (append === false) {
                                // If append is false, overwrite existing cache index.
                                $mutate('setUnassignedLocations').withPayload(
                                    unassigned
                                );
                            }
                            // Return updated cache unassigneds.
                            return state.attributes.get('unassigned');
                        } finally {
                            $mutate('dropStatus').withPayload('caching');
                        }
                    },
                    /**
                     * Merge or overwrite cached unassigned locations.
                     * @param {import('vuex').ActionContext<LocationHierarchyIndexState, ECNBState>} context
                     * @param {Object} payload
                     * @param {Number} payload.id Unassigned location id to merge with cache.
                     */
                    cacheUnassignedLocation: async (context, payload) => {
                        const { state } = context;
                        const { id } = payload;
                        const $use = ECNBModule.use(context);
                        const $mutate = $use.mutate; // Local mutation applicator.
                        try {
                            $mutate('addStatus').withPayload('caching');
                            // Ensure unassigned is defined.
                            await Assertion.expect(id).isNotNil();
                            // Append to cache.
                            $mutate('addUnassignedLocation').withPayload(id);
                            // Return updated cache unassigneds.
                            return state.attributes.get('unassigned');
                        } finally {
                            $mutate('dropStatus').withPayload('caching');
                        }
                    },
                    /**
                     * Fetch and cache hierarchy index.
                     * @param {import('vuex').ActionContext<LocationHierarchyIndexState, ECNBState>} context
                     * @param {Object} payload Action payload.
                     * @param {Boolean} payload.ignoreCache If `true`, force cache refresh from remote.
                     * @returns {Promise<Map<Number, LocationHierarchyResource>>}
                     */
                    fetchHierarchyIndex: fetchers.fetchIndex,
                    /**
                     * Fetch and cache hierarchy resource.
                     * @param {import('vuex').ActionContext<LocationHierarchyIndexState, ECNBState>} context
                     * @param {Object} payload Action payload.
                     * @param {Number} payload.id Resource id.
                     * @returns {Promise<LocationHierarchyResource>}
                     */
                    fetchHierarchy: fetchers.fetchResource,
                };
            },
        };
    }
}
