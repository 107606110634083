// <!-- IMPORTS -->

import { Config } from '@/utils/tree/Config';
import { Node, NodeRecord } from '@/utils/tree/node';

// <!-- SYNC TREE -->

/** @type {Treeview.Duplicators.TreeDuplicator} */
const syncTree = (source) => {
    return source;
    // const { config, nodes } = source;

    // // GET sorted nodes.
    // const collection = NodeRecord.sorted(nodes);

    // // FILTER hierarchy nodes that do not have location / station descendants.
    // const subtreesWithLocations = collection.filter((n) => {
    //     if (Node.isLocationNode(n) || Node.isWeatherStationNode(n)) return true;
    //     const descendants = NodeRecord.getDescendantsWithSelf(nodes, n);
    //     const locations = descendants.filter((n) => Node.isLocationNode(n));
    //     return locations.length > 0;
    // });

    // // GET root nodes.
    // const roots = subtreesWithLocations.filter((n) => Node.isRoot(n));
    // const rootIDs = roots.map((r) => r.id);

    // // GET leaf nodes.
    // const leaves = subtreesWithLocations.filter((n) => Node.isLeaf(n));
    // const leafIDs = leaves.map((l) => l.id);

    // // UPDATE config roots.
    // config.roots = rootIDs;

    // // UPDATE config leaves.
    // config.leaves = leafIDs;

    // // UPDATE node record.
    // const sortedRecord = NodeRecord.fromCollection(subtreesWithLocations);

    // // UPDATE tree.
    // const tree = { config, nodes: sortedRecord };
    // return tree;
};

// <!-- FACTORIES -->

/** @type {Treeview.Factories.TreeFactory} */
const createTree = (props = {}) => {
    const defaults = /** @type {Treeview.Tree} */ ({
        ...Tree.DefaultTree,
    });
    const instance = Object.assign({}, defaults);
    instance.config = Config.create(props?.config);
    instance.nodes = NodeRecord.create(props?.nodes);
    return instance;
    // const synced = syncTree(instance);
    // return synced;
};

// <!-- DUPLICATORS -->

/** @type {Treeview.Duplicators.TreeDuplicator} */
const cloneTree = (source) => {
    const config = Config.clone(source.config);
    const nodes = NodeRecord.clone(source.nodes);
    const instance = { config, nodes };
    return instance;
    // const synced = syncTree(instance);
    // return synced;
};

// <!-- MUTATIONS -->

/** @type {Treeview.Mutations.TreeMutation} */
const overrideTree = (source, patch) => {
    const target = cloneTree(source);
    target.config = Config.override(target.config, patch.config ?? {});
    target.nodes = NodeRecord.override(target.nodes, patch.nodes ?? {});
    return target;
    // const synced = syncTree(target);
    // return synced;
};

// <!-- CLASS -->

export class Tree {
    static get DefaultTree() {
        const config = Config.DefaultConfig;
        const nodes = NodeRecord.create({});
        return Object.freeze(
            /** @type {Treeview.Tree} */ ({
                config,
                nodes,
            })
        );
    }

    // <!-- SELECTORS -->
    /** @type {(tree: Treeview.Tree) => Treeview.Node[]} */
    static all = (tree) => NodeRecord.all(tree.nodes);

    /** @type {(tree: Treeview.Tree) => Treeview.Node[]} */
    static sorted = (tree) => NodeRecord.sorted(tree.nodes);

    /** @type {(tree: Treeview.Tree, predicate: ((node: Treeview.Node) => boolean)) => Treeview.Node[]} */
    static where = (tree, predicate) => NodeRecord.where(tree.nodes, predicate);

    /** @type {(tree: Treeview.Tree, id: string) => Treeview.Node} */
    static findByID = (tree, id) => NodeRecord.findByID(tree.nodes, id);

    /** @type {(tree: Treeview.Tree, selector: Treeview.NodeType | Treeview.NodeCode,  id: string) => Treeview.Node} */
    static findByResourceID = (tree, selector, id) =>
        NodeRecord.findByResourceID(tree.nodes, selector, id);

    // <!-- FACTORIES -->
    static create = createTree;
    static sync = syncTree;

    // <!-- DUPLICATORS -->
    static clone = cloneTree;

    // <!-- MUTATIONS -->
    static override = overrideTree;
}

// <!-- DEFAULT -->
export default Tree;
