// <!-- API -->
import { AxisValue } from '@/utils/filters/axis';

// <!-- UTILITIES -->
import pick from 'just-pick';
import isNil from 'lodash-es/isNil';
import gte from 'lodash-es/gte';
import lte from 'lodash-es/lte';

// <!-- TYPES -->
// ==== GRAPH FILTERS ====
/** @typedef {import('@/utils/filters').AxisExtent} AxisExtent Graph limit or scale extent. */
/** @typedef {import('@/utils/filters').IAxisRange} IAxisRange Record containing the lower and upper extents for a limit or scale. */

/**
 * @class
 * Record containing the inclusive lower and upper axis range bounds.
 * @implements {IAxisRange}
 */
export class AxisRange {
    /**
     * Create an axis range.
     * @param {Readonly<Partial<IAxisRange>>} [props]
     * @returns {IAxisRange}
     */
    static create = (props = {}) => new AxisRange(props);

    /**
     * Clone an existing axis range.
     * @param {Readonly<IAxisRange>} [source]
     * @returns {IAxisRange}
     */
    static clone = (source = new AxisRange()) => new AxisRange(source);

    /**
     * Extract only the data members from an existing instance.
     * @param {Readonly<IAxisRange>} [target]
     * @returns {IAxisRange}
     */
    static data = (target) => pick(target, 'lower', 'upper');

    /**
     * Access lower and upper parsers.
     */
    static get parse() {
        return {
            /**
             * Parse input string as a numeric lower axis value.
             * @param {String | Number | '' | null} [value] Value to parse as minimum.
             * @param {Readonly<Partial<{ whenUndefined: Number, whenInvalid: Number }>>} [defaults] Default values to use.
             */
            lower: (
                value = undefined,
                defaults = { whenUndefined: -Infinity }
            ) => AxisValue.parse(value, defaults),
            /**
             * Parse input string as a numeric upper axis value.
             * @param {String | Number | '' | null} [value] Value to parse as maximum.
             * @param {Readonly<Partial<{ whenUndefined: Number, whenInvalid: Number }>>} [defaults] Default values to use.
             */
            upper: (
                value = undefined,
                defaults = { whenUndefined: Infinity }
            ) => AxisValue.parse(value, defaults),
        };
    }

    /**
     * Access lower and upper validators.
     */
    static get validate() {
        return {
            /**
             * Determine if the input range is valid and if they are within the specified bounds, if any bounds are provided.
             * @param {Readonly<Partial<IAxisRange>>} [value] Range containing the lower and upper values to validate.
             * @param {Readonly<Partial<{ min: Number, max: Number }>>} [bounds] Absolute bounds to enforce, if any.
             * @returns {Boolean} Returns `true` when range is valid.
             */
            range: (value = null, bounds = null) => {
                // Get the default values, when items are missing.
                const { lower = -Infinity, upper = Infinity } = value ?? {};
                const { min = -Infinity, max = Infinity } = bounds ?? {};

                // Check against absolute bounds.
                const isLowerGTEMin = gte(lower, min);
                const isLowerLTEMax = lte(lower, max);
                const isUpperGTEMin = gte(upper, min);
                const isUpperLTEMax = lte(upper, max);

                // Compute the range validity.
                const isLowerValid =
                    isLowerGTEMin &&
                    isLowerLTEMax &&
                    AxisRange.validate.lower(lower, upper);
                const isUpperValid =
                    isUpperGTEMin &&
                    isUpperLTEMax &&
                    AxisRange.validate.upper(upper, lower);
                const isRangeValid = isLowerValid && isUpperValid;

                // console.dir({ lower, upper, min, max });
                // console.dir({
                //     isLowerGTEMin,
                //     isLowerLTEMax,
                //     isUpperGTEMin,
                //     isUpperLTEMax,
                //     isLowerValid,
                //     isUpperValid,
                //     isRangeValid,
                // });
                // debugger;

                // EXPOSE
                return isRangeValid;
            },
            /**
             * Determine if the input value is less than or equal to the upper boundary.
             * @param {Number | null} [value] Lower value.
             * @param {Number | null} [upper] Upper value.
             * @returns {Boolean} Returns `true` when range is valid.
             */
            lower: (value = -Infinity, upper = Infinity) => {
                const min = isNil(value) ? -Infinity : value;
                const max = isNil(upper) ? Infinity : upper;
                const isLowerLTEMax = lte(min, max);
                return isLowerLTEMax === true;
            },
            /**
             * Determine if the input value is greater than or equal to the lower boundary.
             * @param {Number | null} [value] Upper value.
             * @param {Number | null} [lower] Lower value.
             * @returns {Boolean} Returns `true` when range is valid.
             */
            upper: (value = -Infinity, lower = Infinity) => {
                const max = isNil(value) ? Infinity : value;
                const min = isNil(lower) ? -Infinity : lower;
                const isUpperGTEMin = gte(max, min);
                return isUpperGTEMin === true;
            },
        };
    }

    /**
     * Create an axis range.
     * @param {Readonly<Partial<IAxisRange>>} [props]
     */
    constructor(props) {
        // Get the default values (when a property is `undefined`).
        const { lower = -Infinity, upper = Infinity } = props ?? {};
        /** @type {AxisExtent} Minimum axis extent. */
        this.lower = lower;
        /** @type {AxisExtent} Maximum axis extent. */
        this.upper = upper;
    }
}

// <!-- DEFAULT -->
export default AxisRange;
