// <!-- UTILITIES -->
import { isNumber } from '@/utils/typeof';

// <!-- TYPES -->
/** @typedef {import('@/utils/formatters').IIntegerFormatter} IIntegerFormatter */

// <!-- EXPORTS -->
/**
 * @type {IIntegerFormatter} Formats integer according to the specified options. Set options.minimumIntegerDigits to affect zero-padding length minimums.
 *
 * @example
 * ```
 * formatInteger({ value: 12.3 }); // 12.3 ==> "12"
 * formatInteger({ value: 12.3, options: { minimumIntegerDigits: 2 } }); // 12.3 ==> "12"
 * formatInteger({ value: 12.3, options: { minimumIntegerDigits: 3 } }); // 12.3 ==> "012"
 * ```
 */
export const formatInteger = ({ value, options }) => {
    // <!-- DESTRUCTURE -->
    const input = value ?? NaN;

    // <!-- CONDITIONALS -->
    const isValid = isNumber(input) && !Number.isNaN(input);

    // When the input value is a valid number...
    if (isValid) {
        // Compute the truncated integer value.
        const integer = Math.floor(input);
        // Get the formatted integer with specified zero-padded minimum length.
        const config = options ?? { minimumIntegerDigits: 1 };
        const formatted = integer.toLocaleString('en-US', config);
        // Return the formatted string.
        return formatted;
    }

    // When the input value is NOT a valid number, return an empty string.
    return '';
};

// <!-- DEFAULT -->
export default formatInteger;
