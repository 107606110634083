/**
 * Upload error group identifiers.
 */
export const UploadErrorGroupID = /** @type {const} */ ({
    fileSelection: 'fileSelection',
    locationRequest: 'locationRequest',
    locationSuggestion: 'locationSuggestion',
    datasetCreation: 'datasetCreation',
    mappingProfileSuggestion: 'mappingProfileSuggestion',
    mappingProfileCreation: 'mappingProfileCreation',
    mappingProfileApplication: 'mappingProfileApplication',
    datasetIngestion: 'datasetIngestion',
});

/**
 * @class
 * Errors associated with the form.
 */
export class UploadErrors {
    /**
     * @type {Map<keyof UploadErrorGroupID, Set<String>>}
     */
    // @ts-ignore
    errors = new Map(
        Object.keys(UploadErrorGroupID).map((id) => {
            return [id, new Set()];
        })
    );
}
