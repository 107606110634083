// <!-- PLUGINS -->
import { useAxios as axios } from '@/plugins/axios';

// <!-- UTILITIES -->
import isNil from 'lodash-es/isNil';
import { toDate, formatISO } from 'date-fns';

// <!-- MODELS -->
import { Account } from '@/models/v2/accounts/AccountModel';
import { Location, LocationResource } from '@/models/v1/locations/Location';

// <!-- TYPES -->

/** @typedef {{ account: AccountPayload }} AccountResponse Account payload response. */
/** @typedef {Pick<AccountPayload, "name" | "billing_email" | "reminder" | "timezone" | "temp_scale" > & { city: String, state: String, country: String }} AccountRequest Account payload request. */
/** @typedef {AccountResponse} GetAccountByIdResponse */
/** @typedef {AccountRequest} CreateAccountRequest */
/** @typedef {AccountResponse} CreateAccountResponse */
/** @typedef {AccountRequest} UpdateAccountByIdRequest */
/** @typedef {{ status: "updated" | "error" } & AccountResponse} UpdateAccountByIdResponse */
/** @typedef {{ data: AccountPayload[] }} GetAccountsForUserResponse Collection of accounts associated with a single user. */
/** @typedef {{ data: AccountPayload[] }} GetAccountsResponse Collection of all accounts. */
/** @typedef {import('@/utils/standards').IStandardMetrics} IStandardMetrics */
/** @typedef {{ status: String, start_date: String, end_date: String, metrics: ({ location: LocationResource, metrics: IStandardMetrics })[] }} FetchNARAStandardMetricsResponse NARA Standard Metrics response. */

/** @typedef {Readonly<Pick<AccountPayload, 'id' | 'name'>>} IEditAccountTarget */
/** @typedef {Readonly<Pick<AccountPayload, 'name' | 'reminder' | 'temp_scale' | 'timezone' | 'account_tree_level'>>} IEditAccountRequest */
/** @typedef {Readonly<Pick<AccountPayload, 'id' | 'name'>>} IDeleteAccountTarget */

// <!-- TYPES -->
/** @typedef {import('@/api/v1').IRequestException} IRequestException */
/** @typedef {import('@/api/v1').IResponseResult} IResponseResult */
// ==== MODELS ====
/** @typedef {globalThis.Account.Model} AccountResource */
/** @typedef {import('@/models/v2/users/User').UserPayload} UserPayload */
/** @typedef {import('@/models/v2/users/User').UserResource} UserResource */
// ==== REQUESTS ====
/** @typedef {Readonly<Pick<AccountPayload, 'name' | 'reminder' | 'timezone' | 'temp_scale' | 'account_tree_level'>>} ICreateAccountRequest */
// ==== RESPONSES ====
/** @template [T=any] @template [D=any] @typedef {import('axios').AxiosResponse<T,D>} AxiosResponse */

/** @typedef {AxiosResponse<ICreateAccountSuccess, ICreateAccountRequest>} ICreateAccountSuccessResponse */
/** @typedef {AxiosResponse<ICreateAccountDatabaseError, ICreateAccountRequest>} ICreateAccountDatabaseErrorResponse */
/** @typedef {AxiosResponse<ICreateAccountValidationError, ICreateAccountRequest>} ICreateAccountValidationErrorResponse */
/** @typedef {ICreateAccountSuccessResponse | ICreateAccountDatabaseErrorResponse | ICreateAccountValidationErrorResponse} ICreateAccountResponse */

/** @typedef {AxiosResponse<IEditAccountSuccessData, IEditAccountRequest>} IEditAccountSuccessResponse */
/** @typedef {AxiosResponse<IEditAccountDatabaseErrorData, IEditAccountRequest>} IEditAccountDatabaseErrorResponse */
/** @typedef {AxiosResponse<IEditAccountValidationErrorData, IEditAccountRequest>} IEditAccountValidationErrorResponse */
/** @typedef {IEditAccountSuccessResponse | IEditAccountDatabaseErrorResponse | IEditAccountValidationErrorResponse} IEditAccountResponse */

/** @typedef {AxiosResponse<IDeleteAccountSuccessData, any>} IDeleteAccountSuccessResponse */
/** @typedef {AxiosResponse<IDeleteAccountErrorData, any>} IDeleteAccountForbiddenErrorResponse */
/** @typedef {AxiosResponse<IDeleteAccountErrorData, any>} IDeleteAccountServerErrorResponse */
/** @typedef {AxiosResponse<IDeleteAccountExceptionData, any>} IDeleteAccountExceptionResponse */
/** @typedef {IDeleteAccountSuccessResponse | IDeleteAccountForbiddenErrorResponse | IDeleteAccountServerErrorResponse | IDeleteAccountExceptionResponse } IDeleteAccountResponse */
// ==== DATA ====
/** @typedef {ICreateAccountSuccess | ICreateAccountDatabaseError | ICreateAccountValidationError} ICreateAccountData */
/**
 * @typedef {Object} ICreateAccountSuccess
 * @property {"created"} status Response status type.
 * @property {AccountPayload} account Account resource.
 * @property {String} message Human-readable message.
 */
/**
 * @typedef {Object} ICreateAccountDatabaseError
 * @property {"error"} status Response status type.
 * @property {String} message Human-readable message.
 * @property {IRequestException} exception Exception, if one was raised while sending the password reset link. If not present, no error when sending the email occurred.
 */
/**
 * @typedef {Object} ICreateAccountValidationError
 * @property {String} message Human-readable message.
 * @property {Record<String, Array<String>>} errors Record containing properties and the corresponding validation errors. Not present when account is created.
 */

/** @typedef {IEditAccountSuccessData | IEditAccountDatabaseErrorData | IEditAccountValidationErrorData} IEditAccountData */
/**
 * @typedef {Object} IEditAccountSuccessData
 * @property {"updated"} status Response status type.
 * @property {AccountPayload} account Account resource.
 * @property {String} message Human-readable message.
 */
/**
 * @typedef {Object} IEditAccountDatabaseErrorData
 * @property {"error"} status Response status type.
 * @property {String} message Human-readable message.
 * @property {IRequestException} exception Exception, if one was raised while sending the password reset link. If not present, no error when sending the email occurred.
 */
/**
 * @typedef {Object} IEditAccountValidationErrorData
 * @property {String} message Human-readable message.
 * @property {Record<String, Array<String>>} errors Record containing properties and the corresponding validation errors. Not present when account is updated.
 */

/** @typedef {IDeleteAccountSuccessData | IDeleteAccountErrorData | IDeleteAccountExceptionData} IDeleteAccountData */
/**
 * @typedef {Object} IDeleteAccountSuccessData
 * @property {"deleted"} status Response status type.
 * @property {String} message Human-readable message.
 */
/**
 * @typedef {Object} IDeleteAccountErrorData
 * @property {"error"} status Response status type.
 * @property {String} message Human-readable message.
 * @property {IRequestException} exception Exception, if one was raised while sending the password reset link. If not present, no error when sending the email occurred.
 */
/**
 * @typedef {Object} IDeleteAccountExceptionData
 * @property {String} message Human-readable message.
 * @property {String} exception Exception title.
 * @property {String} file File location.
 * @property {Number} line Line numbrer.
 * @property {({ file: String, line: Number, function: String, class: String, type: String })[]} trace Stack trace.
 */

// <!-- FIXTURES -->
import { StandardMetrics } from '@/utils/standards';
import { AccountLevel } from '@/models/v1/accounts';
import { AccountPayload } from '@/payloads/v2/accounts';
import { Maybe } from 'true-myth/dist/maybe';

// <!-- ROUTES -->
const ROUTES = {
    GET_ACCOUNTS: () => 'accounts',
    GET_PROFILE_ACCOUNTS: () => 'profile/accounts',
    GET_ACCOUNT: (id) => `accounts/${id}`,
    CREATE_ACCOUNT: () => 'accounts',
    UPDATE_ACCOUNT: (id) => `accounts/${id}`,
    DELETE_ACCOUNT: (id) => `accounts/${id}`,
    QUERY_NARA_STANDARD_METRICS: (id) =>
        `accounts/${id}/nara-standards-metrics`,
};

// <!-- REQUESTS -->

/**
 * Fetch all institution accounts.
 * @returns {Promise<AccountResource[]>}
 */
export const fetchAccounts = async () => {
    /** @type {Pick<AxiosResponse<GetAccountsResponse>, 'data'>} */
    const response = await axios().get(ROUTES.GET_ACCOUNTS());
    const payloads = response.data?.data ?? [];
    const models = payloads.map((payload) => new Account(payload));
    return models;
};

/**
 * Fetch all accounts associated with the current user.
 * @returns {Promise<AccountResource[]>}
 */
const fetchProfileAccounts = async () => {
    /** @type {import('axios').AxiosResponse<GetAccountsForUserResponse>} */
    const response = await axios().get(ROUTES.GET_PROFILE_ACCOUNTS());
    const payloads = response.data?.data ?? [];
    const models = payloads.map((payload) => new Account(payload));
    return models;
};

/**
 * Fetch the account specified by id.
 * @param {Pick<AccountResource, 'id'>} account
 * @returns {Promise<AccountResource>}
 */
export const fetchAccountById = async (account = { id: 8 }) => {
    /** @type {import('axios').AxiosResponse<GetAccountByIdResponse>} */
    const response = await axios().get(ROUTES.GET_ACCOUNT(account.id));
    const payload = Maybe.of(response.data?.account).map(
        (t) => new AccountPayload(t)
    );
    const model = payload.map((t) => new Account(t)).unwrapOr(null);
    return model;
};

/**
 * Create a new account and get the result of the operation.
 * @param {ICreateAccountRequest} request
 * @returns {Promise<{ account: AccountResource } & IResponseResult>}
 */
export const createAccount = async (
    request = {
        name: 'IPI Example',
        reminder: 'never',
        timezone: 'America/New_York',
        temp_scale: 'F',
        account_tree_level: null,
    }
) => {
    // <!-- TYPE GUARDS -->
    /**
     * @param {any} response
     * @returns {response is ICreateAccountResponse}
     */
    const isResponse = (response) => {
        return (
            !isNil(response) && // Non-null Axios response.
            !isNil(response.status) && // Non-null Axios status.
            !isNil(response.data) // Non-null Axios data property.
        );
    };

    /**
     * @param {ICreateAccountResponse} response
     * @returns {response is ({ status: 200 } & ICreateAccountSuccessResponse)}
     */
    const isSuccessResponse = (response) => {
        return (
            isResponse(response) &&
            response.status === 200 &&
            'status' in response.data &&
            !('exception' in response.data) &&
            response.data.status === 'created' &&
            !isNil(response.data.account)
        );
    };

    /**
     * @param {ICreateAccountResponse} response
     * @returns {response is ({ status: 422 | 400 | 500 } & ICreateAccountDatabaseErrorResponse )}
     */
    const isDatabaseErrorResponse = (response) => {
        return (
            isResponse(response) &&
            response.status !== 200 &&
            'status' in response.data &&
            'exception' in response.data &&
            response.data.status === 'error'
        );
    };

    /**
     * @param {ICreateAccountResponse} response
     * @returns {response is ({ status: 422 } & ICreateAccountValidationErrorResponse )}
     */
    const isValidationErrorResponse = (response) => {
        return (
            isResponse(response) &&
            response.status !== 200 &&
            !('status' in response.data) &&
            'errors' in response.data
        );
    };

    /**
     * Get the result from the response.
     * @param {ICreateAccountResponse} response
     */
    const handleResponse = (response) => {
        /** @type {{ account: AccountResource } & IResponseResult} */
        const result = {
            status: 500,
            account: null,
            label: 'An unknown error occurred.',
            messages: [],
            warnings: [],
            errors: [],
        };
        // Assign the status.
        result.status = response.status;
        // Assign the primary message / result label.
        result.label = response.data.message;
        // Assign the user resource.
        if ('account' in response.data) {
            // Assign user resource to the result.
            const payload = Maybe.of(response.data.account).map(
                (t) => new AccountPayload(t)
            );
            const model = payload.map((t) => new Account(t));
            result.account = model.unwrapOr(null);
        }
        // Handle response based on status code and schema.
        switch (response.status) {
            case 200:
                if (isSuccessResponse(response)) {
                    // If response successful...
                    // ...append additional messages.
                    result.messages = [
                        `Created account ${result.account.name} successfully`,
                    ];
                    return result;
                }
            case 422:
                if (isValidationErrorResponse(response)) {
                    // If response (with validation errors)...
                    const { data } = response;
                    // ...append rule violations as individual errors.
                    const rules = Object.keys(data.errors);
                    const violations = rules.flatMap(
                        (rule) => data.errors[rule]
                    );
                    result.errors = violations;
                    return result;
                } else if (isDatabaseErrorResponse(response)) {
                    // If response (with database / query errors)...
                    const { data } = response;
                    // ...append database exception message.
                    result.errors = [data.exception?.message];
                    return result;
                }
            case 500:
                if (isDatabaseErrorResponse(response)) {
                    // If response (with database / query errors)...
                    const { data } = response;
                    // ...append database exception message.
                    result.errors = [data.exception?.message];
                    return result;
                }
            default:
                // If response of unknown schema.
                throw new TypeError(
                    `Unknown API schema received. Please contact your server administrator: ${JSON.stringify(
                        response.data
                    )}`
                );
        }
    };

    try {
        // <!-- REQUEST -->
        /** @type {ICreateAccountResponse} Response. */
        const response = await axios().post(ROUTES.CREATE_ACCOUNT(), request);
        const result = handleResponse(response);
        return result;
    } catch (e) {
        if ('response' in e && 'isAxiosError' in e) {
            // Get the error result, if possible.
            const result = handleResponse(e.response);
            return result;
        }
        // If no response present in error, throw the error up the stack.
        throw e;
    }
};

/**
 * Update existing account using request body content.
 * @param {IEditAccountTarget} target
 * @param {IEditAccountRequest} request
 * @returns {Promise<{ account: AccountResource } & IResponseResult>}
 */
export const updateAccountById = async (
    target = { id: 8, name: 'NARA' },
    request
) => {
    // <!-- TYPE GUARDS -->
    /**
     * @param {any} response
     * @returns {response is IEditAccountResponse}
     */
    const isResponse = (response) => {
        return (
            !isNil(response) && // Non-null Axios response.
            !isNil(response.status) && // Non-null Axios status.
            !isNil(response.data) // Non-null Axios data property.
        );
    };

    /**
     * @param {IEditAccountResponse} response
     * @returns {response is ({ status: 200 } & IEditAccountSuccessResponse)}
     */
    const isSuccessResponse = (response) => {
        return (
            isResponse(response) &&
            response.status === 200 &&
            'status' in response.data &&
            !('exception' in response.data) &&
            response.data.status === 'updated' &&
            !isNil(response.data.account)
        );
    };

    /**
     * @param {IEditAccountResponse} response
     * @returns {response is ({ status: 422 | 400 | 500 } & IEditAccountDatabaseErrorResponse )}
     */
    const isDatabaseErrorResponse = (response) => {
        return (
            isResponse(response) &&
            response.status !== 200 &&
            'status' in response.data &&
            'exception' in response.data &&
            response.data.status === 'error'
        );
    };

    /**
     * @param {IEditAccountResponse} response
     * @returns {response is ({ status: 422 } & IEditAccountValidationErrorResponse )}
     */
    const isValidationErrorResponse = (response) => {
        return (
            isResponse(response) &&
            response.status !== 200 &&
            !('status' in response.data) &&
            'errors' in response.data
        );
    };

    /**
     * Get the result from the response.
     * @param {IEditAccountResponse} response
     */
    const handleResponse = (response) => {
        /** @type {{ account: AccountResource } & IResponseResult} */
        const result = {
            status: 500,
            account: null,
            label: 'An unknown error occurred.',
            messages: [],
            warnings: [],
            errors: [],
        };
        // Assign the status.
        result.status = response.status;
        // Assign the primary message / result label.
        result.label = response.data.message;
        // Assign the account resource.
        if ('account' in response.data) {
            // Assign account resource to the result.
            const payload = Maybe.of(response.data.account).map(
                (t) => new AccountPayload(t)
            );
            const model = payload.map((t) => new Account(t));
            result.account = model.unwrapOr(null);
        }
        // Handle response based on status code and schema.
        switch (response.status) {
            case 200:
                if (isSuccessResponse(response)) {
                    // If response (without mail server exception)...
                    // ...append additional messages.
                    const name = result.account.name;
                    result.messages = [`Account ${name} has been updated.`];
                    return result;
                }
            case 422:
                if (isValidationErrorResponse(response)) {
                    // If response (with validation errors)...
                    const { data } = response;
                    // ...append rule violations as individual errors.
                    const rules = Object.keys(data.errors);
                    const violations = rules.flatMap(
                        (rule) => data.errors[rule]
                    );
                    result.errors = violations;
                    return result;
                } else if (isDatabaseErrorResponse(response)) {
                    // If response (with database / query errors)...
                    const { data } = response;
                    // ...append database exception message.
                    result.errors = [data.exception?.message];
                    return result;
                }
            case 500:
                if (isDatabaseErrorResponse(response)) {
                    // If response (with database / query errors)...
                    const { data } = response;
                    // ...append database exception message.
                    result.errors = [data.exception?.message];
                    return result;
                }
            default:
                // If response of unknown schema.
                throw new TypeError(
                    `Unknown API schema received. Please contact your server administrator: ${JSON.stringify(
                        response.data
                    )}`
                );
        }
    };

    try {
        // <!-- REQUEST -->
        /** @type {IEditAccountResponse} Response. */
        const response = await axios().put(
            ROUTES.UPDATE_ACCOUNT(target.id),
            request
        );
        const result = handleResponse(response);
        return result;
    } catch (e) {
        if ('response' in e && 'isAxiosError' in e) {
            // Get the error result, if possible.
            const result = handleResponse(e.response);
            return result;
        }
        // If no response present in error, throw the error up the stack.
        throw e;
    }
};

/**
 * Delete account.
 * @param {IDeleteAccountTarget} target
 * @returns {Promise<IResponseResult>}
 */
export const deleteAccountById = async (target = { id: 8, name: 'NARA' }) => {
    // <!-- TYPE GUARDS -->
    /**
     * @param {any} response
     * @returns {response is IDeleteAccountResponse}
     */
    const isResponse = (response) => {
        return (
            !isNil(response) && // Non-null Axios response.
            !isNil(response.status) && // Non-null Axios status.
            !isNil(response.data) // Non-null Axios data property.
        );
    };

    /**
     * @param {IDeleteAccountResponse} response
     * @returns {response is ({ status: 200 } & IDeleteAccountSuccessResponse)}
     */
    const isSuccessResponse = (response) => {
        return (
            isResponse(response) &&
            response.status === 200 &&
            'status' in response.data &&
            !('exception' in response.data) &&
            response.data.status === 'deleted'
        );
    };

    /**
     * @param {IDeleteAccountResponse} response
     * @returns {response is ({ status: 403 } & IDeleteAccountForbiddenErrorResponse )}
     */
    const isForbbidenErrorResponse = (response) => {
        return (
            isResponse(response) &&
            response.status === 403 &&
            'status' in response.data &&
            'exception' in response.data &&
            response.data.status === 'error'
        );
    };

    /**
     * @param {IDeleteAccountResponse} response
     * @returns {response is ({ status: 500 } & IDeleteAccountServerErrorResponse )}
     */
    const isServerErrorResponse = (response) => {
        return (
            isResponse(response) &&
            response.status === 500 &&
            'status' in response.data &&
            'exception' in response.data &&
            response.data.status === 'error'
        );
    };

    /**
     * @param {IDeleteAccountResponse} response
     * @returns {response is ({ status: 404 } & IDeleteAccountExceptionResponse )}
     */
    const isMissingResponse = (response) => {
        return (
            isResponse(response) &&
            response.status === 404 &&
            'status' in response.data &&
            'exception' in response.data &&
            response.data.status === 'error'
        );
    };

    /**
     * @param {IDeleteAccountResponse} response
     * @returns {response is ({ status: 422 | 400 } & IDeleteAccountExceptionResponse )}
     */
    const isExceptionResponse = (response) => {
        return (
            isResponse(response) &&
            response.status !== 200 &&
            !('status' in response.data) &&
            'exception' in response.data
        );
    };

    /**
     * Get the result from the response.
     * @param {IDeleteAccountResponse} response
     * @returns {IResponseResult}
     */
    const handleResponse = (response) => {
        /** @type {IResponseResult} */
        const result = {
            status: 500,
            label: 'An unknown error occurred.',
            messages: [],
            warnings: [],
            errors: [],
        };
        // Assign the status.
        result.status = response.status;
        // Assign the primary message / result label.
        result.label = response.data.message;
        // Handle response based on status code and schema.
        switch (response.status) {
            case 200:
                if (isSuccessResponse(response)) {
                    // If response (without mail server exception)...
                    // ...append message about the target account.
                    result.messages = [
                        `Account ${target.name} deleted successfully.`,
                    ];
                    return result;
                }
            case 403:
                if (isForbbidenErrorResponse(response)) {
                    // If response (with database / query errors)...
                    const { data } = response;
                    // ...append database exception message.
                    result.errors = [data.exception.message];
                    return result;
                }
            case 404:
                if (isMissingResponse(response)) {
                    // If response (with database / query errors)...
                    const { data } = response;
                    // ...append database exception message.
                    result.errors = [data.exception];
                    return result;
                }
            case 500:
                if (isServerErrorResponse(response)) {
                    // If response (with database / query errors)...
                    const { data } = response;
                    // ...append database exception message.
                    result.errors = [data.exception.message];
                    return result;
                }
            case 400:
            case 422:
                if (isExceptionResponse(response)) {
                    // If response (with database / query errors)...
                    const { data } = response;
                    // ...append database exception message.
                    result.errors = [data.exception];
                    return result;
                }
            default:
                // If response of unknown schema.
                throw new TypeError(
                    `Unknown API schema received. Please contact your server administrator: ${JSON.stringify(
                        response.data
                    )}`
                );
        }
    };

    try {
        // <!-- REQUEST -->
        /** @type {IDeleteAccountResponse} Response. */
        const response = await axios().delete(ROUTES.DELETE_ACCOUNT(target.id));
        const result = handleResponse(response);
        return result;
    } catch (e) {
        if ('response' in e && 'isAxiosError' in e) {
            // Get the error result, if possible.
            const result = handleResponse(e.response);
            return result;
        }
        // If no response present in error, throw the error up the stack.
        throw e;
    }
};

/**
 * Fetch NARA Standard metrics for the account.
 * @param {Pick<AccountResource, 'id'>} account
 * @param {import('@/utils/filters').IInterval} dateRange
 * @param {import('@/types').AxiosRequestConfig} [config]
 * @returns {Promise<{ status: String, interval: import('@/utils/date').IInterval, metrics: ({ location: LocationResource, metrics: IStandardMetrics })[] }>}
 */
export const fetchNARAStandardMetrics = async (
    account = {
        id: 8,
    },
    dateRange = {
        start: 0, // JavaScript epoch.
        end: Date.now(), // Today's date.
    },
    config
) => {
    // Build the request.
    const { id } = account;
    const { start = 0, end = Date.now() } = dateRange;
    const start_date = formatISO(toDate(start), {
        format: 'extended',
        representation: 'date',
    }); // Y-m-d format.
    const end_date = formatISO(toDate(end), {
        format: 'extended',
        representation: 'date',
    }); // Y-m-d format.
    const request = {
        start_date,
        end_date,
    };
    // debugger;

    /** @type {import('axios').AxiosResponse<FetchNARAStandardMetricsResponse>} */
    const response = await axios().post(
        ROUTES.QUERY_NARA_STANDARD_METRICS(id),
        request,
        config ?? {}
    );
    const payload = response.data;
    const status = payload.status;
    const interval = {
        start: new Date(`${payload.start_date}T00:00`),
        end: new Date(`${payload.end_date}T00:00`),
    };
    const metrics = payload.metrics.map(({ location, metrics }) => {
        return {
            location: new Location({ payload: location }).toResource(),
            metrics: new StandardMetrics(metrics),
        };
    });
    return {
        status,
        interval,
        metrics,
    };
};

// <!-- EXPORTS -->
export default {
    Account,
    AccountLevel,
    fetchAccounts,
    fetchProfileAccounts,
    fetchNARAStandardMetrics,
    fetchAccountById,
    createAccount,
    updateAccountById,
    deleteAccountById,
};
