<template>
    <footer class="bg-primary-800 p-4">
        <div class="max-w-7xl mx-auto overflow-hidden sm:px-6 lg:px-8">
            <!-- <nav
                class="flex flex-wrap justify-center w-full"
                aria-label="Footer"
            >
                <div class="flex px-5 py-2 space-x-12">
                    <div
                        v-for="item in navigation.main"
                        :key="item.name"
                    >
                        <a
                            :href="item.href"
                            class="main-nav-link text-secondary-500 font-medium hover:text-white"
                        >
                            {{ item.name }}
                        </a>
                    </div>
                    <div class="gap-6 space-x-12">
                        <a
                            :href="supportLink"
                            class="main-nav-link text-secondary-500 font-medium hover:text-white"
                            target="_blank"
                        >
                            Support
                        </a>
                        <a
                            :href="resourcesLink"
                            class="main-nav-link text-secondary-500 font-medium hover:text-white"
                            target="_blank"
                        >
                            Resources
                        </a>
                    </div>
                </div>
            </nav> -->
            <!-- <div class="mt-2 flex justify-center space-x-6 w-full">
                <a
                    class="text-gray-400 hover:text-gray-500"
                    href="https://www.facebook.com/imagepermanenceinstitute"
                    target="_blank"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="white"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        class="opacity-65 hover:opacity-100"
                    >
                        <path
                            d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm3 8h-1.35c-.538 0-.65.221-.65.778v1.222h2l-.209 2h-1.791v7h-3v-7h-2v-2h2v-2.308c0-1.769.931-2.692 3.029-2.692h1.971v3z"
                        />
                    </svg>
                </a>
                <a
                    class="text-gray-400 hover:text-gray-500"
                    href="https://www.instagram.com/image.permanence.institute/"
                    target="_blank"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="white"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        class="opacity-65 hover:opacity-100"
                    >
                        <path
                            d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"
                        />
                    </svg>
                </a>
                <a
                    class="text-gray-400 hover:text-gray-500"
                    href="https://www.youtube.com/c/imagepermanenceinstitute"
                    target="_blank"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="white"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        class="opacity-65 hover:opacity-100"
                    >
                        <path
                            d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"
                        />
                    </svg>
                </a>
                <a
                    class="text-gray-400 hover:text-gray-500"
                    href="https://www.linkedin.com/company/image-permanence-institute"
                    target="_blank"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="white"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        class="opacity-65 hover:opacity-100"
                    >
                        <path
                            d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"
                        />
                    </svg>
                </a>
            </div> -->
            <p class="text-center text-base text-gray-400 w-full">
                {{ copyright }}
            </p>
        </div>
    </footer>
</template>

<script>
    // <!-- API -->
    import { defineComponent, onMounted, ref } from 'vue';
    import { computedEager } from '@vueuse/core';

    // <!-- COMPOSABLE -->
    import { useMarketingDomain } from '@/hooks/env/useMarketingDomain';

    // <!-- UTILITIES -->
    import { Emoji } from '@/utils/emoji';

    // <!-- CONSTANTS -->
    /** Navigation links. @type {{ main: Array<{ name: String, href: String }> }} */
    const navigation = {
        main: [
            {
                name: 'About',
                href: 'https://www.imagepermanenceinstitute.org/about/index.html',
            },
            {
                name: 'Work With Us',
                href: 'https://www.imagepermanenceinstitute.org/consulting/index.html',
            },
            {
                name: 'Contact',
                href: 'https://www.imagepermanenceinstitute.org/contact.html',
            },
        ],
    };

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'Footer',
        setup() {
            // ==== COMPOSABLES ====
            const { getMarketingLink } = useMarketingDomain();

            // ==== STATE ====
            const supportLink = ref('');
            const resourcesLink = ref('');
            const copyrightYear = ref(new Date().getFullYear());

            // ==== COMPUTED ====
            const copyright = computedEager(() => {
                const symbol = Emoji.copyright;
                const year = copyrightYear.value;
                const owner =
                    'Image Permanence Institute at Rochester Institute of Technology';
                const rights = 'All Rights Reserved';
                return `${symbol} ${year} ${owner}. ${rights}.`;
            });

            // ==== LIFECYCLE ====
            onMounted(async () => {
                supportLink.value = getMarketingLink('support/');
                resourcesLink.value = getMarketingLink(
                    'resources/docs/getting-started'
                );
            });

            // ==== EXPOSE ====
            return {
                navigation,
                supportLink,
                resourcesLink,
                copyright,
            };
        },
    });
</script>
