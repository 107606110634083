/**
 * Handle request error.
 *
 * @param {any} error Error content.
 * @returns {Promise<never>} Promise that rejects with the stated reason.
 */
export const onRequestError = (error) => {
    console.error('Request failed.', error);
    return Promise.reject(error);
};

/**
 * Handle response error.
 *
 * @param {any} error Error content.
 * @returns {Promise<never>} Promise that rejects with the stated reason.
 */
export const onResponseError = (error) => {
    console.error('Response error.', error);
    return Promise.reject(error);
};

/**
 * If the Bearer token is present in localStorage, use it. Otherwise, ignore.
 *
 * @param {import('axios').AxiosRequestConfig<any>} request Axios request interface.
 */
export const useLocalBearerTokenRequest = (request) => {
    const auth_token = localStorage.getItem('auth_token');
    if (auth_token) {
        request.headers['Authorization'] = `Bearer ${auth_token}`;
    } else {
        console.warn('Bearer token not added.');
    }
    return request;
};

export default {
    useLocalBearerTokenRequest,
    onRequestError,
    onResponseError,
};
