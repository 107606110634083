<template>
    <div
        v-if="!!$slots.default"
        :class="[
            'modal__panel-content',
            'border-solid border-transparent',
            {
                'border-b-2': !!border,
                'bg-white':
                    !isBlack &&
                    !isWhite &&
                    !isPrimary &&
                    !isSecondary &&
                    !isInfo &&
                    !isWarning &&
                    !isSuccess &&
                    !isFailure &&
                    !isDanger &&
                    !isError,
                'bg-black text-white border-white': isBlack,
                'bg-white text-black border-black': isWhite,
                'bg-white text-primary-700 border-primary-700': isPrimary,
                'bg-white text-secondary-700 border-secondary-700': isSecondary,
                'bg-blue-50 text-info border-info': isInfo,
                'bg-gray-800 text-warning border-warning': isWarning,
                'bg-green-50 text-success border-success': isSuccess,
                'bg-red-50 text-error border-error':
                    isFailure || isDanger || isError,
            },
        ]"
    >
        <div class="p-4 flex flex-col">
            <slot
                :disabled="disabled"
                :busy="busy"
            >
            </slot>
        </div>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent, toRef } from 'vue';

    // <!-- ENUMS -->
    import { Theme } from '@/enums';

    // <!-- COMPOSABLES -->
    import { useEnum } from '@/hooks/useEnum';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'ModalContent',
        props: {
            /**
             * Determine background color and text color using the {@link Theme} enum.
             * Use `null` to supply your own overrides in the `class` attribute.
             *
             * @example
             * ```
             * <ModalContent :theme="null" class="bg-pink-400" /> // Override with custom theme.
             * <ModalContent theme="success" /> // Success theme.
             * ```
             * */
            theme: {
                /** @type {Vue.PropType<Theme[keyof Theme['_dictionary']] | null>} */
                // @ts-ignore
                type: String,
                default: Theme.white,
                validator: (value) =>
                    value === null ||
                    Object.values(Theme).includes(
                        /** @type {Theme[keyof Theme['_dictionary']]} */ (value)
                    ),
            },
            /** Is the border visible? */
            border: {
                /** @type {Vue.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            /** If `true`, button actions are disabled. */
            disabled: {
                /** @type {Vue.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            /** If `true`, button actions are disabled and busy. */
            busy: {
                /** @type {Vue.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
        },
        setup(props) {
            // <!-- DESTRUCTURE -->
            const theme = toRef(props, 'theme');

            // <!-- CONDITIONALS -->
            const {
                isBlack,
                isWhite,
                isPrimary,
                isSecondary,
                isInfo,
                isWarning,
                isSuccess,
                isFailure,
                isDanger,
                isError,
            } = useEnum(Theme, theme);

            // <!-- EXPOSE -->
            return {
                Theme,
                isBlack,
                isWhite,
                isPrimary,
                isSecondary,
                isInfo,
                isWarning,
                isSuccess,
                isFailure,
                isDanger,
                isError,
            };
        },
    });
</script>
