// <!-- API -->
import isNil from 'lodash-es/isNil';

/**
 * Synchronous state instance mutations.
 */
export class ECNBStateMutations {
    /**
     * Create mutation context.
     */
    static get mutate() {
        /**
         * Apply passed mutation function to the provided state, with an optional payload.
         *
         * @template {any} [S=any]
         * @template {any} [P=undefined]
         * @param {(this: S, state: S, payload?: P) => void} mutator Mutation function.
         * @param {S} state State instance.
         * @param {P} [payload] Optional payload instance.
         */
        const $applyMutation = (mutator, state, payload) => {
            // PARAMETER VALIDATION
            if (typeof mutator !== 'function') {
                throw new TypeError(`Provided mutator must be a function.`);
            }
            if (isNil(state)) {
                throw new TypeError(`State instance must be defined.`);
            }

            // Apply the mutation with state as the `this` context.
            mutator.call(state, state, payload);

            // Return nothing.
            return;
        };

        return {
            /**
             * Apply provided mutation function with the provided state, with an optional payload.
             *
             * This is a terminal operation.
             *
             * @template {any} [S=any]
             * @template {any} [P=undefined]
             * @param {(this: S, state: S, payload?: P) => void} mutator Mutation function.
             * @param {S} state State instance.
             * @param {P} [payload] Optional payload instance.
             */
            using: $applyMutation,

            /**
             * Prepare mutation with provided state.
             * @template {any} [P=any]
             * @param {P} payload Required payload instance.
             */
            withPayload: (payload) => {
                const $payload = payload;
                return {
                    /**
                     * Apply provided mutation function with provided state and required payload.
                     *
                     * This is a terminal operation.
                     *
                     * @template {any} [S=any]
                     * @param {(this: S, state: S, payload: P) => void} mutator Mutation function.
                     * @param {S} state State instance.
                     */
                    using: (mutator, state) => {
                        $applyMutation(mutator, state, payload);
                    },
                    /**
                     * Prepare mutation with provided state instance and required payload.
                     * @template {any} [S=any]
                     * @param {S} state State instance.
                     */
                    withState: (state) => {
                        const $state = state;
                        return {
                            /**
                             * Apply provided mutation function with lexical state and required payload.
                             *
                             * This is a terminal operation.
                             *
                             * @param {(this: S, state: S, payload?: P) => void} mutator Mutation function.
                             */
                            using: (mutator) => {
                                $applyMutation(mutator, $state, $payload);
                            },
                        };
                    },
                };
            },

            /**
             * Prepare mutation with provided state.
             * @template {any} [S=any]
             * @param {S} state State instance.
             */
            withState: (state) => {
                const $state = state;
                return {
                    /**
                     * Apply provided mutation function with the lexical state, with an optional payload.
                     *
                     * This is a terminal operation.
                     *
                     * @template {any} [P=undefined]
                     * @param {(this: S, state: S, payload?: P) => void} mutator Mutation function.
                     * @param {P} [payload] Optional payload instance.
                     */
                    using: (mutator, payload = undefined) => {
                        $applyMutation(mutator, $state, payload);
                    },
                    /**
                     * Prepare mutation with lexical state instance and required payload.
                     * @template {any} [P=undefined]
                     * @param {P} payload Payload instance.
                     */
                    withPayload: (payload) => {
                        const $payload = payload;
                        return {
                            /**
                             * Apply provided mutation function with lexical state and required payload.
                             *
                             * This is a terminal operation.
                             *
                             * @param {(this: S, state: S, payload?: P) => void} mutator Mutation function.
                             */
                            using: (mutator) => {
                                $applyMutation(mutator, $state, $payload);
                            },
                        };
                    },
                };
            },
        };
    }
}
