// <!-- UTILITIES -->
import { Enum } from '@/utils/enums';

// <!-- PROVINCE REFERENCE -->
/** @type {Province.References} */
export const Provinces = [
    { iso2: 'CA-AB', subdivision: 'Alberta' },
    { iso2: 'CA-BC', subdivision: 'British Columbia' },
    { iso2: 'CA-MB', subdivision: 'Manitoba' },
    { iso2: 'CA-NB', subdivision: 'New Brunswick' },
    { iso2: 'CA-NL', subdivision: 'Newfoundland and Labrador' },
    { iso2: 'CA-NS', subdivision: 'Nova Scotia' },
    { iso2: 'CA-ON', subdivision: 'Ontario' },
    { iso2: 'CA-PE', subdivision: 'Prince Edward Island' },
    { iso2: 'CA-QC', subdivision: 'Quebec' },
    { iso2: 'CA-SK', subdivision: 'Saskatchewan' },
    { iso2: 'CA-NT', subdivision: 'Northwest Territories' },
    { iso2: 'CA-NU', subdivision: 'Nunavut' },
    { iso2: 'CA-YT', subdivision: 'Yukon' },
];

// <!-- PROVINCE NAME -->
// Get the region subdivision names.
const Names = Provinces.map((region) => region.subdivision);

// Get record mapping iso2 codes to subdivision names.
const NameByISO2 = Provinces.reduce((context, region) => {
    return Object.assign(context, {
        [region.iso2]: region.subdivision,
    });
}, /** @type {Province.ByISO2} */ ({}));

// Get the region subdivision iso2 codes.
const ISO2s = Provinces.map((region) => region.iso2);

// Get record mapping province names to iso2 codes.
const ISO2ByName = Provinces.reduce((context, region) => {
    return Object.assign(context, {
        [region.subdivision]: region.iso2,
    });
}, /** @type {Province.ISO2ByName} */ ({}));

// <!-- NAMED EXPORTS -->
/** Helper class for province enums. */
export class ProvinceName {
    /** Enum mapping iso2 codes to province names. */
    static ByISO2 = Enum.create(NameByISO2);

    /** Get array of province names. */
    static get values() {
        return [...Names];
    }

    /**
     * Check if the passed value is a valid province name.
     *
     * @param {string} value
     * @returns {value is Province.Name}
     */
    static isProvince(value) {
        return (
            typeof value === 'string' &&
            Names.includes(/** @type {Province.Name} */ (value))
        );
    }
}

/** Helper class for iso2 province codes. */
export class ProvinceISO2 {
    /** Enum mapping iso2 codes to province names. */
    static ByName = Enum.create(ISO2ByName);

    /** Get array of province names. */
    static get values() {
        return [...ISO2s];
    }

    /**
     * Check if the passed value is a valid province name.
     *
     * @param {string} value
     * @returns {value is Province.ISO2}
     */
    static isProvince(value) {
        return (
            typeof value === 'string' &&
            ISO2s.includes(/** @type {Province.ISO2} */ (value))
        );
    }
}
