// <!-- API -->
import { LimitFilter } from '@/utils/filters';

// <!-- TYPES -->
/** @typedef {import('@/utils/filters').IAxisID} IAxisID */
/** @typedef {import('@/utils/filters').ILimitFilter} ILimitFilter */
/** @typedef {import('@/utils/filters').ILimitFilterRecord} ILimitFilterRecord */

/**
 * @class
 * Individual limit filter for the keyed axis.
 * @implements {ILimitFilterRecord}
 */
export class LimitFilterRecord {
    /**
     * Create an axis range.
     * @param {Readonly<Iterable<Readonly<ILimitFilter>>>} [props]
     * @returns {ILimitFilterRecord}
     */
    static create = (props = []) => {
        const record = new LimitFilterRecord();
        for (const prop of props) {
            record[prop.key] = prop;
        }
        return record;
    };

    /**
     * Clone an existing axis range.
     * @param {Readonly<ILimitFilterRecord>} [source]
     * @returns {ILimitFilterRecord}
     */
    static clone = (source = new LimitFilterRecord()) =>
        new LimitFilterRecord(source);

    /**
     * Collect keys from the record and return the iterable.
     * @param {ILimitFilterRecord} [source] Source filter record to select entries from.
     */
    static *keys(source = new LimitFilterRecord()) {
        const filters = /** @type {const} */ (['temp', 'rh', 'dp']);
        for (const key of filters) {
            yield key;
        }
        return;
    }

    /**
     * Collect entries from the record and return the iterable. Useful for creating {@link Map} instances.
     * @param {ILimitFilterRecord} [source] Source filter record to select entries from.
     */
    static *entries(source = new LimitFilterRecord()) {
        for (const key of LimitFilterRecord.keys(source)) {
            /** @type {[ key: IAxisID, filter: ILimitFilter ]} */
            const entry = [key, source[key]];
            yield entry;
        }
        return;
    }

    /**
     * Collect values from the record and return the iterable.
     * @param {ILimitFilterRecord} [source] Source filter record to select entries from.
     */
    static *values(source = new LimitFilterRecord()) {
        for (const key of LimitFilterRecord.keys(source)) {
            yield source[key];
        }
        return;
    }

    /**
     * Create an axis range.
     * @param {Readonly<Partial<ILimitFilterRecord>>} [props]
     */
    constructor(props) {
        // Get the temperature filter.
        const temp = LimitFilter.temp(props?.temp);

        // Get the relative humidity filter.
        const rh = LimitFilter.rh(props?.rh);

        // Initialize dew point filter.
        const dp = LimitFilter.dp(props?.dp);

        /** @type {ILimitFilter} Temperature filter. */
        this.temp = temp;

        /** @type {ILimitFilter} Relative humidity filter. */
        this.rh = rh;

        /** @type {ILimitFilter} Dew point filter. */
        this.dp = dp;
    }
}
