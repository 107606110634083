// <!-- API -->
import isNil from 'lodash-es/isNil';

// <!-- TYPES -->
import {
    UploadWorkflow,
    WorkflowStatusID,
} from '@/store/types/uploader/state/UploadWorkflow';
import { FormStep } from '@/hooks/useFormStep';

/**
 * Synchronous getters for a {@link UploadWorkflow} instance.
 */
export const UploadWorkflowGetters = {
    /**
     * Getters for the workflow.
     * @param {UploadWorkflow} context
     */
    get: (context) => ({
        /**
         * Get count of all steps.
         */
        count: () => context.count,
        /**
         * Get steps map.
         */
        steps: () => context.steps,
        /**
         * Get the sequence.
         */
        sequence: () => context.sequence,
        /**
         * Get the first step.
         */
        first: () => context.firstStep,
        /**
         * Get the last step.
         */
        last: () => context.lastStep,
        /**
         * Get the previous step.
         */
        previous: () => context.previousStep,
        /**
         * Get the current step.
         */
        current: () => context.currentStep,
        /**
         * Get the next step.
         */
        next: () => context.nextStep,
        /**
         * Get the index of the provided step.
         * @param {Pick<FormStep, 'id'>} step
         */
        indexOf: ({ id }) => {
            if (!!id && id !== '') {
                const index = context.sequence.findIndex((s) => s.id === id);
                return index;
            }
            return -1;
        },
        /**
         * Verify step exists.
         * @param {Pick<FormStep, 'id'>} step
         */
        step: ({ id }) => {
            return UploadWorkflowGetters.get(context).withID(id);
        },
        /**
         * Get step by id.
         * @param {String} id
         */
        withID: (id) => {
            if (!!id && id !== '') {
                const step = context.steps.get(id);
                return step;
            }
            return null;
        },
        /**
         * Get step by index.
         * @param {Number} index
         */
        at: (index) => {
            if (index >= 0 && index < context.count) {
                return context.steps[index];
            }
            // Index not found.
            return null;
        },
        /**
         * Get the step labels.
         * @returns {String[]}
         */
        labels: () => {
            return context.all.pluck('label').all();
        },
    }),
    /**
     * Validators for the workflow.
     * @param {UploadWorkflow} context
     */
    has: (context) => ({
        /**
         * Verify at least one step exists.
         */
        steps: () => context.count > 0,
        /**
         * Verify step exists.
         */
        first: () => context.firstStep !== undefined,
        /**
         * Verify step exists.
         */
        last: () => context.lastStep !== undefined,
        /**
         * Verify step exists.
         */
        previous: () => context.previousStep !== undefined,
        /**
         * Verify step exists.
         */
        current: () => context.currentStep !== undefined,
        /**
         * Verify step exists.
         */
        next: () => context.nextStep !== undefined,
        /**
         * Verify step exists.
         */
        get step() {
            const $has = UploadWorkflowGetters.has(context);
            return {
                /**
                 * Does the provided form step exist?
                 * @param {Pick<FormStep, 'id'>} step
                 */
                is: ({ id }) => $has.step.withID(id),
                /**
                 * Does the provided form step exist?
                 * @param {FormStep['id']} id
                 */
                withID: (id) => {
                    const validID = !isNil(id);
                    return (
                        validID &&
                        context.steps.has(id) &&
                        !isNil(context.steps.get(id))
                    );
                },
                /**
                 * Does the provided form step exist?
                 * @param {Number} index
                 */
                at: (index) => context.hasStep(index),
            };
        },
        /**
         * Check if status is present.
         * @param {keyof WorkflowStatusID} id
         */
        status: (id) => context.status.has(id),
    }),
    /**
     * Validators for the workflow state.
     * @param {UploadWorkflow} context
     */
    is: (context) => ({
        current: {
            /**
             * Check if the passed step is the current one.
             * @param {Pick<FormStep, 'id'>} step
             */
            step: ({ id }) => context.currentStep.id === id,
            /**
             * Check if the passed step index is the current one.
             * @param {Number} index
             */
            index: (index) => context.currentStepIndex === index,
        },
        first: {
            /**
             * Check if the passed step is the first one.
             * @param {Pick<FormStep, 'id'>} step
             */
            step: ({ id }) => context.firstStep.id === id,
            /**
             * Check if the passed step index is the first one.
             * @param {Number} index
             */
            index: (index) => 0 === index,
        },
        last: {
            /**
             * Check if the passed step is the last one.
             * @param {Pick<FormStep, 'id'>} step
             */
            step: ({ id }) => context.lastStep.id === id,
            /**
             * Check if the passed step index is the last one.
             * @param {Number} index
             */
            index: (index) => 0 === index,
        },
    }),
};
