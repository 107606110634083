// <!-- PLUGINS -->
import { useAxios as axios } from '@/plugins/axios';
import { AxiosError } from 'axios';

// <!-- UTILITIES -->
import { Result } from 'true-myth/dist/result';
import { ErrorResult } from '@/utils/server';

// <!-- MODELS -->
import { Organization } from '@/models/v2/organizations';
import { Account } from '@/models/v2/accounts';
import { AccountPayload } from '@/payloads/v2/accounts';
import { User } from '@/models/v2/users';
import { UserPayload } from '@/payloads/v2/users';
import { Subscription } from '@/models/v2/subscriptions';

// <!-- ROUTES -->

/** @type {Readonly<globalThis.Organization.Routes>} */
const URI = {
    index: () => `organizations`,
    show: (id) => `organizations/${id}`,
    create: () => `organizations`,
    update: (id) => `organizations/${id}`,
    delete: (id) => `organizations/${id}`,
    accounts: (id) => `organizations/${id}/accounts`,
    users: (id) => `organizations/${id}/users`,
    subscriptions: (id) => `organizations/${id}/subscriptions`,
};

// <!-- ENDPOINTS -->

/**
 * Get all organizations available in the database.
 * @type {globalThis.Organization.API.FetchIndex}
 */
export const fetchOrganizations = async (config) => {
    try {
        /** @type {globalThis.Organization.Response.FetchedIndex} */
        const response = await axios().get(URI.index(), config);
        const payloads = response.data.data.map(Organization.Payload.create);
        const models = payloads.map(Organization.Model.fromPayload);
        const result = Result.ok(models);
        return result;
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e)
            : ErrorResult.from(e);
    }
};

/**
 * Get an organization by its identifier.
 * @type {globalThis.Organization.API.FetchResource}
 */
export const fetchOrganizationById = async (request, config) => {
    try {
        /** @type {globalThis.Organization.Response.FetchedResource} */
        const response = await axios().get(URI.show(request.id), config);
        const payload = new Organization.Payload(response.data.organization);
        const model = payload.toModel();
        const result = Result.ok(model);
        return result;
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e, request)
            : ErrorResult.from(e);
    }
};

/**
 * Create a single organization.
 * @type {globalThis.Organization.API.CreateResource}
 */
export const createOrganization = async (request, config) => {
    try {
        /** @type {globalThis.Organization.Response.CreatedResource} */
        const response = await axios().post(URI.create(), request, config);
        const payload = new Organization.Payload(response.data.organization);
        const model = payload.toModel();
        const result = Result.ok(model);
        return result;
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e, request)
            : ErrorResult.from(e);
    }
};

/**
 * Update a single organization by id.
 * @type {globalThis.Organization.API.UpdateResource}
 */
export const updateOrganizationById = async (request, config) => {
    try {
        /** @type {globalThis.Organization.Response.UpdatedResource} */
        const response = await axios().patch(
            URI.update(request.id),
            request,
            config
        );
        const payload = new Organization.Payload(response.data.organization);
        const model = payload.toModel();
        const result = Result.ok(model);
        return result;
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e, request)
            : ErrorResult.from(e);
    }
};

/**
 * Delete an organization by id.
 * @type {globalThis.Organization.API.DeleteResource}
 */
export const deleteOrganizationById = async (request, config) => {
    try {
        /** @type {globalThis.Organization.Response.DeletedResource} */
        const response = await axios().delete(URI.delete(request.id), config);
        const result = Result.ok(response.status === 204);
        return result;
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e, request)
            : ErrorResult.from(e);
    }
};

/**
 * Get an organization by its identifier.
 * @type {globalThis.Organization.API.FetchAccounts}
 */
export const fetchOrganizationAccounts = async (request, config) => {
    try {
        /** @type {globalThis.Organization.Response.FetchedAccounts} */
        const response = await axios().get(URI.accounts(request.id), config);
        const payloads = response.data?.accounts.map(
            (item) => new AccountPayload(item)
        );
        const models = payloads.map((payload) => new Account(payload));
        const result = Result.ok(models);
        return result;
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e, request)
            : ErrorResult.from(e);
    }
};

/**
 * Get an organization by its identifier.
 * @type {globalThis.Organization.API.FetchUsers}
 */
export const fetchOrganizationUsers = async (request, config) => {
    try {
        /** @type {globalThis.Organization.Response.FetchedUsers} */
        const response = await axios().get(URI.users(request.id), config);
        const payloads = response.data.users.map(
            (item) => new UserPayload(item)
        );
        const models = payloads.map((payload) => new User(payload));
        const result = Result.ok(models);
        return result;
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e, request)
            : ErrorResult.from(e);
    }
};

/**
 * Get an organization by its identifier.
 * @type {globalThis.Organization.API.FetchSubscriptions}
 */
export const fetchOrganizationSubscriptions = async (request, config) => {
    try {
        /** @type {globalThis.Organization.Response.FetchedSubscriptions} */
        const response = await axios().get(
            URI.subscriptions(request.id),
            config
        );
        const payloads = response.data.subscriptions.map((subscription) => {
            // Hydrate.
            subscription.organization = response.data.organization;
            return Subscription.Payload.create(subscription);
        });
        const models = payloads.map(Subscription.Model.fromPayload);
        const result = Result.ok(models);
        return result;
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e, request)
            : ErrorResult.from(e);
    }
};

/** @type {Readonly<globalThis.Organization.API>} */
export default {
    fetchOrganizations,
    fetchOrganizationById,
    createOrganization,
    updateOrganizationById,
    deleteOrganizationById,
    fetchOrganizationAccounts,
    fetchOrganizationUsers,
    fetchOrganizationSubscriptions,
};
