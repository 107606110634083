// <!-- API -->
import { isNode } from '@/utils/typeof';

/**
 * Type guard. Allows type inference for the tested variable.
 * @param {any} value The value to test.
 * @returns {value is HTMLElement} `true` if input argument is of type {@link HTMLElement}.
 */
export const isHTMLElement = (value) => {
    if (typeof HTMLElement === 'object') {
        // Browser defines HTMLElement as an object...
        return value instanceof HTMLElement;
    } else {
        // Browser defines HTMLElement as a function.
        // duck-typing...
        return isNode(value) && value.nodeType === 1;
    }
};

// <!-- DEFAULT -->
export default isHTMLElement;
