// <!-- UTILITIES -->
import {
    parse as parseDuration,
    serialize as serializeDuration,
} from 'tinyduration';
import { add, differenceInSeconds, formatDuration, isValid } from 'date-fns';

// <!-- PATTERNS -->
const DURATION_REGEX =
    /P(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)W)?(?:(\d+)D)?(?:T(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?)?$/;

// <!-- UTILITY -->
export class DurationISO {
    /**
     * Format a duration object into an ISO-8601 duration string.
     *
     * @param {Duration | Record<string,never>} duration
     * @returns {string}
     */
    static format(duration = {}) {
        return serializeDuration(duration);
    }

    /**
     * Parse an ISO-8601 duration string into a duration object.
     * @param {string} iso
     * @returns {Duration}
     */
    static parse(iso = 'PT0S') {
        try {
            return parseDuration(iso, { allowMultipleFractions: false });
        } catch (e) {
            return {};
        }
    }

    /**
     * Coerce a duration or string into a duration object.
     *
     * @param {Duration | string} durationOrString
     * @returns {Duration}
     */
    static coerce(durationOrString) {
        return typeof durationOrString === 'string'
            ? DurationISO.parse(durationOrString)
            : durationOrString;
    }

    /**
     * Create a unique duration label.
     *
     * @param {Duration | string} durationOrString
     * @param {{ format?: string[]; zero?: boolean; delimiter?: string; locale?: Locale; }} [options]
     */
    static label(durationOrString, options = { zero: false }) {
        // Normalize to a duration object.
        const duration = DurationISO.coerce(durationOrString);

        // Format the label.
        return formatDuration(duration, options);
    }

    /**
     * Get the number of seconds in the duration.
     *
     * @param {Duration | string} durationOrString
     */
    static magnitude(durationOrString) {
        // Normalize to a duration object.
        const duration = DurationISO.coerce(durationOrString);

        // Get base date.
        const start = new Date(0);

        // Add duration to the date.
        const end = add(start, duration);

        // Get the total magnitude in seconds.
        return isValid(start) && isValid(end)
            ? differenceInSeconds(end, start)
            : 0;
    }

    /**
     * Map an iterable of DurationLike items to an options array.
     * @param {Iterable<Duration | string>} items
     * @param {{ format?: string[]; zero?: boolean; delimiter?: string; locale?: Locale; }} [config]
     * @returns {Array<{ label: string, value: string, magnitude: number }>}
     */
    static toOptions = (items, config = { zero: false }) => {
        // Prepare the options array.
        const options = [];

        // Iterate through the items.
        for (const item of items) {
            const label = DurationISO.label(item, config);
            const value = DurationISO.format(DurationISO.coerce(item));
            const magnitude = DurationISO.magnitude(item);
            options.push({ label, value, magnitude });
        }

        // Return the options.
        return options;
    };
}

// <!-- DEFAULT EXPORT -->
export default DurationISO;
