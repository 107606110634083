// <!-- API -->
import { DateRangeFilter } from '@/utils/filters';

// <!-- UTILITIES -->
import { DateTimeISO, DateTimeLocal } from '@/utils/datetime';

// <!-- TYPES -->
import AnalysisState from '@/store/types/analysis/state';
/** @typedef {import('@/utils/filters').ILimitFilterRecord} ILimitFilterRecord */
/** @typedef {import('@/utils/filters').IScaleFilterRecord} IScaleFilterRecord */
/** @typedef {import('@/utils/filters').ILocationFilter} ILocationFilter */
/** @typedef {import('@/utils/filters').IWeatherStationFilter} IWeatherStationFilter */
/** @typedef {import('@/utils/filters').ISidebarFilterRecord} ISidebarFilterRecord */

/**
 * @class
 * Getters for the analysis state.
 */
export class AnalysisStateGetters {
    /**
     * Access getters for the {@link Filters.DateRange.StoreDataModel}.
     */
    static get daterange() {
        return {
            /**
             * Get the date range from the store.
             * @param {AnalysisState} state
             * @param {{ StartDate: Date, EndDate: Date }} getters
             */
            DateRange: (state, getters) => {
                const start = getters['StartDate'];
                const end = getters['EndDate'];
                return {
                    /** @returns {[ start: Date, end: Date ]} */
                    asDates: () => [start, end],
                    /** @returns {[ start: String, end: String ]} */
                    asISOStrings: () => [
                        DateTimeISO.format(start),
                        DateTimeISO.format(end),
                    ],
                    /** @returns {[ start: String, end: String ]} */
                    asLocalStrings: () => [
                        DateTimeLocal.format(start),
                        DateTimeLocal.format(end),
                    ],
                };
            },
            /**
             * Get the start date string from the store.
             * @param {AnalysisState} state
             * @returns {string}
             */
            StartDate: (state) => {
                const { start } = state.filters.daterange;
                return start;
            },
            /**
             * Get the end date string from the store.
             * @param {AnalysisState} state
             * @returns {string}
             */
            EndDate: (state) => {
                const { end } = state.filters.daterange;
                return end;
            },
            /**
             * Determine if the modifier is checked.
             * @param {AnalysisState} state
             * @param {{ CheckedModifiers: Filters.DateRange.ModifierList }} getters
             */
            DateRangeModifier: (state, getters) => {
                return {
                    all: {
                        /**
                         * Determine if the date range modifier is enabled.
                         * @returns {Boolean}
                         */
                        isEnabled: () => {
                            const $checked = new Set(
                                getters['CheckedModifiers']
                            );
                            return $checked.has('all');
                        },
                        /**
                         * Determine if the date range modifier is disabled.
                         * @returns {Boolean}
                         */
                        isDisabled: () => {
                            const $checked = new Set(
                                getters['CheckedModifiers']
                            );
                            return !$checked.has('all');
                        },
                    },
                    overlap: {
                        /**
                         * Determine if the date range modifier is enabled.
                         * @returns {Boolean}
                         */
                        isEnabled: () => {
                            const $checked = new Set(
                                getters['CheckedModifiers']
                            );
                            return $checked.has('overlap');
                        },
                        /**
                         * Determine if the date range modifier is disabled.
                         * @returns {Boolean}
                         */
                        isDisabled: () => {
                            const $checked = new Set(
                                getters['CheckedModifiers']
                            );
                            return !$checked.has('overlap');
                        },
                    },
                };
            },
            /**
             * Get the set of checked modifiers.
             * @param {AnalysisState} state
             * @returns {Filters.DateRange.ModifierList}
             */
            CheckedModifiers: (state) => {
                const modifiers = new Set(state.filters.daterange.checked);
                return modifiers;
            },
        };
    }
    /**
     * Access getters for the {@link ITimezoneFilter}.
     */
    static get timezone() {
        return {
            /**
             * Get the timezone from the store.
             * @param {AnalysisState} state
             */
            Timezone: (state) => {
                const { timezone } = state.filters.timezone;
                return {
                    /** @returns {TimeZone.Identifier} */
                    asIdentifier: () => timezone,
                    /** @returns {string} */
                    asLocationName: () => {
                        const label = timezone.replaceAll('_', ' ');
                        return label;
                    },
                    /** @returns {string} */
                    asFormalName: () => {
                        const formatter = new Intl.DateTimeFormat('en-CA', {
                            timeZone: timezone,
                            timeZoneName: 'long',
                        });
                        const parts = formatter.formatToParts(new Date());
                        const name = parts.find(
                            (part) => part.type === 'timeZoneName'
                        );
                        return name?.value;
                    },
                    /** @returns {string} */
                    asAbbreviation: () => {
                        const formatter = new Intl.DateTimeFormat('en-CA', {
                            timeZone: timezone,
                            timeZoneName: 'short',
                        });
                        const parts = formatter.formatToParts(new Date());
                        const abbreviation = parts.find(
                            (part) => part.type === 'timeZoneName'
                        );
                        return abbreviation?.value;
                    },
                };
            },
            /**
             * Determine if the modifier is checked.
             * @param {AnalysisState} state
             */
            TimezoneModifier: (state) => {
                return {
                    inherit: {
                        /**
                         * Determine if the date range modifier is enabled.
                         * @returns {Boolean}
                         */
                        isEnabled: () => {
                            return (
                                state.filters.timezone.useAccountTimezone ===
                                true
                            );
                        },
                        /**
                         * Determine if the date range modifier is disabled.
                         * @returns {Boolean}
                         */
                        isDisabled: () => {
                            return (
                                state.filters.timezone.useAccountTimezone ===
                                false
                            );
                        },
                    },
                };
            },
        };
    }
    /**
     * Access getters for the {@link ILimitFilterRecord}.
     */
    static get limits() {
        return {
            /**
             * Get the limits record properties.
             * @param {AnalysisState} state
             */
            Limits: (state) => {
                return {
                    /**
                     * Get the current limit filter record.
                     */
                    get record() {
                        return state.filters.limits;
                    },
                    /**
                     * Get the current temperature limit filter values.
                     */
                    get temp() {
                        return state.filters.limits.temp;
                    },
                    /**
                     * Get the current relative humidity limit filter values.
                     */
                    get rh() {
                        return state.filters.limits.rh;
                    },
                    /**
                     * Get the current dew point limit filter values.
                     */
                    get dp() {
                        return state.filters.limits.dp;
                    },
                };
            },
        };
    }
    /**
     * Access getters for the {@link IScaleFilterRecord}.
     */
    static get scales() {
        return {
            /**
             * Get the scales record properties.
             * @param {AnalysisState} state
             */
            Scales: (state) => {
                return {
                    /**
                     * Get the current scale filter record.
                     */
                    get record() {
                        return state.filters.scales;
                    },
                    /**
                     * Get the current temperature scale filter values.
                     */
                    get temp() {
                        return state.filters.scales.temp;
                    },
                    /**
                     * Get the current relative humidity scale filter values.
                     */
                    get rh() {
                        return state.filters.scales.rh;
                    },
                    /**
                     * Get the current dew point scale filter values.
                     */
                    get dp() {
                        return state.filters.scales.dp;
                    },
                };
            },
        };
    }
    /**
     * Access getters for the {@link ILocationFilter}.
     */
    static get locations() {
        return undefined;
    }
    /**
     * Access getters for the {@link IWeatherStationFilter}.
     */
    static get stations() {
        return undefined;
    }
}

// <!-- DEFAULT -->
export default AnalysisStateGetters;
