// <!-- EXPORTS -->
export { registerAxios } from '@/plugins/axios';
export { registerRouter } from '@/plugins/router';
export { registerStore } from '@/plugins/store';
export { registerVueSelect } from '@/plugins/vue-select';
export { registerVueQuery } from '@/plugins/vue-query';
export { registerFormkit } from '@/plugins/formkit';
export { registerDebugConsole } from '@/plugins/console';
export { registerTimeZones } from '@/plugins/timezones';

// <!-- DEFAULT -->
import { registerAxios } from '@/plugins/axios';
import { registerRouter } from '@/plugins/router';
import { registerStore } from '@/plugins/store';
import { registerVueSelect } from '@/plugins/vue-select';
import { registerVueQuery } from '@/plugins/vue-query';
import { registerFormkit } from '@/plugins/formkit';
import { registerDebugConsole } from '@/plugins/console';
import { registerTimeZones } from '@/plugins/timezones';
export default {
    registerAxios,
    registerRouter,
    registerStore,
    registerVueSelect,
    registerVueQuery,
    registerFormkit,
    registerDebugConsole,
    registerTimeZones,
};
