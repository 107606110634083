<!-- Generic default base button component. -->
<!-- 
    WARNING: You probably want to use the VariantButton
    component instead of this one!

    If you want to change the default style for ALL
    buttons, please change the `buttonClasses` computed
    component.

    If you understand what you're doing, read on.

    NOTE: BaseButton takes advantage of Vue's fallback
    attribute inheritance. All HTML tag attributes on the
    component are forwarded to the native <button> component
    at the root of this one, allowing you to substitute
    this 1:1 with all functionality of the native input
    in addition to gaining the default styles.

    It is important that the `@click` event is NOT
    explicitly defined in the `emits:` option of the
    component definition, so that all events from the
    native <button> input continue to propogate, bubble
    as intended. Declaring the `emits:` option can
    cause Vue to swallow up these events and act in
    a way that differs from the browser standard.

    Use <BaseButton @click="onClick"></BaseButton>.
    The resulting event listener will be bound to the
    root of this template by default. However, we use
    the `v-bind="$attrs"` to ensure that our 
    <button></button> component receives the event
    handler as a fallback.

    See: https://vuejs.org/guide/components/attrs.html
-->
<template>
    <button
        :name="name"
        :type="type"
        :disabled="disabled"
        :value="buttonValue"
        :class="buttonClasses"
        v-bind="$attrs"
    >
        <!-- props.label is used as fallback content -->
        <slot>{{ label ?? '' }}</slot>
    </button>
</template>

<script>
    // <!-- API -->
    import { defineComponent, computed } from 'vue';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'BaseButton',
        props: {
            /** Name assigned to the button element. Same as native type attribute. */
            name: {
                type: String,
                default: 'generic',
            },
            /** Type assigned to the button element. Same as native type attribute. */
            type: {
                type: String,
                default: 'button',
                /** @param {String} value */
                validator(value) {
                    return ['button', 'submit', 'reset'].includes(value);
                },
            },
            /** Value assigned to the button element, if different from label. */
            value: {
                default: null,
                type: [String, Number],
            },
            /** Flag determining if the button is disabled. */
            disabled: Boolean,
            /** Label assigned to the button display. Used as fallback content. */
            label: String,
            /** Custom theme (font, background, border, etc.), if any provided. */
            theme: Array,
            /** Custom size (margins, padding, height, width, etc.), if any provided. */
            size: Array,
            /** Additional variant classes, if any provided. */
            modifiers: Array,
            /** Overriding CSS classes, if any provided. */
            overrides: Array,
        },
        setup(props) {
            /**
             * Button classes are computed based on:
             * - specified theme (default: primary)
             * - specified size (default: lg)
             * - specified modifiers (default: rounded-md, shadow-sm)
             * - Additional classes are handled by Vue, through fallback inheritance.
             */
            const buttonClasses = computed(() => {
                return [
                    ...(props.theme ?? []),
                    ...(props.size ?? []),
                    ...(props.modifiers ?? []),
                    ...(props.overrides ?? []),
                ].filter((x) => x !== undefined);
            });

            /**
             * Button value has preference order of:
             * - props.value
             * - props.label
             * - empty string (by default)
             */
            const buttonValue = computed(() => {
                // If present, use value;
                // or, if present, use label;
                // otherwise, use an empty string.
                return props.value ?? props.label ?? '';
            });

            // Expose properties to Options API.
            return {
                buttonClasses,
                buttonValue,
            };
        },
    });
</script>
