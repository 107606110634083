import rootClasses from '~/formkitRootClasses';

/** <!-- TYPES --> */
import { FormKitConfig } from '@formkit/core';

export default {
    /** @type {Partial<FormKitConfig>} */
    config: {
        rootClasses,
    },
};
