// <!-- UTILITIES -->
import { Emoji } from '@/utils/emoji';
import { Collection } from 'collect.js';
import { isNumber, isString } from '@/utils/typeof'; // Uses `just-compare` and `just-typeof`.
import compare from 'just-compare';
import isNil from 'lodash-es/isNil';
import isNaN from 'lodash-es/isNaN';
import isNull from 'lodash-es/isNull';
import isUndefined from 'lodash-es/isUndefined';
import negate from 'lodash-es/negate';
import gt from 'lodash-es/gt';
import gte from 'lodash-es/gte';
import lt from 'lodash-es/lt';
import lte from 'lodash-es/lte';
import eq from 'lodash-es/eq';

/**
 * @class
 * General immutable assertion class that evaluates a predicate
 * and resolves with either <void> or a thrown Error.
 */
export class Assertion {
    /**
     * Assertion stores a supplier and a message.
     * @param {Partial<Assertion>} config
     */
    constructor(config = {}) {
        /**
         * Label to give to this asssertion.
         * @type {String}
         */
        this.label = 'Assertion';
        /**
         * Supplier to be assessed or lazily evaluated.
         * @type {(any) | (() => any) | (() => Promise<any>)}
         */
        this.supplier = () => {
            throw new Error(`No value or supplier provided.`);
        };
        // Seal properties, write parameters, and then freeze the object.
        Object.seal(this);
        Object.assign(this, config);
        Object.freeze(this);
    }

    /**
     * Evaluate the supplier function and return the resolved value.
     * @param {(any) | (() => any) | (() => Promise<any>)} supplier Asynchronous or synchronous supplier.
     * @returns {Promise<any>}
     */
    static async evaluate(supplier) {
        try {
            // Evaluate the predicate.
            return typeof supplier === 'function' ? await supplier() : supplier;
        } catch (e) {
            // Evaluation has critically failed.
            throw new Error(e);
        }
    }

    /**
     * Evaluate an assertion supplier function or direct value.
     * @param {(any) | (() => any) | (() => Promise<any>)} supplier
     * @param {(result?: any) => Boolean} shouldThrow Should throw if result of parsing supplier (or direct value) results in `true`.
     * @param {Object} [config]
     * @param {String} config.label
     * @param {String} config.success
     * @param {String} config.failure
     */
    static assert(
        supplier,
        shouldThrow = (result) => !result,
        { label, success, failure }
    ) {
        return new Assertion({
            label,
            supplier,
        }).assert(shouldThrow, { success, failure });
    }

    /**
     * Prepare Assertion of a supplier (or direct value) for evaluation.
     * @param {(any) | (() => any) | (() => Promise<any>)} supplier
     * @param {String} [label]
     * @returns {Assertion}
     */
    static expect(supplier, label = 'Expectation') {
        return new Assertion({
            label,
            supplier,
        });
    }

    /**
     * Assert result of evaluated supplier function (or value), with custom assertion logic.
     * @param {(result?: any) => Boolean} shouldThrow Should throw?
     * @param {Object} [config] Configuration for the assertion.
     * @param {String} [config.success] Success message when assertion passes.
     * @param {String} [config.failure] Failure message when assertion fails.
     */
    async assert(shouldThrow, config = {}) {
        const success = config.success ?? 'Assertion has passed.';
        const failure = config.failure ?? 'Assertion has failed.';
        try {
            // Evaluate the predicate test.
            const result = await Assertion.evaluate(this.supplier);
            if (shouldThrow(result)) {
                throw new Error(failure);
            } else {
                Assertion.displaySuccessMessage(this.label, success);
                return result;
            }
        } catch (e) {
            // Get the error.
            const reason =
                typeof e === 'string'
                    ? e
                    : e instanceof Error
                    ? e.message
                    : failure;

            // Provide the appropriate error log.
            Assertion.displayErrorMessage(this.label, reason);
            // Throw when error is received.
            throw e;
        }
    }

    /**
     * Assert evaluated predicate is truthy.
     * @param {Object} [config]
     * @param {String} [config.success]
     * @param {String} [config.failure]
     */
    async isTruthy(config = {}) {
        const success = config.success ?? 'Assertion is truthy.';
        const failure = config.failure ?? 'Assertion is not truthy.';
        /**
         * @type {(result?: any) => Boolean} When shouldThrow is true, fail the assertion.
         */
        const shouldThrow = (result) => !result;
        return await this.assert(shouldThrow, { success, failure });
    }

    /**
     * Assert evaluated predicate is falsy.
     * @param {Object} [config]
     * @param {String} [config.success]
     * @param {String} [config.failure]
     */
    async isFalsy(config = {}) {
        const success = config.success ?? 'Assertion is truthy.';
        const failure = config.failure ?? 'Assertion is not truthy.';
        /**
         * @type {(result?: any) => Boolean} When shouldThrow is true, fail the assertion.
         */
        const shouldThrow = (result) => result;
        return await this.assert(shouldThrow, { success, failure });
    }

    /**
     * Assert evaluated predicate is null.
     * @param {Object} [config]
     * @param {String} [config.success]
     * @param {String} [config.failure]
     */
    async isNull(config = {}) {
        const success = config.success ?? 'Assertion is null.';
        const failure = config.failure ?? 'Assertion is non-null.';
        /**
         * @type {(result?: any) => Boolean} When shouldThrow is true, fail the assertion.
         */
        const shouldThrow = negate(isNull); // (!isNull)
        return await this.assert(shouldThrow, { success, failure });
    }

    /**
     * Assert evaluated predicate is non-null.
     * @param {Object} [config]
     * @param {String} [config.success]
     * @param {String} [config.failure]
     */
    async isNonNull(config = {}) {
        const success = config.success ?? 'Assertion is non-null.';
        const failure = config.failure ?? 'Assertion is null.';
        /**
         * @type {(result?: any) => Boolean} When shouldThrow is true, fail the assertion.
         */
        const shouldThrow = isNull;
        return await this.assert(shouldThrow, { success, failure });
    }

    /**
     * Assert evaluated predicate is undefined.
     * @param {Object} [config]
     * @param {String} [config.success]
     * @param {String} [config.failure]
     */
    async isUndefined(config = {}) {
        const success = config.success ?? 'Assertion is undefined.';
        const failure = config.failure ?? 'Assertion is not undefined.';
        /**
         * @type {(result?: any) => Boolean} When shouldThrow is true, fail the assertion.
         */
        const shouldThrow = negate(isUndefined);
        return await this.assert(shouldThrow, { success, failure });
    }

    /**
     * Assert evaluated predicate is undefined.
     * @param {Object} [config]
     * @param {String} [config.success]
     * @param {String} [config.failure]
     */
    async isDefined(config = {}) {
        const success = config.success ?? 'Assertion is defined.';
        const failure = config.failure ?? 'Assertion is not defined.';
        /**
         * @type {(result?: any) => Boolean} When shouldThrow is true, fail the assertion.
         */
        const shouldThrow = isUndefined;
        return await this.assert(shouldThrow, { success, failure });
    }

    /**
     * Assert evaluated predicate is undefined or null.
     * @param {Object} [config]
     * @param {String} [config.success]
     * @param {String} [config.failure]
     */
    async isNil(config = {}) {
        const success = config.success ?? 'Assertion is undefined or null.';
        const failure =
            config.failure ?? 'Assertion is not undefined nor null.';
        /**
         * @type {(result?: any) => Boolean} When shouldThrow is true, fail the assertion.
         */
        const shouldThrow = negate(isNil);
        return await this.assert(shouldThrow, { success, failure });
    }

    /**
     * Assert evaluated predicate is undefined.
     * @param {Object} [config]
     * @param {String} [config.success]
     * @param {String} [config.failure]
     */
    async isNotNil(config = {}) {
        const success =
            config.success ?? 'Assertion is not undefined nor null.';
        const failure = config.failure ?? 'Assertion is undefined or null.';
        /**
         * @type {(result?: any) => Boolean} When shouldThrow is true, fail the assertion.
         */
        const shouldThrow = isNil;
        return await this.assert(shouldThrow, { success, failure });
    }

    /**
     * Assert evaluated predicate is specifically NaN.
     * @param {Object} [config]
     * @param {String} [config.success]
     * @param {String} [config.failure]
     */
    async isNaN(config = {}) {
        const success = config.success ?? 'Assertion is NaN.';
        const failure = config.failure ?? 'Assertion is not NaN.';
        /**
         * @type {(result?: any) => Boolean} When shouldThrow is true, fail the assertion.
         */
        const shouldThrow = negate((result) => isNaN(result));
        return await this.assert(shouldThrow, { success, failure });
    }

    /**
     * Assert evaluated predicate is a valid Number.
     * @param {Object} [config]
     * @param {String} [config.success]
     * @param {String} [config.failure]
     */
    async isNumber(config = {}) {
        const success = config.success ?? 'Assertion is a valid number.';
        const failure = config.failure ?? 'Assertion is not a valid number.';
        /**
         * @type {(result?: any) => Boolean} When shouldThrow is true, fail the assertion.
         */
        const shouldThrow = (result) => isNaN(result) || !isNumber(result);
        return await this.assert(shouldThrow, { success, failure });
    }

    /**
     * Assert evaluated predicate is a String.
     * @param {Object} [config]
     * @param {String} [config.success]
     * @param {String} [config.failure]
     */
    async isString(config = {}) {
        const success = config.success ?? 'Assertion is a String.';
        const failure = config.failure ?? 'Assertion is not a String.';
        /**
         * @type {(result?: any) => Boolean} When shouldThrow is true, fail the assertion.
         */
        const shouldThrow = negate(isString);
        return await this.assert(shouldThrow, { success, failure });
    }

    /**
     * Assert evaluated predicate is undefined.
     * @param {Object} [config]
     * @param {String} [config.success]
     * @param {String} [config.failure]
     */
    async isEmpty(config = {}) {
        const success = config.success ?? 'Assertion is empty.';
        const failure =
            config.failure ?? 'Assertion is not empty or undefined.';
        /**
         * @type {(result?: Partial<{ length: Number | (() => Number), size: Number | (() => Number), count: Number | (() => Number), isEmpty: Boolean | (() => Boolean), isNotEmpty: Boolean | (() => Boolean)}>) => Boolean} Throw when result is an empty array, empty string, empty collection, or null.
         */
        const shouldThrow = (result) => {
            if (result === undefined) {
                // Fail if the result itself is falsy.
                return true;
            }

            // Collection / isEmpty() / isEmpty
            if (result instanceof Collection || result.isEmpty !== undefined) {
                return typeof result.isEmpty === 'function'
                    ? !result.isEmpty()
                    : !result.isEmpty;
            }

            // Collection / isNotEmpty() / isNotEmpty
            if (
                result instanceof Collection ||
                result.isNotEmpty !== undefined
            ) {
                return typeof result.isNotEmpty === 'function'
                    ? result.isNotEmpty()
                    : result.isNotEmpty;
            }

            // Array or String
            if (result.length !== undefined) {
                return typeof result.length === 'function'
                    ? result.length() > 0
                    : result.length > 0;
            }

            // Map or Set
            if (result.size !== undefined) {
                return typeof result.size === 'function'
                    ? result.size() > 0
                    : result.size > 0;
            }

            // Collection / count()
            if (result.count !== undefined) {
                return typeof result.count === 'function'
                    ? result.count() > 0
                    : result.count > 0;
            }

            // Not a collection type.
            throw new Error(
                `Result is not an Array, String, Map, Set, or Collection.`
            );
        };
        return await this.assert(shouldThrow, { success, failure });
    }

    /**
     * Assert evaluated predicate is undefined.
     * @param {Object} [config]
     * @param {String} [config.success]
     * @param {String} [config.failure]
     */
    async isNotEmpty(config = {}) {
        const success = config.success ?? 'Assertion is not empty.';
        const failure = config.failure ?? 'Assertion is empty or undefined.';
        /**
         * @type {(result?: Partial<{ length: Number | (() => Number), size: Number | (() => Number), count: Number | (() => Number), isEmpty: Boolean | (() => Boolean), isNotEmpty: Boolean | (() => Boolean)}>) => Boolean} Throw when result is an empty array, empty string, empty collection, or null.
         */
        const shouldThrow = (result) => {
            if (result === undefined) {
                // Fail if the result itself is falsy.
                return true;
            }

            // Collection / isEmpty() / isEmpty
            if (result instanceof Collection || result.isEmpty !== undefined) {
                return typeof result.isEmpty === 'function'
                    ? result.isEmpty()
                    : result.isEmpty;
            }

            // Collection / isNotEmpty() / isNotEmpty
            if (
                result instanceof Collection ||
                result.isNotEmpty !== undefined
            ) {
                return typeof result.isNotEmpty === 'function'
                    ? !result.isNotEmpty()
                    : !result.isNotEmpty;
            }

            // Array or String
            if (result.length !== undefined) {
                return typeof result.length === 'function'
                    ? result.length() === 0
                    : result.length === 0;
            }

            // Map or Set
            if (result.size !== undefined) {
                return typeof result.size === 'function'
                    ? result.size() === 0
                    : result.size === 0;
            }

            // Collection / count()
            if (result.count !== undefined) {
                return typeof result.count === 'function'
                    ? result.count() === 0
                    : result.count === 0;
            }

            // Not a collection type.
            throw new Error(
                `Result is not an Array, String, Map, Set, or Collection.`
            );
        };
        return await this.assert(shouldThrow, { success, failure });
    }

    /**
     * Assert evaluated predicate result contains the specified value.
     * @param {any} value
     * @param {Object} [config]
     * @param {String} [config.success]
     * @param {String} [config.failure]
     */
    async includes(value, config = {}) {
        const success =
            config.success ??
            'Assertion value is included within resulting collection.';
        const failure =
            config.failure ??
            'Assertion value is not included within resulting collection.';
        /**
         * @type {(collection?: Partial<{ includes: Function, has: Function, contains: Function }>) => Boolean}
         */
        const shouldThrow = (result) => {
            if (!result) {
                // Fail if the result itself is falsy.
                return true;
            }

            if (
                result instanceof Map ||
                result instanceof Set ||
                typeof result.has === 'function'
            ) {
                return !result.has(value);
            }

            if (
                result instanceof String ||
                result instanceof Array ||
                typeof result.includes === 'function'
            ) {
                return !result.includes(value, 0);
            }

            if (
                result instanceof Collection ||
                typeof result.contains === 'function'
            ) {
                return !result.contains(value);
            }

            // Not a collection type.
            throw new Error(
                `Result is not an Array, String, Map, Set, or Collection.`
            );
        };
        return await this.assert(shouldThrow, { success, failure });
    }

    /**
     * Assert evaluated predicate result does not contain the specified value.
     * @param {any} value
     * @param {Object} [config]
     * @param {String} [config.success]
     * @param {String} [config.failure]
     */
    async excludes(value, config = {}) {
        const success =
            config.success ??
            'Assertion value is not included within resulting collection.';
        const failure =
            config.failure ??
            'Assertion value is included within resulting collection.';
        /**
         * @type {(collection?: Partial<{ includes: Function, has: Function, contains: Function }>) => Boolean}
         */
        const shouldThrow = (result) => {
            if (!result) {
                // Fail if the result itself is falsy.
                return true;
            }

            if (
                result instanceof Map ||
                result instanceof Set ||
                typeof result.has === 'function'
            ) {
                return result.has(value);
            }

            if (
                result instanceof String ||
                result instanceof Array ||
                typeof result.includes === 'function'
            ) {
                return result.includes(value, 0);
            }

            if (
                result instanceof Collection ||
                typeof result.contains === 'function'
            ) {
                return result.contains(value);
            }

            // Not a collection type.
            throw new Error(
                `Result is not an Array, String, Map, Set, or Collection.`
            );
        };
        return await this.assert(shouldThrow, { success, failure });
    }

    /**
     * Assert evaluated predicate result is strictly equivalent.
     * @param {any} value
     * @param {Object} [config]
     * @param {String} [config.success]
     * @param {String} [config.failure]
     */
    async isEqual(value, config = {}) {
        const success = config.success ?? 'Assertion value is equivalent.';
        const failure = config.failure ?? 'Assertion value is not equivalent.';
        /**
         * @type {(result?: any) => Boolean}
         */
        const shouldThrow = negate((result) => {
            return compare(result, value) || eq(result, value);
        });
        return await this.assert(shouldThrow, { success, failure });
    }

    /**
     * Assert evaluated predicate result is not strictly equivalent.
     * @param {any} value
     * @param {Object} [config]
     * @param {String} [config.success]
     * @param {String} [config.failure]
     */
    async isNotEqual(value, config = {}) {
        const success = config.success ?? 'Assertion value is not equivalent.';
        const failure = config.failure ?? 'Assertion value is equivalent.';
        /**
         * @type {(result?: any) => Boolean}
         */
        const shouldThrow = (result) => {
            return compare(result, value) || eq(result, value);
        };
        return await this.assert(shouldThrow, { success, failure });
    }

    /**
     * Assert evaluated predicate result is the same reference.
     * @param {any} value
     * @param {Object} [config]
     * @param {String} [config.success]
     * @param {String} [config.failure]
     */
    async isSame(value, config = {}) {
        const success =
            config.success ?? 'Assertion value is not the same reference.';
        const failure =
            config.failure ?? 'Assertion value is the same reference.';
        /**
         * @type {(result?: any) => Boolean}
         */
        const shouldThrow = negate((result) => result === value);
        return await this.assert(shouldThrow, { success, failure });
    }

    /**
     * Assert evaluated predicate result is not the same reference.
     * @param {any} value
     * @param {Object} [config]
     * @param {String} [config.success]
     * @param {String} [config.failure]
     */
    async isDifferent(value, config = {}) {
        const success =
            config.success ?? 'Assertion value is not the same reference.';
        const failure =
            config.failure ?? 'Assertion value is the same reference.';
        /**
         * @type {(result?: any) => Boolean}
         */
        const shouldThrow = (result) => result === value;
        return await this.assert(shouldThrow, { success, failure });
    }

    /**
     * Assert evaluated predicate result is greater than the provided value.
     * @param {Number} value
     * @param {Object} [config]
     * @param {String} [config.success]
     * @param {String} [config.failure]
     */
    async isGreaterThan(value, config = {}) {
        const success = `Assertion result is greater than ${value}.`;
        const failure = `Assertion result is less than or equal to ${value}.`;
        /**
         * @type {(result?: any) => Boolean}
         */
        const shouldThrow = negate((result) => gt(result, value));
        return await this.assert(shouldThrow, { success, failure });
    }

    /**
     * Assert evaluated predicate result is greater than or equal to the provided value.
     * @param {Number} value
     * @param {Object} [config]
     * @param {String} [config.success]
     * @param {String} [config.failure]
     */
    async isGreaterThanOrEqual(value, config = {}) {
        const success = `Assertion result is greater than or equal to ${value}.`;
        const failure = `Assertion result is less than ${value}.`;
        /**
         * @type {(result?: any) => Boolean}
         */
        const shouldThrow = negate((result) => gte(result, value));
        return await this.assert(shouldThrow, { success, failure });
    }

    /**
     * Assert evaluated predicate result is less than the provided value.
     * @param {Number} value
     * @param {Object} [config]
     * @param {String} [config.success]
     * @param {String} [config.failure]
     */
    async isLessThan(value, config = {}) {
        const success = `Assertion result is less than ${value}.`;
        const failure = `Assertion result is greater than or equal to ${value}.`;
        /**
         * @type {(result?: any) => Boolean}
         */
        const shouldThrow = negate((result) => lt(result, value));
        return await this.assert(shouldThrow, { success, failure });
    }

    /**
     * Assert evaluated predicate result is less than or equal to the provided value.
     * @param {Number} value
     * @param {Object} [config]
     * @param {String} [config.success]
     * @param {String} [config.failure]
     */
    async isLessThanOrEqual(value, config = {}) {
        const success = `Assertion result is less than or equal to ${value}.`;
        const failure = `Assertion result is greater than ${value}.`;
        /**
         * @type {(result?: any) => Boolean}
         */
        const shouldThrow = negate((result) => lte(result, value));
        return await this.assert(shouldThrow, { success, failure });
    }

    /**
     * Assert evaluated predicate result is a valid index for an indexeable of the specified length.
     * @param {Number} length
     * @param {Object} [config]
     * @param {String} [config.success]
     * @param {String} [config.failure]
     */
    async isValidIndex(length, config = {}) {
        const success = `Index is valid for indexable of length ${length}.`;
        const failure = `Index is invalid for indexable of length ${length}.`;
        /**
         * @type {(result?: any) => Boolean}
         */
        const shouldThrow = negate((index) => index >= 0 && index < length);
        return await this.assert(shouldThrow, { success, failure });
    }

    /**
     * Display formatted message.
     *
     * @param {String} label
     * @param {String} emoji
     * @param {String} status
     * @param {String} message
     * @param {String} color
     */
    static displayMessage(label, emoji, status, message, color) {
        console.log(
            `[assert::${label}]: ${emoji} %c${status} - %c${message}`,
            color,
            ``
        );
    }

    /**
     * Displayed success message when assertion is considered passed.
     * @param {String} label
     * @param {String} message
     */
    static displaySuccessMessage(label, message) {
        const status = 'Success!';
        const color = 'color:seagreen';
        Assertion.displayMessage(
            label,
            Emoji.checkmark,
            status,
            message,
            color
        );
    }

    /**
     * Display error message when assertion is considered failed.
     * @param {String} label
     * @param {String} message
     */
    static displayErrorMessage(label, message) {
        const status = 'Error!';
        const color = 'color:firebrick;';
        Assertion.displayMessage(label, Emoji.cross, status, message, color);
    }
}

// Example assertions.
// Assertion.expect([]).isEmpty();
// Assertion.expect([1, 2, 3]).isNotEmpty();
// Assertion.expect('string').isNonNull();
// Assertion.expect(() => null).isNull();
// Assertion.expect(1).isLessThan(10);
// Assertion.expect(25).isGreaterThanOrEqual(25);
// Assertion.expect([2, 4, 6]).excludes(3);
// Assertion.expect('string').isString();
