import { AttributeFactory } from './AttributeFactory';

/**
 * Payload attribute factory interface.
 *
 * @template {{ [key: string]: any }} [P = never]
 * @template {{ [key: string]: any }} [M = never]
 * @extends {AttributeFactory<'payload',P,M>}
 * @implements {Resource.PayloadAttributeFactory<P,M>}
 */
export class PayloadAttributeFactory extends AttributeFactory {
    // <!-- STATIC FACTORY METHODS -->

    /**
     * Create an attribute factory that returns the supplied initial state.
     *
     * @template {{ [key: string]: any }} [P = never]
     * @template {{ [key: string]: any }} [M = never]
     * @param {(data: Partial<M>) => Partial<P>} mapperFn
     * @param {P} initialValues
     */
    static create(mapperFn, initialValues) {
        return new PayloadAttributeFactory(mapperFn, initialValues);
    }

    // <!-- CONSTRUCTOR -->

    /**
     * Create an attribute factory that returns the supplied initial state.
     *
     * @param {(data: Partial<M>) => Partial<P>} mapperFn
     * @param {P} initialValues
     */
    constructor(mapperFn, initialValues) {
        super(initialValues);
        this.createAttributesFromModelData = mapperFn;
    }

    // <!-- FACTORY INTERFACE -->

    /**
     * Create full payload attributes object using default values and overrides.
     *
     * @param {Partial<P>} payload
     * @returns {P}
     */
    fromPayload(payload) {
        return Object.assign(this.initialState, payload);
    }

    /**
     * Create full payload attributes object using default values and overrides.
     *
     * @param {Partial<M>} model
     * @returns {P}
     */
    fromModel(model) {
        const attributes = this.createAttributesFromModelData(model);
        return this.fromPayload(attributes);
    }
}

// <!-- EXPORTS -->
export default PayloadAttributeFactory;
