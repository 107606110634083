// <!-- DEFINE -->
/** @typedef {Record<String, Readonly<Router.RouteRecordRaw>>} */
const children = {
    alerts: {
        path: 'alerts',
        name: 'Alerts',
        component: () =>
            import(
                /* webpackPrefetch: true */ /* webpackChunkName: "debug" */ '@/features/debug/views/Alerts.vue'
            ),
        meta: { namespace: 'debug' },
    },
    toasts: {
        path: 'toasts',
        name: 'Toasts',
        component: () =>
            import(
                /* webpackPrefetch: true */ /* webpackChunkName: "debug" */ '@/features/debug/views/Toasts.vue'
            ),
        meta: { namespace: 'debug' },
    },
    vueselect: {
        path: 'vueselect',
        name: 'Select',
        component: () =>
            import(
                /* webpackPrefetch: true */ /* webpackChunkName: "debug" */ '@/features/debug/views/Select.vue'
            ),
        meta: { namespace: 'debug' },
    },
};

// <!-- EXPORTS -->
/** @typedef {Record<String, Readonly<Router.RouteRecordRaw>>} */
export const debug = {
    debug: {
        path: '/debug',
        name: 'Debug',
        component: () =>
            import(
                /* webpackPrefetch: true */ /* webpackChunkName: "debug" */ '@/features/debug/views/index.vue'
            ),
        meta: { namespace: 'debug' },
        children: [children.alerts, children.toasts, children.vueselect],
    },
};

// <!-- DEFAULT -->
export default debug;
