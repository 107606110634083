// <!-- UTILITIES -->
import { isNumber } from '@/utils/typeof';

// <!-- TYPES -->
/** @typedef {import('@/utils/formatters').IDecimalFormatter} IDecimalFormatter */

// <!-- EXPORTS -->
/**
 * @type {IDecimalFormatter} Formats decimal according to the specified options. Set options.minimumFractionDigits and options.maximumFractionDigits to affect presence of decimals.
 *
 * @example
 * ```
 * formatDecimal({ value: 12.3, options: { minimumFractionDigits: 1 } }); // 12.3 ==> "12.3"
 * formatDecimal({ value: 12.3, options: { minimumFractionDigits: 2, maximumFractionDigits: 2 } }); // 12.3 ==> "12.30"
 * formatDecimal({ value: 12.3, options: { minimumFractionDigits: 0, maximumFractionDigits: 0 } }); // 12.3 ==> "12"
 * ```
 */
export const formatDecimal = ({ value, options }) => {
    // <!-- DESTRUCTURE -->
    const input = value ?? NaN;

    // <!-- CONDITIONALS -->
    const isValid = isNumber(input) && !Number.isNaN(input);

    // When the input value is a valid number...
    if (isValid) {
        // Get the formatted decimal with specified fractional digit lengths. Defaults to maximum of 2 places.
        const config = options ?? {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
        };
        const formatted = input.toLocaleString('en-US', config);
        // Return the formatted string.
        return formatted;
    }

    // When the input value is NOT a valid number, return an empty string.
    return '';
};

// <!-- DEFAULT -->
export default formatDecimal;
