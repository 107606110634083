// <!-- API -->
import { ECNBStateMutations } from '@/store/types/ECNBStateMutations';
import { UploadDataGetters } from '@/store/types/uploader/getters';
import { UploadRecordMutations } from '@/store/types/uploader/mutations/UploadRecordMutations';

// <!-- UTILITIES -->
import clone from 'just-clone';
import isNil from 'lodash-es/isNil';

// <!-- TYPES -->
import { UploadData, UploadRecord } from '@/store/types/uploader/state';
import { UploadStatusID } from '@/store/types/uploader/state/UploadData';
import { hasOwn } from '@vue/shared';

/**
 * Synchronous mutations for a {@link UploadData} instance.
 */
export class UploadDataMutations extends ECNBStateMutations {
    /**
     * Setter mutations for the collection status and individual record.
     * @param {UploadData} context Collection context.
     */
    static set(context) {
        const $ = UploadDataMutations;
        const $find = UploadDataGetters.find(context);
        const $has = UploadDataGetters.has(context);
        return {
            /**
             * Change the record status.
             * @param {keyof UploadStatusID} id
             */
            status: (id) => {
                /**
                 * Change {@link id} status.
                 * @param {Boolean} value
                 */
                const $change = (value) => {
                    const updated = /** @type {Set<keyof UploadStatusID>} */ (
                        new Set(context.status.keys())
                    );
                    if (value === true) {
                        console.info(
                            `[flag::${id}]: %cENABLED`,
                            `background: seagreen;`
                        );
                        updated.add(id);
                    }
                    if (isNil(value) || value === false) {
                        console.info(
                            `[flag::${id}]: %cDISABLED`,
                            `background: maroon;`
                        );
                        updated.delete(id);
                    }
                    context.status = updated;
                };
                return {
                    /** @param {Boolean} value */
                    to: (value) => $change(value),
                    enable: () => $change(true),
                    disable: () => $change(false),
                };
            },
            /**
             * Wrapper for record item mutations.
             */
            get record() {
                const $set = $.set(context);
                return {
                    /**
                     * Pass record mutations.
                     * @param {UploadRecord} record
                     */
                    set: (record) => UploadRecordMutations.set(record),
                    /**
                     * Insert or drop record corresponding to the provided filename.
                     * @param {String} filename
                     * @param {UploadRecord | false | null} [record]
                     */
                    byFilename: (filename, record = null) => {
                        const updated = new Map(context.records.entries());
                        if (isNil(record) || record === false) {
                            // Drop record at matching filename if provided record is falsy.
                            if (!updated.delete(filename)) {
                                console.warn(
                                    `No matching record exists under filename "${filename}". Nothing to drop.`
                                );
                                return;
                            }
                            context.records = new Map(updated.entries());
                            return;
                        }

                        if (record.filename !== filename) {
                            throw new Error(
                                `Provided record does not have a filename matching the specified filename "${filename}".`
                            );
                        }

                        // Set record to the corresponding filename entry
                        context.records = new Map(
                            updated.set(filename, record)
                        );
                        return;
                    },
                    /**
                     * If record with matching filename exists, replace it.
                     * @param {UploadRecord} record
                     */
                    ifPresent: (record) => {
                        const { filename } = record;
                        const filenameExists = $has.filename(filename);
                        const recordExists =
                            filenameExists &&
                            !isNil($find.byFilename(filename));
                        if (recordExists) {
                            // Set the record, if it is present.
                            $set.record.byFilename(filename, record);
                        }
                    },
                    /**
                     * If record with matching filename exists, do nothing. Otherwise, insert it into the collection.
                     * @param {UploadRecord} record
                     */
                    ifMissing: (record) => {
                        const { filename } = record;
                        const filenameExists = $has.filename(filename);
                        const recordMissing =
                            !filenameExists ||
                            isNil($find.byFilename(filename));
                        if (recordMissing) {
                            // Set the record, if it is missing.
                            $set.record.byFilename(filename, record);
                        }
                    },
                };
            },
        };
    }
    /**
     * Adder mutations.
     * @param {UploadData} context Collection context.
     */
    static add(context) {
        const $ = UploadDataMutations;
        const $find = UploadDataGetters.find(context);
        const $has = UploadDataGetters.has(context);
        return {
            /**
             * Add status.
             * @param {keyof UploadStatusID} key
             */
            status: (key) => $.set(context).status(key).enable(),
            /**
             * Wrapper for record item mutations.
             */
            get record() {
                const $set = $.set(context);
                return {
                    /**
                     * Pass record adders.
                     * @param {UploadRecord} record
                     */
                    add: (record) => UploadRecordMutations.add(record),
                    /**
                     * Insert record corresponding to the provided filename.
                     * @param {String} filename
                     * @param {UploadRecord} record
                     */
                    byFilename: (filename, record) => {
                        if (isNil(record)) {
                            throw new Error(
                                `Cannot add null or undefined record.`
                            );
                        }
                        $set.record.byFilename(filename, record);
                    },
                    /**
                     * If record with matching filename exists, merge it.
                     * @param {UploadRecord} record
                     */
                    ifPresent: (record) => {
                        const { filename } = record;
                        const filenameExists = $has.filename(filename);
                        const recordExists =
                            filenameExists &&
                            !isNil($find.byFilename(filename));
                        if (recordExists) {
                            // Get current match.
                            const current = context.records.get(filename);
                            const merged = Object.assign(
                                {},
                                clone(current),
                                record
                            );
                            // Set the record, if it is present.
                            $set.record.byFilename(filename, merged);
                        }
                    },
                    /**
                     * Insert record if it is missing.
                     * @param {UploadRecord} record
                     */
                    ifMissing: (record) => {
                        if (isNil(record)) {
                            throw new Error(
                                `Cannot add null or undefined record.`
                            );
                        }
                        $set.record.ifMissing(record);
                    },
                };
            },
        };
    }
    /**
     * Dropper mutations.
     * @param {UploadData} context Collection context.
     */
    static drop(context) {
        const $ = UploadDataMutations;
        const $set = $.set(context);
        return {
            /**
             * Drop all state properties.
             */
            state: () => {
                const $drop = $.drop(context);
                $drop.status.all();
                $drop.records.all();
            },
            /**
             * Drop property.
             */
            get status() {
                return {
                    /**
                     * Drop all statuses.
                     */
                    all: () => context.status.clear(),
                    /**
                     * Drop specified status.
                     * @param {keyof UploadStatusID} id
                     */
                    byID: (id) => $set.status(id).disable(),
                };
            },
            /**
             * Wrapper for record collection mutations.
             */
            get records() {
                return {
                    /**
                     * Drop collection.
                     */
                    all: () => {
                        context.records.clear();
                    },
                    /**
                     * Drop items from collection that evaluate predicate to true.
                     * @param {(record?: UploadRecord, index?: Number) => Boolean} [predicate]
                     */
                    where: (predicate) => {
                        const removed = context.all.filter(predicate);

                        for (const record of removed) {
                            /** Delete records from the map. */
                            $set.record.byFilename(record.filename, null);
                        }
                    },
                };
            },
            /**
             * Wrapper for record item mutations.
             */
            get record() {
                return {
                    /**
                     * Drop record by filename.
                     * @param {String} filename
                     */
                    byFilename: (filename) => {
                        $set.record.byFilename(filename, null);
                    },
                    /**
                     * Drop record by filename.
                     * @param {Number} id
                     */
                    byDatasetBatchID: (id) => {
                        const match =
                            UploadDataGetters.find(context).byDatasetBatchID(
                                id
                            );
                        if (!isNil(match)) {
                            $set.record.byFilename(match.filename, null);
                        }
                    },
                };
            },
        };
    }
    /**
     * Access specialized status mutations.
     * @param {UploadData} context
     */
    static change(context) {
        const $ = UploadDataMutations;
        return {
            get status() {
                /**
                 * Get status mutations.
                 * @param {keyof UploadStatusID} key
                 */
                const getMutationsForStatus = (key) => {
                    return {
                        /**
                         * Set the status.
                         * @param {Boolean} value
                         */
                        set: (value) => $.set(context).status(key).to(value),
                        /**
                         * Activate status.
                         */
                        enable: () => $.add(context).status(key),
                        /**
                         * Deactivate status.
                         */
                        disable: () => $.drop(context).status.byID(key),
                    };
                };
                return {
                    /**
                     * Mutate the fetching status for the specified property.
                     */
                    get fetching() {
                        return {
                            /**
                             * Mutate the specified status.
                             */
                            get locations() {
                                return getMutationsForStatus(
                                    'fetching_locations'
                                );
                            },
                            /**
                             * Mutate the specified status.
                             */
                            get profiles() {
                                return getMutationsForStatus(
                                    'fetching_mapping_profiles'
                                );
                            },
                        };
                    },
                    /**
                     * Mutate the suggesting status for the specified property.
                     */
                    get suggesting() {
                        return {
                            /**
                             * Mutate the specified status.
                             */
                            get locations() {
                                return getMutationsForStatus(
                                    'suggesting_locations'
                                );
                            },
                            /**
                             * Mutate the specified status.
                             */
                            get profiles() {
                                return getMutationsForStatus(
                                    'suggesting_mapping_profiles'
                                );
                            },
                        };
                    },
                };
            },
        };
    }
}
