// <!-- UTILITIES -->
import { parseLocal, formatLocal } from '@/utils/date';
import { isNumber, isString, isDateTimeLocalString } from '@/utils/typeof';
import { isDate, isValid, toDate, add, sub } from 'date-fns';

// <!-- TYPES -->//TODO: Enhance with global types.
/** @typedef {import('@/utils/date').IDate} IDate */
/** @typedef {import('@/utils/date').ITimestamp} ITimestamp */
/** @typedef {import('@/utils/date').IUnixTimestamp} IUnixTimestamp */
/** @typedef {import('@/utils/datetime').IDateTimeLocalService} IDateTimeLocalService */

// <!-- DEFINITION -->
/**
 * @class
 * Local 8601 datetime service.
 * @implements {IDateTimeLocalService}
 */
export class DateTimeLocal {
    /**
     * Clone existing {@link Date} instance. For convenience casts {@link ITimestamp} into {@link Date} instance.
     * @param {IDate} [argument] Instance to clone. If `null`, uses epoch date. If `undefined` uses today's date.
     * @returns {Date}
     */
    static clone = toDate;

    /** @type {IDateTimeLocalService['parse']} */
    static parse = (argument) => {
        if (isString(argument)) {
            const input = /** @type {String} */ (argument);
            if (isDateTimeLocalString(input)) {
                const date = parseLocal(input, { additionalDigits: 2 });
                return date;
            }
        } else {
            const date = /** @type {IDate} */ (argument);
            return toDate(date);
        }
        throw new RangeError(`Cannot parse invalid Local string: ${argument}.`);
    };

    /** @type {IDateTimeLocalService['format']} */
    static format = (argument) => {
        if (isNumber(argument) || (isDate(argument) && isValid(argument))) {
            const input = /** @type {IDate} */ (argument);
            return formatLocal(input, {});
        }
        throw new RangeError(`Cannot format invalid argument: ${argument}.`);
    };

    /** @type {IDateTimeLocalService['add']} */
    static add = (date, duration) => add(date, duration);

    /** @type {IDateTimeLocalService['subtract']} */
    static subtract = (date, duration) => sub(date, duration);

    /** @type {IDateTimeLocalService['clone']} */
    clone = DateTimeLocal.clone;

    /** @type {IDateTimeLocalService['parse']} */
    parse = DateTimeLocal.parse;

    /** @type {IDateTimeLocalService['format']} */
    format = DateTimeLocal.format;

    /** @type {IDateTimeLocalService['add']} */
    add = DateTimeLocal.add;

    /** @type {IDateTimeLocalService['subtract']} */
    subtract = DateTimeLocal.subtract;
}

// <!-- DEFAULT -->
export default DateTimeLocal;
