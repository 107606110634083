<template>
    <div
        class="flex flex-row flex-wrap mb-2 sm:mb-0 sm:flex-nowrap justify-between"
    >
        <slot></slot>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent } from 'vue';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'PageIntro',
    });
</script>
