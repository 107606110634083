// <!-- MODELS -->
import { StoreDataModel } from './StoreDataModel';

// <!-- DATA CLASS -->
/**
 * Represents the form data model of a date range filter.
 *
 * @implements {Filters.DateRange.Form.Data}
 */
export class FormDataModel {
    // TYPE DEFINITIONS

    /** @typedef {Filters.DateRange.Form.Data} Data */

    // STATIC METHODS

    /**
     * Create new filter state instance.
     *
     * @param {Partial<Data>} [params]
     * @returns {FormDataModel}
     */
    static create(params = {}) {
        return new FormDataModel(params);
    }

    /**
     * Create a new filter model from a fixed date range.
     * @param {FixedDateRange} range
     * @param {TimeZone.Identifier} [timezone]
     * @returns {FormDataModel}
     */
    static fromFixedDateRange(range, timezone = 'UTC') {
        const dateFormatter = new Intl.DateTimeFormat('en-CA', {
            timeZone: timezone,
        });
        const start = dateFormatter.format(range.start);
        const end = dateFormatter.format(range.end);
        return new FormDataModel({
            start,
            end,
            all: false,
            overlap: false,
        });
    }

    // CONSTRUCTOR

    /**
     * Instantiate filter state.
     * @param {Partial<Data>} [params]
     */
    constructor(params = {}) {
        // DEFINE PROPERTIES
        this.start = '';
        this.end = '';
        this.all = false;
        this.overlap = false;

        // APPLY INITIAL VALUES.
        this.update(params);
    }

    // SERVICE METHODS

    /**
     * Update the filter state.
     * @param {Partial<Data>} [params]
     * @returns {FormDataModel}
     */
    update(params = {}) {
        // Update all the data where properties are enumerable and present.
        for (const key in params) {
            switch (key) {
                case 'start':
                case 'end':
                    this[key] = params?.[key] ?? '';
                    break;
                case 'all':
                case 'overlap':
                    this[key] = params?.[key] ?? false;
                    break;
            }
        }

        // Return self.
        return this;
    }

    /**
     * Create a new instance with the same values as the current one.
     * @returns {FormDataModel}
     */
    clone() {
        return new FormDataModel({ ...this });
    }

    /**
     * Create a new cache data model instance using this form data model.
     */
    toCacheModel() {
        const { start, end } = this;
        const checked = new Set();
        if (this.all) checked.add('all');
        if (this.overlap) checked.add('overlap');
        return new StoreDataModel({
            start,
            end,
            checked,
        });
    }
}

// <!-- DEFAULT -->
export default FormDataModel;
