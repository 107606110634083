// <!-- TYPES -->
import { Model } from '@/models/v1/resource/Model';
import { DynamicEnumFactory } from '@/utils/DynamicEnum';
/** @typedef {ReturnType<MappingSettings['toPayload']>} MappingSettingsPayload */
/** @typedef {ReturnType<MappingSettings['toResource']>} MappingSettingsResource */

/** Model attribute names. */
const FIELDS = DynamicEnumFactory().fromKeys([
    'limit',
    'offset',
    'headers',
    'delimiter',
    'decimal',
    'timezone',
]);

/**
 * @class
 */
export class MappingSettings extends Model {
    _initialState() {
        return Model.ComposeStateUsingFields(FIELDS);
    }

    /**
     * @param {MappingSettingsResource} resource
     * @returns {this}
     */
    parseResource(resource) {
        /** @type {(resource: MappingSettingsResource) => [string, any][]} */
        const transformResource = (resource) => {
            const { ...attributes } = resource;
            const entity = {
                // Defaults.
                limit: 0,
                offset: 0,
                headers: true,
                delimiter: null,
                decimal: null,
                timezone: 'UTC',
                // Overrides
                ...attributes,
            };
            return Object.entries(entity);
        };
        super.parseResource(resource, transformResource);
        return this;
    }

    toPayload() {
        return {
            /** @type {Number} */
            [FIELDS.limit]: this.get(FIELDS.limit),
            /** @type {Number} */
            [FIELDS.offset]: this.get(FIELDS.offset),
            /** @type {Boolean} */
            [FIELDS.headers]: this.get(FIELDS.headers),
            /** @type {String} */
            [FIELDS.delimiter]: this.get(FIELDS.delimiter),
            /** @type {String} */
            [FIELDS.decimal]: this.get(FIELDS.decimal),
            /** @type {TimeZone.Identifier} */
            [FIELDS.timezone]: this.get(FIELDS.timezone),
        };
    }

    toResource() {
        return {
            /** @type {Number} */
            [FIELDS.limit]: this.get(FIELDS.limit),
            /** @type {Number} */
            [FIELDS.offset]: this.get(FIELDS.offset),
            /** @type {Boolean} */
            [FIELDS.headers]: this.get(FIELDS.headers),
            /** @type {String} */
            [FIELDS.delimiter]: this.get(FIELDS.delimiter),
            /** @type {String} */
            [FIELDS.decimal]: this.get(FIELDS.decimal),
            /** @type {TimeZone.Identifier} */
            [FIELDS.timezone]: this.get(FIELDS.timezone),
        };
    }
}
