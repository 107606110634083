// <!-- API -->
import session from '@/api/v2/session';

// <!-- UTILITIES -->
import { StorageKey } from '@/enums/StorageKey';
import { Maybe } from 'true-myth/dist/maybe';

/**
 * Class used to manipulate the session storage context.
 */
export class Context {
    /**
     * Get the backend state and use it to update the local/session storage.
     */
    static async pull() {
        // Pull the organization state.
        const currentOrganization = await session.fetchSessionOrganization();

        // Pull the account state.
        const currentAccount = await session.fetchSessionAccount();

        // Save the organization selection state.
        this.organization = currentOrganization.map((t) => t.id).toMaybe();

        // Save the account selection state.
        this.account = currentAccount.map((t) => t.id).toMaybe();
    }

    /**
     * Push the current local/session storage to the backend state, if possible.
     */
    static async push() {
        // Pull the local organization state.
        const selectedOrganization = this.organization;
        if (selectedOrganization.isJust) {
            // Attempt to select the organization.
            const response = await session.selectOrganization({
                organization_id: selectedOrganization.value,
            });
            // If it fails, clear it locally.
            if (response.isErr) {
                this.organization = Maybe.nothing();
            }
        }

        // Pull the local account state.
        const selectedAccount = this.account;
        if (selectedAccount.isJust) {
            // Attempt to select the account.
            const response = await session.selectAccount({
                account_id: selectedAccount.value,
            });
            // If it fails, clear it locally.
            if (response.isErr) {
                this.account = Maybe.nothing();
            }
        }
    }

    static set organization(id) {
        const _id = id.map((t) => `${t}`);
        if (_id.isJust) {
            sessionStorage.setItem(StorageKey.SelectedOrganization, _id.value);
        } else {
            sessionStorage.removeItem(StorageKey.SelectedOrganization);
        }
    }

    static get organization() {
        const _id = sessionStorage.getItem(StorageKey.SelectedOrganization);
        const id = Maybe.of(_id).map((t) => parseInt(t));
        return id;
    }

    static get hasOrganizationSelected() {
        const id = this.organization;
        return id.isJust && id.value > 0;
    }

    static get missingOrganization() {
        return this.organization.isNothing;
    }

    static set account(id) {
        const _id = id.map((t) => `${t}`);
        if (_id.isJust) {
            sessionStorage.setItem(StorageKey.SelectedAccount, _id.value);
        } else {
            sessionStorage.removeItem(StorageKey.SelectedAccount);
        }
    }

    static get account() {
        const _id = sessionStorage.getItem(StorageKey.SelectedAccount);
        const id = Maybe.of(_id).map((t) => parseInt(t));
        return id;
    }

    static get hasAccountSelected() {
        const id = this.account;
        return id.isJust && id.value > 0;
    }

    static get missingAccount() {
        return this.account.isNothing;
    }
}

export default Context;
