<template>
    <div
        v-if="!!showCloseButton"
        class="modal__close relative flex flex-row-reverse items-center pt-4 px-4 pb-2 sm:p-0"
    >
        <!-- Close (XIcon) (hidden on mobile) -->
        <button
            type="button"
            :class="[
                'hidden sm:inline-flex',
                'static sm:absolute sm:-top-2 sm:-right-2 p-1',
                'focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2',
                'transform rotate-0 scale-75',
                'transition-all duration-300 ease-in-out',
                'rounded-full shadow-lg',
                {
                    'cursor-pointer': !disabled && !busy,
                    'text-gray-400 hover:text-white': !disabled && !busy,
                    'hover:-rotate-180 hover:scale-100': !disabled && !busy,
                    'bg-white hover:bg-error': !disabled && !busy,
                    'cursor-not-allowed': disabled && !busy,
                    'cursor-wait animate-pulse': busy,
                    'text-gray-400 hover:text-gray-300': disabled || busy,
                    'bg-gray-50 hover:bg-red-800': disabled || busy,
                },
            ]"
            :title="title ?? 'Close modal.'"
            :disabled="disabled"
            :aria-disabled="disabled"
            @click.stop="$emit('close', { id: 'x', event: $event })"
        >
            <span class="sr-only">{{ label ?? 'Close' }}</span>
            <XIcon
                class="h-8 w-8 sm:h-6 sm:w-6"
                aria-hidden
            />
        </button>
        <!-- Close (Bar) (mobile-only) -->
        <ModalButton
            class="inline-flex sm:hidden box-content"
            :title="title ?? 'Close modal.'"
            :disabled="disabled"
            :aria-disabled="disabled"
            :theme="Theme.danger"
            @click.stop="$emit('close', { id: 'bar', event: $event })"
        >
            <template #default>
                <span>{{ label ?? 'Close' }}</span>
            </template>
        </ModalButton>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent } from 'vue';

    // <!-- UTILITIES -->
    import { Theme } from '@/enums';

    // <!-- COMPONENTS -->
    import ModalButton from '@/components/modals/ModalButton.vue';
    import { XIcon } from '@heroicons/vue/solid';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'ModalCloseButton',
        components: {
            ModalButton,
            XIcon,
        },
        props: {
            /** Should the close button be displayed? */
            showCloseButton: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: true,
            },
            /** Label to use when in bar format. */
            label: {
                /** @type {V.PropType<String>} */
                type: String,
                default: 'Close',
            },
            /** Optional title to display when hovering over the button element. */
            title: {
                /** @type {V.PropType<String>} */
                type: String,
                default: 'Close modal.',
            },
            /** If `true`, button actions are disabled. */
            disabled: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            /** If `true`, button actions are disabled and busy. */
            busy: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
        },
        emits: ['close'],
        setup(props) {
            return {
                // THEME
                Theme,
            };
        },
    });
</script>
