/**
 * formkit.js
 *
 * Initializes and configures the formkit module
 * for use with the project.
 */
import { createVueSelectInput } from '@/plugins/vue-select';
import { plugin, defaultConfig } from '@formkit/vue';
import config from '~/formkit.config';
// NOTE: Do NOT use the genesis default theme shown in the docs, it will conflict with our custom tailwind styles
// XXX import '@formkit/themes/genesis/theme';

// Need access to the tailwind CSS for the formkig configuraiton to work.
import '@/index.css';

/**
 * Use the Formkit plugin.
 *
 * @param {V.App} app Vue application instance.
 * @returns Vue app instance.
 */
export const registerFormkit = (app) => {
    console.groupCollapsed(
        `[plugin::formkit] registered @ ${new Date().toLocaleTimeString()}`
    );
    app.use(
        plugin,
        defaultConfig({
            ...config,
            inputs: {
                vs: createVueSelectInput(),
            },
        })
    );
    console.groupEnd();
    return app;
};
