<template>
    <button
        :disabled="disabled || busy"
        :class="[
            'inline-flex justify-center',
            width,
            'max-h-10 px-4 py-2 m-1',
            'text-base sm:text-sm font-medium',
            'bg-opacity-100 disabled:bg-opacity-50',
            'border-2 border-solid border-transparent',
            'focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2',
            'transition-colors duration-300 ease-out',
            'rounded-md shadow-sm whitespace-nowrap',
            {
                // Cursor
                'cursor-wait animate-pulse': busy,
                'cursor-not-allowed': disabled && !busy,
                'cursor-pointer': !disabled && !busy,

                // Black (Enabled, Disabled, Busy)
                'text-white hover:text-white active:text-black':
                    isBlack && !disabled && !busy,
                'bg-black hover:bg-gray-700 active:bg-white':
                    isBlack && !disabled && !busy,
                'border-transparent ring-offset-0 ring-2 ring-gray-50 hover:shadow-none hover:border-black active:border-black':
                    isBlack && !disabled && !busy,
                'black__disabled text-gray-100': isBlack && (disabled || busy),
                'black__disabled bg-gray-800 shadow-inner border-gray-100':
                    isBlack && (disabled || busy),

                // White (Enabled, Disabled, Busy)
                'text-gray-900 hover:text-black active:text-white':
                    isWhite && !disabled && !busy,
                'bg-white hover:bg-gray-100 active:bg-black':
                    isWhite && !disabled && !busy,
                'border-transparent ring-offset-0 ring-2 ring-gray-600 hover:shadow-none hover:border-black active:border-white':
                    isWhite && !disabled && !busy,
                'white__disabled text-gray-700': isWhite && (disabled || busy),
                'white__disabled bg-gray-50 shadow-inner border-gray-600':
                    isWhite && (disabled || busy),

                // Primary (Enabled, Disabled, Busy)
                'text-white hover:text-white active:text-primary-700':
                    isPrimary && !disabled && !busy,
                'bg-primary-600 hover:bg-primary-800 focus:bg-primary-500 active:bg-white':
                    isPrimary && !disabled && !busy,
                'border-transparent hover:border-primary-900 active:border-primary-600':
                    isPrimary && !disabled && !busy,
                'primary__disabled text-gray-200':
                    isPrimary && (disabled || busy),
                'primary__disabled bg-primary-500 border-primary-500':
                    isPrimary && (disabled || busy),

                // Secondary (Enabled, Disabled, Busy)
                'text-primary-900 hover:text-black active:text-secondary-800':
                    isSecondary && !disabled && !busy,
                'bg-secondary-500 hover:bg-secondary-400 active:bg-white':
                    isSecondary && !disabled && !busy,
                'border-transparent hover:bg-secondary-400 active:border-secondary-400':
                    isSecondary && !disabled && !busy,
                'secondary__disabled text-primary-900':
                    isSecondary && (disabled || busy),
                'secondary__disabled bg-secondary-600 shadow-inner border-secondary-600':
                    isSecondary && (disabled || busy),

                // Info (Enabled, Disabled, Busy)
                'text-primary-700 hover:text-primary-800 active:text-white':
                    isInfo && !disabled && !busy,
                'bg-white hover:bg-primary-50 hover:bg-opacity-50 hover:active:bg-opacity-100 active:bg-primary-500':
                    isInfo && !disabled && !busy,
                'border-primary-700 shadow-inner hover:shadow-none hover:border-primary-600 active:border-white':
                    isInfo && !disabled && !busy,
                'info__disabled text-gray-800': isInfo && (disabled || busy),
                'info__disabled bg-gray-200 border-info':
                    isInfo && (disabled || busy),

                // Warning (Enabled, Disabled, Busy)
                'text-white hover:text-white active:text-warning':
                    isWarning && !disabled && !busy,
                'bg-warning hover:bg-warning hover:bg-opacity-75 active:bg-white':
                    isWarning && !disabled && !busy,
                'border-transparent hover:border-warning active:border-warning':
                    isWarning && !disabled && !busy,
                'warning__disabled text-gray-800':
                    isWarning && (disabled || busy),
                'warning__disabled bg-gray-200 border-warning':
                    isWarning && (disabled || busy),

                // Success (Enabled, Disabled, Busy)
                'text-white hover:text-white active:text-success':
                    isSuccess && !disabled && !busy,
                'bg-success hover:bg-success hover:bg-opacity-50 active:bg-white':
                    isSuccess && !disabled && !busy,
                'border-transparent hover:border-success active:border-success':
                    isSuccess && !disabled && !busy,
                'success__disabled text-gray-800':
                    isSuccess && (disabled || busy),
                'success__disabled bg-gray-200 border-success':
                    isSuccess && (disabled || busy),

                // Failure / Error (Enabled, Disabled, Busy)
                'text-white hover:text-white active:text-error':
                    (isFailure || isError) && !disabled && !busy,
                'bg-error hover:bg-error hover:bg-opacity-50 active:bg-white':
                    (isFailure || isError) && !disabled && !busy,
                'border-transparent hover:border-error active:border-error':
                    (isFailure || isError) && !disabled && !busy,
                'error__disabled text-gray-800':
                    (isFailure || isError) && (disabled || busy),
                'error__disabled bg-gray-200 border-error':
                    (isFailure || isError) && (disabled || busy),

                // Danger (Enabled, Disabled, Busy)
                'text-white hover:text-danger active:text-white':
                    isDanger && !disabled && !busy,
                'bg-danger hover:bg-white active:bg-red-500':
                    isDanger && !disabled && !busy,
                'border-transparent hover:border-danger active:border-white':
                    isDanger && !disabled && !busy,
                'danger__disabled text-red-800': isDanger && (disabled || busy),
                'danger__disabled bg-gray-200 border-red-500':
                    isDanger && (disabled || busy),
            },
        ]"
        v-bind="$attrs"
        @click.prevent="$emit('click', $event)"
    >
        <div class="inline-flex justify-center items-center gap-x-2">
            <slot v-if="$slots.default"></slot>
            <span v-else-if="!!label?.length">
                {{ label }}
            </span>
        </div>
    </button>
</template>

<script>
    // <!-- API -->
    import { defineComponent, toRef } from 'vue';

    // <!-- ENUMS -->
    import { Theme } from '@/enums';

    // <!-- COMPOSABLES -->
    import { useEnum } from '@/hooks/useEnum';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'ModalButton',
        props: {
            /** Property used to specify the label text. Overriden when a slot is available. */
            label: {
                /** @type {Vue.PropType<String>} */
                type: String,
                default: 'Button',
            },
            /** Property used to specify the label text. Overriden when a slot is available. */
            width: {
                /** @type {Vue.PropType<String>} */
                type: String,
                default: 'w-full sm:w-auto',
            },
            /** If `true`, button actions are disabled. */
            disabled: {
                /** @type {Vue.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            /** If `true`, button actions are disabled and busy. */
            busy: {
                /** @type {Vue.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            /** Determine the inner component themes using the {@link Theme} enum. */
            theme: {
                /** @type {Vue.PropType<Theme[keyof Theme['_dictionary']]>} */
                // @ts-ignore
                type: String,
                default: Theme.primary,
                validator: (theme) =>
                    theme === null ||
                    Object.values(Theme).includes(
                        /** @type {Theme[keyof Theme['_dictionary']]} */ (theme)
                    ),
            },
        },
        emits: ['click'],
        setup(props) {
            // <!-- DESTRUCTURE -->
            const theme = toRef(props, 'theme');

            // <!-- CONDITIONALS -->
            const {
                isBlack,
                isWhite,
                isPrimary,
                isSecondary,
                isInfo,
                isWarning,
                isSuccess,
                isFailure,
                isDanger,
                isError,
            } = useEnum(Theme, theme);

            // <!-- EXPOSE -->
            return {
                // THEME
                isBlack,
                isWhite,
                isPrimary,
                isSecondary,
                isInfo,
                isWarning,
                isSuccess,
                isFailure,
                isDanger,
                isError,
            };
        },
    });
</script>
