/**
 * @class
 * Form step definition helper.
 */
export class FormStepDefinition {
    /**
     * Prepare a definition.
     * @param {Partial<FormStepDefinition>} [initial]
     */
    static prepare(initial) {
        return new FormStepDefinition(initial);
    }

    /**
     * Create a form step definition.
     * @param {Partial<FormStepDefinition>} attributes
     */
    constructor(attributes) {
        /** @type {String} Unique step identifier. */
        this.id = null;
        /** @type {Partial<Pick<FormStep, 'label' | 'description'>>} Text details. */
        this.details = null;
        Object.seal(this);
        Object.assign(this, attributes);
    }

    /**
     * Patch the definition.
     * @param {Partial<FormStepDefinition>} attributes
     */
    patch(attributes = {}) {
        Object.assign(this, attributes);
        return this;
    }

    /**
     * Property that provides access to the chainable setters.
     */
    get using() {
        return {
            /** @param {String} value */
            id: (value) => {
                return this.patch({ id: value });
            },
            /** @param {String} value */
            label: (value) => {
                const details = Object.assign({}, this.details, {
                    label: value,
                });
                return this.patch({ details });
            },
            /** @param {String} value */
            description: (value) => {
                const details = Object.assign({}, this.details, {
                    description: value,
                });
                return this.patch({ details });
            },
        };
    }

    /**
     * Constructs the step from the object's current state.
     */
    create() {
        return FormStep.create(this.id, this.details);
    }
}

/**
 * @class
 * Form step interface.
 */
export class FormStep {
    /**
     * Helper constructor for creating a new FormStep.
     *
     * @param {String} id Unique identifier for the step.
     * @param {Partial<Pick<FormStep, 'label' | 'description'>>} details Text details.
     */
    static create(id, details) {
        /** @type {Pick<FormStep, 'id'> & Partial<FormStep>} */
        const attributes = {
            id,
            ...details,
        };
        return new FormStep(attributes);
    }

    /**
     * Construct a FormStep instance.
     * @param {Pick<FormStep, 'id'> & Partial<FormStep>} [attributes]
     */
    constructor(attributes) {
        /** @type {String} Unique step identifier. */
        this.id = null;
        /** @type {String} Human-readable label for the current step. */
        this.label = 'Step #';
        /** @type {String} Informational description. */
        this.description = 'Step description goes here.';
        // Seal object and copy parameters.
        Object.seal(this);
        Object.assign(this, attributes);
    }
}
