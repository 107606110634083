import { AxiosError } from 'axios';
import { Result } from 'true-myth/dist/result';

/**
 * Server error factory.
 */
export class ErrorResult {
    /**
     * Create a `Result.Err` instance from the passed content.
     * @template {unknown} [E=any]
     * @param {E} content
     * @returns
     */
    static err(content) {
        return Result.err(content);
    }

    /**
     * Create an error result containing a simple `reason` clause.
     * @param {string} reason
     * @returns
     */
    static because(reason) {
        return ErrorResult.err({ reason });
    }

    /**
     * Create an error result from a generic `Error`.
     * @param {Error} e
     */
    static from(e) {
        return ErrorResult.err(e);
    }

    /**
     * Create an error result from an AxiosError.
     * @template [R=any] Request data.
     * @param {AxiosError} e
     * @param {R} [request]
     * @returns
     */
    static fromAxiosError(e, request) {
        // Handle special cases based on HTTP status code.
        const { status = 500 } = e;
        switch (status) {
            case 400:
            case 422:
                return ErrorResult.fromBadRequestError(e, request, status);
            case 404:
                return ErrorResult.fromNotFoundError(e, request);
            case 401:
            case 403:
                return ErrorResult.fromUnauthorizedError(e, request, status);
        }

        // Handle unexpected server error.
        return ErrorResult.fromInternalServerError(e, request);
    }

    /**
     * Create a standardized bad request error result.
     * @template {Error} [E=Error]
     * @template [R=any] Request data.
     * @param {E} [e]
     * @param {R} [request]
     * @param {400 | 422} [status]
     * @returns {Result<any, Errors.BadRequestError<E,R>>}
     */
    static fromBadRequestError(e, request, status) {
        return ErrorResult.err({
            status: status ?? 400,
            name: 'Bad Request',
            message: e?.message ?? 'Request could not be completed.',
            error: e,
            request,
        });
    }

    /**
     * Create a standardized resource not found error result.
     * @template {Error} [E=Error]
     * @template [R=any] Request data.
     * @param {E} [e]
     * @param {R} [request]
     * @returns {Result<any, Errors.NotFoundError<E,R>>}
     */
    static fromNotFoundError(e, request) {
        return ErrorResult.err({
            status: 404,
            name: 'Not Found',
            message: e?.message ?? 'Resource not found.',
            error: e,
            request,
        });
    }

    /**
     * Create a standardized unauthorized error result.
     * @template {Error} [E=Error]
     * @template [R=any] Request data.
     * @param {E} [e]
     * @param {R} [request]
     * @param {401 | 403} [status]
     * @returns {Result<any, Errors.UnauthorizedError<E,R>>}
     */
    static fromUnauthorizedError(e, request, status) {
        return ErrorResult.err({
            status: status ?? 401,
            name: 'Unauthorized',
            message: e?.message ?? 'Unauthorized.',
            error: e,
            request,
        });
    }

    /**
     * Create a standardized internal server error result.
     * @template {Error} [E=Error]
     * @template [R=any]
     * @param {E} [e]
     * @param {R} [request]
     * @returns {Result<any, Errors.InternalError<E,R>>}
     */
    static fromInternalServerError(e, request) {
        return ErrorResult.err({
            status: 500,
            name: 'Internal Server Error',
            message: e?.message ?? 'An unexpected error occurred.',
            error: e,
            request,
        });
    }
}

export default ErrorResult;
