// <!-- TYPES -->
import { UploadData, UploadRecord } from '@/store/types/uploader/state';
import { Collection } from 'collect.js';

// <!-- UTILITIES -->
import has from 'just-has';

/**
 * Get filtered group functions.
 * @param {() => Collection<*>} getCollection Helper, used for all callbacks.
 * @param {() => Number} getTotalCount Helper, used for the every callback.
 */
const useFilteredGroup = (getCollection, getTotalCount) => ({
    /**
     * Get the flat count of the filtered group.
     * @returns {Number}
     */
    count: () => {
        return getCollection().count();
    },
    /**
     * Verify no records are in the filtered group.
     * @returns {Boolean}
     */
    none: () => {
        return getCollection().isEmpty();
    },
    /**
     * Verify all records are in the filtered group.
     * @returns {Boolean}
     */
    every: () => {
        return getCollection().count() === getTotalCount();
    },
    /**
     * Verify at least one or more records are in the filtered group.
     * @returns {Boolean}
     */
    some: () => {
        return getCollection().isNotEmpty();
    },
});

/**
 * Synchronous getters for a {@link UploadData} instance.
 */
export const UploadDataGetters = {
    /**
     * Get record query helpers.
     * @param {UploadData} context
     */
    find: (context) => ({
        /**
         * Get record by filename.
         * @param {String} filename
         * @returns {UploadRecord}
         */
        byFilename: (filename) => {
            return !!filename ? context.records.get(filename) : null;
        },
        /**
         * Get record by dataset batch id.
         * @param {Number} id
         * @returns {UploadRecord}
         */
        byDatasetBatchID: (id) => {
            const withBatches = context.whereDatasetBatchUploaded;
            return withBatches.isNotEmpty && id >= 0
                ? withBatches.first((record) => record.batch.value.id === id)
                : null;
        },
        /**
         * Get record by index.
         * @param {Number} index
         * @returns {UploadRecord}
         */
        at: (index) => {
            const records = context.all;
            return records.isNotEmpty() && index >= 0 && index < context.count
                ? records.toArray()[index]
                : null;
        },
        /**
         * Get records that are marked for removal.
         * @returns {Collection<UploadRecord>}
         */
        whereMarked: () => {
            return context.whereMarkedForRemoval;
        },
    }),

    /**
     * Get record collection validators.
     * @param {UploadData} context
     */
    has: (context) => ({
        /**
         * Verify record exist with filename.
         * @param {String} filename
         * @returns {Boolean}
         */
        filename: (filename) => {
            return !!filename ? context.records.has(filename) : false;
        },
        /**
         * Verify record exist with dataset batch id.
         * @param {Number} id
         * @returns {Boolean}
         */
        datasetBatchID: (id) => {
            const match = UploadDataGetters.find(context).byDatasetBatchID(id);
            return !!match;
        },
        /**
         * Verify record exist with index.
         * @param {Number} index
         * @returns {Boolean}
         */
        at: (index) => {
            return index >= 0 && index < context.count;
        },
        /**
         * Verify how records meet the stated requirements.
         */
        marked: useFilteredGroup(
            () => context.whereMarkedForRemoval,
            () => context.count
        ),
        /**
         * Verify information about the suggested locations and mapping profiles.
         */
        suggested: {
            /**
             * Verify information about the suggested locations.
             */
            locations: useFilteredGroup(
                () => context.whereLocationSuggested,
                () => context.count
            ),
            profiles: useFilteredGroup(
                () => context.whereMappingProfileSuggested,
                () => context.count
            ),
        },
        selected: {
            files: useFilteredGroup(
                () => context.all,
                () => context.count
            ),
            locations: useFilteredGroup(
                () => context.whereLocationSelected,
                () => context.count
            ),
            profiles: useFilteredGroup(
                () => context.whereMappingProfileSelected,
                () => context.count
            ),
        },
        uploaded: {
            batches: useFilteredGroup(
                () => context.whereDatasetBatchUploaded,
                () => context.count
            ),
        },
        assigned: {
            files: useFilteredGroup(
                () => context.whereFileAssigned,
                () => context.count
            ),
            locations: useFilteredGroup(
                () => context.whereLocationAssigned,
                () => context.count
            ),
        },
        created: {
            profiles: useFilteredGroup(
                () => context.whereMappingProfileCreated,
                () => context.count
            ),
        },
        ingested: {
            batches: useFilteredGroup(
                () => context.whereDatasetBatchIngested,
                () => context.count
            ),
        },
        applied: {
            profiles: useFilteredGroup(
                () => context.whereMappingProfileApplied,
                () => context.count
            ),
        },
    }),
};
