// <!-- API -->
import { ECNBStateMutations } from '@/store/types/ECNBStateMutations';
import { LocationIndexStateMutations } from '@/store/types/cache/mutations/LocationIndexStateMutations';
import { LocationHierarchyIndexStateMutations } from '@/store/types/cache/mutations/LocationHierarchyIndexStateMutations';
import { WeatherStationIndexStateMutations } from '@/store/types/cache/mutations/WeatherStationIndexStateMutations';
import { MappingProfileIndexStateMutations } from '@/store/types/cache/mutations/MappingProfileIndexStateMutations';
import { NoteIndexStateMutations } from '@/store/types/cache/mutations/NoteIndexStateMutations';

// <!-- TYPES -->
import { CacheState } from '@/store/types/cache/state';

export class CacheStateMutations extends ECNBStateMutations {
    /**
     * Provide access to direct state assignment operations.
     */
    static get set() {
        return {
            /**
             * Assign cache state directly.
             * @param {CacheState} state State instance.
             * @param {'initialized'} id
             */
            status: (state, id) => {
                /**
                 * Change {@link id} status.
                 * @param {Boolean} value
                 */
                const $changeStatus = (value) => {
                    const updated = state.status;
                    if (value === true) {
                        updated.add(id);
                    }
                    if (value === false) {
                        updated.delete(id);
                    }
                    state.status = updated;
                };
                return {
                    /** @param {Boolean} value */
                    to: (value) => $changeStatus(value),
                    enable: () => $changeStatus(true),
                    disable: () => $changeStatus(false),
                };
            },
            locations: LocationIndexStateMutations.set,
            hierarchies: LocationHierarchyIndexStateMutations.set,
            stations: WeatherStationIndexStateMutations.set,
            profiles: MappingProfileIndexStateMutations.set,
            notes: NoteIndexStateMutations.set,
        };
    }

    /**
     * Provide access to direct state assignment operations.
     */
    static get clear() {
        return {
            locations: LocationIndexStateMutations.clear,
            hierarchies: LocationHierarchyIndexStateMutations.clear,
            stations: WeatherStationIndexStateMutations.clear,
            profiles: MappingProfileIndexStateMutations.clear,
            notes: NoteIndexStateMutations.clear,
        };
    }

    /**
     * Provide access to direct state assignment operations.
     */
    static get add() {
        return {
            locations: LocationIndexStateMutations.add,
            hierarchies: LocationHierarchyIndexStateMutations.add,
            stations: WeatherStationIndexStateMutations.add,
            profiles: MappingProfileIndexStateMutations.add,
            notes: NoteIndexStateMutations.add,
        };
    }

    /**
     * Provide access to direct state assignment operations.
     */
    static get drop() {
        return {
            locations: LocationIndexStateMutations.drop,
            hierarchies: LocationHierarchyIndexStateMutations.drop,
            stations: WeatherStationIndexStateMutations.drop,
            profiles: MappingProfileIndexStateMutations.drop,
            notes: NoteIndexStateMutations.drop,
        };
    }
}
