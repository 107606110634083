// <!-- API -->
import { Assertion } from '@/store/types/ECNBAssertions';

// <!-- TYPES -->
import { UploadData, UploadRecord } from '@/store/types/uploader/state';

/**
 * Asynchronous assertions that will test predicates
 * and fail when appropriate, throwing errors to interrupt actions.
 */
export class UploadDataActions {
    /**
     * Upload record collection assertions.
     * @param {UploadData} context
     */
    static assert(context) {
        const $ = UploadDataActions;
        return {
            /**
             * Assert record exists and is not null nor undefined and exists in the data records collection.
             * @param {UploadRecord} record
             * @returns {Promise<void>}
             */
            isPresent: async (record) => {
                await Assertion.expect(
                    record,
                    'reference::isNotNil'
                ).isNotNil();
                await $.assert(context).withFilename(record.filename);
            },
            /**
             * Assert record with matching filename is null or undefined.
             * @param {File | UploadRecord} fileOrRecord
             */
            isMissing: async (fileOrRecord) => {
                await Assertion.expect(fileOrRecord).isNotNil();
                const filename =
                    fileOrRecord instanceof File
                        ? fileOrRecord.name
                        : fileOrRecord.filename;
                await Assertion.expect(
                    context.records.has(filename),
                    'filename::isMissing'
                ).isFalsy();
            },
            /**
             * Assert record exists with matching filename.
             * @param {String} filename
             * @returns {Promise<void>}
             */
            withFilename: async (filename) => {
                await Assertion.expect(filename).isNotNil();
                await Assertion.expect(filename).isNotEmpty();
                const match = context.records.has(filename);
                await Assertion.expect(match, 'filename::exists').isTruthy();
            },
            /**
             * Assert record exists with matching dataset batch id.
             * @param {Number} id
             * @returns {Promise<void>}
             */
            withDatasetBatchID: async (id) => {
                await Assertion.expect(id).isNotNil();
                await Assertion.expect(id).isNumber();
                const match = context.all.first(
                    (record) =>
                        record.batch.exists && record.batch.value.id === id
                );
                await Assertion.expect(match, 'batchID::exists').isDefined();
            },
            /**
             * Assert record exists at the specified index location.
             * @param {Number} index
             * @returns {Promise<void>}
             */
            at: async (index) => {
                await Assertion.expect(index).isNotNil();
                await Assertion.expect(index).isNumber();
                await Assertion.expect(index).isValidIndex(context.count);
                const record = context.all.toArray()[index];
                await Assertion.expect(record, 'record::exists').isNotNil();
            },
            /**
             * Assertions related to collection properties.
             */
            get data() {
                return {
                    isFetchingLocations: async () => {
                        await Assertion.expect(
                            context.isFetchingLocations
                        ).isTruthy();
                    },
                    isNotFetchingLocations: async () => {
                        await Assertion.expect(
                            context.isFetchingLocations
                        ).isFalsy();
                    },
                    isSuggestingLocations: async () => {
                        await Assertion.expect(
                            context.isSuggestingLocations
                        ).isTruthy();
                    },
                    isNotSuggestingLocations: async () => {
                        await Assertion.expect(
                            context.isSuggestingLocations
                        ).isFalsy();
                    },
                    isMissingLocations: async () => {
                        await Assertion.expect(
                            context.isMissingLocations
                        ).isTruthy();
                    },
                    isNotMissingLocations: async () => {
                        await Assertion.expect(
                            context.isMissingLocations
                        ).isFalsy();
                    },
                    isUploading: async () => {
                        await Assertion.expect(context.isUploading).isTruthy();
                    },
                    isNotUploading: async () => {
                        await Assertion.expect(context.isUploading).isFalsy();
                    },
                    isFetchingMappingProfiles: async () => {
                        await Assertion.expect(
                            context.isFetchingMappingProfiles
                        ).isTruthy();
                    },
                    isNotFetchingMappingProfiles: async () => {
                        await Assertion.expect(
                            context.isFetchingMappingProfiles
                        ).isFalsy();
                    },
                    isSuggestingMappingProfiles: async () => {
                        await Assertion.expect(
                            context.isSuggestingMappingProfiles
                        ).isTruthy();
                    },
                    isNotSuggestingMappingProfiles: async () => {
                        await Assertion.expect(
                            context.isSuggestingMappingProfiles
                        ).isFalsy();
                    },
                    isRefreshingMappingProfilePreview: async () => {
                        await Assertion.expect(
                            context.isRefreshingMappingProfilePreview
                        ).isTruthy();
                    },
                    isNotRefreshingMappingProfilePreview: async () => {
                        await Assertion.expect(
                            context.isRefreshingMappingProfilePreview
                        ).isFalsy();
                    },
                    isViewingMappingProfile: async () => {
                        await Assertion.expect(
                            context.isViewingMappingProfile
                        ).isTruthy();
                    },
                    isNotViewingMappingProfile: async () => {
                        await Assertion.expect(
                            context.isViewingMappingProfile
                        ).isFalsy();
                    },
                    isEditingMappingProfile: async () => {
                        await Assertion.expect(
                            context.isEditingMappingProfile
                        ).isTruthy();
                    },
                    isNotEditingMappingProfile: async () => {
                        await Assertion.expect(
                            context.isEditingMappingProfile
                        ).isFalsy();
                    },
                    isMissingMappingProfiles: async () => {
                        await Assertion.expect(
                            context.isMissingMappingProfiles
                        ).isTruthy();
                    },
                    isNotMissingMappingProfiles: async () => {
                        await Assertion.expect(
                            context.isMissingMappingProfiles
                        ).isFalsy();
                    },
                    isCreatingMappingProfiles: async () => {
                        await Assertion.expect(
                            context.isCreatingMappingProfiles
                        ).isTruthy();
                    },
                    isNotCreatingMappingProfiles: async () => {
                        await Assertion.expect(
                            context.isCreatingMappingProfiles
                        ).isFalsy();
                    },
                    isApplyingMappingProfiles: async () => {
                        await Assertion.expect(
                            context.isApplyingMappingProfiles
                        ).isTruthy();
                    },
                    isNotApplyingMappingProfiles: async () => {
                        await Assertion.expect(
                            context.isApplyingMappingProfiles
                        ).isFalsy();
                    },
                    isIngesting: async () => {
                        await Assertion.expect(context.isIngesting).isTruthy();
                    },
                    isNotIngesting: async () => {
                        await Assertion.expect(context.isIngesting).isFalsy();
                    },
                    /**
                     * Specified property assertions.
                     */
                    file: {
                        /**
                         * Assert file has been selected by any record.
                         * @param {File} file
                         */
                        isSelected: async ({ name }) => {
                            const selectedFiles = context.selectedFiles;
                            const filenames = selectedFiles.map(
                                (file) => file.name
                            );
                            await Assertion.expect(filenames).isNotEmpty();
                            await Assertion.expect(filenames).includes(name);
                        },
                        /**
                         * Assert file has not been selected by any record.
                         * @param {File} file
                         */
                        isNotSelected: async ({ name }) => {
                            const selectedFiles = context.selectedFiles;
                            const filenames = selectedFiles.map(
                                (file) => file.name
                            );
                            await Assertion.expect(filenames).excludes(name);
                        },
                        /**
                         * Assert file has been assigned by any record.
                         * @param {File} file
                         */
                        isAssigned: async ({ name }) => {
                            const assignedFiles = context.assignedFiles;
                            const filenames = assignedFiles.map(
                                (file) => file.name
                            );
                            await Assertion.expect(filenames).isNotEmpty();
                            await Assertion.expect(filenames).includes(name);
                        },
                        /**
                         * Assert file has not been assigned by any record.
                         * @param {File} file
                         */
                        isNotAssigned: async ({ name }) => {
                            const assignedFiles = context.assignedFiles;
                            const filenames = assignedFiles.map(
                                (file) => file.name
                            );
                            await Assertion.expect(filenames).excludes(name);
                        },
                    },
                };
            },
        };
    }
}
