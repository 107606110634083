<template>
    <div id="ecnb-content">
        <router-view v-slot="{ Component, route }">
            <transition
                name="fade"
                mode="out-in"
            >
                <keep-alive>
                    <div
                        id="transition-target"
                        :key="String(route.meta.namespace ?? 'ecnb')"
                    >
                        <component :is="Component ?? 'LoadingSpinner'" />
                    </div>
                </keep-alive>
            </transition>
        </router-view>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent } from 'vue';

    // <!-- COMPONENT -->
    import LoadingSpinner from '@/components/LoadingSpinner.vue';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'Content',
        components: {
            LoadingSpinner,
        },
    });
</script>

<style lang="scss">
    /* we will explain what these classes do next! */
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }
</style>
