// <!-- API -->
import { ref, computed, reactive } from 'vue';
import { fetchAccountById, fetchAccounts } from '@/api/v1/accounts';
import profile from '@/api/v2/profile';
import organizations from '@/api/v2/organizations';

// <!-- UTILITIES -->
import pick from 'just-pick';
import isNil from 'lodash-es/isNil';
import { Context } from '@/utils/session/Context';

// <!-- COMPONENTS -->
import SwitchAccountTableIcons from '@/features/switch-account/components/SwitchAccountTableIcons.vue';

// <!-- COMPOSABLES -->
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useAlerts } from '@/components/alerts/hooks/useAlerts';
import { useAccountsIndex } from '@/hooks/cache/useAccountsIndex';

// <!-- TYPES -->
import { ECNBState } from '@/store/types/ECNBStore';
import { Result } from 'true-myth/dist/result';
import { Maybe } from 'true-myth/dist/maybe';

/** @template [S=any] @typedef {import('vuex').Store<S>} Store<S> */

// <!-- DEFINITION -->
/**
 * Provides access to all composable submodules.
 * @template {Vue.EmitsOptions} [E=Vue.EmitsOptions]
 */
class SwitchAccounts {
    /**
     * Instantiate a new SwitchAccounts composable.
     * @param {Object} [props] Props to pass to the SwitchAccounts.
     * @param {Store<ECNBState>} [props.store] Optional store to provide. Will be instantiated if nothing is provided.
     * @param {Router.Instance} [props.router] Optional router to provide. Will be instantiated if nothing is provided.
     * @param {ReturnType<useAlerts>} [props.alerts] Alerts composable.
     * @param {ReturnType<useAccountsIndex>} [props.accounts] Account index composable.
     * @param {Vue.SetupContext<E>} [context] Setup context used to emit events.
     */
    constructor(props, context) {
        // Deconstruct parameters.
        const { store, router, alerts, accounts } = props ?? {};

        /** @type {Store<ECNBState>} */
        this.store = store ?? useStore();

        /** @type {Router.Instance} */
        this.router = router ?? useRouter();

        /** @type {ReturnType<useAlerts>} */
        this.alerts = alerts ?? useAlerts();

        /** @type {ReturnType<useAccountsIndex>} */
        this.accounts = accounts ?? useAccountsIndex();

        /** @type {SwitchAccountConstants<E>} */
        this.constants = new SwitchAccountConstants(this);

        /** @type {SwitchAccountState<E>} */
        this.state = new SwitchAccountState(this);

        /** @type {SwitchAccountCache<E>} */
        this.cached = new SwitchAccountCache(this);

        /** @type {SwitchAccountsAPI<E>} */
        this.api = new SwitchAccountsAPI(this);

        /** @type {context['emit']} */
        this.emit = context.emit;

        /** @type {Boolean} */
        this.initialized = false;
    }

    /**
     * Initialize respective submodule
     */
    initialize() {
        const $context = this;
        if (!$context.initialized) {
            // Initialize sequentially. These must be synchronous.
            $context.constants.initialize();
            $context.state.initialize();
            $context.cached.initialize();
            $context.api.initialize();
            $context.initialized = true;
            // If an onInit event exists, invoke it now.
            $context.api.events?.onInit?.();
            // Return the context.
            return $context;
        }
    }

    /**
     * Get access to the module setters.
     */
    get register() {
        const $context = this;
        return {
            /** @param {SwitchAccounts<E>['constants']} instance */
            constants: (instance) => {
                $context.constants = instance;
                return $context;
            },
            /** @param {SwitchAccounts<E>['state']} instance */
            state: (instance) => {
                $context.state = instance;
                return $context;
            },
            /** @param {SwitchAccounts<E>['cached']} instance */
            cached: (instance) => {
                $context.cached = instance;
                return $context;
            },
            /** @param {SwitchAccounts<E>['api']} instance */
            api: (instance) => {
                $context.api = instance;
                return $context;
            },
        };
    }

    /**
     * Get reactive data and computed properties.
     * @returns {Omit<SwitchAccountConstants<E>, 'initialize'> & Omit<SwitchAccountState<E>, 'initialize'> & Omit<SwitchAccountCache<E>, 'initialize' | 'initStatusConditionals' | 'initAccountTargets'>}
     */
    get data() {
        const $context = this;
        return {
            ...$context.constants,
            ...$context.state,
            ...$context.cached,
        };
    }

    /**
     * Get the actions.
     */
    get actions() {
        const $context = this;
        return {
            ...$context.api.getters,
            ...$context.api.events,
            ...$context.api.methods,
        };
    }
}

// ==== CONSTANTS ====
/**
 * @class
 * Submodule for the {@link SwitchAccounts} composable.
 * @template {Vue.EmitsOptions} [E=Vue.EmitsOptions]
 */
class SwitchAccountConstants {
    /**
     * Instantiate submodule.
     * @param {SwitchAccounts<E>} context
     */
    constructor(context) {
        /** @type {SwitchAccounts<E>} */
        this.context = context;
        this.context.register.constants(this);
    }

    /**
     * Initialize submodule.
     */
    initialize() {
        /** Loading status IDs. */
        this.LoadingIDs = /** @type {const} */ ([
            'idle',
            'loading',
            'success',
            'failure',
        ]);
        /** @type {User.Model} */
        this.CurrentUser = this.context.store.state.users.me;
        /**
         * Default column definition.
         */
        this.defaultColDef = Object.freeze({
            resizable: true,
            sortable: true,
            filter: true,
            floatingFilter: true,
            floatingFilterComponentParams: { suppressFilterButton: true },
            suppressMovable: true,
            suppressMenu: true,
            lockPosition: true,
            headerName: '',
            cellClass: 'flex items-center text-left leading-5 break-normal',
            flex: 1,
        });
    }
}

// ==== STATE ====
/**
 * @class
 * Submodule for the {@link SwitchAccounts} composable.
 * @template {Vue.EmitsOptions} [E=Vue.EmitsOptions]
 */
class SwitchAccountState {
    /**
     * Instantiate submodule.
     * @param {SwitchAccounts<E>} context
     */
    constructor(context) {
        /** @type {SwitchAccounts<E>} */
        this.context = context;
        this.context.register.state(this);
    }

    /**
     * Initialize submodule.
     */
    initialize() {
        // ==== STATUS ====
        /** @type {Vue.Ref<'idle' | 'loading' | 'success' | 'failure'>} */
        this.status = ref('idle');

        // ==== TIMEZONE ====
        /** @type {Vue.Ref<TimeZone.Identifier>} */
        this.displayTimezone = ref('UTC');

        // ==== ACCOUNT INDEX ====
        /** @type {Vue.Ref<Map<Number, Account.Model>>} */
        this.accountIndex = ref(new Map());

        // ==== AG GRID ====
        /** @type {{ grid: AgGrid.GridApi, column: AgGrid.ColumnApi }} */
        this.api = reactive({
            grid: null,
            column: null,
        });

        /** @type {Vue.Ref<Array<Account.Model>>} */
        this.rowData = ref([]);

        /** @type {Vue.Ref<Array<AgGrid.ColumnDef>>} */
        this.colDefs = ref([]);
    }
}

// ==== COMPUTED PROPERTIES ====
/**
 * @class
 * Submodule for the {@link SwitchAccounts} composable.
 * @template {Vue.EmitsOptions} [E=Vue.EmitsOptions]
 */
class SwitchAccountCache {
    /**
     * Instantiate submodule containing computed properties.
     * @param {SwitchAccounts<E>} context
     */
    constructor(context) {
        /** @type {SwitchAccounts<E>} */
        this.context = context;
        this.context.register.cached(this);
    }

    /**
     * Initialize submodule.
     */
    initialize() {
        // ==== CONDITIONALS (STATUS) ====
        this.initStatusConditionals();
    }

    /**
     * Initialize the status conditionals.
     */
    initStatusConditionals() {
        const { state } = this.context;

        /** @type {Vue.ComputedRef<Boolean>} */
        this.isIdle = computed(() => {
            return state.status.value === 'idle';
        });

        /** @type {Vue.ComputedRef<Boolean>} */
        this.isLoading = computed(() => {
            return (
                state.status.value === 'loading' ||
                this.context.accounts.isFetching.value === true
            );
        });

        /** @type {Vue.ComputedRef<Boolean>} */
        this.isLoadedWithSuccess = computed(() => {
            return state.status.value === 'success';
        });

        /** @type {Vue.ComputedRef<Boolean>} */
        this.isLoadedWithFailure = computed(() => {
            return state.status.value === 'failure';
        });
    }
}

// ==== API ====
/**
 * @class
 * Submodule for the {@link SwitchAccounts} composable.
 * @template {Vue.EmitsOptions} [E=Vue.EmitsOptions]
 */
class SwitchAccountsAPI {
    /**
     * Instantiate submodule containing computed properties.
     * @param {SwitchAccounts<E>} context
     */
    constructor(context) {
        /** @type {SwitchAccounts<E>} */
        this.context = context;
        this.context.register.api(this);
    }

    /**
     * Initialize submodule.
     */
    initialize() {
        // ==== GETTERS ====
        this.initGetters();
        // ==== SETTERS ====
        this.initSetters();
        // ==== METHODS ====
        this.initMethods();
        // ==== EVENTS ====
        this.initEventHandlers();
    }

    initGetters() {
        const $api = this;
        const $emit = this.context.emit;
        const { state, store } = $api.context;

        /**
         * Get the default column definitions.
         * @returns {Readonly<AgGrid.ColumnDef>}
         */
        const getDefaultColDef = () => this.context.constants.defaultColDef;

        /**
         * Get keyed column definitions.
         */
        const getColumnSchema = () => {
            return Object.freeze({
                /** @type {AgGrid.ColumnDef} */
                spacer: {
                    suppressMovable: true,
                    suppressNavigable: true,
                    suppressKeyboardEvent: () => true,
                    suppressHeaderKeyboardEvent: () => true,
                    lockPosition: true,
                    filter: false,
                    minWidth: 0,
                    flex: 0.5,
                },
                /** @type {AgGrid.ColumnDef} */
                icons: {
                    headerName: '',
                    field: 'icons',
                    cellRendererFramework: SwitchAccountTableIcons,
                    lockPosition: true,
                    filter: false,
                    maxWidth: 60,
                    cellRendererParams: {
                        /**
                         * Handle switching to an account.
                         * @param {Object} event
                         * @param {Number} index Account index.
                         */
                        handleSwitchAccount: async (event, index) => {
                            try {
                                // Get the account id to switch to.
                                const id = state.rowData.value[index]?.id;

                                // Try to select an account.
                                const previous =
                                    $api.context.store.state?.accounts?.account;
                                const next = await fetchAccountById({ id });

                                // Check if the account has changed.
                                const isDirty =
                                    previous?.id === undefined ||
                                    previous?.id !== next?.id;

                                /** @type {Router.RouteRecordName[]} */
                                const refreshOn = [
                                    'Locations',
                                    'Notes',
                                    'Weather Stations',
                                    'Admin Dashboard',
                                    'Admin Accounts',
                                    'Admin Users',
                                    'Admin NARA Standards',
                                ];

                                /** @type {Router.RouteRecordName[]} */
                                const redirectOn = [
                                    'Select Account',
                                    'Location Detail',
                                ];

                                const { currentRoute } = $api.context.router;

                                // Check if a page refresh is required.
                                const isPageRefreshRequired =
                                    isDirty ||
                                    (isDirty &&
                                        currentRoute.value.matched.some(
                                            ({ name }) => {
                                                return refreshOn.includes(name);
                                            }
                                        ));

                                // Check if a page redirect is required.
                                const isPageRedirectRequired =
                                    currentRoute.value.matched.some(
                                        ({ name }) => {
                                            return redirectOn.includes(name);
                                        }
                                    );

                                // Set account to the requested one.
                                store.commit('setCurrentAccount', next);

                                // Push the latest selections.
                                await Context.push();

                                // Notify listeners that the account was selected.
                                // Simply ignore this event if you do not have any side-effects.
                                $emit('select', next);

                                // If dirty the page will redirect or refresh.
                                if (isPageRedirectRequired) {
                                    // Is a redirect required?
                                    console.log(`Selecting account...`);
                                    if (
                                        currentRoute.value.matched.some(
                                            ({ name }) =>
                                                name === 'Location Detail'
                                        )
                                    ) {
                                        // SPECIAL CASE: redirect to the data manager.
                                        $api.context.router.push({
                                            name: 'Data Manager',
                                            replace: true,
                                        });
                                    } else {
                                        $api.context.router.push({
                                            name: 'Home',
                                            replace: true,
                                        });
                                    }
                                    return;
                                } else if (isPageRefreshRequired) {
                                    // Is a refresh required?
                                    console.log(`Switching account...`);
                                    $api.context.router.go(0);
                                    return;
                                } else {
                                    console.log(`No account change detected.`);
                                }
                            } catch (e) {
                                // Notify listeners that the account was not selected.
                                $emit('error', e);
                            }
                        },
                    },
                },
                /** @type {AgGrid.ColumnDef} */
                name: {
                    headerName: 'Account Name',
                    field: 'name',
                    minWidth: 350,
                    maxWidth: 800,
                    wrapText: true,
                    flex: 2,
                },
                /** @type {AgGrid.ColumnDef} */
                id: {
                    headerName: 'Account ID',
                    field: 'id',
                    maxWidth: 130,
                    sort: 'asc',
                },
                /** @type {AgGrid.ColumnDef} */
                lastUploadDate: {
                    headerName: `Last Upload (${
                        state.displayTimezone.value ?? 'UTC'
                    })`,
                    field: 'lastUploadAt',
                    valueFormatter: $api.methods.formatDate,
                    minWidth: 150,
                    maxWidth: 400,
                    filter: false,
                    sort: 'desc',
                    cellClass: `${$api.context.constants.defaultColDef.cellClass} text-center sm:text-left`,
                },
            });
        };

        /**
         * Get column definitions in ordered array.
         * @returns {AgGrid.ColumnDef[]}
         */
        const getColumnDefs = () => {
            const schema = getColumnSchema();
            return [
                schema.icons,
                schema.name,
                schema.lastUploadDate,
                schema.spacer,
            ];
        };

        /**
         * Get the current account.
         * @returns {store['state']['accounts']['account']}
         */
        const getCurrentAccount = () => {
            return store.state.accounts.account;
        };

        /**
         * Map access level into its corresponding display text.
         * @param {String} value
         */
        const getAccessLevelDisplayText = (value) => {
            const id = value.toLowerCase();
            switch (id) {
                case 'normal':
                    return 'Data Manager';
                case 'readonly':
                    return 'Data Analyst';
                case 'admin':
                    return 'Admin';
                default:
                    return value;
            }
        };

        /**
         * Create account index from array,
         * @param {Account.Model[]} accounts
         */
        const getAccountsAsIndex = (accounts) => {
            /** @type {[ id: Number, account: Account.Model ][]} */
            const entries = accounts.map((u) => {
                /** @type {[ id: Number, account: Account.Model ]} */
                const entry = [u.id, { ...u }];
                return entry;
            });
            /** Get map. */
            return new Map(entries);
        };

        /**
         * Map
         * @param {Account.Model[]} accounts
         * @returns
         */
        const getAccountsAsRowData = (accounts) => {
            return accounts.map((account) => getAccountAsRecord(account));
        };

        /**
         * Copy account and modify it for the row data.
         * @param {Account.Model} account
         */
        const getAccountAsRecord = (account) => ({
            ...account,
        });

        /**
         * Copy account from index as a selected account target.
         * @param {Account.Model['id']} id
         */
        const getAccountAsTarget = (id) => {
            const source = state.accountIndex.value.get(id);
            return {
                ...pick(source, 'id', 'name'),
            };
        };

        /** Getter calls that provide live accessors. */
        this.getters = {
            getDefaultColDef,
            getColumnSchema,
            getColumnDefs,
            getCurrentAccount,
            getAccessLevelDisplayText,
            getAccountsAsIndex,
            getAccountsAsRowData,
            getAccountAsRecord,
            getAccountAsTarget,
        };
    }

    initSetters() {
        const $api = this;
        const { state } = $api.context;

        /**
         * Save the grid api reference.
         * @param {AgGrid.GridApi} api
         */
        const setGridApi = (api) => (state.api.grid = api);

        /**
         * Save the column api reference.
         * @param {AgGrid.ColumnApi} api
         */
        const setColumnApi = (api) => (state.api.column = api);

        /**
         * Set the loading status.
         * @param {'idle' | 'loading' | 'success' | 'failure'} [id]
         */
        const setLoading = (id = 'idle') => {
            state.status.value = id ?? 'idle';
        };

        /**
         * Set account index instance.
         * @param {Map<Number, Account.Model>} index
         */
        const setAccountIndex = (index) => {
            state.accountIndex.value = new Map(index.entries());
        };

        /**
         * Set the row data.
         * @param {Account.Model[]} data
         */
        const setRowData = (data) => {
            state.rowData.value = [...data];
        };

        /** Setters for mutation of the state. */
        this.setters = {
            setGridApi,
            setColumnApi,
            setLoading,
            setAccountIndex,
            setRowData,
        };
    }

    initEventHandlers() {
        const $api = this;
        const { state, alerts } = $api.context;
        /**
         * When accounts index is loaded/refreshed,
         * update the row data, with mapped account access levels.
         * @param {Account.Model[]} accounts
         */
        const onUpdateAccounts = (accounts) => {
            const { getAccountsAsIndex, getAccountsAsRowData } = $api.getters;
            const { setAccountIndex, setRowData } = $api.setters;
            const accountIndex = getAccountsAsIndex(accounts);
            const accountData = getAccountsAsRowData(accounts);
            setAccountIndex(accountIndex);
            setRowData(accountData);
        };

        /**
         * After initialization, run this event.
         */
        const onInit = async () => {
            // Initialize the column definitions.
            const { getColumnDefs } = $api.getters;
            state.colDefs.value = [...getColumnDefs()];
        };

        /** @param {Events.GridReadyEvent} e */
        const onGridReady = (e) => {
            const { setGridApi, setColumnApi } = $api.setters;
            setGridApi(e?.api);
            setColumnApi(e?.columnApi);
        };

        /** @param {Events.ColumnResizedEvent} e */
        const onColumnResized = (e) => {
            // Refresh cells on column resize.
            state.api.grid.refreshCells();
        };

        /** Event handlers and callbacks. */
        this.events = {
            onInit,
            onUpdateAccounts,
            onGridReady,
            onColumnResized,
        };
    }

    initMethods() {
        const $api = this;
        const { state } = $api.context;

        /**
         * Format the date.
         * @type {AgGrid.ValueFormatterFunc}
         */
        const formatDate = (params) => {
            // Get the value.
            const date = Maybe.of(params.value).map((dt) => new Date(dt));
            // Conditionally execute based on if row was found.
            const formatted = date.match({
                Just: (dt) => {
                    return dt.toLocaleDateString('en-ca', {
                        timeZone: state.displayTimezone.value ?? 'UTC',
                    });
                },
                Nothing: () => 'No date provided.',
            });
            // Return the value.
            return formatted;
        };

        /**
         * Create watcher that syncs the loading overlay.
         * @type {Vue.WatchEffect}
         */
        const syncLoadingOverlay = (onCleanup) => {
            const { data } = $api.context;
            // Show or hide the overlay.
            const grid = data?.api?.grid;
            const isOverlayVisible = data.isLoading.value === true;
            const action = isOverlayVisible
                ? 'showLoadingOverlay'
                : 'hideOverlay';
            grid?.[action]();
            // Register onCleanup call.
            onCleanup(() => {
                // If data in the grid empty, show the no rows overlay.
                if (data?.rowData?.value?.length > 0) {
                    // Hide all overlays if something goes wrong.
                    data?.api?.grid?.hideOverlay();
                } else {
                    // Hide all overlays if something goes wrong.
                    data?.api?.grid?.showNoRowsOverlay();
                }
            });
        };

        /** @typedef {import('axios').AxiosError} AxiosError */

        /**
         * View the accounts for the user (if they are an administrator).
         * @returns {Promise<[ error?: AxiosError, accounts?: Account.Model[] ]>}
         */
        const viewAdminAccounts = async () => {
            try {
                const accountList = await fetchAccounts();
                return [null, accountList];
            } catch (e) {
                // ERROR, user is not an admin.
                console.log(`The user is not an administrator.`, e);
                return [e, null];
            }
        };

        /**
         * View the accounts for the user.
         * @returns {Promise<[ error?: AxiosError, accounts?: Account.Model[] ]>}
         */
        const viewProfileAccounts = async () => {
            try {
                const response = await profile.fetchUserAccounts();
                const accountList = response.unwrapOr(
                    /** @type {Account.Model[]} */ ([])
                );
                return [null, accountList];
            } catch (e) {
                // ERROR, user is not an admin.
                console.log(`The user is not authenticated.`, e);
                return [e, null];
            }
        };

        /**
         * Refresh the organization selection.
         */
        const refreshOrganization = async () => {
            const { store, constants, router } = $api.context;
            console.time(
                `[organizations::selection] - Refreshing Organization Selection:`
            );
            try {
                if (store.state.accounts.hasOrganization) {
                    return Result.ok(store.state.accounts.organization);
                }
                if (constants.CurrentUser?.organizations?.length > 0) {
                    return Result.ok(constants.CurrentUser?.organizations[0]);
                } else {
                    router.push('/login');
                    return Result.err('No organization selected.');
                }
            } finally {
                // TODO: Fetch if missing?

                console.timeEnd(
                    `[organizations::selection] - Refreshing Organization Selection:`
                );
            }
        };

        /**
         * Update the row data after requesting accounts from the cached index.
         * @param {Boolean} [forceReload]
         */
        const refreshAccounts = async (forceReload = false) => {
            const { store, router } = $api.context;
            const { setLoading } = $api.setters;
            try {
                console.time(`[accounts::index] - Refreshing Account index:`);
                setLoading('loading');

                // IF NO ORGANIZATION IS SELECTED, one must be...
                const selectedOrganization = await refreshOrganization();
                if (selectedOrganization.isOk) {
                    const organization = selectedOrganization.value;
                    store.commit('setCurrentOrganization', organization);
                }

                // USERS must select all accounts under the current organization.
                const request = { id: store.state.accounts.organization?.id };
                const availableAccounts =
                    await organizations.fetchOrganizationAccounts(request);

                const accountList = availableAccounts.unwrapOr(
                    /** @type {Account.Model[]} */ ([])
                );
                $api.events.onUpdateAccounts(accountList);
                setLoading('success');

                if (availableAccounts.isErr) {
                    // Throw error, if one was received.
                    throw availableAccounts.error;
                }

                // // THROW error and logout.
                // const accountList =
                //     this.context.store.state.users?.me?.accounts ?? [];
                // $api.events.onUpdateAccounts(accountList ?? []);
                // throw ProfileError;
            } catch (e) {
                setLoading('failure');
                if ('code' in e && e.code === 'ERR_BAD_REQUEST') {
                    console.log(
                        `The user is not authenticated or no accounts could be found.`,
                        e
                    );
                    localStorage.clear();
                    sessionStorage.clear();
                    await this.context.store.dispatch(`logout`);
                } else {
                    console.error(e);
                    // Clear login information and then redirect.
                    localStorage.clear();
                    sessionStorage.clear();
                    await this.context.store.dispatch(`logout`);
                    router.push('login');
                }
            } finally {
                console.timeEnd(
                    `[accounts::index] - Refreshing Account index:`
                );
            }
        };

        /** Event triggers and methods. */
        this.methods = {
            formatDate,
            refreshAccounts,
            syncLoadingOverlay,
        };
    }
}

/**
 * Composable function that returns the initialized context object.
 * @template {Vue.EmitsOptions} [E=Vue.EmitsOptions]
 * @param {Object} props
 * @param {Vue.SetupContext<E>} context
 *
 */
export const useSwitchAccount = (props, context) => {
    const hook = new SwitchAccounts(props, context);
    return hook.initialize();
};

// <!-- DEFAULT -->
export default useSwitchAccount;
