// <!-- API -->
import { collect } from 'collect.js';

// <!-- TYPES -->
import { FormStep } from '@/hooks/useFormStep';

/**
 * Status ID for workflow status.
 */
export const WorkflowStatusID = /** @type {const}*/ ({
    /** Indicates workflow completion was unsuccessful with a fatal error. Indicates restart is necessary. */
    rejected: 'rejected',
    /** Indicates workflow completion was successful. */
    fulfilled: 'fulfilled',
    /** Indicates the workflow progression is currently locked. */
    locked: 'locked',
});

/**
 * @class
 * Representation of the CSV Uploader form.
 */
export class UploadWorkflow {
    /**
     * Constructs an upload state instance.
     */
    constructor() {
        /**
         * @type {Set<keyof WorkflowStatusID>}
         */
        this.status = new Set();
        /**
         * Map of {@link FormStep} instances, pertaining to the form's current state.
         * @type {Map<String, FormStep>}
         */
        this.steps = new Map();
        /**
         * Current step index. -1 indicates uninitialized.
         * @type {Number}
         */
        this.currentStepIndex = -1;
    }

    // <!-- PROPERTIES -->

    /**
     * Get collection of steps, keyed by their ids.
     */
    get all() {
        return collect([...this.steps.values()]);
    }
    /**
     * Get ordered array of form steps.
     */
    get sequence() {
        return this.all.all();
    }
    /**
     * Get the total count of steps.
     */
    get count() {
        return this.steps.size;
    }
    /**
     * Get the first step.
     */
    get firstStep() {
        return this.getStep(0);
    }
    /**
     * Get the last step.
     */
    get lastStep() {
        return this.getStep(this.count - 1);
    }
    /**
     * Get the previous step.
     */
    get previousStep() {
        return this.getStep(this.currentStepIndex - 1);
    }
    /**
     * Get the current step.
     */
    get currentStep() {
        return this.getStep(this.currentStepIndex);
    }
    /**
     * Get the next step.
     */
    get nextStep() {
        return this.getStep(this.currentStepIndex + 1);
    }

    // <!-- STATUS -->

    get isIdle() {
        return this.currentStepIndex === -1;
    }

    get isDone() {
        return this.isFulfilled || this.isRejected;
    }

    get isFulfilled() {
        return this.status.has('fulfilled');
    }

    get isRejected() {
        return this.status.has('rejected');
    }

    get isLocked() {
        return this.status.has('locked');
    }

    get isRunning() {
        return !this.isIdle && !this.isDone;
    }

    // <!-- ACCESSORS -->

    /**
     * Get step at specified index.
     * @param {Number} index
     * @returns {FormStep}
     */
    getStep(index) {
        return index >= 0 && index < this.count ? this.sequence[index] : null;
    }

    /**
     * Check if step at specified index exists.
     * @param {Number} index
     */
    hasStep(index) {
        return this.count > 0 && this.getStep(index) !== null;
    }
}
