// <!-- API -->
import { StandardRange } from '@/utils/standards';

// <!-- UTILITIES -->
import isNil from 'lodash-es/isNil';

// <!-- TYPES -->
// TODO - Enhance with global types.
/** @typedef {import('@/utils/standards').IStandard} IStandard */
/** @typedef {import('@/utils/standards').IStandardRange} IStandardRange */

// <!-- CLASS -->
/**
 * Class representing a standard object.
 * @implements {IStandard}
 */
export class Standard {
    /**
     * Create new standard instance.
     * @param {Readonly<Partial<IStandard>>} props
     * @returns {IStandard}
     */
    static create = (props) => {
        const instance = new Standard(props);
        return instance;
    };

    /**
     * Clone existing standard.
     * @param {Readonly<IStandard>} source
     * @returns {IStandard}
     */
    static clone = (source) => {
        const instance = new Standard(source);
        return instance;
    };

    /**
     * Clone existing standard with sanitized and validated ranges.
     * @param {Readonly<IStandard>} source
     * @returns {IStandard}
     */
    static validate = (source) => {
        // Validate the name and description.
        const name = isNil(source?.name) ? '' : source.name;
        const description = isNil(source?.description)
            ? ''
            : source.description;

        // Sanitize the temperature standard.
        const T = StandardRange.sanitize(source.T);

        // Sanitize the relative humidity standard.
        const RH = StandardRange.sanitize(source.RH, {
            minValue: 0,
            maxValue: 100,
        });

        // Compose validated instance.
        const instance = Standard.create({
            name,
            description,
            T: StandardRange.validate(T),
            RH: StandardRange.validate(RH),
        });

        // Return instance.
        return instance;
    };

    /**
     * Create a general standard instance.
     * @param {Readonly<Partial<IStandard>>} props
     */
    constructor(props) {
        // <!-- DESTRUCTURE -->
        const {
            name = '',
            description = '',
            T = null,
            RH = null,
        } = props ?? {};

        /** @type {String} Name of the standard. */
        this.name = isNil(name) ? '' : name;

        /** @type {String} Description of the standard. Can contain HTML. */
        this.description = isNil(description) ? '' : description;

        /** @type {IStandardRange} Temperature standard. */
        this.T = isNil(T) ? new StandardRange() : StandardRange.clone(T);

        /** @type {IStandardRange} Relative humidity standard. */
        this.RH = isNil(RH) ? new StandardRange() : StandardRange.clone(RH);
    }
}

// <!-- DEFAULT -->
export default Standard;
