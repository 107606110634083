// <!-- API -->
import { UploadErrors } from '@/store/types/uploader/state';
import { UploadErrorsMutations } from '@/store/types/uploader/mutations';
import { UploadErrorsGetters } from '@/store/types/uploader/getters';
import { UploadErrorGroupID } from '@/store/types/uploader/state/UploadErrors';

// <!-- SUB-MODULES -->

// <!-- TYPES -->

// ts-ignore
import { ECNBState } from '@/store/types/ECNBStore';
import { ECNBModule } from '@/store/types/ECNBModule';

// CLASS
/**
 * @class
 * Uploader module.
 */
export class UploadErrorsModule extends ECNBModule {
    /**
     * Name of the module.
     */
    static get namespace() {
        return 'uploader/errors';
    }

    /**
     * Module state, getters, mutations, and actions.
     */
    static get module() {
        // EXPOSE
        return {
            namespaced: true,
            state: () => new UploadErrors(),
            modules: {},
            get getters() {
                const $getters = UploadErrorsGetters;
                return {
                    ...$getters,
                };
            },
            get mutations() {
                const $add = UploadErrorsMutations.add;
                const $clear = UploadErrorsMutations.clear;
                const setters = {
                    /**
                     * @param {UploadErrors} state
                     * @param {{ id: keyof UploadErrorGroupID, error: String}} payload
                     */
                    raiseError: (state, payload) => {
                        const { id, error } = payload;
                        $add(state).error(id, error);
                    },
                    /**
                     * @param {UploadErrors} state
                     * @param {{ id: keyof UploadErrorGroupID, errors: String[] }} payload
                     */
                    raiseErrors: (state, payload) => {
                        const { id, errors } = payload;
                        $add(state).errors(id, ...errors);
                    },
                };
                const droppers = {
                    /**
                     * @param {UploadErrors} state
                     */
                    clearModule: (state) => {
                        const initialState = new UploadErrors();
                        Object.assign(state, initialState);
                    },
                    /**
                     * @param {UploadErrors} state
                     */
                    clearErrors: (state) => {
                        $clear(state).all();
                    },
                    /**
                     * @param {UploadErrors} state
                     * @param {{ id: keyof UploadErrorGroupID, error: String }} payload
                     */
                    dropGroupError: (state, payload) => {
                        const { id, error } = payload;
                        $clear(state).error(id, error);
                    },
                    /**
                     * @param {UploadErrors} state
                     * @param {keyof UploadErrorGroupID} id
                     */
                    clearGroupErrors: (state, id) => {
                        $clear(state).group(id);
                    },
                };
                return {
                    ...setters,
                    ...droppers,
                };
            },
            actions: {},
        };
    }
}
