import { AttributeFactory } from './AttributeFactory';

/**
 * Model attribute factory interface.
 *
 * @template {{ [key: string]: any }} [P = never]
 * @template {{ [key: string]: any }} [M = never]
 * @extends {AttributeFactory<'model',P,M>}
 * @implements {Resource.ModelAttributeFactory<P,M>}
 */
export class ModelAttributeFactory extends AttributeFactory {
    // <!-- STATIC FACTORY METHODS -->

    /**
     * Create an attribute factory that returns the supplied initial state.
     *
     * @template {{ [key: string]: any }} [P = never]
     * @template {{ [key: string]: any }} [M = never]
     * @param {(data: Partial<P>) => Partial<M>} mapperFn
     * @param {M} initialValues
     */
    static create(mapperFn, initialValues) {
        return new ModelAttributeFactory(mapperFn, initialValues);
    }

    // <!-- CONSTRUCTOR -->

    /**
     * Create an attribute factory that returns the supplied initial state.
     *
     * @param {(data: Partial<P>) => Partial<M>} mapperFn
     * @param {M} initialValues
     */
    constructor(mapperFn, initialValues) {
        super(initialValues);
        this.createAttributesFromPayloadData = mapperFn;
    }

    // <!-- FACTORY INTERFACE -->

    /**
     * Create full model attributes object using default values and overrides.
     *
     * @param {Partial<P>} payload
     * @returns {M}
     */
    fromPayload(payload) {
        const attributes = this.createAttributesFromPayloadData(payload);
        return this.fromModel(attributes);
    }

    /**
     * Create full model attributes object using default values and overrides.
     *
     * @param {Partial<M>} model
     * @returns {M}
     */
    fromModel(model) {
        return Object.assign(this.initialState, model);
    }
}

// <!-- EXPORTS -->
export default ModelAttributeFactory;
